.internal-tracking-pane {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  &__campaign {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
  }

  .toggletip-label {
    display: flex;
  }
}
