.crm-expression {
  flex-wrap: wrap;
  display: flex;
  gap: var(--spacing-unit-x2);

  &__select {
    min-width: 20rem;
    max-width: 30rem;
  }
}
