.welcome-message-modal {
  width: 50rem;

  &__header-title {
    display: flex;
    align-items: center;
  }

  &__info-text {
    .text-bubble {
      margin: unset;
    }
  }

  &__preview-label {
    margin-top: var(--spacing-unit-x3);

    &__text {
      text-transform: capitalize;
    }
  }

  &__message-bubble {
    margin-top: var(--spacing-unit);
  }

  &__checkbox-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-unit-x4);

    &-row {
      display: flex;
      align-items: center;
    }

    &-second-row {
      display: flex;
      align-items: center;
      margin-top: var(--spacing-unit-x2);
    }

    &-checkbox {
      margin-top: var(--spacing-unit);
      margin-right: var(--spacing-unit-x2);
    }
  }
}
