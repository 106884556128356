.multi-text-input {
  display: flex;
  gap: var(--spacing-unit);

  &__select {
    min-width: 19.5rem;
    max-width: 50rem;
  }

  &__popover {
    &-content {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
    }
  }
}
