.select-v2-menu-footer-toggle {
  display: flex;
  gap: var(--spacing-unit);
  align-items: center;
  padding: var(--spacing-unit) 0;

  &__toggle .toggle {
    margin-left: 0;
  }
}
