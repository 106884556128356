.validation-section {
  display: flex;
  width: 100%;

  &__wrapper {
    border-top: var(--standard-border-dark);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }

  &__content {
    flex: 1;

    &-subtitle {
      margin: calc(var(--spacing-unit-x3) / 2) 0 var(--spacing-unit-x2);
    }
  }

  &-button {
    min-width: var(--spacing-unit-x26);
  }
}
