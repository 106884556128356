.select-program {
  &__container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
    margin: var(--spacing-unit-x4) 0;
  }
  
  &__button {
    &-next-container {
      display: flex;
      justify-content: flex-end;
    }
    
    &-programs {
      display: flex;
      gap: 0.84rem;
      width: fit-content;
    }
  }
  
  &__title-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
  }
  
}
