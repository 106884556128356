.selectable-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-unit) var(--spacing-unit-x3);
  cursor: pointer;

  &__checked-icon.svg {
    fill: var(--teal);
  }

  &:hover {
    background-color: var(--hover-row-background-teal);
  }

  &:hover &__action {
    display: block;
  }

  &__action {
    display: none;
  }

  &__selected {
    background-color: var(--lighter-teal);
    border-left: var(--standard-border-radius) solid var(--teal);
    padding-left: calc(var(--spacing-unit-x3) - var(--standard-border-radius));
  }
}
