
.clear-history-modal {
  width: 40rem;

  &__checkbox {
    margin-top: var(--spacing-unit-x6);
    margin-bottom: var(--half-spacing-unit);
    justify-content: left;
    display: flex;
  }

  &__confirm-continue {
    margin-top: var(--spacing-unit-x2);
  }
}
