.add-to-segment-modal {
  width: 50rem;
  
  &__radio-card {
    flex: 1 1 auto;
    gap: var(--spacing-unit-x4);
    
    &-container {
      margin-top: var(--spacing-unit-x4);
    }
  }
}
