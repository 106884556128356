.segment-errors-modal {
  width: 50rem;

  &__error {
    &-header {
      display: flex;
      gap: var(--spacing-unit-x2);
      align-items: center;

      .svg {
        flex-shrink: 0;
      }
    }

    &-message {
      margin-left: 2.3334rem;
    }
  }

  &__caution-message {
    margin: 0 0 var(--spacing-unit-x4) 0;
  }
}
