
.modal-sub-header {
  display: flex;

  &__inner {
    padding: var(--spacing-unit) var(--spacing-unit-x7);
    align-items: center;
    border-bottom: var(--standard-border);
    flex: 1 1 100%;
    display: flex;

    &-no-border {
      border-bottom: none;
    }
  }
}
