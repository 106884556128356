.expiration-date {
  margin-top: var(--spacing-unit-x4);
  position: relative;

  .tooltip__trigger {
    position: absolute;
    top: 0.1rem;
    left: 14.8rem;
  }

  svg {
    fill: var(--hover-create-option-icon);
  }

  &__date-container {
    position: relative;

    margin-bottom: var(--spacing-unit);
    margin-left: var(--spacing-unit);
    margin-top: 1rem;

    &::before {
      position: absolute;
      content: '';
      width: 0.33rem;
      height: 100%;
      border-radius: var(--standard-border-radius);
      left: 0;
      top: 0;
      background-color: var(--teal);
    }

    .label,
    &--date-text {
      margin-left: var(--spacing-unit-x3);
      margin-top: var(--spacing-unit);
    }

    &--date-picker {
      display: flex;
      justify-content: space-between;
      align-content: center;
      gap: var(--spacing-unit-x2);
      margin-left: var(--spacing-unit-x3);

      .date-picker {
        width: 35.33rem;
      }

      .tooltip__trigger {
        align-self: center;
      }

      .button-copy {
        border-radius: 0.3rem;
        border: none;
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }
}
