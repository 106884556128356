.tableV2-tbody {
  display: block;
  width: 100%;

  &__scroll {
    &__scrollbar {
      margin-right: var(--half-spacing-unit);
      padding: var(--spacing-unit) 0;

      &-horizontal {
        margin-bottom: var(--half-spacing-unit);
        padding: 0 var(--spacing-unit);
      }
    }
  }

  &__row:has(&__expander-cell-with-subRow) {
    .tableV2-tbody__td {
      box-shadow: none;
    }
  }

  &__row:has(.spacer-block-V2-small, .spacer-block-V2__line-first) {
    box-shadow: none;
  }

  &__expander-cell-with-subRow:not(:has(.expander__button)) {
    .typography {
      padding-left: var(--spacing-unit);
    }
  }

  &__row {
    display: table;
    width: 100%;
    min-height: calc(var(--spacing-unit-x8) - var(--spacing-unit-x2) / 3);
    table-layout: fixed;
    position: relative;

    &:hover {
      background-color: var(--hover-row-background-teal);

      .tableV2-tags__tag-manager-trigger-with-text {
        visibility: visible;
      }

      .expander__caret-icon-wrapper {
        position: relative;
        background: var(--hover-row-background-teal) !important;
      }

      .tableV2-tbody__hover-sticky-cols {
        background-color: var(--hover-row-background-teal);
      }

      .tableV2-tbody__loader {
        background-color: var(--white);
      }

      .tableV2-row-action {
        display: flex;
      }

      .info-hover-card {
        visibility: visible;
      }
    }

    &-actionable {
      cursor: pointer;
    }

    &-actions {
      position: sticky;
      right: 0;
      width: 0;
      padding: 0;
    }

    &-disabled {
      cursor: not-allowed;
      
      td {
        &:not(.tableV2-tbody__checkbox):not(.tableV2-tbody__radio) {
          pointer-events: none;
        }
      }

      &-title td {
        pointer-events: auto;
      }

      &:hover {
        background-color: var(--off-white-hover-disabled);
      }
    }

    &-hovered {
      background-color: var(--hover-row-background-teal);
      z-index: var(--z-index-99999);

      .expander__caret-icon-wrapper {
        background-color: var(--hover-row-background-teal) !important;
      }

      .tag-manager-trigger {
        visibility: visible;
      }

      .tableV2-draggable-row-drag-source {
        display: block;
      }

      .tableV2-row-action {
        display: flex;
      }

      .info-hover-card {
        visibility: visible !important;
      }
    }
  }

  &__loader {
    & > .loader {
      margin: 0 auto;
    }

    &-row {
      display: table;
      width: 100%;
    }
  }

  &__clickable-cell {
    cursor: pointer;
  }
}
