.segments-insights-settings-container {
  display: flex;
  gap: var(--spacing-unit-x13);
  
  &__image {
    width: 20rem;
    height: fit-content;
  }
   
  &__button-container {
    display: flex;
    gap: var(--spacing-unit-x3);

    svg {
      fill: var(--white);
      margin-right: var(--spacing-unit);
    }
  }
   
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--spacing-unit-x2);
  }
}
