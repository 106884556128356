.quick-add-contact {
  width: 72rem;

  .key-value-picker {
    max-width: none;
    margin-top: var(--spacing-unit-x3);

    &__header__cell {
      .label {
        font-size: var(--smallest-font-size);
      }
    }

    .input-with-status {
      margin-right: -0.67rem;
    }

    &__row__last {
      padding-bottom: var(--spacing-unit-x4);
      border-bottom: var(--standard-border);
    }

    .input__container,
    &__header__cell label {
      width: 17.41rem;
    }

    &__row__cell-required .input__container,
    &__header__cell-required label {
      width: 25.58rem;
    }

    &__add {
      margin-top: var(--spacing-unit-x2);
    }
  }

  .selectv2__container {
    margin-top: var(--spacing-unit-x4);
    background-color: var(--input-background);
    padding-top: 1.33rem;
    padding-left: 2.16rem;
    padding-right: 1.41rem;
    padding-bottom: var(--spacing-unit-x4);

    .selectv2__indicator-separator {
      width: 0;
    }
  }

  &__loader {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
