.custom-touch-point-behaviors-body {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

  &__label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8334rem;

    > span,
    > div {
      display: flex;
      align-items: center;
    }
  }

  &__select {
    border-bottom: var(--standard-border);
    display: flex;
    flex-direction: column;
    padding-bottom: var(--spacing-unit-x3);
    width: 100%;
  }
}
