.custom-insights-results-row {
  padding: var(--spacing-unit-x6);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  background-color: var(--white);

  &__flex-container {
    display: flex;
    align-items: center;
  }

  &__description {
    flex-grow: 1;
    margin: 0 var(--spacing-unit-x8) 0 var(--spacing-unit-x4);

    > div {
      white-space: pre-wrap;
    }
  }
   
  .button {
    height: var(--spacing-unit-x6);
    line-height: var(--line-height-smallish) !important;
  }
  
  &__icon,
  .button {
    flex-shrink: 0;
  }

  &__reason {
    margin-left: var(--spacing-unit-x12);
  }
}
