.step-issues {
  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit-x2);
  }

  &__paginator-container {
    margin-left: auto;
    flex-shrink: 0;
  }

  &__steps-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x3);
  }
}