.schedule-selector {
  &__header {
    margin-bottom: var(--spacing-unit-x3);
  }

  &__manually-radio-container {
    display: flex;
    align-items: center;

    button {
      margin-top: var(--spacing-unit);
    }

    svg {
      fill: var(--light-gray);
    }

    label {
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__more-options {
    margin-top: var(--half-spacing-unit);
  }

  &__run-schedule {
    &-container {
      display: inline-flex;
      align-items: center;
      gap: var(--spacing-unit-x2);

      &-disabled {
        label {
          color: var(--light-gray);
        }
      }
    }

    &-frequency,
    &-interval {
      width: calc(var(--spacing-unit-x24) + var(--half-spacing-unit));
    }

    &-hour {
      align-self: flex-end;

      & .time-picker-v2__label {
        margin-bottom: var(--spacing-unit-x2);
      }

      & .time-picker-v2-picker {
        width: 14.5rem;
      }
    }

    &-checkbox {
      margin-top: var(--spacing-unit-x2);
    }
  }

  select {
    line-height: var(--line-height-large);
  }
}
