.forms-join-view-page-header {
  &__create-form-button {
    height: var(--spacing-unit-x6);
  }

  &__create-form-icon {
    margin-right: var(--spacing-unit-x2);
  }

  &__view-webinars-icon {
    margin-left: var(--spacing-unit-x2);
  }

  &__header {
    &-button {
      display: flex;
      gap: var(--spacing-unit-x2);
    }
  }

  &__drop-down {
    &-open {
      background-color: var(--off-white-hover);
    }
  }

  & > .drop-down__drop-down-styled {
    margin-top: var(--spacing-unit) !important;
  }
}
