.embed-code-modal {
  width: 50rem;

  &__body {
    height: 20rem;

    textarea {
      height: 16rem;
    }
  }

  &__footer {
    &__extra {
      margin-right: auto;
      display: flex;
      gap: var(--spacing-unit-x4);

      &-section {
        display: flex;
        align-items: center;
        gap: var(--half-spacing-unit);

        .svg {
          margin-top: calc(var(--spacing-unit-x2) / 12);
        }
      }
    }
  }
}
