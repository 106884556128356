.add-alert-filter-button {
  padding: var(--spacing-unit-x3) var(--spacing-unit-x4) !important;
  justify-content: flex-start;
  width: 100%;

  &:hover {
    background-color: var(--lighter-off-white);
  }

  &__add-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);
    background-color: var(--text-gray-opacity-10);
    border-radius: var(--spacing-unit-x2);
    margin-right: var(--spacing-unit-x3);
  }
}
