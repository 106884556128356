.form-upload-file {
  &__files {
    display: block;
    width: 100%;
    position: relative;
    max-width: 46rem;

    &__wrapper {
      display: flex;
      gap: var(--spacing-unit-x3);
      align-items: center;
      height: 5.1667rem;
      padding: var(--spacing-unit-x2);
    }

    &--selected {
      background-color: var(--lighter-teal);

      &::before {
        content: '';
        width: 0.3333rem;
        background-color: var(--teal);
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    &:hover {
      background-color: var(--selectable-row-hover);
      text-decoration: none;
    }
  }

  &__file {
    text-align: left;

    &-title {
      max-width: 40rem;
    }

    &-svg {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
      fill: var(--gray-icon);
    }

    &-date {
      font-size: var(--spacing-unit-x2);
    }
  }

  &__selection-button {
    display: flex;
    gap: var(--spacing-unit-x2);
  }
}
