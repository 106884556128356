.advanced-import-options {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  &__radio-label {
    > .typography {
      display: flex;
      gap: var(--spacing-unit);
    }
  }

  &__advanced {
    &-header {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      cursor: pointer;

      &:hover {
        div {
          color: var(--text-teal);
        }

        svg {
          fill: var(--text-teal) !important;
        }
      }
    }

    &-content {
      display: none;

      &-visible {
        display: block;
        margin-top: var(--spacing-unit-x4);
      }
    }

    &-section {
      display: flex;
      align-items: baseline;

      &-header {
        width: 17.5rem;
        align-items: center;
        display: flex;
        gap: var(--spacing-unit);

        &-title {
          flex-shrink: 0;
        }
      }

      &-content {
        flex-grow: 1;
      }

      &:not(:first-child) {
        padding-top: var(--spacing-unit-x3);
        margin-top: var(--spacing-unit-x3);
        border-top: var(--standard-border);
      }
    }
  }

  &__pill {
    border-radius: var(--standard-border-radius);
    background-color: var(--chip-base);
    color: var(--dark-blue);
    padding: 0.16rem var(--spacing-unit);
    margin: 0 var(--half-spacing-unit);
  }

  &__external-id-rule {
    display: block;

    &-container {
      .selectv2__container {
        margin: var(--spacing-unit-x2) 0;
      }
    }
    
    &-checkbox {
      margin-top: var(--spacing-unit-x2);
    }

    &-object-type {
      display: flex;
      align-items: flex-start;
      margin-top: var(--spacing-unit);

      .typography {
        margin-right: var(--spacing-unit);
      }

      &-input {
        flex-grow: 1;
      }

      &-label {
        display: flex;
        align-items: center;
        gap: var(--spacing-unit);
      }
    }
  }

  &__select {
    width: 33rem;

    > svg {
      color: var(--text-gray);
    }
  }

  &__hidden {
    display: none;
  }
}