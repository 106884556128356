.subscription-management {
  &__label {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4) ;
  }

  &__version-col {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);

    .svg {
      margin-top: var(--spacing-unit);
    }
  }

  &__version-name {
    display: flex;
    align-items: center;
    gap: var(--half-spacing-unit);

    .svg {
      margin-top: var(--spacing-unit);
    }

    .typography {
      max-width: 21rem;
    }
  }
  

  &__main-button {
    .svg {
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__position {
    display: contents;
  }

  .position-container__children {
    max-width: 83rem;
    @media screen {
      margin: auto;
    }
  }

  &__header {
    min-width: calc(var(--positioning-min-width) - 4.166rem);
  }

  &__version-col-icon-warning__trigger-wrapper {
    display: flex;

    .svg {
      margin: var(--half-spacing-unit);
    }
  }
}
