.create-account-modal {
  width: 50rem;

  .label {
    margin-top: var(--spacing-unit-x4);
  }

  &__first__container {
    .label {
      margin-top: 0;
    }
  }

  &__row {
    display: flex;
    gap: var(--spacing-unit-x3);

    &-input__container {
      flex: 1;
    }
  }

  &__divider {
    padding-top: var(--spacing-unit-x4);
    border-bottom: var(--standard-border);
  }

  &__helper {
    margin-top: var(--spacing-unit);
  }

  &__submit-button {
    &.button {
      height: var(--spacing-unit-x6);
    }

    &-submitting.button {
      padding-top: 0;
      padding-left: var(--spacing-unit-x6);
      padding-right: var(--spacing-unit-x6);
    }

    &-svg.svg {
      height: var(--spacing-unit-x4);
      width: var(--spacing-unit-x4);
      color: var(--white);
    }

    &-loader {
      margin-bottom: var(--half-spacing-unit);
    }
  }
}
