.field-replacement {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x3);
  
  &__arrow {
    align-self: center;
    fill: var(--gray-icon);
    transform: rotate(90deg);
  }
  
  &__current-field {
    background-color: var(--selectable-row-hover);
    border: var(--standard-border-darker);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  }
  
  &__value-container {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit-x16) / 12);
  }

  &__current-field-container {
    background-color: var(--input-background);
    border-radius: var(--standard-border-radius);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    padding: calc(var(--spacing-unit-x18) / 12) var(--spacing-unit-x2);
  }

}