.date-range-picker {
  position: relative;

  &__panel-footer {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    display: flex;
    justify-content: space-between;

    &-reset-button {
      padding-left: var(--spacing-unit);

      &:hover {
        color: var(--hover-teal) !important;
      }
    }
  }

  /* Dropdown panel */

  &__panel {
    .ant-picker {
      &-cell {
        padding: calc(var(--spacing-unit) / 3) 0;

        &:not(.ant-picker-cell-disabled, .ant-picker-cell-range-start, .ant-picker-cell-range-end) {
          &:hover {
            .ant-picker-cell-inner {
              color: var(--text-teal) !important;
              background: none !important;

              &::before {
                position: absolute;
                top: 0;
                inset-inline-end: 0;
                bottom: 0;
                inset-inline-start: 0;
                z-index: -1;
                border: var(--standard-border) !important;
                border-color: var(--text-teal) !important;
                background: var(--white);
                content: '';
                display: unset;
              }

              &::after {
                content: none;
              }
            }
          }
        }

        &-disabled {
          &::before {
            content: none;
          }
        }

        &::before {
          transition: none;
        }

        .ant-picker-cell-inner {
          transition: none;
        }
      }

      &-presets {
        ul {
          padding: var(--spacing-unit-x3) calc(var(--spacing-unit-x9) / 2) var(--spacing-unit-x2) var(--spacing-unit-x2) !important;

          li {
            color: var(--text-teal);
            font-size: var(--default-font-size);
            line-height: var(--line-height-smallish);
            font-weight: var(--font-weight-medium);
            padding: var(--spacing-unit-x2) !important;

            &:hover {
              background: transparent !important;
              color: var(--darker-teal);
            }
          }
        }

        li + li {
          margin-top: 0 !important;
        }
      }

      &-cell-today {
        .ant-picker-cell-inner {
          &::before {
            display: none;
          }
        }
      }

      &-cell-range-hover,
      &-cell-range-hover-start,
      &-cell-range-hover-end {
        &::before,
        &::after {
          border: none !important;
        }

        .ant-picker-cell-inner {
          &::after {
            border: none !important;
          }
        }
      }

      &-cell-range-hover-start,
      &-cell-range-hover-end {
        &::before {
          width: 50%;
        }

        &::after {
          content: none !important;
        }

        .ant-picker-cell-inner {
          &::after {
            content: none !important;
          }
        }
      }

      &-cell-range-hover-start {
        &::before {
          left: 50%;
        }
      }

      &-header {
        padding: var(--spacing-unit-x3) 0 0 0;

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: var(--spacing-unit-x6);
          width: var(--spacing-unit-x6);
          color: var(--text-gray);

          &:hover {
            color: var(--text-teal);
          }
        }

        &-view {
          line-height: var(--spacing-unit-x6);

          button {
            line-height: var(--spacing-unit-x6);

            &:hover {
              color: var(--text-teal);
              text-decoration: unset;
            }
          }
        }
      }

      &-date-panel {
        padding: 0 var(--spacing-unit-x4);
        width: unset;

        .ant-picker-body {
          padding: var(--spacing-unit-x2) 0 var(--spacing-unit);
        }

        .ant-picker-header {
          border-bottom: none;
        }

        .ant-picker-cell {

          /* Date from other months */
          &:not(.ant-picker-cell-in-view) {
            visibility: hidden;

            .ant-picker-cell-inner {
              display: none;
            }
          }

          /* Date cell borders */
          .ant-picker-cell-inner {
            border-start-start-radius: 50% !important;
            border-end-start-radius: 50% !important;
            border-start-end-radius: 50% !important;
            border-end-end-radius: 50% !important;
          }

          &:not(.ant-picker-cell-disabled, .ant-picker-cell-range-start, .ant-picker-cell-range-end) {
            &:hover {
              .ant-picker-cell-inner {
                &::before {
                  border-radius: 50% !important;
                }
              }
            }
          }

          &-range-hover,
          &-range-hover-start,
          &-range-hover-end {
            &::before,
            &::after {
              background: var(--lighter-teal) !important;
            }

            .ant-picker-cell-inner {
              &::after {
                background: var(--lighter-teal) !important;
              }
            }
          }
        }

        /* WeekDay text color */
        thead {
          th {
            color: var(--light-gray);
          }
        }
      }

      &-month-panel,
      &-year-panel {
        width: var(--month-year-picker-panel-width);

        .ant-picker-header {
          padding: var(--spacing-unit-x3) var(--spacing-unit-x4) 0;
        }

        .ant-picker-body {
          padding: 0;
        }

        .ant-picker-cell {
          &:not(.ant-picker-cell-disabled, .ant-picker-cell-range-start, .ant-picker-cell-range-end) {
            &:hover {
              .ant-picker-cell-inner {
                &::before {
                  border-radius: var(--standard-border-radius) !important;
                }
              }
            }
          }

          &-range-hover,
          &-range-hover-start,
          &-range-hover-end {
            &::before,
            &::after {
              background: none !important;
            }

            .ant-picker-cell-inner {
              &::after {
                background: none !important;
              }
            }
          }
        }
      }
    }

    &-highlight-today {
      .ant-picker {
        &-cell-today {
          .ant-picker-cell-inner {
            font-weight: var(--font-weight-medium);
          }

          &:not(.ant-picker-cell-range-end) {
            .ant-picker-cell-inner {
              color: var(--text-teal) !important;

              &::before {
                display: unset;
                background: var(--lighter-teal) !important;
                border: none !important;
                border-radius: 50% !important;
                z-index: -1 !important;
              }
            }
          }
        }
      }
    }

    &-active-preset-1 ul li:nth-child(1),
    &-active-preset-2 ul li:nth-child(2),
    &-active-preset-3 ul li:nth-child(3) {
      background: var(--lighter-teal);

      &:hover {
        background: var(--lighter-teal) !important;
      }
    }
  }

  .ant-picker {
    &-focused {
      border-color: var(--border-gray);
      box-shadow: none;

      .ant-picker-input {
        &-active {
          &::after {
            content: '';
            box-shadow: var(--date-picker-input-shadow);
          }
        }
      }

      .ant-picker-suffix {
        svg {
          fill: var(--text-teal);
        }
      }
    }

    &-range {
      padding: 0;
      pointer-events: none;

      &:hover {
        border-color: var(--border-gray);
      }

      &-separator {
        display: flex;
        padding: 0 calc(var(--spacing-unit-x2) / 3);
      }
    }

    &-input {
      pointer-events: auto;

      input {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: calc(var(--spacing-unit-x4) / 3) var(--spacing-unit-x2);
        color: var(--text-gray);

        &:hover {
          cursor: pointer;
        }
      }

      &::after {
        position: absolute;
        top: calc(-1 * var(--standard-border-width));
        left: calc(-1 * var(--standard-border-width));
        width: calc(100% + 2 * var(--standard-border-width));
        height: calc(100% + 2 * var(--standard-border-width));
        border: var(--standard-border);
        border-color: var(--dark-blue);
        border-radius: var(--standard-border-radius);
        pointer-events: none;
        transition: all 0.3s ease-out;
      }

      &:hover {
        &::after {
          content: '';
        }
      }

      &:not(:first-child) {
        input {
          padding-right: var(--spacing-unit-x5);
          width: var(--spacing-unit-x25);
        }
      }
    }

    &-suffix {
      padding-right: var(--spacing-unit-x2);
      margin-inline-start: 0;
      position: absolute;
      right: 0;

      svg {
        fill: var(--button-gray);
      }
    }

    &-active-bar {
      display: none;
    }
  }
}
