:root {
  --linear-80: background 80ms linear;
  --linear-120: 120ms linear;
  --expand-timing: 180ms;
  --ease-out-all-180: all var(--expand-timing) ease-out;
  --ease-all-slow: all 0.5s ease;
  --ease-in-out-300: all 300ms ease-in-out;
  --slide-up-accordion-fade: slideUpAccordionFade 200ms ease-in;
  --slide-down-accordion-fade: slideDownAccordionFade 200ms ease-in;
  --slide-up-accordion: slideUpAccordion 200ms ease-in;
  --slide-down-accordion: slideDownAccordion 200ms ease-in;

  @keyframes slideDownAccordion {
    from {
      height: 0;
      overflow: hidden;
    }

    to {
      height: var(--radix-accordion-content-height);
      overflow: hidden;
    }
  }

  @keyframes slideUpAccordion {
    from {
      height: var(--radix-accordion-content-height);
      overflow: hidden;
    }

    to {
      height: 0;
      overflow: hidden;
    }
  }

  @keyframes slideDownAccordionFade {
    from {
      height: 0;
      opacity: 0;
    }

    to {
      height: var(--radix-accordion-content-height);
      opacity: 1;
    }
  }

  @keyframes slideUpAccordionFade {
    from {
      height: var(--radix-accordion-content-height);
      opacity: 1;
    }

    to {
      height: 0;
      opacity: 0;
    }
  }
}
