.percentage-actions {
  &__button {
    width: 100%;

    & .toggle {
      margin-bottom: 0.2rem;
    }
  }

  &__action {
    text-align: right;
  }
}