.review-current-step {
  display: flex;
  gap: var(--spacing-unit-x3);
  flex-direction: column;
  
  &__step-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
  }

  .box {
    padding-left: var(--spacing-unit-x2);
    padding-right: var(--spacing-unit-x2);
  }
}