.landing-page-properties {
  &__headline {
    margin-top: 0;
  }

  &__block {
    margin-bottom: var(--spacing-unit-x4);

    &-label {
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__button-svg {
    margin-right: 0.833rem;
  }
}
