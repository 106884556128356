
.initial-message-sending {
  max-width: 50rem;

  &__title {
    width: 100%;
  }

  &__body-first {
    margin-bottom: var(--spacing-unit-x4);
  }

  .modal-body {
    white-space: break-spaces;
  }
}
