
.content-warning {
  box-sizing: border-box;
  border: var(--standard-border);
  border-radius: var(--thick-border-width);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--standard-border);
    height: 3.917rem;
    padding: 0 var(--spacing-unit-x4);

    &-element {
      max-width: calc(var(--spacing-unit-x20) * 2);
    }
  }

  &__message {
    &-info {
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
      background-color: var(--light-theme-background-darker);
    }

    &-label {
      margin: var(--spacing-unit) var(--spacing-unit-x4);
    }
  }

  &__section {
    margin: 0 var(--spacing-unit-x4);

    &-row {
      display: flex;
      align-items: flex-start;

      &-link {
        margin-left: var(--spacing-unit);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__icon {
    overflow: unset;
    fill: var(--yellow-caution);

    &-container {
      height: var(--line-height-large);
      display: flex;
      align-items: center;
    }
  }

  &__pill {
    background-color: var(--gray-icon);

    &-running {
      background-color: var(--pulse-green);
    }

    &-stopped {
      color: var(--text-gray);
      background-color: var(--border-failed);
    }
  }
}
