.company__info-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  column-gap: var(--spacing-unit-x4);

  & + & {
    border-top: var(--standard-border);
    margin: var(--spacing-unit-x4) 0;
    padding-top: var(--spacing-unit-x4);
  }

  &-required {
    margin-left: var(--half-spacing-unit);
    font-weight: var(--font-weight-medium-light);
  }

  &-title {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__edit-button {
    height: var(--spacing-unit-x5);
  }

  &-description {
    margin-bottom: var(--spacing-unit-x4);
  }
}
