
@keyframes growIn {
  from {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
  }

  to {
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x2);
    padding-top: var(--spacing-unit-x2);
    padding-bottom: var(--spacing-unit-x2);
    max-height: var(--spacing-unit-x20);
  }
}

.info-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--standard-border-radius);
  margin: var(--spacing-unit-x2) 0;
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  background-color: var(--lighter-teal);
  border: var(--standard-border-width) solid var(--border-teal);
  max-height: var(--spacing-unit-x20);
  overflow: hidden;

  &--wrapper {
    display: flex;
    align-items: center;

    &-buttons {
      flex: 1 1 27rem;
      justify-content: end;
    }
  }

  &__svg {
    margin: 0.3rem 1rem 0 0;

    .svg {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
    }
  }

  &--multiline {
    align-items: flex-start;

    .info-action__svg {
      margin-top: 0;
    }
  }

  &--animate-down {
    animation: growIn 0.5s;
  }

  &--secondary {
    color: var(--text-teal);
    margin-left: var(--spacing-unit-x2);
    white-space: nowrap;

    &:hover {
      background-color: var(--teal-secondary-alert);
    }
  }

  &--banner {
    border: none;
    border-radius: 0;
    max-width: var(--positioning-max-width);
    min-width: calc(var(--positioning-min-width) + var(--spacing-unit-x6) + var(--half-spacing-unit));
    margin: 0 0 var(--spacing-unit) 0;
    padding-top: calc(var(--spacing-unit) + var(--half-spacing-unit));
    padding-right: 0;
    padding-bottom: calc(var(--spacing-unit) + var(--half-spacing-unit));
    padding-left: var(--spacing-unit-x4);

    & .info-action__svg {
      margin: 0 1rem 0 0;
    }

    & .info-action--wrapper-buttons {
      flex: 1 1 25rem;
      justify-content: end;
    }
  }
}
