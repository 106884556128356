.list-page-table-container {
  &__tags {
    img {
      height: var(--spacing-unit-x10);
      width: var(--spacing-unit-x16);
    }
  }

  &__no-folders {
    img {
      height: var(--spacing-unit-x10);
      width: var(--spacing-unit-x16);
    }
  }

  &__search-path {
    display: inline-block;
    align-items: center;
    align-self: center;
    width: 100%;
  }

  &__search-name {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);

    &-clickable {
      cursor: pointer;
    }

    .svg {
      margin-right: 0.66rem;
    }

    &-typography {
      display: table;
      width: 100%;
      table-layout: fixed;
      text-align: left;
    }
  }

  &__secondary {
    display: flex;
    justify-content: flex-start;
    margin-top: 0.166666666rem;
  }

  &__menu {
    position: sticky;
    top: var(--spacing-unit-x4);
    max-height: calc(100vh - 25.5rem);
    min-width: 22.5rem;
    height: auto;
    z-index: 5;
  }

  & .collapsible-menu-item-hover-menu {
    .collapsible-menu__svg-folder-closed {
      fill: var(--text-gray);
    }

    .collapsible-menu-item-with-header__header {
      padding-bottom: 0;
    }

    .sortable-folders {
      padding: var(--spacing-unit) 0 var(--spacing-unit) 0;
      min-width: 25rem;
    }

    .tags-list {
      width: 25rem;
    }
  }

  &__favorite-svg-icon {
    fill: var(--teal) !important;
  }

  &__infohover {
    &-segment > div {
      display: flex;
      gap: 0.86rem;
      max-width: 100%;
    }

    &-icon .info-hover-card > a {
      align-items: center;
      display: flex;
    }
  }

  &__toast {
    max-width: 44rem;
    display: flex;
    flex-wrap: wrap;
    white-space: break-spaces;
  }

  &__name-col {
    width: 100%;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
