.analytics-upsell-info-hover {
  &__icon-container {
    display: inline-flex;
  }

  &__action {
    margin-top: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x3);
    width: 100%;
  }

  &__learn {
    display: flex;
    justify-content: center;
  }
}
