.landing-page-manager-details {
  display: flex;
  gap: var(--spacing-unit-x4);
  margin-bottom: var(--spacing-unit-x4);

  &__container {
    &-left {
      flex: 2;
    }

    &-right {
      flex: 0 0 23rem;
    }
  }

  &__headline {
    margin-bottom: var(--spacing-unit-x3);
  }

  &__embedded-forms {
    padding: 0;

    &-header {
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4) var(--spacing-unit-x3);
    }
  }

  &__wrapper {
    .position-container__children {
      margin-bottom: var(--spacing-unit-x4);
    }
  }

  &__caution {
    margin-bottom: var(--spacing-unit-x4);
  }
}
