.input-with-status {
  position: relative;

  .input {
    padding-left: var(--spacing-unit-x2);
    padding-right: var(--spacing-unit-x7);
  }

  &__input {
    &__container {
      width: 100%;

      .input__icon {
        right: 1.25rem;
        left: unset;
        pointer-events: none;
      }
    }

    &-error-message {
      text-align: left;
      margin-top: var(--spacing-unit);
    }
  }

  &__loader {
    height: var(--spacing-unit-x3);
    width: var(--spacing-unit-x3);
    position: absolute;
    right: 1.25rem;
    left: unset;
    top: 50%;
    transform: translateY(-50%);
  }

  &__tooltip {
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__err-msg {
    margin-top: var(--spacing-unit);
  }
}
