.email-composer {
  &__page {
    background-color: var(--page-back);
    min-width: var(--positioning-min-width);
  }

  &__mobile-review {
    overflow: hidden;
    height: 100vh;
  }


  &__tabs {
    background-color: var(--page-back);

    &-upload {
      height: unset;

      &__list {
        border-top: var(--standard-border);
        border-bottom: var(--standard-border);
        padding-left: var(--spacing-unit-x4);
        padding-right: var(--spacing-unit-x4);
        background-color: var(--hover-row-background-teal);
        position: sticky;
        top: var(--position-sticky-header-composer);
        z-index: var(--z-index-composer-tablist);
      }
    }

    &__review-pill {
      background: var(--remove-red);
      color: var(--white) !important;
      margin: 0 calc(var(--spacing-unit) + var(--half-spacing-unit));
      padding: var(--half-spacing-unit) calc(var(--spacing-unit) + var(--half-spacing-unit));
    }

    &__list {
      border-top: var(--standard-border);
      border-bottom: var(--standard-border);
      padding-left: var(--spacing-unit-x4);
      padding-right: var(--spacing-unit-x4);
      background-color: var(--hover-row-background-teal);
      position: sticky;
      top: var(--position-sticky-header-composer);
      z-index: var(--z-index-composer-tablist);
    }

    &__customer-care {
      &__list {
        border-top: var(--standard-border);
        border-bottom: var(--standard-border);
        padding-left: var(--spacing-unit-x4);
        padding-right: var(--spacing-unit-x4);
        background-color: var(--hover-row-background-teal);
        position: sticky;
        z-index: var(--z-index-composer-tablist);
        top: var(--position-sticky-custom-care-header);
      }
    }

    .tabs-ao {
      &__list-with-content {
        &__triggers {
          padding-top: var(--spacing-unit-x4);
          display: flex;
        }

        &__content {
          display: flex;
          gap: var(--spacing-unit-x2);

          .button {
            padding: var(--spacing-unit) var(--spacing-unit-x3);
          }
        }
      }

      &__panel {
        margin-top: 0;
      }
    }
  }

  &__header-button {
    height: var(--spacing-unit-x6);
  }

  /* 12rem ~= the height of the header and tabs */
  &__composer__editor {
    height: calc(100vh - 12rem);
    width: 100%;

    iframe {
      display: block;
    }
  }

  &__hide-editor {
    opacity: 0;
  }
}

@media (max-width: 72.25rem) {
  .email-composer {
    &__tabs {
      &__customer-care {
        &__list {
          top: var(--position-sticky-custom-care-header-small);
        }
      }
    }
  }
}
