.program-selected {
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-unit) + var(--half-spacing-unit));
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  width: 39.75rem;

  &__centered {
    justify-content: center;
    padding: var(--spacing-unit-x4);
  }

  &__status {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: calc(var(--spacing-unit-x8) / 12);
  }

  &__circle {
    width: calc(var(--spacing-unit-x18) / 12);
    height: calc(var(--spacing-unit-x18) / 12);
    border-radius: 50%;
    display: inline-block;

    &-running {
      background-color: var(--success-green);
    }

    &-stopped {
      background-color: var(--border-failed);
    }
  }
}