.switch-buttons {
  display: flex;

  &__item {
    cursor: pointer;
    border-radius: var(--standard-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    border: var(--standard-border-dark);

    & + & {
      margin-left: var(--spacing-unit-x2);
    }

    &-icon.svg {
      display: block;
      fill: var(--button-gray);
      border-radius: var(--standard-border-radius);
      height: var(--spacing-unit-x3);
      width: var(--spacing-unit-x3);
    }

    &:hover:not(&-active) {
      .svg {
        fill: var(--light-gray);
      }
    }

    &-active {
      background-color: var(--lighter-teal);
      border-color: var(--teal);

      .svg {
        fill: var(--teal);
        background-color: var(--lighter-teal);
      }

      &:hover {
        background-color: var(--lighter-teal);
        border-color: var(--hover-teal);

        .svg {
          fill: var(--hover-teal);
        }
      }
    }
  }
}
