.contact-segments {
  &__header-button {
    display: flex;
    gap: var(--spacing-unit-x2);
  }

  &__caution {
    display: flex;
   
    .caution__icon {
      flex: none;
    }
    
    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
     
    &-text {
      margin-right: var(--spacing-unit-x2);
      max-width: 90rem;
    }
     
    &-buttons {
      display: flex;
      align-items: center;
      flex: none;
    }
  }
  
  &__page-content {
    min-width: var(--positioning-min-width);
    padding: var(--spacing-unit-x4);
    padding-top: var(--spacing-unit-x5);

    .actionable-nested-table {
      border-top: none;
    }

    .actionable-nested-table__thead {
      border-top: var(--standard-border);
    }
  }

  &__new-segment-button[disabled] {
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
    }
  }

  &__import-data-button {
    margin-left: var(--spacing-unit);
    white-space: nowrap;
  }

  &__main {
    display: flex;
    gap: var(--spacing-unit-x2);
  }

  &__contacts-table {
    .table__th,
    .table__td {
      white-space: nowrap;
    }

  }

  &__no-folders {
    img {
      height: 5rem;
      width: 8rem;
    }
  }

  &__menu {
    position: sticky;
    top: var(--spacing-unit-x4);
    z-index: var(--z-index-menu);

    .collapsible-menu-item-hover-menu {
      .sortable-folders {
        padding: var(--spacing-unit) 0;
        width: 25rem;
      }

      .tags-list {
        width: 25rem;
      }
    }
  }

  &__favorite-svg-icon {
    fill: var(--teal) !important;
  }

  &__drop-down {
    &-open {
      background-color: var(--off-white-hover);

      .drop-down__drop-down {
        z-index: var( --z-index-999999);
      }
    }
  }

  &__scroll-area {
    height: calc(100% - calc(var(--spacing-unit-x25) / 3));

    &__viewport {
      height: 100%;
    }
  }

  &__search-path {
    display: inline-block;
    align-items: center;
    align-self: center;
    width: 100%;
  }

  &__search-name {
    display: flex;
    align-items: center;
    gap: 0.66rem;

    &-typography {
      display: table;
      width: 100%;
      table-layout: fixed;

      > div {
        display: inline-block;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  &__infohover {
    &-segment {
      overflow: hidden;

      > div {
        display: flex;
        gap: 0.86rem;
        max-width: 100%;
      }

      &-name > div {
        white-space: pre;
      }
    }

    &-icon .info-hover-card > a {
      align-items: center;
      display: flex;
    }
  }

  &__toast {
    max-width: 44rem;
    display: flex;
    flex-wrap: wrap;
    white-space: break-spaces;
  }

  .drop-down {
    padding-right: var(--spacing-unit-x2);
  }

  &__toast-folder-move {
    margin-right: var(--half-spacing-unit);
  }

  &__last-updated {
    left: 70%;
    position: absolute;
    top: var(--chart-extra-element-top);
    z-index: var(--z-index-chart-extra);
    padding-top: var(--spacing-unit);
  }

  &__more-filters {
    padding: 0 var(--spacing-unit-x4);

    .radio__container {
      height: calc((var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2)) * 2);
      margin: 0;
    }
  }

  &__header {
    gap: var(--spacing-unit-x2);
    padding: 0 var(--spacing-unit-x4);
  }

  &__add-button {
    height: var(--spacing-unit-x4);
    width: var(--spacing-unit-x4);
  }

  &__crm-sourced-sync-toast-text {
    width: calc(322rem / 12);
  }
  
  &__copy-segment-banner {
    &-trigger {
      margin-bottom: calc(0rem - var(--spacing-unit-x2)) !important;
    }
  }

  &__empty-state {
    margin-top: var(--spacing-unit-x2);

    &-bullets {
      margin: var(--spacing-unit-x2);
      display: flex;
      flex-direction: column;
      row-gap: var(--spacing-unit);
    }

    .text-link {
      margin-left: var(--spacing-unit-x2);
    }
  }
}
