.select-with-sub-content {
  display: block;

  &__container {
    margin-top: var(--spacing-unit-x3);
    height: inherit;
    position: relative;
    padding-left: calc(var(--spacing-unit-x8) / 3);

    &::before {
      position: absolute;
      content: '';
      width: calc(var(--spacing-unit-x2) / 3);
      height: 100%;
      border-radius: var(--standard-border-radius);
      left: 0;
      top: 0;
    }

    &--active {
      &::before {
        background-color: var(--teal);
      }
    }

    &--warning {
      &::before {
        background-color: var(--error);
      }
    }

    &--caution {
      &::before {
        background-color: var(--yellow-caution);
      }
    }
  }
}
