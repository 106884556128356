.email-composer-preview-dynamic-content-banner {
  display: flex;
  justify-content: center;
  width: 100%;
  background: var(--hover-row-background-teal);
  padding: var(--spacing-unit-x2);
  gap: var(--spacing-unit);
  text-overflow: ellipsis;
  white-space: nowrap;

  &__content-size {
    position: absolute;
    visibility: hidden;
    display: flex;
    width: auto;
    gap: var(--spacing-unit);

    &__item {
      display: flex;
      gap: var(--spacing-unit);
    }
  }

  &__previewing-as-label {
    display: flex;
    width: auto;
    gap: var(--spacing-unit);
    padding-right: var(--half-spacing-unit);
    white-space: nowrap;
  }

  &__content-wrapper {
    display: flex;
    width: auto;
    gap: var(--spacing-unit);

    &__item {
      display: flex;
      gap: var(--spacing-unit);
    }

    &__icon {
      background: var(--hover-row-button-background-teal);
      padding: var(--half-spacing-unit);
      border-radius: var(--standard-border-radius);
    }
  }

}
