.floating-action-header {
  align-items: center;
  background: var(--dark-blue);
  border-radius: var(--standard-border-radius);
  display: flex;
  height: var(--spacing-unit-x10);
  padding: var(--spacing-unit-x4);
  justify-content: space-between;
  margin-bottom: var(--spacing-unit-x4);
  position: sticky;
  top: 0;
  z-index: var(--z-index-9999);

  &__total-count {
    display: flex;
    gap: var(--spacing-unit-x2);
  }

  &__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__actions {
    display: flex;

    &-button {
      &-disabled {
        cursor: not-allowed;
      }
      
      &:hover {
        background-color: var(--blue);
      }
    }

    &-icon {
      color: var(--white);

      &-disabled {
        color: var(--button-gray);
        cursor: not-allowed;
      }
    }
  }
}
