.export-contacts {
  &__header {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__dropdown-top {
    margin-left: var(--spacing-unit-x2);
  }

  &__progress-bar {
    padding: 0 var(--spacing-unit-x23) var(--spacing-unit-x6);
  }
  /* stylelint-disable */
  @media (max-width: 1000px) {
    .position-container > .position-container__children {
      min-width: unset;
    }
  }
  /* stylelint-enable */
}
