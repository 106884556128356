.program-step-link {
  display: flex;
  justify-content: flex-end;

  .button {
    color: var(--text-teal);
    font-weight: var(--font-weight-medium);

    &:hover {
      color: var(--hover-teal);
    }
  }
}
