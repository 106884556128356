@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-circle {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -600;
  }
}

.loader {
  width: 5.333rem;
  height: 5.4rem;
  background-color: transparent;

  &-body {
    min-height: 9rem;
  }

  &--center {
    position: absolute;
    top: calc(50% - 2.66rem);
    left: calc(50% - 2.66rem);
    z-index: 100000;
  }

  &--white-background {
    background-color: var(--white);
  }

  &--blackout {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.4);
    width: 100vw;
    height: 100vh;
    z-index: 300000;
  }

  &--page {
    border-radius: 0.333rem;
    width: 9rem;
    height: 8rem;
    top: calc(50% - 4.27rem);
    left: calc(50% - 4.57rem);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: var(--spacing-unit-x6);
      animation: loading 3s linear infinite;

      circle {
        animation: loading-circle 2s linear infinite;
        stroke: var(--teal);
        fill: transparent;
        stroke-dashoffset: 0;
        stroke-width: 0.5rem;
        stroke-dasharray: 300;
        stroke-miterlimit: 12;
      }
    }
  }

  &--row {
    width: var(--spacing-unit-x3);
    height: var(--spacing-unit-x2);

    svg {
      width: var(--spacing-unit-x3);
      height: var(--spacing-unit-x2);
      display: inline-block;

      circle {
        animation: none;
      }
    }
  }
}
