
.sms-summary-report {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-unit-x4);
  width: 58.5rem;

  &__chart-container-base {
    border-top: var(--standard-border-width) solid var(--border-sent);
    border-radius: 0 0 var(--standard-border-radius) var(--standard-border-radius);
    margin-bottom: var(--spacing-unit-x2);
  }

  &__table-holder {
    min-width: 53rem;
    max-width: 58.5rem;
  }
}

