.email-composer-preview-iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;

  &__wrapper {
    width: 100%;
    height: 100%;

    &-plain {
      background-color: var(--white);
    }

    &--mobile-rotated {
      border-radius: 0 var(--spacing-unit-x5) var(--spacing-unit-x5) 0 !important;
    }

    &--mobile-inner {
      border-radius: 0 0 var(--spacing-unit-x5) var(--spacing-unit-x5);
      height: 100%;
    }

    .loader {
      display: none;
    }
  }

  &--mobile {
    border-radius: 0 0 var(--spacing-unit-x5) var(--spacing-unit-x5);
  }

  &--rotated {
    border-radius: 0 var(--spacing-unit-x5) var(--spacing-unit-x5) 0;
  }
}
