.upgrade-log-modal {
  width: 100rem;
  
  &__header {
    padding: var(--spacing-unit-x4);
  }

  &__body-content {
    padding: var(--spacing-unit-x4);
  
    &-title {
      padding-bottom: var(--spacing-unit-x4);
    }
  }

  &__table-column {
    &-button {
      height: var(--spacing-unit-x6);
      display: flex;
      flex-flow: row-reverse;

      .button {
        display: none;
        gap: var(--spacing-unit);
        padding: var(--spacing-unit) var(--spacing-unit-x3);
      }
    }

    &-result {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }
  }

  .tableV2-tbody__row:hover {
    .button {
      display: flex;
    }
  }
}
