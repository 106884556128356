.list-selector {
  display: flex;
  align-items: center;
  gap: var(--spacing-unit-x2);
  
  &__button {
    flex-shrink: 0;

    svg {
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
  
    &-text {
      max-width: 25rem;
    }
  }
}
