.edit-optout-step {
  width: 42rem;

  &__description {
    text-align: center;
    padding-top: var(--spacing-unit-x3);
    padding-bottom: var(--spacing-unit-x2);
    color: var(--light-gray);
    font-weight: var(--font-weight-medium-light);
    font-size: var(--default-font-size);
    line-height: var(--spacing-unit-x3);
  }
}
