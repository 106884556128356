@import '../../../../../libs/styles/index.css';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.app {
  &__right {
    position: relative;
    flex: 1 1 auto;
    overflow-x: auto;
  }

  &__top-bar-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: var(--overlay);
    opacity: 0.5;
    height: var(--top-bar-height);
    z-index: 20000;
  }

  &__navigation-cover {
    position: absolute;
    top: var(--top-bar-height);
    left: 0;
    width: calc(var(--nav-width) + var(--standard-border-width));
    background-color: var(--overlay);
    opacity: 0.5;
    height: 100vh;
    z-index: 20000;

    &--collapsed {
      width: calc(var(--nav-width-collapsed) + var(--standard-border-width));
    }
  }
}

.root {
  display: flex;
  flex-wrap: nowrap;
  font-size: 12px; /* stylelint-disable-line */
}

.feature-flag {
  display: block;
  position: absolute;
  bottom: 0;
  right: var(--spacing-unit);
  width: 3rem;
  height: 3rem;
  z-index: 1000;
  font-size: 3rem;
  line-height: 3rem;

  &:hover {
    text-decoration: none;
  }
}

.error {
  display: block;
  color: var(--red);
  padding: var(--spacing-unit) 0;
  width: 100%;
}
