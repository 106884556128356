.landing-pages-upload-modal {
  width: 50rem;

  &__container {
    height: 27rem;
  }

  &__info {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__drop-zone {
    height: 22rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--spacing-unit-x3);
    border: var(--thin-dashed-border);
    border-color: var(--light-gray);
  }

  &__file {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-unit-x5);
    height: var(--spacing-unit-x13);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x2);
    overflow: hidden;

    &-svg {
      position: relative;
      width: 4.16rem;
      height: var(--spacing-unit-x10);
      fill: var(--lighter-off-white);
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: calc(var(--spacing-unit) + var(--half-spacing-unit));
    }

    &-name {
      max-width: 30rem;

      &-wrapper {
        display: flex;
        align-items: center;
        gap: var(--spacing-unit-x2);
      }
    }

    &-remove {
      &-button {
        width: 1.33rem;
        height: 1.33rem;
        align-self: center;
        cursor: pointer;

        &:hover {
          background-color: unset;

          & > .svg {
            fill: var(--tab-gray);
          }
        }
      }

      &-icon {
        &.svg {
          fill: var(--tab-gray);
          height: 0.666rem;
          width: 0.666rem;
        }
      }
    }
  }
}
