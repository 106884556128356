.visitor-alerts {
  padding: 0;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-unit-x4) var(--spacing-unit-x4) 0 var(--spacing-unit-x4);

    &-search {
      display: flex;
      gap: var(--spacing-unit-x2);
    }
  }

  &__filters {
    display: flex;
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x4);
    border-bottom: var(--input-border-hover);

    &-clear {
      color: var(--text-teal);
      border: none;

      &:hover {
        background-color: var(--light-teal);
      }
    }
  }

  &__disabled {
    color: var(--disabled-alert-gray);

    &-sub {
      color: var(--disabled-alert-secondary-gray);
    }
  }
}
