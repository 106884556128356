.update-forms-modal {
  width: 50rem;
  
  &__name-with-icon {
    display: flex;
    gap: var(--spacing-unit-x2);
    align-items: center;
  
    .svg {
      flex-shrink: 0;
      width: calc(var(--spacing-unit-x8) / 3);
      height: calc(var(--spacing-unit-x8) / 3);
      color: var(--button-gray);
    }
  }
}
