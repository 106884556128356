
.empty-list-ctr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6rem;
  background: var(--empty-gray-background);
  border-radius: var(--standard-border-radius);

  &__picker {
    background: transparent;
    height: 8rem;
  }
}

.empty-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
