.invite-users-modal-header {
  &__back {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__add-multiple-button {
    padding: calc(var(--spacing-unit-x10) / 6) var(--spacing-unit-x2) !important;

    .svg {
      margin-bottom: 0;
    }
  }

  &__tooltip {
    .typography {
      white-space: pre-line !important;
    }
  }
}
