.labelV2 {
  display: block;

  &__required {
    color: var(--light-gray);
  }

  &__container {
    &-margin-bottom {
      margin-bottom: var(--spacing-unit);
    }

    &-with-flex {
      display: flex;
      align-items: center;
    }
  }

  &-with-flex {
    display: flex;
    align-items: center;
  }

  &__input {
    &-has-gap {
      gap: var(--half-spacing-unit);
    }

    &-children,
    &-required {
      line-height: 1.66667rem;
      font-weight: var(--font-weight-medium);
      font-size: var(--large-font-size);
    }

    &-required {
      font-weight: var(--font-weight-medium-light);
    }

    &-info-tooltip {
      &__trigger-wrapper {
        margin-left: 0.3333rem;
      }

      &-svg {
        width: 1.3333rem;
        height: 1.3333rem;
      }
    }
  }

  &__small {
    &-has-gap {
      gap: var(--half-spacing-unit);
    }

    &-icon {
      margin-right: var(--spacing-unit);
      width: var(--spacing-unit-x2);
      height: var(--spacing-unit-x2);
    }

    &-count,
    &-children,
    &-required {
      line-height: var(--spacing-unit-x2);
      font-weight: var(--font-weight-medium);
      font-size: var(--default-font-size);
    }

    &-required {
      font-weight: var(--font-weight-medium-light);
    }

    &-info-tooltip {
      &__trigger-wrapper {
        margin-left: var(--half-spacing-unit);
      }

      &-svg {
        width: var(--spacing-unit-x2);
        height: var(--spacing-unit-x2);
      }
    }
  }

  &__medium {
    &-has-gap {
      gap: var(--half-spacing-unit);
    }

    &-icon {
      margin-right: 0.6667rem;
      width: 1.33333rem;
      height: 1.33333rem;
    }

    &-count,
    &-children,
    &-required {
      line-height: 1.66667rem;
      font-weight: var(--font-weight-medium);
      font-size: var(--large-font-size);
    }

    &-required {
      font-weight: var(--font-weight-medium-light);
    }

    &-info-tooltip {
      &__trigger-wrapper {
        margin-left: 0.3333rem;
      }

      &-svg {
        width: 1.3333rem;
        height: 1.3333rem;
      }
    }
  }

  &__section {
    &-has-gap {
      gap: var(--spacing-unit);
    }

    &-icon {
      margin-right: 0.8333rem;
      width: 1.33333rem;
      height: 1.33333rem;
    }

    &-count,
    &-children,
    &-required {
      line-height: var(--spacing-unit-x4);
      font-weight: var(--font-weight-medium);
      font-size: var(--larger-font-size);
    }

    &-required {
      font-weight: var(--font-weight-medium-light);
    }

    &-info-tooltip {
      &__trigger-wrapper {
        margin-left: 0.3333rem;
      }

      &-svg {
        width: 1.3333rem;
        height: 1.3333rem;
      }
    }
  }

  &__title {
    &-has-gap {
      gap: var(--spacing-unit);
    }

    &-icon {
      margin-right: var(--spacing-unit-x2);
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
    }

    &-count,
    &-children,
    &-required {
      line-height: var(--spacing-unit-x6);
      font-weight: var(--font-weight-bold);
      font-size: var(--sub-header-font-size);
    }

    &-required {
      font-weight: var(--font-weight-medium-light);
    }

    &-info-tooltip {
      &__trigger-wrapper {
        margin-left: var(--spacing-unit);
      }

      &-svg {
        width: var(--spacing-unit-x4);
        height: var(--spacing-unit-x4);
      }
    }
  }

  &__standard {
    &-has-gap {
      gap: var(--spacing-unit);
    }

    &-icon {
      margin-right: 0.8333rem;
      width: 1.3333rem;
      height: 1.3333rem;
    }

    &-count,
    &-children,
    &-required {
      line-height: 2.1667rem;
      font-weight: var(--font-weight-medium);
      font-size: var(--sub-header-font-size);
    }

    &-required {
      font-weight: var(--font-weight-medium-light);
    }

    &-info-tooltip {
      &__trigger-wrapper {
        margin-left: 0.3333rem;
      }

      &-svg {
        width: 1.3333rem;
        height: 1.3333rem;
      }
    }
  }

  &__large {
    &-has-gap {
      gap: var(--spacing-unit);
    }

    &-icon {
      margin-right: var(--spacing-unit-x2);
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
    }

    &-count,
    &-children,
    &-required {
      line-height: var(--spacing-unit-x6);
      font-weight: var(--font-weight-medium);
      font-size: var(--page-header-font);
    }

    &-required {
      font-weight: var(--font-weight-medium-light);
    }

    &-info-tooltip {
      &__trigger-wrapper {
        margin-left: var(--spacing-unit);
      }

      &-svg {
        width: var(--spacing-unit-x4);
        height: var(--spacing-unit-x4);
      }
    }
  }

  .tooltip__trigger {
    display: flex;
  }

  &__info-tooltip-svg {
    &:hover {
      fill: var(--text-teal) !important;
    }
  }
}
