.preview-and-import-modal {
  width: 105rem;
  height: calc(100vh - var(--spacing-unit-x8));

  .modal {
    &__body {
      display: flex;
      padding: 0;
    }

    &-body {
      display: flex;
      padding: 0;
      overflow: hidden;
    }
  }

  &__header {
    &__back-button {
      &:focus-visible {
        box-shadow: var(--keyboard-focus-box-shadow);
      }
    }

    &__title {
      flex: 1 1 100%;
    }

    &__extra {
      margin-left: auto;

      & .input__container {
        width: 25rem;
      }
    }
  }

  &__left {
    width: 30rem;
    min-width: 30rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 50vh;

    &-no-footer {
      padding-bottom: 0;
    }
  }

  &__blurb {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    border-radius: var(--standard-border-radius);
    background-color: var(--input-background);
    margin: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }

  &__assets {
    display: flex;
    flex-direction: column;
    overflow: auto;

    &-primary,
    &-asset {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      &:hover {
        background-color: var(--hover-row-background-teal);
      }

      &:hover &-preview {
        display: flex;
        fill: var(--text-gray);
      }

      &-preview {
        display: none;
        margin-top: var(--half-spacing-unit);
        margin-right: var(--spacing-unit);

        &.svg {
          height: 1.33rem;
          width: 1.33rem;
          fill: var(--text-teal);
        }

        &-current {
          display: flex;
          fill: var(--text-teal);
        }
      }
    }

    &-primary {
      position: sticky;
      top: 0;
      padding: calc(var(--spacing-unit-x4) / 3) var(--spacing-unit-x3) calc(var(--spacing-unit-x4) / 3) var(--spacing-unit-x4);

      &-text {
        flex: 1;
        padding-left: var(--spacing-unit-x2);
      }

      &-title {
        flex: 1;

        &-container {
          padding: var(--spacing-unit);
        }
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    &-section {
      display: flex;
      align-items: center;
      gap: calc(var(--spacing-unit-x8) / 3);
      padding: var(--spacing-unit-x2) 0 var(--spacing-unit-x2) calc(var(--spacing-unit-x14) / 3);
      cursor: pointer;

      &-text {
        flex: 1;
      }

      &-expanded {
        padding-bottom: var(--spacing-unit);
      }
    }

    &-asset {
      padding: calc(var(--spacing-unit-x4) / 3) var(--spacing-unit-x3) calc(var(--spacing-unit-x4) / 3) var(--spacing-unit-x4);
      justify-content: space-between;

      &-selected {
        background-color: var(--hover-row-background-teal);
      }

      &-title-container {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &-pill {
        margin-left: auto;
      }

      &-text {
        &-untitled,
        &-trigger {
          flex: 1;
          max-width: 16rem;
        }

        &-wrapper {
          display: flex;
          align-items: center;
          width: 100%;
        }

        padding-left: var(--spacing-unit-x2);
        padding-right: var(--spacing-unit);
      }

      &-preview {
        right: var(--spacing-unit);
      }
    }
  }

  &__expander {
    fill: var(--light-gray);
  }

  &__divider {
    border-bottom: var(--standard-border);
    margin: 0 var(--spacing-unit-x3);
  }

  &__indicator {
    background-color: var(--off-white-focus);
    min-width: calc(var(--spacing-unit-x8) / 3);
    height: calc(var(--spacing-unit-x8) / 3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--standard-border-radius);
  }

  &__empty {
    &-listing {
      width: 100%;
    }
  }

  &__right {
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 0 var(--spacing-unit-x4);
    height: calc(100vh - 16.5rem);
    background-color: var(--modal-gray-background);
    overflow: auto;

    &-center {
      align-items: center;
    }

    &-no-footer {
      height: unset;
    }

    &-no-preview {
      padding: var(--spacing-unit-x4);
    }
  }

  &__preview {
    &-container {
      background-color: var(--white);
      width: 100%;
      height: 100%;
      flex: 1;
    }

    &-image {
      max-width: 100%;
      object-fit: contain;
    }
  }
}
