.customize {
  padding-top: var(--spacing-unit-x4);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--spacing-unit-x4);

    & .page-header {
      margin-bottom: 0;
      min-width: unset;
    }
  }

  &__section {
    background-color: var(--white);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x4);
  }

  &__button-reset {
    gap: var(--spacing-unit-x2);

    & > svg {
      fill: var(--light-theme-selected-item);
      width: 1.3rem;
      height: 1.3rem;
    }

    & > .typography {
      color: var(--light-theme-selected-item);
    }
  }

  &__alerts {
    padding-top: var(--spacing-unit-x4);
    max-width: 31rem;
  }

  &__img {
    height: auto;
    width: auto;
    max-height: var(--spacing-unit-x9);
    max-width: var(--spacing-unit-x24);
  }

  &__logo-container {
    display: flex;
    gap: var(--spacing-unit-x7);
  }

  &__input {
    width: 33rem;
  }

  &__info {
    flex-direction: column;

    & .typography {
      margin-bottom: var(--spacing-unit);
    }
  }

  &__logo {
    margin-top: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x4);
    flex-basis: 28.73%;

    &-body {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: var(--spacing-unit-x2);
      border-radius: var(--standard-border-radius);
      border: var(--dashed-border);
      max-width: var(--media-card-width);
      width: 100%;
      background-color: var(--input-background);
      overflow: hidden;
      height: var(--media-card-body-height);
      padding: var(--spacing-unit-x4);
      position: relative;

      &:focus {
        & .customize__logo-hover-content > .button {
          color: unset;
          background-color: var(--white);
        }
      }

      &-hover-visible {
        border: unset;
      }

      &-empty {
        padding: 0;

        .static-image {
          margin-bottom: 0;
        }
      }
    }

    &-hover {
      position: absolute;
      display: none;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: var(--media-card-background-hover);
      border-radius: var(--half-spacing-unit) var(--half-spacing-unit) 0 0;

      &-button {
        margin: 0 auto;
        color: var(--white);

        &:hover,
        &:focus {
          color: unset;
          background-color: var(--white);
        }
      }

      &-visible {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
