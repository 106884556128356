
.welcome-message {
  &__header,
  &__text-bubble {
    margin-bottom: var(--spacing-unit-x2);
  }

  .caution,
  &__small-text,
  &__text-info {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__text-bubble {
    width: 33rem;
  }

  .container__wrapper&__text-bubble {
    width: 35rem;
    padding-bottom: var(--spacing-unit);
  }

  &__buttons {
    display: inline;
    margin-right: var(--page-header-font);
    font-size: var(--large-font-size);

    &:hover:not(:disabled) {
      svg {
        fill: var(--text-gray);
      }
    }
  }
}
