.beta-baner-card {
  max-width: 46rem;
  display: flex;
  align-items: center;
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  overflow: hidden;
  margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x4);

  &__container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    padding: 0 var(--spacing-unit-x4);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);

    &-title {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x3);
  }

  .static-image {
    width: 15rem;
    height: 13rem;
  }
}
