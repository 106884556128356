.recommended-segments {
  .page-header {
    margin-bottom: var(--spacing-unit-x2);
    padding-left: unset;
  }

  &__results {
    margin-top: var(--spacing-unit-x6);
  }
   
  .segment-created-toast {
    max-width: 35rem;
  }
}
