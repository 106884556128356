
.tag-manager-trigger {
  align-items: center;
  outline: var(--input-border-hover);
  border-radius: var(--legend-border-radius);
  border: var(--input-border-hover);
  cursor: pointer;
  display: flex;
  gap: calc(var(--spacing-unit));
  height: var(--spacing-unit-x4);
  padding: var(--spacing-unit);
  width: fit-content;

  &:hover {
    background-color: var(--teal);
    outline: unset;
    border: var(--hover-teal-border);
    cursor: pointer;

    & .tag-manager-trigger__icon {
      filter: drop-shadow(var(--tag-text-shadow));
      fill: var(--white);
    }

    .typography {
      color: var(--white);
      text-shadow: var(--tag-text-shadow);
    }
  }

  &__icon {
    width: var(--spacing-unit-x2);
    height: var(--spacing-unit-x2);
    fill: var(--light-gray);
  }
}
