
.new-feature-toggle {
  flex: 0 0 11rem;
  margin-right: var(--spacing-unit-x15);
  display: flex;
  height: var(--top-bar-height);
  justify-content: flex-end;
  align-items: center;

  &__title {
    color: var(--gray);
    font-size: var(--large-font-size);
  }
}

@media (--breakpoint-sm) {
  .new-feature-toggle {
    display: none;
  }
}
