.plain-text-composer-footer {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  border-radius: 0 0 var(--standard-border-radius)  var(--standard-border-radius);
  border: none;
  border-top: var(--standard-border);

  .container__wrapper + &__wrapper {
    margin-top: 0;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__personalize-button .svg {
    margin-bottom: 0;
  }
}
