.list-page-table {
  width: 100%;

  &__header {
    padding: var(--spacing-unit-x3) 0 var(--spacing-unit-x2) var(--spacing-unit-x4);
    position: sticky;
    top: -0.0833rem;
    z-index: var(--z-index-logo);
    background-color: var(--page-back);
    display: flex;
    align-items: center;
    justify-content: center;

    &-search {
      margin-bottom: 0;
      width: 18rem;
    }

    &-folder-path {
      width: calc(100% - 18rem);
    }
  }

  &__search {
    &__list {
      &-empty {
        min-height: 30rem;
      }
    }
  }

  &__table {
    max-width: unset;

    .actionable-nested-table__thead-fixed {
      top: 5.416rem;
    }
  }
}
