.draggable-item {
  display: flex;
  align-items: center;
  user-select: none;

  &__over {
    box-shadow: 0 calc(var(--standard-border-width) * -2) 0 var(--teal) inset;

    &-top {
      box-shadow: 0 calc(var(--standard-border-width) * -2) 0 var(--teal);
    }
  }

  &__index {
    width: var(--spacing-unit-x6);
    text-align: right;
    margin-right: var(--spacing-unit-x2);
    flex-shrink: 0;
  }

  &__element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: grab;
    min-width: 27.5rem;
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    margin-right: var(--spacing-unit-x2);
    padding: var(--spacing-unit) 0;
    flex-grow: 1;

    &-remove {
      padding: 0 var(--spacing-unit);
      border-left: var(--standard-border);

      & svg {
        width: calc(10rem / 12) !important;
        height: calc(10rem / 12) !important;
      }

      & button {
        border-radius: var(--spacing-unit-x2);
        height: var(--spacing-unit-x4);
        width: var(--spacing-unit-x4);
      }
    }

    &-error {
      border: var(--program-step-border-error);
    }

    &-drag {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: var(--spacing-unit-x4);
      min-height: var(--spacing-unit-x4);

      &-icon {
        fill: var(--disabled-edit-columns-action);
      }
    }

    &-name {
      font-size: var(--large-font-size);
      font-weight: var(--font-weight-medium-light);
      margin-right: var(--spacing-unit-x2);
      margin-left: calc(8rem / 12);
      width: 100%;
      background-color: transparent;

      &,
      &:active,
      &:focus {
        border: none;
        outline: none;
      }

      &-readonly {
        cursor: default;
      }
    }

    &-dragged {
      background-color: var(--hover-row-background-teal);
      border-color: var(--border-teal);
    }
  }

  &:hover {
    .draggable-item__element:not(.draggable-item__element-locked):not(.draggable-item__element-error) {
      border-color: var(--border-teal);
    }

    .draggable-item__element-drag-icon {
      fill: var(--text-gray);
    }
  }

  &__error {
    margin: calc(-1 * (var(--spacing-unit) + var(--half-spacing-unit))) 0 var(--spacing-unit-x2) var(--spacing-unit-x8);
  }
}
