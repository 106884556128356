.collapsible-menu-item-hover-menu {
  border-bottom: var(--standard-border);
  display: flex;

  &:last-child {
    border-bottom: 0;

    .collapsible-menu__item-icon {
      border-bottom-right-radius: var(--standard-border-radius);
      border-bottom-left-radius: var(--standard-border-radius);
    }
  }

  &__content {
    background-color: var(--white);
    border-radius: 0 var(--standard-border-radius) var(--standard-border-radius) var(--standard-border-radius);
    box-shadow: var(--hover-menu-shadow);
    margin-left: var(--standard-border-width);
    max-width: 25rem;
    min-width: 25rem;
    position: absolute;
    z-index: 2;

    &-wrapper {
      position: relative;
    }
  }

  &__trigger {
    flex-grow: 1;
    z-index: 4;

    &-active svg {
      fill: var(--teal) !important;
      color: var(--teal) !important;
    }
  }
}
