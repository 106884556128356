
.tag-manager-applied-item {
  border: var(--teal-border);

  &:hover {
    background-color: var(--lighter-teal);
  }

  &__icon {
    fill: var(--teal);
    min-width: 0.917rem;
  }
}
