.filter {
  align-items: center;
  cursor: pointer;
  display: flex;
  width: 100%;
  text-align: left;
  gap: var(--spacing-unit-x2);
  height: var(--spacing-unit-x6);
  padding: 0 var(--spacing-unit-x4);

  &:hover {
    background-color: var(--hover-row-background-teal);
    border-radius: unset;
  }

  &__icon {
    height: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2)) !important;
    width: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2)) !important;

    &-active {
      fill: var(--teal) !important;
      color: var(--teal) !important;
    }

    .filter:hover & {
      fill: var(--text-gray);
      color: var(--text-gray);
    }
  }

  &__name {
    flex-grow: 1;

    &-container {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }

    &-lock {
      background: var(--border-gray);
      line-height: 0.75rem;
      border: var(--standard-border-radius) solid var(--border-gray);
      border-radius: 50%;
      box-sizing: border-box;
      display: grid;
      margin-left: 0.5rem;
    }
  }
}
