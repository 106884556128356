@keyframes loader-animation {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-animation {
  0% {
    transform: rotate(50deg);
    opacity: 0;
    stroke-dashoffset: 60;
  }

  100% {
    transform: rotate(230deg);
    opacity: 1;
    stroke-dashoffset: 50;
  }
}

.spinner {
  &__wrapper {
    width: 100%;
    height: 100%;
    padding: var(--spacing-unit-x4);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &-unsize {
      padding: 0;
      width: unset;
    }
  }

  &__svg {
    fill: none;
    animation: 1s ease-in-out 0s 1 normal forwards running spinner-animation;
    opacity: 0;

    &--container {
      animation: 0.84s cubic-bezier(0.4, 0.15, 0.6, 0.83) 0s infinite normal none running loader-animation;
      transform-origin: center center;
    }

    &--xsmall {
      max-width: var(--spacing-unit-x2);
      max-height: var(--spacing-unit-x2);
      min-width: var(--spacing-unit-x2);
      min-height: var(--spacing-unit-x2);
    }

    &--small {
      max-width: var(--spacing-unit-x3);
      max-height: var(--spacing-unit-x3);
      min-width: var(--spacing-unit-x3);
      min-height: var(--spacing-unit-x3);
    }

    &--medium {
      max-width: var(--spacing-unit-x4);
      max-height: var(--spacing-unit-x4);
      min-width: var(--spacing-unit-x4);
      min-height: var(--spacing-unit-x4);
    }

    &--large {
      max-width: var(--spacing-unit-x8);
      max-height: var(--spacing-unit-x8);
      min-width: var(--spacing-unit-x8);
      min-height: var(--spacing-unit-x8);
    }

    &--xlarge {
      max-width: var(--spacing-unit-x12);
      max-height: var(--spacing-unit-x12);
      min-width: var(--spacing-unit-x12);
      min-height: var(--spacing-unit-x12);
    }

    circle {
      fill: none;
      stroke-dasharray: 60;
      stroke-dashoffset: inherit;
      stroke-linecap: round;
      stroke-width: 1.5;
      stroke: var(--teal);
    }
  }

  &__headline {
    margin-top: var(--spacing-unit-x6);
    margin-bottom: var(--spacing-unit-x2);
  }

  &__text {
    color: var(--light-gray);
  }
}
