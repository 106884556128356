.time-picker-v2 {
  &__parent-modal {
    z-index: var(--z-index-99999);
  }

  &__label {
    gap: calc(var(--spacing-unit-x2) / 3);

    &-icon-wrapper {
      padding: calc(var(--spacing-unit-x2) / 3);
    }
  }

  & .ant-picker {
    height: var(--spacing-unit-x6);
  }

  &-picker {
    width: 25rem;
    padding: 0;

    &.ant-picker-focused {
      border: var(--standard-border-dark-blue);
      box-shadow: none;

      &__popup-open {
        box-shadow: var(--date-picker-input-shadow);

        .time-picker-v2__icon {
          &-trigger {
            pointer-events: none;
          }
        }
      }
    }

    &.ant-picker-status-error&.ant-picker-focused {
      box-shadow: none;
    }

    .ant-picker-input {
      >input {
        padding: 0 var(--spacing-unit-x2);
        padding-right: var(--spacing-unit);

        &:disabled {
          opacity: 0.5;
        }
      }

      .ant-picker-suffix {
        pointer-events: unset;
        margin-inline-start: 0;
      }

      .ant-picker-clear {
        border-radius: 50%;

        & svg {
          height: 2.85rem;
          width: 2.85rem;
        }
      }
    }
  }

  &__clock-icon,
  &__clear-icon {
    border: var(--spacing-unit) solid transparent;
    background-clip: padding-box;
    border-radius: 50%;

    &:hover {
      background-color: var(--chip-base);
    }

    &-trigger {
      display: flex;
    }
  }

  &__clock-icon {
    padding: calc(var(--spacing-unit-x2) / 3);
  }

  &__clear-icon {
    padding: calc(var(--spacing-unit-x7) / 6);
  }

  &__error-icon-container {
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--spacing-unit);
  }

  /* Panel */
  &-panel {
    .ant-picker-time-panel {
      --content-height: 20.75rem;
      width: 25rem;
      padding-top: 0 !important;

      .ant-picker-content {
        height: var(--content-height);
      }

      &-column {
        min-width: 25%;
        flex: auto;
        margin: var(--spacing-unit) 0;
        padding-left: var(--spacing-unit);
        scrollbar-gutter: stable;

        &::-webkit-scrollbar {
          width: calc(var(--spacing-unit) + var(--half-spacing-unit));
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border: var(--half-spacing-unit) solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          background-color: var(--dark-border-gray);
          border-radius: var(--large-border-radius);
        }

        &::after {
          height: calc(var(--content-height) - var(--spacing-unit-x2) - var(--spacing-unit-x6));
        }
      }

      &-cell {
        margin-inline: 0 !important;

        & + li {
          margin-top: var(--half-spacing-unit);
        }

        &-inner {
          display: flex !important;
          justify-content: center;
          align-items: center;
          line-height: var(--line-height-large) !important;
          height: var(--spacing-unit-x6) !important;
          width: unset !important;
          padding-inline-start: unset !important;

          &:hover {
            border: var(--hover-teal-border);
            border-color: var(--text-teal);
            color: var(--text-teal) !important;
            background: var(--white) !important;
          }
        }

        &-selected {
          .ant-picker-time-panel-cell-inner {
            background: var(--text-teal) !important;
            color: var(--white) !important;
            font-weight: var(--font-weight-medium);

            &:hover {
              border: none;
              background: var(--darker-teal) !important;
            }
          }
        }
      }
    }

    .ant-picker-ranges {
      display: none;
    }
  }

  &__footer {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
    display: flex;
    justify-content: flex-end;
  }
}
