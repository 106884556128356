
.checkboxgroup {
  display: block;

  &__container {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x3);

    &--vertical {
      align-items: flex-start;
      flex-direction: column;
      margin: var(--spacing-unit) 0 var(--half-spacing-unit) 0;
    }
  }
}
