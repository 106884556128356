.audience-center-tabbed-container {
  &__error-banner {
    &-displayed {
      margin-top: 0;
    }
  }

  .tabs-ao__list.listing-page-with-tabs__tab-headers__list {
    z-index: var(--z-index-1);
  }
}