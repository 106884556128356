.auto-resize-textarea {
  background-color: var(--input-background);
  margin: 0;
  width: 100%;
  border-radius: 0.25rem;
  color: var(--text-gray);
  font-size: var(--large-font-size);
  line-height: var(--line-height-large);
  padding: var(--spacing-unit-x2);
  border: var(--input-border);
  transition: var(--linear-80);
  overflow: hidden;
  resize: none;
  margin-bottom: var(--spacing-unit-x2);

  &:hover,
  &:focus {
    background-color: var(--white);
  }

  &:hover {
    border: var(--input-border-hover);
  }

  &:focus {
    border-color: var(--dark-blue);
    outline: var(--input-outline-border);
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
  }

  &__error {
    border: var(--input-border-error);

    &:hover {
      border: var(--input-border-error);
    }

    &:focus {
      border: var(--input-border-error);
      box-shadow: var(--autoresize-textarea-box-shadow);
      outline: none;
    }
  }
}
