.refresh-preview-modal {
  .modal-body {
    width: calc(var(--spacing-unit-x20) * 4);
    padding: var(--spacing-unit-x4);
    padding-top: var(--spacing-unit-x6);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-unit-x6);
  }

  &__text {
    color: var(--light-gray);
  }

  &__button {
    gap: calc(var(--spacing-unit-x6) / 3);
  }
}
