.schedule-options {
  &__options {
    > div:first-child {
      margin-bottom: var(--spacing-unit-x2);
    }

    &-container {
      display: flex;
      gap: var(--spacing-unit-x2);
    }
  }

  &__note {
    margin-top: var(--spacing-unit-x2);
  }
}
