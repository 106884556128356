.info-tooltip {
  &__container {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .tooltip__trigger {
      &:focus {
        outline: var(--focus-light-teal) solid var(--thick-border-width);
        border-radius: 100%;
      }
    }
  }

  &__small {
    width: var(--spacing-unit-x3);
    height: var(--spacing-unit-x3);
  }

  &__medium {
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);
  }

  &__large {
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
  }

  &__svg {
    fill: var(--light-gray);
  }
}
