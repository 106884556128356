.info-hover-card {
  &__content {
    max-height: 31rem;
    width: 28rem;
  }

  &__scroll {
    & .scroll-area__viewport {
      max-height: 24rem;
    }
  }
}

.landing-pages-info-hover {
  &__children {
    padding: 0;
  }

  &__name {
    width: 24rem;
    margin-bottom: var(--spacing-unit-x2);
  }

  &__scroll {
    height: var(--spacing-unit-x28);
  }

  &__body,
  &__footer {
    padding: 0;

    &-section {
      border-bottom: var(--standard-border);
      padding: var(--spacing-unit-x3) 0;

      &-title {
        padding: 0 var(--spacing-unit-x3) var(--spacing-unit);
      }

      &-line {
        padding: 0 var(--spacing-unit-x3) var(--spacing-unit);
        display: flex;
        align-items: center;
        width: 28rem;

        .svg {
          margin-right: var(--spacing-unit);
        }

        & & {
          padding: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  &__footer {
    &-section-line {
      gap: calc(1rem / 12);
    }
  }

  &__show-link {
    padding: var(--spacing-unit) var(--spacing-unit-x3) 0;
  }
}
