.ab-split-summary {
  display: block;

  &__chart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x4) 0;
  }

  &__typography {
    display: flex;
    justify-content: space-between;

    &-wrapper {
      width: 100%;
    }
  }

  &__accordion {
    height: 100%;
    align-items: center;

    &-wrapper {
      width: 100%;
      height: 100%;
    }

    &-radix {
      &__panel[data-state='open'] {
        margin: 0 0 0 1.2rem;
        display: flex;
        flex-direction: column;
        gap: var(--spacing-unit-x2);
        padding-bottom: var(--spacing-unit-x2);
      }

      &__item {
        border-bottom: 0;
        padding: 0;
      }

      &__button {
        padding: 0;
        justify-content: flex-end;
        gap: var(--spacing-unit);

        > svg {
          width: calc(var(--spacing-unit) + var(--half-spacing-unit));
        }

        &[data-state='open'] {
          > svg {
            transform: rotate(360deg);
          }
        }

        &[data-state='closed'] {
          > svg {
            transform: rotate(270deg);
          }
        }
      }

      &:hover {
        > .ab-split-summary__accordion-radix__item .ab-split-summary__accordion-radix__panel,
        .typography {
          background: var(--input-background);
        }
      }
    }
  }

  &__total {
    margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x2) calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
    width: auto;

    &-final {
      &:first-child {
        width: 100%;
      }
    }
  }

  &__divider {
    height: var(--standard-border-width);
    background-color: var(--border-gray);
    margin-left: 1.2rem;
  }

  &__item {
    padding-right: var(--spacing-unit-x14);

    > div {
      justify-content: space-between;
    }
  }

  &__scroll {
    & .scroll-area__viewport {
      max-height: 26.5rem;
      min-width: 17rem;

      > div {
        display: flex !important;
        flex-direction: column;
        gap: var(--spacing-unit-x2);
      }
    }
  }

  &__gradient {
    pointer-events: none;
    position: absolute;
    width: 100%;
    left: 0;

    &-bottom {
      background: linear-gradient(180deg, transparent 0%, var(--page-back) 100%);
      bottom: 0;
      height: var(--spacing-unit-x2);
    }

    &-top {
      background: linear-gradient(360deg, transparent 0%, var(--page-back) 100%);
      top: 0;
      height: var(--spacing-unit-x2);
    }
  }

  .highcharts-background {
    fill: transparent;
  }
}
