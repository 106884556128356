.source-replacement {
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x3);
  padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

  &__arrow {
    align-self: center;
    fill: var(--gray-icon);
    transform: rotate(90deg);
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
  }

  &__source-box {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit) + var(--half-spacing-unit));
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

    &-current {
      background-color: var(--selectable-row-hover);
    }
  }
}
