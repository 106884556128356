.top-bar-search {
  flex: 1 1 auto;

  &__bar {
    display: flex;
    align-items: center;
    height: var(--top-bar-height);
  }

  &__search-icon {
    height: var(--spacing-unit-x3);
    width: var(--spacing-unit-x3);
  }

  &__caret-icon {
    width: 0.58rem;
    height: 0.33rem;
    fill: var(--light-gray);
  }

  &__input {
    position: relative;
    font-size: var(--larger-font-size);
    line-height: var(--larger-font-size);
    color: var(--text-gray);
    width: 100%;
    outline: none;
    border: none;
    padding-left: 0.2rem;
  }

  &__drop-down {
    .drop-down__drop-down {
      left: var(--spacing-unit-x3);
    }

    .drop-down__label span {
      height: var(--spacing-unit-x3);
    }

    .drop-down__main-button {
      padding: calc((var(--top-bar-height) - 1.83rem) / 2) 0 calc((var(--top-bar-height) - 1.83rem) / 2) 1.2rem;

      &:hover {
        text-decoration: underline;

        .top-bar-search__search-icon {
          fill: var(--text-gray);
        }
      }
    }

    .drop-down__main-button[data-state='open'] {
      .top-bar-search__search-icon {
        fill: var(--text-gray);
      }

      .caret-icon {
        fill: var(--text-gray);
      }
    }

    .caret-icon {
      position: relative;
      left: -0.3rem;
      top: -0.1rem;
    }
  }

  &__menu-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &:focus > label {
      box-shadow: none;
    }

    &:focus-visible > label {
      box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
    }
  }

  &__check {
    width: 0.9rem;
    height: 0.74rem;
    margin-right: var(--spacing-unit);
  }

  &__option {
    padding-bottom: var(--spacing-unit);
    padding-left: calc(var(--spacing-unit) + 0.9rem);
    color: var(--light-gray);
    white-space: nowrap;

    &:last-child {
      padding-bottom: 0;
    }

    &--selected {
      padding-left: 0;
      color: var(--gray);
    }
  }

  .drop-down__label {
    padding-right: var(--spacing-unit);
  }
}
