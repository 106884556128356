.popover-card {
  border: var(--standard-border);
  background-color: var(--white);
  border-radius: var(--standard-border-radius);
  box-shadow: var(--box-shadow);
  width: 40rem;

  &__header {
    background-color: var(--selectable-row-hover);
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
    padding: var(--spacing-unit) var(--spacing-unit-x3);
    height: var(--spacing-unit-x7);
    border-radius: var(--standard-border-radius) var(--standard-border-radius) 0 0;

    &-has-icon {
      padding: var(--spacing-unit) var(--spacing-unit-x2);
    }
      
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--spacing-unit-x6);
      height: var(--spacing-unit-x6);
    }
  }

  &__content {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
  }

  &__footer {
    padding: var(--spacing-unit-x3);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      padding: var(--spacing-unit) var(--spacing-unit-x4);
    }
  
    &-right {
      display: flex;
      justify-content: flex-end;
    }
  }
}
