.custom-create-option {
  background-color: var(--white);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  border-top: unset;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  padding: calc(var(--spacing-unit-x16) / 12) 0 calc(var(--spacing-unit-x16) / 12) 0 !important;


  &:hover:not(.custom-create-option__disabled),
  &__hidden-menu {
    .selectv2__option-new {
      background-color: var(--selectable-row-hover);

      &-icon {
        background-color: var(--hover-create-option-icon);

        & > svg {
          fill: var(--white) !important;
        }
      }
    }
  } 
  
  &__hidden-menu {
    border-top: var(--standard-border);
  }
  
}