.dynamic-content-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit);
  margin-top: var(--spacing-unit-x3);
}

.condition-card {
  width: 100%;
  border: var(--standard-border);
  border-top: var(--condition-card-teal-border);
  border-radius: var(--standard-border-radius);

  &__container {
    padding: var(--spacing-unit) var(--spacing-unit-x4) var(--spacing-unit-x4);
  }

  &__header {
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    border-bottom: var(--standard-border);
  }

  &__content {
    padding: var(--spacing-unit-x2);

    &-tag {
      display: inline-block;
      background-color: var(--selectable-row-hover);
      border-radius: var(--standard-border-radius);
      padding: 0 var(--spacing-unit);
      margin: var(--half-spacing-unit);

      .typography {
        word-break: break-word;
      }
    }

    &-or {
      display: inline-block;
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
      padding: 0 var(--spacing-unit);
      margin: 0 var(--half-spacing-unit);
    }

    & div {
      display: inline-block;
    }
  }

  &__divider {
    width: 100%;
    border: var(--standard-border);

    &-container {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x3);
      margin: var(--spacing-unit-x2) 0;
    }
  }
}
