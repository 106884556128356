.profile-drop-down {
  flex: 1 1 10%;
  margin-left: var(--spacing-unit-x2);
  margin-right: var(--spacing-unit-x4);
  font-size: var(--menu-only-font-size);
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a,
  button,
  .drop-down {
    font-size: var(--large-font-size);
    line-height: var(--large-font-size);
  }

  sup {
    font-size: 9.75px; /* stylelint-disable-line */
  }

  .drop-down {
    max-width: 18rem;
  }

  .drop-down__drop-down {
    margin-right: var(--spacing-unit-x4);
  }

  &__label:focus-visible,
  &__label-link:focus-visible {
    outline: none !important;
    text-decoration: none !important;
  }

  &__label {
    margin-bottom: var(--spacing-unit-x2);
    display: flex;
    align-items: center;
    white-space: nowrap;

    button:focus > label,
    a:focus > label {
      box-shadow: none;
    }

    &-link:focus,
    &-link:active {
      outline: none !important;
      border: none !important;
    }

    &-link:hover {
      cursor: pointer;
      text-decoration: underline;
      outline: none !important;
    }
  }

  &__user-name {
    display: block;
    overflow: visible;
  }

  &-item:focus-visible {
    outline: none;
    box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
  }

  &__theme {
    display: flex;
    margin-bottom: var(--spacing-unit-x2);

    &-button {
      margin-left: var(--spacing-unit);
      border: var(--standard-border);
      border-color: var(--lightest-gray);
      height: var(--spacing-unit-x3);
      width: 1.33rem;

      .label {
        display: block;
        cursor: pointer;
      }

      &--current {
        border-color: var(--text-gray);
      }

      &--light {
        background-color: var(--light-theme-background);
      }

      &--dark {
        background-color: var(--dark-theme-background);
      }
    }
  }

  &__sign-out {
    flex: 0 0 7.5rem;
    background-color: var(--black-back-transparent);
    padding: var(--spacing-unit-x5);
    color: var(--white);
    width: 100%;
    font-size: var(--large-font-size);
    display: flex;
    justify-content: center;

    &-button {
      display: block;
      margin: var(--spacing-unit-x2) 0 0;
      cursor: pointer;
      text-align: left;

      .typography:hover {
        text-decoration: underline;
      }
    }

    &-inside {
      max-width: 62rem;
      padding: 0 10rem;
      flex: 1 1 auto;
    }

    &-title {
      font-size: var(--page-header-font);
      padding-bottom: var(--spacing-unit-x2);
      display: flex;
      align-items: center;
      width: 100%;

      svg {
        fill: var(--signout-svg);
        width: 1.5rem;
        height: 1.5rem;
        margin-right: var(--spacing-unit);
      }
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
      margin: var(--spacing-unit-x2) var(--spacing-unit);

      button {
        background-color: var(--white);
        color: var(--black);
        padding: 0.75rem var(--spacing-unit-x3);
        font-weight: var(--font-weight-bold);
        border-radius: 0.2rem;
        margin-left: var(--spacing-unit-x2);
      }
    }
  }

  &__languages {
    background-color: var(--white);
    border: var(--standard-border);
    width: 11rem;
    padding: var(--spacing-unit-x2);
    margin-left: var(--spacing-unit-x24);
    margin-top: var(--spacing-unit);

    &-item {
      width: 100%;
      margin-bottom: var(--spacing-unit-x2);
      background-color: var(--input-background);
      background-size: 0.75rem 0.46rem;
      background-position: calc(100% - var(--spacing-unit-x2)) center;
      background-repeat: no-repeat;
      border: var(--input-border);
      border-radius: 0.25rem;
      padding: var(--spacing-unit);
      outline: none;
      transition: var(--linear-80);
      text-align: left;

      &::-ms-expand {
        display: none;
      }

      &:hover,
      &:focus {
        background-color: var(--white);
      }

      &:hover {
        border: var(--input-border-hover);
      }

      &:focus,
      &--open {
        border: var(--input-border-focus);
        box-shadow: 0 0 0 var(--standard-border-width) var(--dark-blue);
      }

      &:focus-visible {
        outline: none;
        box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
      }

      button {
        width: 100%;
      }

      .drop-down__label {
        padding: 0;
        justify-content: space-between;
      }
    }
  }

  &__language {
    display: flex;
    align-items: center;

    &:hover {
      background-color: var(--hover-background-gray);
      color: var(--white);
    }

    &-spacer {
      display: inline-block;
      width: var(--spacing-unit-x2);
    }

    .typography {
      margin-left: var(--spacing-unit);
      flex: 1;

      &:hover {
        color: var(--white);
      }
    }
  }

  &__menu {
    max-width: 30rem;
    margin-right: var(--spacing-unit-x4);
    display: flex;
    align-items: center;

    &-link:focus {
      &,
      & > * {
        box-shadow: none;
      }
    }

    .drop-down__drop-down {
      margin-right: var(--spacing-unit-x4);
    }

    .drop-down__main-button {
      .typography {
        color: var(--light-gray);
        padding: var(--half-spacing-unit);
      }

      &:hover {
        .typography {
          color: var(--text-gray);
        }
      }

      &[data-state='open'] {
        .typography {
          color: var(--text-gray);
        }

        .caret-icon {
          fill: var(--text-gray);
        }
      }
    }
  }
}
