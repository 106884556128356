
.recipient-summary {
  > .container {
    border: var(--card-border-highlight);
  }

  &__table-contact-breakdown {
    margin-bottom: var(--spacing-unit-x2);
  }
}
