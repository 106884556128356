
.file-download {
  &__text {
    width: fit-content;

    &-bullet {
      margin-left: var(--spacing-unit-x2);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: var(--spacing-unit-x2);
  }
}
