
.segment-activity-details-section {
  border-top: var(--standard-border);
  padding: var(--spacing-unit-x3) 0;

  &__title {
    align-items: center;
    display: flex;
    margin-bottom: var(--spacing-unit-x2);

    &-text {
      flex-grow: 1;
    }
  }
}
