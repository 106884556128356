.landing-page-url-group {
  &__header {
    display: flex;
    margin-bottom: var(--spacing-unit-x4);
    padding: var(--half-spacing-unit) 0;
    align-items: baseline;

    &-not-default {
      align-items: center;
      justify-content: space-between;
      padding: 0;

      &__label {
        margin-right: var(--spacing-unit-x4);
        word-break: break-word;
      }
    }
  }

  &__label {
    margin-right: var(--spacing-unit);
  }

  &__block {
    padding-bottom: var(--spacing-unit-x2);

    &-title {
      display: flex;
      justify-content: space-between;
      min-height: 2.5rem;

      &-center {
        align-items: center;
      }
    }

    &-public-url {
      margin-top: var(--spacing-unit-x2);
    }

    &-public-url,
    &-seo-friendly-url,
    &-short-url {
      /* stylelint-disable */
      @media (max-width: 1420px) {
        /* stylelint-enable */
        max-width: 55rem;
      }

      /* stylelint-disable */
      @media (max-width: 1298px) {
        /* stylelint-enable */
        max-width: 45rem;
      }
    }

    & + & {
      border-top: var(--standard-border);
      padding-top: var(--spacing-unit-x2);
    }
  }
}
