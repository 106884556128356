.legacy-banner {
  width: 100%;
  padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  background-color: var(--selectable-row-hover);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__single {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
  }

  &__text {
    &-margin {
      margin-right: var(--spacing-unit-x4);
    }
  }

  .split {
    &__button-main {
      .svg {
        height: var(--spacing-unit-x2);
        width: var(--spacing-unit-x2);
      }
    }

    &__options {
      min-width: var(--split-button-large-content-width) !important;

      button {
        padding: calc(var(--spacing-unit-x4) / 3) var(--spacing-unit-x2);
      }

      .svg {
        height: 1.33334rem;
        width: 1.33334rem;
        margin-right: 1.33334rem;
      }

      .split-button-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pill {
          padding: calc(var(--spacing-unit) / 3) calc(var(--spacing-unit-x3) / 2);
        }
      }
    }
  }
}
