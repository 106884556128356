.program-performance {
  min-width: var(--positioning-min-width);
  max-width: var(--positioning-max-width);
  margin-top: var(--spacing-unit-x6);
  margin-bottom: var(--spacing-unit-x4);

  &__refresh-ctr {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-unit-x4);
  }

  &__schedule {
    line-height: var(--line-height-large);

    &-ctr {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-width: 21rem;
    }
  }

  &__clear-history,
  &__exit-contacts {
    width: 40rem;
  }

  &__donut {
    height: var(--spacing-unit-x6);
  }

  &__empty-listing {
    padding: var(--spacing-unit-x10) 0;
    margin-top: var(--spacing-unit-x4);
  }

  &__all-contacts-tab {
    display: flex;
    align-items: center;

    & .tooltip__trigger {
      display: flex;
    }
  }
}
