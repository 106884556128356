:root {
  --personalization-width: 50rem;
}

.sms-personalization {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    padding-top: 1.333rem;
    padding-bottom: 1.333rem;

    span {
      flex: 1 1 100%;
    }
  }

  &__body {
    padding: 0;
    overflow: hidden;
    min-height: 23rem;
  }

  &__row-info {
    padding-left: var(--spacing-unit-x2);
  }

  &__inputs {
    margin-top: var(--spacing-unit-x4);
  }

  &__inputs-hidden {
    display: none;
    padding-bottom: 0;
  }

  &__inputs-fields {
    display: flex;
    margin-top: var(--spacing-unit-x4);
  }

  &__input-max-length {
    flex: 0 0 var(--spacing-unit-x18);
    padding-right: var(--spacing-unit-x2);
  }

  &__input-fallback {
    flex: 1;
  }

  &__error {
    border-color: var(--error);
  }

  &__inputs-error {
    padding-top: var(--spacing-unit);
  }

  &__fallback-helper {
    margin-top: var(--spacing-unit-x4);
  }

  &__no-fields {
    display: flex;
    border-radius: 0.25rem;
    margin: 1rem 0;
    padding: 1rem;
    background-color: var(--light-yellow);
  }

  &__svg-span {
    padding-right: calc(2rem / 3);
    width: 2.5rem;
    margin-right: calc(2rem / 3);
  }

  &__span {
    line-height: var(--line-height-medium);

    a {
      color: var(--text-teal);

      .svg--smaller-icon {
        margin-top: calc(1.5rem / 10);
        margin-left: 0.5rem;
        fill: var(--text-teal);
      }
    }
  }

  &.modal,
  .modal-body {
    overflow: unset;
  }
}
