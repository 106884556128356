.capped-signups {
  &__submissions {
    margin-top: var(--spacing-unit-x5);

    .svg {
      margin-left: var(--spacing-unit);
    }

    &__info {
      display: inline-block;
    }
  }
}
