.fatigue-suppression-rules {
  &__rules {
    display: flex;
    align-items: flex-end;
  }

  &__button-section {
    margin-top: var(--spacing-unit-x4);
  }

  &__delete-rule {
    margin-left: var(--spacing-unit-x3);

    svg {
      fill: var(--error);
    }
  }
}
