.inline-editing {
  &__container {
    &-flex {
      display: flex;
      align-items: center;
    }

    &-grid {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
    }
  }

  &__title {
    text-align: left;
  }

  &__character-count {
    pointer-events: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    position: absolute;
    top: var(--spacing-unit-x2);
    right: var(--spacing-unit-x2);
    z-index: var(--z-index-9999);
  }

  &__input {
    width: 100%;
    margin: 0;
    border: var(--input-border-focus);
    color: var(--text-gray);
    background-color: var(--input-background);
    border-radius: var(--standard-border-radius);
    outline: var(--input-outline-border);
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-index-9);
    height: inherit;
    padding: inherit;
    font-size: var(--page-header-font);
    line-height: var(--line-height-title-page);
    font-weight: var(--font-weight-medium);

    &::selection {
      background-color: var(--input-select-background);
    }

    &--max-length {
      overflow: hidden;
      padding-right: var(--spacing-unit-x10);
      padding-left: var(--spacing-unit-x2);
    }

    &--container {
      display: inline-flex;
      position: relative;
      height: var(--spacing-unit-x6);
      padding: 0 var(--spacing-unit-x2);
      font-size: var(--sub-header-font-size);
      font-weight: var(--font-weight-bold);
    }

    &--size {
      width: 100%;
      display: inline-flex;
      visibility: hidden;
      white-space: pre;
      padding-left: var(--half-spacing-unit);
      height: inherit;
      font-size: inherit;
      font-weight: inherit;
    }

    &--gradient {
      padding-left: 0;

      &::after {
        content: '';
        position: absolute;
        inset: calc(var(--spacing-unit-x2) / 12) auto calc(var(--spacing-unit-x2) / 12) calc(var(--spacing-unit-x2) / 12);
        width: var(--spacing-unit-x3);
        border-radius: var(--standard-border-radius) 0 0 var(--standard-border-radius);
        background: linear-gradient(90deg, var(--white) 45.97%, rgba(255, 255, 255, 0.01) 85.56%, rgba(255, 255, 255, 0) 88.18%);
        z-index: var(--z-index-99);
      }
    }

    &--button {
      max-width: var(--editable-button-max-width);
      min-width: var(--editable-button-min-width);
      padding: var(--spacing-unit) var(--spacing-unit-x2);
      display: grid;
      grid-template-columns: 1fr auto;

      .typography {
        font-weight: var(--font-weight-bold);
      }

      &-icon.svg {
        margin: 0 0 0 calc(var(--spacing-unit) + var(--half-spacing-unit));
      }
    }
  }

  &__trigger {
    &-ICON {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      height: var(--spacing-unit-x6);
      margin-left: unset;
    }
  }
}
