.label {
  font-weight: var(--font-weight-medium);
  font-size: var(--default-font-size);
  line-height: var(--spacing-unit-x4);
  color: var(--text-gray);
  margin: var(--spacing-unit) 0 var(--half-spacing-unit) 0;

  &-required {
    margin-left: var(--half-spacing-unit);
  }
}
