.info-cards {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  height: 15.6rem;
  border: var(--standard-border);
  border-radius: 0.33rem;
  background-color: var(--white);

  &__cards {
    display: flex;
    flex-direction: row;
    margin-top: var(--spacing-unit-x4);
  }

  &__card-container {
    margin-right: var(--spacing-unit-x2);
    min-width: 12.27rem;
    height: 6.67rem;
    background-color: var(--lighter-off-white);
    padding: 1.25rem 0 0 var(--spacing-unit-x3);
    border-radius: var(--standard-border-radius);
  }

  &__label-content {
    margin-top: var(--spacing-unit-x3);
    display: flex;

    &-primary {
      margin-right: var(--spacing-unit-x2);
      line-height: var(--line-height-sub-header);
    }
  }
}
