.incoming-webhook-toggle-section {
  align-items: center;
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  display: flex;
  gap: var(--spacing-unit-x4);
  padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  
  &__info-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
  }

  &__toggle-container {
    width: 3.75rem;
  }
}
