.direct-select-segment-builder {
  &__view-selected {
    display: flex;
    gap: var(--spacing-unit-x4);
    align-items: center;
    
    &-button {
      gap: calc(10rem / 12);
    }
  }
  
  &__contacts-details {
    margin-top: var(--spacing-unit-x4);
    
    &__top-section {
      margin-bottom: var(--spacing-unit-x4);
    }
  }
}
