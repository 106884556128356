.pie-chart {
  .highcharts-legend-item {
    rect {
      transform: translate(0, 0.8rem) !important;
    }

    &:first-of-type rect {
      display: none;
    }

    &:nth-of-type(2) {
      .pie-chart__legend-row {
        border-top: none;
      }
    }
  }

  &__legend-right {
    .highcharts-legend-item {
      rect {
        transform: translate(0, var(--spacing-unit-x2)) !important;
      }
    }
  }

  &__legend-row,
  &__legend-title-row {
    width: 30rem;
    min-width: 30rem;
    display: flex;
    font-weight: var(--font-weight-medium-light);
    margin-left: var(--spacing-unit);
    padding-right: var(--spacing-unit-x2);
    padding-left: var(--spacing-unit-x2);

    &-wide {
      width: 54rem;
      min-width: 54rem;
    }

    &-label {
      flex: 7;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &__tooltip {
        position: absolute;
        visibility: hidden;
        bottom: 100%;
        left: 0;
        opacity: 0;
        transition: opacity 0s;
        max-width: 50rem;

        &__text {
          text-align: center;
          line-height: var(--line-height-large);
          white-space: pre;
        }
      }

      &:hover &__tooltip {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s  step-end;

        /* Bottom arrow */
        &::after {
          content: ' ';
          position: absolute;
          top: 100%;
          left: calc(50% - 0.4rem);
          border-width: 0.4rem;
          border-style: solid;
          border-color: var(--text-gray) transparent transparent transparent;
        }
      }
    }

    &-value {
      flex: 1;
      text-align: right;

      &__tooltip {
        position: absolute;
        visibility: hidden;
        bottom: 100%;
        right: 0;
        opacity: 0;
        transition: opacity 0s;

        &__text {
          text-align: center;
          line-height: var(--line-height-large);
          white-space: pre;
        }
      }

      &:hover &__tooltip {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s  step-end;

        /* Bottom arrow */
        &::after {
          content: ' ';
          position: absolute;
          top: 100%;
          right: calc(var(--half-spacing-unit) + var(--spacing-unit));
          border-width: 0.4rem;
          border-style: solid;
          border-color: var(--text-gray) transparent transparent transparent;
        }
      }
    }
  }

  &__legend-row {
    font-size: var(--large-font-size);
    border-top: var(--standard-border);
    height: var(--spacing-unit-x7);
    padding-bottom: calc(var(--spacing-unit-x4) / 12);
    align-items: center;

    &:hover {
      background-color: var(--hover-row-background-teal);
    }

    &-value {
      font-weight: var(--font-weight-medium);
    }
  }

  &__legend-title-row {
    border-bottom: var(--input-border-hover);
    font-size: var(--smallest-font-size);
    line-height: var(--line-height-large);
    color: var(--light-gray);
    font-weight: var(--font-weight-bold);
  }
}

.legend-svg {
  margin: 0 var(--spacing-unit);
  display: inline-block;
  width: calc(10rem / 12);
  height: calc(10rem / 12);
  background-repeat: no-repeat;
}

.highcharts-legend-item > span {
  overflow: visible !important;
}

/* stylelint-disable */
@media (max-width: 1250px) {
  /* stylelint-enable */
  .pie-chart {
    &__legend-row,
    &__legend-title-row {
      &-wide {
        width: 40rem;
        min-width: 40rem;
      }
    }
  }
}
