.bouncesAndOptOuts {
  &__modal {
    width: 50rem;
  }

  &__body.modal-body {
    padding: var(--spacing-unit-x4);
  }

  &__body-text {
    margin-bottom: var(--spacing-unit-x4);
  }


  &__option {
    &-icon {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
    }

    &-intro {
      &-title {
        margin: 0 0 var(--spacing-unit-x2) 0;
      }
    }

    &-wrapper {
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4) ;
      margin-bottom: var(--spacing-unit-x2);

      background-color: var(--input-background);
      border-radius: var(--standard-border-radius);
    }
  }
}
