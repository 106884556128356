.common-paginator {
  align-items: center;
  display: flex;
  gap: var(--spacing-unit-x2);

  &__button {
    &-back:disabled .svg {
      fill: var(--button-gray) !important;
    }
    
    &-next:hover .svg {
      fill: var(--white) !important;
    }
  }
}
