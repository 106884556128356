.file-select-with-search-modal {
  display: block;
  width: 50rem;

  &__loader {
    width: 100%;
  }

  &__body {
    padding: 1.3333rem var(--spacing-unit-x4) !important;
    overflow: hidden !important;
  }

  &__description {
    margin-top: calc(var(--spacing-unit-x4) / 3);
    margin-bottom: var(--spacing-unit-x4);
  }

  &__files {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-unit) 0;

    &-search-header {
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__file {
    align-items: center;
    display: flex;
    cursor: pointer;
    gap: var(--spacing-unit-x3);
    padding: var(--spacing-unit-x2);
    border-left: 0.3334rem solid transparent;

    &-selected {
      background-color: var(--lighter-teal) !important;
      border-left: 0.3334rem solid var(--teal);
    }

    &:hover {
      background-color: var(--selectable-row-hover);
    }

    &-name {
      display: flex;
      flex-direction: column;
    }
  }

  & .scroll-area {
    &__viewport {
      max-height: 37rem;
    }

    &__scrollbar {
      padding: 1.33rem 0;
    }
  }
}
