.create-incoming-webhook-modal {
  width: 56rem;

  &__header {
    padding: 1.6667rem var(--spacing-unit-x6);
  }

  &__body {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x6) var(--spacing-unit-x6) !important;

    > div + div {
      margin-top: var(--spacing-unit-x4);
      border-top: var(--standard-border);
      padding-top: var(--spacing-unit-x4);
    }

    &-loading {
      filter: opacity(0.5);
      pointer-events: none;
    }
  }

  &__footer > div {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x6);
  }

  &__details {
    &-name,
    &-description {
      margin-bottom: var(--spacing-unit-x4);
    }

    &-name {
      &-error {
        display: inline-flex;
        align-items: center;
        gap: var(--spacing-unit);
        margin-top: 0.8334rem;

        svg,
        div {
          color: var(--error-text);
          fill: var(--error-text);
        }
      }
    }

    &-description {
      .textarea {
        min-width: 100%;
        max-width: 100%;
        min-height: 8rem;
      }
    }

    .label {
      line-height: var(--line-height-large);
      margin: 0 0 0.8334rem 0;
    }
  }

  &__create-button {
    width: var(--spacing-unit-x16);
  }

  &__edit-button {
    width: var(--spacing-unit-x23);
  }

  &__info-tooltip {
    margin-left: 0.6667rem;
  }

  .input,
  .textarea,
  .select,
  .single-select-dropdown__faux-select {
    background-color: var(--white);
  }
}
