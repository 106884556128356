.asset-picker-info {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-unit-x4) var(--spacing-unit-x4) calc(var(--spacing-unit-x4) / 12) var(--spacing-unit-x4);

  &__default {
    margin-top: var(--spacing-unit-x2);
  }

  &__warning {
    margin-top: calc(20rem / 12);
  }

  & &__info {
    margin-top: 0;
  }


  &__show-border-bottom {
    border-bottom: var(--standard-border);
    padding: var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x3) var(--spacing-unit-x4);
    margin: 0;
  }
}