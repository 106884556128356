.required-fields-missing-modal-body {
  &__errors {
    border-radius: var(--standard-border-radius);
    border: var(--standard-border);

    &-header {
      column-gap: var(--spacing-unit-x2);
      padding: calc(var(--spacing-unit-x3) / 2) var(--spacing-unit-x4);
      border-bottom: var(--standard-border);
      background-color: var(--input-background);
    }
  }

  &__info-status {
    padding-left: var(--spacing-unit-x4);
  }
}
