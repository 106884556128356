.edit-outgoing-webhook-step {
  width: 50rem;

  .checkboxgroup__container,
  &__read-only-columns-container {
    flex-wrap: wrap;
    row-gap: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit-x2);

    > div {
      width: calc(50% - var(--spacing-unit-x2));
    }
  }

  .checkbox__label {
    line-height: var(--line-height-medium);
  }

  .checkboxgroup + &__error-message {
    margin-top: var(--spacing-unit-x2);
  }

  &__loader {
    margin: 0 auto;
  }

  &__error-message {
    display: flex;
    gap: var(--spacing-unit);
    align-items: center;

    &-icon {
      fill: var(--error-text);
    }
  }

  &__check-all-container {
    margin-top: var(--spacing-unit-x3);
  }

  &__read-only {
    .typography {
      margin-bottom: unset !important;
    }
    
    .label {
      margin-bottom: var(--half-spacing-unit) !important;

      &:not(:first-child) {
        margin-top: var(--spacing-unit-x4);
      }
    }
    
    &-endpoint + &-endpoint {
      margin-top: var(--spacing-unit-x2);
    }

    &-endpoint {
      display: flex;
      justify-content: space-between;
      width: 100%;
      
      &-container {
        margin-top: var(--spacing-unit-x2);
      }

      & button {
        visibility: hidden;
        height: calc(20rem / 12);
      }

      &:hover button {
        visibility: visible;
      }
    }

    &-columns-container {
      display: flex;
      column-gap: var(--spacing-unit-x4);
    }
  }
}
