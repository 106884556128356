.input-with-copy {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-unit-x2);

  >:first-child {
    flex-grow: 1;
  }
  
  &__button-copy {
    flex-shrink: 0;
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
    padding: 0 !important;
  
    svg {
      width: 1.3334rem;
      height: 1.3334rem;
    }
  }
}
