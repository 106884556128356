.edit-columns-modal {
  width: 100rem;
  height: 65rem;
  
  &__body {
    display: flex;
    gap: var(--spacing-unit-x6);
    padding: 0 var(--spacing-unit-x6) !important;

    &> :first-child,
    &> :last-child {
      flex: 1 1 0;
    }
  }

  &__columns-listing {
    &-body {
      height: 46.25rem;
      padding-left: 0;
      padding-right: 0;
    }

    &-header {
      padding: var(--spacing-unit-x4) 0;
    }
  }
  
  &__separator {
    border-right: var(--standard-border);
  }

  &__search__container {
    position: sticky;
    margin: var(--spacing-unit-x4) 0;
    background-color: var(--white);
    z-index: var(--z-index-9);
    top: 0;
  }

  &__available-fields {
    height: 45.25rem;
  }
}
