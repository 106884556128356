.manage-profile {
  &__button {
    margin-top: var(--spacing-unit-x6);
  }

  &__main-header {
    margin-bottom: calc(var(--spacing-unit) + var(--spacing-unit-x2) / 3);
  }

  &__header {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__item {
    display: flex;
    align-items: flex-start;

    &-value {
      padding-top: calc(var(--half-spacing-unit) / 2 );
      max-width: 80%;
      margin-left: calc(var(--spacing-unit-x2) / 3 * 4);
    }

    &:not(:last-of-type) {
      margin-bottom: var(--spacing-unit-x2);
    }
  }
}
