.inbox-sidebar-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-unit);
  padding: var(--spacing-unit-x4) var(--spacing-unit-x3);

  &__svg {
    fill: var(--lightest-gray);
  }

  &__image {
    width: var(--spacing-unit-x14);
  }
}
