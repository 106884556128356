.template-catalog-modal-preview {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;

  &__switch {
    position: absolute;
    top: var(--spacing-unit-x2);
    right: var(--spacing-unit-x2);
    height: var(--spacing-unit-x6);
    box-shadow: var(--program-flow-step-shadow);

    .switch__body {
      height: 100%;
    }
  }

  &__template {
    width: 100%;
    height: 100%;
    border: none;

    &-container {
      flex: 1;
      height: 100%;
      position: relative;

      &-mobile {
        background-color: var(--modal-gray-background);
      }
    }

    &-mobile {
      width: var(--template-catalog-modal-mobile-preview-width);
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
    padding: var(--spacing-unit-x4);
    width: 36rem;
    border-left: var(--standard-border);
    overflow: auto;
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit-x3) / 2);
    padding-bottom: var(--spacing-unit-x4);
    border-bottom: var(--standard-border);
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
    padding: var(--spacing-unit-x2) 0;
  }

  &__sub-header {
    font-weight: var(--font-weight-bold);
  }

  &__categories {
    display: flex;
    gap: var(--spacing-unit);
    margin-top: var(--spacing-unit-x2);
    width: 100%;
    flex-wrap: wrap;
  }

  &__terms {
    display: flex;
    gap: var(--spacing-unit);
    margin-top: var(--spacing-unit-x2);
    width: 100%;
    flex-wrap: wrap;
  }

  &__pill-button {
    padding: var(--half-spacing-unit) var(--spacing-unit-x2);
    border: var(--standard-border-darker);
    height: fit-content;
    user-select: none;

    &:hover {
      background-color: var(--selectable-row-hover);
    }
  }
}
