.crm-push-errors-modal {
  min-height: 40rem;
  max-height: 60rem;
  min-width: 60rem;
  max-width: 100rem;

  .modal-header {
    max-height: var(--spacing-unit-x11);
  }

  .modal-body {
    flex: 1;
  }

  .modal-footer {
    max-height: var(--spacing-unit-x14);
  }
}
