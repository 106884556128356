
.base-tag {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: var(--spacing-unit-x4);
  max-width: 8.333rem;
  min-width: calc(var(--spacing-unit-x2) / 3);
  overflow-x: scroll;
  overflow-y: hidden;
  position: sticky;
  transition: all 0.05s ease-in-out;
  width: fit-content;
  cursor: pointer;

  &::-webkit-scrollbar {
    display: none;
  }

  &__no-cursor {
    cursor: default;
  }

  &__background {
    background-color: var(--background-tag);
    border-radius: var(--legend-border-radius);
  }

  &__color {
    border: var(--tag-color-border);
    border-radius: var(--legend-border-radius) 0 0 var(--legend-border-radius);
    height: 100%;
    position: absolute;
    top: 0;
    width: calc(var(--spacing-unit-x2) / 3);
    z-index: 2;

    &-collapsed {
      border-radius: var(--legend-border-radius);
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }
    }

    &-filling {
      height: 100%;
      position: absolute;
      transition: all 0.05s ease-in-out;
      width: 0;
      z-index: 1;

      &-full-width {
        width: 100%;
      }
    }

    &-no-border {
      border-color: transparent;
    }
  }

  &__tooltip {
    height: 100%;
    width: 100%;
  }
}
