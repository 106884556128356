.insert-url__modal {
  width: 100%;
  max-width: calc(100 * var(--spacing-unit));

  &-input {
    margin: var(--spacing-unit-x2) 0;
  }

  &-right-actions {
    display: flex;
  }

  &__remove-left {
    .modal-footer__inner {
      justify-content: space-between;
      padding-left: var(--spacing-unit-x5);
    }
  }
}
