.invite-single-user-form {
  .grid__row {
    margin-top: 0;
  }

  &__row-email {
    .tooltip__trigger,
    .input-with-status {
      width: 100%;
    }
  }
}
