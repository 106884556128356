.inbox {
  &__table-helpers {
    display: flex;
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x2);

    &-filters {
      flex: 1;
      display: flex;
      gap: var(--spacing-unit-x2);

      &-type {
        width: var(--spacing-unit-x24);
      }

      &-sender {
        margin-left: var(--spacing-unit-x10);
      }
    }

    &-search {
      width: 25rem;
    }
  }

  &__created {
    width: 13.3334rem;
  }
}
