.segments-to-replace-section {
  display: flex;
  gap: var(--spacing-unit-x3);
  flex-direction: column;
  
  &__segments {
    display: grid;
    grid-template-columns: 1fr var(--spacing-unit-x12) 1fr;
    row-gap: var(--spacing-unit-x2);
  }

  &__replacement {
    white-space: nowrap;
  }
}