.input-with-clear {
  display: block;
  position: relative;

  &__loader {
    position: absolute;
    right: var(--spacing-unit-x8);
    top: 0.8rem;
    padding: 0;
    width: auto;
    height: auto;

    &--labelled {
      top: var(--spacing-unit-x6);
    }
  }

  .input {
    padding-right: var(--spacing-unit-x8);
  }

  &--has-loading .input {
    padding-right: var(--spacing-unit-x13);
  }

  &--small .input {
    padding-right: var(--spacing-unit-x4);
  }

  .button--float {
    position: absolute;
    top: 0;
    right: 0;
    background: none;

    &:hover {
      background: none;
    }
  }

  &__clear-button-svg-wrapper {
    padding: calc(var(--half-spacing-unit) * 1.6667) calc(var(--spacing-unit) * 1.16667);
  }

  &__clear-button-divider {
    &-visible {
      position: absolute;
      right: var(--spacing-unit-x6);
      top: var(--spacing-unit);
      width: calc(var(--half-spacing-unit) * 0.33334);
      height: var(--spacing-unit-x4);
      background-color: var(--button-back-gray);
    }
  }

  &__clear-button {
    visibility: hidden;
    position: absolute;
    right: calc(var(--spacing-unit) * 1.1);
    top: var(--spacing-unit);
    border-radius: var(--spacing-unit-x2);
    transition: background-color 0.2s ease-in-out;

    &-small {
      fill: var(--button-hover) !important;
      height: var(--spacing-unit-x2) !important;
      width: var(--spacing-unit-x2);
      margin: 0;
      position: absolute;
      top: 0.5rem;
      right: 1.2rem;
    }

    &:hover {
      background-color: var(--navigation-button-hover);

      .svg {
        fill: var(--black);
      }
    }

    .svg {
      transition: fill 0.2s ease-in-out;
      height: calc(var(--spacing-unit-x2) * 0.83334);
      width: calc(var(--spacing-unit-x2) * 0.83334);

      &:focus {
        outline: none;
      }

      &:focus-visible {
        border-radius: var(--standard-border-radius); /* keyboard-only focus styles */
        border: var(--input-border-focus);
        outline: var(--input-outline-border);
      }
    }

    &-visible {
      visibility: visible;
    }

    &-labelled {
      top: 2.8rem;
    }

    &-divider-labelled {
      top: 2.75rem;
    }

    &-labelled&-small {
      .svg {
        top: calc(var(--spacing-unit-x5) + var(--half-spacing-unit));
      }
    }
  }
}
