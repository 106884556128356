.custom-link {
  padding: var(--spacing-unit-x4);

  &__header {
    padding-bottom: var(--spacing-unit-x2);
  }

  &__form {
    margin-top: var(--spacing-unit-x4);
  }
}
