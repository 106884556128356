.accordion-radix {
  &__item {
    border-bottom: var(--standard-border);
    padding-bottom: var(--spacing-unit-x6);
    padding-right: var(--spacing-unit-x2);
    padding-left: var(--spacing-unit-x2);
    line-height: 1.334rem;

    &[data-state='closed'] {
      padding-bottom: 0;
    }

    &[data-disabled='true'] button:hover {
      cursor: default;
    }
  }

  &__header {
    display: flex;
    margin: 0;
    height: 3.3334rem;

    &[data-state='open'] button {
      font-weight: var(--font-weight-bold);
    }
  }

  &__button {
    background-color: transparent;
    border: none;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .svg {
      fill: var(--text-gray);
    }

    &-reverse {
      flex-direction: row-reverse;
    }

    &--noSidePadding {
      padding-left: 0;
      padding-right: 0;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
    }

    &[data-state='open'] .accordion-radix__caret-icon.accordion-radix__down-up svg {
      transform: rotate(180deg);
    }

    &[data-state='open'] .accordion-radix__caret-icon.accordion-radix__right-down svg {
      transform: rotate(90deg);
    }
  }

  &__panel {
    margin-top: var(--spacing-unit-x3);
  }
}
