.automated-program-templates-listing-page-container {
  &__catalog,
  &__new-template {
    height: var(--spacing-unit-x6);
  }

  &__catalog {
    width: 15rem;
    margin-right: var(--spacing-unit);

    & > .typography {
      white-space: nowrap;
    }
  }

  &__new-template {
    .svg {
      fill: var(--white);
    }
  }
}
