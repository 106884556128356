.edit-crm-step-v2 {
  width: 60rem;

  &:not(:has(&__sync-error-message)) {
    padding: 0;
  }

  &__selector {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x7);
  }

  &__divider {
    height: var(--standard-border-width);
    background-color: var(--border-gray);
    width: 100%;

    &-container {
      display: flex;
      align-items: center; 
      justify-content: center;
      margin: var(--spacing-unit-x2) 0;
    }

    &-text {
      margin: 0 var(--spacing-unit);
    }
  }
  
  &__cards {
    &-header {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      padding: var(--spacing-unit) 0;
      margin-bottom: var(--spacing-unit-x2);
    }

    &-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: var(--spacing-unit-x2);
      overflow-y: auto;
    }

    &-scroll {
      height: 24rem;
    }

    &-group {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  &__step {
    border-bottom: var(--standard-border);
  }

  &__submit {
    margin-left: var(--spacing-unit-x2);
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &-submit {
      display: flex;
    }
  }
}
