.edit-columns-dropdown {
  &__listing {
    &-body {
      height: 26rem;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: var(--standard-border);
    padding: var(--spacing-unit-x4);
    padding-bottom: var(--spacing-unit-x3);
    
    > a {
      margin-right: auto;
    }
  }

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--half-spacing-unit);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit);
    padding-left: var(--spacing-unit-x2);

    & svg {
      fill: var(--light-gray);
      color: var(--light-gray);
    }

    &-caret {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--spacing-unit-x2);
      height: var(--spacing-unit-x2);
    }

    &:hover {
      background-color: var(--navigation-button-hover);

      svg {
        fill: var(--text-gray);
        color: var(--text-gray);
      }
    }
  }
}
