.labs {
  &__info-action {
    margin-bottom: var(--spacing-unit-x4);

    .info-action--wrapper-buttons {
      display: none;
    }

    .text-link {
      display: inline;
    }
  }

  &__content {
    background-color: var(--white);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: 0 var(--spacing-unit-x4) var(--spacing-unit-x2);
  }

  &__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-unit-x4);
    padding-top: var(--spacing-unit-x4);

    &-warning {
      display: flex;
      padding: var(--spacing-unit-x4);
      gap: var(--spacing-unit-x3);
      background-color: var(--page-back);
      border-left: var(--text-teal-border);

      &-left {
        .svg {
          fill: var(--disabled-plus-gray);
        }
      }

      &-body {
        margin-top: var(--half-spacing-unit);
        margin-bottom: var(--spacing-unit-x2);
      }

      &-link .typography {
        line-height: unset;
      }
    }

    &-description {
      padding-right: var(--spacing-unit-x4);

      &-title {
        margin-bottom: var(--spacing-unit-x2);

        &-ctr {
          display: flex;
          align-items: flex-start;
        }
      }
    }

    .toggle {
      margin-right: var(--half-spacing-unit);
    }

    &:not(:first-of-type) {
      border-top: var(--standard-border);
      margin-top: var(--spacing-unit-x4);
    }
  }
}
