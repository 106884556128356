.nested-table {
  flex: 1;
  padding: 0;

  &__holder {
    width: 100%;
    overflow-x: auto;
    border: var(--standard-border);
    border-radius: 0.33rem;
    border-spacing: 0;
    min-width: var(--positioning-min-width);
    max-width: var(--positioning-max-width);
    padding-bottom: var(--spacing-unit-x4);
    display: flex;
    background-color: var(--white);
    margin-bottom: var(--spacing-unit-x4);
  }

  &__left {
    text-align: left;
  }

  &__center {
    justify-content: center;
    text-align: center;
  }

  &__right {
    justify-content: flex-end;
    text-align: right;
  }

  &__thead &__tr {
    align-items: flex-end;
    height: var(--spacing-unit-x8);

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: var(--white);
    }
  }

  &__thead &__expander {
    padding-left: var(--spacing-unit-x4);
  }

  &__th {
    padding-bottom: var(--spacing-unit);
    color: var(--light-gray);
    font-weight: var(--font-weight-bold);
    font-size: var(--smallest-font-size);
    text-transform: uppercase;
    border-bottom: var(--input-border-hover);
    cursor: pointer;

    &:nth-child(1) {
      width: 6rem;
      padding-left: var(--spacing-unit-x4);
    }

    &:last-child {
      padding-right: var(--spacing-unit-x4);
    }

    &.table__use-checkbox {
      padding-right: var(--spacing-unit-x9);
    }

    &:hover {
      text-decoration: underline;
    }

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
    }

    &-last {
      padding-right: var(--spacing-unit-x4);
    }
  }

  &__tbody {
    display: flex;
    flex-direction: column;
  }

  &__tbody &__expander {
    border-bottom: none;
    padding: 0;
    padding-left: var(--spacing-unit-x4);

    > div {
      display: flex;
      align-items: center;

      &:focus {
        outline: none;
      }

      &:focus-visible {
        box-shadow: var(--keyboard-focus-box-shadow);
      }
    }

    &-text {
      flex: 1;
      border-bottom: var(--standard-border);
      padding: var(--spacing-unit-x2) 0;
      margin-left: var(--spacing-unit-x2);
    }
  }

  &__tr:hover &__td {
    background-color: var(--lighter-off-white);
  }

  &__td {
    border-bottom: var(--standard-border);
    padding: var(--spacing-unit-x2) 0;

    &-last {
      padding: 0;
      border-bottom: none;

      &-inner {
        margin-right: var(--spacing-unit-x4);
        padding: var(--spacing-unit-x2) 0;
        border-bottom: var(--standard-border);
      }
    }
  }

  &__row-selected {
    background-color: var(--off-white-focus);

    &:hover {
      background-color: var(--lighter-off-white);
    }
  }

  &__row-last &__td,
  &__row-last &__td-last-inner,
  &__row-last &__expander-text {
    border-bottom: none;
  }

  &__thead &__th {
    border-bottom: var(--input-border-hover);
  }

  .color-label {
    &-open,
    &-won,
    &-lost {
      padding: var(--half-spacing-unit) var(--spacing-unit);
      border-radius: var(--legend-border-radius);
    }

    &-open {
      background-color: var(--report-open-background);
      color: var(--darker-blue);
    }

    &-won {
      background-color: var(--report-won-background);
      color: var(--highlight-green);
    }

    &-lost {
      background-color: var(--report-lost-background);
      color: var(--highlight-red);
    }
  }

  &__expand-toggle {
    transform: rotate(0deg);
    transition: 75ms;
  }

  &__expand-toggled {
    transform: rotate(90deg);
    transition: 75ms;
  }
}
