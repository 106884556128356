.create-program-template-modal {
  width: 50rem;

  &__accordion,
  &__template-name__container {
    margin-top: var(--spacing-unit-x4);
  }

  &__accordion {
    &__button {
      padding: 0;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      padding-left: var(--spacing-unit);
      width: 100%;

      &-title {
        display: flex;
        align-items: center;
        gap: var(--spacing-unit);

        &-icon .svg {
          margin-top: calc(var(--spacing-unit-x2) / 12);
          transform: none !important;
        }
      }
    }

    &__item {
      padding: 0;
      border-bottom: none;
    }

    &__panel {
      margin-top: 0;
    }
  }

  &__asset-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-unit-x2) 0;
    border-bottom: var(--standard-border);

    &:last-of-type {
      border-bottom: none;
    }

    &-container {
      overflow: auto;
    }

    .svg {
      fill: var(--teal);
      margin-right: var(--spacing-unit);
    }

    &-left {
      display: flex;
      align-items: center;

      &-title {
        max-width: var(--spacing-unit-x27);
        text-align: left;

        &:hover {
          cursor: default;
        }
      }
    }

    &-input {
      width: 27.5rem;
    }
  }
}
