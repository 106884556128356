.input-to-select-mapping-row {
  display: flex;
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  justify-content: space-between;

  &:hover {
    background-color: var(--hover-row-background-teal);

    .input-to-select-mapping-row__actions {
      display: flex;
    }
  }

  &__inputs {
    display: flex;
    align-items: flex-start;
  }

  &__input,
  &__select {
    width: 32rem;
  }

  &__seperator,
  &__input,
  &__select {
    margin-right: var(--spacing-unit-x3);
  }

  &__seperator {
    margin-top: var(--spacing-unit-x2);
  }

  &__actions {
    display: none;
  }

  &__button {
    margin: 0;
    height: var(--spacing-unit-x6);
    width: var(--spacing-unit-x6) !important;
    justify-content: center;

    &:hover {
      background-color: var(--hover-row-button-background-teal);
    }

    & > .svg {
      fill: var(--text-gray);
    }

    &-disabled {
      pointer-events: none;

      &:hover {
        background: none !important;
      }
    }
  }

  &__delete {
    &:hover {
      background-color: var(--status-warning);
    }

    &,
    &:hover {
      .svg {
        fill: var(--remove-red);
      }
    }
  }

  &__svg-disabled {
    opacity: 0.4;
    transition: fill 80ms linear;
  }
}
