.dirty-banner {
  position: fixed;
  z-index: var(--z-index-banner);
  top: 0;
  left: 0;
  right: 0;
  height: var(--spacing-unit-x10);
  display: flex;
  background-color: var(--dark-blue);
  color: var(--white);

  &__nav {
    width: var(--nav-width);
    display: flex;
    align-items: center;
    justify-content: center;

    .svg {
      width: 7.41rem;
      height: 50%;
      fill: var(--white);
    }

    &-mobile {
      display: none;
    }
    /* stylelint-disable */
    @media (max-width: 1116px) {
      /* stylelint-enable */
      & {
        display: none;
      }

      &-mobile {
        min-width: var(--nav-width-collapsed);
        max-width: var(--nav-width-collapsed);
        display: flex;
        align-items: center;
        justify-content: center;

        .svg {
          width: 50%;
          height: 50%;
        }
      }
    }
  }

  &__main {
    width: calc(100vw - var(--nav-width));
    /* stylelint-disable */
    @media (max-width: 1116px) {
      /* stylelint-enable */
      & {
        width: calc(100vw - var(--nav-width-collapsed));
      }
    }
  }

  &__position {
    height: 100%;
    margin-left: var(--positioning-margin-left);
    /* stylelint-disable */
    @media (max-width: 1485px) {
      /* stylelint-enable */
      & {
        margin-left: 0;
        padding-right: 0;
      }
    }
  }

  &__content {
    max-width: var(--positioning-max-width);
    height: 100%;
    margin: 0 auto;
    padding: 0 var(--spacing-unit-x4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* stylelint-disable */
    @media (max-width: 1485px) {
      /* stylelint-enable */
      & {
        margin: 0;
      }
    }
  }

  &__title {
    flex: 1;
  }

  &__buttons {
    display: flex;
    gap: var(--spacing-unit);

    .button {
      height: var(--spacing-unit-x6);
    }
  }

  &__button {
    &-discard {
      color: var(--white);
      border: var(--standard-border-width) solid;

      &:focus {
        background-color: unset;
      }

      &:hover {
        color: var(--dark-blue);
        background-color: var(--white);
      }
    }

    &-save {
      &[disabled] {
        pointer-events: none;
      }

      &-container {
        cursor: not-allowed;
      }
    }
  }
}
