.sales-segments {
  &__contacts-section,
  &__prospects-section,
  &__opportunities-section {
    margin-top: var(--spacing-unit-x10);
  }

  &__segment-option {
    margin-top: var(--spacing-unit-x3);

    &__content {
      margin-top: var(--spacing-unit-x3);
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }
  }
}
