.listing-page-with-tabs {
  position: relative;
  padding-top: 0;

  .tabs-ao {
    .tabs-ao__list {
      left: var(--spacing-unit-x4);
      position: absolute;
      top: var(--spacing-unit-x14);
      z-index: var(--z-index-999);
    }
  }

  .list-page-scroll-area {
    height: 90vh;
  }


  .actionable-nested-table__body {
    margin-top: 0;
  }
}

