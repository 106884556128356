.fav-icon {
  padding: var(--spacing-unit-x4);

  &__header {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__caution {
    margin: var(--spacing-unit-x4) 0;

    .caution__text {
      display: block;
    }
  }

  &__form {
    &__actions {
      display: flex;
      margin-top: var(--spacing-unit-x6);

      .button--primary {
        margin-right: var(--spacing-unit-x4);
      }
    }
  }
}
