.content-validations {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit);
  padding: calc(var(--half-spacing-unit) + var(--spacing-unit)) var(--spacing-unit-x3) var(--spacing-unit-x3) var(--spacing-unit-x10);

  &__validation {
    display: flex;
    gap: var(--spacing-unit-x2);
    padding: var(--half-spacing-unit) 0;

    .svg {
      margin-top: var(--half-spacing-unit);
    }

    .spinner {
      &__wrapper {
        width: unset;
        padding: 0;
      }

      &__svg {
        min-width: 1.33rem;
        max-width: 1.33rem;
        min-height: 1.33rem;
        max-height: 1.33rem;
      }
    }
  }

  .button {
    margin: calc(var(--half-spacing-unit) + var(--spacing-unit)) 0;
  }
}
