
.columns-order-modal {
  width: 50rem;

  &__body {
    max-height: 55rem;

    &-title {
      margin-bottom: var(--spacing-unit-x3);

      :first-child {
        margin-bottom: var(--spacing-unit);
        margin-top: var(--spacing-unit-x2);
      }
    }
  }

  &__column {
    padding: var(--spacing-unit) var(--spacing-unit-x2);
  }

  &__checkbox {
    margin-right: auto;
  }

  &__button-cancel {
    margin-left: var(--spacing-unit-x3);
  }
}
