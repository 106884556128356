.media-card {
  border-radius: var(--standard-border-radius);
  border: var(--standard-border);
  background-color: var(--white);
  width: 100%;
  overflow: hidden;

  &--selectable {
    cursor: pointer;
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--fit-content {
    width: fit-content;
  }

  &--blur {
    .media-card__body {
      opacity: 0.3;
    }
  }

  &:focus-visible {
    box-shadow: var(--indicator-focus-box-shadow);
    border-color: var(--dark-blue);
    outline: var(--input-outline-border);
  }

  &__body {
    border-bottom: var(--standard-border);
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: 100%;

    &--disabled {
      opacity: 0.5;
      background-color: var(--media-card-background-disabled);
    }

    &-empty {
      padding: 0;

      .static-image {
        margin-bottom: 0;
      }
    }

    &-hover {
      position: absolute;
      display: none;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: var(--media-card-background-hover);
      border-radius: var(--half-spacing-unit) var(--half-spacing-unit) 0 0;

      &-content {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__selected {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: var(--spacing-unit);
        }
      }

      &-actions {
        position: absolute;
        top: 0;
        right: 0;
        padding: var(--spacing-unit-x2);

        button,
        .button--icon {
          color: var(--white);

          .drop-down__tooltip {
            width: 100%;
            height: 100%;

            & > div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          svg {
            fill: var(--white);
          }

          &:hover {
            background-color: var(--darker-blue);

            svg {
              fill: var(--white);
            }
          }
        }
      }

      &-visible {
        display: unset;
      }
    }
  }

  &__main-action-button {
    margin: 0 auto;
    color: var(--white);

    &:hover {
      color: var(--dark-blue);
      background-color: var(--white);
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
    background-color: var(--white);
    max-height: var(--media-card-footer-height);

    &-info {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
    }

    &-title {
      gap: var(--spacing-unit);
      display: flex;
      align-items: center;
      height: var(--spacing-unit-x4);

      &__text {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        /* stylelint-disable */
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        /* stylelint-enable */
      }

      & .pill-label {
        margin: 0;
      }
    }

    &-description {
      height: var(--spacing-unit-x3);
    }

    &-tooltip {
      padding: calc(var(--half-spacing-unit) * 3) var(--spacing-unit-x2);
    }

    .hover-svg {
      fill: var(--light-gray);

      &:hover {
        fill: var(--text-teal);
      }
    }

    &--disabled {
      background-color: var(--media-card-background-disabled);
    }
  }

  &:hover {
    box-shadow: var(--media-card-box-shadow);

    .media-card__body-hover {
      display: unset;
    }

    .media-card__radio-container {
      display: block;
    }
  }

  .media-card__radio-container {
    display: none;
    margin-top: auto;

    .media-card__radio,
    .media-card__radio__radio-label {
      pointer-events: none;
      margin: 0;
      padding: 0;
    }
  }

  &--selected {
    border: var(--teal-border);

    .media-card__radio-container {
      display: block;
    }
  }
}
