
.complex-list {
  &__title-row {
    margin-bottom: var(--spacing-unit);
    display: flex;

    &-text {
      flex: 4;
    }

    &-actions {
      flex: 1;
    }
  }

  &__label-row,
  &__row {
    display: flex;
    padding: var(--spacing-unit-x2) 0;
  }

  &__label-row {
    margin-top: var(--spacing-unit-x2);
    padding-bottom: var(--spacing-unit);
    border-bottom: var(--standard-border);
  }

  &__row {
    border-bottom: var(--standard-border);

    &-parent,
    &-child {
      border-bottom: none;
    }

    &-parent {
      padding-bottom: 0;
    }

    &-child:not(&-last) {
      padding-bottom: 0;
    }

    &-last {
      border-bottom: var(--standard-border);

      &-parent {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  &__label-column {
    flex: 2;

    &-child {
      margin-left: var(--spacing-unit-x4);
      margin-right: -2rem;
    }

    &-svg {
      padding-left: var(--spacing-unit);
      position: relative;

      .svg {
        fill: var(--light-gray);
        position: absolute;
        top: -0.1667rem;
      }
    }
  }

  &__label-data {
    flex: 1;
  }

  &__num-pct-parent {
    width: fit-content;
  }

  &__number-display {
    display: none;
  }

  &__num-pct-parent:hover &__pct-display {
    display: none;
  }

  &__num-pct-parent:hover &__number-display {
    display: inline;
  }

  &__highlighted {
    div {
      color: var(--red);
    }
  }
}
