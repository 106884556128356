.upgrade-programs {
  &__footer {
    background-color: var(--white);
    border: var(--standard-border);
    border-left: none;
    bottom: var(--spacing-unit-x14);
    box-shadow: var(--footer-shadow);
    display: flex;
    gap: var(--spacing-unit-x2);
    justify-content: flex-end;
    padding: var(--spacing-unit-x4) 9.67rem;
    position: sticky;
  }

  &__on-review-page {
    padding-bottom: var(--spacing-unit-x18);
  }
  
  &__progress-bar {
    margin: 0 calc(var(--spacing-unit-x19) + var(--half-spacing-unit));
  }
}
