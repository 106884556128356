.automated-programs-listing-page-container {
  &__new-program {
    height: var(--spacing-unit-x6);

    .svg {
      fill: var(--white);
      margin-right: var(--spacing-unit);
    }
  }

  &__error-toast {
    left: 35%;

    &-message {
      padding: var(--spacing-unit-x2) 0;
    }
  }

  & .th.automated-programs-listing-page-container__contacts {
    padding-right: var(--spacing-unit);
  }

  & .folder-search__list .th.automated-programs-listing-page-container__contacts {
    padding-right: var(--spacing-unit-x4);
  }

  & .td .automated-programs-listing-page-container__contacts {
    padding-right: var(--spacing-unit-x7);
  }

  .actionable-nested-table__trh:last-of-type .table__right .typography {
    margin-right: 0;
  }

  .th.table__right.automated-programs-listing-page-container__contacts {
    padding-right: var(--spacing-unit-x7);
  }

  &__program-state {
    border-radius: 50%;
    height: calc(var(--spacing-unit) + var(--half-spacing-unit));
    width: calc(var(--spacing-unit) + var(--half-spacing-unit));
    background-color: var(--disabled-plus-gray);
    margin-left: var(--half-spacing-unit);
    margin-right: var(--spacing-unit);

    &-error {
      fill: var(--remove-red);
      margin-right: calc(var(--spacing-unit-x10) / 12);
      margin-left: calc(var(--spacing-unit-x10) / 12);
    }

    &-running {
      background-color: var(--success-green);
    }

    &-stopped {
      background-color: var(--yellow-caution);
    }
  }

  &__program-name {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: calc(-1 * var(--half-spacing-unit));

    &-typography {
      display: flex;
      flex-direction: row;
      padding-left: var(--spacing-unit);
    }

    .info-hover-card {
      visibility: hidden;
      padding-left: var(--spacing-unit);

      &__visible {
        visibility: visible;
      }
    }
  }

  .nested-table-row:hover .info-hover-card {
    visibility: visible;
  }
}
