.tableV2-draggable-row {
  &-drag-source {
    width: var(--spacing-unit-x2);
    height: var(--spacing-unit-x2);
    position: absolute;
    display: none;
    left: var(--half-spacing-unit);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: grab;

    svg {
      fill: var(--button-gray);
    }
  }

  &:hover .tableV2-draggable-row-drag-source {
    display: block;
  }

  &-dragging {
    background-color: var(--hover-row-background-teal);

    .tableV2-draggable-row-drag-source > svg {
      fill: none;
    }
  }

  &-over&-cannot-drop {
    border-bottom: none;
  }

  &-over-top&-cannot-drop {
    border-top: none;
  }

  &-over {
    border-bottom: var(--thick-border-width) solid var(--disabled-teal) !important;
  }

  &-over-top {
    border-top: var(--thick-border-width) solid var(--disabled-teal) !important;
  }
}
