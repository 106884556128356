.current-step {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-unit-x2) + var(--spacing-unit));
  padding-bottom: var(--spacing-unit-x3);

  &__header {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-unit-x4);
  }
  
  &__step {
    border-radius: var(--standard-border-radius);
    box-shadow: var(--program-flow-step-shadow);
    display: flex;
    flex-direction: row;
    width: fit-content;
    
    &-container {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
    }

    &-icon {
      height: unset;
      width: var(--spacing-unit-x6);
    }
    
    &-info {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
      padding: var(--spacing-unit) var(--spacing-unit-x4) var(--spacing-unit) var(--spacing-unit);
    }
  }
}