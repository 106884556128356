
.form-row {
  padding-bottom: var(--spacing-unit-x2);

  &--action-right,
  &--action-left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .label {
      flex: 0 0 100%;
    }

    .select,
    .input {
      flex: 0 1 calc(100% - var(--spacing-unit-x6));
    }

    button {
      flex: 0 0 var(--spacing-unit-x6);
      line-height: var(--spacing-unit-x2);
      padding: 0 var(--spacing-unit-x2);
    }
  }

  &--no-padding {
    padding-bottom: 0;
  }
}
