.checkbox-card {
  display: grid;
  grid-template-columns: auto 1fr auto;
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  padding: var(--spacing-unit-x2) var(--spacing-unit-x3) var(--spacing-unit-x2) var(--spacing-unit-x3);
  cursor: pointer;
  width: 100%;

  &__no-border {
    border: none;
    border-radius: 0;
  }

  &:focus-visible {
    border: var(--standard-border-dark-blue);
    background: var(--hover-row-background-teal);
    box-shadow: 0 0 0 var(--thick-border-width) var(--focus-light-teal);
    outline: none;
  }

  &__selected {
    border: var(--teal-border);
    background: var(--hover-row-background-teal);

    &:hover {
      background: var(--lighter-teal);
    }
  }

  &__hovered {
    border: var(--teal-border);
  }

  &__error {
    border: var(--program-step-border-error);
  }

  &__information {
    overflow: auto;
    align-self: center;

    &-description {
      margin-top: var(--half-spacing-unit);
    }
  }

  &__icon {
    &-error {
      fill: var(--remove-red) !important;
    }

    &-right,
    &-left {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
    }

    &-right {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
      margin-left: var(--spacing-unit-x3);
    }

    &-left {
      width: var(--spacing-unit-x6);
      height: var(--spacing-unit-x6);
      border-radius: var(--standard-border-radius);
      background: var(--page-back);
      margin-right: var(--spacing-unit-x3);

      &-disabled {
        & > .svg {
          fill: var(--button-gray) !important;
        }
      }

      &-selected {
        background: var(--teal);

        & > .svg {
          fill: var(--white) !important;
          filter: drop-shadow(var(--primary-button-text-shadow));
        }
      }
    }
  } 
  
  &__dropdown {
    &-selected {
      box-shadow: inset 0.3334rem 0 var(--teal);

      &:hover {
        background: var(--lighter-teal);
      }
    }

    &-focus {
      background: var(--hover-row-background-teal);
    }
  }
}