.alert-schedule {
  .single-select-dropdown {
    width: 16.67rem;
  }

  &__checkbox {
    &-container {
      margin-top: var(--spacing-unit-x4);
    }

    &-weekly-statistics {
      margin-bottom: var(--spacing-unit-x2);
      display: flex;
      gap: var(--spacing-unit);
      align-items: center;

      svg {
        fill: var(--light-gray);
      }
    }
  }

  &__schedule-details {
    align-items: center;
    background-color: var(--hover-row-background-teal);
    display: flex;
    gap: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit-x4);
    padding: var(--spacing-unit-x2);
    width: fit-content;
  }
}
