.expandable-step-description {
  &__description {
    display: flex;
    align-items: center;
  }

  &__expand-toggle {
    transform: rotate(0deg);
    transition: 75ms;
  }

  &__expand-toggled {
    transform: rotate(90deg);
    transition: 75ms;
  }

  &__expander {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: none;
    }

    .svg {
      margin-right: 0.33333rem;
    }
  }

  &__expansion {
    margin-top: 0.66666rem;
    margin-left: 4.25rem;
  }

  &-show {
    display: block;
  }

  &-no-show {
    display: none;
  }

  &__brief-description {
    display: inline-block;
  }

  &__step-svg {
    display: inline-block;
    margin-right: 1.3333rem;

    .program-step-icon {
      height: var(--spacing-unit-x4);
      width: var(--spacing-unit-x4);
      border-radius: calc(2rem / 12);

      .svg {
        height: var(--spacing-unit-x2);
        width: var(--spacing-unit-x2);
      }
    }
  }

  &__error {
    padding-left: var(--spacing-unit-x2);
    margin-top: var(--half-spacing-unit);

    .svg {
      fill: var(--remove-red);
    }
  }
}
