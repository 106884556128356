.step-three-mapping-field {
  display: grid;
  grid-template-columns: 16.5rem 5.34rem 1fr 1fr;
  padding: var(--spacing-unit-x2) 0;

  &__act-on-contacts-field {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit) + var(--half-spacing-unit));
  }

  &__column-centered {
    display: inline-flex;
    flex-flow: row;
    margin-right: var(--spacing-unit);
    margin-top: calc(var(--spacing-unit) + var(--half-spacing-unit));
  }

  &__field-status-column {
    display: flex;
    justify-content: center;
  }

  &__select,
  &__input,
  &__data-type {
    width: 20rem;
  }

  &__loader {
    &-container {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &-row {
      padding-left: 0.66667rem;

      & svg {
        color: var(--teal) !important;
      }
    }
  }

  &__format {
    &-container {
      display: grid;
      justify-items: center;
      grid-column: 1 / -1;
      margin-top: var(--spacing-unit);
    }

    &-box {
      align-items: center;
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
      display: inline-flex;
      gap: var(--spacing-unit-x4);
      padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
    }
  }

  &__tooltip {
    padding: calc(var(--half-spacing-unit) + var(--spacing-unit-x2)) var(--spacing-unit-x3) var(--spacing-unit-x3) var(--spacing-unit-x3);

    &-wrapper {
      align-items: baseline;
      display: flex;
    }

    &-container {
      display: inline-flex;
      overflow: hidden;
      margin-top: var(--spacing-unit-x2);

      &:hover .svg--icon,
      &:active .svg--icon,
      &:focus .svg--icon {
        fill: var(--hover-create-option-icon) !important;
      }

      .svg {
        margin-bottom: calc(var(--standard-border-width) * -1);
      }
    }

    & .typography {
      color: var(--white) !important;
    }

    &-header {
      padding-bottom: var(--spacing-unit);
    }

    .svg {
      fill: var(--light-gray);
    }
  }
}
