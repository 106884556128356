.resizable-menu {
  display: flex;
  user-select: none !important;

  .button--navigation-button {
    height: var(--spacing-unit-x5);
    width: var(--spacing-unit-x5);

    > svg {
      height: var(--spacing-unit-x2) !important;
      width: var(--spacing-unit-x2) !important;
    }
  }

  &__collapsed {
    width: var(--spacing-unit-x6) !important;

    & .menu {
      display: none;
    }

    & .resizable-menu__resize-bar {
      cursor: unset !important;
      left: 2.9rem !important;
      width: calc(var(--spacing-unit-x2) / 12) !important;

      &:hover {
        background-color: var(--button-border-gray) !important;
        box-shadow: unset;
      }
    }

    & .tooltip__trigger {
      margin-left: 0 !important;
    }

    & .resizable-menu__tooltip .tooltip__trigger {
      margin-left: 0 !important;
    }
  }

  &__menu-content {
    flex: 1;
    border-top: var(--standard-border);

    & .menu {
      border-bottom: none !important;
      border-right: none !important;
      border-top: none !important;
    }
  }

  &__resize-bar {
    background-color: var(--dark-page-back);
    bottom: 7.16rem;
    top: var(--spacing-unit-x11);
    width: calc(var(--half-spacing-unit) / 4);
    z-index: var(--z-index-9);

    &::before,
    &::after {
      bottom: 0;
      content: '';
      position: absolute;
      top: 0;
      width: var(--spacing-unit-x2);
      z-index: -1;
    }

    &::before {
      margin-left: - var(--spacing-unit);
      left: - var(--spacing-unit);
    }

    &::after {
      margin-right: var(--spacing-unit);
      right: - var(--spacing-unit);
    }

    &:hover {
      background-color: var(--teal);
      box-shadow: var(--resizable-bar-shadow);
      cursor: col-resize;
    }
  }

  &__resizing {
    background-color: var(--teal);
    cursor: col-resize;
  }

  &__toggle-button {
    background-color: var(--white);
    border: var(--radio-button-border-gray);
    position: sticky;
    top: 48rem;
    transition: all 0.3s ease;
    z-index: var(--z-index-900);

    > svg {
      margin-right: 0.3rem;
    }

    &:hover {
      background-color: var(--hover-create-option-icon);

      > svg {
        fill: var(--button-back-gray);
      }
    }

    &-collapsed {
      height: var(--spacing-unit-x6);
      left: 1.6rem;
      position: absolute;
      top: 48rem;
      transition: all 0.3s ease;

      > svg {
        margin-left: 0.58rem;
      }
    }
  }

  &__tooltip {
    width: 0;

    & .tooltip__trigger {
      margin-left: calc((var(--spacing-unit-x2) + var(--half-spacing-unit)) * -1) !important;
      margin-top: 42.5rem;
    }

    &__description {
      align-items: center;
      background-color: var(--gray);
      border-radius: var(--half-spacing-unit);
      color: var(--white);
      display: inline-flex;
      margin-bottom: 0.2rem;
      overflow: hidden;
      padding: var(--legend-border-radius) var(--spacing-unit);
      white-space: nowrap;
    }
  }

  & .list-picker-modal-body__menu-folders,
  .list-picker-modal-body__menu-tags {
    padding: 0;
  }
}
