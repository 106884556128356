.list-program-basic-details-editor {
  width: var(--detail-column-width);
  margin-left: var(--spacing-unit-x4);

  &__text-area {
    width: 100% !important;
    min-height: var(--spacing-unit-x14);
  }
   
  input,
  textarea,
  select {
    background-color: var(--white);
  }

  &__details {
    margin-top: var(--spacing-unit-x3);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x3);

    &-field {
      > :last-child {
        margin-top: var(--spacing-unit);
        white-space: break-spaces;
      }
    }

    label,
    &-timezone > div {
      margin: 0 0 var(--spacing-unit-x2);
      line-height: var(--line-height-smallish);
    }
     
    &-header-with-tooltip {
      display: inline-flex;
      align-items: center;
      gap: var(--spacing-unit);
    }
  }
}
