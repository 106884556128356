
.draggable-column {
  display: flex;
  align-items: center;
  user-select: none;

  &__over {
    box-shadow: 0 calc(var(--standard-border-width) * -2) 0 var(--teal) inset;

    &-top {
      box-shadow: 0 calc(var(--standard-border-width) * -2) 0 var(--teal);
    }
  }

  &__index {
    width: var(--spacing-unit-x6);
    text-align: right;
    margin-right: var(--spacing-unit-x2);
    flex-shrink: 0;
  }

  &__column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: grab;
    min-width: 27.5rem;
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    margin-right: var(--spacing-unit-x2);
    padding: var(--spacing-unit) 0;
    flex-grow: 1;
    
    &-remove {
      padding: 0 var(--spacing-unit);
      border-left: var(--standard-border);

      & svg {
        width: calc(10rem / 12) !important;
        height: calc(10rem / 12) !important;
      }

      & button {
        border-radius: var(--spacing-unit-x2);
        height: var(--spacing-unit-x4);
        width: var(--spacing-unit-x4);
      }
    }

    &-drag {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: var(--spacing-unit-x4);
      min-height: var(--spacing-unit-x4);

      &-icon {
        fill: var(--disabled-edit-columns-action);
      }
    }

    &-name {
      margin-right: var(--spacing-unit-x2);
      margin-left: calc(8rem / 12);
      width: 100%;
    }

    &-status-hidden,
    &-status-locked {
      margin-left: auto;
      margin-right: var(--spacing-unit-x2);
    }

    &-status-locked {
      display: none;
    }

    &-locked {
      border-color: var(--blue);

      & svg {
        color: var(--blue);
      }
    }

    &-hidden {
      background-color: var(--input-background);
    }

    &-dragged {
      background-color: var(--hover-row-background-teal);
      border-color: var(--border-teal);
    }
  }

  &__actions {
    visibility: hidden;
  }

  &:hover {
    .draggable-column__actions {
      visibility: visible;
    }

    .draggable-column__column:not(.draggable-column__column-locked) {
      border-color: var(--border-teal);
    }

    .draggable-column__column-drag-icon {
      fill: var(--text-gray);
    }
  }
}
