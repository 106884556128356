.trigger-button {
  .button--icon-right {
    height: var(--spacing-unit-x6);
    padding: var(--spacing-unit) var(--spacing-unit-x3);
    justify-content: space-between;

    .svg {
      margin-left: var(--spacing-unit);
      height: var(--spacing-unit);
      width: var(--spacing-unit);
    }
  }

  &__dropdown-label {
    display: flex;
    align-items: center;
    padding: 0 var(--spacing-unit);

    &-flex {
      gap: 0.75rem;
    }

    .typography {
      color: var(--gray) !important;
    }
  }

  &__menu-item {
    outline: none;
  }

  &__pointer-event {
    pointer-events: auto;
  }
}
