
.peoples-chart {
  &__reach-pct {
    width: 99.9%;
    height: 2.25rem;
    margin-bottom: var(--spacing-unit);
    position: relative;
    background-color: var(--icon-back-gray);

    > div {
      height: var(--spacing-unit);
    }
  }

  &__people {
    height: 2.25rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__people-main {
    background-color: var(--teal);
  }

  &__people-bg {
    width: 100%;
  }

  &__label {
    margin-top: var(--spacing-unit);
  }
}
