.check-status-card {
  padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: start;

  &__in-preview {
    grid-template-areas: 'auto 1fr' 'tooltip-trigger tooltip-trigger';
    row-gap: var(--spacing-unit-x3);
    border: none;

    .check-status-card__tooltip-trigger {
      grid-area: tooltip-trigger;
    }

    .check-status-card__image {
      align-self: start;
    }
  }

  &__image {
    width: var(--spacing-unit-x11);
    height: var(--spacing-unit-x10);
    align-self: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__button-main {
    min-width: var(--spacing-unit-x26);
    height: calc((var(--spacing-unit-x4) / 3) * 5);
  }

  &__button-check-again {
    display: flex;
    justify-content: center;
    padding: var(--spacing-unit) var(--spacing-unit-x3) var(--spacing-unit) var(--spacing-unit-x2);
    min-width: unset;
  }

  &__button-check-again-teal {
    display: flex;
    justify-content: center;
    padding: var(--spacing-unit) 0;
    min-width: unset;
  }

  &__dots {
    padding: 0;
  }
}
