.dropdown-with-toggles {
  width: 28.25rem;

  &__baseline {
    display: flex;
    gap: var(--spacing-unit);
    align-items: center;
    justify-content: flex-end;
    width: 28.25rem;
  }

  &__toggles {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    width: 28.25rem;
    background-color: var(--white);
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-unit-x2);

    &-right {
      justify-content: flex-end;
    }
  }

  &__more-info {
    border-top: var(--standard-border);
    padding: var(--spacing-unit-x2);
  }
}
