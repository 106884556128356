.program-lists-and-segments {
  min-width: 70.4rem;
  max-width: 87.1rem;
  margin-bottom: var(--spacing-unit-x4);

  &__source-top {
    border-top: var(--standard-border);
  }

  &__source-row {
    border-bottom: var(--standard-border);
    font-size: var(--large-font-size);
    color: var(--text-gray);
    padding: calc(var(--spacing-unit) + var(--half-spacing-unit)) var(--spacing-unit-x2);
    display: flex;
    justify-content: space-between;
    height: var(--spacing-unit-x7);

    &--subject {
      flex: 1 1 100%;
      cursor: pointer;
      text-align: left;

      svg {
        fill: var(--teal);
      }
    }

    &--subject-name {
      margin-left: var(--spacing-unit-x2);
    }

    &:hover {
      background-color: var(--input-background);

      & .program-lists-and-segments__row-button {
        display: flex;

        &.button--remove {
          margin-top: var(--half-spacing-unit);
        }
      }
    }
  }

  &__row-button {
    display: none;
    height: var(--spacing-unit-x3);
    flex: 0 0 auto;
    align-items: center;
  }

  &__list-button {
    margin: var(--spacing-unit-x4) var(--spacing-unit-x2) var(--spacing-unit-x2) 0;

    svg {
      margin-right: var(--spacing-unit);
    }
  }
}
