
.page-container {
  margin-top: var(--top-bar-height);
  height: calc(100vh - var(--top-bar-height));
  background-color: var(--page-back);
  overflow-y: auto;
  padding: var(--spacing-unit-x4);

  &--no-padding {
    padding: 0;
  }

  &--no-chrome {
    margin-top: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--white);
  }

  &--no-overflow {
    overflow: hidden;
  }
}

.root--iframe {
  &-visible .page-container {
    display: none;
  }
}


/* stylelint-disable */
@media (max-width: 1485px) {
  /* stylelint-enable */
  .page-container:not(&--always-padding) {
    padding: var(--spacing-unit-x4) 0;

    &--no-padding {
      padding: 0;
    }
  }
}