.drop-down {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: visible;

  div[role='menuitem'] {
    &:hover {
      outline: none;
    }
  }

  .caret-icon {
    flex: 0 0 0.58rem;
    transition: transform 250ms ease-out;
    position: relative;
    fill: var(--light-gray);
  }

  &__main-button {
    height: 100%;
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);

    &:hover {
      text-decoration: none;

      .caret-icon {
        fill: var(--text-gray);
      }

      .button--primary .caret-icon {
        fill: var(--white);
      }
    }
  }

  &__open {
    transform: rotate(180deg);
  }

  &__drop-down {
    background-color: var(--white);
    list-style: none;
    border-radius: var(--standard-border-radius);
    box-shadow: var(--drop-down-shadow);
    border: var(--standard-border);
    padding: var(--spacing-unit-x3);
    overflow: hidden;
    z-index: var(--z-index-body);

    &[data-side='top'] {
      margin-bottom: var(--spacing-unit);
    }

    &-align-right {
      margin: -2rem 2rem;
    }

    &-overflow {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }

    &-styled {
      row-gap: unset;
      margin-top: var(--spacing-unit);
      padding: calc((var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2)) / 2) 0;

      [data-radix-collection-item] {
        cursor: pointer;
        padding: var(--spacing-unit) var(--spacing-unit-x2);

        &:hover {
          background-color: var(--lighter-teal);
        }

        &[data-disabled] {
          &:hover {
            cursor: no-drop;
            background-color: inherit;
          }

          .typography {
            opacity: 0.5;
          }

          .svg {
            fill: var(--button-gray);
            color: var(--button-gray);
          }
        }

        .svg {
          fill: var(--text-gray);
          color: var(--text-gray);
          margin-right: var(--spacing-unit-x2);
        }
      }
    }
  }

  &__overflow-button {
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--half-spacing-unit);

    &:hover,
    &[data-state='open'] {
      background-color: var(--off-white-hover);
    }
  }

  &__tooltip {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__trigger-button > * {
    cursor: pointer;
  }

  &__footer {
    border-top: var(--standard-border);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit) var(--spacing-unit-x3);
    margin-top: var(--spacing-unit);
  }
}
