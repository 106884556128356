.review-segments-modal {
  max-width: unset;
  width: 50rem;
  
  & .modal-body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
    padding: var(--spacing-unit-x4) !important;
  }

  &__header {
    background-color: var(--input-background) !important;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4) !important;
  }
  
  &__info {
    background-color: var(--light-theme-background-darker);
    border-radius: var(--standard-border-radius);
    display: flex;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4) ;
  }

  & .tableV2 {
    min-width: unset;
    
    &-tbody {
      max-height: 25rem;
      overflow: auto;
    }
  }
}