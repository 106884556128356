.logo-modals-header {
  display: flex;
  justify-content: space-between;
  flex: 1 1 100%;

  &__header {
    display: flex;
    align-items: center;
  }

  &--title {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    /* stylelint-disable */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* stylelint-enable */

    &-preview {
      max-width: calc(var(--spacing-unit-x20) * 2);
    }
  }

  &--default {
    margin-left: var(--spacing-unit);
  }

  &__actions-btn {
    padding: calc(var(--spacing-unit-x10) / 6) var(--spacing-unit-x2) !important;
    margin-left: var(--spacing-unit-x2);

    .svg {
      margin-bottom: 0;
      width: 0.83rem;
    }

    &.button--float .svg {
      margin-left: var(--spacing-unit);
    }
  }
}
