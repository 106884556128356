.form-modal {
  width: 60rem;

  &__body {
    overflow-y: auto;
    padding: var(--spacing-unit-x4) !important;
  }

  &__new-form-button {
    margin-left: var(--spacing-unit-x2);

    &:hover {
      background-color: var(--lighter-teal);
    }
  }

  &__editor {
    border: var(--standard-border);
    border-left: none;
  }

  &__select-label {
    display: flex;
    gap: var(--spacing-unit);
  }

  .form-manage-block {
    display: flex;
    justify-content: space-between;
    align-items: self-end;

    .form-modal__personalization {
      flex: 1;
    }

    button {
      padding: var(--spacing-unit-x2);
    }

    svg {
      width: var(--spacing-unit-x2);
      height: var(--spacing-unit-x2);
      margin-right: var(--spacing-unit);
    }
  }

  .accordion-radix__item {
    border: 0;
  }

  .accordion-radix__panel {
    border: 0;
  }

  .toggle_title {
    color: var(--ao-var-black);
    margin-bottom: var(--spacing-unit);
  }

  &__toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-unit-x2);
    border: var(--standard-border);
    margin: var(--spacing-unit-x3) 0;
    border-radius: var(--standard-border-radius);

    button {
      min-width: 3rem;
      margin-left: var(--spacing-unit-x4);
    }
  }

  &__options__accordion__item {
    transition: all 0.5s;
    padding: 0;

    button {
      justify-content: flex-end;
      padding: 0;

      .typography {
        margin-left: var(--spacing-unit-x2);
      }
    }
  }

  .tabs-ao__list {
    min-width: unset !important;
    max-width: 17.5rem;
    margin-bottom: var(--spacing-unit-x2);

    button {
      margin-right: var(--spacing-unit-x2);

      .typography {
        margin-left: 0;
      }
    }
  }
}
