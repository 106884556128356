.illustration-modal {
  width: calc(var(--spacing-unit-x20) * 4 + var(--spacing-unit-x2) / 3);

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-unit-x2);

    &.modal-body {
      padding: var(--spacing-unit-x4) var(--spacing-unit-x6);
    }

    &-text {
      color: var(--light-gray);
    }
  }

  &__img {
    &-regular {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &-small {
      width: var(--spacing-unit-x14);
      height: var(--spacing-unit-x14);
    }

    &-wrapper {
      width: var(--empty-state-img-size);
      height: var(--empty-state-img-medium-height);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__button {
    padding: calc(var(--spacing-unit-x4) / 3) var(--spacing-unit-x4);
    max-width: calc(var(--spacing-unit-x25) * 2);
    width: 100%;
  }

  &__disclaimer-text {
    color: var(--light-gray);
  }
}
