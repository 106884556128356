.field-matching-warning-modal {
  max-width: 50rem;
  
  &__info-container {
    background-color: var(--input-background);
    border-radius: var(--standard-border-radius);
    padding: calc(var(--spacing-unit-x2) + var(--spacing-unit)) var(--spacing-unit-x4);
  }
  
  & .modal-body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
    padding: var(--spacing-unit-x4) !important;
  }

  &__header {
    background-color: var(--input-background) !important;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4) !important;
  }
  
  &__segment {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-unit);
    
    &-container {
      display: flex;
      flex-direction: column;
      gap: calc(var(--spacing-unit-x2) + var(--spacing-unit));
    }
  }
  
}