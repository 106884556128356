.number-badge {
  display: inline-block;
  padding: 0 calc(var(--spacing-unit-x3) / 2);
  border-radius: calc(var(--extra-large-border-radius) / 2);

  &__default {
    color: var(--text-gray);
    background-color: var(--chip-base);
  }

  &__important {
    color: var(--white);
    background-color: var(--remove-red);
  }

  &__primary {
    color: var(--white);
    background-color: var(--teal);
    text-shadow: var(--primary-small-button-text-shadow);
  }

  &__primary-inverted {
    color: var(--text-teal);
    background-color: var(--white);
  }

  &__added {
    color: var(--add-success);
    background-color: var(--status-active);
  }

  &__removed {
    color: var(--text-label-error);
    background-color: var(--status-error);
  }

  &__x-margin {
    margin: 0 var(--spacing-unit-x2);
  }
}
