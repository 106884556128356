.validations-list {
  &__item {
    &-header {
      display: flex;

      &:not(&-rest) {
        > :first-child {
          margin-top: 0.16667rem;
          margin-right: var(--spacing-unit-x2);
        }
      }

      &-align {
        align-items: center;
        flex-wrap: wrap;
      }

      &-disabledLinks {
        a {
          &:hover {
            text-decoration: none;
          }

          div {
            color: var(--lighter-gray) !important;

            &:hover {
              text-decoration: none;
              cursor: not-allowed;
            }
          }
        }
      }
    }

    &-tooltip {
      margin-left: var(--half-spacing-unit);
    }
  }
}
