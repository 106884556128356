.ab-split-first-section {
  display: block;

  &__header {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__content {
    display: flex;
    gap: var(--spacing-unit-x6);
    margin-top: -1rem;

    & > span {
      width: 30rem;
    }
  }

  &__name {
    max-width: 30rem;
    min-width: 23.5rem;
    width: 100%;
  }

  &__number {
    .input {
      padding-right: var(--spacing-unit);
    }

    .svg,
    .loader {
      right: var(--spacing-unit-x5);
      top: calc(var(--spacing-unit-x8) + var(--half-spacing-unit));
    }

    & > span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    width: 11rem;
  }
}
