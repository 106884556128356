.immediately-options {
  > div:first-child {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__note {
    margin-top: var(--spacing-unit-x2);
  }

  &__options,
  &__options-custom {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
  }

  &__options-custom {
    margin-top: var(--spacing-unit-x2);

    .checkbox__container {
      width: 10.67rem;
    }
  }

  &__label {
    margin-bottom: var(--spacing-unit-x2);
  }
}
