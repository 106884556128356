.marketing-domain__form {
  &__caution {
    margin: var(--spacing-unit-x4) 0;
  }

  &__content {
    max-width: calc(10 * var(--spacing-unit-x8));

    .tooltip__trigger--inline {
      display: block;
    }
  }

  &-domain-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x4);
  }

  &__reset-tooltip {
    max-width: calc(2 * var(--spacing-unit-x19));
  }

  &__checkbox-wrapper {
    display: flex;
    column-gap: var(--spacing-unit);
  }

  &__actions {
    display: flex;
    margin-top: var(--spacing-unit-x6);

    .button--primary {
      margin-right: var(--spacing-unit-x4);
    }
  }

  &__tooltip {
    padding: var(--half-spacing-unit) var(--spacing-unit-x2);
  }
}
