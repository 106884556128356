.upload-file {
  width: 50rem;
  height: auto;
  overflow: visible;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }

  .drop-zone {
    margin-top: 2rem;
    padding-top: var(--spacing-unit-x4);
    padding-bottom: var(--spacing-unit-x4);
    height: 22rem;
    display: flex;
    gap: (--spacing-unit-x3);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__file {
      width: 100%;
    }

    &__file-loaded {
      height: initial;
      padding-top: 0;
      padding-bottom: 0;

      svg {
        align-self: normal;
      }
    }
  }

  .drop-zone__text {
    margin-top: var(--spacing-unit-x2);
  }

  .drop-zone,
  &__initial-inputs--file-name-input {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__initial-inputs {
    label,
    .selectv2__label {
      margin-top: 0;
    }
  }

  &__link {
    margin-bottom: var(--spacing-unit-x4);

    .typography {
      color: var(--text-teal);

      &:hover {
        color: var(--darker-teal);
      }
    }
  }

  &__selectV2 {
    & .selectv2__clear-indicator {
      &-with-dropdown-indicator-hidden {
        margin-right: var(--spacing-unit) !important;
      }
    }
  }

  &__selectV2-container {
    position: relative;
    height: inherit;
    margin-top: var(--spacing-unit-x2);
    padding-left: var(--spacing-unit-x3);
    margin-left: var(--spacing-unit);
  }

  &__bottom-options {
    margin-top: var(--spacing-unit-x4);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);

    &__download {
      display: flex;
      flex-direction: row;
      gap: var(--spacing-unit);
    }

    &__date-container {
      .label {
        margin-top: 0;
      }

      &__date-text {
        margin-top: var(--spacing-unit);
      }

      &__date-picker {
        display: flex;
        justify-content: space-between;
        align-content: center;
        gap: var(--spacing-unit-x2);
        flex-direction: row;

        .date-picker {
          width: 33.4rem;
        }

        .tooltip__trigger {
          align-self: center;
        }

        .button-copy {
          border-radius: 0.3rem;
          border: none;
          padding-bottom: 0;
          padding-top: 0;
        }
      }
    }
  }

  &__replace-button {
    margin-top: var(--spacing-unit);
    color: var(--text-teal);

    &:hover {
      color: var(--darker-teal);
    }
  }

  .selectv2__clear-indicator {
    display: none;
  }

  .modal-body,
  .modal-footer__inner {
    padding: var(--spacing-unit-x4);
  }

  .modal-body {
    overflow: visible;
  }

  .modal-footer__inner {
    gap: var(--spacing-unit-x4);
  }

  .button.upload-file__button-upload {
    margin: 0;
    line-height: 1.6667rem;
  }

  &__button {
    &-cancel {
      padding: 0.9167rem 0;
    }

    &-upload {
      padding: 0.6667rem var(--spacing-unit-x4);
    }
  }
}
