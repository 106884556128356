
.sync-result-modal {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  max-height: 100%;
  max-width: 100%;
  overflow-y: scroll;

  .svg {
    width: var(--spacing-unit-x3);
    height: var(--spacing-unit-x3);
  }

  .modal-header__close-button {
    padding: var(--spacing-unit-x2);
    top: var(--spacing-unit-x2);
    right: var(--spacing-unit-x2);
  }

  .modal-header__inner {
    border-bottom: unset;
  }

  &__parent {
    display: flex;
  }

  &__float-child-left {
    padding-left: var(--spacing-unit-x6);
  }

  &__float-child-right {
    height: calc(100% - 20rem);
    width: calc(100% - 20rem);
  }
}
