.small-collapsible-banner {
  background-color: var(--lighter-teal);
  border-bottom: var(--standard-border);
  border-top: var(--half-spacing-unit) solid var(--teal);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  position: relative;
  transition: height 200ms ease-in-out;
  margin-top: var(--top-bar-height);

  &__actions {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-unit-x4);
    align-items: center;
  }
  
  &__collapse-button:hover {
    color: var( --darker-teal);
  }
  
  &__container {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-unit-x2);
    align-items: center;
  }

  &__icon {
    align-self: baseline;
  }
}

.root--iframe-visible {
  .small-collapsible-banner {
    display: none;
  }
}
