.folder-table-label {
  display: block;

  &__count {
    margin-left: var(--spacing-unit);
  }

  &__prefix {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    &-separator {
      margin: 0 var(--spacing-unit-x2);

      &.svg {
        fill: var(--button-gray);
        height: 0.667rem;
        width: 0.667rem;
      }
    }
  }

  &__text {
    margin: 0 0 0 var(--spacing-unit-x2);

    &-with-no-icon {
      margin: 0;
    }
  }

  &__content {
    padding-right: var(--spacing-unit-x2);
  }

  &__folder-tree {
    &__2 .breadcrumbs-item-with-tooltip {
      &:nth-child(2) {
        max-width: calc(30% - 1.5% / 2);
      }

      &:last-child {
        max-width: 100%;
      }
    }

    &__3 .breadcrumbs-item-with-tooltip {
      &:nth-child(2) {
        max-width: calc(15% - 1.5% / 2);
      }

      &:nth-child(4) {
        max-width: calc(25% - 1.5%);
      }

      &:last-child {
        max-width: 100%;
      }
    }

    &__4 .breadcrumbs-item-with-tooltip {
      &:nth-child(2) {
        max-width: calc(12.5% - 1.5% / 2);
      }

      &:nth-child(4) {
        max-width: calc(12.5% - 1.5%);
      }

      &:nth-child(6) {
        max-width: calc(25% - 1.5%);
      }

      &:last-child {
        max-width: 100%;
      }
    }
  }

  &__sub-types {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-unit);

    &__divider {
      margin-left: var(--spacing-unit-x2);
      margin-right: var(--half-spacing-unit);
      background-color: var(--button-back-gray);
      height: var(--spacing-unit-x3);
      width: calc(var(--half-spacing-unit) * 0.5);
    }
  }

  &__filter-tooltip__trigger-wrapper {
    margin-left: var(--spacing-unit);
  }
}
