.list-picker-modal-header {
  &__search-input {
    width: 25rem;
  }

  &__header {
    display: flex;
    align-items: center;
  }
}
