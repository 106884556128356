.select-crm-sources {
  &__header {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__description {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__select-sources {
    margin-bottom: calc(var(--spacing-unit-x4) / 3);
    gap: var(--spacing-unit-x2);
    display: flex;
    align-items: center;

    .button {
      height: var(--spacing-unit-x6);

      .svg {
        margin-right: var(--spacing-unit-x2);
      }
    }
  }

  &__table {
    border: none;

    > div {
      border-radius: 0;
    }

    &-container {
      align-items: center;
      display: flex;
      gap: var(--spacing-unit-x2);
      justify-content: center;
    }
  }

  &__source-name {
    align-items: center;
    display: inline-flex;
    gap: var(--spacing-unit);
  }
}
