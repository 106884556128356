.landing-page-composer-custom-code-sidebar {
  width: 35rem;
  border-left: var(--standard-border);
  position: relative;
  transition: width 0.25s ease-in-out;

  &:hover {
    .landing-page-composer-custom-code-sidebar__collapse-button {
      display: block;
    }
  }

  &--collapsed {
    width: 1.333rem;
    cursor: pointer;

    &:hover {
      width: 2.08333rem;
    }

    .landing-page-composer-custom-code-sidebar__collaps-button {
      display: block;
    }

    .landing-page-composer-custom-code-sidebar__container {
      display: none;
    }
  }

  &__toggle-button {
    position: absolute;
    top: var(--spacing-unit-x2);
    left: -1.25rem;
    width: var(--spacing-unit-x5);
    height: var(--spacing-unit-x5);
    border-radius: 50%;
    border: var(--hover-teal-border);
    padding: var(--half-spacing-unit);
  }

  &__collapse-button {
    display: none;
  }

  &__container {
    display: flex;
    flex: max-content;
    flex-direction: column;
    height: 100%;

    .tabs-ao {
      .tabs-ao__tab {
        border-left: none;
        border-top: none;
        border-radius: 0;
        padding: calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
      }

      .tabs-ao__panel {
        height: calc(100% - 3.75rem);
        overflow: auto;
        border: none;
      }
    }
  }

  &__tabs {
    height: 100%;
  }
}
