.page-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: var(--spacing-unit-x4);
  min-width: var(--positioning-min-width);

  &-background {
    background-color: var(--white);
    padding: var(--spacing-unit-x4) 0;
    margin-bottom: 0;
  }

  &__not-centered {
    padding-left: var(--spacing-unit-x4);
  }

  &__center-container {
    display: block;
  }

  &__back-button {
    &-text {
      font-size: var(--sub-header-font-size);
      color: var(--text-teal);
      display: flex;
      align-items: flex-end;
      padding-right: 0;
      line-height: var(--line-height-larger);
      height: auto;

      &:focus-visible {
        box-shadow: var(--keyboard-focus-box-shadow);
      }
    }

    &:focus-visible {
      box-shadow: var(--keyboard-focus-box-shadow);
    }
  }
}
