
.branch-step-detail {
  &__go-to {
    margin-left: var(--spacing-unit-x4);
  }

  &__svg {
    position: relative;
    margin: 0 var(--spacing-unit);
    top: calc(var(--half-spacing-unit) / 3);
  }

  &__icon {
    position: relative;
    margin: 0 var(--spacing-unit);
    top: calc(var(--spacing-unit) / 3);
  }
}
