
.edit-go-to-step {
  width: 31rem !important;

  &__icon {
    background-color: var(--light-orange);
    padding: var(--spacing-unit-x2);
    display: inline-block;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--orange);
    }
  }
}
