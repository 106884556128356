.manage-webinar-details-modal {
  width: 60rem;

  .modal-body {
    padding: var(--spacing-unit-x4);
  }

  &__header {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
  }

  &__name {
    &-wrapper {
      display: flex;
      width: 100%;
      align-items: flex-end;
      gap: var(--spacing-unit);

      .inputV2__wrapper {
        flex: 1 1 auto;
      }
    }
  }

  &__label {
    margin-bottom: var(--spacing-unit);
  }

  &__time {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
    padding: calc(var(--spacing-unit-x4) / 3) 0;

    &-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
    padding: calc(var(--spacing-unit-x4) / 3) 0;

    &-container {
      display: flex;
      justify-content: space-between;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  &__divider {
    width: var(--standard-border-width);
    background-color: var(--border-gray);
  }

  &__timezone {
    display: flex;
    flex-direction: column;
    min-width: var(--webinar-details-modal-timezone-picker-width);
    max-width: var(--webinar-details-modal-timezone-picker-width);
  }

  &__webinar-detail {
    display: flex;
    align-items: center;

    .label {
      width: 8rem;
    }

    .inputV2__wrapper {
      flex: 1 1 auto;
    }

    &-container {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-unit-x3);
      background-color: var(--light-theme-background-darker);
      border-radius: var(--standard-border-radius);
    }

    &-field {
      &-gray {
        background: var(--off-white-hover);
      }

      &__container {
        flex: 1;
      }

      &-container {
        display: flex;
        flex-direction: column;
        margin-top: var(--spacing-unit-x4);
        gap: var(--spacing-unit-x2);
      }

      &-disabled {
        color: var(--light-gray);
      }
    }
  }

  &__toggle-wrapper {
    padding: var(--spacing-unit-x2) 0;
    margin-top: var(--spacing-unit-x2);

    > :first-child:not(:only-child) {
      padding-right: var(--spacing-unit-x4);
      border-right: var(--standard-border);
    }

    > :nth-child(2) {
      padding-left: var(--spacing-unit-x4);
    }
  }

  &__footer {
    .modal-footer__inner {
      padding-right: var(--spacing-unit-x4);
      gap: var(--spacing-unit-x4);
    }
  }

  &__registration {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-unit-x3);
    background-color: var(--light-theme-background-darker);
    border-radius: var(--standard-border-radius);

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
      margin-top: var(--spacing-unit-x4);
    }
  }

  &__close-button {
    padding: 0;
  }

  &__main {
    padding-top: var(--spacing-unit-x4);
    border-top: var(--standard-border);
  }

  &__tabs {
    &__list {
      margin-bottom: var(--spacing-unit-x3);
      min-width: unset;
    }
  }

  &__content {
    margin-top: 0;
    padding-top: var(--spacing-unit-x4);
    border-top: var(--standard-border);
  }
}
