.collapsible-banner-collapse-button {
  &__icon-container {
    align-items: center;
    border-radius: var(--standard-border-radius);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: var(--spacing-unit-x6);
    transition: background-color 200ms ease-in-out 0s;
    width: var(--spacing-unit-x6);

    &:hover {
      background-color: var(--hover-row-button-background-teal);
      cursor: pointer;
    }
  }

  &__tooltip {
    height: fit-content !important;
    width: fit-content !important;

    &__trigger-wrapper {
      margin: var(--spacing-unit-x2) var(--spacing-unit-x2) 0 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}