.campaign-table {
  padding: 0;
  margin-bottom: var(--spacing-unit-x10);

  &__loading {
    .tableV2 {
      tr {
        min-height: var(--actionable-nested-table-row-height);
      }
    }
  }

  &__header {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4) var(--spacing-unit-x5);

    &-subtitle {
      margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x5);
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;

    &-dropdowns {
      display: flex;
      gap: var(--spacing-unit-x2);
      flex: 0;
    }

    &-search {
      display: flex;
      gap: var(--spacing-unit-x2);
    }
  }

  &__search {
    min-width: calc(var(--spacing-unit-x2) * 25);
  }

  &__details-cell {
    .pill-label {
      overflow: unset;
    }

    .svg {
      margin: 0 var(--spacing-unit);
    }

    &-tooltip {
      .svg {
        fill: var(--button-hover);
        margin: 0 var(--spacing-unit);
      }
    }
  }

  &__pipline-header-cell {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);

    & .tooltip__trigger {
      line-height: 0;
    }
  }

  &-button-download {
    button[data-state='open'] {
      background-color: var(--hover-row-button-background-teal);
    }
  }

  .tableV2-head__thead {
    z-index: initial;
  }
}
