.form-submission-info-hover-card {
  display: block;

  &__section {
    &-title {
      color: var(--light-gray);
    }

    &-tree {
      min-height: var(--spacing-unit-x17);
    }

    &-related,
    &-recent {
      display: flex;
      justify-content: space-between;
      padding: 0 0 var(--spacing-unit-x2) 0;
    }
  }

  &__divider {
    width: 100%;
    height: var(--standard-border-width);
    background-color: var(--border-gray);
    margin: var(--spacing-unit-x3) 0 var(--spacing-unit-x3) 0;
  }

  &__forms {
    display: flex;
    flex-direction: column;
    gap: 0.33rem;
    margin-bottom: var(--spacing-unit-x2);

    &-name {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      max-width: 24rem;

      & > svg {
        flex-shrink: 0;
      }
    }
  }

  &__activities {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);

    &-element {
      display: flex;
      justify-content: space-between;
    }

    &-name {
      display: flex;
      gap: var(--spacing-unit);
      align-items: center;

      &-compound {
        display: flex;
        gap: var(--half-spacing-unit);
      }
    }
  }

  &__loader {
    width: 100%;
    height: 100%;
  }

  &__info-hover {
    &__card-content {
      max-height: 58rem;
    }

    &-scroll {
      .scroll-area__viewport {
        max-height: 45rem;
      }
    }
  }
}
