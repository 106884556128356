
.message-overview {
  &__title-line {
    display: flex;
    margin-bottom: var(--spacing-unit-x4);
  }

  &__title-input {
    flex: 2;
    margin-right: var(--spacing-unit-x2);
  }

  &__sender-code {
    flex: 1;

    &-number {
      padding-top: 1.1rem;
      padding-left: 0.1rem;
    }
  }
}
