.info-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--standard-border-radius);
  margin: var(--spacing-unit-x2) 0;
  padding: var(--spacing-unit-x2) var(--spacing-unit-x3);

  &--active {
    background-color: var(--status-active);
    border: var(--standard-border-width) solid var(--status-active-border);
  }

  &--caution {
    background-color: var(--light-yellow);
    border: var(--standard-border-width) solid var(--yellow-border);
  }

  &--warning {
    background-color: var(--status-warning);
    border: var(--standard-border-width) solid var(--status-warning-border);
  }

  &--informative {
    background-color: var(--lighter-off-white);
  }

  &--wrapper {
    display: flex;
    align-items: center;

    &-status-text {
      justify-content: end;
    }
  }

  &__svg {
    margin-right: var(--spacing-unit-x2);

    .svg {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
    }
  }

  &--multiline {
    align-items: flex-start;
  }

  &--secondary {
    color: var(--text-teal);
    margin-left: var(--spacing-unit-x2);

    &:hover {
      background-color: var(--teal-secondary-alert);
    }
  }

  &--no-margin {
    margin: 0;
  }
}
