.upgraded-forms-summary-modal {
  width: 80rem;

  &__cell-name {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
  }

  &__body {
    > .typography {
      margin-bottom: var(--spacing-unit-x4);
    }
  }
}
