.edit-modal {
  width: 50rem;

  &.modal {
    overflow: unset;
  }

  .modal-body {
    padding: var(--spacing-unit-x4);
  }

  .modal-footer__inner {
    padding: var(--spacing-unit-x4);
  }

  .form-row {
    padding-top: var(--spacing-unit-x4);
    padding-bottom: 0;
  }

  &__back-button-svg {
    margin-right: var(--spacing-unit-x2);
  }

  &__label {
    margin-bottom: var(--spacing-unit);
  }

  &__modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x5);
    gap: 0.75rem;
  }

  &__description {
    line-height: var(--line-height-large);
    margin-bottom: var(--spacing-unit);
    margin-right: var(--spacing-unit);
  }

  &__scroll {
    & .scroll-area {
      &__viewport {
        max-height: 37rem;
      }

      &__scrollbar {
        padding: 1.33rem 0;
      }
    }
  }
}
