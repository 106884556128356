.act-on-contacts-content-mapping {
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
   
    &-row {
      display: flex;
      gap: var(--spacing-unit-x3);
      margin-bottom: var(--spacing-unit-x2);
      width: calc(100% - var(--spacing-unit-x8));
    }
  }

  &__row {
    display: flex;
    gap: var(--spacing-unit-x2);
  
    > :first-child {
      margin-right: var(--spacing-unit);
    }

    &-field,
    &-name {
      width: 100%;
    }
  }

  &__row + &__row {
    margin-top: var(--spacing-unit-x2);
  }
   
  &__delete {
    width: var(--spacing-unit-x6);
  }
   
  &__footer {
    padding-top: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit-x4);
    border-top: var(--standard-border);
  }
   
  &__error {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-unit);
    margin-top: 0.8334rem;

    svg,
    div {
      color: var(--error-text);
    }
  }

  &__input-error {
    border-color: var(--error);
  }
}
