.add-sender-modal {
  width: 100%;
  max-width: 50rem;

  &__header {
    &--default {
      margin-left: var(--half-spacing-unit);
    }
  }

  &__input-list {
    max-width: 27.25rem;

    .tooltip__trigger {
      display: block;
      width: 100%;
    }
  }

  &__loader {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
