.highlightable-text-area {
  position: relative;
  padding: 0;
  border: none;
  margin-bottom: 2rem;

  .auto-resize-textarea,
  &__highlighted-output {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
  }

  &__highlighted-output {
    z-index: 0;
    top: 2.75rem;
    white-space: pre-wrap;
    background-color: var(--input-background);
    margin: 0;
    width: 100%;
    border-radius: 0.25rem;
    color: var(--text-gray);
    font-size: var(--large-font-size);
    line-height: var(--line-height-large);
    padding: var(--spacing-unit-x2);
    transition: var(--linear-80);
    margin-bottom: var(--spacing-unit-x2);
    overflow-wrap: break-word;
  }

  .auto-resize-textarea {
    z-index: 1;
    color: transparent;
    caret-color: var(--black);
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  .textarea:hover + &__highlighted-output,
  .textarea:focus + &__highlighted-output {
    background-color: var(--white);
  }

  &__highlight {
    box-shadow: 0 0 0 0.1rem var(--remove-red);
    background-color: var(--light-red);
  }

  &__highlight + &__highlight {
    box-shadow: 0 0.1rem 0 0 var(--remove-red), 0 -0.1rem 0 0 var(--remove-red), 0.1rem 0 0 var(--remove-red);
  }

  &__no-highlight + &__highlight + &__no-highlight {
    box-shadow: none;
  }
}
