.poll-block-modal {
  width: 50rem;

  .modal-body {
    max-height: 50rem;
    overflow-y: auto;
  }

  &__info {
    margin-bottom: var(--spacing-unit-x3);
  }

  &__upload {
    .drop-zone {
      padding: 0;
      height: 100%;
      min-height: 12rem;
      justify-content: space-evenly;

      &__division {
        display: none;
      }

      &__text {
        margin-top: 0;
      }
    }

    &-image {
      height: 100%;
      min-height: 12rem;

      .upload-image {
        &-holder {
          max-height: 12rem;

          img {
            max-height: 12rem;
            min-height: 11rem;
            width: 100%;
            object-fit: cover;
            border-radius: var(--large-border-radius);
            padding: unset;
            aspect-ratio: 3.88 / 1;
          }
        }

        &-hover-content {
          padding: unset;
          flex-direction: column;
        }

        &-hover-change-button {
          margin: unset;
        }
      }
    }

    &-toggle {
      margin-top: var(--spacing-unit-x2);
      margin-bottom: var(--spacing-unit-x3);

      .toggle {
        margin-left: 0;
      }

      &-label {
        display: flex;
        align-items: center;
      }
    }
  }

  &__image-label {
    display: flex;
    gap: var(--spacing-unit);
    align-items: center;
    margin-bottom: var(--spacing-unit-x3);

    &-open {
      margin-bottom: var(--spacing-unit);
    }

    &.typography__inline {
      margin-bottom: 0;
      padding-top: calc(var(--spacing-unit) / 3);
    }
  }

  &__image-toggle {
    padding-top: calc(var(--spacing-unit) / 3);
  }

  &__choices-label {
    margin-top: var(--spacing-unit-x3);
  }

  &__choice,
  &__comment-prompt {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__setting {
    margin-bottom: var(--spacing-unit-x2);

    &-label {
      margin-top: var(--spacing-unit-x3);
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__comment-prompt {
    display: flex;

    &-label {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }

    &-divider {
      width: var(--half-spacing-unit);
      border-radius: 1rem;
      background-color: var(--teal);
      margin-right: calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
      margin-left: 0.541666rem;

      & + div {
        width: 100%;
      }
    }
  }

  .labelV2__container:not(.poll-block-modal__upload-image-label) {
    margin-bottom: var(--spacing-unit-x2);
  }
}
