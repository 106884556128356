.spacer-block-V2 {
  min-width: 4.7333rem;

  &__wrapper {
    display: flex;
  }

  &-small {
    display: block;
    position: relative;
    min-width: calc(var(--spacing-unit-x6) + var(--spacing-unit-x3));
    margin: calc(var(--spacing-unit-x2) * -1) 0;
  }

  &-large {
    display: block;
    position: relative;
    min-width: var(--spacing-unit-x9);
    margin: calc(var(--spacing-unit-x2) * -1) 0;
  }

  &-half {
    display: block;
    position: relative;
    min-width: var(--spacing-unit-x9);

    &-bottom {
      border-left: var(--vertical-table-border);
      position: absolute;
      top: 0;
      bottom: var(--spacing-unit-x2);
    }

    &-top {
      border-left: var(--vertical-table-border);
      position: absolute;
      top: var(--spacing-unit-x2);
      bottom: 0;
    }
  }

  &__line,
  &__line-first {
    background: var(--dark-border-gray);
    height: 100%;
    left: 0.8869rem;
    position: absolute;
    top: 0;
    min-width: 0.0625rem;
    z-index: var(--z-index-999);

    &-first {
      left: 2.8875rem;
    }
  }

  &__last-block {
    min-width: 4.375rem;
  }

  &__first-depth-values {
    position: absolute;
    top: 1rem;
    width: var(--spacing-unit-x7);
  }
}
