
.program-errors-modal {
  width: 50rem;

  &__body-text {
    margin-bottom: var(--spacing-unit-x4);
    margin-top: var(--spacing-unit-x2);
  }

  &__section-header {
    margin-top: var(--spacing-unit-x2);
  }

  &__card-wrapper {
    display: flex;
    flex-direction: column;
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    margin: var(--spacing-unit-x4) 0;

    &:last-child {
      margin: var(--spacing-unit-x2) 0;
    }
  }

  &__card-title {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--light-theme-background-darker);
    border-bottom: var(--standard-border);
    padding: var(--spacing-unit) var(--spacing-unit-x4);
  }

  &__card-body {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x11);
  }

  &__card-message {
    & + & {
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__svg {
    margin: 0 var(--spacing-unit-x2) 0 0;

    .svg& {
      fill: var(--red);
      margin-right: var(--spacing-unit-x3);
      margin-left: var(--spacing-unit);
    }
  }

  &__fix-errors-trigger {
    &:has(button[disabled]) > div {
      cursor: not-allowed;
    }

    button[disabled] {
      pointer-events: none;
    }
  }
}
