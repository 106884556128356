.step-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x2);

  &__button {
    display: flex;
    gap: 0.84rem;
    width: fit-content;
  }

  &__content {
    margin: var(--spacing-unit-x2) 0;
  }
}
