.number-and-select-input {
  &__container {
    display: flex;
    width: fit-content;
    align-items: center;

    border: var(--standard-border);
    border-radius: var(--standard-border-radius);

    .selectv2__indicator-container {
      height: 2.8rem;
    }

    .selectv2__control {
      height: 2.8rem;

      &:not(.selectv2__control-menu-open):not(:hover) {
        border-color: transparent !important;
      }

      &.selectv2__control-menu-open,
      &:hover {
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
      }
    }

    .number-input__input-v2 {
      height: 100%;

      &:not(:focus):not(:hover) {
        border-color: transparent;
      }
    }

    .number-range-input__max {
      .number-input__input-v2 {
        &:hover,
        &:focus {
          border-top-right-radius: unset;
          border-bottom-right-radius: unset;
        }
      } 
    }

    .number-range-input__container {
      border: none;
    }

    &-error {
      border-color: var(--remove-red);
    }
  }

  &__single-input {
    .number-input__input-v2 {
      &:hover,
      &:focus {
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
      }
    }
  }

  &__error {
    margin-top: var(--spacing-unit);
    display: flex;
    align-items: flex-start;
    gap: 0.333rem;

    .svg {
      margin: var(--half-spacing-unit);
    }
  }

  &__divider {
    width: 0.083rem;
    border-left: var(--standard-border);
    height: 2.8rem;
  }

}
