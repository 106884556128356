
.send-alert-detail {
  &__recipients {
    margin-left: var(--spacing-unit-x4);
    margin-top: var(--spacing-unit);

    > div {
      display: flex;
      margin-bottom: var(--spacing-unit);
    }

    .svg {
      margin-right: var(--spacing-unit);
      margin-top: var(--half-spacing-unit);
    }
  }

  &__send-letter-container {
    .svg {
      position: relative;
      top: calc(var(--half-spacing-unit) / 3);
    }
  }
}
