.pane-drawer {
  background-color: var(--white);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);

  &__pane {
    border-bottom: var(--standard-border-dark);

    &--disabled {
      cursor: not-allowed;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-unit-x2);

      .svg {
        width: var(--spacing-unit-x4);
        height: var(--spacing-unit-x4);
      }
    }

    &:last-of-type {
      border-bottom: none;
    }

    &__item {
      padding: 0;

      &[data-disabled] button {
        cursor: not-allowed;

        &:hover {
          background: transparent;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    &__header {
      height: unset;
      gap: var(--spacing-unit);
    }

    &__button {
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

      &:has(.pane-drawer__pane--disabled) {
        justify-content: flex-end;
      }

      &:hover {
        background: var(--hover-row-background-teal);
      }
    }

    &__icon {
      .svg.accordion-radix__caret-icon {
        width: var(--spacing-unit-x4) !important;
        height: var(--spacing-unit-x4) !important;
      }
    }

    &__panel {
      margin-top: 0;

      &[data-state='open'] {
        animation: var(--slide-down-accordion);
      }

      &[data-state='closed'] {
        animation: var(--slide-up-accordion);
      }
    }

    &__inner-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 1.333rem;

      &-icon {
        width: var(--spacing-unit-x4) !important;
        height: var(--spacing-unit-x4) !important;
        fill: var(--text-gray);
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x4);
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x4);
    }

    &__tooltip:hover {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  &__dropdown {
    .svg {
      width: 1.3334rem;
      height: 1.3334rem;
    }
  }
}
