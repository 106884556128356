.web-page-picker-modal {
  .input-with-clear .input {
    padding-right: 0;
  }

  /* Fixes the link wrapping awkwardly */
  .asset-picker-sidebar__info-block {
    padding-right: var(--spacing-unit-x3);
    padding-left: var(--spacing-unit-x3);

    .link {
      gap: var(--half-spacing-unit);
    }
  }
}