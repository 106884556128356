
.schema-editor {
  &__divider {
    border-top: var(--standard-border);
    margin-bottom: unset;
  }

  &__table {
    margin-top: var(--spacing-unit-x7);
  }
   
  &__rows {
    > div {
      padding: var(--spacing-unit-x2) 0;
    }

    >:first-child {
      padding-top: var(--spacing-unit-x4);
    }

    >:last-child {
      padding-bottom: unset;
    }
  }
}
