.invalid-source-warning {
  margin-top: calc(var(--spacing-unit) / 5);

  &__icon {
    fill: var(--yellow-caution) !important;
    height: var(--spacing-unit-x2) !important;
    width: var(--spacing-unit-x2) !important;
  }

  &__header {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-unit-x2);

    &-icon {
      fill: var(--white);
    }

    &-title {
      color: var(--white);
    }
  }

  &__content {
    &-link {
      margin-left: var(--half-spacing-unit);
    }
  }
}
