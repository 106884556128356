
.sms-preview {
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x4);

  &__sms-row {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--spacing-unit-x4);

    &__reverse {
      flex-direction: row-reverse;
    }
  }

  &__sms-block {
    max-width: 21rem;
  }

  &__timestamp {
    margin-bottom: var(--spacing-unit);

    &__right {
      text-align: right;
    }
  }
}
