.number-range-input {
  display: table-caption;

  &__error {
    margin-top: var(--spacing-unit);
    display: flex;
    align-items: flex-start;
    gap: 0.333rem;

    .svg {
      margin: var(--half-spacing-unit);
    }
  }

  &__container {
    display: flex;
    width: fit-content;
    align-items: center;
    background-color: var(--white);

    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
   
    &-error {
      border-color: var(--remove-red);
    }

    .inputV2 {
      height: 100%;
    }

    .number-input__symbol {
      border: none;
      border-left: var(--standard-border);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    padding: 0 var(--half-spacing-unit);
  }

  &__max,
  &__min {
    .number-input__input-v2 {
      &:not(:focus):not(:hover) {
        border-color: transparent;
      }
    }

    &-empty {
      input {
        width: var(--spacing-unit-x9);
      }
    }
  }
}