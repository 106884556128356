.list-program-activities {
  padding-bottom: 0;

  &__table {
    border: none;
    max-width: unset;
  }

  > div:first-child {
    padding: 0 var(--spacing-unit-x4);
  }
}
