.users {
  &__content {
    padding: var(--spacing-unit-x4);

    .table {
      .no-value-td {
        color: var(--button-gray);
      }
    }
  }

  &__toast {
    .toast__toast {
      white-space: pre-line;
    }
  }
}

/* stylelint-disable */
@media (max-width: 1485px) {
  /* stylelint-enable */
  .users {
    margin-left: 0;

    &__content {
      padding: var(--spacing-unit-x4) 0;
    }
  }
}
