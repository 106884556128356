.insert-image-modal {
  width: 50rem;

  &__header {
    justify-content: flex-start;
    height: var(--spacing-unit-x12);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

    &__back-button {
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__body.modal-body {
    padding: var(--spacing-unit-x4);
  }

  &__footer {
    .modal-footer__inner {
      padding: var(--spacing-unit-x4);
    }
  }

  &__loader {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.split__options__wrapper {
  z-index: var(--z-index-999999);
}
