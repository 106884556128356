.bulk-actions-modal {
  width: 50rem;

  &__banner {
    margin-top: 0;
  }

  &__card-wrapper {
    display: flex;
    flex-direction: column;
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    overflow: hidden; /* fixes corners when background-color is set */
    margin: var(--spacing-unit-x4) 0;

    &:last-child {
      margin-top: var(--spacing-unit-x4);
      margin-bottom: 0;
    }
  }

  &__card-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--spacing-unit-x6);
    background-color: var(--light-theme-background-darker);
    border-bottom: var(--standard-border);
    padding: var(--spacing-unit) var(--spacing-unit-x4);
  }

  &__card-body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x9);

    &--hide-icons {
      padding-left: var(--spacing-unit-x4);
      padding-right: var(--spacing-unit-x4);
    }
  }

  &__card-message {
    & + & {
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__footer .modal-footer__inner {
    justify-content: right;
  }

  &__svg {
    margin: 0.3rem var(--spacing-unit-x2) 0 0;

    &-error {
      fill: var(--red);
    }

    &-success {
      fill: var(--success-green);
    }
  }

  &__acting {
    height: var(--spacing-unit-x6);
    padding-top: 0;
  }

  &__card-message-row {
    display: flex;
    align-items: center;

    &--has-description {
      cursor: pointer;
    }
  }

  &__card-message-icon {
    margin-right: var(--spacing-unit);
  }

  &__card-message-description {
    margin-left: var(--spacing-unit);
  }
}
