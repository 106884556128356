.program-manager-modals {
  display: block;

  &__error-toast {
    left: 35%;

    &-message {
      padding: var(--spacing-unit-x2) 0;
    }
  }
}
