.box {
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x3);
  padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

  &__current {
    background-color: var(--selectable-row-hover);
  }

  &__source {
    gap: calc(var(--spacing-unit) + var(--half-spacing-unit));
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  }
}