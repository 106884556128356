.preview-modal {
  &__title {
    font-size: var(--larger-font-size);
    line-height: var(--line-height-larger);
    color: var(--text-gray);
    font-weight: var(--font-weight-medium-light);
  }

  .modal-body {
    min-width: 35rem;

    &__inner {
      margin: 0 auto var(--spacing-unit-x6) auto;
    }
  }
}
