.add-recipients-block {
  display: flex;
  align-items: center;
  gap: var(--spacing-unit-x6);
  padding: var(--spacing-unit-x3);
  background-color: var(--input-background);
  justify-content: space-between;
  margin: 0 auto;
  border-radius: 0.3333rem;
  width: 71rem;

  &__info {
    &-header {
      display: flex;
      gap: 0.3333rem;
      align-items: center;
    }
  }

  &__button {
    display: flex;
    gap: 0.3333rem;
    width: var(--spacing-unit-x26);
    height: var(--spacing-unit-x6);
    padding: var(--spacing-unit) var(--spacing-unit-x3) var(--spacing-unit) var(--spacing-unit-x2);
    border-radius: 0.3333rem;
    flex: 0 0 auto;
  }

  .svg {
    fill: var(--light-gray);

    &:hover {
      fill: var(--text-teal);
    }
  }
}
