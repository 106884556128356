.hover-svg {
  display: inherit;

  &__hover-teal {
    &:hover {
      fill: var(--teal) !important;
    }
  }

  &__hover-text-teal {
    &:hover {
      fill: var(--text-teal) !important;
    }
  }
}
