.scheduled-send-modal {
  width: 50rem;
  display: block;

  .modal-body__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: var(--spacing-unit-x2);
  }

  &__inputs {
    display: flex;
    margin-bottom: var(--spacing-unit-x4);

    .radio-input {
      margin-top: 0;
    }

    > * {
      padding-right: var(--spacing-unit-x4);
    }

    .react-date-picker__wrapper {
      border: none;

      .react-date-picker__inputGroup__leadingZero {
        position: relative;
      }
    }

    .react-calendar__tile:disabled {
      text-decoration: none;
      cursor: default;
    }

    .radiogroup .label {
      margin-top: 0;
    }
  }

  &__calendar {
    width: 22rem;
    position: absolute;
  }

  &__time-estimate,
  &__allowed-window-warning {
    width: 43.3334rem;
  }

  &__time-estimate {
    display: flex;
    padding: var(--spacing-unit-x2);
    background-color: var(--input-background);
    line-height: var(--line-height-smallish);

    &-icon {
      flex: auto;
      margin-right: var(--spacing-unit-x2);

      span {
        background: var(--button-back-gray);
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--spacing-unit-x3);
        height: var(--spacing-unit-x3);
      }
    }

    span {
      font-weight: var(--font-weight-medium);
    }
  }

  &__time-error {
    margin-top: calc(-1 * var(--spacing-unit-x3));
    margin-bottom: var(--spacing-unit-x4);
  }

  .modal-body&__form-body {
    min-height: 33rem;
  }

  &__info {
    margin-top: var(--spacing-unit-x4);

    a {
      margin-left: var(--spacing-unit);

      .svg {
        margin-left: var(--spacing-unit);
        fill: var(--text-teal);
      }
    }
  }

  &__button-scheduling {
    height: unset;
  }
}
