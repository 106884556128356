.recipient-select-list {
  &__scrollbar {
    z-index: var(--z-index-body);
  }

  &__category-label {
    display: flex;
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
    align-items: center;
    position: sticky;
    top: 0;
    background-color: var(--white);
    z-index: var(--z-index-multi-select);

    &-action {
      height: var(--spacing-unit-x2);
      cursor: pointer;

      svg {
        color: var(--button-gray);
      }

      &:hover {
        svg {
          color: var(--text-teal);
        }
      }
    }

    > div {
      flex-shrink: 0;
    }

    &::after {
      content: '';
      display: inline-block;
      border-bottom: var(--standard-border);
      width: 100%;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-unit-x2);

    &-action {
      visibility: hidden;
    }

    &:hover {
      background-color: var(--hover-row-background-teal);
    }

    &:hover &-action {
      visibility: visible;
    }
  }

  &__category-section {
    position: relative;
  }

  &__view-users-button {
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    border-radius: var(--standard-border-radius);
    width: fit-content;
    cursor: pointer;
    outline: var(--standard-border-width) solid var(--border-teal);
    outline-offset: calc(var(--standard-border-width) * -1);

    &:hover {
      outline: none;
      background-color: var(--teal);

      > div {
        color: var(--white) !important;
      }
    }
  }
}
