.custom-input {
  display: flex;
  align-items: center;
  overflow: hidden;
  min-width: var(--spacing-unit-x2);
  grid-area: 1 / 1 / 2 / 3;

  &__counter {
    margin-left: var(--half-spacing-unit);
  }
}