.program-steps-listing {
  &__table-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4) 0;
  }

  &__not-empty {
    padding: 0;
  }

  &__table {
    max-width: unset;
    border: 0;

    .nested-table-row {
      height: unset;

      &:has(.list-program-step--is-editing) {
        background-color: var(--white) !important;

        .table-row-action {
          background: unset;

          button:hover {
            background-color: var(--lighter-teal);
          }
        }
      }

      &-dnd-drag-source {
        top: calc(var(--spacing-unit-x3) + var(--half-spacing-unit));
      }

      &__text {
        padding: 0;
      }
    }

    .table-row-primary-action__tooltip {
      align-items: flex-start;
      margin: var(--spacing-unit) 0;
      height: var(--spacing-unit-x6);

      button {
        margin: unset;
      }
    }

    &-empty-listing button {
      width: 25rem;
    }
  }

  &__add-step {
    border-top: var(--input-border-hover);
    padding: var(--spacing-unit-x4);

    &-button {
      height: var(--spacing-unit-x6);

      .svg {
        margin-right: var(--spacing-unit);
      }
    }
  }

  select {
    line-height: var(--spacing-unit-x3);
  }
}
