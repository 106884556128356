.sync-errors {
  width: 100%;

  &__panel-padding {
    text-align: center;
    margin-top: 0;
    background-color: var(--white);
    padding-top: var(--spacing-unit-x4);
  }

  &__great-news {
    font-weight: var(--font-weight-medium);
    font-size: var(--large-page-header-font);
    text-align: center;
    color: var(--text-gray);
  }

  &__sync-time {
    margin-top: var(--spacing-unit-x4);
    text-align: center;
    font-size: var(--large-font-size);
  }

  &__title-section {
    border-top: var(--standard-border);
    padding-bottom: var(--spacing-unit-x8);
  }

  &__title-text {
    display: flex;
    justify-content: center;
    align-content: center;
    border-bottom: 0;
    font-weight: var(--font-weight-lighter);
    margin-top: var(--spacing-unit-x6);
    font-size: var(--larger-font-size);
    color: var(--text-gray);
    line-height: var(--line-height-larger);
  }

  &__sub-header {
    font-weight: var(--font-weight-lightest);
    color: var(--light-gray);
  }

  &__table__table-holder {
    overflow-x: auto;
    overflow-y: auto;
    min-width: unset;
    border-top: unset;
  }

  .table__paginator,
  .paginator {
    min-width: unset;
    width: 100%;
  }

  &__table {
    margin-left: var(--spacing-unit-x4);
    margin-right: var(--spacing-unit-x4);
    overflow-x: auto;
    overflow-y: auto;

    .tbody {
      .td {
        cursor: default;
      }
    }

    .thead {
      .th {
        cursor: default;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
