.anti-spam-validations {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit);
  padding: 0 var(--spacing-unit-x3) var(--spacing-unit-x3) var(--spacing-unit-x10);

  &--no-padding {
    padding: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
    padding: calc(var(--half-spacing-unit) + var(--spacing-unit)) 0;

    &--minimal-padding {
      padding: var(--spacing-unit) 0;
    }

    &-li {
      margin-left: var(--spacing-unit-x5);
      text-indent: calc(var(--spacing-unit-x3) * -1);

      .text-link {
        display: inline;
      }
    }

    &-validation {
      display: flex;
      gap: var(--spacing-unit-x2);
      padding: var(--half-spacing-unit) 0;

      .svg {
        margin-top: var(--half-spacing-unit);
      }
    }
  }
}
