.export-options-screen {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  &__frequency {
    display: flex;

    &-label {
      width: 18rem;
    }
  }

  &__section {
    display: flex;
    align-items: baseline;

    &-header {
      width: 17.5rem;
      flex-shrink: 0;
    }

    &-content {
      flex-grow: 1;
      margin-left: var(--spacing-unit);
    }

    &:not(:first-child) {
      padding-top: var(--spacing-unit-x3);
      margin-top: var(--spacing-unit-x3);
      border-top: var(--standard-border);
    }
  }

  &__pill {
    border-radius: var(--standard-border-radius);
    background-color: var(--chip-base);
    color: var(--dark-blue);
    padding: 0.16rem var(--spacing-unit);
    margin: 0 var(--half-spacing-unit);
  }

  &__tooltip {
    align-items: center;
    display: flex;
    margin-right: var(--spacing-unit-x2);

    > .typography {
      width: auto;
    }
  }

  &__radio-label {
    > .typography {
      display: flex;
      gap: var(--spacing-unit);
    }
  }

  &__button-next {
    display: flex;
    justify-content: flex-end;
  }
}
