.segment-tree-page {
  width: 100%;
  
  &__ancestors-container {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit);
    padding: var(--spacing-unit-x2);
  }

  &__back-button:hover {
    background-color: unset;
  }

  &__breadcrumbs {
    display: grid;
    gap: var(--spacing-unit);

    &-one {
      grid-template-columns: auto;
    }
    
    &-two {
      grid-template-columns: repeat(2, auto);
      
      &-has-drop-down {
        grid-template-columns: repeat(3, auto);
      }
    }
    
    &-three {
      grid-template-columns: repeat(3, auto);
    }
    
    &-four {
      grid-template-columns: repeat(4, auto);
    }

    & .breadcrumbs__crumb {
      & .actionable-breadcrumbs-item {
        text-decoration: none;
      }
      
      & .breadcrumbs-item {
        padding: 0.66667rem;
      }
      
      & .breadcrumbs-item-with-tooltip {
        display: flex;
      }

      &:last-child .breadcrumbs-item:hover {
        cursor: auto;
      }
      
      &:not(:last-child) .breadcrumbs-item:hover {
        cursor: pointer;
        border-radius: var(--standard-border-radius);
        background-color: var(--off-white-focus);
        text-decoration: none;
      }
    } 
  }

  &__loader {
    left: unset;
    
    &-container {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &-row {
      padding-left: 0.66667rem;

      & svg {
        color: var(--teal) !important;
      }
    } 
  }

  &__scroll-area {
    height: 42.3rem;

    .scroll-area__viewport > div {
      display: flex !important;
      flex-direction: column;
      height: 100%;
    }
  }
}
