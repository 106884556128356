.form-manager {
  &__download-modal {
    .modal-body {
      padding: var(--spacing-unit-x4);
    }

    .modal-footer {
      &__inner {
        gap: var(--spacing-unit-x4);
        padding: var(--spacing-unit-x4);
      }
    }
  }

  &__clear-first {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__iframe {
    min-height: 100vh;
    width: calc(100% - var(--spacing-unit-x8));
    margin: 0 var(--spacing-unit-x4) var(--spacing-unit-x4);
    border: none;
  }
}
