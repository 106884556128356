.toggletip {
  &__trigger {
    &:hover {
      text-decoration: none;
    }

    &-default {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      &-small {
        padding: var(--half-spacing-unit);
      }

      &-medium {
        padding: calc(var(--spacing-unit-x2) / 3);
      }

      &-large {
        padding: var(--spacing-unit);
      }
    }

    &-inline {
      display: inline;
    }

    .svg {
      flex-shrink: 0;
    }
  }

  &__content {
    max-width: 36rem;
    color: var(--white);
    background-color: var(--text-gray);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x3);
    z-index: var(--z-index-999999);

    .typography {
      color: var(--white);
    }
  }

  &__title {
    margin-bottom: calc(var(--spacing-unit-x3) / 2);
    padding-right: var(--spacing-unit-x4);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--spacing-unit-x3);
  }

  &__arrow {
    fill: var(--text-gray);
  }

  &__header {
    position: relative;
    min-height: calc(var(--spacing-unit-x3) / 2);
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(-1 * var(--spacing-unit-x3) / 2);
    right: calc(-1 * var(--spacing-unit-x3) / 2);

    &--icon {
      fill: var(--white);
      padding: var(--spacing-unit);
      border-radius: 50%;
      opacity: 0.5;

      &:hover {
        fill: var(--text-gray);
        opacity: 1;
        background-color: var(--white);
      }
    }
  }
}
