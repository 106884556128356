.recipient-select {
  position: relative;

  &__input {
    &__container {
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__list {
    .scroll-area__viewport {
      max-height: 34rem;
    }

    &-no-footer {
      .scroll-area__viewport {
        max-height: 38.5rem;
      }
    }
  }

  &__content {
    position: absolute;
    background-color: var(--white);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    box-shadow: var(--recipients-select-box-shadow);
    z-index: 2;
    width: 100%;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: var(--standard-border);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x3);

    &:hover:not(&-add-group) {
      background-color: var(--hover-row-background-teal);
      cursor: pointer;

      svg {
        fill: var(--text-teal);
      }
    }

    &-add-group {
      button {
        width: 100%;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--spacing-unit-x5);
      height: var(--spacing-unit-x5);

      svg {
        fill: var(--button-gray);
      }
    }
  }
}
