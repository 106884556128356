.simple-line-chart {
  &__chart-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: var(--spacing-unit-x2);
    position: relative;
  }

  &__wrapper {
    position: absolute;
  }
}
