
.root--theme-dark .navigation {
  border-right: var(--standard-border-width) solid var(--dark-theme-border);
  background-color: var(--dark-theme-background);

  &__logo svg {
    fill: var(--dark-theme-selected);
  }

  &__expander {
    border-top: var(--standard-border-width) solid var(--dark-theme-border);
    border-right: var(--standard-border-width) solid var(--dark-theme-border);
    background-color: var(--dark-theme-background);

    svg {
      fill: var(--dark-theme-unselected);
    }

    span {
      color: var(--dark-theme-unselected);
    }

    &:hover {
      background-color: var(--dark-theme-background-darker);

      span {
        color: var(--dark-theme-selected);
      }

      svg {
        fill: var(--dark-theme-selected);
      }
    }
  }

  &__item-name {
    label {
      color: var(--dark-theme-unselected);
    }

    &--active > label {
      background-color: var(--dark-theme-selected-item-background);
      color: var(--dark-theme-selected-item);
    }

    &--root {
      background-color: var(--dark-theme-background);
    }

    &--root-active > label {
      color: var(--dark-theme-selected);
    }

    &--root:hover > label {
      color: var(--dark-theme-selected);
    }

    &--not-active:hover > label {
      color: var(--dark-theme-selected);
    }

    &:hover {
      label {
        color: var(--dark-theme-selected);
      }

      svg {
        fill: var(--dark-theme-selected);
      }
    }

    &--active:hover > label {
      color: var(--dark-theme-selected);
    }
  }

  &__group {
    &--depth-1 .navigation__mobile-header {
      color: var(--dark-theme-selected);
    }

    &--visible-hover.navigation__group--depth-1 {
      background-color: var(--dark-theme-background);
    }
  }

  &__mobile-header-line {
    background-color: var(--light-blue);
  }

  &__item-icon-default {
    fill: var(--dark-theme-unselected);
  }

  &__item-icon-selected {
    fill: var(--dark-theme-selected);
  }

  &__item-caret {
    fill: var(--dark-theme-unselected);
  }

  &--disabled {
    .navigation__item-name:hover svg {
      fill: var(--dark-theme-unselected);
    }

    a:hover,
    .navigation__item-name:hover > label {
      color: var(--dark-theme-unselected);
    }

    .navigation__item-name--active:hover > label {
      color: var(--dark-theme-selected-item);
    }
  }
}

.root--theme-dark .navigation--disabled.navigation--expanded {
  .navigation__item-name--root:hover svg {
    fill: var(--dark-theme-unselected);
  }

  .navigation__item-name--root-active > label {
    color: var(--dark-theme-selected);
  }

  .navigation__item-icon-selected {
    fill: var(--dark-theme-selected) !important;
  }
}
