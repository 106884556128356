.asset-picker-table-container {
  &__table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    scrollbar-gutter: stable;

    &.asset-picker-table--no-sidebar {
      padding-left: 3.3333rem;
    }

    & .tableV2 {
      & .tableV2-head__sticky-header::after {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom: var(--standard-border);
      }

      &__show-more {
        min-height: var(--spacing-unit-x11);
      }

      & .tooltip__trigger {
        cursor: default;
      }

      &__loading {
        height: var(--spacing-unit-x12);
        margin-top: unset;

        &.loader--page {
          & svg {
            width: 1.8rem;

            & circle {
              stroke-width: 0.6rem;
            }
          }
        }
      }

      & .breadcrumbs-item,
      & .tooltip__trigger {
        cursor: default;
        max-width: 100%;
      }
    }
  }
}
