.program-flow-step {
  position: absolute;
  display: flex;
  text-align: center;
  color: var(--gray);
  align-items: center;
  justify-content: center;

  &__node {
    z-index: 10;
    border-radius: 0.16rem;
    position: relative;
    display: inline-block;
    margin: var(--spacing-unit-x2);
    box-shadow: 0 0.16rem 0.33rem 0 rgba(0, 0, 0, 0.14);
    background-color: var(--white);
    overflow: visible;
    cursor: default;

    &--start {
      background-color: var(--green);
      box-shadow: none;

      &:hover {
        background-color: #94D1A1;
      }

      .program-flow-step__name {
        min-width: 0;
        margin: 0.75rem;
        color: var(--dark-green);
      }
    }

    &--new {
      border-radius: 0.333rem;
      box-shadow: 0 0 0 0.333rem var(--step-highlight);
      animation: highlight 900ms ease-in-out 5s forwards;
    }

    &:hover {
      text-decoration: none;
      z-index: 12;

      .program-flow-step__icons {
        display: flex;
      }
    }

    &-selected {
      border: var(--standard-border-dark-blue);
      box-shadow: 0 0 0 var(--thick-border-width) var(--focus-light-teal);
    }
  }

  @keyframes highlight {
    from {
      box-shadow: 0 0 0 0.333rem var(--step-highlight);
    }

    to {
      box-shadow: 0 0.16rem 0.33rem 0 rgba(0, 0, 0, 0.14);
    }
  }

  .program-step-icon {
    border-top-left-radius: 0.16rem;
    border-bottom-left-radius: 0.16rem;
    position: absolute;
    height: auto;
    width: var(--spacing-unit-x6);
    top: 0;
    left: 0;
    bottom: 0;
  }

  &__name {
    min-width: 6.25rem;
    border-radius: 0.16rem;
    cursor: pointer;
    flex: 1 1 auto;
    margin: 0.5rem var(--spacing-unit-x2) 0.5rem var(--spacing-unit-x8);
    display: block;
    font-size: var(--default-font-size);
    line-height: var(--larger-font-size);
    padding-top: 0.2rem;

    &:focus {
      outline: 0;
    }
  }

  &__letter {
    display: block;
    font-size: var(--smallest-font-size);
    font-weight: var(--font-weight-medium);
    color: var(--light-gray);
    text-align: left;
  }

  &__text {
    color: var(--text-gray);
    display: block;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* stylelint-enable */
  }

  &__plus {
    cursor: pointer;
    display: flex;
    z-index: 11;
    position: absolute;
    background-color: var(--light-gray);
    justify-content: center;
    align-items: center;
    border-radius: 100rem;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.5rem solid var(--page-back);
    transform: translate(-0.5rem, -0.5rem);
    padding: 0;
    box-sizing: content-box;

    svg {
      position: relative;
      width: 0.833rem;
      height: 0.833rem;
      fill: var(--white);
    }

    &:hover {
      background-color: var(--text-gray);
      border: 0.5rem solid var(--off-white-focus);
    }

    &--view-only {
      cursor: default;
      background-color: unset;
      border: unset;

      &:hover {
        cursor: default;
        background-color: unset;
        border: unset;
      }
    }
  }

  &__yes,
  &__no {
    cursor: default;
    position: absolute;
    bottom: -1.5rem;
    font-size: var(--smallest-font-size);
    line-height: var(--smallest-font-size);
  }

  &__no {
    color: var(--light-gray);
    left: 27%;
  }

  &__yes {
    color: var(--text-teal);
    left: 65%;
  }

  &__cover {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    opacity: 0.9;
    border-radius: 0.167rem;
    z-index: 2;
  }

  &__icons {
    box-sizing: border-box;
    background-color: #555D69;
    border-radius: calc(2rem / 12);
    transition: 120ms;
    position: absolute;
    display: none;
    top: -1rem;
    z-index: 10;

    &--base {
      right: -1.5rem;
    }

    &--shifted {
      right: -2rem;
    }
  }

  &__icon {
    height: 100%;
    display: flex;
    align-items: center;

    button {
      width: 2.5rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.167rem;
      padding: 0.75rem 0;

      &:hover {
        background-color: var(--hover-background-gray);

        svg {
          fill: var(--white);
        }
      }

      svg {
        height: 1rem;
        width: 1rem;
        fill: var(--page-back);
      }
    }

    &--view-only button {
      cursor: pointer;
      background-color: var(--disabled-gray);

      &:hover {
        cursor: pointer;
        background-color: var(--disabled-gray);
      }

      svg {
        fill: var(--page-back);
      }
    }
  }

  &__button {
    box-shadow: var(--program-flow-step-shadow);

    &:hover {
      text-decoration: none;
    }

    &--exit-step {
      cursor: default;

      * {
        cursor: default;
      }

      &:hover {
        cursor: default;
      }
    }

    &-error {
      border: var(--program-step-border-error);
      border-radius: var(--standard-border-radius);
    }
  }

  &__action-button {
    border-radius: 0.16rem;
    cursor: default;
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 0.167rem);
    height: calc(100% - 0.167rem);
    display: none;
    box-sizing: content-box;
    z-index: 10;
  }

  &__step-title {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);

    .svg {
      fill: var(--remove-red);
    }
  }
}

.program-flow--copy,
.program-flow--goto {
  .program-flow-step:hover {
    .program-flow-step__icons {
      display: none;
    }
  }
}

.program-flow--copy {
  .program-flow-step__node {
    opacity: 0.75;
  }

  .program-flow-step__plus {
    border: 0.5rem solid var(--light-teal);
    background-color: var(--focus-teal);

    &:hover {
      border: 0.5rem solid var(--light-teal);
      background-color: var(--text-teal);
    }
  }
}

.program-flow--goto {
  .program-flow-step__node--can-go-to {
    .program-flow-step__action-button {
      top: -0.25rem;
      left: -0.25rem;
      border: 0.333rem solid var(--yellow-border);

      &:hover {
        border-color: var(--yellow);
      }
    }
  }

  .program-flow-step__node--is-go-to {
    .program-flow-step__action-button {
      top: -0.083rem;
      left: -0.083rem;
      border: 0.2rem dashed var(--yellow-highlight);
      cursor: default;
    }
  }

  .program-flow-step__node--no-go-to {
    .program-flow-step__cover {
      display: block;
    }

    .program-flow-step__action-button {
      display: none;
    }
  }

  .program-flow-step__action-button {
    display: block;
  }

  .program-flow-step__plus {
    background-color: var(--lightest-gray);

    &:hover {
      background-color: var(--lightest-gray);
      border: 0.5rem solid var(--page-back);
    }
  }
}

@media print {
  .program-flow-step {
    &__plus {
      svg {
        fill: var(--gray);
      }
    }
  }
}
