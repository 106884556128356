.alert-filter-card {
  padding: var(--spacing-unit-x4);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);

  &__filter {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-name {
      display: flex;
      gap: var(--spacing-unit-x2);

      svg {
        fill: var(--text-teal);
        color: var(--text-teal);
      }
    }

    &-actions-container {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--spacing-unit-x6);
      height: var(--spacing-unit-x6);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);

      &-edit {
        &:hover {
          background-color: var(--lighter-teal);
          border-color: var(--border-teal);

          & > .svg {
            fill: var(--text-teal);
          }
        }
      }

      &-delete {
        &:hover {
          background-color: var(--status-warning);
          border-color: var(--status-warning-border);

          & > .svg {
            fill: var(--erase-hover-red);
          }
        }
      }
    }
  }

  &__conditions {
    border-top: var(--standard-border);
    margin-top: var(--spacing-unit-x4);

    &-header {
      padding-top: var(--spacing-unit-x3);

      &-expanded {
        padding-bottom: var(--spacing-unit-x3);
      }
    }

    &-visitors {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
      padding: calc(var(--spacing-unit-x16) / 12) var(--spacing-unit-x2);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);

      & + & {
        margin-top: var(--spacing-unit-x2);
      }

      svg {
        color: var(--text-teal);
      }

      &:has(&-logical) {
        width: fit-content;
      }
    }
  }
}
