.program-settings {
  display: flex;
  min-width: var(--positioning-min-width);
  max-width: var(--positioning-max-width);
  margin-bottom: var(--spacing-unit-x4);

  &__left-container {
    flex: 2;
    min-width: calc(var(--program-settings-left-container-max-width) - var(--program-settings-right-container-min-width));
    max-width: var(--program-settings-left-container-max-width);
    margin-right: var(--spacing-unit-x4);
  }

  &__right-container {
    flex: 0 0 var(--program-settings-right-container-min-width);

    > .button {
      display: flex;
    }
  }

  &__button-disabled {
    pointer-events: none;
  }

  &__section-header {
    display: flex;
    justify-content: space-between;
    padding-left: var(--spacing-unit-x4);
    padding-right: var(--spacing-unit-x4);

    &__link-icon {
      margin-right: calc(var(--spacing-unit) + var(--half-spacing-unit));
      transform: translate(0, 7%);
    }
  }

  &__sources-container {
    padding-bottom: var(--spacing-unit-x7);

    .container__title {
      padding: 0 var(--spacing-unit-x4);
    }
  }

  &__options-container,
  &__schedule-container {
    & .section-headline {
      margin-bottom: var(--spacing-unit-x3);
    }
  }

  &__detail {
    margin-bottom: var(--spacing-unit-x4);

    &-label {
      display: inline-block;
      margin-bottom: var(--spacing-unit);
      margin-left: var(--half-spacing-unit);
      cursor: pointer;
    }

    &-extra-text {
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__program-details {
    margin-top: var(--spacing-unit-x5);
  }

  &__table__table-holder,
  .table__paginator {
    border-radius: unset;
    border-right: none;
    border-left: none;
  }

  &__table__table-holder,
  .table__paginator,
  .paginator {
    min-width: initial;
    max-width: initial;
  }

  &__table__table-holder {
    border-top: none;
    border-bottom: none;
    padding-bottom: 0;

    &.table__has-pagination {
      & .tr:last-of-type {
        & .td {
          border-bottom: none;
        }
      }
    }

    & .tr:last-of-type {
      & .td {
        border-bottom: var(--standard-border);
      }
    }
  }
}
