.send-details-attach-note {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__conditional-text-container {
    display: flex;
    gap: var(--half-spacing-unit);
  }

  &__section-header {
    margin-bottom: var(--spacing-unit);
  }

  &__section-sub-text {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__toggle-container {
    padding-right: var(--spacing-unit-x2);
  }

  &__info-container_crm-warning {
    margin-top: var(--spacing-unit-x4);
    display: flex;
    justify-content: flex-start;
    gap: var(--spacing-unit-x2);
  }
}
