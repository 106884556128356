.program-early-exit-conditions {
  min-width: 70.4rem;
  max-width: 87.1rem;
  margin-bottom: var(--spacing-unit-x4);

  &__early-exit-empty {
    width: 30rem;
  }

  &__early-exit-row {
    display: flex;
    position: relative;
    border-bottom: var(--standard-border);
    height: var(--spacing-unit-x7);
    align-items: center;
    cursor: default;

    &:first-child {
      border-top: var(--standard-border);
    }

    &:hover {
      background-color: var(--hover-row-background-teal);

      .program-early-exit-conditions__row-buttons {
        display: flex;
      }
    }

    &-with-extra {
      height: var(--spacing-unit-x12);
      align-items: flex-start;
      padding-top: var(--spacing-unit);
    }

    &-condition {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-unit) var(--spacing-unit-x2);
      flex-wrap: nowrap;
      flex: 1;

      &-text,
      &-extra {
        display: flex;
        gap: var(--spacing-unit);
        max-width: 77.5rem;
      }

      &-text {
        align-items: center;
      }

      &-extra {
        gap: var(--spacing-unit);
        padding-top: var(--spacing-unit);

        &-inner {
          flex: 1;
          display: flex;
          align-items: center;
          gap: var(--spacing-unit);
        }

        &-arrow {
          margin-top: calc(-1 * calc(var(--spacing-unit) / 3));
        }
      }
    }
  }

  &__condition {
    flex: 1 1 auto;
    white-space: nowrap;
  }

  &__icon {
    &--message svg,
    &--list svg {
      margin-top: var(--half-spacing-unit);
      fill: var(--teal);
    }
  }

  &__segment-text {
    flex: 1 1 100%;

    .tooltip__trigger {
      max-width: 100%;
    }
  }

  &__row-buttons {
    position: absolute;
    display: none;
    top: var(--half-spacing-unit);
    right: 0;
    background: linear-gradient(90deg, rgba(249, 249, 249, 0) 0%, var(--hover-row-background-teal) 2.5rem, var(--hover-row-background-teal) 100%);
    padding-left: var(--spacing-unit-x6) !important;
    padding-right: var(--half-spacing-unit);
  }

  &__row-button {
    padding-top: var(--half-spacing-unit);
  }

  &__edit-button,
  &__delete-button {
    &:hover {
      background-color: var(--hover-row-button-background-teal);
    }
  }

  &__delete-button {
    margin-right: var(--spacing-unit);
  }

  &__list-button-ctr {
    margin: var(--spacing-unit-x4) var(--spacing-unit-x2) var(--spacing-unit-x2) 0;
  }

  &__list-button {
    height: var(--spacing-unit-x6);
    gap: var(--spacing-unit);
  }
}
