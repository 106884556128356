.pill {
  background-color: var(--teal);
  display: inline-block;
  padding: 0.16666rem var(--spacing-unit-x2);
  border-radius: var(--spacing-unit-x2);
  margin: 0 var(--spacing-unit-x2);

  &__no-margin {
    margin: 0;
  }

  &__extra-small {
    line-height: var(--line-height-standard);
    padding: calc(var(--spacing-unit) / 3) calc(var(--spacing-unit-x3) / 2);
  }

  &__small {
    padding: 0.16667rem var(--spacing-unit);
  }

  &__secondary {
    background-color: var(--purple-secondary);
    text-transform: uppercase;
    text-shadow: var(--secondary-pill-text-shadow);
    font-weight: var(--font-weight-bold);
  }

  &__secondary&__small {
    line-height: var(--line-height-standard);
    padding: 0.16666rem var(--spacing-unit-x2);
  }

  &__gray {
    background-color: var(--selectable-row-hover);
    color: var(--gray-pill);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-standard);
    padding: 0.16666rem var(--spacing-unit-x2);
  }

  &__gray&__small {
    line-height: var(--line-height-standard);
    padding: 0.16666rem var(--spacing-unit-x2);
  }

  &__gray&__extra-small {
    padding: calc(var(--spacing-unit) / 3) calc(var(--spacing-unit-x3) / 2);
  }

  &__bordered {
    background-color: var(--white);
    color: var(--text-gray);
    line-height: var(--line-height-large);
    padding: 0.17rem calc(var(--spacing-unit-x3) / 2);
    border: var(--standard-border-darker);
  }

  &__cursor-pointer {
    cursor: pointer;
  }
}
