.account-redirects-input {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-unit-x2);
    align-items: center;

    &-status {
      display: flex;
      align-items: center;
      padding: calc(var(--half-spacing-unit) / 2) var(--spacing-unit-x2);

      .svg {
        margin-right: var(--spacing-unit);
        width: var(--spacing-unit-x2);
        height: var(--spacing-unit-x2);
      }
    }
  }

  &__link {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__input {
    width: calc(var(--spacing-unit-x20) * 4);
  }

  &__actions {
    display: flex;
    margin-top: var(--spacing-unit-x6);

    .button {
      margin-right: var(--spacing-unit-x4);
    }
  }

  &__edit-button {
    color: var(--text-teal);
  }

  &__url {
    display: flex;
  }

  &__modal {
    &-info {
      margin-top: var(--spacing-unit-x2);

      &-item {
        padding-left: var(--spacing-unit-x4);
        margin-top: var(--half-spacing-unit);
        position: relative;

        &::before {
          content: '';
          width: var(--half-spacing-unit);
          height: var(--half-spacing-unit);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: var(--spacing-unit);
          background-color: var(--light-gray);
        }
      }
    }
  }
}
