.send-details-send-method {
  &__cards {
    margin-top: var(--spacing-unit-x2);

    &-later-options {
      margin-top: var(--spacing-unit-x2);

      &-text {
        margin-left: var(--spacing-unit-x4);
        margin-bottom: var(--spacing-unit-x2);
      }

      &-tooltip {
        display: flex;
      }
    }
  }

  &__send-now-suffix {
    padding-top: var(--spacing-unit-x4);
  }

  &__send-timezone-suffix {
    &-top {
      margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x2);
      display: flex;
      gap: var(--spacing-unit-x2);

      &-date {
        flex: 1;
      }

      &-time {
        position: relative;

        .time-picker-v2-picker {
          width: 12rem;
        }

        &__error-wrapper {
          position: absolute;
        }
      }
    }

    &-timezone {
      width: 100%;

      &-container {
        width: 100%;
        display: flex;
      }

      &-label {
        display: flex;
        align-items: center;
        gap: var(--spacing-unit);
      }

      &-divider {
        width: var(--half-spacing-unit);
        border-radius: 1rem;
        background-color: var(--teal);
        margin-right: var(--spacing-unit-x3);
        margin-left: 0.541666rem;

        & + div {
          width: 100%;
        }
      }
    }

    &-full-width {
      width: 100%;
    }
  }

  &__send-staggered-suffix {
    margin-top: var(--spacing-unit-x4);

    &-top {
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__send-adaptive-suffix {
    margin-top: var(--spacing-unit-x4);

    ul {
      padding-left: var(--spacing-unit-x4);
    }

    li {
      margin-bottom: var(--spacing-unit);
    }
  }

  .ant-picker-range {
    width: 100%;
  }

  &__error {
    border-color: var(--remove-red);
    margin-bottom: var(--spacing-unit);
  }
}
