.email-composer-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--standard-border);
  padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

  &__actions {
    display: flex;
    justify-content: center;
    gap: var(--spacing-unit-x2);
  }

  &__toast {
    .typography {
      padding: var(--spacing-unit-x2) 0;
    }
  }
}
