
.program-steps-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--spacing-unit-x2);
  margin-top: var(--spacing-unit-x6);

  &__button-hidden {
    padding-bottom: var(--spacing-unit-x4);
  }

  &__button {
    padding: var(--spacing-unit-x2);
    font-weight: var(--font-weight-medium-light);

    .svg {
      height: 1.5rem !important;
      width: 1.5rem !important;
    }
  }
}
