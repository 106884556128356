.listing-page {
  .tabs-ao__panel {
    display: none;
  }

  .tabs-ao__list {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__content {
    min-width: var(--positioning-min-width);
    padding: 0 var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x4);

    &-tableV2 {
      padding-top: var(--spacing-unit-x8);
    }
  }

  & .collapsible-menu__collapsed {
    height: fit-content;
    min-width: unset;
  }

  & .collapsible-menu-item-hover-menu {
    .collapsible-menu__svg-folder-closed {
      fill: var(--text-gray);
    }

    .collapsible-menu-item-with-header__header {
      padding-bottom: 0;
    }

    .sortable-folders {
      padding: var(--spacing-unit) 0 var(--spacing-unit) 0;
      min-width: 25rem;
    }

    .tags-list {
      width: 25rem;
    }
  }

  &__main {
    display: flex;
    gap: var(--spacing-unit-x2);
    min-width: var(--positioning-min-width);
  }

  &__custom-filters {
    padding: 0 var(--spacing-unit-x4);
  }

  .collapsible-menu {
    position: sticky;
    max-height: calc(100vh - 8.5rem);
    z-index: var(--z-index-menu);
    top: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit-x2);
  }

  .actionable-nested-table {
    border-top: none;
  }

  .actionable-nested-table__thead {
    border-top: var(--standard-border);
  }

  &__has-tabs {
    background-color: var(--page-back);

    .list-page-scroll-area__viewport {
      overflow: unset;
      margin-top: 2rem;
    }

    .page-header {
      padding-top: 0;
      padding-left: var(--spacing-unit-x4);
      padding-right: var(--spacing-unit-x4);
    }

    .folder-search__container {
      .actionable-nested-table__body {
        margin-top: 0;
      }
    }
  }
}
