.sync-schedule {
  padding: 0;
  border: 0;
  background-color: transparent;
  margin-top: var(--spacing-unit-x6);

  &__schedule-panel {
    background-color: var(--white);
    margin: var(--spacing-unit-x2) 0;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x4);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
  }

  &__schedule-panel > &__subtext {
    margin: var(--spacing-unit-x4) 0;
  }

  &__status {
    background-color: var(--white);
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x2);
    padding-left: var(--spacing-unit-x4);
    display: flex;
    border-radius: var(--standard-border-radius);
    align-content: center;
    border: var(--standard-border);
  }

  &__status > &__title-panel {
    flex-grow: 1;
    display: flex;
    align-items: center;

    svg {
      color: var(--text-teal);
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__sync-button-container--redesigned {
    .button--primary {
      line-height: var(--spacing-unit-x4) !important;
    }
  }

  &__status .button--primary {
    line-height: var(--line-height-smallish);
  }

  &__results-link {
    padding-left: var(--spacing-unit-x2);
    color: var(--text-teal);
    font-size: var(--large-font-size);
    line-height: var(--line-height-larger);
    cursor: pointer;
    font-weight: 600;
  }

  &__results-link:hover:not(:disabled) {
    text-decoration: underline;
    color: var(--text-teal);
  }

  &__pageContainer {
    margin-top: 0;
  }

  &__schedule-option {
    display: flex;
    font-size: var(--large-font-size);
  }

  &__schedule-option > &__maintext {
    padding-right: var(--spacing-unit-x2);
    line-height: var(--line-height-large);
    margin-top: var(--half-spacing-unit);
  }

  &__positionContainer > div {
    padding-left: var(--spacing-unit-x2);
  }

  &__page-header__left {
    padding-left: var(--spacing-unit-x2);
  }

  &__primary-radiogroup > .radiogroup > .radiogroup__body {
    display: block;
  }

  &__primary-radiogroup .radio__label {
    margin-bottom: var(--half-spacing-unit);
  }

  &__primary-radiogroup .radio-top {
    margin-top: 0;
  }

  &__primary-radiogroup .radio-bottom {
    margin-bottom: 0.75rem;
  }

  &__secondary-radiogroup {
    margin-top: var(--spacing-unit-x6);
  }

  &__schedule-row {
    display: flex;
    margin-left: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x2);
  }

  &__option-row {
    display: flex;
    margin-top: var(--spacing-unit-x2);
  }

  &__schedule-col {
    padding-right: var(--spacing-unit-x3);
  }

  &__option-text {
    position: relative;
    top: var(--spacing-unit-x2);
  }

  &__schedule-option > &__subtext {
    color: var(--light-gray);
    margin-top: var(--half-spacing-unit);
    font-weight: var(--font-weight-lighter);
    line-height: var(--line-height-large);
  }

  &__subtext-filters {
    margin-top: var(--spacing-unit-x2) !important;
    margin-bottom: var(--spacing-unit-x4);
  }

  &__filter-subtext {
    margin-left: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x2);
  }

  &__panel-warning-filters {
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x4);
  }

  &__condition-card {
    background: var(--ao-var-page-bg);
  }

  &__option-panel {
    background-color: var(--white);
    margin-top: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x2) var(--spacing-unit) var(--spacing-unit-x4);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    display: table;
  }

  &__option-panel-with-height {
    height: var(--spacing-unit-x24);
  }

  &__option-panel-unset-height {
    height: unset;
  }

  &__panel-title {
    font-size: var(--larger-font-size);
    font-weight: var(--font-weight-lighter);
    line-height: var(--line-height-larger);
    display: contents;
  }

  &__option {
    float: left;
    margin-top: var(--spacing-unit-x2);
    width: calc(50%);
    line-height: var(--line-height-standard);
    color: var(--text-gray);
    padding-right: var(--spacing-unit-x4);
  }

  &__option > &__subtext {
    color: var(--light-gray);
    margin-top: 0;
    font-weight: var(--font-weight-lighter);
    padding-top: var(--spacing-unit);
    padding-left: var(--spacing-unit-x4);
    line-height: var(--line-height-standard);
  }

  &__label-enable {
    color: var(--text-gray);
  }

  &__label-disable {
    color: var(--lightest-gray);
  }

  &__status-loader {
    padding: unset;
    height: var(--spacing-unit-x4);
    width: var(--spacing-unit-x5);
  }

  &__status-format {
    padding-left: 0.5rem;
    font-weight: var(--font-weight-bold);
  }

  &__panel-warning {
    width: 100%;
    background-color: var(--light-yellow);
    line-height: var(--line-height-standard);
    color: var(--text-dark-yellow);
    display: flex;
    margin-top: var(--spacing-unit-x4);
    align-items: center;
    margin-right: var(--spacing-unit-x2);

    .svg--larger-icon {
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  &__svg-warning {
    height: 1.75rem;
    padding: 0.75rem var(--spacing-unit) var(--spacing-unit-x5) var(--spacing-unit-x3);
  }

  &__more-info {
    color: #1E7DF8;
  }
}

.loader--page svg {
  width: var(--spacing-unit-x4);
}
