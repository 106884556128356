
.numbered-list-container {
  &__list-columns {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: var(--spacing-unit-x4);
  }

  &__list-columns div {
    flex: 0 0 30%;
  }

  &__button {
    border-top: var(--columns-title-border);
    margin-top: var(--spacing-unit-x4);
  }

  &__button div {
    margin-top: var(--spacing-unit-x2);
  }
}
