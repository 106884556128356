.single-select-dropdown-root {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-99999);

  &__dropdown-container {
    position: absolute;
    background: var(--white);
    box-shadow: var(--box-shadow);
    border-radius: 0 0 var(--standard-border-radius) var(--standard-border-radius);

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      right: 0;
      top: 91%;
      bottom: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, var(--white) 100%);
      border-radius: inherit;
    }
  }
}
