.top-bar {
  position: absolute;
  width: 100%;
  top: 0;
  box-shadow: var(--box-shadow);
  background-color: var(--white);
  height: var(--top-bar-height);
  display: flex;
  align-items: center;
  z-index: var(--z-index-9999);

  &--hidden {
    display: none;
  }

  &:has(.drop-down__drop-down) {
    z-index: var(--z-index-999999);
  }
}
