.display-conditions-builder {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  &__flip-container {
    display: flex;
    gap: var(--spacing-unit-x4);
    flex-direction: column;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
  }

  &__error-messages {
    display: flex;
    flex-direction: column;
    gap: 0.15rem;

    ul {
      margin-top: 0;
      margin-bottom: 0;
      padding-inline-start: var(--spacing-unit-x4);
    }

    &-wrapper {
      justify-content: flex-start;
      align-items: unset;
      margin: 0;

      .info-status--wrapper {
        align-items: unset;
      }
    }
  }

  &__add-condition {
    height: var(--spacing-unit-x6);
    padding-left: var(--spacing-unit-x2);
    flex-grow: 0;

    &:hover .svg {
      fill: var(--white);
    }
  }
}
