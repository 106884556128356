.incoming-webhook-input {
  flex-grow: 1;
  padding-bottom: 2.3334rem;
  width: 100%;

  &__label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8334rem;

    > span,
    > div {
      display: flex;
      align-items: center;
    }
  }
  
  &:has(&__error) {
    padding-bottom: unset;
  }

  &__error {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-unit);
    margin-top: 0.8334rem;
    
    & div {
      color: var(--error-text);
    }
  }
}