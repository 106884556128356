.upload-html-composer-footer {
  display: flex;
  justify-content: space-between;
  padding: calc(var(--spacing-unit) + var(--half-spacing-unit)) var(--spacing-unit-x2);
  border: none;
  border-top: var(--standard-border);
  border-left: var(--standard-border);
  position: absolute;
  bottom: 0;
  z-index: var(--z-index-9);
  width: 100%;
  background: var(--white);

  &__wrapped {
    flex-direction: column;
    align-items: center;

    &__divider {
      margin-top: var(--spacing-unit-x2);
      margin-bottom: var(--spacing-unit);
      width: 100%;
      padding: 0 var(--spacing-unit-x2);
      border-bottom: var(--standard-border);
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
  }

  &__divider {
    align-self: stretch;
    width: 0.0834rem;
    background-color: var(--border-gray);
    margin-bottom: 0.667rem;
    margin-top: 0.667rem;
    margin-left: var(--spacing-unit-x2);
    margin-right: var(--spacing-unit-x2);
  }

  &__personalize-button .svg {
    margin-bottom: 0;
  }
}
