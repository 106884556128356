.tableV2-row-action {
  background: linear-gradient(90deg, rgba(249, 249, 249, 0) 0%, var(--hover-row-background-teal) 2.5rem, var(--hover-row-background-teal) 100%);
  position: absolute;
  display: none;
  right: 0;
  top: 0.125rem;
  bottom: 0.125rem;
  padding: 0 var(--spacing-unit-x2) 0 var(--spacing-unit-x6);

  button:hover {
    background-color: var(--hover-row-button-background-teal);
  }

  .drop-down__tooltip {
    height: 100%;

    > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__visible {
    display: flex;
  }
}
