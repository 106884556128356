.nested-dropdown {
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 16rem;
    z-index: var(--z-index-toast);
  }

  &__faux-select {
    display: flex;
    cursor: pointer;
    margin: 0;
    width: 100%;
    border-radius: var(--standard-border-radius);
    font-size: var(--large-font-size);
    line-height: var(--line-height-title-page);
    padding: 0 var(--spacing-unit-x2);
    box-sizing: border-box;
    appearance: none;
    background-color: var(--input-background);
    background-repeat: no-repeat;
    background-position: calc(100% - var(--spacing-unit-x2)) center;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuMDEgMi41aDkuOThjLjg5OCAwIDEuMzQ3IDEuMDg1LjcxNCAxLjcxOEw2LjcxMyA5LjIwNWExLjAwMyAxLjAwMyAwIDAgMS0xLjQyMiAwTC4yOTYgNC4yMThDLS4zMzcgMy41ODUuMTEyIDIuNSAxLjAxIDIuNVoiIGZpbGw9IiM0NDQiLz48L3N2Zz4=');
    background-size: 0.75rem 0.75rem;
    color: var(--text-gray);
    border: var(--input-border);
    max-height: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
    outline: none;
    transition: var(--linear-80);
    padding-right: var(--spacing-unit-x5);
    overflow: hidden;

    &-flex {
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    &:hover,
    &:focus {
      background-color: var(--white);
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3IDQiPjxwYXRoIGQ9Ik0uNTkgMGg1LjgyYS41Ny41NyAwIDAgMSAuNDIgMUwzLjkyIDMuODNhLjU5LjU5IDAgMCAxLS44MyAwTC4xNyAxYS41Ny41NyAwIDAgMSAuNDItMVoiLz48L3N2Zz4=');
      background-size: 0.75rem 0.45rem;
    }

    &:hover {
      border: var(--input-border-hover);
    }

    &:focus-visible {
      outline: none;
      box-shadow: var(--keyboard-focus-box-shadow);
    }

    &:focus {
      border: var(--input-border-focus);
      outline: var(--input-outline-border);
    }

    &:disabled {
      opacity: 0.7;

      &:hover,
      &:focus {
        cursor: default;
        background-color: var(--input-background);
        border: var(--input-border);
      }
    }

    &--active {
      outline: var(--input-outline-border);
    }

    &:invalid {
      color: var(--light-gray);
    }

    &-placeholder {
      display: flex;
      align-items: center;

      svg {
        margin-right: var(--spacing-unit-x2);
      }
    }
  }

  &__dropdown-wrapper {
    position: absolute;
    top: 0.6667rem;
    display: none;
    width: 100%;
    flex-grow: 1;
    box-shadow: var(--drop-down-shadow);
    z-index: var(--z-index-toast);
    border-radius: var(--standard-border-radius);
    border: var(--standard-border);
    background-color: var(--white);

    &--active {
      display: block;
    }
    
    &--is-compact {
      top: 50%;
      transform: translateY(-54%);
      overflow: auto;
    }
  }

  &__tooltip__trigger-ellipsis {
    overflow: hidden;

    .typography {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__search-wrapper {
    box-shadow: var(--box-shadow);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x3);
    border-bottom: var(--standard-border);

    &--hidden-search {
      box-shadow: var(--drag-preview-box-shadow);
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
      padding-bottom: 0;
      position: relative;
    }
  }

  &__dropdown-content-title {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-unit-x3);

    &-back-button {
      --back-button-size: calc(var(--spacing-unit-x6) - var(--spacing-unit-x2) / 3);
      height: var(--back-button-size);
      min-height: var(--back-button-size);
      width: var(--back-button-size);
      min-width: var(--back-button-size);
    }
  }

  &__dropdown-container {
    background: var(--white);
  }

  &__options {
    padding: var(--spacing-unit) 0 var(--spacing-unit) 0;
    margin: 0;
    max-height: 16rem;
    min-height: var(--spacing-unit-x18);
    overflow: auto;
    position: relative;

    &--scrolled {
      max-height: 17.6rem;
    }

    &-wrapper {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: var(--spacing-unit-x3);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--white) 100%);
        pointer-events: none;
        z-index: 1;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--spacing-unit) var(--spacing-unit-x4);
      cursor: pointer;
      position: relative;
      border-radius: var(--standard-border-radius);

      &:hover {
        z-index: var(--z-index-multi-select);
        background-color: var(--hover-row-background-teal);

        .nested-dropdown__options-item-nested-icon {
          fill: var(--light-gray);
        }
      }

      &-nested-icon {
        fill: var(--button-gray);
      }

      &--selected {
        z-index: var(--z-index-multi-select);
        background-color: var(--lighter-teal);
        border-radius: 0;
        border-left: var(--half-spacing-unit) solid var(--teal);
        padding-left: calc(var(--spacing-unit-x4) - var(--half-spacing-unit));

        .nested-dropdown__options-item-value-icon {
          fill: var(--teal);
        }

        &:hover {
          background-color: var(--lighter-teal);
        }

        &:nth-last-child(1)::after {
          content: unset !important;
        }
      }

      &--disabled {
        opacity: 50%;
        cursor: default;

        &:hover {
          background-color: var(--white);
        }

        &:nth-last-child(1)::after {
          content: unset !important;
        }

        button {
          cursor: default;
        }
      }

      .nested-dropdown__options-item-value-icon {
        margin-right: calc(var(--spacing-unit-x2) + var(--spacing-unit-x2) / 3);
        min-height: 1.33rem;
        min-width: 1.33rem;
      }

      &-value {
        overflow: hidden;
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        padding-right: var(--spacing-unit-x4);
      }
    }
  }

  &__checkbox {
    padding: var(--spacing-unit) 0;
  }

  &__footer-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    border-top: var(--standard-border);
  }

  &__button-wrapper {
    border-top: var(--standard-border);
    padding: var(--spacing-unit-x4) calc(var(--half-spacing-unit) + var(--spacing-unit));
  }

  &__clear-wrapper {
    flex-shrink: 0;
    padding: 0;

    &--active {
      display: none;
    }
  }

  &__clear-text {
    padding: 0 0 0 var(--spacing-unit-x2);
  }
}
