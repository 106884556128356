.table {
  .table-action-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1.41666667rem;
    padding-top: 1.41666667rem;
    background-color: var(--dark-blue);
    border-radius: var(--standard-border-radius) var(--standard-border-radius) 0 0;
    height: var(--spacing-unit-x9);

    > * {
      height: 1.666rem;
    }

    &__checkbox {
      margin-left: var(--spacing-unit-x4);
      margin-right: var(--spacing-unit-x2);

      .checkbox__label::before,
      .checkbox__label::after {
        border-radius: 0.16666rem;
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    &__delete {
      height: var(--spacing-unit-x5);
    }

    &__count {
      padding-left: var(--spacing-unit-x4);
      line-height: var(--spacing-unit-x2);
      width: 50%;
    }

    &__actions {
      padding-left: var(--spacing-unit-x6);
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: var(--spacing-unit-x2);
      padding-right: var(--spacing-unit-x4);
      align-items: center;

      & .button {
        border: none;
      }

      .button--table-action:not(.button--delete),
      .drop-down__main-button {
        &:hover {
          border-radius: var(--standard-border-radius) var(--standard-border-radius) 0 0;
          background-color: var(--blue);
        }
      }

      &-drop-down button,
      .button--table-action {
        color: var(--white);
        font-weight: var(--font-weight-medium-light);

        .svg {
          fill: var(--white) !important;
        }
      }

      &-drop-down {
        &-option {
          display: inline-flex;

          > .svg {
            margin-top: var(--half-spacing-unit);
          }
        }

        &-open button {
          background-color: var(--blue);
        }

        button {
          height: var(--spacing-unit-x6) !important;
          width: var(--spacing-unit-x6) !important;
        }
      }

      & .tag-manager__drop-down-open .button--table-action {
        background-color: var(--blue);
      }

      .drop-down {
        padding-right: 0 !important;
      }
    }

    &-sticky {
      position: sticky;

      & > div {
        left: 0;
        position: sticky;
      }

      &__checkbox {
        margin-left: 0;
        padding-left: var(--spacing-unit-x5);
        z-index: 3;
      }

      &__count {
        margin-left: -5.75rem;
        padding-left: 5.75rem !important;
        z-index: 2;
      }

      &__actions {
        margin-left: -16.75rem;
        padding-left: 16.75rem !important;
        z-index: 1;
      }
    }
  }
}

.drop-down__drop-down-overflow.drop-down__drop-down-styled {
  margin-top: var(--half-spacing-unit);
}
