.list-info-hover-card {
  &__section {
    max-width: 21rem;

    &-tree {
      min-height: var(--spacing-unit-x17);
    }

    &-type {
      display: flex;
      justify-content: space-between;
      padding: 0 0 var(--spacing-unit-x2) 0;
    }
  }

  &__divider {
    background-color: var(--border-gray);
    height: var(--standard-border-width);
    margin: var(--spacing-unit-x3) 0 var(--spacing-unit-x3) 0;
    width: 100%;
  }

  &__system {
    display: flex;
  
    &-soft-bounces {
      display: block;
    }
  }
  
  &__crm {
    &-error-tooltip {
      background-color: var(--white);

      &-trigger {
        margin-top: var(--spacing-unit-x2);

        &:hover {
          cursor: pointer;
        }
      }

      .tooltip__content:has(&) span .tooltip__arrow {
        fill: var(--white);
      }
    }

    &-status {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      &-title {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &-subtitle {
        flex-wrap: nowrap;
        margin-top: var(--spacing-unit-x2);

        & > .text-link {
          margin-left: var(--spacing-unit);
        }
      }

      &-icon {
        &-warning-solid-no-fill {
          fill: var(--yellow-caution);
        }

        &-fail {
          fill: var(--remove-red);
        }

        &-reload-no-fill {
          color: var(--teal);
        }

        &-check {
          fill: var(--success-green);
        }

        &-opt-out {
          fill: var(--disabled-plus-gray);
        }
      }
    }
  }

  .segment-details-info-hover__sources {
    display: flex;
    flex-direction: column;
    gap: var(--half-spacing-unit);
    justify-content: center;
  }
}
