.selected-file {
  height: var(--spacing-unit-x17);
  border: var(--standard-border);
  border-color: var(--off-white-focus);
  border-radius: var(--standard-border-radius);
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  &__icon {
    fill: var(--off-white-hover);
    width: calc(140rem / 12);
    height: 8.25rem;
    margin-top: 2.25rem;
  }

  &__details {
    display: flex;
    gap: var(--spacing-unit);
    max-width: calc(100% - 7rem);

    &-name {
      margin-bottom: var(--spacing-unit-x2);
    }

    > div {
      align-self: center;
      max-width: 100%;
      overflow: hidden;
    }
  }

  &__remove-file {
    &-icon .svg {
      fill: var(--tab-gray);
      height: 0.83333rem;
      width: 0.83333rem;
    }

    &-button {
      align-self: center;
      margin-left: var(--spacing-unit-x2);
      margin-right: var(--spacing-unit-x4);
      cursor: pointer;
      height: var(--spacing-unit-x6);
      width: var(--spacing-unit-x6);
      border-radius: 50%;
      flex-shrink: 0;
    }
  }
}
