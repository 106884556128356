.list-picker-modal-body {
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 100rem;
  width: 100%;
  min-height: 26.66rem;

  &__empty-listing-text-name-trigger {
    display: inline-flex;

    & > div {
      max-width: 30rem;
    }
  }

  &__info-content {
    border-top: var(--standard-border);
    width: 100%;

    .search-results {
      height: 100%;
      max-height: 100%;

      &__scroll-area {
        height: 100%;
      }
    }

    &-warning {
      border-top: none;

      .search-results {
        gap: unset !important;

        &__label {
          padding: 0 0 1.75rem 1.66rem;
        }

        &__scroll-area {
          max-height: calc(100% - var(--spacing-unit-x8)) !important;
          padding-top: calc(var(--spacing-unit-x3) + var(--half-spacing-unit));
        }
      }
    }
  }

  &__loader {
    position: relative;
  }

  &__with-caution {
    .actionable-nested-table {
      &-with-lazy-loading {
        max-height: calc(100% - var(--spacing-unit-x8)) !important;
      }
    }
  }

  &__main-content {
    display: flex;
    flex-direction: row;
    height: 100%;

    .actionable-nested-table {
      &-with-lazy-loading {
        flex: 1 0 75.08rem;
        height: 100%;
        max-height: 100%;
      }

      &__body {
        height: 100%;
        overflow: auto;
      }
    }
  }

  &-filter-container {
    padding-top: var(--spacing-unit);
    padding-bottom: var(--spacing-unit);
  }

  &__menu {
    border-left: none;
    border-bottom: none;
    border-radius: unset !important;
    flex-shrink: 0;

    .menu {
      &__item {
        margin-right: 0 !important;
      }

      &__items {
        height: 100%;
      }
    }

    & .menu-item-with-header__header {
      padding-left: var(--spacing-unit-x4);
    }

    & .radio__container {
      padding-left: var(--spacing-unit-x4);
    }

    & .folder__header {
      margin-left: var(--spacing-unit-x2);
    }

    &-folders,
    &-tags {
      padding: var(--spacing-unit) 0;
    }

    &-folders .folder__item-count {
      padding-right: var(--spacing-unit-x4);
    }

    &-folders .typography {
      max-width: 100% !important;
    }

    &-tags {
      min-width: 0 !important;
      max-width: 100% !important;

      & .tag-count {
        margin-right: var(--spacing-unit-x4) !important;
        padding-right: 0 !important;
      }
    }

    &-folders&-folders {
      &-non-ucl .folder {
        padding-left: var(--spacing-unit);
      }

      &-ucl .folder {
        padding-left: calc(var(--spacing-unit-x10) / 6);
      }
    }

    &-no-folders&-no-tags img {
      height: var(--spacing-unit-x10);
      width: var(--spacing-unit-x16);
    }

    &-tags .tag {
      padding-left: var(--spacing-unit-x6);
    }

    &-section {
      margin-right: 2.167rem;
    }

    & .selectv2__container {
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4) 0 var(--spacing-unit-x4);
    }
  }

  &__search-loader {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    & .loader {
      position: unset !important;
    }
  }

  &__table {
    border: none;
    height: 100%;
    max-width: 100%;

    &-empty-listing {
      align-self: center;
      width: 100%;
    }

    &-header-shadow .actionable-nested-table__thead-fixed {
      box-shadow: var(--box-shadow);
    }

    & .table__th:first-child {
      flex: unset !important;
    }

    & .table__th {
      cursor: unset !important;
    }

    & .nested-table-row {
      & > div:first-child {
        flex: unset !important;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:not(:has(.segment-name-with-info-hover)) {
        .nested-table-row__expander {
          margin-right: var(--spacing-unit-x4);

          &-parent {
            margin-right: 0.875rem;
          }
        }
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      .caution {
        border-left: none;
        border-radius: 0;
        margin: 0;
      }

      .th:last-child,
      .nested-table-row__cell:last-of-type {
        margin-right: var(--spacing-unit-x4) !important;
      }

      .actionable-nested-table {
        max-width: unset !important;
      }
    }
  }

  &__more-filters {
    padding: 0 0 var(--spacing-unit-x2) var(--spacing-unit-x4);
    width: max-content;
  }

  &__list-checkbox .checkbox__indicator {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+SW50ZXJtZWRpYXRlIENoZWNrbWFyazwvdGl0bGU+CiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkludGVybWVkaWF0ZS1DaGVja21hcmsiPgogICAgICAgICAgICA8cmVjdCBpZD0iRmlsbCIgZmlsbD0iIzAwQkFCRSIgeD0iMCIgeT0iMCIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiByeD0iMiI+PC9yZWN0PgogICAgICAgICAgICA8cmVjdCBpZD0iU2hhZG93IiBmaWxsPSIjMDA5QTlFIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHg9IjMiIHk9IjgiIHdpZHRoPSIxMCIgaGVpZ2h0PSIyIiByeD0iMSI+PC9yZWN0PgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHg9IjMiIHk9IjciIHdpZHRoPSIxMCIgaGVpZ2h0PSIyIiByeD0iMSI+PC9yZWN0PgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
  }

  &__custom-banner {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  }
}