.filterable-list {
  &__title {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x2) 0.65rem var(--spacing-unit-x2);
    border: var(--standard-border);
  }

  &__list {
    height: var(--list-max-height);
    overflow: auto;
    margin-top: 0;
    padding-left: 0;
    border: var(--standard-border);
    border-top: 0;

    &-selected {
      border-left: none;
    }

    &-item {
      padding: 0.75rem 0 var(--spacing-unit) var(--spacing-unit-x2);
      white-space: nowrap;
    }
  }

  &__flex {
    display: flex;
    align-items: center;

    &-justify {
      justify-content: center;
    }

    > .typography {
      padding-left: var(--spacing-unit);
    }
  }

  &__checkbox {
    &:hover {
      cursor: pointer;
    }

    &-readonly {
      opacity: 0.2;

      &:hover {
        cursor: not-allowed;
      }

      & .checkbox {
        pointer-events: none;
      }
    }
  }

  &__search {
    & > .input-with-clear__clear-button {
      top: 0.85rem;
    }

    & > .input-with-clear__clear-button-divider {
      top: 0.75rem;
    }

    & > .input {
      border-radius: 0;
      padding-left: var(--spacing-unit-x2);
      height: var(--spacing-unit-x7);
      background-image: none;

      &:focus {
        border-color: var(--border-gray);
      }

      &:hover {
        cursor: text;
        border-color: var(--border-gray);
      }

      &:hover,
      &:focus-visible {
        box-shadow: none;
        outline: unset;
      }
    }
  }
}
