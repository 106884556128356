.data-cards-container {
  display: flex;
  gap: var(--spacing-unit-x4);
  width: 100%;
  padding: var(--spacing-unit-x4) 0;

  &__data-card {
    flex: 1 0 calc(29rem + var(--half-spacing-unit));
  }

  &__single-line-chart {
    margin-top: 1.167rem;
  }

  &__text-link {
    margin-top: 1.167rem;
  }

  &__engaged-contacts {
    &:hover {
      border-color: var(--border-clicked);
    }

    > .data-card__background {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__pie-chart {
    margin-left: auto;

    .highchart-series-hover {
      fill: var(--light-theme-background) !important;
      background-color: var(--light-theme-background) !important;
    }
  }

  & .highcharts-container {
    margin-left: auto;
  }

  &__active-contacts {
    &:hover {
      .activity-gauge-chart__tooltip-value {
        display: flex;
      }
    }

    &-detail {
      display: flex;
    }

    &-warning {
      &-icon {
        align-self: center;
        fill: var(--yellow-caution);
        margin: 0 var(--spacing-unit) calc(var(--spacing-unit) / 6) 0;
      }

      &:hover {
        border-color: var(--yellow-caution);

        .highcharts-series path {
          fill: var(--yellow-caution-hover) !important;
        }
      }
    }

    &-error {
      &-icon {
        align-self: center;
        fill: var(--remove-red);
        margin: 0 var(--spacing-unit) calc(var(--spacing-unit) / 6) 0;
      }

      &:hover {
        border-color: var(--remove-red);

        .highcharts-series path {
          fill: var(--remove-red-hover) !important;
        }
      }
    }

    &-default {
      fill: var(--teal);

      &:hover {
        border-color: var(--teal);

        .highcharts-series path {
          fill: var(--hover-teal) !important;
        }
      }
    }
  }

  &__total-contacts {
    &:hover {
      border-color: var(--border-delivered);
    }

    &-left-content {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 0.417rem;
      margin-top: var(--spacing-unit-x2);

      &-empty {
        margin-top: calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
      }
    }

    &-percentage {
      color: var(--green-percentage);

      &-negative {
        color: var(--remove-red);
      }

      &-svg {
        &-negative {
          fill: var(--remove-red);
        }

        &-percentage {
          fill: var(--green-percentage);
        }
      }
    }
  }
}
