.image-picker-modal-preview {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  &__image-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: var(--spacing-unit-x4);
    background-color: var(--modal-gray-background);

    &__inner {
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: inherit;
      justify-content: center;
      align-items: center;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    width: 25rem;
    border-left: var(--standard-border);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

    &-name {
      padding-bottom: var(--spacing-unit-x4);
      border-bottom: var(--standard-border);

      .typography {
        overflow: hidden;
        /* stylelint-disable */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        /* stylelint-enable */
      }
    }

    &-properties {
      display: flex;
      flex-direction: row;
      gap: var(--spacing-unit-x6);

      &-container {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-unit-x4);
        padding: var(--spacing-unit-x4) 0;
      }

      &-col {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-unit-x2);
      }

      &-edit-button {
        width: max-content;
        display: flex;
        gap: 0.833rem;
        padding: var(--spacing-unit) var(--spacing-unit-x3);
      }
    }
  }
}
