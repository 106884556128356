.recipients {
  &__list-button {
    margin-top: var(--spacing-unit-x4);
  }

  &__lists {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__lists-row {
    display: flex;
    align-items: center;
    padding: var(--spacing-unit) 0;
    border-bottom: var(--standard-border);

    > :nth-child(1) {
      flex: 0 0 var(--spacing-unit-x4);

      .svg {
        flex: 0 0 1rem;
        width: 1rem;
        height: 1rem;
        fill: var(--teal);
        margin: 0 var(--spacing-unit);
      }
    }

    > :nth-child(2) {
      flex: 1;
    }

    > :nth-child(3),
    > :nth-child(4) {
      text-align: right;
    }

    > :nth-child(3) {
      flex: 0 0 var(--spacing-unit-x4);
    }

    > :nth-child(4) {
      flex: 0 0 var(--spacing-unit-x7);
      margin-left: var(--spacing-unit-x2);
    }
  }

  &__lists-header {
    label {
      font-weight: var(--font-weight-bold);
      color: var(--gray);
      text-transform: uppercase;
      font-size: var(--smallest-font-size);
    }
  }
}
