.visitor-alerts-table {
  display: flex;
  justify-content: center;

  &__custom-header {
    display: flex;
    align-items: center;

    &-title {
      margin-right: var(--spacing-unit);
    }
  }

  &__alert {
    &-name {
      display: flex;
      gap: var(--spacing-unit);
      flex-direction: column;
      align-items: flex-start;
    }

    &-description {
      width: 100%;
    }
  }

  &__recipients {
    display: flex;
    justify-content: center;
  }

  &__disabled {
    color: var(--disabled-alert-gray);
    text-align: left;

    &-sub {
      color: var(--disabled-alert-secondary-gray);
    }
  }

  &__alert-status-wrapper {
    height: var(--spacing-unit-x4);
    align-items: center;
  }

  &__mute {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: var(--spacing-unit-x4);
    justify-content: center;

    &-icon {
      &.svg {
        height: 1.16667rem;
        width: 1.16667rem;
      }

      &-large.svg {
        height: var(--spacing-unit-x4);
        width: var(--spacing-unit-x4);
      }
    }

    &.button:hover {
      background-color: unset;
    }
  }

  .drop-down__drop-down-styled {
    min-width: 16.66rem;
  }
}
