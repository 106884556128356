
.table-row-action {
  background: linear-gradient(90deg, rgba(249, 249, 249, 0) 0%, var(--hover-row-background-teal) 2.5rem, var(--hover-row-background-teal) 100%);
  position: absolute;
  height: 100%;
  display: none;
  right: 0;
  width: auto !important;
  padding-left: var(--spacing-unit-x6) !important;
  padding-right: var(--half-spacing-unit);
  column-gap: var(--half-spacing-unit);

  button:hover {
    background-color: var(--hover-row-button-background-teal);
  }

  &__container {
    margin: var(--standard-border-width) 0;
    position: sticky;
    right: 0;
    display: block;
    z-index: 3;
  }

  &__visible {
    display: inline-flex;
  }

  > div {
    height: 100%;
  }
}
