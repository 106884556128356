:root {
  --disabled: #AEAEAE;
}

.link {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-unit);
  line-height: calc(var(--spacing-unit-x2) + (var(--spacing-unit-x4) / 3));
  border: var(--transparent-border);
  border-radius: var(--standard-border-radius);

  &:hover {
    text-decoration: none;
  }

  &:hover &__text {
    text-decoration: underline;
    text-decoration-color: inherit;
  }

  &:hover &__text-teal {
    .typography {
      color: var(--darker-teal);
    }
  }

  &:hover &__icon-teal {
    fill: var(--darker-teal);
  }

  &:focus-visible &__text-teal {
    .typography {
      color: var(--darker-teal);
    }
  }

  &:focus-visible &__icon-teal {
    fill: var(--darker-teal);
  }

  &:hover &__text-white .typography {
    text-decoration: underline;
  }

  &:focus-visible &__text-white .typography {
    text-decoration: underline;
  }

  &:focus-visible,
  &:active {
    border: var(--standard-border-dark-blue);
    box-shadow: 0 0 0 calc((var(--spacing-unit-x4) / 3) / 4) #ACE9EB;
  }

  &:has(&__text-white):focus {
    border: var(--standard-border-white);
  }

  &:focus-visible &__text,
  &:active &__text {
    text-decoration: underline;
    text-decoration-color: inherit;
  }

  &__noBorder {
    &:focus-visible,
    &:active {
      border: var(--transparent-border);
      box-shadow: none;
    }
  }

  &__inline {
    display: inline;

    &:focus-visible,
    &:active {
      border: var(--transparent-border);
      box-shadow: none;
    }
  }

  &__disabled {
    pointer-events: none;
  }

  &__small {
    gap: var(--half-spacing-unit);
    line-height: var(--spacing-unit-x3);
  }

  &__large {
    line-height: var(--spacing-unit-x4);
  }

  &__text {
    &-white {
      &-disabled {
        opacity: 0.5;
      }
    }

    &-disabled {
      .typography {
        color: var(--disabled);
      }
    }
  }

  &__icon {
    width: calc(var(--spacing-unit-x2) + (var(--spacing-unit-x4) / 3));
    height: calc(var(--spacing-unit-x2) + (var(--spacing-unit-x4) / 3));
    padding: calc(var(--spacing-unit-x4) / 6);
    fill: var(--text-teal);

    &-inline {
      vertical-align: bottom;
      margin-left: var(--spacing-unit);
    }

    &-small {
      width: var(--spacing-unit-x3);
      height: var(--spacing-unit-x3);
    }

    &-large {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
    }

    &-white {
      fill: var(--white);

      &-disabled {
        opacity: 0.5;
      }
    }

    &-gray {
      fill: var(--text-gray);
    }

    &-disabled {
      fill: var(--disabled);
    }
  }
}
