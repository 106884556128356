.condition-group {
  width: 100%;
  
  &__depth-indicator {
    background-color: var(--button-gray);
    border: var(--condition-group-connector-border);
    position: absolute;
    height: 100%;
  }
  
  &__condition-row-container:last-child &__depth-indicator {
    height: var(--spacing-unit-x4);
  }

  &__condition-row-container:only-child &__depth-indicator {
    height: var(--spacing-unit-x3);
  }

  &__condition-row {
    display: flex;
    position: relative;
    gap: var(--spacing-unit-x2);
    align-items: flex-start;

    &-container:not(:first-child) > & {
      padding-top: var(--spacing-unit);
    }

    &-container:not(:last-child) > & {
      padding-bottom: var(--spacing-unit);
    }

    &-container {
      margin-left: var(--spacing-unit-x3);
      position: relative;
    }

    > .svg { 
      margin-right: 0.8rem; 
    }
  }

  &__logic-connector {
    color: var(--button-gray);
  }

  &__connector {
    display: flex;
    align-items: center;
    gap: var(--half-spacing-unit);
    height: var(--spacing-unit-x6);

    &-start {
      background-color: var(--button-gray);
      width: var(--spacing-unit-x2);
      border: var(--condition-group-connector-border);
      top: var(--spacing-unit-x3);
      border-radius: var(--standard-border-radius);
    }
  }

  &__expression {
    flex-grow: 1;
  }

  &__action-button {
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
    
    &.button--float .svg {
      width: 1.333rem;
      height: 1.333rem;
    }
  }
}
