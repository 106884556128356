
.start-page {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: var(--spacing-unit-x4);

  &__image {
    margin-top: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x2);

    img {
      height: 20.835rem;
    }
  }

  &__box {
    &-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-outlined {
      min-width: 38.5rem;
      padding: var(--spacing-unit-x4);
      margin-top: var(--spacing-unit-x4);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
    }

    &-default {
      margin-top: var(--spacing-unit-x2);
      padding: var(--spacing-unit-x4);
    }

    &-title {
      margin-bottom: var(--spacing-unit-x2);
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: center;

      .button {
        min-width: 25rem;
      }
    }
  }
}
