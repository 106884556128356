.bee-editor {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  &__disable-focus {
    pointer-events: none;
  }

  .textarea {
    height: 50vh;
  }
}
