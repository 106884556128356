.webinar-details {
  &__select-card {
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x3);
    background-color: var(--input-background);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: var(--spacing-unit);

      & .button--navigation-button {
        width: var(--spacing-unit-x5);
        height: var(--spacing-unit-x5);
      }

      & .svg {
        width: var(--spacing-unit-x2) !important;
        height: var(--spacing-unit-x2) !important;
        fill: var(--light-gray) !important;
      }
    }
  }


  &__select-popover {
    &__trigger {
      &:focus-visible {
        border: var(--standard-border-dark-blue);
      }
    }

    &-selected {
      display: flex;
      background-color: var(--white);
      gap: var(--spacing-unit-x3);

      &-webinar {
        background-color: var(--lighter-off-white);
        border-radius: var(--standard-border-radius);
      }

      &-icon {
        border: var(--standard-border);
        border-radius: var(--standard-border-radius);
      }
    }

    &__anchor {
      display: flex;
      background-color: var(--white);
      justify-content: space-between;
      padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);

      & + div {
        width: 50rem;
      }
    }

    & .button--outline {
      gap: var(--spacing-unit);
    }

    & .svg {
      margin: 0;
    }
  }
}
