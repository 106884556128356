.program-performance-table-header {
  margin-bottom: var(--spacing-unit-x4);

  &__title-search {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x4) 0;
  }

  .program-header__table-actions {
    margin-bottom: 0;
  }
}
