.signatures-modal {
  width: 100rem;
  height: 66rem;

  &__header {
    display: flex;
    justify-content: space-between;

    &-section {
      display: flex;
      gap: var(--spacing-unit-x2);
      align-items: center;
    }

    &-subsection {
      display: flex;
      gap: var(--spacing-unit);
      align-items: center;
    }

    &-manage-button {
      padding: 0.833rem var(--spacing-unit-x2);

      &:focus-visible {
        box-shadow: none;
      }

      .svg {
        margin-bottom: 0;
      }
    }

    &-tooltip {
      max-width: 25rem !important;
      padding: var(--spacing-unit-x2) var(--spacing-unit-x3) !important;

      &__trigger-wrapper {
        width: var(--spacing-unit-x4);
        height: var(--spacing-unit-x4);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.333rem;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
  }

  &__content {
    width: 100%;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4) 0;
    overflow: auto;
    background-color: var(--white);
  }

  &__grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-unit-x4);
    padding: var(--spacing-unit-x2) 0 var(--spacing-unit-x4);
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-section {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }

    &-preview-button {
      padding: var(--spacing-unit) var(--spacing-unit-x3);
    }
  }

  .modal {
    &-header__inner {
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    }

    &-body {
      padding: 0;
    }

    &-footer__inner {
      padding: var(--spacing-unit-x4);
    }
  }
}
