.email-suppression-domains {
  &__textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: var(--spacing-unit-x7);

    &-section {
      margin-top: var(--spacing-unit-x4);
    }
  }
}
