
.position-container {
  margin-left: var(--positioning-margin-left);

  &__restore-margin {
    margin-left: calc(0 - var(--spacing-unit-x4));
  }

  &__children {
    min-width: var(--positioning-min-width);
    max-width: var(--positioning-max-width);
    margin: 0 auto;
    padding: 0 var(--spacing-unit-x4);

    &-adjusted {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  &__flex {
    display: flex;
    align-items: center;
  }

  &__banner-background {
    background-color: var(--lighter-teal);

    & > div {
      padding: 0 calc(var(--spacing-unit) + var(--half-spacing-unit));
    }
  }

  &__no-inner-padding > div {
    padding-left: 0;
  }
}

/* stylelint-disable */
@media (max-width: 1485px) {
  /* stylelint-enable */
  .position-container {
    margin-left: 0;

    &__children {
      margin: 0;
    }
  }
}