.advertising-banner {
  background-color: var(--lighter-teal);
  border-color: var(--border-teal);
  position: relative;

  &__sub-text {
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x4);
  }

  &__buttons {
    margin-top: var(--spacing-unit-x4);
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x4);
  }

  &__image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
  }
}
