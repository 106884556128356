.table {
  padding: 0;
  flex: 1;

  &__table-holder {
    width: 100%;
    border: var(--standard-border);
    border-bottom: var(--standard-border);
    border-radius: var(--standard-border-radius);
    border-spacing: 0;
    display: flex;
    background-color: var(--white);
  }

  &__has-pagination {
    border-bottom: none;
    border-radius: var(--standard-border-radius) var(--standard-border-radius) 0 0;
  }

  &__table-holder,
  &__paginator {
    min-width: var(--positioning-min-width);
    max-width: var(--positioning-max-width);
  }

  &__paginator {
    padding: var(--spacing-unit-x4);
    background-color: var(--white);
    border: var(--standard-border);
    border-radius: 0 0 var(--standard-border-radius) var(--standard-border-radius);

    .paginator {
      margin: 0;
    }
  }

  &__padder {
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x8);
    border-bottom: var(--input-border-hover);

    &-filter {
      height: var(--spacing-unit-x16);
    }

    &-actions {
      background-color: var(--dark-blue);
      border-bottom: var(--table-actions-border);
    }

    &-hide {
      display: none;
    }
  }

  &__use-checkboxes {
    .td:first-of-type,
    .th:first-of-type {
      flex: 0 0 var(--spacing-unit-x6) !important;
      justify-content: center;
    }
  }

  &__global-filter {
    padding: 0 var(--spacing-unit-x4);
    display: flex;
    justify-content: space-between;

    &-info-text {
      color: var(--light-gray);
      font-size: var(--default-font-size);
      line-height: var(--line-height-small);
    }

    > span {
      display: inline-block;
      width: 15rem;
    }
  }

  &__search {
    border: var(--standard-border);
  }

  &__left {
    text-align: left;
  }

  &__center {
    justify-content: center;
  }

  &__right {
    justify-content: flex-end;
  }

  &__has-overflow {
    .thead {
      padding-right: 1.4rem;
    }
  }

  .thead {
    .tr {
      align-items: flex-end;
      height: var(--spacing-unit-x9);

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: var(--white);
      }
    }
  }

  .thead .tr&__global-filter-container {
    height: var(--spacing-unit-x4);
  }

  .th {
    height: 100%;
    align-items: center;
    padding-bottom: 1.41666667rem;
    padding-top: 1.41666667rem;
    color: var(--light-gray);
    font-weight: var(--font-weight-bold);
    font-size: var(--smallest-font-size);
    text-transform: uppercase;
    border-bottom: var(--input-border-hover);
    cursor: pointer;

    &:nth-child(1) {
      width: 6rem;
      padding-left: var(--spacing-unit-x4);
    }

    &:last-child {
      padding-right: var(--spacing-unit-x4);
    }

    &.table__use-checkbox {
      padding-right: var(--spacing-unit-x9);
    }

    &:hover {
      text-decoration: underline;
    }

    &-unsorted:hover {
      text-decoration: none;
      cursor: default;
    }

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
    }
  }

  &__sort-span {
    margin-left: var(--spacing-unit);
  }

  .tr {
    &.table__actionable {
      cursor: pointer;
    }

    &.table__disabled {
      .draft-messages-table__title-col {
        pointer-events: none;
      }

      .svg {
        fill: var(--button-gray);
      }

      &:hover {
        background-color: var(--white);
      }
    }

    &:hover {
      background-color: var(--hover-row-background-teal);
    }
  }

  &__single-title {
    height: 3.5rem;
    line-height: 1.75rem;
    padding: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit);
    width: 100%;
    border-bottom: var(--standard-border);
    color: var(--text-gray);
    font-size: var(--large-font-size);
    font-weight: var(--font-weight-medium);
  }

  .tbody,
  .tfoot {
    font-weight: var(--font-weight-medium-light);

    .tr:first-of-type {
      .td {
        border-top: none;
      }
    }

    .td {
      border-top: var(--standard-border);
      padding-top: var(--spacing-unit-x2);
      padding-bottom: var(--spacing-unit-x2);
      font-size: var(--large-font-size);
      color: var(--text-gray);
      align-items: center;

      .table__muted {
        color: var(--light-gray);
      }

      .table__col-highlighted {
        border-radius: var(--highlight-border-radius);
        padding: 0 var(--spacing-unit);
        word-break: break-word;
      }
    }
  }

  .tfoot {
    border-top: var(--standard-border);
    font-weight: var(--font-weight-bold);
  }

  .td,
  .th {
    padding-left: 0;
    line-height: var(--line-height-large);
    display: inline-flex;

    &.table__spacing-right {
      padding-right: var(--spacing-unit-x2);
    }
  }

  .td {
    &:nth-child(1) {
      margin-left: var(--spacing-unit-x4);
    }

    &:last-of-type {
      margin-right: var(--spacing-unit-x4);
    }
  }

  .th:not(:first-child):not(:last-child),
  .td:not(:first-child):not(:last-child) {
    padding-right: var(--spacing-unit);
  }

  &__actions:not(&__th) {
    height: var(--line-height-table-actions);
    display: flex;
    align-items: flex-start;

    .button {
      padding-right: 0;
      flex: 0;
    }
  }

  .clickable {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__empty-text {
    margin-top: var(--spacing-unit-x8);
    display: flex;
    justify-content: center;
  }

  &__indented {
    padding-left: var(--spacing-unit-x2);
  }

  &__ellip {
    display: flex;
    max-width: 90%;

    &__wrapper,
    .tooltip__trigger {
      max-width: 100%;
    }
  }

  &__legend-container {
    display: inline-flex;
    align-items: center;
  }

  &__underlined {
    border-bottom: 0.083rem dashed var(--table-underline);
  }

  &__legend {
    margin-left: var(--spacing-unit);
    width: var(--spacing-unit-x2);
    height: var(--spacing-unit-x2);
    border-radius: var(--legend-border-radius);
  }

  &__text-icon-link-col,
  &__text-icon-col {
    display: flex;
    align-items: center;
  }

  &__text-icon-link-col {
    svg {
      color: var(--text-teal);
      fill: var(--text-teal);
      margin: 0 var(--spacing-unit);
      flex: none;
    }

    a {
      display: flex;
      align-items: center;

      &:hover {
        .typography {
          text-decoration: underline;
          color: var(--hover-teal);
        }
      }
    }
  }

  &__text-icon-col {
    svg {
      margin-right: var(--spacing-unit);
    }
  }

  .tr:hover &__row-actions {
    display: inline-flex;
  }

  &__use-sticky-columns {
    & .th {
      height: 100%;
    }

    & .tbody .tr:hover [data-sticky-td]:not([role='columnheader']) {
      background-color: var(--hover-row-background-teal);
    }

    .table__sticky-margin-column:not([role='columnheader']) {
      border: none !important;
      margin-left: 0 !important;
    }

    [data-sticky-td] {
      background-color: var(--white);
    }

    [data-sticky-last-left-td] {
      &[role='columnheader'] {
        border-right: var(--standard-border);
      }

      &:not([role='columnheader']) {
        border-right: var(--standard-border);
        box-shadow: var(--locked-columns-box-shadow);
        z-index: 2 !important;
      }
    }
  }

  &__header-add-margin {
    .thead {
      .tr {
        border-bottom: var(--input-border-hover);

        .th {
          border-bottom: none;

          &:last-of-type {
            margin-right: var(--spacing-unit-x4);
          }

          &:first-of-type {
            margin-left: var(--spacing-unit-x4);
            padding-left: 0;
          }
        }
      }
    }

    .table__row-actions {
      margin-right: var(--spacing-unit-x4);
    }
  }
}

/* stylelint-disable */
@media (max-width: 1500px) {
  /* stylelint-enable */
  .table {
    &__table-holder {
      margin-left: 0;
    }
  }
}
