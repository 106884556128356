.tag {
  position: relative;
  height: var(--spacing-unit-x6);
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spacing-unit-x4);

  &-title {
    display: flex;
    align-items: center;
  }

  &-color {
    height: var(--spacing-unit-x2);
    width: 0.333rem;
    opacity: 0.79;
    border-radius: var(--standard-border-width);
    margin: 0 var(--spacing-unit-x3) 0 var(--spacing-unit);
    flex: none;
  }

  &-hover {
    cursor: pointer;
    background-color: var(--hover-row-background-teal);
  }

  &__actions-container {
    position: absolute;
    right: 0;
  }
}
