.check-status-modal {
  width: 120rem;
  height: 85rem;

  &__header {
    background-color: var(--white);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

    &__right {
      gap: var(--spacing-unit-x2);
    }

    &__button-check {
      height: var(--spacing-unit-x6);
      padding: var(--spacing-unit) var(--spacing-unit-x3) var(--spacing-unit) var(--spacing-unit-x2);
      margin-left: var(--spacing-unit);
    }
  }

  &__body.modal-body {
    background-color: var(--page-back);
    padding: var(--spacing-unit-x4) var(--spacing-unit-x6);
  }

  &__message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50rem;
    max-width: 50rem;
    max-height: 100%;
    text-align: center;
    margin: 0 auto;

    &__spinner {
      height: unset;
    }
  }
}
