.progress-line {
  position: relative;
  height: var(--spacing-unit-x3);

  div {
    height: inherit;
    width: 100%;
    position: absolute;
    border-radius: var(--extra-large-border-radius);
  }

  &__total {
    background-color: var(--border-gray);
    width: 100%;

    &:hover {
      cursor: default;
    }
  }

  &__actual {
    width: 100%;
    border: var(--tag-color-border);
    background-color: var(--teal);

    &:hover {
      cursor: default;
    }

    &-hidden {
      display: none;
    }
  }
}
