.review-and-send-main {
  padding: var(--spacing-unit-x6);

  &__mobile-review {
    overflow: auto;
    height: 100vh;
  }

  &__header {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    justify-content: space-between;

    &__errors {
      position: relative;

      &::after {
        border-right: var(--standard-border-dark);
        content: '';
        height: var(--line-height-standard);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__email-tracking {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: calc(2rem / 3);
    }

    &__button {
      min-width: var(--spacing-unit-x26);
    }
  }
}
