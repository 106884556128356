
.bread-crumbs {
  display: flex;
  flex-direction: column;

  &__separator {
    fill: var(--button-gray);
    margin: 0 var(--spacing-unit);
  }

  &__crumbs {
    display: flex;
    align-items: center;
    padding-top: var(--spacing-unit-x2);
    padding-bottom: calc(var(--spacing-unit) + var(--half-spacing-unit));

    &-wrap {
      flex-wrap: wrap;
    }
  }

  &__hidden-crumb {
    visibility: hidden;
    position: absolute;
  }

  &__overflow-dots {
    display: flex;
    align-items: center;
    padding-bottom: 0.2rem;
  }

  &__crumb {
    align-items: center;
    display: flex;
    overflow: hidden;
  }

  &__name {
    width: 100%;
  }
}
