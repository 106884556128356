.step-three-mapping-headers {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x2);
  margin-bottom: var(--spacing-unit-x2);

  &__headers {
    display: grid;
    grid-template-columns: 16.5rem 5.34rem 1fr 1fr;
    
    &-act-on-contacts {
      background-color: var(--background-list-header);
      color: var(--blue);
      grid-column: span 2;
      padding: var(--half-spacing-unit) 0 var(--half-spacing-unit) var(--spacing-unit);
    }

    &-marketing-lists {
      background-color: var(--hover-row-background-teal);
      color: var(--text-teal);
      padding: var(--half-spacing-unit) 0 var(--half-spacing-unit) var(--spacing-unit);
    }
  }
  
  &__header {
    border-bottom: var(--standard-border);
    padding-bottom: var(--spacing-unit);

    &-arrow {
      align-items: center;
      display: flex;
      justify-content: center;
      height: calc(var(--spacing-unit-x4) + var(--half-spacing-unit));
    }
  }
}
