.clickthrough-links {
  &__header {
    display: grid;
    grid-template-columns: 1fr auto;

    &-test-button {
      .svg {
        margin-right: 0.833rem;
      }
    }
  }

  &__list {
    padding: 0;

    &-header {
      margin-bottom: 0;
      border-bottom: var(--input-border-hover);
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
      gap: var(--spacing-unit-x4);

      :first-child {
        flex: 2 1 0;
      }

      :last-child {
        flex: 3 1 0;
      }
    }
  }
}
