.review-removed-step-email-message-action {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x2);
  
  &__action-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
  }

  &__box {
    padding-left: var(--spacing-unit-x2);
    padding-right: var(--spacing-unit-x2);
  }
  
  &__message {
    background-color: var(--chip-base);
    border-radius: var(--spacing-unit-x2);
    padding: var(--half-spacing-unit)calc(var(--spacing-unit-x18) / 12);
    
    &-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: var(--spacing-unit);
    }
  }

  &__value-container {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit-x16) / 12);
    width: 36.0834rem;
  }
}