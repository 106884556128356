.related-forms-list {
  display: flex;
  flex-direction: column;
  gap: 0.33rem;
  margin-bottom: var(--spacing-unit-x2);

  &__name {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);

    & > svg {
      flex-shrink: 0;
    }
  }
}
