.dynamic-list-row {
  display: flex;
  gap: var(--spacing-unit-x2);

  > div {
    min-width: calc(10rem / 12);
  }

  & + & {
    margin-top: var(--spacing-unit-x2);
  }
  
  &__list {
    &-static {
      display: flex;
      align-items: center;
      background-color: var(--input-background);
      height: var(--spacing-unit-x6);
      align-self: center;

      > div {
        padding-left: var(--spacing-unit-x2);
      }
    }

    &-edit {
      align-self: center;
    }

    &-item {
      padding: var(--spacing-unit) 0;
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-self: center;
      font-weight: var(--font-weight-bold);
    }
     
    &-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--spacing-unit-x15);
    }

    &-static,
    &-item,
    &-item-tooltip,
    &-edit {
      flex: 1 1 0;
    }
  }

  &:hover &__trash-icon {
    fill: var(--remove-red);
    cursor: pointer;
  }

  &__trash {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 3.25rem;

    &-icon {
      display: inline;
      fill: var(--white);
    }

    &-button {
      padding: var(--spacing-unit) 0.75rem;
    }
  }

  &__warning {
    fill: var(--yellow-caution);
  }

  &__check-circle,
  &__warning,
  &__error {
    margin-top: var(--spacing-unit);
  }

  &__unmapped {
    color: var(--light-gray);
  }
}
