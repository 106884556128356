
.upload-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45.25rem;

  &__text {
    margin: var(--spacing-unit-x3) 0 var(--spacing-unit-x7);
  }

  &__progress-bar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__progress-bar {
    background-color: var(--button-back-gray);
    border: 0;
    width: 22rem;
    height: 0.25rem;
    border-radius: var(--standard-border-radius);

    &::-webkit-progress-bar {
      background-color: var(--button-back-gray);
      border-radius: var(--standard-border-radius);
    }

    &::-webkit-progress-value {
      background-color: var(--teal);
      border-radius: var(--standard-border-radius);
    }

    &::-moz-progress-bar {
      background-color: var(--teal);
      border-radius: var(--standard-border-radius);
    }
  }

  &__percentage {
    margin-left: 0.75rem;
  }
}
