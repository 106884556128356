
.switch {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 100%;
  background: var(--off-white-focus);
  border-radius: var(--standard-border-radius);

  &__is-toggle {
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    border: var(--standard-border);
  }

  &__is-toggle:not(&__selected) {
    background-color: var(--white);

    &:hover {
      background-color: var(--lighter-off-white);
    }
  }

  &__is-toggle&__selected {
    border-color: var(--hover-teal);
    text-shadow: var(--primary-small-button-text-shadow);

    &:hover {
      background-color: var(--hover-teal);
    }
  }

  &__body:not(&__is-toggle) {
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x4);
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-gray);

    &:hover {
      text-decoration: none;
    }

    &:first-child {
      border-top-left-radius: var(--standard-border-radius);
      border-bottom-left-radius: var(--standard-border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--standard-border-radius);
      border-bottom-right-radius: var(--standard-border-radius);
    }
  }

  &__selected {
    background: var(--teal);
    color: var(--white);

    .svg {
      filter: brightness(0) invert(1);
    }
  }

  &__disabled {
    background: var(--disabled-gray);
  }
}
