.copy-to-list-step-editor {
  display: block;

  &__matching-email {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-unit);

    button {
      margin-top: var(--spacing-unit);
    }

    svg {
      fill: var(--light-gray);
    }

    label {
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__source-selector {
    margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x3) 0;
  }
}
