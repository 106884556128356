.launch-permission-modal {
  width: 100%;
  max-width: calc(100 * var(--spacing-unit));
  overflow: unset;

  .modal-header__inner {
    border-top-left-radius: 0.333rem;
    border-top-right-radius: 0.333rem;
  }
  
  .modal-body {
    padding-top: var(--spacing-unit-x4);
    padding-bottom: var(--spacing-unit-x8);
    overflow: unset;
  }

  &-description {
    margin-bottom: var(--spacing-unit-x4);
  }
}