.step-one {
  &__selected-parent {
    &-centered {
      justify-content: center;
    }
    
    &-container {
      border: var(--standard-border);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
      height: var(--spacing-unit-x10);
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
      width: 60.22rem;
    }
  }
}