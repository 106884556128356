.display-conditions-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  & .container {
    border-top: calc(var(--spacing-unit-x2) / 3) solid var(--teal);

    &__wrapper {
      width: 100%;
    }
  }

  &--has-errors {
    & .container {
      border: var(--program-step-border-error);
      border-top: calc(var(--spacing-unit-x2) / 3) solid var(--error-focus);
    }
  }

  &__divider {
    display: flex;
    gap: var(--spacing-unit-x4);
    align-items: center;

    &__line {
      flex: 1 1;
      border: var(--standard-border);
      margin-top: var(--spacing-unit);
      border-radius: var(--standard-border-radius);

      &__conditions {
        border: var(--standard-border-dark);
        border-bottom: none;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--standard-border);
    padding: 0 var(--spacing-unit-x4);
    height: var(--spacing-unit-x10);

    &__actions {
      display: flex;
      gap: var(--spacing-unit);
    }

    &__divider {
      align-self: stretch;
      width: 0.0834rem;
      background-color: var(--border-gray);
      margin-left: var(--spacing-unit);
      margin-right: var(--spacing-unit);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

    &__grouping-operator {
      display: flex;
      gap: var(--spacing-unit-x2);
      align-items: center;
      justify-content: flex-start;

      &-select {
        min-width: var(--spacing-unit-x16);

        & .selectv2__menu-list {
          min-width: var(--spacing-unit-x24);
        }
      }
    }
  }
}
