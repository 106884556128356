.push-setting {
  margin-bottom: var(--spacing-unit-x4);
  position: relative;

  &__editable-container {
    &-container {
      max-width: 73rem;
    }
  }

  &__loader {
    margin: 0 auto;
  }

  &__subheader {
    margin: var(--spacing-unit-x2) 0;
  }

  &__push-images {
    margin-top: var(--spacing-unit-x4);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-unit-x6);

    svg {
      flex-shrink: 0;
    }
  }

  &__push-image-container {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    display: flex;
    align-items: center;
    max-width: 34rem;
    width: 100%;
    gap: var(--spacing-unit-x2);
    justify-content: center;
    height: 4rem;
  
    &-text {
      padding-right: var(--spacing-unit-x4);
    }
  }

  &__push-selector {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    background-color: var(--light-theme-background-darker);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x3) 0;

    &-subheader {
      margin: var(--spacing-unit-x2) 0;
      max-width: 66.667rem;
    }

    .radio-card__tooltip__trigger-wrapper {
      margin-top: var(--spacing-unit-x2);
    }
  }
}
