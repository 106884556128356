.upgrade-helper {
  display: block;

  &__button.button--icon {
    .svg {
      height: var(--spacing-unit-x4);
      width: var(--spacing-unit-x4);
    }
  }
}
