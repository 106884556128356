.poll-block-response {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  &-text {
    color: var(--text-black);
    word-wrap: break-word;
    width: 100%;
  }

  .typography__italic {
    color: var(--light-gray);
  }

  &__container {
    width: 90%;
    max-width: 46rem;
    margin-top: var(--spacing-unit-x8);
    padding: var(--spacing-unit-x4);
    border: var(--standard-border);
    border-radius: var(--large-border-radius);
  }

  .textarea {
    outline: none;
  }

  &__image {
    height: 12rem;
    width: 100%;
    margin-bottom: var(--spacing-unit-x4);
    border-radius: var(--large-border-radius);
    object-fit: cover;
    aspect-ratio: 3.88 / 1;
  }

  &__prompt {
    margin-bottom: var(--spacing-unit-x3);
    font-weight: var(--font-weight-bold);
    word-wrap: break-word;
    width: 100%;
  }

  &__choiceHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-edit {
      padding-left: var(--spacing-unit-x2);
      color: var(--text-black);
    }
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
    margin-top: var(--spacing-unit-x2);

    &-checkbox {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      padding: calc(var(--spacing-unit-x4) / 3) var(--spacing-unit-x2);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);

      .typography {
        margin-left: 1.8333rem;
      }

      .checkbox {
        border: var(--standard-border-dark-blue);
        border-color: var(--tab-gray);

        &__label {
          .typography {
            margin-left: 0;
          }
        }

        &:hover {
          background-color: var(--selectable-row-hover);
          border-color: var(--gray);
        }
      }

      .checkbox--checked {
        border: var(--standard-border-dark-blue);
        background-color: var(--dark-blue);

        &:hover {
          background-color: var(--dark-blue);
          border: var(--standard-border-dark-blue);
        }
      }

      &--checked {
        border: var(--table-actions-border);

        .typography {
          margin-left: unset;
        }
      }

      &--edit {
        padding: calc(var(--spacing-unit-x4) / 3) calc(var(--spacing-unit) + var(--half-spacing-unit));

        &:hover {
          border-color: var(--tab-gray);
        }
      }

      &--edit:has(.checkbox--checked) {
        border: var(--standard-border-dark-blue);

        &:hover {
          border-color: var(--tab-gray);
        }
      }
    }
  }

  &__radios {
    &-radio {
      width: 100%;
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      padding: calc(var(--spacing-unit-x4) / 3) var(--spacing-unit-x2);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);

      .typography {
        margin-left: 1.8333rem;
      }

      &--checked {
        border: var(--table-actions-border);

        .typography {
          margin-left: unset;
        }
      }

      &--edit {
        padding: calc(var(--spacing-unit-x4) / 3) calc(var(--spacing-unit) + var(--half-spacing-unit));

        &:hover {
          border-color: var(--tab-gray);
        }

        .typography {
          margin-left: unset;
        }
      }

      &--edit:has(.radio--checked) {
        border: var(--standard-border-dark-blue);

        &:hover {
          border-color: var(--tab-gray);
        }
      }

      & .radio {
        &__container {
          margin: 0;
          width: 100%;
        }

        &--checked {
          .label {
            &::before {
              border: var(--standard-border-dark-blue);
              background: var(--dark-blue);
            }

            &:hover {
              &::before {
                background: var(--dark-blue);
              }
            }
          }
        }

        &__label {
          height: auto;

          &::after,
          &::before {
            top: 0.25rem;
            bottom: unset;
            border: var(--standard-border-dark-blue);
          }

          &:hover {
            &::before {
              background-color: var(--selectable-row-hover);
            }
          }
        }
      }
    }

    .radiogroup--vertical {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__commentPrompt {
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit);
  }

  &__comment {
    display: flex;

    &-divider {
      width: 0.1666rem;
      background: var(--dark-blue);
    }

    &-content {
      width: 100%;
      background-color: var(--lighter-off-white);
      padding: var(--spacing-unit-x2) calc(var(--spacing-unit) + var(--half-spacing-unit));

      &-main {
        padding: var(--spacing-unit) calc(var(--spacing-unit) + var(--half-spacing-unit));

        &-textOnly {
          padding: calc(var(--spacing-unit-x5) / 3) var(--spacing-unit-x2);
        }
      }

      &-description {
        margin-top: calc(var(--spacing-unit) + var(--half-spacing-unit));
      }
    }
  }

  &__textarea {
    height: 10rem;
    margin-top: var(--spacing-unit-x2);
  }

  &__infoText {
    margin-top: var(--spacing-unit-x4);
    color: var(--dark-blue);
  }

  &__infoText-comment {
    color: var(--dark-blue);
    margin-top: var(--spacing-unit-x2);
  }

  &__submitButton {
    margin-top: var(--spacing-unit-x3);
  }

  &__otherResponses {
    width: 90%;
    max-width: 46rem;
    padding-top: var(--spacing-unit-x4);

    &-charts {
      display: flex;
      flex-direction: column;
      gap: 1.3333rem;
      margin: var(--spacing-unit-x2) 0;
    }

    &-chart {
      max-width: 49.666rem;
      height: var(--spacing-unit-x3);
      background-color: var(--dark-blue);
      border-radius: var(--small-border-radius);

      &--empty {
        display: none;

        &-label {
          padding-left: var(--spacing-unit-x2);
          border-left: var(--standard-border);
          border-color: var(--disabled-plus-gray);
        }
      }

      &-container {
        display: flex;
        align-items: center;
        gap: var(--spacing-unit-x2);
        margin-top: var(--spacing-unit);
      }
    }
  }

  &__comments {
    width: 100%;
    max-width: 46rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);

    &-container {
      width: 90%;
      max-width: 46rem;
      max-height: 50rem;
      margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x8);
    }
  }
}
