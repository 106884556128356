:root {
  --modal-width: 50rem;
}

.manager-assign-accounts-modal {
  width: var(--modal-width);
  height: calc(var(--modal-width) + var(--spacing-unit-x8));

  &__header {
    &__extra {
      margin-left: auto;
    }
  }

  .modal &__body {
    padding: 0;

    .tableV2 {
      min-width: 100%;
      margin: 0;
    }

    .tableV2-head__checkbox,
    .tableV2-tbody__checkbox {
      width: var(--spacing-unit-x13);
      max-width: var(--spacing-unit-x13);
      text-align: unset;
      padding-left: var(--spacing-unit-x7);
    }

    table {
      min-width: auto;
    }
  }

  &__footer {
    &__extra {
      margin-right: auto;
      display: flex;
      align-items: center;

      &-name {
        max-width: 17rem;
        padding-right: calc(var(--spacing-unit-x4) / 12);
      }

      &-count {
        padding-left: calc(var(--spacing-unit-x4) / 12);
      }
    }
  }
}
