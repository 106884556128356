.list-picker-modal-footer {
  &__toggle-button {
    display: inline-block;
    border-left: var(--standard-border);
    margin-left: var(--spacing-unit-x4);
    padding-left: var(--spacing-unit-x4);

    .toggle {
      margin-left: 0;
    }
  }

  &__tooltip-button {
    margin-left: var(--spacing-unit-x2);
  }
}
