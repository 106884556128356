.table-pagination {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-unit-x4);
  border-top: var(--standard-border);

  &__controls {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);

    &-without-input {
      gap: 1.1875rem;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    & * {
      display: block;
    }

    button {
      cursor: pointer;

      &:disabled {
        pointer-events: none;

        svg {
          fill: var(--button-hover);
        }
      }

      svg {
        fill: var(--text-gray);

        &:hover {
          fill: var(--hover-create-option-icon);
        }
      }
    }
  }

  &__count {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);

    &-input {
      width: 5.166rem;

      .input {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
    }
  }

  &__arrow-background {
    height: var(--spacing-unit-x5);
    width: var(--spacing-unit-x5);
    display: flex;
    align-items: center;
    justify-content: center;

    &-active:hover {
      border-radius: 0.3333rem;
      background-color: var(--hover-row-background-teal);

      svg {
        fill: var(--hover-create-option-icon);
      }
    }
  }
}
