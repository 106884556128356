.details-card-wrapper {
  display: block;

  &__contact-card {
    &-header {
      display: flex;
      gap: var(--spacing-unit-x3);
      align-items: center;

      &-info {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
      }
    }

    &-information {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
      margin-left: var(--half-spacing-unit);

      > .tooltip__trigger {
        width: fit-content;
      }

      &-field {
        display: flex;
        gap: var(--spacing-unit-x2);
        align-items: center;
      }
    }

    &-activities {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
    }

    &-details {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
    }
  }
}
