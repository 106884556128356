.submission-details {
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  display: flex;
  flex-direction: column;
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

  &__header {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x3);
  }

  &__icon {
    transform: none !important;
    flex-shrink: 0;
    height: var(--spacing-unit-x4) !important;
    width: var(--spacing-unit-x4) !important;
  }

  &__title {
    padding: var(--spacing-unit) 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__item {
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    border-bottom: var(--standard-border);

    &-field {
      display: flex;
      gap: var(--half-spacing-unit);
    }
    
    &-response {
      word-break: break-all;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
