.security__section {
  & + & {
    border-top: var(--standard-border);
    margin: var(--spacing-unit-x4) 0;
    padding-top: var(--spacing-unit-x4);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-unit-x2);
    align-items: center;
    height: var(--spacing-unit-x4);

    &-status {
      display: flex;
      align-items: center;
      padding: 0 var(--spacing-unit-x2);

      .svg {
        margin-right: var(--spacing-unit);
        width: var(--spacing-unit-x2);
        height: var(--spacing-unit-x2);
      }
    }
  }

  &__display-value {
    margin-top: var(--spacing-unit-x4);
  }

  &__description {
    max-width: 90%;
  }

  &__content {
    padding-top: var(--spacing-unit-x3);

    .input-with-status {
      min-width: calc(var(--spacing-unit-x20) * 3);
    }

    .security-setting-dropdown {
      max-width: 32rem;
    }
  }

  &__actions {
    display: flex;
    margin-top: var(--spacing-unit-x2);

    .button {
      margin-right: var(--spacing-unit-x4);
    }
  }
}
