.typography {
  color: var(--text-gray);
  font-size: var(--large-font-size);
  line-height: var(--line-height-large);
  font-weight: var(--font-weight-medium-light);

  &__type- {
    &body-text {
      &-small,
      &-small-light,
      &-small-lighter,
      &-lightest-gray-small,
      &-gray-small,
      &-white-small {
        font-size: var(--medium-font-size);
        line-height: var(--line-height-smallish);
      }

      &-lightest-gray-small,
      &-lightest-gray {
        color: var(--lightest-gray);
      }

      &-lighter,
      &-small-lighter {
        color: var(--tab-gray);
      }

      &-light,
      &-light-tiny,
      &-small-light,
      &-large-light {
        color: var(--light-gray);
      }

      &-input {
        color: var(--light-gray);
        line-height: var(--line-height-standard);
      }

      &-large,
      &-large-light,
      &-gray-large {
        font-size: var(--larger-font-size);
      }

      &-tiny,
      &-white-tiny,
      &-light-tiny {
        font-size: var(--smallest-font-size);
        line-height: var(--line-height-smallest);
      }

      &-light-tiny {
        line-height: var(--line-height-small);
      }

      &-white,
      &-white-regular,
      &-white-small,
      &-white-tiny {
        color: var(--white);
      }

      &-gray,
      &-gray-large,
      &-gray-medium,
      &-gray-small,
      &-gray-tiny {
        color: var(--gray);
      }

      &-gray-medium {
        font-size: var(--large-medium-font-size);
      }

      &-white-regular,
      &-lightest-gray {
        font-size: var(--large-font-size);
        line-height: var(--line-height-large);
      }
    }

    &extra-large {
      font-size: var(--extra-large-font-size);
    }

    &link {
      &,
      &-large,
      &-larger,
      &-small,
      &-tiny {
        color: var(--text-teal);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          color: var(--darker-teal);
        }

        &:focus {
          color: var(--focus-teal);
        }
      }

      &-sms {
        color: var(--sms-preview-link);
      }

      &-white,
      &-white-small,
      &-white-larger,
      &-white-large,
      &-white-tiny {
        color: var(--white);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          color: var(--white);
        }

        &:focus {
          color: var(--white);
        }
      }

      &-white-large,
      &-large {
        font-size: var(--large-font-size);
      }

      &-white-larger,
      &-larger {
        font-size: var(--larger-font-size);
      }

      &-white-small,
      &-small {
        font-size: var(--medium-font-size);
      }

      &-white-tiny,
      &-tiny {
        font-size: var(--small-font-size);
        line-height: var(--line-height-small);
      }
    }

    &data-card-text {
      color: var(--text-gray);
      font-size: var(--small-page-header-font);
      line-height: var(--line-height-extra-large);

      &-bold {
        font-weight: var(--font-weight-bold);
      }

      &-light {
        color: var(--light-gray);
        font-size: var(--larger-font-size);
        font-weight: var(--font-weight-medium);
        line-height: var(--line-height-large-medium);
      }
    }

    &emphasized-text {
      font-size: var(--large-font-size);

      &-teal {
        color: var(--text-teal);
      }
    }

    &normal-text {
      &-teal,
      &-teal-small,
      &-teal-large,
      &-white-large,
      &-gray,
      &-gray-large {
        line-height: var(--line-height-medium);
      }

      &-teal-small {
        font-size: var(--smallest-font-size);
      }

      &-teal,
      &-gray {
        font-size: var(--medium-font-size);
      }

      &-gray-large {
        font-size: var(--large-font-size);
      }

      &-teal,
      &-teal-small,
      &-teal-large {
        color: var(--text-teal);
      }

      &-white-large {
        color: var(--white);
      }

      &-gray,
      &-gray-large {
        color: var(--light-gray);
      }

      &-darker-blue {
        color: var(--darker-blue);
      }

      &-teal-larger {
        color: var(--text-teal);
        font-size: var(--larger-font-size);
      }

      &-disabled {
        color: var(--button-gray);
        font-size: var(--medium-font-size);
      }

      &-disabled-large {
        color: var(--button-gray);
        font-size: var(--large-font-size);
      }
    }

    &error {
      font-size: var(--medium-font-size);

      &,
      &-small,
      &-large,
      &-large-medium {
        color: var(--error);
      }

      &-small {
        font-size: var(--small-font-size);
      }

      &-large,
      &-large-medium {
        font-size: var(--large-font-size);
      }
    }

    &error-new {
      color: var(--error-text);
      font-size: var(--medium-font-size);
    }

    &validation-error {
      font-size: var(--default-font-size);
      color: var(--remove-red);
      line-height: var(--line-height-standard);
      
      &-large {
        color: var(--remove-red);
        font-size: var(--large-font-size);
        line-height: var(--line-height-large);
      }
    }

    &header- {
      &page,
      &sub {
        line-height: var(--page-header-font);
      }

      &page,
      &page-semi-bold {
        font-size: var(--sub-header-font-size);
        line-height: var(--line-height-title-page);

        &-large {
          font-size: var(--page-header-font);
        }
      }

      &sub,
      &table {
        text-transform: uppercase;
      }

      &sub {
        font-size: var(--medium-font-size);
        color: var(--light-gray);
      }

      &table {
        font-size: var(--smallest-font-size);
        line-height: var(--line-height-large);
        color: var(--light-gray);
        white-space: nowrap;
      }

      &section {
        font-size: var(--larger-font-size);
        line-height: var(--line-height-larger);
  
        &-light,
        &-disabled {
          font-size: var(--larger-font-size);
          line-height: var(--line-height-larger);
          color: var(--light-gray);
        }

        &-disabled {
          color: var(--disabled-plus-gray);
        }
      }

      &banner,
      &banner-dark {
        color: var(--white);
        font-size: var(--sub-header-font-size);
        line-height: var(--line-height-banner);

        &-dark {
          color: var(--text-gray);
        }
      }

      &field-mapping {
        font-size: var(--medium-font-size);
        color: var(--blue);
        line-height: var(--line-height-medium-small);

        &-sub {
          color: var(--light-gray);
          font-size: var(--smallest-font-size);
          line-height: var(--line-height-medium-large);
        }

        &-small {
          font-size: var(--smallest-font-size);
          color: var(--blue);
          line-height: var(--line-height-standard);
        }
      }

      &modal {
        font-size: var(--large-font-size);
        line-height: var(--line-height-small);

        &-small {
          font-size: var(--sub-header-font-size);
        }

        &-sub-info {
          font-size: var(--sub-header-font-size);
          color: var(--light-gray);
          line-height: var(--line-height-large);
        }
      }
    }

    &headline- {
      &modal {
        font-size: var(--sub-header-font-size);
        line-height: var(--line-height-larger);

        &-confirmation {
          font-size: var(--page-header-font);
          line-height: var(--line-height-headline-page);
        }
      }

      &modal-small {
        font-size: var(--large-font-size);
        line-height: var(--line-height-medium);
      }
       
      &modal-large {
        font-size: var(--page-header-font);
      }

      &page {
        font-size: var(--large-page-header-font);
        line-height: var(--line-height-headline-page);
      }

      &page-small {
        font-size: var(--small-page-header-font);
        line-height: var(--line-height-headline-page-small);
      }
    }

    &data-card- {
      &info {
        color: var(--text-gray);
        font-size: var(--small-page-header-font);
      }

      &modal-header {
        color: var(--text-gray);
        font-size: var(--sub-header-font-size);
        line-height: var(--line-height-banner);
      }
    }

    &code-text- {
      &medium {
        line-height: var(--line-height-medium);
        font-family: monospace;
        font-size: var(--medium-font-size);
      }

      &large {
        line-height: var(--line-height-large);
        font-family: monospace;
        font-size: var(--large-font-size);
      }
    }
  }

  &__bold {
    font-weight: var(--font-weight-bold);
  }

  &__extra-bold {
    font-weight: var(--font-weight-extra-bold);
  }

  &__italic {
    font-style: italic;
  }

  &__medium-light {
    font-weight: var(--font-weight-medium-light);
  }

  &__semi-medium {
    font-weight: var(--font-weight-semi-medium);
  }

  &__medium {
    font-weight: var(--font-weight-medium);
  }

  &__inline {
    display: inline;
  }

  &__inline-block {
    display: inline-block;
  }

  &__center {
    text-align: center;
  }

  &__right {
    text-align: right;
  }

  &__no-trim {
    white-space: pre-wrap;
  }

  &__toggletip {
    &__trigger {
      padding: 0 var(--half-spacing-unit);
      margin-left: var(--half-spacing-unit);
    }
  }
}
