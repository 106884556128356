
.divided-content-data-card {
  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-unit-x3);
    height: 100%;
    justify-content: space-between;
  }

  &__left-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
  }

  &__right-content {
    align-self: center;
    flex-grow: 1;
  }
}
