.email-composer-preview-validations {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--standard-border-dark);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

    &-title {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }
  }

  &__accordion {
    &__item {
      border-bottom: var(--standard-border-dark);
      padding: 0;
    }

    &__header {
      height: auto;
    }

    &__panel {
      margin: 0;
    }

    &__button {
      gap: var(--spacing-unit-x2);
      border-radius: 0;
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

      &:hover {
        background-color: var(--hover-row-background-teal);
      }
    }

    &-header {
      display: flex;
      gap: var(--spacing-unit-x2);

      &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        gap: var(--half-spacing-unit);

        > .typography {
          text-align: inherit;
        }
      }

      .svg {
        width: var(--spacing-unit-x4);
        height: var(--spacing-unit-x4);
      }
    }
  }
}
