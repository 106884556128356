.scheduled-messages {
  margin-top: var(--spacing-unit-x6);
  width: 100%;

  &__table__table-holder {
    width: 100%;

    .tr {
      max-width: 100% !important;
    }
  }

  &__header-flex-cell {
    display: flex;
    gap: 0.75rem;
    align-items: center;

    .tooltip__trigger {
      display: inherit;

      &:hover {
        .svg--fill-light-gray {
          fill: var(--text-gray);
        }
      }
    }
  }

  &__cancel-send {
    .modal-header {
      text-align: center;
    }
  }
}
