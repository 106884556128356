.security {
  background-color: var(--white);
  padding: var(--spacing-unit-x4);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);

  &-form {
    max-width: calc(var(--spacing-unit-x24) * 3);
    margin-top: var(--spacing-unit-x6);

    &-row-with-action {
      margin-bottom: var(--spacing-unit);
    }
  }

  &-description {
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x4);
  }

  &__input-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button-show:hover {
    svg {
      fill: var(--darker-teal);
    }
  }

  &__button {
    margin-top: var(--spacing-unit-x2);
  }

  .tooltip__trigger,
  .input-with-status {
    width: 100%;
  }

  &__error-container {
    display: flex;
    flex-direction: column;
  }
}
