.subscription-category-management {
  &__categories-container {
    padding-top: var(--spacing-unit-x3);
    padding-left: var(--spacing-unit-x4);
    padding-right: var(--spacing-unit-x4);
    margin-top: var(--spacing-unit-x4);
    background-color: var(--white);
    border-radius: 0.3rem;
    border: var(--standard-border);
    margin-bottom: var(--spacing-unit-x6);
  }

  &__data-container {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-unit-x4);
  }

  &__category-headers {
    display: flex;
    margin-top: var(--spacing-unit-x4);
    padding-bottom: 1.58333rem;
    justify-content: space-between;
  }

  &__page-description {
    margin-top: var(--spacing-unit-x4);
  }

  &__child-category-header {
    width: 40.23rem;
    padding-left: 3.27rem;
  }

  &__parent-cat-row {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__parent-cat-row:last-of-type {
    margin-bottom: 2.416rem;
  }

  &__children {
    align-items: center;
    gap: var(--spacing-unit-x4);
    margin-bottom: 2.1666rem;
    display: flex;
  }

  &__category-desc {
    margin-top: 0.25rem;
  }

  .single-select-dropdown__wrapper {
    width: 36.91rem;
  }

  &__tooltip .typography {
    font-size: var(--smallest-font-size);
  }

  &__parents {
    width: 50%;
  }
}
