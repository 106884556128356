.sticky-container {
  &__fixed-container {
    position: absolute;
    box-shadow: var(--recipients-select-box-shadow);
    background-color: var(--page-back);
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index-99999);

    &-position {
      margin-left: var(--positioning-margin-left);
    }

    &-wrapper {
      width: 100%;
    }

    &-wrapper-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  &__fixed-container-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 5rem;
    max-width: var(--positioning-max-width);
    min-width: var(--positioning-min-width);
    padding: 0 var(--spacing-unit-x4);
  }
}

/* stylelint-disable */
@media (max-width: 1485px) {
  .sticky-container {
    &__fixed-container {
      &-position {
        margin-left: 0;
      }

      &-content {
        margin: 0;
      }
    }
  }
}
