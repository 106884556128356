.managers {
  margin: var(--spacing-unit-x12) var(--spacing-unit-x4) 0 0;
  overflow-y: auto;

  .sb-show-main & {
    margin-left: 0;
  }

  &__page-header {
    margin-left: var(--spacing-unit-x4);
  }

  &__sub-header-container,
  &__table {
    margin-left: var(--spacing-unit-x4);
  }

  &__table-helpers {
    display: flex;
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x4) var(--spacing-unit-x4);
    align-content: flex-end;

    &-accounts {
      flex: 1;

      &-select {
        max-width: 27rem;
      }
    }

    &-search {
      display: flex;
      align-content: flex-end;
      flex-wrap: wrap;
      width: 25rem;

      .input-with-clear__clear-button-divider-visible,
      .input-with-clear__clear-button {
        top: var(--spacing-unit-x7);
      }
    }
  }

  &__manager-name {
    display: flex;
    align-items: center;
  }

  .single-select-dropdown__wrapper,
  .single-select-dropdown-root__dropdown-container {
    width: 27rem !important;
  }
}
