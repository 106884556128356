.invite-users-modal {
  width: 100%;
  max-width: 83rem;

  .modal-body {
    padding: 0 var(--spacing-unit-x7);
    padding-right: 0;
  }

  &__content {
    &__grid {
      margin-top: 0;

      &-column {
        padding: var(--spacing-unit-x5) 0 var(--spacing-unit-x4) 0;

        &:not(:first-child) {
          padding-right: var(--spacing-unit-x7);
          padding-left: var(--spacing-unit-x4);
          max-height: var(--invite-users-modal-height);
          overflow: auto;
        }

        &:not(:last-child) {
          border-right: var(--standard-border);
          padding-left: 0;
          padding-right: var(--spacing-unit-x4);
        }
      }
    }
  }

  &__error-modal {
    .caution {
      white-space: pre-line;
    }
  }
}

.label-with-svg-tooltip {
  &__tooltip {
    .typography {
      white-space: pre-line !important;
    }

    .tooltip__actions {
      width: auto;
    }
  }
}
