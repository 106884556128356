
.tags-tab {
  display: block;

  &__inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: var(--spacing-unit-x4);

    svg {
      fill: var(--text-gray);
    }
  }

  &__left-inputs {
    display: flex;

    .text-link {
      align-self: center;
      margin-left: var(--spacing-unit-x2);
    }
  }

  &__multi-select {
    width: 20.67rem;
  }

  &__right-options {
    display: flex;
  }

  .typography {
    align-self: center;
  }

  &__table-wrapper {
    background: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
