.segment-name-with-info-hover {
  display: block;

  &__header {
    &-title {
      color: var(--white);
    }

    &-subtitle {
      &-main {
        color: var(--white);
      }

      &-secondary {
        color: var(--lightest-gray);
      }
    }
  }

  &__info-hover {
    &-scroll .scroll-area__viewport {
      height: 20rem;
    }

    &-icon .info-hover-card > a {
      align-items: center;
      display: flex;
    }
  }

  &__system {
    &-scroll {
      height: unset;
    }
  }

  &__regular {
    &-scroll {
      max-height: 17.25rem;

      > .scroll-area__root {
        height: 17.5rem;
      }
    }
  }
}
