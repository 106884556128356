
.initial-message {
  &__main-container {
    display: flex;
    padding: var(--spacing-unit-x4) 0;
  }

  &__left-container {
    flex: 2;
    margin-right: var(--spacing-unit-x4);
  }

  &__right-container {
    flex: 0 0 29rem;
  }
}

.initial-message-splash {
  &__headline {
    text-align: left;
  }

  &__image {
    margin: var(--spacing-unit-x7) 0 var(--spacing-unit-x6);
    text-align: center;

    img {
      height: 20rem;
    }
  }

  &__info-ctr {
    margin: 0 auto;
    width: 54.75rem;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x4);
  }

  &__info,
  &__action {
    display: flex;
    justify-content: center;
  }

  &__info .typography {
    width: 50.75rem;
  }

  &__action {
    margin-top: var(--spacing-unit-x8);
  }
}
