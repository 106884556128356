.time-input {
  display: block;
  width: 14.5rem;

  &__clear-hidden {
    width: 11.5rem;
  }

  .react-time-picker {
    &__wrapper {
      height: var(--spacing-unit-x6);
      border-color: var(--border-gray);
      border-radius: calc(1rem / 6);
      background-color: var(--input-background);
      padding: var(--spacing-unit) var(--spacing-unit) var(--spacing-unit) var(--spacing-unit-x2);

      &:focus-within {
        border: none;
        outline: var(--input-outline-border);
      }

      input {
        color: var(--text-gray);
        font-family: var(--font-regular);
        font-size: var(--input-font-size);
      }

      input,
      select,
      button {
        &:hover:not(button) {
          outline: var(--standard-border-width) solid var(--disabled-teal);
        }

        &:focus:not(button) {
          outline: var(--standard-border-width) solid var(--dark-blue);
        }

        &:focus-visible {
          box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
        }
      }

      .svg {
        fill: var(--button-hover);
      }

      &:hover {
        background-color: var(--white);
        border-color: var(--dark-blue);
      }
    }

    &__inputGroup {
      &__leadingZero {
        color: var(--text-gray);
        font-family: var(--font-regular);
        font-size: var(--input-font-size);
        position: relative;
      }

      &__amPm {
        color: var(--text-gray);
        margin-left: var(--spacing-unit-x4);
        display: inline-block;
      }
    }
  }

  &__disabled {
    .react-time-picker {
      &__wrapper:hover {
        background-color: var(--input-background);
        border-color: var(--border-gray);
      }

      &__clear-button:hover {
        cursor: unset;
      }
    }

    span,
    input {
      color: var(--light-gray) !important;
    }

    input,
    select {
      outline: unset !important;
    }
  }

  &__error {
    .react-time-picker {
      &__wrapper {
        border-color: var(--error);
      }
    }
  }
}

.time-input.react-time-picker--open {
  .react-time-picker__wrapper {
    background-color: var(--white);
  }
}
