.import-summary {
  text-align: center;
  margin-top: var(--spacing-unit-x2);
  width: 100%;

  &__add-contact-preference {
    width: 33rem;

    & .selectv2__container {
      margin-bottom: var(--spacing-unit-x5);
    }
  }

  &__rectangle {
    margin: 0;
    border: none;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x5) var(--spacing-unit-x4);
    background-color: var(--lighter-off-white);
    display: grid;
    column-gap: var(--spacing-unit-x6);
    row-gap: var(--spacing-unit-x4);
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
  }

  &__title {
    display: flex;
  }

  &__value {
    float: left;
  }
}
