
.radiogroup {
  & > label {
    display: block;
  }

  &--vertical {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
  }

  &--horizontal {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
  }
}
