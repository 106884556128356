.content-expander {
  display: flex;

  &__nesting-controls {
    display: flex;

    & + & {
      padding-right: 0;
    }

    .svg {
      margin-bottom: 0.3rem;
      fill: var(--hover-teal);
    }
  }
}
