.page-header-option {
  display: flex;
  align-items: center;
  gap: var(--spacing-unit);

  > div:first-child {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);
  }

  &__option-svg {
    fill: var(--teal);
    color: var(--teal);

    &-unselected {
      fill: var(--light-gray);
      color: var(--light-gray);
    }
  }
}
