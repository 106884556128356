.plain-text-composer {
  width: 60rem;
  display: flex;
  flex-direction: column;
  box-shadow: var(--nav-box-shadow);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  box-sizing: content-box;

  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: var(--spacing-unit-x4) 0;
  }

  &__header {
    display: flex;
    gap: var(--spacing-unit);
    align-items: center;
    background-color: var(--ao-var-dark-blue);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    border-radius: var(--standard-border-radius)  var(--standard-border-radius) 0 0;
    border-bottom: var(--standard-border);
    box-shadow: var(--nav-box-shadow);
  }

  &__content {
    height: calc(100vh - 27rem);
    min-height: 25rem;
    border: none;
    border-radius: unset;
  }

  &__footer {
    border-radius: 0  0 var(--standard-border-radius) var(--standard-border-radius);
    padding: calc(var(--spacing-unit) + var(--half-spacing-unit)) var(--spacing-unit-x2);

    &__wrapper {
      margin-top: 0 !important;
    }
  }

  &__textarea,
  &__textarea:focus,
  &__textarea:hover {
    height: 100%;
    font-family: Fira Code, monospace;
    resize: none;
    border: none;
    border-radius: 0;
    outline: none;
    background-color: var(--white);
    padding: var(--spacing-unit-x4);
    box-sizing: border-box;
  }
}

/* stylelint-disable */
@media (max-width: 1024px) {
  /* stylelint-enable */
  .plain-text-composer {
    width: 50rem;
    min-width: 50rem;

    &__container {
      width: unset;
      min-width: 54rem;
      margin: var(--spacing-unit-x4);
    }
  }
}
