.radio-card-group {
  .radio-card {
    margin-bottom: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

    &:last-child {
      margin-bottom: unset;
    }
  }

  &__additional-options {
    margin-top: var(--spacing-unit-x2);

    &__item {
      padding: 0;

      &:last-of-type {
        border-bottom: none;
      }
    }

    &__button {
      justify-content: left;
      padding: var(--spacing-unit-x2) 0;

      .svg {
        margin: 0 var(--spacing-unit-x2);
        margin-left: 0 !important;
      }
    }

    &__panel {
      margin-top: 0;
    }

    &__panel[data-state='open'] {
      animation: var(--slide-down-accordion-fade);
    }

    &__panel[data-state='closed'] {
      animation: var(--slide-up-accordion-fade);
    }
  }
}
