
.caret-icon {
  fill: var(--gray);
  transition: transform var(--linear-120);

  &--toggled {
    transform: rotate(90deg);
  }

  &--down,
  &--up {
    margin: 0 0.15rem;
    width: 0.6rem;
    height: 0.6rem;
  }

  &--right {
    margin: 0 0.26rem;
    width: 0.41rem;
    height: 0.66rem;
  }
}
