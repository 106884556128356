.crm-entity-selector {
  &__open {
    background-color: var(--text-teal);
    color: var(--white);

    & .svg {
      fill: var(--white);
    }
  }

  &__dropdown {
    max-height: 30.5rem;
    width: 25rem;
    overflow-y: auto;
  }
}
