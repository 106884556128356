.clickthrough-link-block {
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  gap: var(--spacing-unit-x4);

  &__actions {
    gap: var(--spacing-unit);
  }

  .input-with-status__tooltip__trigger-wrapper {
    flex: 4 1 0;

    &:first-child {
      flex: 3 1 0;
    }
  }
}
