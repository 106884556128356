.button-menu {
  button .svg {
    margin-bottom: 0;
  }

  &__open {
    &-trigger {
      background-color: var(--text-teal) !important;
      color: var(--white);
    }
  }

  &__options {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    width: 25rem;
    box-shadow: 0 var(--spacing-unit) var(--spacing-unit-x2) 0 #0000000F;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x2);
    background-color: var(--white);

    &:hover {
      background-color: var(--hover-row-background-teal);
      cursor: pointer;
    }
  }

  &__divider {
    border-top: var(--standard-border);
  }
}
