.next-run-data-card {
  &__next-run {
    display: flex;
    align-items: center;

    .svg {
      margin-right: calc(10rem / 12);
      color: var(--light-gray);
      fill: var(--light-gray);
    }
  }

  .divided-content-data-card__right-content {
    align-self: flex-start;
    flex-grow: 0;
  }

  &__dropdown {
    &-row {
      display: inline-flex;
      align-items: center;
    }
  }
}
