.import-sales-users-modal {
  width: 100%;
  max-width: calc(var(--spacing-unit-x21) * 8);

  .modal-body {
    min-height: calc(var(--spacing-unit-x24) * 3);
    padding: 0;
  }

  &__header {
    &-button {
      padding: calc(var(--spacing-unit-x10) / 6) var(--spacing-unit-x2) !important;

      .svg {
        margin-bottom: 0;
      }
    }
  }

  &__users-count {
    margin-right: auto;
  }

  &__table {
    &__table-holder {
      border: none;

      .tr {
        &:last-of-type {
          .td {
            border-bottom: var(--standard-border);
          }
        }
      }
    }

    .thead {
      .tr {
        .th {
          &:last-of-type {
            margin-right: calc(var(--spacing-unit-x20) / 3);
          }

          &:first-of-type {
            margin-left: calc(var(--spacing-unit-x20) / 3);
          }
        }
      }
    }

    .td {
      &:last-of-type {
        margin-right: calc(var(--spacing-unit-x20) / 3);
      }

      &:first-of-type {
        margin-left: calc(var(--spacing-unit-x20) / 3);
      }
    }

    .no-value-td {
      color: var(--button-gray);
    }
  }
}
