.condition {
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
  padding: var(--spacing-unit-x2);

  &__conditions {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
  }
  
  &__divide-line {
    border-top: var(--standard-border);
    width: 100%;
  }

  &__operations {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x3);
  }

}