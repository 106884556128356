.toast {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  top: var(--spacing-unit-x12);
  z-index: var(--z-index-toast);
  animation: slidein 2s;
  transition: opacity 1s top 1.5s;
  pointer-events: none;
  min-height: var(--spacing-unit-x8);

  &__slideout {
    top: 0;
    transition: all 0.5s;
  }

  &__toast {
    align-items: center;
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    background-color: var(--white);
    border-radius: 0.333rem;
    box-shadow: 0 0.167rem 0.333rem 0 rgba(0, 0, 0, 0.1), 0 0.5rem 1.333rem 0 rgba(0, 0, 0, 0.02);
    width: 34rem;
    display: flex;
    pointer-events: auto;
  }

  &__no-padding {
    padding: 0 var(--spacing-unit-x2) 0 0;
    width: unset;

    .toast__text {
      flex: 0 1 auto;
      padding: var(--spacing-unit-x2) 0;
    }
  }

  &__text {
    flex: 0 1 22rem;
    color: var(--text-gray);
    font-size: var(--large-font-size);
    max-width: 36rem;
  }

  svg {
    flex: 0 0 4rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  button {
    flex: 8rem;
  }
}

@keyframes slidein {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: var(--spacing-unit-x12);
    opacity: 1;
  }
}
