.label-with-svg-tooltip {
  display: flex !important;
  align-items: center;

  .tooltip__trigger {
    margin-left: var(--spacing-unit);
    line-height: initial;
    display: flex;
  }

  &-required {
    margin-left: var(--half-spacing-unit);
  }

  &__change-on-hover {
    .svg {
      fill: var(--light-gray);

      &:hover {
        fill: var(--text-gray);
      }
    }
  }

  &__disabled {
    opacity: 0.5;
  }
}
