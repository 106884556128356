.date-input {
  display: flex;
  gap: var(--spacing-unit);

  &__date-picker {
    width: var(--spacing-unit-x28);
  }

  &__date-range-picker {
    width: 24rem;

    .ant-picker-input {
      input {
        padding: var(--spacing-unit) var(--spacing-unit-x2);
      }
    }

  }
}
