.edit-address-modal {
  width: 100%;
  max-width: 50rem;

  &-grid-row {
    margin: 0;
    padding-bottom: var(--spacing-unit-x3);
  }

  &-button-delete {
    &.button {
      margin-left: 0;
      margin-right: auto;
    }
  }

  .form-row {
    padding-bottom: var(--spacing-unit-x3);

    &:last-of-type {
      padding-bottom: 0;
    }

    .label {
      margin-bottom: var(--spacing-unit-x2);
    }
  }
}
