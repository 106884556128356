.create-suggested-segment-modal {
  width: 100rem;
  height: 60rem;
  
  &__body {
    &-header {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }
  }

  &__segment-info {
    margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x3) 0;

    > div:not(:last-child) {
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__preview-segment-button {
    padding-top: var(--spacing-unit);
    width: 9.4rem;

    button {
      cursor: not-allowed !important;
    }
  }

  &__footer {
    button:first-child {
      height: var(--spacing-unit-x6);
    }
  }
}
