.segment-definition-element-with-operator {
  &__operator {
    margin-bottom: var(--spacing-unit-x2);
    width: fit-content;

    &-preview {
      .typography {
        visibility: hidden;
      }
    }
  }
}
