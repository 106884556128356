.textarea {
  background-color: var(--white);
  margin: 0;
  width: 100%;
  border-radius: 0.25rem;
  color: var(--text-gray);
  font-size: var(--large-font-size);
  padding: var(--spacing-unit-x2);
  border: var(--input-border);
  box-sizing: border-box;
  transition: var(--linear-80);

  &::placeholder {
    color: var(--light-gray);
  }

  &:hover,
  &:focus {
    background-color: var(--white);
  }

  &:hover {
    border: var(--input-border-hover);
  }

  &:focus {
    border-color: var(--dark-blue);
  }

  &:focus-visible {
    outline: none;
  }

  &:focus,
  &:focus-visible {
    outline: var(--input-outline-border);
  }

  &:disabled {
    background-color: var(--off-white-hover);
    color: var(--button-gray);
    opacity: 0.7;

    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }

  &__no-resize {
    resize: none;
  }

  &__error {
    outline-color: var(--error);
    border-color: var(--error);

    &:hover,
    &:focus {
      outline: none;
      border-color: var(--error);
      box-shadow: none;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__resize-direction {
    &-vertical {
      resize: vertical;
    }

    &-horizontal {
      resize: horizontal;
    }
  }

  &__max-length {
    display: flex;
    align-items: center;
    gap: var(--half-spacing-unit);
  }
}
