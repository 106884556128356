
.program-step-icon {
  border-radius: calc(2rem / 12);
  height: var(--spacing-unit-x5);
  width: var(--spacing-unit-x5);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--off-white-hover);

  svg {
    height: 1.33rem;
    width: 1.33rem;
  }

  &--override {
    background-color: var(--text-gray);

    svg {
      height: calc(14rem / 12);
      width: calc(14rem / 12);
    }
  }

  &--green {
    background-color: var(--light-green);

    svg {
      fill: var(--white);
    }
  }

  &--magenta {
    background-color: var(--magenta);

    svg {
      fill: var(--white);
    }
  }

  &--teal {
    background-color: var(--lighter-teal);

    svg {
      fill: var(--hover-teal);
    }
  }

  &--orange {
    background-color: var(--light-orange);

    svg {
      fill: var(--orange);
    }
  }

  &--red {
    background-color: var(--light-red);

    svg {
      fill: var(--red);
    }
  }

  &--blue {
    background-color: var(--light-royal-blue);

    svg {
      fill: var(--royal-blue);
    }
  }

  &--gray {
    background-color: var(--icon-back-gray);

    svg {
      fill: var(--program-icon-gray);
    }
  }

  &--crm {
    background-color: var(--crm-back-gray);

    svg {
      fill: var(--crm-icon-gray);
    }
  }

  &--sms {
    background-color: var(--purple-icon);

    svg {
      fill: var(--white);
    }
  }

  &__icon-only {
    height: 1.33rem;
    width: 1.33rem;

    &--override {
      fill: var(--text-gray);
      height: calc(14rem / 12);
      width: calc(14rem / 12);
    }

    &--green {
      fill: var(--white);
    }

    &--magenta {
      fill: var(--white);
    }

    &--teal {
      fill: var(--hover-teal);
    }

    &--orange {
      fill: var(--orange);
    }

    &--red {
      fill: var(--red);
    }

    &--blue {
      fill: var(--royal-blue);
    }

    &--gray {
      fill: var(--program-icon-gray);
    }

    &--crm {
      fill: var(--crm-icon-gray);
    }
  }
}

@media print {
  .program-step-icon {
    &--green,
    &--magenta,
    &--teal,
    &--orange,
    &--red,
    &--blue,
    &--gray,
    &--crm {
      svg {
        fill: var(--gray);
      }
    }

    &__icon-only {
      &--green,
      &--magenta,
      &--teal,
      &--orange,
      &--red,
      &--blue,
      &--gray,
      &--crm {
        fill: var(--gray);
      }
    }
  }
}
