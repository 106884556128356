.send-test-email-options {
  padding-top: var(--spacing-unit-x3);
  border-top: var(--standard-border);

  &__input {
    max-width: 20rem;
  }

  &__textarea {
    height: 8rem;
  }

  &__accordion {
    &__panel[data-state='open'] {
      animation: var(--slide-down-accordion-fade);
    }

    &__panel[data-state='closed'] {
      animation: var(--slide-up-accordion-fade);
    }

    &__button {
      justify-content: left;
      gap: var(--spacing-unit-x2);
      padding: 1.5rem 0;
    }

    &__icon {
      margin-left: var(--spacing-unit);
      display: flex;
    }

    &__item {
      border-bottom: none;
      padding: 0;
    }

    &__panel {
      margin-top: var(--spacing-unit-x2);
    }
  }
}
