.landing-pages {
  margin: 0 calc(-1 * var(--spacing-unit-x4));

  &__empty {
    border-radius: var(--standard-border-radius);
    height: var(--spacing-unit-x12);
    margin: 0 var(--spacing-unit-x4) var(--spacing-unit-x4);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    background-color: var(--input-background);
  }

  .tableV2 {
    min-width: 100%;
  }
}
