.list-program-step {
  width: 100%;

  &__row {
    display: grid;
    align-items: center;
    column-gap: var(--spacing-unit-x2);
    margin: calc(var(--spacing-unit-x4) / 3) 0;
    grid-template-columns: auto 1fr;

    &-header {
      grid-column: 2;
      cursor: pointer;
      text-align: left;
      height: var(--spacing-unit-x6);

      &-caret {
        width: var(--spacing-unit-x2);
      }

      &-error-icon,
      &-step-name {
        margin-left: var(--spacing-unit);
      }

      &-step-name {
        width: 60rem;
      }
    }

    &-right {
      gap: var(--spacing-unit-x4);
      margin-right: var(--spacing-unit-x3);

      button {
        height: var(--spacing-unit-x6);
      }
    }
  }

  &__centered {
    display: flex;
    align-items: center;
  }

  &__step-details {
    margin-bottom: var(--spacing-unit-x3);
  }

  &__edit-container {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__step-details,
  &__edit-container {
    margin-left: calc(var(--spacing-unit-x9) + var(--half-spacing-unit));
  }

  &__edit-container,
  &__edit-input {
    width: 44rem;
  }

  &__edit-input {
    &__container {
      margin-left: var(--half-spacing-unit);
    }

    &-error {
      grid-column: 2;
      grid-row: 2;
      margin-left: var(--spacing-unit-x3);
      visibility: hidden;

      &-visible {
        visibility: visible;
      }
    }
  }
}
