.email-composer-preview-plain-text {
  width: 100%;
  height: 100%;
  background-color: var(--white);

  pre {
    min-height: 100%;
    font-size: var(--larger-font-size);
    line-height: 150%;
    white-space: pre-line;
    word-break: break-word;
    margin: 0;
    padding: 3.333rem;
    background-color: var(--white);
  }

  &--dark-mode {
    pre {
      background-color: var(--dark-mode-content-background);
      color: var(--white);
    }

    .email-composer-preview-plain-text__dynamic-text-block {
      &.container {
        border: var(--dark-mode-container-border);
      }

      &__title {
        background: var(--dark-mode-container-header-background);
      }

      &__content {
        background: var(--dark-mode-container-background);
      }
    }
  }

  &--mobile {
    overflow: hidden;
    border-radius: 0 0 var(--spacing-unit-x5) var(--spacing-unit-x5);

    &-rotated {
      border-radius: 0 var(--spacing-unit-x5) var(--spacing-unit-x5) 0;
    }

    pre {
      max-height: 100%;
      overflow: auto;
      font-size: var(--large-font-size);
      padding: 1.333rem;
    }
  }

  &__dynamic-text-block {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;

    &__wrapper {
      margin-top: 0;
    }

    &__title {
      display: flex;
      width: 100%;
      align-items: center;
      background: var(--hover-row-background-teal);
      padding-left: var(--spacing-unit-x3);
      padding-right: var(--spacing-unit-x3);
      gap: var(--spacing-unit);
      font-family: Open Sans, Helvetica, Arial, sans-serif;
      height: var(--spacing-unit-x8);
      border-radius: var(--standard-border-top-inner-radius);
    }

    &__content {
      padding: var(--spacing-unit-x4);
      border-radius: var(--standard-border-bottom-inner-radius);
    }
  }

  &__static-text-block + &__dynamic-text-block {
    &__wrapper {
      margin-top: var(--spacing-unit-x4);
    }
  }

  &__dynamic-text-block + &__dynamic-text-block {
    &__wrapper {
      margin-top: var(--spacing-unit-x3);
    }
  }
}
