.spinner-modal {
  width: 40rem;

  .modal-body {
    padding: var(--spacing-unit-x8) var(--spacing-unit-x4);
  }

  .spinner__wrapper {
    padding: 0;
  }
}
