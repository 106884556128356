.send-test-email-personalization-modal {
  width: 60rem;

  &__body {
    padding: var(--spacing-unit-x4) 0 !important;
  }

  &__caution {
    margin: 0 var(--spacing-unit-x4);
  }

  .tableV2 {
    min-width: 100%;
    max-width: 100%;

    &-tbody__row:hover {
      background-color: var(--white);
    }
  }

  &__field {
    display: flex;
    gap: var(--spacing-unit-x2);
    align-items: center;
  }
}
