.table-with-loader {
  position: relative;

  &__loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  &__table-loading-state {
    visibility: hidden;
  }
}
