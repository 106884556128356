.sales-permission-modal {
  width: 100%;
  max-width: 74rem;

  &__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--spacing-unit-x4);

    &:first-child {
      padding-top: var(--spacing-unit-x2);
    }

    & + & {
      border-top: var(--standard-border);
      margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x2) 0;
    }

    &-description {
      max-width: 56rem;

      &-title {
        margin-bottom: var(--spacing-unit);
      }
    }
  }
}
