
.tag-manager-base-item {
  align-items: center;
  border-radius: var(--standard-border-radius);
  display: flex;
  height: var(--spacing-unit-x5);
  padding: var(--spacing-unit) var(--spacing-unit-x2);

  &:hover {
    background: var(--lighter-teal);
    cursor: pointer;
  }

  &__color {
    border: var(--tag-color-border);
    border-radius: var(--legend-border-radius);
    height: var(--spacing-unit-x2);
    margin-right: 0.75rem;
    min-width: var(--half-spacing-unit);
  }

  &__name {
    margin-right: auto;
    max-width: 19rem;
  }

  &__tooltip {
    align-items: center;
    display: flex;
    flex-grow: 1;
    max-width: 100%;
  }
}
