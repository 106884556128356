.template-catalog-modal-content {
  &__container {
    grid-area: container;
    padding-top: 0;

    .tableV2-tbody__td {
      padding-top: var(--spacing-unit);
      padding-bottom: var(--spacing-unit);
    }
  }

  &__empty-listing {
    width: 100%;
    overflow: auto;

    button {
      width: calc(var(--spacing-unit-x25) * 2);
    }
  }

  &__search-results {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  }

  &__filters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    grid-area: filter;
    position: sticky;
    top: 0;
    z-index: var(--z-index-9999);
    background-color: var(--white);

    .switch-buttons {
      width: 100%;
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
      justify-content: flex-end;
    }
  }

  &__sub-types {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
    width: 90%;

    &-wrapper {
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &-header {
      margin-right: var(--spacing-unit);
      font-weight: var(--font-weight-bold);
    }

    &-chips {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      flex-wrap: wrap;
    }

    &-chip {
      .typography {
        font-weight: var(--font-weight-medium);
      }
    }

    &-divider {
      width: 100%;
      border-bottom: var(--standard-border);
    }

    &-clear {
      justify-content: flex-end;
      padding: calc(var(--spacing-unit-x4) / 3) 0;
    }
  }

  &__grid {
    grid-gap: var(--spacing-unit-x4);
    display: grid;
    grid-template-columns: repeat(4, 4fr);
    padding: 0 var(--spacing-unit-x4);

    &__spinner {
      height: auto;
    }
  }

  &__cell-image {
    max-height: inherit;
    max-width: inherit;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;

    &-wrapper {
      min-width: var(--spacing-unit-x8);
      max-width: var(--spacing-unit-x8);
      min-height: var(--spacing-unit-x8);
      max-height: var(--spacing-unit-x8);
      border-radius: var(--standard-border-radius);
      border: var(--standard-border);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      &-error {
        background-color: var(--page-back);
      }
    }
  }
}
