.import-success {
  display: flex;
  gap: var(--spacing-unit-x2);
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: var(--spacing-unit-x8) 16rem var(--spacing-unit-x10);

  img {
    height: 15rem;
  }

  button {
    gap: calc(10rem / 12);

    &:first-of-type {
      margin: var(--spacing-unit-x2) 0;
    }
  }
}
