.condition-card__switch--selected {
  font-weight: bold;
}

.radio-top:checked + label {
  font-weight: bold;
}

.condition-card {
  background: var(--page-back) !important;
  padding: var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x3) var(--spacing-unit-x4) !important;
  margin-bottom: var(--spacing-unit-x4);

  &__header {
    display: flex;
    align-items: center;
    padding: 0 0 var(--spacing-unit-x2) 0 !important;

    .switch {
      margin: 0 var(--spacing-unit-x2);
    }

    .typography {
      margin-bottom: 0;
    }
  }

  &__conditions {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit-x2);

    .typography {
      width: 2rem;
      text-align: right;
    }
  }

  &__conditions-fields {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
    width: 100%;

    div {
      flex: 1;
    }
  }

  .add-button__delete {
    margin-top: 0 !important;
  }

  .button {
    margin-top: var(--spacing-unit-x2);
  }
}
