.marketing-domain {
  padding: var(--spacing-unit-x4);

  &__header {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__caution {
    .caution__text {
      display: block;
    }
  }

  &__url-wrapper {
    display: flex;
    align-items: baseline;
    column-gap: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit-x5);
  }
}
