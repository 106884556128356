.emoji-button {
  &__drop-down {
    z-index: var(--z-index-99999);
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-unit-x2);
    height: var(--spacing-unit-x6);
    width: var(--spacing-unit-x6);
    padding: 0 var(--spacing-unit-x2);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    outline: none;
    cursor: pointer;
    transition: var(--linear-80);
    background-color: var(--white);
  
    &:hover,
    &--hover {
      background-color: var(--off-white-focus);
    }

    &:focus-visible {
      border: var(--standard-border-dark-blue);
      outline: var(--input-outline-border);
    }

    & .svg {
      width: 1.334rem;
      height: 1.334rem;
    }

    &--disabled {
      cursor: not-allowed;
      background-color: var(--off-white-hover-disabled) !important;
    }
  }
}
