
.program-suppressions {
  display: block;
  margin-bottom: var(--spacing-unit-x4);

  &__table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x2);

    &__search-container {
      display: flex;
      flex: 0 0 33rem;

      &__button {
        flex: 0 0 3rem;
      }

      &__search-bar {
        flex: 1 0 auto;
      }
    }
  }

  &__empty-table {
    border: var(--standard-border);
  }

  .tr .table__row-actions {
    margin-right: var(--spacing-unit-x4);
  }
}
