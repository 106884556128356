.listing-page-share-modal {
  width: 50rem;
  height: 66rem;

  .modal-body {
    padding: var(--spacing-unit-x4) 0;
  }

  .modal-header,
  .modal-footer {
    &__inner {
      padding-left: var(--spacing-unit-x4);
      padding-right: var(--spacing-unit-x4);
    }
  }

  &__footer__extra {
    margin-right: auto;
  }

  &__title {
    margin-bottom: var(--spacing-unit-x2);
    padding: 0 var(--spacing-unit-x4);
  }

  &__search {
    padding: 0 var(--spacing-unit-x4);
    margin-top: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x2);
  }

  .tableV2 {
    border-bottom: var(--standard-border-dark);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__accounts-list {
    margin-top: var(--spacing-unit-x2);
  }

  &__type-name {
    margin: 0 var(--spacing-unit-x4);
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    background-color: var(--selectable-row-hover);
    border-radius: var(--standard-border-radius);
  }

  &__preview-text {
    margin-left: var(--spacing-unit-x2);
  }
}
