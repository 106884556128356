.custom-touch-point-behaviors-mapping-field {
  align-items: center;
  display: flex;
  gap: var(--spacing-unit-x2);
  padding: var(--spacing-unit-x3) 0 var(--spacing-unit) 0;
  width: 100%;
  
  &__field {  
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: calc(80rem / 12);
    
    &-arrow-container {
      align-items: center;
      display: flex;
      justify-content: center;
      height: var(--spacing-unit-x6);
      width: var(--spacing-unit-x6);
    }
    
    &-name {
      width: calc(44rem / 12 );
    }
  }

  &__info-tooltip {
    align-items: center;
    display: flex;
    justify-content: center;
    height: var(--spacing-unit-x4);
    width: var(--spacing-unit-x4);
  }

  &__input__container {
    width: 100%;
  }
}
