
.edit-task-step {
  width: 50rem !important;
  min-height: 10rem;

  &__not-current {
    margin-top: var(--spacing-unit-x2);
  }

  &__text-area {
    height: 5rem;
    width: 100%;
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;

    .edit-task-step__col {
      flex: 1 1 50%;

      &:first-child {
        margin-right: var(--spacing-unit-x4);
      }
    }
  }

  &__due-row {
    display: flex;
    padding-left: var(--spacing-unit-x4);
    flex-wrap: nowrap;

    .input {
      flex: 0 0 4.166rem;
      margin-right: var(--spacing-unit);
    }

    .select {
      flex: 0 0 8rem;
    }
  }
}
