.opt-out-link-selector {
  &__open {
    background-color: var(--text-teal) !important;
    color: var(--white);

    .svg {
      fill: var(--white) !important;
    }
  }


  &__button .svg {
    margin-bottom: 0;
  }
}
