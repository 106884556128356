.program-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: var(--spacing-unit-x28);
  margin-right: var(--spacing-unit-x4);

  &__editing {
    margin-right: 0;
    padding-right: var(--spacing-unit-x4);
    height: var(--spacing-unit-x6);
    border-right: var(--standard-border);
  }

  &__label-image {
    margin-right: var(--spacing-unit-x2);
  }

  &__circle {
    width: var(--spacing-unit-x2);
    height: var(--spacing-unit-x2);
    border-radius: 50%;
    display: inline-block;

    &-running {
      background-color: var(--pulse-green);
      box-shadow: 0 0 0 rgba(0, 186, 79, 0.6);
      margin-left: 0.3334rem;
    }

    &-stopped {
      background-color: var(--border-failed);
    }

    &-draft {
      background-color: var(--gray-icon);
    }
  }
}
