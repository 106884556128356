.segment-composer-row {
  display: flex;
  width: 100%;
  background-color: var(--white);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  gap: var(--spacing-unit-x2);
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x2) var(--spacing-unit);
  align-items: baseline;

  .inputV2__container,
  .selectv2__indicators-container,
  .selectv2__indicator-container {
    height: 2.8rem;
  }

  &__row {
    &-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex-grow: 1;
    }
    
    &-definition {
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-unit-x2);
      flex-direction: column;
      flex-grow: 1;

      &-with-padding {
        padding-bottom: var(--spacing-unit-x2);
      }

      .selectv2__container {
        flex-shrink: 0;
      }

      > div {
        flex-wrap: wrap;
      }
    }
  }

  &__criteria-wrapper {
    flex-shrink: 0;
    display: flex;
    gap: var(--spacing-unit-x2);
    width: 100%;
  }

  &__criteria-select {
    &-container {
      display: flex;
      flex-direction: column;
    }

    .selectv2__value-container {
      width: 0;
      padding-left: 0 !important;
    }

    .selectv2__control {
      flex-wrap: nowrap;
    }

    &-depth-indicator {
      background-color: var(--button-gray);
      border: var(--condition-group-connector-border);
      border-top-left-radius: var(--standard-border-radius);
      border-top-right-radius: var(--standard-border-radius);
      margin-top: var(--spacing-unit-x2);
      margin-left: var(--spacing-unit-x3);
      width: 0;
      flex-grow: 1;
    }
  }

  &__action-button {
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);

    &.button--float .svg {
      width: 1.333rem;
      height: 1.333rem;
    }
  }

  &__left-actions {
    display: flex;
    gap: var(--spacing-unit);
    align-items: center;

    &-drag {
      visibility: hidden;
      width: var(--spacing-unit-x2);
      height: var(--spacing-unit-x2);
      cursor: grab;
    }
  }

  &__draggable:hover &__left-actions-drag {
    visibility: visible;
  }
  
  &__dragged {
    background-color: var(--hover-row-background-teal);
    border-color: var(--border-teal);
  }

  &__dropped {
    @keyframes row-highlight {
      from {
        background-color: var(--hover-row-background-teal);
        border-color: var(--border-teal);
      }

      to {
        background-color: var(--white);
        border-color: var(--border-gray);
      }
    }

    animation: ease-in 1s row-highlight;
  }
}
