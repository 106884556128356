.contact-segments-modals {
  &__delete-confirmation {
    width: 40rem;
  }

  &__action-button {
    display: flex;
    padding: var(--spacing-unit) var(--spacing-unit-x3);
  }
}
