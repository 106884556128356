
.drag-layer {
  position: fixed;
  pointer-events: none;
  z-index: var(--z-index-99999);
  left: 0;
  top: calc(-1 * var(--spacing-unit-x4));
  right: 0;
  bottom: 0;
}
