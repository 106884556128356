.send-email-message-upgrade-step {
  &__arrow {
    align-self: center;
    fill: var(--gray-icon);
    transform: rotate(90deg);
  }
  
  &__condition {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
    padding: var(--spacing-unit-x2);
  }
  
  &__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: var(--half-spacing-unit);
    
  }

  &__current-field {
    background-color: var(--selectable-row-hover);
    border: var(--standard-border-darker);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  }

  &__value-container {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit-x16) / 12);
  }

  &__current-field-container {
    background-color: var(--input-background);
    border-radius: var(--standard-border-radius);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    padding: calc(var(--spacing-unit-x18) / 12) var(--spacing-unit-x2);
  }

  &__source {
    background-color: var(--chip-base);
    border-radius: var(--spacing-unit-x2);
    padding: var(--half-spacing-unit)calc(var(--spacing-unit-x18) / 12);
  }
}