.upload-image-modal-body {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  > :not(:first-child) {
    margin: 0;
  }

  &__uploader {
    max-height: var(--upload-image-drop-zone-height);

    .upload-image-image {
      padding: var(--spacing-unit-x2);
    }
  }
}
