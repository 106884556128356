.template-catalog-modal {
  width: 100%;
  height: 100%;

  .modal-header__inner {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    flex: 0;
  }

  .modal-footer__inner {
    justify-content: space-between;
    padding: var(--spacing-unit-x4);
  }

  .modal-body {
    padding: 0;
  }

  &__header {
    &__search {
      width: 25rem;

      &-hide {
        display: none;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: minmax(max-content, 0);
    grid-template-areas: 'sidebar filter' 'sidebar container';

    .asset-picker-sidebar {
      grid-area: sidebar;
      display: grid;
      max-width: 25rem;
      border-right: var(--standard-border);
      overflow: hidden;
    }

    &:has(.template-catalog-modal-preview) {
      display: flex;
    }
  }

  &__loader {
    width: 100%;
    height: 100%;
  }

  &__footer {
    &__right {
      display: flex;
      gap: var(--spacing-unit-x4);
    }

    &__left {
      display: flex;
      gap: var(--spacing-unit-x4);
      align-items: center;
    }

    &__preview-button {
      gap: calc(var(--spacing-unit-x2) / 3);
    }

    .button-with-loader {
      width: var(--template-catalog-modal-submit-button-width);
    }
  }

  &__sidebar {
    &__menu {
      &-filter {
        &-container {
          margin-top: var(--spacing-unit-x3);

          .filter__count {
            font-weight: var(--font-weight-medium);
            font-size: var(--default-font-size);
          }
        }

        &-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: var(--spacing-unit) var(--spacing-unit-x4);

          &:last-of-type {
            margin-bottom: var(--spacing-unit);
          }

          &-name {
            align-items: center;

            label {
              width: calc(var(--spacing-unit-x11) * 3);
            }
          }
        }
      }
    }
  }

  &-content {
    width: 100%;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

    &__filters {
      display: flex;
      justify-content: flex-end;
    }
  }
}
