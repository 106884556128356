
.menu {
  background-color: var(--white);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  height: 100%;
  overflow: hidden;
  position: relative;

  &__items {
    .scroll-area__viewport > div {
      table-layout: fixed;
      width: 100%;
    }

    &__scrollbar {
      margin-right: calc(var(--spacing-unit-x2) / 3);
      z-index: 2;
    }
  }

  &__item {
    border-bottom: var(--standard-border);
    margin-right: 2.167rem;

    &:last-child {
      border-bottom: none;
    }
  }

  & .menu-item-with-header__scrolling {
    margin-bottom: calc(0rem - calc(var(--half-spacing-unit) / 3));
  }
}
