.sidebar-container {
  &__custom-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: calc((var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2)) * 2);

    .radio__container {
      margin: 0;
    }
  }

  .checkbox {
    &__container {
      gap: var(--spacing-unit-x2);

      &.ellip .checkbox__label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: normal;
      }
    }

    &__label {
      margin-left: 0;
    }
  }

  .checkboxgroup__container--vertical {
    margin: 0;
    display: block;
  }
}
