.notifications {
  &__description {
    margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x4);
  }

  &__toast-container {
    padding: var(--spacing-unit-x2) 0;
  }

  &__section {
    &-container {
      justify-content: space-between;
    }

    &-item-title {
      margin-bottom: var(--half-spacing-unit);
    }
  }
}
