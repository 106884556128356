.row-group {
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  
  &__dragged {
    border-color: var(--border-teal);
  }
  
  &__dragged &__header {
    background-color: var(--hover-row-background-teal);
  }
  
  &__header:not(&__dropped):hover {
    background: var(--chip-base);
  }

  &__dropped {
    @keyframes group-highlight {
      from {
        border-color: var(--border-teal);
      }

      to {
        border-color: var(--border-gray);
      }
    }

    animation: ease-in 1s group-highlight;
  }

  &__dropped &__header {
    @keyframes group-header-highlight {
      from {
        background-color: var(--hover-row-background-teal);
      }

      to {
        background-color: var(--selectable-row-hover);
      }
    }

    animation: ease-in 1s group-header-highlight;
  }
  
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-unit) var(--spacing-unit-x4) var(--spacing-unit) var(--spacing-unit);
    background-color: var(--selectable-row-hover);
    height: var(--spacing-unit-x10);
    border-bottom: var(--standard-border);

    &-clickable {
      flex: 1 1 auto;
      height: 100%;
      cursor: pointer;

      > div {
        height: 100%;
        width: 100%;
      }
    }

    &-left {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      
      &-drag {
        visibility: hidden;
        width: var(--spacing-unit-x2);
        height: var(--spacing-unit-x2);
        cursor: grab;
      }
    }

    &-draggable:hover &-left-drag {
      visibility: visible;
    }
  }
  
  &__body {
    padding: var(--spacing-unit-x4);
    display: flex;
    flex-direction: column;
    background-color: var(--white);

    > div:not(:first-child) {
      padding-top: var(--spacing-unit);
    }

    > div:not(:last-child) {
      padding-bottom: var(--spacing-unit);
    }

    & .row-group__body {
      background-color: var(--page-back);
    }
  }

  &__switch {
    &-container {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }
  }

  &__inline-editing {
    .typography {
      font-weight: var(--font-weight-medium);
    }

    &__input {
      font-size: var(--larger-font-size);
      font-weight: var(--font-weight-medium);
      
      &--button {
        min-width: unset;
      }
    }
  }
}
