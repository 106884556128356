.global-composer-header-save-indicator {
  display: flex;
  align-items: center;
  gap: var(--spacing-unit);
  max-height: var(--spacing-unit-x6);
  padding-top: var(--half-spacing-unit);
  width: 21.5rem;
  justify-content: flex-end;

  &__status {
    &-active {
      color: var(--text-gray);
    }

    &:hover {
      cursor: default;
    }
  }

  &__svg {
    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  &__fade {
    &-text {
      &-enter-active,
      &-exit-active {
        transition: opacity 200ms;
      }

      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
        }
      }
    }
  }

  &__icon {
    width: 1.667rem;
    height: 1.25rem;

    &-hide {
      opacity: 0;
    }

    &-show {
      opacity: 1;
    }

    &-trigger {
      position: absolute;
      top: 0.75rem;
      left: 0.67rem;
      transition: opacity 100ms ease-in-out;
    }
  }

  &__progress-bar {
    opacity: 1;

    &-inactive {
      opacity: 0;
    }
  }

  .popover {
    height: var(--spacing-unit-x6);
    padding-top: calc(var(--spacing-unit) / 6);

    &__trigger {
      display: flex;
    }
  }

  &__popover {
    border: var(--standard-border);
    background-color: var(--white);
    width: 36rem;
    border-radius: var(--standard-border-radius);
    box-shadow: var(--box-shadow);

    &-trigger {
      position: relative;
      width: var(--spacing-unit-x6);
      height: var(--spacing-unit-x6);

      &:hover,
      &:focus-within {
        background-color: var(--selectable-row-hover);
        border-radius: var(--spacing-unit-x20);
        cursor: pointer;
        outline: none;

        & .svg {
          fill: var(--text-gray) !important;
        }
      }

      &-active {
        background-color: var(--selectable-row-hover);
        border-radius: var(--spacing-unit-x20);
        cursor: pointer;
        outline: none;

        & .svg {
          fill: var(--text-gray) !important;
        }
      }
    }

    &-header {
      background-color: var(--selectable-row-hover);
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      padding: var(--spacing-unit) 0.75rem;
      height: var(--spacing-unit-x7);
      border-radius: var(--standard-border-radius) var(--standard-border-radius) 0 0;

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--spacing-unit-x6);
        height: var(--spacing-unit-x6);
      }
    }

    &-content {
      padding: var(--spacing-unit-x2) var(--spacing-unit-x3) 0 var(--spacing-unit-x3);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
    }

    &-footer {
      padding: var(--spacing-unit-x3);
      display: flex;
      justify-content: flex-end;
    }
  }

  &__button-with-loader {
    width: var(--spacing-unit-x20);

    &-loading.button {
      cursor: default;
    }
  }
}
