.chip {
  width: max-content;
  display: flex;
  align-items: center;
  gap: var(--half-spacing-unit);
  background-color: var(--chip-base);
  padding: var(--half-spacing-unit) var(--half-spacing-unit) var(--half-spacing-unit) calc(var(--half-spacing-unit) + var(--spacing-unit));
  border-radius: 1rem;

  &:hover {
    background-color: var(--hover-indicator-background);
  }

  &--fixed {
    background-color: var(--text-gray);
    padding-right: calc(var(--half-spacing-unit) + var(--spacing-unit));

    .typography {
      color: var(--white);
    }
  }

  &--simple {
    padding-right: calc(var(--half-spacing-unit) + var(--spacing-unit));
  }

  &--selected {
    border: var(--standard-border);
    border-color: var(--text-gray);
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
    padding-right: calc(var(--half-spacing-unit) + var(--spacing-unit));
  }

  &__remove-button {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;

    &:hover {
      background-color: var(--text-gray);

      .svg {
        fill: var(--white);
      }
    }

    &:focus {
      background-color: var(--text-gray);
      border: var(--standard-border);
      border-color: var(--white);
      outline: var(--input-border-focus);
      box-shadow: none;

      .svg {
        fill: var(--white);
      }
    }

    .svg {
      width: 0.666rem;
      height: 0.666rem;
      fill: var(--text-gray);
    }
  }
}
