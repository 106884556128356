.tableV2 {
  position: relative;
  border: var(--standard-border);
  border-radius: calc(var(--standard-border-radius) + var(--standard-border-width));
  background-color: var(--white);
  min-width: calc(var(--positioning-min-width) - 4.166rem);
  overflow: hidden;

  &__virtual-scroller {
    height: 100%;

    &-wrapper {
      height: 100%;
      overflow: auto;
    }
  }

  &__sticky {
    overflow: unset;
  }

  &__scroll {
    &__scrollbar {
      margin-right: var(--half-spacing-unit);
      padding: var(--spacing-unit) 0;

      &-horizontal {
        margin-bottom: var(--half-spacing-unit);
        padding: 0 var(--spacing-unit);
      }
    }
  }

  &:has(&__no-border) {
    border: 0;

    .tableV2-head__sticky-header::after {
      outline: 0;
    }
  }

  &:has(&__bottom-border) {
    border-bottom: var(--standard-border);
  }

  &:has(&__top-border) {
    border-top: var(--standard-border);
  }

  &-head {
    &__th {
      user-select: none;
    }
  }

  &-head,
  &-tfoot {
    &__th {
      padding: 1.397rem var(--spacing-unit-x4);
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .cell-with-hover-card {
    display: flex;
    align-items: center;
    gap: 0.9rem;

    button {
      color: inherit;
    }
  }

  &-tbody__td {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    box-shadow: var(--box-shadow-table-row-border);
  }

  &-head,
  &-tbody,
  &-tfoot {
    &__sticky-column {
      position: sticky;
      background-color: var(--white);
      left: 0;
    }

    &__sticky-column:has(+ :not(&__sticky-column))::after {
      content: '';
      position: absolute;
      inset: 0 0 0 auto;
      width: 0.083rem;
      border-right: var(--standard-border);
    }

    &__checkbox {
      width: var(--spacing-unit-x10);
      max-width: var(--spacing-unit-x10);
      min-width: var(--spacing-unit-x10);
      padding: 0;
      text-align: center;

      & + th,
      & + td {
        padding-left: 0;
      }
    }

    &__radio {
      width: var(--spacing-unit-x10);
      max-width: var(--spacing-unit-x10);
      padding: 0;

      & + th,
      & + td {
        padding-left: 0;
      }

      .radio__container {
        width: var(--radio-size);
        height: var(--radio-size);
        margin: 0 auto;
      }
    }
  }

  &__loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--spacing-unit-x25);
  }

  &__show-more {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-unit);
  }

  &__loading {
    width: 100%;
    background: linear-gradient(0deg, var(--white), transparent);
    height: var(--spacing-unit-x16);
    margin-top: calc(var(--spacing-unit-x16) * -1);
    position: sticky;
  }

  &__ellip {
    display: block;

    &__wrapper,
    .tooltip__trigger {
      max-width: 100%;
    }
  }
}
