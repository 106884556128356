
.edit-create-in-crm-step {
  width: 50rem !important;
  min-height: 10rem;

  &__not-current {
    margin-top: var(--spacing-unit-x2);
  }

  &__step-name {
    width: 27rem;
  }
}
