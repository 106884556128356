.program-messages {
  min-width: 70.4rem;
  max-width: 87.1rem;
  margin-bottom: var(--spacing-unit-x4);

  &__message-row {
    border-bottom: var(--standard-border);
    width: 100%;
    font-size: var(--large-font-size);
    font-weight: var(--font-weight-medium);
    color: var(--text-gray);
    padding: var(--spacing-unit) var(--spacing-unit-x2) 0.6rem var(--spacing-unit-x2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--spacing-unit-x7);

    &:first-of-type {
      border-top: var(--standard-border);
    }

    &-pill {
      margin-left: calc(var(--spacing-unit-x3) / 2);
    }

    &-subject {
      display: flex;
      flex: 1 1 100%;
      cursor: pointer;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 1.5rem;

      & > svg {
        fill: var(--gray);
        height: 1.33rem;
        width: 1.33rem;
        margin-right: var(--spacing-unit-x2);
      }
    }

    &:hover {
      background-color: var(--input-background);

      .program-messages__row-button {
        display: flex;
      }
    }
  }

  &__row-button {
    flex: 0 0 auto;
    align-items: center;
    height: 1.5rem;
    display: none;
  }

  &__list-button {
    margin: var(--spacing-unit-x4) var(--spacing-unit-x2) var(--spacing-unit-x2) 0;
  }
}
