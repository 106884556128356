
.error-message {
  width: 33rem;

  &__wrapper {
    & > button {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-extension > button {
      max-width: 15rem;
    }

    &-size > button {
      max-width: 27rem;
    }

    &-empty > button {
      max-width: 22rem;
    }
  }

  &__filename {
    word-break: break-word;
  }
}
