.message-composer {
  .label {
    justify-content: space-between;
  }

  &__svg {
    display: flex;
    justify-content: space-between;

    .button--float {
      padding: 0.375rem 0.5rem;

      .svg {
        margin: 0;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &__counter {
    order: -1;
    display: inline-block;
    margin-right: calc(1rem / 12);

    &--bold {
      font-weight: var(--font-weight-bold);
      margin-left: var(--spacing-unit);
    }

    &--bullet {
      margin: 0 var(--spacing-unit);
    }
  }

  &__errors {
    display: flex;
    flex-direction: column;
    line-height: var(--line-height-small);
    color: var(--error-text);

    & > :first-child {
      margin-bottom: 0.5rem;
    }
  }
}
