.step-two {
  &__table-empty {
    &-text {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: var(--spacing-unit-x2);
    }

    .tableV2-tbody__row:hover {
      background-color: unset;
    }
  }
  
  & .tableV2 {
    max-width: 58.84rem;
    min-width: unset;
  }
}
