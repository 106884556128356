.personalization {
  width: 100rem;
  height: 66rem;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: var(--spacing-unit-x4);
    padding-right: var(--spacing-unit-x4);

    &-items {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }

    &-extra {
      width: 23rem;
    }
  }

  .modal &__body {
    padding: 0;
    display: flex;
  }

  &__sidebar {
    width: 25rem;
    border-right: var(--standard-border);
    padding-top: var(--spacing-unit-x3);
    padding-bottom: var(--spacing-unit-x2);

    .asset-picker-sidebar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - var(--spacing-unit-x20));
    }

    &--filter {
      &__item {
        &.button:disabled {
          &.filter {
            background-color: unset;
          }

          svg {
            fill: var(--disabled-plus-gray);
          }

          .typography {
            color: var(--disabled-plus-gray);
          }

          .tooltip__trigger--inline {
            margin-left: var(--spacing-unit-x2);

            &.filter__name-lock {
              margin-left: 0;
            }
          }
        }

        .tooltip__trigger--inline {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: var(--spacing-unit-x3);
          min-height: var(--spacing-unit-x3);
          margin-left: var(--spacing-unit);

          .svg {
            margin-left: 0;
          }
        }
      }
    }

    &-learn-more {
      background-color: var(--input-background);
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
      margin: 0 var(--spacing-unit-x4);
    }
  }

  &__table {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;

    &-cell-warning {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }

    &-with-padding {
      padding-top: var(--spacing-unit-x4);
    }

    & .tableV2 {
      & .tableV2-head__sticky-header::after {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      & .tooltip__trigger {
        cursor: default;
      }
    }

    & .folder-search {
      .tableV2 {
        overflow: unset;
      }

      &__container {
        padding: 0;
        margin-top: var(--spacing-unit-x4);
        border: none;
      }

      &__title {
        margin-left: var(--spacing-unit-x3);
      }

      &__select-folder {
        margin: var(--spacing-unit-x4) var(--spacing-unit-x3) var(--spacing-unit-x2) var(--spacing-unit-x3);
      }

      & .breadcrumbs-item,
      & .tooltip__trigger {
        cursor: default;
        max-width: 100%;
      }
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &-inner {
      margin-left: calc(-1 * var(--spacing-unit-x2));
    }

    &-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }
  }

  &__add-modal {
    max-width: calc(var(--spacing-unit-x25) * 4);

    &.modal .modal-body {
      padding: var(--spacing-unit-x4);
    }

    .svg {
      fill: var(--modal-gray-background);
    }

    .svg.radio-card__svg--hover {
      fill: var(--text-teal);
    }

    .radio-card__svg.radio-card__no-width {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
    }

    .radio-card {
      align-items: center;
    }
  }

  &__toaster {
    height: var(--spacing-unit-x8);
  }

  &__multiple-contacts-header {
    width: calc(var(--spacing-unit-x20) * 3);
    text-align: left;
  }
}
