.click-to-copy {
  display: flex;
  align-items: center;

  &:hover {
    .click-to-copy {
      &__button {
        visibility: unset;
      }

      &__svg {
        visibility: unset;
      }
    }
  }

  &__button {
    visibility: hidden;
    align-self: end;
    line-height: calc(5rem / 3);
    font-weight: var(--font-weight-medium);
    margin: 0 var(--half-spacing-unit) 0 var(--spacing-unit);
    padding: 0 var(--spacing-unit);

    &-clicked,
    &:hover:not(:disabled) {
      color: var(--text-teal);
      background-color: var(--lighter-teal);
    }
  }

  &__svg {
    visibility: hidden;
  }

  &__hover-row-bg {
    .click-to-copy__button {
      &-clicked,
      &:hover:not(:disabled) {
        background-color: var(--hover-row-button-background-teal);
      }
    }
  }

  &__tight-spacing {
    .click-to-copy__button {
      padding: 0;

      &-clicked,
      &:hover:not(:disabled) {
        color: var(--darker-teal);
        background: none;
      }
    }

    .click-to-copy__svg {
      fill: var(--darker-teal);
    }
  }
}
