.insert-image-url-modal-body {
  &__label {
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit);
  }

  &__preview {
    &__img {
      max-height: inherit;
      max-width: inherit;
      padding: var(--spacing-unit-x2);

      &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        max-height: var(--upload-image-drop-zone-height);
        height: var(--upload-image-drop-zone-height);
        background-color: var(--input-background);
        border: var(--dashed-border);
        border-radius: var(--large-border-radius);
      }
    }

    &__label {
      margin: var(--spacing-unit-x4) 0 var(--spacing-unit);
    }

    &-hide {
      display: none;
    }
  }

  &__loader {
    height: var(--spacing-unit-x3);
  }
}
