
/* stylelint-disable */
.chart {
  position: relative;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .highcharts-button-symbol {
    cursor: pointer;
    height: 4px !important;
  }

  .highcharts-title {
    font-weight: var(--font-weight-medium);
  }

  .highcharts-container svg {
    width: 101%;
  }

  .highcharts-exporting-group .highcharts-button-box {
    cursor: pointer;
    transform: translate(-13px, -20px);
  }

  .highcharts-focus-border {
    stroke: var(--browser-highlight); /* keyboard-only focus styles */
  }

  .highcharts-button-normal .highcharts-button-box {
    fill: none;
  }

  .highcharts-button-pressed .highcharts-button-box,
  .highcharts-button-hover .highcharts-button-box,
  .highcharts-button-box:hover {
    fill: var(--off-white-focus) !important;
    height: var(--spacing-unit-x6) !important;
    width: var(--spacing-unit-x6) !important;
  }

  .highcharts-container {
    overflow: visible !important;
  }

  .highcharts-contextmenu .highcharts-menu {
    border-radius: 4px;
    border: 1px solid var(--off-white-focus) !important;
    box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.06), 0 8px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .highcharts-contextmenu .highcharts-menu hr {
    border: 1px solid var(--off-white-focus);
  }
}
/* stylelint-enable */
