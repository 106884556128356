.tag-with-name {
  &:hover {
    .typography {
      color: var(--white);
    }
  }

  &__name {
    margin: 0 var(--spacing-unit) 0 0.666rem;
    transition: color 0.05s linear;
    z-index: 1;

    &--selected {
      color: var(--white);
      text-shadow: var(--tag-text-shadow);
    }
  }

  &__must-scroll {
    min-width: 8.333rem;
    flex-shrink: 1;
  }
}
