.review-and-send {
  &__container {
    display: grid;
    grid-template-columns: 1fr 40rem;
    background-color: var(--page-back);
  }

  .background_scrollbottom {
    position: fixed;
    bottom: 0;
    height: 4.5rem;
    background: var(--page-back);
    width: 100%;
  }

  &__header {
    display: grid;
    background-color: var(--dark-blue);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    gap: calc(var(--spacing-unit-x4) / 3);
    grid-template-columns: 1fr repeat(3, auto);
    position: sticky;
    top: var(--position-sticky-header-composer);
    z-index: var(--z-index-999);

    &__customer-care {
      display: grid;
      background-color: var(--dark-blue);
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
      gap: calc(var(--spacing-unit-x4) / 3);
      grid-template-columns: 1fr repeat(3, auto);
      position: sticky;
      top: var(--position-sticky-custom-care-header);
      z-index: var(--z-index-999);
    }

    &-tooltip {
      max-width: 25.5rem;
    }
  }

  &__sidebar {
    border-left: var(--standard-border);
    background: var(--review-send-bg);
    padding-bottom: 4.5rem;

    &__tabs-container {
      position: absolute;
      bottom: calc(var(--half-spacing-unit) + var(--spacing-unit));
      right: var(--spacing-unit-x10);
      transform: translate(-6rem, 0);
    }

    &__iframe-wrapper-mobile {
      display: block;

      .email-composer-preview-mobile {
        margin-top: var(--spacing-unit-x2);
      }
    }

    &__iframe-wrapper {
      padding: 0 var(--spacing-unit-x6);

      &-content-missing {
        height: 100%;
      }

      .loader {
        display: none;
      }

      .not-rendered {
        background-color: transparent;

        .loader {
          margin: 0 auto;
          display: flex;
        }

        .email-composer-preview-iframe {
          visibility: hidden;
        }
      }

      &-plain {
        height: 100%;
        padding: 0;
      }
    }
  }
}

@media (max-width: 72.25rem) {
  .review-and-send {
    &__header {
      &__customer-care {
        top: var(--position-sticky-custom-care-header-small);
      }
    }
  }
}
