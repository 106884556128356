.inputV2 {
  margin: 0;
  width: 100%;
  color: var(--text-gray);
  border: var(--input-border);
  transition: var(--linear-80);
  height: var(--spacing-unit-x6);
  font-size: var(--large-font-size);
  background-color: var(--white);
  padding: 0 var(--spacing-unit-x2);
  border-radius: var(--standard-border-radius);
  min-width: calc(var(--spacing-unit-x20) + var(--spacing-unit-x20));

  &::placeholder {
    color: var(--light-gray);
  }

  &:disabled {
    opacity: 0.5;
    background-color: var(--off-white-hover);

    &:hover {
      cursor: not-allowed;
      border-color: var(--border-gray);
    }

    & ~ .inputV2__with-items {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &:hover {
    border: var(--inputV2-border-hover);
  }

  &:focus {
    border-color: var(--dark-blue);
    outline: var(--inputV2-outline-border);

    & + .inputV2__with-items {
      .inputV2-with-clear-button.button svg {
        fill: var(--gray);
      }
    }
  }

  &__wrapper {
    &-full-width {
      width: 100%;
    }
  }

  &__with-password {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &-item-divider {
    display: block;
    width: 0.0833rem;
    height: 1.6667rem;
    background-color: var(--border-gray);
  }

  &__leading-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: var(--spacing-unit-x2);
  }

  &__leading-icon,
  &__trailing-icon {
    fill: var(--button-gray);
    pointer-events: none;

    &-success {
      fill: var(--success-green);
    }

    &-error {
      fill: var(--remove-red);
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  &__error {
    border-color: var(--remove-red);
  }

  &__error:hover,
  &__error:focus {
    outline: none;
    border-color: var(--remove-red);
  }

  &__container {
    position: relative;
    display: block;
    height: var(--spacing-unit-x6);

    &-input-info {
      margin-bottom: var(--spacing-unit);
    }
  }

  &__without-focus {
    &:focus {
      outline: none;
    }
  }

  &__with-items {
    top: 50%;
    display: flex;
    align-items: center;
    position: absolute;
    gap: var(--spacing-unit-x2);
    transform: translateY(-50%);
    right: var(--spacing-unit-x2);

    &-right-position {
      right: var(--spacing-unit);
      gap: var(--spacing-unit);

      .inputV2-with-max-character__counter {
        padding-right: var(--spacing-unit);
      }
    }

    &-children {
      right: var(--standard-border-width);
      gap: 0;

      .inputV2-with-max-character__counter {
        padding-right: 0.75rem;
      }
    }

    &-has-copy {
      right: 0.0833rem;
      gap: 0;
      height: 2.9167rem;
    }
  }

  &-with-status {
    position: relative;

    &__loader {
      height: 1.33333rem;
      width: 1.33333rem;
    }

    &__tooltip__trigger-wrapper {
      display: flex;
    }
  }

  &-with-clear {
    display: block;
    position: relative;

    .inputV2 {
      padding-right: 4.0833rem;
    }

    &-button {
      padding: unset;
      width: var(--spacing-unit-x4);
      border-radius: var(--spacing-unit-x2);
      height: var(--spacing-unit-x4);
      position: relative;

      .svg.inputV2-with-clear-button-svg.svg--icon {
        fill: var(--tab-gray);
      }

      &:hover {
        .svg.inputV2-with-clear-button-svg.svg--icon {
          fill: var(--text-gray) !important;
        }
      }

      &:focus {
        width: var(--spacing-unit-x4);
        background-color: var(--chip-base) !important;
        border: 0.0833rem solid var(--dark-blue);
        box-shadow: inset 0 0 0 0.1667rem var(--white);

        .svg.inputV2-with-clear-button-svg.svg--icon {
          fill: var(--text-gray);
        }

        &::after {
          content: '';
          position: absolute;
          inset: -0.1667rem;
          border: var(--inputV2-outline-border);
          border-radius: 50%;
          z-index: -1;
        }

        .inputV2-with-clear-button {
          gap: var(--spacing-unit);
          right: var(--spacing-unit);
        }
      }

      &-svg {
        height: calc(10rem / 12) !important;
        width: calc(10rem / 12) !important;
        margin: 0 !important;

        &:focus {
          outline: none;
        }
      }
    }

    &__typing-loader {
      display: flex;
      justify-content: center;
      width: var(--spacing-unit-x4);

      & > svg > circle {
        fill: var(--teal);
      }
    }
  }

  &__copy-button {
    border-radius: 0 var(--half-spacing-unit) var(--half-spacing-unit) 0;
    height: 2.875rem;

    &:focus-visible {
      border-color: var(--dark-blue);
      outline: var(--inputV2-outline-border);
    }

    &-success {
      background-color: var(--lighter-teal);

      & > .svg {
        fill: var(--text-teal) !important;
      }

      &:hover {
        background-color: var(--lighter-teal);
      }
    }

    &-error {
      background-color: var(--remove-red);

      & > svg {
        fill: var(--white) !important;
      }

      &:hover {
        background-color: var(--remove-red);
      }
    }

    &-read-only {
      border-radius: var(--standard-border-radius);
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  &-with-max-character {
    display: block;

    &__counter {
      &-error {
        .typography {
          color: var(--error-text);
        }
      }

      &-hidden {
        padding-right: unset;
      }
    }
  }

  &__read-only {
    padding-right: 0;

    &-appearance {
      &-standard {
        &-error {
          padding-left: 0;
        }

        &:focus,
        &:hover {
          border: var(--input-border);
          outline: none;
        }
      }

      &-none {
        border-color: transparent !important;
        padding-left: 0;

        &:disabled {
          background: none;
          border-color: transparent;
        }

        &-error {
          padding-left: 0;
        }

        &-with-copy {
          border-color: transparent;

          &:focus,
          &:hover {
            border-color: transparent;
            outline: none;
          }
        }
      }

      &-has-focus {
        &:focus {
          outline: var(--inputV2-outline-border);
        }
      }
    }
  }

  &__has {
    &-leading-icon {
      padding-left: 3.2333rem;
    }

    &-trailing-icon {
      padding-right: 3.2333rem;
    }

    &-max-character {
      padding-right: 5.0333rem;
    }

    &-combination {
      padding-right: 8.0667rem;
    }

    &-copy {
      padding-right: 3.9667rem;
    }
  }

  &__bottom-info {
    color: var(--light-gray);

    &-tooltip-wrapper {
      display: flex;
      align-items: center;
      gap: 0.5833rem;
    }
  }
}
