.folder-search {
  display: block;

  &__container {
    margin-bottom: unset;
  }

  &__select-folder {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-unit);
    gap: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x6);

    & > .button {
      width: var(--spacing-unit-x19);
      height: 2.33rem;
    }
  }

  &__title {
    align-items: center;
    margin-bottom: 1.33rem;
  }

  &__list {
    max-width: unset;

    &-empty-listing {
      height: 100%;
    }
  }
}
