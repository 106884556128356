.segment-tree {
  & .breadcrumbs-hover-card__breadcrumb {
    width: 100%;
  }
  
  & .breadcrumbs-hover-card__breadcrumbs {
    display: grid;
    max-width: 66.66667rem;
    width: max-content;
  }
  
  &-one .breadcrumbs-hover-card__breadcrumbs {
    grid-template-columns: auto;
    max-width: calc(20rem - 1.33334rem);
  }

  &-two .breadcrumbs-hover-card__breadcrumbs {
    grid-template-columns: repeat(2, auto);
    max-width: calc(40rem - 1.33334rem);
  }
  
  &-three .breadcrumbs-hover-card__breadcrumbs {
    grid-template-columns: repeat(3, auto);
    max-width: calc(60rem - 1.33334rem);
  }
  
  &-four .breadcrumbs-hover-card__breadcrumbs {
    grid-template-columns: repeat(4, auto);
  }

  &-five .breadcrumbs-hover-card__breadcrumbs {
    grid-template-columns: repeat(5, auto);
  }
}
