.branding-section__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: var(--spacing-unit-x9);

  &-title {
    margin-bottom: var(--spacing-unit-x2);
  }

  &-edit-wrapper {
    min-width: var(--spacing-unit-x13);
    display: flex;
    justify-content: flex-end;
  }

  &-edit-button {
    height: var(--spacing-unit-x5);
  }

  &-status {
    display: flex;
    align-items: center;
    padding-right: var(--spacing-unit-x2);
    gap: var(--spacing-unit);

    .svg {
      fill: var(--light-gray);
    }
  }
}
