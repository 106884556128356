.international-sending {
  &__link {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__toggle {
    display: flex;
  }

  &__confirmation {
    width: 40rem;
  }
}
