.success-modal {
  width: 40rem;
  
  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    align-items: center;

    img {
      height: 15rem;
      width: 21rem;
    }
  }
  
  &__buttons-container {
    padding: var(--spacing-unit-x2) 0;

    &-button {
      width: 25rem;
    }
    
    &-button + &-button {
      margin-top: var(--spacing-unit-x2);
    }
  }
}
