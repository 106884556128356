.import-options-screen {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  &__subtitle {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__segment-rules {
    display: flex;
    gap: var(--spacing-unit-x3);

    &-label {
      width: 18rem;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x3);
      margin-top: var(--half-spacing-unit);
    }
  }

  &__divider {
    height: var(--standard-border-width);
    background-color: var(--border-gray);
    margin: var(--spacing-unit-x3) 0;
  }

  &__policy {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
    background-color: var(--chip-base);
    border-radius: var(--standard-border-radius);
    
    &-link {
      display: inline;
    }
  }

  &__button-next {
    display: flex;
    justify-content: flex-end;
  }

  &__select {
    width: 33rem;

    > svg {
      color: var(--text-gray);
    }
  }

  .container__wrapper {
    margin-top: 0;
  }
  
}

