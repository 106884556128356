.complexity-validation-display {
  margin-bottom: var(--spacing-unit-x3);

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: calc(2 * var(--spacing-unit-x2) / 3);
    }

    &-icon {
      margin-right: calc(var(--spacing-unit) + var(--half-spacing-unit));
    }
  }
}
