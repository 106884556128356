
.color-picker {
  &__colors {
    align-items: center;
    background-color: var(--white);
    border-radius: var(--standard-border-radius);
    box-shadow: var(--hover-menu-shadow);
    display: flex;
    flex-wrap: wrap;
    gap: 0.833rem;
    height: 6.833rem;
    margin-top: calc(var(--spacing-unit) / 2);
    overflow: hidden;
    padding: 0.875rem;
    position: absolute;
    width: 18.167rem;
    z-index: 1;
  }

  &__color {
    align-items: center;
    border: calc(var(--spacing-unit) / 6) solid rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: flex;
    height: var(--spacing-unit-x4);
    justify-content: center;
    width: var(--spacing-unit-x4);

    &:hover {
      box-shadow: 0 0 var(--standard-border-radius) calc(var(--spacing-unit) / 6) rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }

    &-selected {
      fill: var(--white);

      &-no-color {
        fill: var(--gray-icon);
      }
    }

    &-wrapper {
      align-items: center;
      border: calc(var(--half-spacing-unit) / 2)  solid var(--button-hover);
      border-radius: 50%;
      display: flex;
      height: var(--spacing-unit-x5);
      justify-content: center;
      margin: calc(0rem - var(--half-spacing-unit));
      width: var(--spacing-unit-x5);

      & .color-picker__color:hover {
        box-shadow: none;
      }
    }
  }

  &__no-color {
    border: var(--input-border-hover);
  }

  &__trigger {
    width: 2.33rem;
    height: 2.13rem;

    & .input-with-color-picker__color-picker-icon.svg {
      height: 1.16667rem;
      width: 1.16667rem;
    }

    & .input-with-color-picker__color-picker-caret.svg {
      height: 0.238rem;
      width: 0.417rem;
    }

    &:hover {
      background-color: unset;
    }
  }
}
