.new-add-folder-modal {
  display: block;
  width: 50rem;

  &.modal {
    & .modal-body {
      overflow: unset;
    }

    & .modal-header__inner {
      border-top-left-radius: var(--standard-border-radius);
      border-top-right-radius: var(--standard-border-radius);
      background-color: var(--input-background);
      padding: var(--spacing-unit-x3) var(--spacing-unit-x5);
    }
  }

  &__input,
  &__input-valid {
    padding-left: var(--spacing-unit-x7);
    background-repeat: no-repeat;
    background-size: 1.33rem 1.33rem;
    background-position: var(--spacing-unit-x2) center;

    &-label {
      line-height: var(--line-height-smallish);
      margin-top: var(--spacing-unit-x4);
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__input {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uNTg2IDEuNTg2QTIgMiAwIDAgMSAyIDFoNGMuMTc3IDAgLjM0Ni4wNy40NzEuMTk1TDguMjc2IDNIMTRhMiAyIDAgMCAxIDIgMnY4YTIgMiAwIDAgMS0yIDJIMmEyIDIgMCAwIDEtMi0yVjNhMiAyIDAgMCAxIC41ODYtMS40MTRaTTIgMi4zMzNBLjY2Ny42NjcgMCAwIDAgMS4zMzMgM3YxMGEuNjY3LjY2NyAwIDAgMCAuNjY3LjY2N2gxMmEuNjY3LjY2NyAwIDAgMCAuNjY3LS42NjdWNUEuNjY3LjY2NyAwIDAgMCAxNCA0LjMzM0g4YS42NjcuNjY3IDAgMCAxLS40NzEtLjE5NUw1LjcyNCAyLjMzM0gyWm02IDMuMzM0Yy4zNjggMCAuNjY2LjI5OC42NjYuNjY3djEuOTk5aDIuMDAxYS42NjcuNjY3IDAgMSAxIDAgMS4zMzNoLTJ2Mi4wMDFhLjY2Ny42NjcgMCAxIDEtMS4zMzQgMHYtMmgtMmEuNjY3LjY2NyAwIDAgMSAwLTEuMzM0aDJ2LTJjMC0uMzY4LjI5OC0uNjY2LjY2Ny0uNjY2WiIgZmlsbD0iI0FFQUVBRSIvPjwvc3ZnPg==');

    &:focus {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uNTg2IDEuNTg2QTIgMiAwIDAgMSAyIDFoNGMuMTc3IDAgLjM0Ni4wNy40NzEuMTk1TDguMjc2IDNIMTRhMiAyIDAgMCAxIDIgMnY4YTIgMiAwIDAgMS0yIDJIMmEyIDIgMCAwIDEtMi0yVjNhMiAyIDAgMCAxIC41ODYtMS40MTRaTTIgMi4zMzNBLjY2Ny42NjcgMCAwIDAgMS4zMzMgM3YxMGEuNjY3LjY2NyAwIDAgMCAuNjY3LjY2N2gxMmEuNjY3LjY2NyAwIDAgMCAuNjY3LS42NjdWNUEuNjY3LjY2NyAwIDAgMCAxNCA0LjMzM0g4YS42NjcuNjY3IDAgMCAxLS40NzEtLjE5NUw1LjcyNCAyLjMzM0gyWm02IDMuMzM0Yy4zNjggMCAuNjY2LjI5OC42NjYuNjY3djEuOTk5aDIuMDAxYS42NjcuNjY3IDAgMSAxIDAgMS4zMzNoLTJ2Mi4wMDFhLjY2Ny42NjcgMCAxIDEtMS4zMzQgMHYtMmgtMmEuNjY3LjY2NyAwIDAgMSAwLTEuMzM0aDJ2LTJjMC0uMzY4LjI5OC0uNjY2LjY2Ny0uNjY2WiIgZmlsbD0iIzQ0NCIvPjwvc3ZnPg==');
    }
  }

  &__input-valid {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uNTg2IDEuNTg2QTIgMiAwIDAgMSAyIDFoNGMuMTc3IDAgLjM0Ni4wNy40NzEuMTk1TDguMjc2IDNIMTRhMiAyIDAgMCAxIDIgMnY4YTIgMiAwIDAgMS0yIDJIMmEyIDIgMCAwIDEtMi0yVjNhMiAyIDAgMCAxIC41ODYtMS40MTRaTTIgMi4zMzNBLjY2Ny42NjcgMCAwIDAgMS4zMzMgM3YxMGEuNjY3LjY2NyAwIDAgMCAuNjY3LjY2N2gxMmEuNjY3LjY2NyAwIDAgMCAuNjY3LS42NjdWNUEuNjY3LjY2NyAwIDAgMCAxNCA0LjMzM0g4YS42NjcuNjY3IDAgMCAxLS40NzEtLjE5NUw1LjcyNCAyLjMzM0gyWm02IDMuMzM0Yy4zNjggMCAuNjY2LjI5OC42NjYuNjY3djEuOTk5aDIuMDAxYS42NjcuNjY3IDAgMSAxIDAgMS4zMzNoLTJ2Mi4wMDFhLjY2Ny42NjcgMCAxIDEtMS4zMzQgMHYtMmgtMmEuNjY3LjY2NyAwIDAgMSAwLTEuMzM0aDJ2LTJjMC0uMzY4LjI5OC0uNjY2LjY2Ny0uNjY2WiIgZmlsbD0iIzQ0NCIvPjwvc3ZnPg==');
  }

  &__select {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__nested {
    margin-top: var(--spacing-unit-x4);

    &-checkbox {
      margin-bottom: var(--spacing-unit-x2);
    }

    &-select {
      padding-left: var(--spacing-unit-x7);
      background-repeat: no-repeat, no-repeat;
      background-position: calc(100% - var(--spacing-unit-x2)) center, var(--spacing-unit-x2) center;
      background-size: 0.75rem 0.46rem, 1.5rem 1.33rem;
    }
  }
}
