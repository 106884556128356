.send-details {
  display: flex;
  gap: var(--spacing-unit-x2);
  justify-content: center;
  padding: var(--spacing-unit-x4);

  /* stylelint-disable */
  @media (max-width: 1100px) {
    /* stylelint-enable */
    flex-direction: column;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    max-width: 63rem;

    /* stylelint-disable */
    @media (max-width: 1298px) {
      /* stylelint-enable */
      flex-grow: 1;
      max-width: 100%;
    }

    &-section {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x4);
      min-width: 63rem;

      /* stylelint-disable */
      @media (max-width: 1298px) {
        /* stylelint-enable */
        min-width: 40rem;
        width: unset;
      }

      /* stylelint-disable */
      @media (max-width: 1100px) {
        /* stylelint-enable */
        min-width: 70rem;
      }
    }
  }

  &__right {
    flex-grow: 0;
    flex-shrink: 0;
    width: 40rem;

    /* stylelint-disable */
    @media (max-width: 1298px) {
      /* stylelint-enable */
      width: 32rem;
    }

    /* stylelint-disable */
    @media (max-width: 1100px) {
      /* stylelint-enable */
      min-width: 70rem;
      width: 100%;
    }

    &__section {
      width: 100%;
    }
  }
}
