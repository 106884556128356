.message-picker-modal {
  &__body {
    position: relative;
    min-height: 10rem;

    &--picker {
      max-width: 100rem;
      width: calc(100vw - 6.66rem);
      max-height: var(--message-picker-height);
    }

    &--preview {
      min-width: 74rem;
    }

    &--folders {
      display: flex;
      flex-direction: column;
    }
  }

  &__chooser {
    padding-bottom: 0;
    padding-top: 0;
    width: 45rem;

    .chooser-button {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: var(--spacing-unit-x4);
      flex: 0 0 22.5rem;

      &__icon {
        display: flex;
        align-items: center;
        height: unset;
        width: unset;
      }

      svg {
        max-height: unset !important;
        max-width: unset !important;
      }
    }
  }

  &__body.modal-body {
    padding: 0 var(--spacing-unit-x7) var(--spacing-unit-x4) var(--spacing-unit-x7);

    &.message-picker-modal__body--folders {
      padding: 0;
    }
  }

  &__preview-message-button {
    display: flex;
    align-items: center;

    svg {
      position: relative;
      top: 0.2rem;
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__message-preview {
    position: relative;
    min-width: 74rem;
    display: flex;
    justify-content: center;
    min-height: calc(var(--spacing-unit-x20) * 4);
    padding: var(--spacing-unit-x2);
    background-color: var(--white);

    button {
      position: absolute;
      top: 1rem;
      right: 3rem;
      background: var(--off-white-focus);
      width: 3rem;
      height: 3rem;
    }

    &-iframe {
      border: none;
      outline: none;
    }
  }

  &__custom-scroll-area.message-picker {
    max-height: var(--message-picker-height);
  }

  &__legacy-banner {
    margin-top: var(--spacing-unit-x6);
  }


}
