.fatigue-rule-setting {
  &__inputs {
    display: flex;
  }

  &__select-span {
    margin-right: var(--spacing-unit-x4);
  }

  &__select {
    width: 22rem;
  }

  &__input {
    width: var(--spacing-unit-x12);
  }

  &__input[type='number']::-webkit-inner-spin-button,
  &__input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }

  &__error {
    margin-top: var(--spacing-unit);
  }
}
