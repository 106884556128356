.collapsible-menu {
  background-color: var(--white);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  height: auto;
  overflow: hidden;
  position: relative;
  transition: var(--ease-out-all-180);
  transition-property: background-color, height, width;
  width: 30rem;
  min-width: var(--spacing-unit-x28);
  flex: 0 0 auto;

  /* stylelint-disable */
  @media (max-width: 1599px) {
    /* stylelint-enable */
    width: 25rem;
  }
  /* stylelint-disable */
  @media (max-width: 1439px) {
    /* stylelint-enable */
    width: 20rem;
  }

  .scroll-area__viewport > div {
    display: block !important;
  }

  &__title {
    padding-right: var(--spacing-unit);
  }

  &__division {
    &:first-child {
      padding: var(--spacing-unit) 0;
    }

    & + & {
      &::before {
        content: '';
        display: block;
        margin: 0 var(--spacing-unit-x3);
        border-top: var(--standard-border);
      }
    }
  }

  &__collapsed {
    display: block;
    background-color: unset;
    height: 100%;
    min-width: var(--spacing-unit-x8);
    overflow: unset;
    width: var(--spacing-unit-x8);

    &-icon {
      transform: rotate(180deg);
      width: 100%;
    }

    .collapsible-menu__division:first-child {
      padding: 0;
    }
  }

  &__collapser {
    background-color: var(--input-background);
    border-bottom: var(--standard-border);
    border-radius: var(--collapsible-menu-radius) var(--collapsible-menu-radius) 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--spacing-unit-x3) 0 var(--spacing-unit-x4);
    transition: width var(--expand-timing) ease-out;
    width: 100%;
    height: var(--spacing-unit-x8);

    &-collapsed {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;

      &:hover {
        & .collapsible-menu__collapser-icon-collapsed > svg {
          fill: var(--text-gray);
          transition: fill var(--expand-timing) ease-out;
        }
      }
    }

    &-icon {
      width: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2));
      height: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2));
      transition: background-color var(--expand-timing) ease-out;

      .button,
      .svg {
        width: 100%;
        height: 100%;
      }

      & > svg {
        fill: var(--light-gray);
        transition: transform var(--expand-timing) linear;
      }

      &-collapsed {
        width: var(--spacing-unit-x2);
        height: var(--spacing-unit-x2);
      }
    }
  }

  &__items {
    height: calc(100% - var(--spacing-unit-x8));

    &__scrollbar {
      margin-right: calc(var(--spacing-unit-x2) / 3);
      padding-top: calc(var(--spacing-unit-x2) / 3);
      padding-bottom: calc(var(--spacing-unit-x2) / 3);
      z-index: var(--z-index-999);
    }
  }

  &__item-icon {
    align-items: center;
    background-color: var(--white);
    cursor: pointer;
    display: flex;
    height: var(--spacing-unit-x8);
    justify-content: center;
    transition: background-color var(--expand-timing) ease-out;
    width: 100%;
    z-index: 3;

    & > svg {
      fill: var(--button-hover);
      color: var(--button-hover);
      transition: fill var(--expand-timing) ease-out, color var(--expand-timing) ease-out;
    }

    &:hover {
      background-color: var(--hover-row-background-teal);

      & > svg {
        fill: var(--light-gray);
        color: var(--light-gray);
      }
    }
  }
}
