.recipients-list {
  &__recipient {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--spacing-unit-x10);
    padding: 0 1.25rem 0 var(--spacing-unit-x6);
    cursor: default;

    &-info-details {
      width: 15rem;
    }

    &-right-section {
      display: flex;
      flex-grow: 1;
      position: relative;
      align-items: center;
      justify-content: flex-end;
      height: var(--spacing-unit-x4);
    }

    &:hover {
      background-color: var(--hover-row-background-teal);
      border-bottom-right-radius: var(--spacing-unit-x5);
      border-top-right-radius: var(--spacing-unit-x5);
    }

    &:hover &-right-section {
      & > :first-child {
        visibility: hidden;
      }

      & > :last-child {
        visibility: visible;
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--spacing-unit-x4);
    width: var(--spacing-unit-x4);
    border-radius: var(--spacing-unit-x2);

    &-icon.svg {
      height: var(--spacing-unit-x2);
      width: var(--spacing-unit-x2);
    }

    &:hover,
    &-selected {
      background-color: var(--text-gray-opacity-15);
    }

    &-selected:hover {
      background-color: var(--text-gray-opacity-25);
    }

    &-container {
      visibility: hidden;
      position: absolute;
      display: flex;
      gap: var(--spacing-unit);
    }
  }

  &__group-header {
    padding: var(--spacing-unit) 1.25rem var(--spacing-unit) var(--spacing-unit-x7);
  }
}
