
.actionable-nested-table-with-lazy-loading {
  display: block;

  &__loader {
    width: 100%;
    background: linear-gradient(0deg, var(--white), transparent);
    border: var(--standard-border);
    border-top: unset;
    height: var(--spacing-unit-x16);
    margin-top: calc(var(--spacing-unit-x16) * -1);
    position: sticky;
    z-index: 3;
  }

  &__show-more {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    margin-top: var(--spacing-unit);
  }
}
