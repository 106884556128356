.ab-split-second-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  &__header {
    display: flex;
    gap: var(--spacing-unit-x2);
    align-items: center;
  }

  &__switch {
    > button {
      height: var(--spacing-unit-x6) !important;

      > div {
        font-weight: var(--font-weight-medium);
      }
    }

    &-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-left: var(--half-spacing-unit);
    }
  }

  &__button {
    &-expand {
      align-self: flex-start;
      margin-left: var(--half-spacing-unit);

      &-text {
        color: var(--text-teal);
      }
    }

    &-select {
      width: fit-content;
      gap: var(--spacing-unit);

      > svg {
        fill: var(--text-gray);
      }
    }
  }

  &__splits {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: var(--spacing-unit-x10);
    grid-row-gap: var(--spacing-unit-x4);
    padding: var(--half-spacing-unit);
    margin-bottom: var(--spacing-unit-x3);

    &-hidden {
      max-height: var(--spacing-unit-x28);
      overflow-y: hidden;
      margin-bottom: 0;
    }

    &-wrapper {
      padding-bottom: var(--spacing-unit-x4);
      border-bottom: var(--standard-border);
      margin-top: -0.5rem;
    }
  }

  &__split {
    & > span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-label {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--spacing-unit);
      align-items: center;
    }

    &-locked {
      color: var(--disabled-gray);
    }
  }

  &__suppress {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
  }

  &__table {
    border: none;
    margin-top: -1.5rem;
  }

  &__trigger-wrapper {
    display: flex;
  }

  .input-with-status {
    & .input {
      padding-right: 0;
      min-width: var(--spacing-unit-x13);
    }

    & .input__icon {
      top: var(--spacing-unit-x3);
      right: var(--spacing-unit-x2);
    }
  }
}

/* stylelint-disable */
@media (max-width: 1064px) {
  .ab-split-second-section__splits {
    grid-column-gap: var(--spacing-unit-x5);
  }

  .ab-split-second-section .input-with-status {
    min-width: 7.33rem;
    max-width: var(--spacing-unit-x18);
  }
}

@media (max-width: 995px) {
  .ab-split-second-section__splits {
    grid-column-gap: var(--spacing-unit-x4);
  }

  .ab-split-second-section .input-with-status {
    min-width: var(--spacing-unit-x13);
  }
}
/* stylelint-enable */
