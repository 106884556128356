.clipboard-copy {
  display: inline-flex;
  align-items: center;
  max-width: max-content;
  position: relative;

  &__button {
    padding: var(--half-spacing-unit) var(--spacing-unit);

    &:hover {
      background-color: var(--hover-row-button-background-teal);
    }
  }

  &__svg {
    margin-left: var(--half-spacing-unit);
  }

  &__trigger-text {
    &:hover {
      text-decoration: none;
    }
  }
}
