.expander {
  &__button {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: -1rem;
    }
  }

  &__border-wrapper {
    display: flex;
    gap: 1.666rem;
  }

  &__icon-wrapper {
    position: relative;
    z-index: 1;
    height: 1.6667rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__thread {
    &::before {
      content: '';
      height: 100%;
      width: 0.0833rem;
      position: absolute;
      top: -1rem;
      margin-left: var(--half-spacing-unit);
      background-color: var(--border-gray);
      cursor: pointer;
    }

    &[threadLine^='hovered']::before {
      background-color: var(--teal);
    }
  }
}
