.upload-html-modal {
  &__description {
    color: var(--light-gray);

  } 

  &__options__accordion__item {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: var(--spacing-unit-x3);

    &:last-child {
      border-bottom: none;
    }

    button {
      padding: 0;
      justify-content: start;
    }
  }

  .accordion-radix__panel {
    margin-top: 0;
  }

  .upload-file__caution {
    margin: 0;
  }

  .drop-zone {
    margin-top: 0;
  }

  &__options__accordion {
    margin-top: var(--spacing-unit-x4);
  }

  &__accordion-title {
    margin-left: var(--spacing-unit-x2);
  }

  &__accordion-description {
    margin-left: 2rem;
    color: var(--light-gray);
  }

  &__caution {
    margin-bottom: var(--spacing-unit-x3);
    margin-top: 0;
  }
}