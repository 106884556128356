.html-preview {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-unit-x4);
  background-color: var(--modal-gray-background);

  &__loading {
    background-color: var(--white);
  }

  &__iframe {
    max-width: 100%;
    border: none;
    pointer-events: none;

    &-container {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      padding: var(--spacing-unit-x4);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
      background-color: var(--white);

      &--hidden {
        opacity: 0;
        position: absolute;
      }
    }
  }
}
