
.wait-until-step {
  &__row {
    display: flex;
    width: 43rem;
    align-items: center;
  }

  &__col {
    display: flex;
    flex-direction: column;
    width: 10.75rem;

    label {
      display: block;
    }

    select {
      display: block;
    }
  }

  &__col-notfirst {
    display: flex;
    flex-direction: column;
    width: 14.75rem;
    margin-left: var(--spacing-unit-x2);
  }

  &__label-checkbox {
    margin-left: var(--spacing-unit-x2);
  }

  &__label-checkbox--nomargin {
    margin-left: 0;
  }

  &__select-small {
    margin-left: var(--spacing-unit-x2);
    flex: 0 0 10rem;
  }

  &__select-large {
    flex: 0 0 20rem;
  }

  .react-date-picker__wrapper {
    border: none;
    outline: none;

    .react-date-picker__inputGroup__input {
      border: none;
      outline: none;
    }
  }
}
