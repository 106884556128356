.spam-check-results {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    background-color: var(--white);
    border-radius: var(--standard-border-radius);
    border: var(--standard-border);

    &__error {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 40rem;
      text-align: center;
      margin: auto;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    border-right: var(--standard-border);
    min-width: calc(var(--spacing-unit-x20) * 4);

    &-title {
      padding: calc(var(--spacing-unit-x2) + var(--half-spacing-unit)) var(--spacing-unit-x4);
      border-bottom: var(--standard-border);
    }

    &-filters {
      flex: max-content;
      overflow: auto;
    }

    &__filter {
      display: grid;
      grid-template-areas: 'title svg' 'score svg';
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x2) var(--spacing-unit-x6);
      border-bottom: var(--standard-border);
      text-align: left;

      &:hover {
        text-decoration: none;
        background-color: var(--selectable-row-hover);
      }

      &-selected {
        background-color: var(--lighter-teal);
        position: relative;

        &::before {
          position: absolute;
          content: '';
          border: var(--text-teal-border);
          inset: 0 auto 0 0;
        }
      }

      &-title {
        grid-area: title;
      }

      &-score {
        grid-area: score;
      }

      &-svg {
        grid-area: svg;
      }
    }

    &-bottom {
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
      gap: var(--spacing-unit-x3);
      border-top: var(--standard-border);

      img {
        max-height: var(--spacing-unit-x6);
      }
    }
  }

  &__details {
    width: 100%;
    overflow-y: auto;
    padding: var(--spacing-unit-x5) var(--spacing-unit-x6);

    &__table {
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);

      &-text {
        padding: var(--spacing-unit-x3) var(--spacing-unit-x4) 0;
      }
    }

    &__success {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: var(--spacing-unit-x8) 0;
      max-width: 40rem;
      text-align: center;
      margin: 0 auto;
    }
  }
}
