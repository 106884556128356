.asset-picker-view-table-container {
  &__scroll-area .scroll-area__viewport > div {
    display: flex !important;
    flex-direction: column;
    gap: var(--spacing-unit-x3);
  }

  &__source-selected-items {
    display: flex;
    flex-direction: column;

    &__title {
      margin: var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x2) var(--spacing-unit-x4);
    }
  }

  &__table {
    width: 100%;

    &--hidden {
      display: none;
    }

    & .location-breadcrumbs {
      max-width: 100%;

      & .breadcrumbs-item {
        cursor: default;
      }
    }

    & .tableV2-tags__tags button {
      padding-top: calc(1rem / 6);
      padding-bottom: calc(1rem / 6);
    }
  }
}
