.forms-upgrade-confirmation {
  img {
    width: 20.8334rem;
    height: 15rem;
  }

  &__status,
  &__status-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__status {
    gap: var(--spacing-unit-x2);
    max-width: 50rem;

    button {
      width: 25rem;
      gap: var(--spacing-unit-x2);

      &:first-of-type {
        margin-top: var(--spacing-unit-x2);
      }
    }

    &-container {
      padding-top: var(--spacing-unit-x8);
      padding-bottom: var(--spacing-unit-x10);
    }
  }

  &__steps {
    &-list {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--spacing-unit-x2);
    }

    &-top-section {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
      padding: var(--spacing-unit-x4);
    }

    &-bottom-section {
      padding: var(--spacing-unit-x4);
      padding-top: 0;
    }

    &-link > .typography {
      font-size: var(--larger-font-size);
      line-height: var(--line-height-larger);
    }
  }
}
