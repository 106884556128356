.list-program-info-hover {
  &__trigger {
    padding-top: var(--spacing-unit);
    display: block;
  }

  &__source {
    align-items: flex-start;
    display: flex;
    padding-top: var(--spacing-unit);

    svg {
      margin-right: var(--spacing-unit);
    }

    > div:last-child {
      width: 100%;
    }
  }

  &__description {
    overflow: hidden;
    padding-top: var(--spacing-unit);
    text-overflow: ellipsis;
    /* stylelint-disable */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* stylelint-enable */

    &-extended {
      display: block;
    }

    &-container {
      padding-top: var(--spacing-unit-x2);
      margin-top: var(--spacing-unit-x2);
      border-top: var(--standard-border);
    }
  }

  &__header {
    .info-hover-header__subtitle {
      gap: var(--half-spacing-unit);
    }

    .svg {
      fill: var(--white);
      margin-right: var(--half-spacing-unit);
    }
  }

  &__footer {
    height: unset;

    & > div:first-child {
      margin-right: var(--half-spacing-unit);
    }
  }

  &__children {
    padding: var(--spacing-unit-x3);
  }

  &__expanded {
    .list-program-info-hover__children {
      max-height: 19rem;
      padding-bottom: 0;
    }
  }

  &__show-more {
    margin-top: var(--spacing-unit-x2);

    &-expanded {
      padding-bottom: var(--spacing-unit-x3);
    }

    &:hover > div {
      color: var(--darker-teal);
    }
  }
}
