
.import-info {
  padding: var(--spacing-unit-x12) 19rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__headline {
    margin-bottom: var(--spacing-unit-x3);
  }

  &__wrapper {
    &-content {
      width: 45.25rem;
    }

    &-email-send {
      text-align: center;
      margin-top: var(--spacing-unit-x4);
    }
  }

  &__import-another {
    margin-top: var(--spacing-unit-x6);
  }
}
