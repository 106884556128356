.email-composer-settings {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-unit-x4);
  padding: var(--spacing-unit-x4);
  justify-content: center;

  .container__wrapper + .container__wrapper {
    margin-top: 0;
  }

  .container .label,
  .selectv2__label {
    margin-top: 0;
  }

  label,
  label > .typography {
    font-size: var(--large-font-size);
  }

  &__fallback-sender {
    padding-top: var(--spacing-unit-x2);
    padding-bottom: var(--spacing-unit-x2);

    &__content {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
      flex-grow: 1;

      .selectv2__container {
        width: 56.333rem;
      }
    }
  }

  .input__container {
    width: 100%;
  }

  &__plain-text-caution {
    margin: 0;
  }

  &__subject-input,
  &__preview-input {
    display: flex;
    flex-grow: 1;

    .tags-input {
      display: flex;
      flex-grow: 1;
    }

    .tagify {
      min-width: 30rem;
      max-width: 100vw;
      flex-grow: 1;
    }
  }

  &__text-row {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-unit);
    align-items: center;

    .label {
      margin-bottom: 0;
    }
  }

  &__validation-recommendations {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-unit);
    gap: var(--spacing-unit-x2);
    padding-left: var(--spacing-unit-x2);
  }

  &__subsection {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
    max-width: 59rem;
  }

  &__subject-label {
    margin-bottom: 0;
  }

  /* stylelint-disable */
  @media (max-width: 1100px) {
    /* stylelint-enable */
    flex-direction: column;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);

    /* stylelint-disable */
    @media (max-width: 1298px) {
      /* stylelint-enable */
      flex-grow: 1;
    }

    &__section {
      min-width: 63rem;

      .selectv2__container {
        min-width: 40rem;
      }

      .container {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-unit-x4);
        width: 100%;
      }

      /* stylelint-disable */
      @media (max-width: 1298px) {
        /* stylelint-enable */
        min-width: 40rem;
        width: unset;
      }

      /* stylelint-disable */
      @media (max-width: 1100px) {
        /* stylelint-enable */
        min-width: 70rem;
      }
    }
  }

  &__right {
    width: 40rem;
    flex-shrink: 0;
    flex-grow: 0;

    /* stylelint-disable */
    @media (max-width: 1298px) {
      /* stylelint-enable */
      width: 32rem;
    }

    /* stylelint-disable */
    @media (max-width: 1100px) {
      /* stylelint-enable */
      min-width: 70rem;
      width: 100%;
    }

    &__section {
      width: 100%;

      .drawer-pane-group {
        width: 100%;
      }

      .selectv2__container {
        max-width: 58.333rem;
      }
    }
  }
}
