.landing-pages-url-modal {
  width: 50rem;

  &__container {
    min-height: 8rem;
  }

  &__info {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__input-name {
    margin-bottom: var(--spacing-unit-x2);
  }
}
