.step-source-selector {
  &__radio-container > div {
    gap: var(--spacing-unit-x2);
  }

  &__show-source-selector {
    &-radio {
      flex-shrink: 0;
    }

    &-container {
      display: flex;
      width: 100%;
    }
  }

  &__source-selector-container {
    margin-top: var(--spacing-unit-x2);

    &__content {
      margin: var(--spacing-unit-x2) 0 var(--spacing-unit);
    }
  }
}
