.upgrade-item {
  display: block;

  &__log-button {
    white-space: nowrap;

    svg {
      margin-right: var(--spacing-unit);
      height: calc(var(--spacing-unit-x2) + var(--spacing-unit-x2) / 3);
      width: calc(var(--spacing-unit-x2) + var(--spacing-unit-x2) / 3);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--spacing-unit-x3);
  }
   
  &__complete {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    gap: var(--spacing-unit-x2);
   
    > div {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x4);
    }
    
    &-buttons {
      flex-shrink: 0;
    }
    
    &-text {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
    }
  }
}
