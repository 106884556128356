.add-button {
  padding: calc(var(--spacing-unit-x16) / 12) var(--spacing-unit-x2);
  display: flex;
  align-items: center;
  gap: calc(var(--spacing-unit-x16) / 12);
  width: fit-content;
  border-radius: var(--standard-border-radius);
  color: var(--text-teal);

  &:hover {
    background-color: var(--lighter-teal);
  }

  &__no-label {
    padding: calc(var(--spacing-unit-x16) / 12);
    border-radius: var(--extra-large-border-radius);
  }

  &__delete {
    color: var(--text-gray);

    &:hover {
      color: var(--remove-red);
      background-color: var(--status-warning);
  
      > div {
        color: var(--remove-red);
      }
    }

    > div {
      color: var(--text-gray);
    }
  }

  &__disabled {
    color: var(--button-gray);

    > div {
      color: var(--button-gray);
    }

    &:hover {
      color: var(--button-gray);
      background-color: var(--off-white-hover-disabled);
      cursor: not-allowed;

      > div {
        color: var(--button-gray);
      }
    }
  }
}
