.copy-log {
  max-width: 100rem;
  
  & .tableV2-tbody {
    height: 30rem;
  }
  
  &__status {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-unit);
  }
}