.copy-from-segment-step-editor {
  &__source-selector {
    margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x3) 0;
  }

  &__update-records {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__inline {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-unit);

    .tooltip__trigger {
      margin-top: var(--spacing-unit);
    }

    svg {
      fill: var(--light-gray);
    }

    label {
      margin-right: var(--spacing-unit-x2);
    }
  }
}
