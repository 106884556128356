.account-details {
  &-description {
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x4);
  }

  &-item-header {
    display: flex;
    align-items: center;
    margin-bottom: calc(var(--spacing-unit) + var(--spacing-unit-x2) / 3);

    &__text {
      margin-right: var(--spacing-unit);
    }

    &__svg {
      fill: var(--light-gray);

      &:hover {
        fill: var(--text-gray);
      }
    }

    .tooltip__trigger {
      display: flex;
    }
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
