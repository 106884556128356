.add-logo-modal {
  width: 100%;
  max-width: 90rem;

  /* stylelint-disable */
  @media (max-width: 1200px) {
    /* stylelint-enable */
    max-width: calc(100vw - var(--spacing-unit-x8));
  }

  &__body {
    display: flex;
    grid-gap: var(--spacing-unit-x6);

    /* stylelint-disable */
    @media (max-width: 884px) {
      /* stylelint-enable */
      display: block;
    }

    &-inputs {
      max-width: 39.333rem;
      width: 100%;

      /* stylelint-disable */
      @media (max-width: 884px) {
        /* stylelint-enable */
        max-width: 100%;
      }
    }

    &-upload {
      width: 100%;
    }
  }
}
