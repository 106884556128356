.email-composer-preview-tabs {
  display: flex;
  gap: var(--half-spacing-unit);
  background-color: var(--selectable-row-hover);
  border-radius: var(--vertical-bar-border-radius);

  &__toggletip__trigger {
    &--focus {
      .email-composer-preview-tabs__tab:hover {
        .typography {
          color: var(--text-gray);
        }

        .svg {
          fill: var(--text-gray);
        }
      }
    }
  }

  &__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-unit);
    border-radius: var(--vertical-bar-border-radius);
    padding: var(--spacing-unit) var(--spacing-unit-x4);
    cursor: pointer;

    &:hover,
    &:focus-visible,
    &--active {
      text-decoration: none;

      .typography {
        color: var(--text-gray);
      }
    }

    &:focus-visible {
      outline: none;
      box-shadow: var(--indicator-focus-box-shadow);
    }

    &--active {
      background-color: var(--white);
      box-shadow: var(--drop-down-shadow);
    }

    &--disabled {
      .svg {
        fill: var(--light-gray);
      }

      &:hover {
        color: var(--text-gray);

        .svg {
          fill: var(--text-gray);
        }
      }
    }
  }
}
