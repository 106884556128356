
.data-card-header {
  align-items: center;
  display: flex;
  gap: 0.417rem;

  &__text {
    white-space: nowrap;
  }
}
