
.initial-message-editor {
  background-color: var(--white);
  padding: var(--spacing-unit-x4) var(--spacing-unit-x4) 0;
  border: var(--standard-border);
  margin-bottom: var(--spacing-unit-x4);

  .container {
    border: none;
    margin-bottom: 0;
    padding: var(--spacing-unit-x4) 0;

    .section-headline {
      display: none;
    }
  }

  &__header {
    margin-bottom: var(--spacing-unit-x4);
  }

  ul {
    margin-bottom: 0;
    list-style-type: square;
    padding-left: var(--spacing-unit-x2);

    li {
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__simple {
    margin-left: var(--default-font-size);
  }

  &__legalese {
    margin-left: var(--page-header-font);
  }
}
