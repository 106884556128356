.modal-header {
  display: flex;
  position: relative;

  &__inner {
    min-height: var(--spacing-unit-x11);
    padding: calc(var(--spacing-unit-x2) + var(--half-spacing-unit)) 3.33333rem;
    border-bottom: var(--modal-border);
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--light-theme-background-darker);
    border-top-left-radius: 0.333rem;
    border-top-right-radius: 0.333rem;
  
    &--icon {
      justify-content: flex-start;
    }

    &--no-border {
      border-bottom: none;
    }

    &--list {
      & input {
        background-color: var(--white);
      }
    }

    &--default {
      padding: var(--spacing-unit-x6) var(--spacing-unit-x6) 0 var(--spacing-unit-x6);
      justify-content: center;
      background-color: transparent;
    }
  }

  &__close-button {
    border-radius: 50%;
    height: var(--spacing-unit-x6);
    position: absolute;
    right: var(--spacing-unit-x3);
    top: 50%;
    transform: translateY(-50%);
    width: var(--spacing-unit-x6);
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border-radius: 50%;
    }

    svg {
      fill: var(--light-gray);
      height: calc(var(--spacing-unit-x8) / 3);
      width: calc(var(--spacing-unit-x8) / 3);
    }

    &:hover svg {
      fill: var(--text-gray);
    }
  }
}
