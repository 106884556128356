
.table-with-menu {
  position: relative;

  &__menu {
    position: absolute;
    top: var(--standard-border-width);
    right: var(--standard-border-width);
    padding: calc(10rem / 12) var(--spacing-unit-x2);
    border-bottom: var(--input-border-hover);
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), var(--white) var(--spacing-unit-x2));
    z-index: 5;
  }
}
