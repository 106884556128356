.selectV2-popover {
  & div[data-radix-popper-content-wrapper] {
    z-index: var(--z-index-9999) !important;
  }

  & button[data-test='selectV2-popover-trigger'] {
    text-decoration: none;
  }

  &__select-open {
    &.button .svg {
      fill: var(--hover-create-option-icon);
    }

    &.button .typography {
      color: var(--hover-create-option-icon);
    }
  }

  &__selectV2 {
    &__hide-search-input {
      & .selectv2__control {
        display: none;
      }

      & .selectv2__menu {
        margin-top: unset !important;

        & .custom-menu-list {
          & .selectv2__menu-list {
            border: unset;
          }
        }
      }
    }
  }

  &__container.container {
    padding: 0;

    & .selectv2 {
      & .selectv2__control-dots {
        margin-right: var(--spacing-unit);
      }

      & .selectv2__clear-indicator {
        & -with-dropdown-indicator-hidden {
          margin-right: var(--spacing-unit) !important;
        }
      }

      & .selectv2__control {
        margin: var(--spacing-unit-x2);
      }


      &__menu {
        position: unset;
        margin-bottom: 0;

        &-list {
          border: unset;
          border-top: var(--standard-border);
          border-top-left-radius: 0;
          border-top-right-radius: 0;

          & .empty-listing {
            &__text {
              width: 100%;
              text-wrap: initial;
            }
          }
        }
      }
    }
  }
}