.personalization-tag-input {
  &__wrapper {
    position: relative;
    display: flex;
    gap: var(--spacing-unit);
  }

  &__character-count {
    pointer-events: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    position: absolute;
    top: 0.7rem;
    right: var(--spacing-unit-x2);

    &--disabled {
      opacity: 0.5;
    }
  }

  &__loader {
    padding: 0.666rem;
  }

  &__wrapper .tagify {
    min-height: var(--spacing-unit-x6);
    border: var(--standard-border);
    font-size: var(--large-font-size);
    width: 30rem;
    max-width: 30rem;
    padding-right: 3.75rem;
    background-color: var(--white);
    border-radius: var(--standard-border-radius);

    &__input {
      padding: calc(2 / var(--spacing-unit));
      padding-left: var(--spacing-unit-x2);
    }

    &:focus-visible,
    &:focus-within,
    &:active {
      border: var(--standard-border-dark-blue);
      outline: var(--input-outline-border);
    }

    & .tagify-input {
      padding: 0.416rem;
    }

    & .tagify__tag {
      border-radius: var(--spacing-unit-x20);
      height: var(--spacing-unit-x4);
      max-height: var(--spacing-unit-x4);
      border: var(--standard-border);
      padding: 0.17rem 0.25rem 0.17rem 0.75rem;
      margin: 0 var(--half-spacing-unit);
      gap: var(--half-spacing-unit);

      &-invalid {
        border: var(--program-step-border-error);

        &:hover {
          cursor: pointer;
        }
      }

      &-icon {
        display: none;

        &--visible {
          display: inline-flex;
        }
      }

      &-tooltip {
        min-width: unset;
        width: max-content;
        max-width: 25rem;
        visibility: hidden;
        position: absolute;
        bottom: 150%;
        color: var(--white);
        background-color: var(--text-gray);
        border-radius: var(--standard-border-radius);
        padding: var(--spacing-unit) var(--spacing-unit-x2);
        font-size: var(--large-font-size);
        text-align: center;
        white-space: normal;
        overflow: unset;
        text-overflow: unset;
        display: unset;
        vertical-align: unset;
        transition: none;

        &__text {
          display: flex;
          align-items: center;
          gap: var(--spacing-unit);
        }

        &::before {
          display: none;
        }

        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: var(--spacing-unit-x6);
          margin-left: calc(0 - var(--spacing-unit));
          border-width: var(--spacing-unit);
          border-style: solid;
          border-color: var(--text-gray) transparent transparent transparent;
        }
      }

      &:focus-visible {
        outline: var(--standard-border-dark-blue);
      }

      & > div:not(.tagify__tag-tooltip) {
        display: flex;
        align-items: center;
        gap: var(--half-spacing-unit);
        border-radius: var(--spacing-unit-x20);
        padding: 0;

        &:hover {
          & .tagify__tag-tooltip { /* stylelint-disable-line selector-max-compound-selectors */
            left: 0;
            visibility: visible;
          }
        }
      }

      span[contenteditable] {
        padding: 0;
        margin: 0;

        &:hover {
          cursor: pointer;
        }
      }

      &:hover {
        border-radius: var(--spacing-unit-x20);
        border: var(--standard-border);
        background-color: var(--selectable-row-hover);
        cursor: pointer;

        &.tagify__tag-invalid {
          border: var(--program-step-border-error);
        }
      }

      & .tagify__tag__removeBtn {
        width: var(--spacing-unit-x3);
        height: var(--spacing-unit-x3);
        font: var(--spacing-unit-x3) / 1 Open Sans;
        margin: 0.17rem;
        padding: 0.17rem;

        &::after {
          width: inherit;
          padding-left: 0.17rem;
          color: var(--text-gray);
        }

        &:focus-visible {
          outline: var(--standard-border-dark-blue);
          background-color: var(--hover-indicator-background);
        }

        &:hover {
          color: var(--black);
          background-color: var(--hover-indicator-background);

          & .tagify__tag-tooltip { /* stylelint-disable-line selector-max-compound-selectors */
            visibility: visible;

            &::after { /* stylelint-disable-line selector-max-compound-selectors */
              left: calc(50% - var(--spacing-unit));
            }
          }
        }

        & + div::before { /* stylelint-disable-line selector-max-compound-selectors */
          box-shadow: unset !important;
        }
      }

      &-emoji {
        border-radius: unset;
        outline: unset;
        padding: unset;
        margin: unset;

        &:hover {
          background-color: unset;
          border-radius: unset;
          cursor: unset;
          outline: unset;
        }
      }

      &-text {
        max-width: 34rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: unset !important;
        color: var(--text-gray);
      }
    }
  }

  &__input-wrapper {
    position: relative;

    .tags-input {
      align-items: center;
    }

    .tagify {
      display: flex;
      align-items: center;
    }

    &--has-tags {
      .tagify .tagify__tag {
        margin-top: var(--half-spacing-unit);
        margin-bottom: var(--half-spacing-unit);
      }

      .tagify .tagify__input {
        line-height: normal;
      }
    }

    &--error {
      .tagify {
        border: var(--program-step-border-error);
      }
    }

    &--disabled {
      cursor: not-allowed;

      .tagify {
        background-color: var(--off-white-hover);
        opacity: 0.5;
        pointer-events: none;

        .tagify__tag {
          padding-right: 0.75rem;
        }

        .tagify__tag__removeBtn {
          display: none;
        }
      }
    }
  }

  &__fallback-modal__content {
    & .selectv2__control {
      cursor: unset !important;
    }
  }

  &__tag-buttons {
    display: flex;
    gap: var(--spacing-unit);
  }
}
