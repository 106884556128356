.input-with-color-picker {
  align-items: center;
  display: flex;
  position: relative;
  transition: var(--linear-80);

  &__color-picker {
    position: absolute;

    &-trigger {
      align-items: center;
      display: flex;
      gap: calc(var(--spacing-unit) / 2);
      height: 1.833rem;
      justify-content: center;
      width: 2.333rem;

      &:hover {
        border: var(--input-border);
        border-radius: var(--standard-border-radius);
        cursor: pointer;
      }
    }

    &-caret {
      fill: var(--light-gray);
      height: 0.238rem;
      width: 0.417rem;

      &-open {
        fill: var(--light-gray);
      }
    }

    &-icon {
      fill: var(--button-hover);
    }
  }

  & .input {
    height: 2.333rem;
    font-size: var(--medium-font-size);
    line-height: var(--line-height-standard);
    padding-left: var(--spacing-unit-x6);
  }

  & .input__container {
    align-items: center;
    display: flex;

    .input-with-max-length__counter {
      top: var(--half-spacing-unit);
    }
  }

  & > &__color-picker {
    margin-left: var(--spacing-unit);
    position: absolute;
    z-index: 1;
  }

  .color-picker__trigger-open &__color-picker-trigger {
    border: var(--input-border);
    border-radius: var(--standard-border-radius);
    cursor: pointer;
  }
}
