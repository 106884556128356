.account-management-data-cards {
  margin: var(--spacing-unit-x12) var(--spacing-unit-x4) 0;

  &__loader {
    margin-top: var(--spacing-unit-x4);
  }

  &__manager {
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x8);
  }

  &__text {
    min-width: 80rem;
    max-width: 94rem;
  }

  &__cards {
    margin-top: var(--spacing-unit-x4);
    display: flex;
    gap: var(--spacing-unit-x3);
  }

  &__card {
    flex: 1;

    &-child-count {
      padding-left: var(--spacing-unit-x3);
    }
  }

  &__pie-chart {
    display: flex;
    justify-content: flex-end;
  }
}
