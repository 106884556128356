
.manage-tag-modal {
  width: 50rem;

  .modal-body {
    margin-bottom: var(--spacing-unit);
  }

  &__input {
    &-no-color {
      .input__icon {
        fill: var(--tag-no-color);
      }
    }

    &-coral-red {
      .input__icon {
        fill: var(--tag-coral-red);
      }
    }

    &-orange-peel {
      .input__icon {
        fill: var(--tag-orange-peel);
      }
    }

    &-golden-yellow {
      .input__icon {
        fill: var(--tag-golden-yellow);
      }
    }

    &-lime-green {
      .input__icon {
        fill: var(--tag-lime-green);
      }
    }

    &-leaf {
      .input__icon {
        fill: var(--tag-leaf);
      }
    }

    &-sky {
      .input__icon {
        fill: var(--tag-sky);
      }
    }

    &-cobalt {
      .input__icon {
        fill: var(--tag-cobalt);
      }
    }

    &-vivid-violet {
      .input__icon {
        fill: var(--tag-vivid-violet);
      }
    }

    &-hot-magenta {
      .input__icon {
        fill: var(--tag-hot-magenta);
      }
    }

    &-ruby-red {
      .input__icon {
        fill: var(--tag-ruby-red);
      }
    }
  }

  &__label {
    margin-top: var(--spacing-unit-x2);
    margin-bottom: calc(var(--spacing-unit) + var(--half-spacing-unit));
  }

  &__color-picker-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__color-container {
    box-sizing: content-box;
    height: 3.167rem;
    width: 3.167rem;
    display: inline-block;
    padding: var(--half-spacing-unit);
    border-radius: 50%;
    border: var(--legend-border-radius) solid transparent;

    &-selected {
      border: var(--legend-border-radius) solid var(--button-hover);
    }
  }

  &__color-chooser {
    box-sizing: border-box;
    height: 3.167rem;
    width: 3.167rem;
    border: var(--standard-border-width) solid rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    justify-content: center;
    display: inline-flex;
    align-items: center;

    svg {
      fill: var(--white);
      width: 1.167rem;
      height: 1.167rem;
    }

    &-no-color {
      border: var(--standard-border-width) solid var(--button-hover);

      svg {
        fill: var(--gray-icon);
      }
    }

    &:hover {
      box-shadow: 0 0 0.333rem 0.167rem rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
}
