.tableV2-tags {
  &__hidden-tag {
    position: absolute;
    visibility: hidden;
  }

  &__tag-manager-trigger-with-text {
    visibility: hidden;
  }

  &__tags {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit);
    width: 100%;
    height: 1.6667rem;

    .tag-manager__drop-down-open .tag-manager-trigger,
    .tag-viewer__drop-down-open .tag-manager-trigger {
      background-color: var(--teal);
      cursor: pointer;

      .tag-manager-trigger__icon {
        fill: var(--white);
      }

      .tag-manager-trigger-with-number__number,
      .tag-manager-trigger-with-text__text {
        color: var(--white);
      }
    }

    .tag-manager__drop-down-open {
      .drop-down__main-button {
        .typography {
          color: var(--white);
        }
      }
    }
  }

  &-hover {
    .tag-manager-trigger {
      visibility: visible;
    }
  }
}
