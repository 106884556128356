.forms-upgrade-modal {
  .progress-bar {
    margin: var(--spacing-unit-x4) var(--spacing-unit-x20);
  }
  
  &__header {
    background-color: var(--white);
    padding: var(--spacing-unit-x4);
  }
   
  &__body {
    background-color: var(--page-back);
  }

  &__footer {
    &__extra {
      margin-right: auto;
    }

    .modal-footer__inner {
      padding: var(--spacing-unit-x4);
    }
  }
}
