.email-composer-preview-actions {
  &__container {
    display: flex;
    column-gap: var(--spacing-unit);
  }

  &__drop-down-actions {
    display: inline-grid;
  }

  &__button {
    justify-content: center;
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
    border: none;

    &:hover {
      background-color: var(--off-white-focus);
    }

    .svg {
      width: var(--spacing-unit-x3);
      height: var(--spacing-unit-x3);
    }
  }

  &__disabled-button:hover {
    background-color: transparent !important;
    cursor: not-allowed;
  }

  &__drop-down {
    &-open .button {
      background-color: var(--off-white-focus);
    }
  }

  &__tooltip {
    align-items: center;
    display: flex;
    transition: var(--linear-80);
  }

  .drop-down {
    & > .button {
      width: var(--spacing-unit-x6);
      height: var(--spacing-unit-x6);
      transition: var(--linear-80);

      &:hover {
        background-color: var(--off-white-focus);
      }
    }

    .tooltip__trigger .svg {
      width: var(--spacing-unit-x3);
      height: var(--spacing-unit-x3);
    }
  }
}
