.company__addresses-list {
  border-top: var(--standard-border);
  margin: var(--spacing-unit-x4) 0;
  padding-top: var(--spacing-unit-x4);

  &-title {
    margin-bottom: var(--spacing-unit-x2);
  }

  &-description {
    margin-bottom: var(--spacing-unit-x4);
    padding-right: var(--spacing-unit-x12);
  }
}
