.clickthrough-links-modal {
  width: 100rem;
  height: 60rem;

  &__header {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }

  &__body {
    &.modal-body {
      padding: 0;
    }

    &-flex {
      display: flex;
      flex-direction: column;
    }
  }

  &__description {
    border-bottom: var(--standard-border);
  }

  &__footer {
    .modal-footer__inner {
      padding: var(--spacing-unit-x4);
    }
  }

  &__button-check {
    margin-right: auto;

    &__dots {
      padding: 0;
    }
  }
}
