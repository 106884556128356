.webinars-join-view-data-cards {
  margin: var(--spacing-unit-x12) var(--spacing-unit-x4) 0;

  &__cards {
    margin-top: var(--spacing-unit);
    display: flex;
    gap: var(--spacing-unit-x3);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--spacing-unit-x6);

    &-dropdown {
      display: flex;
      align-items: center;

      &-label {
        color: var(--tab-gray);
      }

      &-trigger {
        display: flex;
        align-items: center;
        padding-left: calc(var(--spacing-unit) + var(--half-spacing-unit));

        &:hover > div,
        &:hover > svg {
          color: var(--text-teal);
          fill: var(--text-teal) !important;
        }

        .svg {
          margin: 0 calc(8rem / 12);
        }
      }
    }
  }
}
