.preview-segment-modal {
  width: 100rem;
  height: 60rem;
  
  &__segment-name {
    margin-bottom: var(--spacing-unit);
  }
   
  .tableV2 {
    margin-top: var(--spacing-unit-x4);
    
    &__wrapper {
      height: 37rem;
    }
  }
}
