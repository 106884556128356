.global-composer-header-review-bar {
  display: flex;
  justify-content: space-between;
  background-color: var(--dark-blue);
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  gap: calc(var(--spacing-unit-x4) / 3);
  position: sticky;
  top: var(--position-sticky-global-header-composer);
  z-index: var(--z-index-999);

  &__right {
    display: flex;
    gap: var(--spacing-unit-x2);
  }

  &__customer-care {
    display: grid;
    background-color: var(--dark-blue);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    gap: calc(var(--spacing-unit-x4) / 3);
    grid-template-columns: 1fr repeat(3, auto);
    position: sticky;
    top: var(--position-sticky-custom-care-header);
    z-index: var(--z-index-999);
  }

  &-tooltip {
    max-width: 25.5rem;
  }
}