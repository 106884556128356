
.table-row-primary-action {
  &__tooltip {
    display: flex;
    align-items: center;
  }

  &__button {
    margin: 0.333rem 0;
    justify-content: center;
    width: var(--spacing-unit-x6) !important;
    height: var(--spacing-unit-x6) !important;

    &:hover {
      background-color: var(--hover-row-button-background-teal);
    }

    & > .svg {
      fill: var(--text-gray);
    }

    &-disabled {
      pointer-events: none;

      &:hover {
        background: none !important;
      }
    }
  }

  &__svg-disabled {
    opacity: 0.4;
    transition: fill 80ms linear;
  }
}
