.form-templates-listing-page-container {
  &__create-template {
    height: var(--spacing-unit-x6);

    &-disabled {
      &.button:hover {
        cursor: not-allowed;
      }
    }

    .svg {
      fill: var(--white);
      margin-right: var(--spacing-unit);
    }
  }

  &__content-catalog {
    max-height: var(--spacing-unit-x6);
    margin-right: var(--spacing-unit-x2);
  }
}