.options-block {
  &__item {
    position: relative;
    padding-left: var(--spacing-unit-x3);

    &::after {
      content: '';
      position: absolute;
      top: calc(var(--line-height-large) / 2);
      transform: translateY(-50%);
      left: var(--spacing-unit);
      width: var(--half-spacing-unit);
      height: var(--half-spacing-unit);
      background-color: var(--text-gray);
      border-radius: 50%;
    }
  }
}
