.preview-asset-modal {
  width: 56rem;

  .modal-body {
    padding: 0 var(--spacing-unit-x4);
  }

  &__medium {
    width: 80rem;
  }

  &__full {
    &-padding {
      .modal-body {
        padding: var(--spacing-unit-x4);
      }
    }

    &-height {
      height: 100%;
    }
  }

  &__header {
    justify-content: flex-start;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    width: 100%;
   
    &__asset-name {
      &__trigger-wrapper {
        margin-right: var(--spacing-unit-x4);
      }
    }

    &__actions {
      &-dropdown {
        margin-left: auto;
      }

      &-button {
        padding: calc(var(--spacing-unit-x10) / 6) var(--spacing-unit-x2);

        .svg {
          width: 0.83rem;
        }

        &.button--float .svg {
          margin: 0 0 0 var(--spacing-unit-x2);
        }

      }
    }
  }

  &__body {
    &-grey {
      background: var(--modal-gray-background);
    }

    &-content {
      width: 100%;
      height: 100%;
      min-height: 31.666rem;
      display: flex;
      justify-content: center;
      background-color: var(--white);
    }

    &-image {
      max-height: 100%;
      max-width: 100%;
    }

    &-pdf {
      width: 100%;
    }

    &-iframe {
      border: none;
      width: 100%;
      min-height: 30rem;
    }
  }

  &__footer {
    .modal-footer__inner {
      padding: var(--spacing-unit-x4);
    }
  }

  &__loader {
    align-self: center;
  }
}
