
.segment-info-hover-card-segment-definition {
  display: block;

  &__expression {
    padding: var(--half-spacing-unit) 0 var(--half-spacing-unit) 0;

    &-wrapper {
      padding: 0.5rem 0 0.5rem 0;
      display: flex;
      flex-direction: row;
      column-gap: var(--half-spacing-unit);
    }

    &-index {
      padding-right: calc(var(--half-spacing-unit) + var(--spacing-unit));
      fill: var(--light-gray);
    }
  }

  &__field {
    color: var(--text-teal);
  }

  &__invalid {
    color: var(--error);
  }

  &__wrapper {
    display: flex;
    align-items: center;
    column-gap: var(--half-spacing-unit);
    flex-wrap: wrap;

    &-custom {
      padding-left: calc(var(--half-spacing-unit) + var(--spacing-unit));
    }
  }

  &__details-link {
    margin-top: var(--spacing-unit-x2);
  }
}
