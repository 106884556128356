.tableV2-header-menu-actions__menu {
  position: absolute;
  top: 0;
  right: 0;
  width: var(--spacing-unit-x9);
  height: var(--spacing-unit-x10);
  box-shadow: var(--box-shadow-table-header-border);
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), var(--white) var(--spacing-unit-x2));
  z-index: var(--z-index-99999);
}
