.poll-block-removed-modal {
  width: 40rem;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-unit-x4);
    padding: var(--spacing-unit-x6) var(--spacing-unit-x4) var(--spacing-unit-x2);
  }

  .options-block {
    width: 100%;
    text-align: left;

    &__item {
      position: relative;
      padding-left: var(--spacing-unit-x3);
  
      &::after {
        content: '';
        position: absolute;
        top: calc(var(--line-height-large) / 2);
        transform: translateY(-50%);
        left: var(--spacing-unit);
        width: var(--half-spacing-unit);
        height: var(--half-spacing-unit);
        background-color: var(--text-gray);
        border-radius: 50%;
      }
    }
  }
  
}
