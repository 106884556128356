.ignore-year-popover {
  &__popover {
    &-content {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);

      .toggle {
        margin-left: 0;
      }
    }
  }
}
