.select-export-fields-screen {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  &__header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: var(--spacing-unit-x5);

    &-text {
      display: flex;
      gap: var(--spacing-unit);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: var(--spacing-unit-x2);
  }

  &__button-next {
    display: flex;
    justify-content: flex-end;
  }

  /* stylelint-disable */
  @media (max-width: 1200px) {
    .select-export-fields-screen__content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  /* stylelint-enable */
}
