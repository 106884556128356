.analytics-tracking-pane {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  &__domains {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    border-radius: 0.33333rem;
    background: var(--input-background);
    max-width: 45rem;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      &__text {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: var(--spacing-unit);

        .info-tooltip__svg {
          pointer-events: none;
        }
      }
    }
  }
}
