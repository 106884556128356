.inactive-soft-bounces {
  &__tooltip {
    margin-top: 50%;

    &-container {
      position: absolute;
      top: calc(var(--spacing-unit-x4) + var(--half-spacing-unit));
      height: var(--spacing-unit-x6);
      left: var(--spacing-unit-x14);
    }

    svg {
      fill: var(--gray-icon);
    }
  }
}
