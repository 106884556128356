.erase-contact-container {
  margin-top: var(--spacing-unit-x4);

  &__block {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    background-color: var(--white);
    padding: var(--spacing-unit-x4);
    margin-top: var(--spacing-unit-x4);
  }

  &__title {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__info {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__input {
    margin-bottom: var(--spacing-unit-x6);

    .input-with-status {
      width: 27.25rem;
    }

    .input {
      padding-right: var(--spacing-unit-x7);
    }
  }
}
