
.report-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 5.83rem;
  margin-bottom: var(--spacing-unit-x6);

  &__row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
  }

  &__buttons__row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .svg {
      height: 1.33rem;
      width: 1.33rem;
      fill: var(--text-gray);
    }
  }

  &__align {
    align-self: center;
  }
}
