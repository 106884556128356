.image-media-card {
  .media-card__body {
    display: flex;
    flex-direction: column;
    height: var(--spacing-unit-x26);
  }

  &--fill {
    .media-card__body {
      justify-content: flex-start;
      overflow: hidden;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &--fill {
      max-width: unset;
      max-height: unset;
      width: 100%;
      height: 100%;
    }
  }
}
