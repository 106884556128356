.empty-listing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding: var(--spacing-unit-x8) var(--spacing-unit-x4) var(--spacing-unit-x10);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);

  &__no-border {
    border: 0;
    border-radius: 0;
  }

  &__full-height {
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__headline {
    max-width: var(--empty-state-content-width);
    margin-top: var(--spacing-unit-x2);
    text-align: center;
  }

  &__text {
    max-width: var(--empty-state-content-width);
    margin-top: var(--spacing-unit-x2);
    text-align: center;

    &-small {
      line-height: calc(var(--spacing-unit-x2) + var(--spacing-unit));
    }
  }

  &__button {
    margin-top: var(--spacing-unit-x4);

    &-small {
      line-height: calc(var(--spacing-unit-x2) + var(--spacing-unit));
    }

    &-link {
      margin-left: var(--spacing-unit);
    }

    &-icon {
      gap: var(--spacing-unit);
    }
  }

  &__link {
    margin-top: var(--spacing-unit-x4);

    &-small {
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__small {
    width: var(--spacing-unit-x16);
    height: var(--spacing-unit-x10);
  }

  &__medium {
    width: var(--empty-state-img-size);
    height: var(--empty-state-img-medium-height);
  }

  &__large {
    width: var(--empty-state-img-large-width);
    height: var(--empty-state-img-size);
  }

  &__card {
    width: 8.333rem;
    height: var(--spacing-unit-x16);
  }
}
