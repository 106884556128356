.account-redirects {
  &__info-action {
    margin-bottom: var(--spacing-unit-x4);

    .info-action--wrapper-buttons {
      display: none;
    }
  }

  &-input__header {
    height: var(--spacing-unit-x4);
  }

  &__section {
    background-color: var(--white);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x4);
  }

  &-input:first-child {
    padding-bottom: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x4);
    border-bottom: var(--standard-border);
  }
}
