.email-sms-header {
  min-width: var(--positioning-min-width);
  max-width: var(--positioning-max-width);

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--spacing-unit-x2);
  }

  &__drop-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: var(--standard-border-radius);
    border-radius: 0.33rem;
    padding: var(--spacing-unit-x2) var(--spacing-unit) var(--spacing-unit-x2) var(--spacing-unit-x2);

    &:hover {
      background-color: var(--off-white-hover);
    }
  }

  &__download {
    margin-right: 0.75rem;
  }

  &__open {
    transform: rotate(180deg);
  }

  &__dropdown-label {
    display: flex;
    align-items: center;
    padding-left: 1.1rem;
    width: 18.6rem;
  }

  .drop-down__main-button[data-state='open'] {
    background-color: var(--off-white-hover);
  }
}
