.select-or-create-item {
  display: flex;
  margin-top: calc(-1 * var(--spacing-unit-x2));

  &-align-items {
    align-items: center;
  }

  > .button {
    flex-basis: 34%;
    margin-left: var(--spacing-unit-x2);
  }

  .selectv2__container {
    flex-basis: 66%;
  }

  &__svg {
    margin-right: var(--spacing-unit);
  }
}
