.radio-card {
  position: relative;
  width: 43.5rem;
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x2) var(--spacing-unit-x5);
  display: flex;
  gap: var(--spacing-unit-x5);
  background-color: var(--white);
  cursor: pointer;

  &--small-card {
    align-items: center;
    gap: var(--spacing-unit-x8);
    
    .radio-card__svg {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
    }
  }

  &__tooltip {
    &__trigger-wrapper {
      display: flex;
      padding: var(--half-spacing-unit) 0;
    }
  }

  &--selected {
    background-color: var(--lighter-teal);
    border: var(--teal-border);

    .radio-card__svg {
      &--hover {
        opacity: 1;
      }

      &--blur {
        opacity: 0;
      }

      &--disabled {
        opacity: 0;
      }
    }
  }

  &--disabled {
    background-color: var(--light-theme-background-darker);
    border: var(--standard-border);
    cursor: not-allowed;

    .radio-card {
      &__text__title {
        color: var(--light-gray);
      }

      &__svg {
        &--hover {
          opacity: 0;
        }

        &--blur {
          opacity: 0;
        }

        &--disabled {
          fill: var(--disabled);
          opacity: 1;
        }
      }
    }

    * {
      cursor: not-allowed;
    }
  }

  &__svg {
    flex-shrink: 0;
    width: var(--spacing-unit-x13);
    height: var(--spacing-unit-x13);

    .svg {
      transition: var(--ease-all-slow);
      position: absolute;
    }

    &--hover {
      opacity: 0;
    }

    &--blur {
      opacity: 1;
    }
  }

  &:focus-visible {
    border: var(--standard-border-dark-blue);
    outline: var(--input-outline-border);
  }

  &:hover:not(.radio-card--disabled) {
    transition: var(--ease-all-slow);
    border: var(--teal-border);

    .radio-card__svg {
      &--hover {
        opacity: 1;
      }

      &--blur {
        opacity: 0;
      }
    }

    .radio__label::before {
      transition: var(--ease-all-slow);
      border-color: var(--border-teal);
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--half-spacing-unit);
  }

  &__radio {
    position: absolute;
    top: var(--half-spacing-unit);
    right: var(--half-spacing-unit);

    .radio__label--no-label {
      width: unset;
    }

    .radio__label::before {
      border-color: var(--border-gray);
    }
  }

  &__radio-out {
    display: flex;
    align-items: center;
    width: 100%;

    .radio-card {
      width: 100%;
      background-color: var(--white);
      border: var(--radio-card-border);
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4) !important;

      &__radio {
        display: contents;
      }

      &--selected {
        border: var(--teal-border);
        background: var(--lighter-teal);
        box-shadow: var(--radio-card-box-shadow);

        &:hover {
          background-color: var(--hover-row-button-background-teal);
        }
      }

      &:hover:not(.radio-card--disabled):not(.radio-card--selected) {
        background-color: var(--hover-row-background-teal);
      }

      &__text__title {
        margin-bottom: var(--spacing-unit);
      }
    }
  }
}
