.review-send-email-message-step {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x2);

  &__arrow {
    margin-top: calc(49.5rem / 12);
    fill: var(--gray-icon);
    justify-self: center;
  }

  &__change-to-container {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit-x16) / 12);
    padding: calc(var(--spacing-unit-x18) / 12) 0;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr var(--spacing-unit-x12) 1fr;
    row-gap: var(--spacing-unit-x2);
  }

  &__current-field-container {
    background-color: var(--input-background);
    border-radius: var(--standard-border-radius);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    padding: calc(var(--spacing-unit-x18) / 12) var(--spacing-unit-x2);
  }
  
  &__message {
    background-color: var(--chip-base);
    border-radius: var(--spacing-unit-x2);
    padding: var(--half-spacing-unit)calc(var(--spacing-unit-x18) / 12);
    
    &-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: var(--spacing-unit);
    }
  }

  &__value-container {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit-x16) / 12);
  }
}