.popover-trigger-button {
  &__open {
    .svg {
      color: var(--white);
    }
  }

  &__selected {
    .svg {
      color: var(--teal);
    }
  }
}
