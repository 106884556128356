.first-time-modal {
  width: 84rem;
  height: 52rem;
  background-color: var(--white);
  overflow-y: scroll;
  margin-top: 16rem;

  &__img-parent {
    width: 25rem;
    height: 20rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--spacing-unit-x6);
    margin-top: var(--spacing-unit-x7);
  }

  &__img {
    width: 100%;
    height: auto;
  }

  .modal-header__close-button {
    padding: var(--spacing-unit-x2);
    top: var(--spacing-unit-x2);
    right: var(--spacing-unit-x2);
  }

  .modal-header__inner {
    border-bottom: unset;
  }

  &__parent {
    display: flex;
    flex-direction: column;
  }

  &__top-text {
    height: var(--spacing-unit-x7);
    color: var(--text-gray);
    font-size: var(--spacing-unit-x5);
    font-weight: var(--font-weight-medium);
    line-height: var(--spacing-unit-x7);
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--spacing-unit-x4);
  }

  &__lower-text {
    height: var(--spacing-unit-x10);
    width: 53rem;
    color: var(--light-gray) !important;
    font-size: 1.15rem;
    line-height: 1.75rem;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--spacing-unit-x8);
  }

  &__close-button {
    height: var(--spacing-unit-x6);
    width: 15rem;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: var(--spacing-unit-x8);
    line-height: 1.15rem;
  }
}
