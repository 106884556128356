.review-export-fields {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  &__header {
    > div:first-child {
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__file {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    background-color: var(--input-background);
    display: flex;
    align-items: center;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

    &-name {
      margin-left: var(--spacing-unit);
      margin-right: var(--spacing-unit-x4);
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
    }
  }

  &__status {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x3);
    flex-grow: 1;

    &-header {
      display: flex;
      gap: var(--spacing-unit-x2);
      margin-bottom: var(--spacing-unit-x2);
    }

    div {
      text-align: left;
    }

    &-container {
      display: flex;
      gap: var(--spacing-unit-x4);
    }

    &-icon {
      &-unmapped {
        fill: var(--remove-red);
      }
    }
  }
}
