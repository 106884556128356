.change-field-step-editor {
  position: relative;

  .loader {
    margin: 0 auto;
  }

  &__update-options {
    margin-top: var(--spacing-unit-x2);
    display: grid;
    column-gap: var(--spacing-unit-x2);
    grid-template-columns: 2fr 1fr;

    &-select {
      width: 100%;
      grid-column: 1;

      &-wider {
        grid-column: 1 / 3;
      }
    }

    &-input {
      &__container {
        margin-top: auto;
        grid-column: 2;
      }

      &-error {
        visibility: hidden;
        grid-column: 2;
        grid-row: 2;

        &-visible {
          visibility: visible;
        }
      }
    }
  }

  &__set-value-input-error {
    position: absolute;
    bottom: calc(var(--line-height-large) * -1);
    display: none;

    &-visible {
      display: block;
    }
  }

  &__more-options {
    margin-top: var(--spacing-unit-x3);

    &,
    &__content {
      width: 100%;
    }

    &-personalization {
      position: relative;

      &-button {
        align-self: flex-end;
       
        &:has(button[disabled]) > div {
          cursor: not-allowed;
        }
  
        button {
          padding: var(--spacing-unit);
          margin-bottom: var(--half-spacing-unit);

          svg {
            margin: 0 !important;
          }
        }

        button[disabled] {
          pointer-events: none;
          
          svg {
            fill: var(--light-gray) !important;
          }
        }
      }

      &__content {
        margin-top: var(--spacing-unit);
        display: flex;
        flex-direction: column;
      }
    }
  }
}
