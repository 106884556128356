.collapsible-menu-item-with-header {
  position: relative;
  padding-bottom: var(--spacing-unit);

  &__header {
    height: var(--spacing-unit-x8);
    border-bottom: calc(var(--half-spacing-unit) / 3) solid transparent;
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3) var(--spacing-unit) var(--spacing-unit-x4);
    position: sticky;
    top: 0;
    transition: all ease-in-out 200ms;
    background-color: var(--white);
    z-index: 1;

    &-open {
      height: var(--spacing-unit-x7);
    }

    &-text {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-grow: 1;
      gap: var(--spacing-unit-x2);
      white-space: nowrap;

      .caret-icon {
        width: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2));
        height: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2));
        padding: 0.375rem;
      }

      &-collapsed {
        cursor: default;
      }
    }

    & > svg {
      transition: transform var(--expand-timing) ease-out;
    }
  }

  & .sortable-folders .folder .row-actions {
    margin-right: var(--spacing-unit-x3);
  }

  & .menu-item__tags .row-actions {
    margin-right: var(--spacing-unit-x3);
  }

  & .menu-item {
    &__add-button,
    &__toggle-sales-users-button {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: var(--linear-80);

      &.button {
        height: var(--spacing-unit-x4);
        width: var(--spacing-unit-x4);
      }

      &:hover {
        background-color: var(--off-white-focus);
        border-radius: var(--legend-border-radius);

        & > svg {
          fill: var(--text-gray);
        }
      }
    }

    &__add-icon {
      &.svg {
        height: var(--spacing-unit-x2);
        width: var(--spacing-unit-x2);
      }
    }

    &__toggle-sales-users-button {
      &.button {
        height: var(--spacing-unit-x4);
        width: var(--spacing-unit-x4);
      }

      &-active:hover {
        background-color: var(--white);
      }
    }

    &__toggle-sales-users-icon {
      &.svg {
        height: var(--spacing-unit-x4);
        width: var(--spacing-unit-x4);
      }
    }
  }

  & > svg {
    fill: var(--light-gray);
  }

  &__scrolling {
    border-bottom: var(--standard-border);
  }

  &__custom-filter {
    border-bottom: none;
    position: unset;
  }
}
