.split {
  display: inline-flex;

  &__button {
    border-color: var(--border-gray);
    height: var(--spacing-unit-x6);

    &-delete_outline {
      border-color: var(--erase-hover-red);

      &:hover:not(:disabled) {
        border-color: var(--erase-hover-red);
      }

      &:hover:disabled {
        background-color: var(--off-white-hover-disabled);
        border-color: var(--border-gray);
      }
    }

    &-delete_outline,
    &-outline {
      &:disabled {
        border-color: var(--border-gray);
      }

      &:hover:not(:disabled) {
        .typography {
          color: var(--white);
        }
      }
    }

    &-outline {
      border-color: var(--text-teal);

      &:hover {
        border-color: var(--text-teal);

        &:not(:disabled) {
          border-color: var(--text-teal);
        }

        &:disabled {
          background-color: var(--off-white-hover-disabled);
        }
      }
    }

    &-primary {
      &:hover:not(:disabled) {
        background-color: var(--hover-teal);
      }
    }

    &-primary&-main {
      &:focus-visible {
        box-shadow: var(--box-shadow-split-button-focus);
      }
    }

    &-white {
      background-color: var(--white);

      &:disabled {
        background-color: var(--white);

        &:hover {
          background-color: var(--white);
          border-color: var(--border-gray);
        }

        .svg {
          fill: var(--button-gray) !important;
        }
      }
    }

    &-outline,
    &-white,
    &-delete_outline {
      &:disabled {
        cursor: not-allowed;

        .typography {
          color: var(--button-gray);
        }
      }
    }

    &:hover {
      background-color: var(--off-white-focus);
      border-color: var(--border-gray);
    }

    &:focus-visible,
    &:active:not(:disabled) {
      border-color: var(--dark-blue);
      outline: var(--inputV2-outline-border);
    }

    &-main {
      padding: calc(var(--spacing-unit-x4) / 3) calc(var(--spacing-unit-x8) / 3);
      border-radius: var(--standard-border-radius) 0 0 var(--standard-border-radius);

      &:focus-visible,
      &:active {
        position: relative;
        z-index: var(--z-index-9);
      }

      .svg {
        width: 1.3334rem;
        height: 1.3334rem;
      }
    }

    &-trigger {
      visibility: hidden;
    }

    &-toggle {
      padding: 0 var(--spacing-unit-x2);
      border: 0;
      border-radius: 0 var(--standard-border-radius) var(--standard-border-radius) 0;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        border: var(--standard-border);
        border-left: none;
        border-radius: 0 var(--standard-border-radius) var(--standard-border-radius) 0;
        z-index: var(--z-index-1);
        width: var(--spacing-unit-x6);
      }

      &:focus-visible::after,
      &:active:not(:disabled)::after {
        border-left: var(--standard-border-width) solid;
        border-color: var(--dark-blue);
        margin-left: calc(-1 * var(--spacing-unit) / 6);
        outline: var(--inputV2-outline-border);
      }

      &-primary {
        border-left: var(--split-button-inner-border);

        &:focus-visible {
          &::after {
            border: var(--input-border-focus);
            border-left: unset;
          }
        }

        &-open {
          background-color: var(--hover-teal);
        }

        &::after {
          border: 0;
        }
      }

      &-white {
        &-open {
          background-color: var(--button-back-gray);
        }
      }

      &-delete_outline {
        &-open {
          background-color: var(--erase-hover-red);

          .svg {
            fill: var(--white) !important;
          }
        }

        &::after {
          border-color: var(--erase-hover-red);
        }
      }

      &-delete_outline,
      &-outline {
        &:disabled {
          border-width: 0;

          &::after {
            border-color: var(--border-gray);
          }
        }

        &:hover:not(:disabled) {
          .svg {
            width: var(--spacing-unit-x2);
          }
        }
      }

      &-outline {
        width: var(--spacing-unit-x6);

        &-open {
          background-color: var(--text-teal);

          .svg {
            fill: var(--white) !important;
          }
        }

        &:not(:disabled)::after {
          border-color: var(--text-teal);
        }
      }

      .svg {
        width: var(--spacing-unit-x2);
        margin-right: 0;
      }
    }

    &-single {
      border-radius: var(--standard-border-radius);
    }
  }

  &__options {
    padding: calc(var(--spacing-unit-x4) / 3) 0;
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    background-color: var(--white);
    box-shadow: var(--drop-down-shadow);

    &__wrapper {
      margin-top: var(--spacing-unit);
    }
  }

  &__option {
    padding: var(--spacing-unit) var(--spacing-unit-x3);
    border-radius: 0;
    width: 100%;
    box-sizing: border-box;
    border: none;
    background-color: unset;

    &-selected {
      background-color: var(--lighter-teal);
    }

    &-label {
      width: 100%;
      text-align: left;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      background-color: unset;
    }
  }

  &__svg-container {
    display: flex;
    width: var(--spacing-unit-x4);
  }
}
