.api-access-keys {
  &__info-action {
    margin-bottom: var(--spacing-unit-x4);

    .info-action--wrapper-buttons {
      display: none;
    }

    .text-link {
      display: inline-flex;
      align-items: center;
    }
  }

  &__info-message {
    margin-right: var(--half-spacing-unit);
  }

  &__content {
    background-color: var(--white);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x4);
  }

  &__generate-key-content {
    background-color: var(--white);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    text-align: center;
    padding: var(--spacing-unit-x2);
    padding-bottom: var(--spacing-unit-x5);
  }

  &__key-info {
    margin-top: var(--spacing-unit);
  }

  &__generate-key-button {
    display: flex;
    gap: var(--spacing-unit);
    margin: var(--spacing-unit-x3) 0;
    background-color: var(--lighter-teal);
    border: var(--standard-border-width) solid var(--border-teal);

    &:hover {
      background-color: var(--teal-light);
    }
  }

  &__label {
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x2);
  }

  &__shield-icon {
    margin-bottom: var(--spacing-unit);
  }

  .tooltip__trigger {
    display: inline-block;
  }
}

/* stylelint-disable */
@media (max-width: 1220px) {
  /* stylelint-enable */
  .api-access-keys {
    &__info-action {
      .typography {
        display: inline;
      }

      .text-link {
        display: inline;
        margin-left: var(--spacing-unit);
      }
    }
  }
}
