.info-hover-header {
  &__title {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  &__subtitle {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit);
  }
}
