.custom-insights-results {
  display: block;
  
  &__loader {
    width: 100%;
  }
   
  &__last-updated-label {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
  }
}
