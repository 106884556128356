.tag-viewer {
  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x3);
    max-height: 22.5rem;
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    width: 25rem;
  }

  &__tags {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
    max-height: 17.5rem;
    position: relative;

    &-bottom {
      min-height: var(--half-spacing-unit);
      width: 100%;
    }

    &-gradient {
      background: linear-gradient(180deg, transparent 0%, var(--white) 100%);
      bottom: 0;
      height: var(--spacing-unit-x3);
      pointer-events: none;
      position: absolute;
      width: 100%;
    }

    .tag-manager-base-item {
      background-color: unset;
      cursor: unset;
    }
  }
}
