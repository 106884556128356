
.send-sms-step-detail {
  display: flex;
  align-items: flex-start;

  &__label {
    width: var(--spacing-unit-x15);

    .svg {
      position: relative;
      top: calc(var(--half-spacing-unit) / 3);
      left: var(--spacing-unit);
    }
  }

  &__message {
    flex: 1;
  }
}
