
.change-field-step-detail {
  > div {
    margin-bottom: var(--spacing-unit);
  }

  &__condition {
    &-email {
      display: inline;

      .svg {
        fill: var(--text-teal);
        position: relative;
        top: calc(var(--half-spacing-unit) / 3);
      }
    }

    &-welcome {
      display: inline;

      .svg {
        position: relative;
        top: calc(var(--half-spacing-unit) / 3);
      }
    }
  }

  &__operation {
    display: flex;
    margin-left: var(--spacing-unit-x4);

    > div {
      margin-right: calc(2rem / 12);

      &:not(:last-child) {
        flex-shrink: 0;
      }
    }
    
    &-error {
      margin: var(--standard-border-width) var(--half-spacing-unit) 0;
    }
  }
}
