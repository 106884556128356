.asset-select {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x2);

  &__default-launch:not(&__default-launch--disabled):focus,
  &__default-launch:not(&__default-launch--disabled):focus-visible,
  &__default-launch__button:focus-visible,
  &__button-launch:focus {
    border: var(--input-border-focus);
    box-shadow: var(--input-box-shadow-focus);
    outline: unset;
  }

  &__default-launch:focus-within {
    border: var(--input-border-focus);
  }

  .labelV2__container-margin-bottom {
    margin-bottom: unset;
  }

  &__helper-text {
    display: flex;
    gap: var(--spacing-unit);
    align-items: center;
  }

  &__default-launch {
    display: flex;
    justify-content: space-between;
    height: var(--spacing-unit-x6);
    border: var(--standard-border);
    background-color: var(--white);
    transition: border-color 0.2s ease-in-out 0s;
    border-radius: var(--standard-border-radius);
    cursor: pointer;
    user-select: none;

    &__button-container {
      display: flex;
      align-items: center;
    }

    &__button {
      text-decoration: none;
      padding: 0 var(--spacing-unit-x2);
      height: calc(var(--spacing-unit-x6) - 0.1667rem);
      display: flex;
      align-items: center;

      /** Avoids button shifting when focused **/
      border: var(--transparent-border);

      &--left {
        border-radius: none;
      }

      &--right {
        border-radius: var(--standard-border-radius);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:hover {
        text-decoration: none;
        background-color: var(--lighter-teal);
      }

      &[disabled] {
        background-color: unset;
        cursor: not-allowed;
      }
    }

    &:not(&--disabled):hover {
      border: var(--inputV2-border-hover);
    }

    &--disabled {
      background-color: var(--off-white-hover-disabled);
      cursor: not-allowed;
    }

    &--error {
      border: var(--program-step-border-error);
    }

    &__asset-label {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
      justify-content: center;
      padding: var(--spacing-unit) var(--spacing-unit-x2);
      overflow: hidden;

      &__icon {
        height: calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
        width: calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
        fill: var(--light-gray);

        &--placeholder {
          fill: var(--button-gray);
        }

        &--error {
          fill: var(--error);
        }

        &--disabled {
          fill: var(--button-gray);
        }
      }
    }

    &__replace-container {
      -webkit-box-align: center;
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-shrink: 0;
      box-sizing: border-box;

      &__divider {
        display: flex;

        &::before {
          content: '';
          border-left: var(--standard-border);
          margin: var(--spacing-unit) 0;
        }
      }
    }
  }

  &__button-launch {
    display: flex;
    align-items: center;
    background-color: unset;
    gap: var(--half-spacing-unit);

    &.button--outline {
      height: var(--spacing-unit-x6);
    }

    .button[disabled] {
      color: var(--button-gray);
  
      /* Overriding a Button component !important property */
      .svg {
        fill: var(--button-gray) !important;
      }
    }
  }
}
