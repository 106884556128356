.incoming-webhooks {
  display: block;

  &__header {
    gap: var(--spacing-unit-x2);

    .page-headline {
      display: flex;
      align-items: center;
    }
  }

  &__button-add {
    &-disabled {
      cursor: not-allowed !important;
      opacity: 0.5;

      &:hover {
        background: var(--teal);
      }
    }

    &-tooltip {
      max-width: 22rem;
    }
  }

  &__webhook-name-wrapper {
    align-items: center;
    display: flex;
  }

  &__webhook-status-wrapper {
    display: inline-block;
  }

  &__disabled {
    opacity: 0.5;
  }

  &__not-tested {
    .toggle {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__not-tested-tooltip {
    max-width: 23rem;
  }

  &__test-button {
    .svg {
      width: 1.333rem;
      height: 1.333rem;
      margin-right: var(--spacing-unit);
    }
  }

  &__webhook-name {
    align-content: start;
    display: block;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: var(--spacing-unit-x3);
  }

  &__webhook-description {
    padding-top: calc(var(--half-spacing-unit) / 2);
  }

  &__restricted {
    padding-top: var(--spacing-unit-x12);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);

    .typography__type-headline-page {
      max-width: unset;
    }
  }

  .tableV2-tbody__row-no-tested {
    .tableV2-row-action {
      background: linear-gradient(90deg, rgba(249, 249, 249, 0) 0%, var(--input-background) 2.5rem, var(--input-background) 100%);

      button:hover {
        background-color: var(--off-white-focus);
      }
    }

    .tableV2-row-drop-down-actions__open > button {
      background-color: var(--off-white-focus);
    }

    &:hover {
      background-color: var(--input-background);
    }
  }

  & .tableV2-tbody__row {
    > :first-child {
      padding: var(--spacing-unit-x2) 0 var(--spacing-unit-x2) var(--spacing-unit-x4) !important;
    }
  }
  
  &__drop-down-open {
    z-index: var(--z-index-99999);
  }
}
