.global-composer-header {
  position: sticky;
  top: 0;
}

@media (max-width: 72.25rem) {
  .global-composer-header-bottom {
    &__tabs {
      &__customer-care {
        &__list {
          top: var(--position-sticky-custom-care-header-small);
        }
      }
    }
  }
}
