.upgrade-assistant {
  &__log-button {
    white-space: nowrap;

    svg {
      margin-right: var(--spacing-unit);
    }
  }

  &__state {
    display: flex;
    align-items: center;
    gap: calc(var(--half-spacing-unit) + var(--spacing-unit));
    margin-bottom: var(--spacing-unit-x3);
  }
   
  &__progress {
    &-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: var(--spacing-unit-x2);
    }
    margin-bottom: var(--spacing-unit-x6);
  }

  &__section {
    margin-bottom: var(--spacing-unit-x4);
  }
   
  &__container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);

    &-teal-border {
      border-top: var(--thick-border-width) solid var(--teal) !important;
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
     
    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

      &-header {
        display: flex;
        align-items: center;
        gap: var(--half-spacing-unit);
      }
       
      &-text {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-unit);
      }
    }
  }
}
