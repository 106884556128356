.container {
  padding: var(--spacing-unit-x4);
  background-color: var(--white);
  border-radius: var(--standard-border-radius);
  border: var(--standard-border);

  &__wrapper + &__wrapper {
    margin-top: var(--spacing-unit-x4);
  }

  &__has-header {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: var(--spacing-unit-x2);
  }

  &__title {
    margin-bottom: var(--spacing-unit-x2);

    &-no-description {
      margin-bottom: var(--spacing-unit-x3);
    }
  }

  &__description {
    margin-bottom: var(--spacing-unit-x3);
  }

  &__no-bottom {
    margin-bottom: 0;
  }

  &__no-side-padding {
    padding-left: 0;
    padding-right: 0;
  }

  &__no-bottom-padding {
    padding-bottom: 0;
  }

  &__no-padding {
    padding: 0;
  }

  .section-headline {
    margin-top: 0;
  }

  &__header {
    padding: 0.66667rem 2rem;
    background-color: var(--light-theme-background-darker);
    border-top-left-radius: 0.333rem;
    border-top-right-radius: 0.333rem;
    border: var(--standard-border);
  }
}
