.landing-page-manage-urls {
  &__header {
    display: flex;

    &-breadcrumbs {
      gap: var(--spacing-unit);
    }
  }

  &__add-button {
    margin-left: auto;
    gap: calc(var(--spacing-unit-x5) / 3);
  }

  &__sub-header {
    max-width: 64rem;
    margin: var(--spacing-unit-x4) 0;
  }
}
