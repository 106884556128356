.modal-root {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-99999);
  justify-content: center;
  align-items: center;
  display: none;
  overflow: hidden;

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--overlay);
    opacity: 0.55;
    display: none;
  }

  &--visible {
    display: flex;
    width: 100vw;
    height: 100vh;

    .modal-root__background {
      display: block;
    }
  }
}

.modal {
  position: fixed;
  background-color: var(--white);
  min-width: 15rem;
  border-radius: 0.333rem;
  max-height: calc(100vh - var(--spacing-unit-x8));
  max-width: calc(100vw - var(--spacing-unit-x8));
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  &--clear {
    background-color: transparent;
  }

  &--show-overflow {
    overflow: visible;
  }

  &--large-header {
    justify-content: center;
    padding-top: var(--spacing-unit-x6);
  }

  .modal-header {
    flex: 1 1 auto;
  }

  .modal-sub-header {
    flex: 1 1 auto;
  }

  .modal-body {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x7);
    overflow: auto;
    height: 100%;

    &__inner {
      height: 100%;
    }
  }

  &--no-padding .modal-body {
    padding: 0;
  }

  &--padding-v2 {
    .modal-header__inner,
    .modal-footer__inner,
    .modal-body {
      padding-left: var(--spacing-unit-x4);
      padding-right: var(--spacing-unit-x4);
    }
  }

  &--custom-scroll-areas .modal-body,
  &--no-scroll .modal-body {
    overflow: hidden;
  }

  &--allow-full-screen {
    max-height: 100vh;
    max-width: 100vw;

    .modal__body {
      overflow: hidden;
      padding: 0;
    }
  }

  &--full-screen {
    height: 100vh;
    width: 100vw;
    max-height: unset;
    max-width: unset;
    border-radius: 0;

    .modal__body {
      overflow: hidden;
      padding: 0;
    }
  }

  .modal-footer {
    flex: 1 1 auto;
  }

  .modal-footer-action {
    display: flex;
    align-items: center;
    margin-right: auto;

    .button {
      margin-right: var(--spacing-unit-x4);
    }
  }
}
