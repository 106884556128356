
.numbered-list {
  &__title {
    border-bottom: var(--standard-border);
    margin-bottom: var(--spacing-unit-x2);
  }

  &__mark {
    background-color: unset;
  }

  &__not-top-20 {
    padding-left: var(--half-spacing-unit);
  }

  &__items {
    padding-left: var(--spacing-unit-x2);
  }
}
