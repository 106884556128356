.select-asset-v2 {
  width: 50rem;

  &__row-container {
    margin-top: var(--spacing-unit-x4);

    &-child {
      cursor: pointer;
      display: flex;
      gap: var(--spacing-unit-x3);
      align-items: center;
      position: relative;
      margin-left: var(--spacing-unit-x3);
      margin-top: var(--spacing-unit-x2);
      margin-bottom: var(--spacing-unit-x2);
      margin-right: var(--spacing-unit-x2);

      &-radio-button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;

        label {
          width: fit-content;
        }
      }
    }
  }

  .radio-card__svg {
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);

    .svg {
      top: var(--spacing-unit-x3);
    }
  }

  .radio-card__text {
    .typography:first-child {
      margin-bottom: 0;
    }
  }

  &__close-button {
    padding: 0.9167rem 0;
  }

  .button.select-asset-v2__save-button {
    padding: 0.6667rem var(--spacing-unit-x4);
    margin: 0;
    line-height: 1.6667rem;
  }

  &__header {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }

  .modal-body,
  .modal-footer__inner {
    padding: var(--spacing-unit-x4);
  }

  .modal-footer__inner {
    gap: var(--spacing-unit-x4);
  }

  &__radio-card {
    flex: 1 1 auto;
    gap: var(--spacing-unit-x4);
  }

  &__loader {
    width: 100%;
  }
}
