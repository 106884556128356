.customize-section {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-border-top {
      margin-top: var(--spacing-unit-x4);
      padding-top: var(--spacing-unit-x4);
      border-top: var(--standard-border);
    }
  }

  &__actions {
    display: flex;
    margin-top: var(--spacing-unit-x6);

    .button--primary {
      margin-right: var(--spacing-unit-x4);
    }
  }

  &__edit {
    min-width: var(--spacing-unit-x13);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-unit);

    & .button {
      height: var(--spacing-unit-x5);
    }

    .svg {
      fill: var(--light-gray);
    }
  }
}
