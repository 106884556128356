.affected-contacts-data-card {
  &__contacts-affected {
    display: flex;
    gap: var(--spacing-unit-x2);
    align-items: flex-end;


    &-source__trigger-wrapper {
      display: contents;
    }
  }

  .divided-content-data-card {
    &__left-content {
      width: calc(100% - 7.75rem);
    }
  }
}
