
.sync-results-table {
  &__table {
    width: 100%;

    .th {
      padding-right: 3rem;

      &:last-child {
        padding-right: var(--spacing-unit-x8);
      }

      &:nth-child(2) {
        padding-right: 2rem;
      }
    }
  }
}
