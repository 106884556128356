.crm-date-field {
  .radio__container {
    margin: unset;
  }

  .radiogroup--vertical {
    gap: var(--spacing-unit-x2);
  }

  &__radio {
    &-with-options {
      width: 100%;

      &__options-container {
        width: 100%;

        &__content {
          flex-grow: 1;
        }
      }
    }
  }
}
