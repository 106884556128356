.asset-picker-modal {
  width: var(--asset-picker-modal-width);
  height: var(--asset-picker-modal-height);

  &__body {
    display: flex;
    flex-direction: row;
    height: 100%;

    &__info-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

      &--hidden {
        display: none;
      }
    }
  }

  &__table {
    &--hidden {
      display: none;
    }
  }
}
