.html-media-card {
  width: 100%;
  min-width: 33rem;
  height: 21rem;
  display: flex;
  flex-direction: column;
  border-radius: var(--standard-border-radius);

  .media-card__body {
    position: relative;
    height: 100%;
    padding: var(--spacing-unit-x4) var(--spacing-unit-x4) 0;
    overflow: hidden;

    &--disabled .html-media-card__body-iframe {
      pointer-events: none;
    }

    .html-media-card__body-iframe {
      width: 100%;
      height: 100%;
      border: none;

      &--hidden {
        position: absolute;
        opacity: 0;
      }
    }
  }
}
