
.edit-add-msdynamics-step {
  width: 50rem;
  min-height: 15rem;

  &__not-current {
    margin-top: var(--spacing-unit-x2);
  }

  &__row {
    margin-bottom: var(--spacing-unit-x2);

    .input,
    .select {
      width: 28.75rem;
    }
  }
}
