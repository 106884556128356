.landing-pages-listing-page-container {
  &__create-landing-page-button {
    height: var(--spacing-unit-x6);

    &-disabled {
      &.button:hover {
        cursor: not-allowed;
      }
    }

    .svg {
      fill: var(--white);
      margin-right: var(--spacing-unit);
    }
  }

  &__landing-page-name {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: calc(-1 * var(--half-spacing-unit));

    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;
      padding-left: var(--spacing-unit);
      gap: var(--spacing-unit);
    }

    &-typography {
      display: flex;
      align-items: center;
      overflow: hidden;

      .svg {
        flex-shrink: 0;
      }
    }

    .info-hover-card {
      visibility: hidden;

      &__visible {
        visibility: visible;
      }
    }
  }

  &__radio {
    .radio__label {
      display: flex;
    }
  }

  &__views,
  &__clicks {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-unit);
    align-items: center;

    &__info .svg {
      display: flex;
      align-items: center;
    }
  }

  .base-tag {
    max-width: var(--spacing-unit-x8);
  }
}
