.embed-codes {
  margin: 0 calc(-1 * var(--spacing-unit-x4));

  &__empty {
    height: var(--spacing-unit-x12);
    margin: 0 var(--spacing-unit-x4);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    background-color: var(--input-background);
    border-radius: var(--standard-border-radius);
  }

  &__create-code {
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x4) var(--spacing-unit-x4);
    gap: var(--spacing-unit);
    height: var(--spacing-unit-x6);

    & &-svg {
      fill: var(--text-gray);
    }
  }
}
