.preview-logo-modal {
  width: 56rem;

  & .modal-header__inner {
    padding-right: 2.33333rem;
  }

  &__body {
    &-error {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: var(--status-warning);
      border-radius: var(--standard-border-radius);
      padding: var(--spacing-unit-x6);

      .section-headline {
        margin: var(--spacing-unit-x3) 0 var(--spacing-unit-x2);
      }
  
      &-message {
        width: var(--upload-image-error-message-width);
        margin: 0 auto var(--spacing-unit-x3);
  
        .typography {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
