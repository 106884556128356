.import-contacts-v2 {
  scrollbar-gutter: stable;

  &__import-log-button {
    white-space: nowrap;

    svg {
      margin-right: var(--spacing-unit);
    }
  }

  &__upload-bullet-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(15rem, 1fr));
    grid-column-gap: var(--spacing-unit-x10);
    margin: var(--spacing-unit-x5) var(--spacing-unit-x9) 0;
  }

  &__drop-zone-wrapper {
    padding: var(--spacing-unit-x9) var(--spacing-unit-x9) var(--spacing-unit-x10) var(--spacing-unit-x9);
  }

  &__dropzone-text {
    display: flex;
    justify-content: center;
  }

  &__selected-file {
    margin: calc(124rem / 12) auto;
    width: 42rem;
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-top: var(--spacing-unit-x4);
  }

  &__header {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__dropdown-top {
    margin-left: var(--spacing-unit-x2);
  }

  &__progress-bar {
    padding: 0 var(--spacing-unit-x23) var(--spacing-unit-x6);
  }
}
