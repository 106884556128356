.landing-page-composer-custom-java-script {
  padding: var(--spacing-unit-x4) var(--spacing-unit-x3);

  &__title {
    margin-bottom: var(--spacing-unit);
  }

  &__desc {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__selected-media {
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x2);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
  }

  &__button {
    display: flex;
    gap: var(--spacing-unit);
    margin-bottom: var(--spacing-unit-x4);
  }

  &__editor {
    border-top: var(--standard-border);
    border-right: var(--standard-border);

    &-title {
      margin-bottom: var(--spacing-unit);
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: var(--spacing-unit-x2);
      border: var(--standard-border);
      border-bottom-left-radius: var(--standard-border-radius);
      border-bottom-right-radius: var(--standard-border-radius);
      background-color: var(--white);

      &-actions {
        display: flex;

        &-copy {
          &:disabled {
            color: var(--text-gray);
            cursor: not-allowed;
            
            & .typography {
              cursor: not-allowed;

              &:hover {
                color: var(--text-gray) !important;
              }
            }
          }
        }
      }

      &-undo,
      &-redo {
        fill: var(--text-gray);

        &--disabled {
          svg {
            fill: var(--button-gray);
          }
        }
      }
    }
  }

  &__ace-editor {
    border: var(--standard-border);
    border-top-left-radius: var(--standard-border-radius);
    border-top-right-radius: var(--standard-border-radius);
  }
}
