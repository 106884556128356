.email-composer-preview-sidebar {
  max-width: calc(var(--spacing-unit-x20) * 4);
  min-width: calc(var(--spacing-unit-x20) * 3);
  flex: max-content;
  border-left: var(--standard-border);
  display: flex;
  flex-direction: column;

  &__tabs {
    display: flex;
    flex-direction: column;
    flex: max-content;
    overflow: auto;

    &__list {
      min-width: auto;
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4) 0;
      border-bottom: var(--standard-border-dark);
    }

    &-tab {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);

      &-pill {
        background-color: var(--remove-red);
        border-radius: calc(var(--spacing-unit) + var(--half-spacing-unit));
        padding: 0 calc(var(--spacing-unit) + var(--half-spacing-unit));

        .typography {
          color: var(--white) !important;
        }
      }
    }

    & .tabs-ao__panel {
      margin: 0;
      overflow: auto;
    }
  }

  &__email-size {
    border-top: var(--standard-border-dark);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

    &-title {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }

    .single-line-chart {
      &__values {
        margin-bottom: calc(var(--spacing-unit-x3) / 2);
      }

      &__bar {
        height: 0.833rem;
        border-radius: var(--extra-large-border-radius);
        overflow: hidden;

        &-actual {
          border-radius: var(--extra-large-border-radius) 0 0 var(--extra-large-border-radius);
        }
      }
    }
  }
}
