.forms-join-view-listing-page-container {
  display: block;

  &__loader {
    width: 100%;
  }

  &__action-button {
    display: flex;
    padding: var(--spacing-unit) var(--spacing-unit-x3);
  }
   
  &-crm-error-icon {
    display: flex;
  }

  .listing-page__content-tableV2 {
    padding-top: var(--spacing-unit-x4);
  }
}
