.custom-link__form {
  max-width: calc(var(--spacing-unit-x8) * 10);

  &__header {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    display: flex;
    align-items: center;
    padding: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x4);

    &-eye-svg {
      fill: var(--text-gray);
      margin-right: var(--spacing-unit-x2);
    }

    .tooltip__trigger {
      margin-left: auto;
    }
  }

  &__content {
    &-row {
      .label {
        margin: var(--spacing-unit-x2) 0;
      }

      .tooltip__trigger--inline {
        display: block;
      }
    }

    .label-with-svg-tooltip__change-on-hover {
      .svg {
        fill: var(--button-gray);

        &:hover {
          fill: var(--text-gray);
        }
      }
    }
  }

  &__actions {
    display: flex;
    margin-top: var(--spacing-unit-x4);

    .button--primary {
      margin-right: var(--spacing-unit-x4);
    }
  }
}
