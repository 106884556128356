
.wait-step-detail {
  &__button {
    margin-top: var(--line-height-smallest);
    padding: var(--line-height-smallest);

    .svg {
      margin-bottom: 0.17rem;
    }
  }
}
