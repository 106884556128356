.assign-managers-modal {
  width: 50rem;
  height: 50rem;

  &__header {
    &__extra {
      margin-left: auto;
    }
  }

  .tableV2 {
    margin-top: var(--spacing-unit);

    &__empty {
      border: none;
    }
  }

  &__footer {
    .modal-footer__inner {
      justify-content: space-between;
    }

    &-buttons {
      display: flex;
    }
  }

  &__submit-button {
    &.button {
      height: var(--spacing-unit-x6);
    }

    &-submitting.button {
      padding-top: 0;
      padding-left: var(--spacing-unit-x6);
      padding-right: var(--spacing-unit-x6);
    }

    &-svg.svg {
      height: var(--spacing-unit-x4);
      width: var(--spacing-unit-x4);
      color: var(--white);
    }

    &-loader {
      margin-bottom: var(--half-spacing-unit);
    }
  }
}
