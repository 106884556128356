
.paginator {
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-unit-x3) 0;
  min-width: calc(var(--positioning-min-width) - var(--spacing-unit-x8));
  max-width: calc(var(--positioning-max-width) - var(--spacing-unit-x8));

  &__left-controls,
  &__right-controls {
    display: flex;
    align-items: center;
  }

  &__records {
    margin-right: var(--spacing-unit-x2);
  }

  &__results-per-page {
    width: var(--spacing-unit-x10);
    height: var(--spacing-unit-x6);
    font-size: var(--input-font-size);
    line-height: var(--line-height-large);
    padding: 0.667rem;
    border-radius: var(--checkbox-border-radius);
  }

  &__page {
    width: var(--spacing-unit-x10);
    height: var(--spacing-unit-x6);
    font-size: var(--input-font-size);
    line-height: var(--line-height-large);
    padding: 0.165rem 0.667rem 0 0.667rem;
    border-radius: var(--checkbox-border-radius);
    margin: 0 var(--spacing-unit);
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__nav-button {
    padding: 0.625rem;

    &:focus-visible {
      box-shadow: var(--keyboard-focus-box-shadow);
    }

    svg {
      fill: var(--light-gray);
      cursor: pointer;
      display: block;

      &:hover {
        fill: var(--text-gray);
      }
    }

    &[disabled] {
      pointer-events: none;

      svg {
        fill: var(--button-gray);
        cursor: disabled;
      }
    }
  }

  &__page-text-left {
    margin-left: 1.25rem;
  }

  &__page-text-right {
    margin-right: 1.25rem;
  }
}
