.create-edit-embed-code-modal {
  width: 50rem;

  &__info {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__label-external {
    display: flex;
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit);
    align-items: center;

    &-typo {
      width: var(--spacing-unit-x13);
    }
  }
}
