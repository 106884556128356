.successfully-upgraded-modal {
  width: 40rem;

  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    justify-content: center;
    align-items: center;
  }

  img {
    height: 15rem;
    width: 21rem;
  }

  &__buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x2) 0;

    .button {
      width: 25rem;
    }
  }
}
