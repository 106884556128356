.time-zone-select-v2 {
  display: block;

  &__trigger {
    display: flex;
    align-items: center;
    border-radius: calc(var(--spacing-unit-x4) / 4);

    &:hover {
      cursor: pointer;
    }

    &-disabled {
      cursor: not-allowed;

      &__text {
        color: var(--button-gray);
      }
    }

    &-svg {
      fill: var(--tab-gray);
      height: 1.334rem;
      width: 1.334rem;

      &-small {
        fill: var(--text-gray);
        height: 0.417rem;
        width: 0.667rem;
      }
    }

    &:focus-visible {
      outline: var(--standard-border-dark-blue);
      box-shadow: 0 0 0 var(--half-spacing-unit) var(--focus-light-teal);
    }

    &:hover:not(&-disabled) {
      & .svg {
        fill: var(--hover-create-option-icon);
      }

      & .typography {
        color: var(--hover-create-option-icon);
      }
    }
  }

  &__svg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);

    &-margin {
      margin-right: var(--half-spacing-unit);
    }
  }

  &__time-tooltip {
    &__trigger-wrapper {
      width: 9.75rem;
    }
  }

  &__selectV2-popover {
    & div[data-radix-popper-content-wrapper] {
      & div[data-align='start'] {
        width: 25rem;
      }
    }
  }
}
