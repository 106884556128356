.dynamic-list {
  &__header {
    padding-bottom: var(--spacing-unit-x2);
    display: flex;
    border-bottom: var(--standard-border);
    margin: 0 4.25rem var(--spacing-unit-x2) calc(22rem / 12);
    gap: var(--spacing-unit-x2);
  
    &-item {
      border-radius: var(--small-border-radius);
      padding: calc(4rem / 12) var(--spacing-unit-x2);
      flex: 1 1 0;
     
      &:not(&-toggle):not(&-crm) {
        background-color: var(--hover-row-background-teal);

        > div {
          color: var(--text-teal);
        }
      }
  
      &-crm {
        background-color: var(--background-list-header);

        > div {
          color: var(--dark-blue);
        }
      }

      &-toggle {
        width: var(--spacing-unit-x15);
        flex: unset;
      }
    }
  }

  &__list-row {
    &:last-child {
      .dynamic-list-row__list-toggle > button {
        display: none;
      }
    }
  }
}
