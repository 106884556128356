.selectv2 {
  &__label {
    display: flex;
    align-items: center;
    gap: 0.333rem;
    margin-top: var(--spacing-unit);

    .label {
      margin: 0;
    }

    &-tooltip__trigger-wrapper {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);

    &-disabled {
      cursor: not-allowed;
    }

    & .custom-select-container__trigger-wrapper {
      overflow: visible;
    }
      
    &-has-counter {
      .selectv2__value-container {
        flex-wrap: nowrap;
        white-space: nowrap; 
      }
    }
  }

  &__control {
    min-height: var(--spacing-unit-x4) !important;
    background-color: var(--white) !important;
    border-color: var(--border-gray) !important;
    cursor: pointer !important;
    transition: all 200ms ease-in-out 0s !important;
    align-items: stretch !important;
    outline: none !important;

    &:hover:not(.selectv2__control-menu-open) {
      border-color: var(--button-gray) !important;
    }

    &:not(:has(.selectv2__clear-indicator)) .selectv2__indicator-separator {
      margin-left: var(--spacing-unit-x2);
    }

    &-loader {
      height: calc(200rem / 12) !important;
    }

    &-focus {
      border: var(--standard-border-dark-blue) !important;
    }

    &-menu-open:not(.selectv2__control-searchable) {
      border: var(--standard-border-dark-blue) !important;
      box-shadow: var(--date-picker-input-shadow) !important;

      & .selectv2__clear-indicator > svg,
      & .selectv2__indicator > svg {
        fill: var(--gray) !important;
      }

      &-error {
        border-color: var(--remove-red) !important;
        box-shadow: none !important;
      }
    }

    &-paginator {
      width: fit-content;
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--spacing-unit) 0 var(--spacing-unit) var(--spacing-unit-x2);

      &-default {
        color: var(--text-gray);
        fill: var(--text-gray);
      }

      &-error {
        color: var(--error-text);
        fill: var(--error-text);
      }
    }

    &-dots {
      width: auto;
      height: auto;
      padding: 0;

      &--multi {
        height: var(--spacing-unit-x5);
      }
    }

    &--is-disabled {
      background-color: var(--off-white-hover) !important;
    }
  }

  &__clear-indicator {
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);
    border: none;
    border-radius: var(--spacing-unit-x2);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease-in-out 0s, border 0s, margin 0s, padding 0s, outline 0s !important;

    & > svg {
      fill: var(--tab-gray);
      transition: all 200ms ease-in-out 0s !important;
    }

    &-container {
      height: var(--spacing-unit-x6);
      padding: calc(var(--spacing-unit-x2) / 4) var(--spacing-unit) !important;
      align-items: center;

      &:hover,
      &:focus {
        outline: none;

        & .selectv2__clear-indicator {
          background-color: var(--chip-base);
          padding: var(--spacing-unit) !important;

          & > svg {
            fill: var(--text-gray) !important;
          }
        }
      }

      &:focus {
        & .selectv2__clear-indicator {
          border: var(--hover-indicator-border);
          box-shadow: var(--indicator-focus-box-shadow);
          padding: calc((var(--spacing-unit-x8)) / 12) !important;
        }
      }
    }

    &-with-dropdown-indicator-hidden {
      margin-right: var(--spacing-unit-x2) !important;

      &:hover,
      &:focus {
        margin-right: var(--spacing-unit) !important;
      }
    }
  }

  &__error {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit);
    height: var(--spacing-unit-x3);

    & svg {
      color: var(--error-text);
      fill: var(--error-text);
    }
  }

  &__indicator {
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);
    cursor: pointer !important;
    transition: all 200ms ease-in-out 0s, border 0s, border-radius 0s, margin 0s, padding 0s !important;
    border-radius: var(--spacing-unit-x2);
    display: flex;
    justify-content: center;
    align-items: center;

    &-container {
      align-items: center;
      display: flex !important;
      justify-content: center;
      height: var(--spacing-unit-x6);
      padding: unset !important;
      width: var(--spacing-unit-x6);

      &:hover,
      &:focus {
        outline: none;

        & .selectv2__indicator {
          & > svg {
            fill: var(--text-gray) !important;
          }
        }
      }

      &:focus {
        outline: none;

        & .selectv2__indicator {
          background-color: var(--hover-indicator-background);
          border: var(--hover-indicator-border);
          border-radius: 50%;
          box-shadow: var(--indicator-focus-box-shadow);
          box-sizing: border-box;
          outline: none;

          & > svg {
            fill: var(--text-gray) !important;
          }
        }
      }
    }

    & > svg {
      fill: var(--tab-gray) !important;
      transition: all 200ms ease-in-out 0s !important;
    }

    &-separator {
      background-color: var(--border-gray) !important;
    }
  }

  &__input {
    width: auto;
    overflow: hidden;

    & > input {
      caret-color: var(--dark-blue);
      color: var(--text-gray) !important;
      font-family: var(--font-regular) !important;
      font-size: var(--large-font-size) !important;
      font-weight: var(--font-weight-medium-light) !important;
      line-height: var(--line-height-large) !important;
      opacity: 1 !important;
    }

    &-container-error {
      border-color: var(--remove-red) !important;
    }
  }

  &__loading-indicator {
    color: var(--teal) !important;
  }

  &__menu {
    box-shadow: var(--media-card-box-shadow) !important;
    margin-top: calc(var(--spacing-unit-x16) / 12) !important;

    .custom-menu-list {
      box-shadow: var(--media-card-box-shadow);
      
      &__search-input-wrapper {
        display: flex;
        padding: var(--spacing-unit-x2);
        border: var(--standard-border);
        border-radius: var(--standard-border-radius);
  
        &-no-bottom-border {
          border-bottom: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &-with-search {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
       
      &-with-create {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
       
      &__search-input-content {
        display: flex;
        width: 100%;
        gap: var(--spacing-unit-x2);
        border-radius: var(--standard-border-radius);
        box-shadow: var(--date-picker-input-shadow) !important;
        border: var(--standard-border-dark-blue) !important;
        overflow: hidden;
        padding-right: var(--spacing-unit-x2);
        height: var(--spacing-unit-x6);
  
        input {
          outline: none;
          width: 100%;
          padding: var(--spacing-unit) 0;
          border: 0;
          font-size: var(--large-font-size);
          line-height: var(--line-height-large);
        }
      }
    }

    &-with-footer {
      .custom-menu-list {
        box-shadow: none;
      }
    }

    &-list {
      background-color: var(--white);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
      min-height: var(--spacing-unit-x9);
      min-width: 20rem;
      padding: calc(var(--spacing-unit-x16) / 12) 0 calc(var(--spacing-unit-x16) / 12) 0 !important;
      transition: all 0.2s ease-in-out 0s !important;

      &--groups-without-label {
        padding-top: 0 !important;
      }

      & > .selectv2__group {
        padding-bottom: 0;
      }

      &:has(.selectv2__option-new-container) {
        padding-bottom: 0 !important;

        & .selectv2__option-last {
          margin-bottom: calc(var(--spacing-unit-x16) / 12);
        }
      }

      &:has(.selectv2__no-options-message) {
        padding: 0 !important;
      }

      &-loader {
        height: calc(200rem / 12) !important;
      }

      &-with-footer {
        border-bottom: none;
      }
    }

    &-list-paginator,
    &-paginator {
      min-width: unset;
      width: fit-content !important;
    }

    &-portal {
      z-index: var(--z-index-single-select-dropdown) !important;

      &-inside-modal {
        z-index: 100000 !important;
      }
    }
  }

  &__no-options-message {
    padding: var(--spacing-unit-x4);

    & .empty-listing {
      &__headline,
      &__text {
        color: var(--text-gray);
      }

      &__text {
        width: 20.83333rem;
      }

      &__medium {
        height: var(--spacing-unit-x16);
        width: var(--spacing-unit-x20);
      }
    }

    &-empty-menu .empty-listing__text {
      width: unset;
    }
  }

  &__option {
    align-items: center;
    cursor: pointer !important;
    display: flex !important;
    gap: var(--spacing-unit-x2);
    justify-content: space-between !important;
    min-height: var(--spacing-unit-x6);
    padding: 0 var(--spacing-unit-x2) 0 var(--spacing-unit-x4) !important;
    transition: all 200ms ease-in-out 0s !important;

    &-large-padding {
      padding: var(--spacing-unit-x2) var(--spacing-unit-x3) !important;
    }

    &-disabled {
      cursor: not-allowed !important;
      background-color: unset !important;
      opacity: 0.5;
    }

    &:hover {
      background-color: var(--selectable-row-hover);
    }

    &-not-focused {
      background-color: var(--white) !important;
    }

    &-new {
      gap: var(--spacing-unit-x3);
      padding: calc(var(--spacing-unit-x16) / 12) var(--spacing-unit-x3) !important;
      word-break: break-word;

      &-create-item {
        align-items: center;
        display: flex !important;
        gap: var(--spacing-unit-x2);
      }

      &-container {
        .selectv2__menu-list:has(.selectv2__option-selectable) & {
          bottom: 0;
          background-color: var(--white);
          border-top: var(--standard-border);
          padding-bottom: calc(var(--spacing-unit-x16) / 12);
          padding-top: calc(var(--spacing-unit-x16) / 12);
          position: sticky;
        }
      }

      &-enter {
        align-items: center;
        align-self: baseline;
        display: flex;
        flex-shrink: 0;
        height: var(--spacing-unit-x4);
        justify-content: center;
      }

      &-icon {
        align-items: center;
        align-self: baseline;
        background-color: var(--chip-base);
        border-radius: var(--spacing-unit-x2);
        display: flex;
        justify-content: center;
        line-height: calc(var(--spacing-unit-x2) / 12);
        padding: var(--spacing-unit);
        transition: all 200ms ease-in-out 0s;

        & > svg {
          transition: all 200ms ease-in-out 0s;
        }
      }

      &-truncated {
        word-break: normal;
      }
    }

    &-right-side-container {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &-selected {
      background-color: var(--lighter-teal) !important;
      box-shadow: var(--selected-option-box-shadow) !important;
      color: var(--text-gray) !important;

      &:hover {
        background-color: var(--hover-row-button-background-teal) !important;
      }

      &-icon-container {
        align-items: center;
        display: flex;
        min-height: var(--spacing-unit-x6);
        justify-content: center;
        width: var(--spacing-unit-x6);
      }
    }

    &-truncated .selectv2__option-value {
      word-break: normal;
    }

    &-value {
      padding: calc(var(--spacing-unit-x16) / 12) 0;
      word-break: break-word;

      &--subtext-right {
        display: flex;
        flex-direction: row;
        gap: var(--spacing-unit);
        align-items: center;
      }
    }

    &-value-paginator {
      word-break: keep-all;
    }
  }

  &__placeholder {
    display: flex;
  }

  &__single-value-paginator {
    margin: 0 !important;
    text-overflow: unset !important;

    &-has-icon {
      align-items: center;
      display: flex;
      gap: var(--spacing-unit-x2);
    }
  }

  &__multi-value {
    display: flex !important;
    background-color: var(--chip-base) !important;
    border-radius: 1rem !important;
    flex-shrink: 0;
  
    &.hidden {
      display: none !important;
    }
    
    &.only-chip {
      flex-shrink: unset;
    }
  }

  &__group-heading {
    display: flex !important;
    flex-direction: row;
    gap: var(--spacing-unit-x2);
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    padding: 0 1.5rem 0.666rem 2rem !important;

    &:first-of-type {
      margin-top: 0.333rem;
    }

    &--full-width {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__label {
      white-space: nowrap;
    }

    &__divider {
      flex: 1 1;
      border-top: var(--standard-border);

      &--full-width {
        padding-bottom: calc(var(--spacing-unit-x2) * 0.584);
        border: unset;
        border-top: var(--standard-border);
      }
    }

    &__badge {
      margin: unset;
    }

    &__tooltip-trigger {
      color: var(--text-teal);
      font-weight: var(--font-weight-bold);
      font-size: var(--medium-font-size);
    }
  }

  &__value-container {
    justify-content: flex-start;
    padding: 0 var(--spacing-unit-x2) !important;

    &-no-clearable {
      padding-right: 0 !important;
    }
  }

  &__indicators-container {
    &--multi {
      align-items: flex-start !important;

      & .selectv2__indicator-separator {
        height: 1.6rem;
      }
    }

    &--hover {
      &.selectv2__indicators:hover {
        background-color: var(--lighter-teal);
        border-radius: var(--standard-border-radius);
      }
    }
  }
}
