.attendance-and-conversion-rate-line-chart {
  display: block;

  &__values {
    align-items: center;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: var(--spacing-unit);
  }

  &__bar {
    position: relative;
    height: var(--spacing-unit-x2);

    &-total {
      background-color: var(--border-gray);
      width: 100%;
      height: inherit;
      position: absolute;
      border-radius: var(--extra-large-border-radius);

      &:hover {
        cursor: default;
      }
    }

    &-actual {
      width: 100%;
      border: var(--tag-color-border);
      background-color: var(--teal);
      height: inherit;
      border-radius: var(--extra-large-border-radius);

      &-wrapper {
        height: inherit;
        position: absolute;
        border-radius: var(--extra-large-border-radius);
      }

      &:hover {
        cursor: default;
      }

      &-hidden {
        display: none;
      }
    }
  }

  &__loader {
    width: 100%;
    height: 100%;
  }
}
