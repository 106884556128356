.meta-data-pane {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-unit-x4);
  }

  &__fields {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);

    &-textarea {
      height: var(--spacing-unit-x16);
    }
  }

  &__upload-image {
    &-disabled {
      pointer-events: none;
    }

    &-container {
      width: 50%;

      &-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: var(--spacing-unit);

        label {
          margin: 0;
        }
      }
    }

    img {
      max-height: 22rem;
    }
  }

  &__preview {
    width: 50rem;

    &-image {
      max-width: 100%;
      max-height: 26rem;
    }

    &-meta-name {
      text-align: start;
      color: var(--medium-blue);
    }

    &-meta-url {
      color: var(--comment-line-green);
    }
  }
}
