.section-with-header {
  position: relative;

  &__header {
    display: flex;
    gap: var(--spacing-unit-x2);
    align-items: center;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    position: sticky;
    top: 0;
    background-color: var(--white);

    > :first-child {
      flex-shrink: 0;
    }

    &::after {
      content: '';
      display: inline-block;
      border-bottom: var(--standard-border);
      width: 100%;
    }
  }
}
