.add-contacts-from-crm {
  scrollbar-gutter: stable;

  &__subheader {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__sync-now-modal-body-text {
    padding-bottom: var(--spacing-unit-x4);
  }
}
