
* {
  box-sizing: border-box;
}

body,
html {
  font-family: var(--font-regular);
  font-weight: normal;
  font-style: normal;
  font-size: 12px; /* stylelint-disable-line */
  line-height: var(--spacing-unit-x2);
  background-color: var(--white);
  overflow: hidden;
  padding: 0;
  margin: 0;
  color: var(--text-gray);
}

input,
select,
textarea,
button {
  font-family: inherit;
}

button {
  font-weight: normal;
  font-style: normal;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  font-size: var(--large-font-size);
  line-height: var(--spacing-unit-x3);
  color: var(--text-gray);

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  label {
    cursor: pointer;
  }
}

a {
  color: var(--gray);
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: var(--text-gray);
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
}

button:focus,
label:focus {
  outline: none;
}

a:hover label {
  cursor: pointer;
}

button:focus > label,
a:focus > label {
  cursor: pointer;
  box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
}

@custom-media --breakpoint-sm (min-width: 720px);
@custom-media --breakpoint-mobile-only (max-width: 600px);

/* util classes positioning */
.push-left-none { margin-right: 0; }
.push-right-none { margin-left: 0; }
.push-down-none { margin-top: 0; }
.push-up-none { margin-bottom: 0; }
.push-left-half { margin-right: var(--half-spacing-unit); }
.push-right-half { margin-left: var(--half-spacing-unit); }
.push-down-half { margin-top: var(--half-spacing-unit); }
.push-up-half { margin-bottom: var(--half-spacing-unit); }
.push-left { margin-right: var(--spacing-unit); }
.push-right { margin-left: var(--spacing-unit); }
.push-down { margin-top: var(--spacing-unit); }
.push-up { margin-bottom: var(--spacing-unit); }
.push-left-x2 { margin-right: var(--spacing-unit-x2); }
.push-right-x2 { margin-left: var(--spacing-unit-x2); }
.push-down-x2 { margin-top: var(--spacing-unit-x2); }
.push-up-x2 { margin-bottom: var(--spacing-unit-x2); }
.push-left-x3 { margin-right: var(--spacing-unit-x3); }
.push-right-x3 { margin-left: var(--spacing-unit-x3); }
.push-down-x3 { margin-top: var(--spacing-unit-x3); }
.push-up-x3 { margin-bottom: var(--spacing-unit-x3); }
.push-left-x4 { margin-right: var(--spacing-unit-x4); }
.push-right-x4 { margin-left: var(--spacing-unit-x4); }
.push-down-x4 { margin-top: var(--spacing-unit-x4); }
.push-up-x4 { margin-bottom: var(--spacing-unit-x4); }
.push-left-x5 { margin-right: var(--spacing-unit-x5); }
.push-right-x5 { margin-left: var(--spacing-unit-x5); }
.push-down-x5 { margin-top: var(--spacing-unit-x5); }
.push-up-x5 { margin-bottom: var(--spacing-unit-x5); }
.push-left-x6 { margin-right: var(--spacing-unit-x6); }
.push-right-x6 { margin-left: var(--spacing-unit-x6); }
.push-down-x6 { margin-top: var(--spacing-unit-x6); }
.push-up-x6 { margin-bottom: var(--spacing-unit-x6); }

.inset-left-none { padding-right: 0; }
.inset-right-none { padding-left: 0; }
.inset-down-none { padding-top: 0; }
.inset-up-none { padding-bottom: 0; }
.inset-left-half { padding-right: var(--half-spacing-unit); }
.inset-right-half { padding-left: var(--half-spacing-unit); }
.inset-down-half { padding-top: var(--half-spacing-unit); }
.inset-up-half { padding-bottom: var(--half-spacing-unit); }
.inset-left { padding-right: var(--spacing-unit); }
.inset-right { padding-left: var(--spacing-unit); }
.inset-down { padding-top: var(--spacing-unit); }
.inset-up { padding-bottom: var(--spacing-unit); }
.inset-left-x2 { padding-right: var(--spacing-unit-x2); }
.inset-right-x2 { padding-left: var(--spacing-unit-x2); }
.inset-down-x2 { padding-top: var(--spacing-unit-x2); }
.inset-up-x2 { padding-bottom: var(--spacing-unit-x2); }
.inset-left-x3 { padding-right: var(--spacing-unit-x3); }
.inset-right-x3 { padding-left: var(--spacing-unit-x3); }
.inset-down-x3 { padding-top: var(--spacing-unit-x3); }
.inset-up-x3 { padding-bottom: var(--spacing-unit-x3); }
.inset-left-x4 { padding-right: var(--spacing-unit-x4); }
.inset-right-x4 { padding-left: var(--spacing-unit-x4); }
.inset-down-x4 { padding-top: var(--spacing-unit-x4); }
.inset-up-x4 { padding-bottom: var(--spacing-unit-x4); }
.inset-left-x5 { padding-right: var(--spacing-unit-x5); }
.inset-right-x5 { padding-left: var(--spacing-unit-x5); }
.inset-down-x5 { padding-top: var(--spacing-unit-x5); }
.inset-up-x5 { padding-bottom: var(--spacing-unit-x5); }
.inset-left-x6 { padding-right: var(--spacing-unit-x6); }
.inset-right-x6 { padding-left: var(--spacing-unit-x6); }
.inset-down-x6 { padding-top: var(--spacing-unit-x6); }
.inset-up-x6 { padding-bottom: var(--spacing-unit-x6); }

.inset { padding: var(--spacing-unit); }
.inset-half { padding: var(--half-spacing-unit); }
.inset-x2 { padding: var(--spacing-unit-x2); }
.inset-x3 { padding: var(--spacing-unit-x3); }
.inset-x4 { padding: var(--spacing-unit-x4); }

/* color util classes */
.color-text-gray {
  color: var(--text-gray);
}

.color-light-gray {
  color: var(--light-gray);
}

.ellip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellip-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  word-break: break-all;
}

.flex-gap {
  display: flex;
  gap: var(--spacing-unit);
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

@media print {
  .no-print {
    display: none !important;
  }

  .page-container--print-class {
    height: unset !important;
  }

  .positionContainer {
    overflow: unset;
  }

  .positionContainer > div {
    min-width: 100vw;
    max-width: 100vw;
  }

  .pane-container {
    height: auto;
    overflow: visible;

    &__inner {
      position: relative;
      top: unset;
      left: unset;
      bottom: unset;
      right: unset;
      overflow: visible;
    }
  }

  .program-flow {
    width: 100vw;
    height: auto;
  }

  .chart {
    page-break-inside: avoid;
  }
}

.no-pointer-events {
  pointer-events: none;
}

.display-none {
  display: none;
}

.divider {
  border-top: var(--standard-border);
  width: 100%;
}
