
.contact-settings {
  display: block;

  &__container {
    width: 50rem;
  }

  &__header {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__input {
    width: var(--spacing-unit-x12);

    &-section {
      position: relative;
      margin-top: var(--spacing-unit-x3);
    }

    &-container {
      &:first-child {
        margin-right: var(--spacing-unit-x8);
      }
    }

    &-error {
      position: absolute;
    }
  }
}
