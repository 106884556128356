.webinar-attendance-data-card {
  & > .data-card__background {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > .single-line-chart > .single-line-chart__values {
      margin-bottom: 0;
    }
  }
}
