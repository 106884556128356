.form-manager-details {
  display: flex;
  gap: var(--spacing-unit-x4);
  margin-bottom: var(--spacing-unit-x4);

  &__container {
    &-left {
      flex: 2;
    }

    &-right {
      flex: 0 0 var(--program-settings-right-container-min-width);
    }
  }
}
