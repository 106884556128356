
.entity-record-table {
  &__rec_count {
    text-align: right;
    padding-bottom: var(--spacing-unit-x2);
    padding-right: var(--spacing-unit-x2);
    font-size: var(--large-font-size);
    line-height: var(--line-height-smallish);
  }

  &__title-text {
    border-bottom: none;
    font-weight: var(--font-weight-medium);
    font-size: var(--sub-header-font-size);
    margin-top: var(--spacing-unit-x14);
    margin-left: var(--spacing-unit-x2);
    padding: 0;
  }

  &__modal_heading {
    padding-left: var(--spacing-unit-x2);
    padding-right: var(--spacing-unit-x2);
    left: 0;
    width: calc(100vw);

    .modal-header__close-button {
      padding: var(--spacing-unit-x2);
      top: var(--spacing-unit-x2);
      right: var(--spacing-unit-x2);
    }

    .svg {
      width: var(--spacing-unit-x3);
      height: var(--spacing-unit-x3);
    }
  }

  &__outer {
    margin: 0 var(--spacing-unit-x2) var(--spacing-unit-x8);
    overflow-x: auto;
    border-radius: 0.333rem;

    .table__table-holder {
      overflow-x: auto;
      overflow-y: visible;
      height: calc(100vh - 20rem);
    }

    .table__table-holder,
    .table__paginator,
    .paginator {
      max-width: unset;
    }

    .table {
      .tbody {
        overflow: visible !important;

        .td {
          overflow-wrap: anywhere;
          padding-left: var(--spacing-unit-x3);
          padding-right: var(--spacing-unit-x3);
          cursor: default;

          &:first-child {
            padding-left: 0;
          }
        }
      }

      .td,
      .th {
        &:last-child {
          padding-right: 0;
        }
      }

      .th {
        padding-left: var(--spacing-unit-x3);
        padding-right: var(--spacing-unit-x3);
        cursor: default;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
          padding-left: var(--spacing-unit-x4);
        }

        &:hover {
          text-decoration: none;
        }
      }

      .thead {
        .tr {
          height: unset;
          padding-bottom: var(--spacing-unit);
          padding-top: var(--spacing-unit-x4);
          cursor: default;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
