.all-permissions-form {
  &__accordion {
    &__panel {
      margin-top: var(--spacing-unit);
    }

    &__item {
      border-bottom: none;
      padding: 0;

      &[data-state='open'] {
        padding-bottom: var(--spacing-unit-x2);
      }
    }
  }

  .permissions-group {
    &__trigger {
      column-gap: calc(var(--spacing-unit-x3) / 2);

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-left: var(--spacing-unit);
      }
    }

    &__content {
      &:not(:first-child) {
        margin-top: var(--spacing-unit-x4);
      }

      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
      padding: var(--spacing-unit-x3);
      padding-left: calc(var(--spacing-unit-x7) / 3);

      .user-launch-approval-select {
        margin-bottom: var(--spacing-unit);
      }

      &-option {
        display: flex;
        justify-content: space-between;
        padding: 0 var(--half-spacing-unit);

        &:not(:last-child) {
          padding-bottom: var(--spacing-unit);
          border-bottom: var(--standard-border);
          margin-bottom: var(--spacing-unit-x2);
        }
      }

      &-title {
        font-size: var(--large-font-size);
        line-height: var(--line-height-large);
        margin-top: 0;
        margin-bottom: var(--spacing-unit-x3);

        .tooltip__trigger {
          line-height: inherit;
        }
      }

      &__launch {
        margin-top: var(--spacing-unit-x4);
      }
    }
  }
}

.permissions-group__content-title__tooltip {
  max-width: 25rem;
  padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
}