.step-type-selector {
  &__type {
    align-items: center;
    border-radius: var(--standard-border-radius);
    border: var(--standard-border);
    display: flex;
    padding: var(--spacing-unit-x2);

    &:hover {
      background-color: var(--hover-row-background-teal);
      cursor: pointer;
    }

    &-definition {
      flex-grow: 1;
      margin-left: var(--spacing-unit-x4);
    }

    & + & {
      margin-top: var(--spacing-unit-x2);
    }
  }
}
