.data-review-sidebar {
  &__header {
    margin-bottom: var(--spacing-unit-x4);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tableV2 {
    min-width: unset;
  }
  
  &__status {
    display: flex;
    gap: var(--spacing-unit);
    align-items: center;
  }

  &__pagination {
    display: flex;
    gap: var(--spacing-unit-x2);
    align-items: center;
    flex-shrink: 0;

    &-button {
      padding: var(--spacing-unit);
      border: var(--standard-border);

      svg {
        margin: 0 !important;
      }

      &:hover {
        border-color: var(--text-teal);

        svg {
          fill: var(--white) !important;
        }
      }

      &[disabled] {
        border: var(--standard-border);

        svg {
          fill: var(--button-gray) !important;
        }
      }
    }
  }
}
