.customize-preview {
  margin-top: var(--spacing-unit-x4);

  &__preview-content {
    background-color: var(--lighter-off-white);
    border-radius: calc(var(--checkbox-border-radius) * 5) calc(var(--checkbox-border-radius) * 5) calc(var(--checkbox-border-radius) * 5)
      calc(var(--checkbox-border-radius) * 5);
    border: var(--standard-border);
    border-color: var(--page-back);
    margin-top: var(--spacing-unit-x2);
  }

  &__img {
    max-width: 6.8rem;
    max-height: var(--spacing-unit-x7);
  }

  &__tab-bar {
    display: flex;
    border-bottom: var(--standard-border);
    max-height: 4.4rem;
  }

  &__tab {
    width: calc(2 * var(--spacing-unit-x21));
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
    background-color: var(--white);
    border: var(--standard-border);
    margin-top: calc(var(--spacing-unit-x5) / 3);
    margin-bottom: calc(-1 * (var(--spacing-unit) / 6));
    padding: 1.1rem var(--spacing-unit-x4) 1.1rem var(--spacing-unit-x3);
    border-top-right-radius: 0.84rem;
    border-top-left-radius: 0.84rem;

    &::after {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: calc(-1 * var(--half-spacing-unit) / 3);
      border-bottom: var(--standard-border);
      border-color: var(--white);
      content: '';
    }
  }

  &__dots {
    display: flex;
    align-items: center;
    gap: calc(var(--spacing-unit-x17) / 12);
    padding: 0 var(--spacing-unit-x3);

    span {
      border-radius: 50%;
      background-color: var(--border-gray);
      border: calc(var(--standard-border-width) / 2) solid rgba(0, 0, 0, 0.2);
      height: calc(var(--spacing-unit-x23) / 12);
      width: calc(var(--spacing-unit-x23) / 12);
    }
  }

  &__circle {
    width: var(--spacing-unit-x3);
    height: var(--spacing-unit-x3);
    background-color: var(--button-back-gray);
    border-radius: 50%;

    &-small {
      width: var(--spacing-unit-x2);
      height: var(--spacing-unit-x2);
    }
  }

  &__rectangle {
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit);
    background-color: var(--light-off-white);

    &-long {
      width: 14.5rem;
    }

    &-medium {
      width: var(--spacing-unit-x17);
    }
  }

  &__content {
    height: var(--spacing-unit-x17);
    display: flex;
    border-top: none;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &__main {
    background-color: var(--lighter-off-white);
    flex-basis: 85%;
    border-bottom-right-radius: inherit;

    &-topbar {
      height: 50%;
      display: flex;
      align-content: center;
      justify-content: space-between;
      background-color: var(--white);
      padding: 0 var(--spacing-unit-x4);
      box-shadow: var(--box-shadow);

      &-item {
        flex-basis: 50%;
        display: flex;
        align-items: center;
        gap: var(--spacing-unit-x2);

        &-right {
          justify-content: flex-end;
        }
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    padding-top: var(--spacing-unit-x2);
    padding-bottom: var(--half-spacing-unit);
    flex-basis: 15%;
    border-right: var(--standard-border);
    border-bottom-left-radius: inherit;

    &-section {
      width: 75%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-unit-x4);
      flex-direction: row;
    }
  }

  &__logo {
    width: auto;
    height: auto;
    margin-top: var(--half-spacing-unit);
    max-width: 6.8rem;
    max-height: var(--spacing-unit-x7);
  }
}
