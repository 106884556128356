
.grid {
  box-sizing: border-box;

  &__row {
    display: flex;
    margin-top: 1rem;
  }

  &__column {
    min-width: 0;

    &:not(:first-child) {
      padding-left: 1rem;
    }

    &:not(:last-child) {
      padding-right: 1rem;
    }

    &--25 {
      flex: 0 0 25%;
    }

    &--30 {
      flex: 0 0 30%;
    }

    &--33 {
      flex: 0 0 33.333%;
    }

    &--40 {
      flex: 0 0 40%;
    }

    &--50 {
      flex: 0 0 50%;
    }

    &--60 {
      flex: 0 0 60%;
    }

    &--66 {
      flex: 0 0 66.666%;
    }

    &--70 {
      flex: 0 0 70%;
    }

    &--100 {
      flex: 0 0 100%;
    }
  }
}
