.segment-name-with-ftp-sync-and-info-hover {
  display: block;
  overflow: hidden;
  gap: var(--spacing-unit);

  &__content {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit);
  }

  &__icon {
    min-width: var(--spacing-unit-x2);
    display: flex;
    margin-top: 0.2rem;

    &-input-status-invalid-no-fill {
      fill: var(--remove-red);
    }

    &-reload-no-fill {
      color: var(--teal);
    }
  }

  &__tooltip {
    padding: var(--spacing-unit-x3);

    &-header {
      position: relative;
      min-height: calc(var(--spacing-unit-x3) / 2);
    }

    &-title {
      padding-right: var(--spacing-unit-x4);
      color: var(--white);
    }
    
    &-content {
      display: flex;
      gap: var(--spacing-unit);
      flex-direction: column;
    }
    
    &-data {
      display: flex;
      gap: var(--spacing-unit);

      &-details {
        display: flex;
        flex-direction: column;
      }
    }
    
    &-icon {

      margin-top: 0.3rem;

      &-input-status-invalid-no-fill {
        fill: var(--white);
      }
    }
  }
}
