.non-crm-contacts {
  &__schema_editor_containers {
    display: flex;
    column-gap: var(--spacing-unit-x4);

    > div {
      margin: unset !important;
      width: calc(50% - var(--spacing-unit-x2));
    }
  }
}
