.copy-from-segment-details {
  > div:not(:first-child) {
    margin-top: var(--spacing-unit);
  }

  &__segment {
    display: inline-flex;
    align-items: center;
    white-space: pre;

    svg {
      margin: 0 var(--half-spacing-unit);
    }
  }
}
