
.api-step-detail {
  &__label {
    width: var(--spacing-unit-x12);
  }

  &__field {
    margin-bottom: var(--spacing-unit);
    display: flex;

    &-elided {
      flex: 1;
    }

    .ellip {
      max-width: 50rem;
    }

    &-complex {
      &,
      &-values {
        margin-top: var(--spacing-unit);
      }

      &-values {
        margin-top: var(--spacing-unit);
        margin-left: var(--spacing-unit-x4);

        &-value {
          display: flex;

          &-label {
            flex: 0 0 var(--spacing-unit-x28);
          }

          &-value {
            flex: 1;
          }
        }
      }
    }
  }
}
