
.copy-to-list-step {
  &__update {
    margin-top: var(--spacing-unit);
  }

  &__target-list-container {
    display: flex;

    .svg {
      margin: var(--half-spacing-unit);
    }
  }
}
