.list-picker {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 10rem;

  &__header,
  &__row {
    width: 100%;
    display: flex;
  }

  &__col {
    display: flex;
    overflow: hidden;

    & > label {
      padding: 0.75rem var(--spacing-unit) 0.75rem 0;
      font-size: var(--large-font-size);
      color: var(--text-gray);
      display: flex;
      align-items: center;
      flex: 1 1 100%;
      flex-wrap: nowrap;
      overflow: hidden;

      button {
        margin-right: var(--spacing-unit);
      }
    }

    .list-picker__text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 1.5rem;
      width: 100%;
      cursor: pointer;
    }

    &:nth-child(1) {
      flex: 0 0 3rem;

      & > label {
        padding: 0.75rem 0.75rem 0.75rem 0;
      }
    }

    &:nth-child(2) {
      flex: 1 1 100%;
    }

    &:nth-child(3) {
      flex: 0 0 12rem;

      svg {
        max-width: 1rem;
        max-height: 1rem;
        fill: var(--teal);
        margin: 0 var(--spacing-unit) 0.166666rem 0;
      }
    }

    &:nth-child(4) {
      flex: 0 0 6rem;

      & > label {
        justify-content: flex-end;
        padding-right: 0;
      }
    }
  }

  &__list-icon {
    fill: var(--gray);

    &-disabled {
      fill: var(--lighter-gray);
    }
  }

  &__header {
    border-bottom: var(--standard-border);

    &--folders {
      padding-left: var(--spacing-unit-x2);
    }

    .list-picker__col {
      &-hide {
        display: none;
      }

      & > label {
        font-weight: var(--font-weight-bold);
        color: var(--gray);
        font-size: var(--smallest-font-size);
      }

      &:nth-child(1) > label {
        padding-left: var(--spacing-unit-x2);
        padding-right: 2.2rem;
      }

      &:nth-child(2) > label {
        padding-left: 1.3rem;
      }

      &:nth-child(3) > label {
        padding-left: 1.4rem;
      }

      &:nth-child(4) > label {
        padding-right: 1.9rem;
      }
    }
  }

  &__row {
    width: 100%;
    display: flex;

    .list-picker__col {
      cursor: pointer;

      &-hide {
        display: none;
      }

      & > label {
        border-top: var(--standard-border);
      }

      &:nth-child(1) > label {
        margin-left: var(--spacing-unit-x2);
      }

      &:nth-child(2) > label {
        font-weight: var(--font-weight-medium);
      }

      &:nth-child(4) > label {
        margin-right: var(--spacing-unit-x4);
      }
    }

    &:nth-child(2) .list-picker__col > label {
      border-top: none;
    }

    &:hover {
      text-decoration: none;
      background-color: var(--page-back);
    }
  }

  &__folder {
    margin-left: var(--spacing-unit);
    padding: var(--spacing-unit-x3) 0 0 0;
    font-size: var(--large-font-size);
    width: 100%;

    &-row {
      padding: var(--spacing-unit) var(--spacing-unit) var(--spacing-unit) 0.2rem;
      display: flex;
      align-items: center;
      margin-right: var(--spacing-unit-x3);
    }

    &-icon {
      flex: 0 0 1.15rem;
      width: 1.15rem;
      height: 1rem;
      fill: var(--gray-icon);
      margin: 0 var(--spacing-unit);
    }

    .list-picker__row .list-picker__col > label {
      border-top: var(--standard-border);
    }

    &--collapsable {
      .list-picker__row {
        padding-left: var(--spacing-unit);
      }
    }
  }

  &__icon-holder {
    flex: 0 0 2.4rem;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      max-width: 1.333rem;
      max-height: 1.333rem;
    }
  }

  &__checked-icon {
    width: 1.333rem;
    height: 1.333rem;
    border-radius: 2rem;
    background-color: var(--teal);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      fill: var(--white);
      width: 0.735rem;
      height: 0.673rem;
      position: absolute;

      &:nth-child(1) {
        z-index: 1;
      }

      &:nth-child(2) {
        fill: var(--focus-teal);
        position: relative;
        top: 0.1rem;
      }
    }
  }

  &__search {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-section {
      color: var(--light-gray);
      font-weight: var(--font-weight-medium);
      padding: var(--spacing-unit-x4) 0 var(--spacing-unit-x2);
    }

    &-body {
      padding: 0 var(--spacing-unit-x4);
      flex: 1 1 100%;
    }

    .list-picker__folder {
      padding-top: 0;
      margin-left: 0;
    }

    .list-picker__folder-row {
      padding-bottom: var(--spacing-unit-x2);

      .svg.list-picker__folder-icon {
        margin-left: 0;
      }
    }

    .list-picker__row .list-picker__col > label {
      border-top: var(--standard-border);
    }

    .list-picker__row .list-picker__col:nth-child(4) > label {
      margin-right: 1rem;
    }
  }

  &__disabled-icon {
    padding-left: var(--spacing-unit-x2);
  }
}
