.input {
  background-color: var(--white);
  margin: 0;
  width: 100%;
  border-radius: var(--half-spacing-unit);
  color: var(--text-gray);
  font-size: var(--large-font-size);
  padding-left: var(--spacing-unit-x2);
  padding-right: var(--spacing-unit-x2);
  border: var(--input-border);
  height: var(--spacing-unit-x6);
  transition: var(--linear-80);

  &::placeholder {
    color: var(--light-gray);
    font-size: var(--large-font-size);
  }

  &--quantity {
    padding-left: var(--spacing-unit);
    -moz-appearance: textfield;
  }

  &--quantity::-webkit-outer-spin-button,
  &--quantity::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover,
  &:focus {
    background-color: var(--white);
  }

  &:hover {
    border: var(--input-border-hover);
  }

  &:focus {
    border-color: var(--dark-blue);
    outline: var(--input-outline-border);
  }

  &__small {
    height: var(--spacing-unit-x4);
  }

  &__icon {
    position: absolute;
    left: var(--spacing-unit-x2);
    top: 50%;
    transform: translateY(-50%);
    fill: var(--button-gray);

    &-error {
      fill: var(--remove-red);
    }

    &-success {
      fill: var(--success-green);
    }

    &__small {
      top: var(--half-spacing-unit);
    }
  }

  &:disabled {
    background-color: var(--off-white-hover);
    color: var(--button-gray);
    opacity: 0.7;

    &:hover,
    &:focus {
      cursor: not-allowed;
      border: var(--input-border);
    }
  }

  &[readonly] {
    padding: 0;
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:focus {
      outline: none;
      border-color: transparent;
      box-shadow: none;
    }
  }

  &:focus&[readonly],
  &:hover&[readonly] {
    background-color: transparent;
    border-color: transparent;
  }

  &__error {
    outline-color: var(--error);
    border-color: var(--error);
  }

  &--read-only {
    border: var(--standard-border) !important;
    padding: var(--spacing-unit-x2) !important;
  }

  &__error:hover,
  &__error:focus {
    outline: none;
    border-color: var(--error);
    box-shadow: none;
  }

  &__container {
    position: relative;
    display: block;

    &-labelled {
      .input__icon {
        top: var(--spacing-unit-x6);

        &__small {
          top: calc(var(--spacing-unit-x5) + var(--half-spacing-unit));
        }
      }
    }

    &-icon {
      .input {
        padding-left: calc(var(--spacing-unit-x7) * 0.9);
      }
    }
  }
}
