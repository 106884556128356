.edit-permissions-modal {
  width: 100%;
  max-width: calc(100 * var(--spacing-unit));

  &__unset-overflow {
    overflow: unset;

    .modal-body {
      overflow: unset;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &-copy-btn {
      padding: calc(var(--spacing-unit-x10) / 6) var(--spacing-unit-x2) !important;

      .svg {
        margin-bottom: 0;
      }
    }
  }

  &__body {
    &__admin-access {
      display: flex;
      justify-content: space-between;
      padding: var(--spacing-unit-x3);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
      margin-bottom: var(--spacing-unit-x5);
      align-items: center;

      &__description {
        margin-bottom: var(--spacing-unit);
      }
    }
  }

  &__copy-icon {
    width: var(--spacing-unit-x2);
    height: var(--spacing-unit-x2);
    margin-right: var(--half-spacing-unit);
  }

  &__content {
    &:not(:first-child) {
      margin-top: var(--spacing-unit-x4);
    }

    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x3);
    padding-left: calc(var(--spacing-unit-x7) / 3);

    .user-launch-approval-select {
      margin-bottom: var(--spacing-unit);
    }

    &-option {
      display: flex;
      justify-content: space-between;
      padding: 0 var(--half-spacing-unit);

      &:not(:last-child) {
        padding-bottom: var(--spacing-unit);
        border-bottom: var(--standard-border);
        margin-bottom: var(--spacing-unit-x2);
      }
    }

    &-title {
      font-size: var(--large-font-size);
      line-height: var(--line-height-large);
      margin-top: 0;
      margin-bottom: var(--spacing-unit-x3);

      .tooltip__trigger {
        line-height: inherit;
      }
    }

    &__launch {
      margin-top: var(--spacing-unit-x4);
    }
  }
}
