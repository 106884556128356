.url-version-modal {
  width: 50rem;

  .modal {
    &-header__inner {
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    }

    &-footer__inner {
      padding: var(--spacing-unit-x4);
    }
  }

  .modal &__modal-body {
    padding: 0;
  }

  &__body-items {
    padding: var(--spacing-unit-x4);
    overflow-wrap: anywhere;
  }

  &__click-to-copy {
    display: flex;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-unit);

    &-title {
      display: flex;
      justify-content: space-between;
      min-height: var(--spacing-unit-x5);

      &-center {
        align-items: center;
      }
    }

    &-public-url {
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__url-display {
    flex: auto;
  }

  &__typography-word {
    background-color: var(--lighter-off-white);
    word-break: break-all;
    text-wrap: balance;
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    border-radius: var(--half-spacing-unit);
    gap: var(--spacing-unit-x2);
  }

  &__url-tooltip {
    display: flex;
    align-items: center;
  }

  &__tooltip {
    &__content {
      width: 25rem;
      padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
    }
  }
  
  &__body-items-align {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__body-items-align:last-child {
    margin-bottom: 0;
  }
  
}
