.search {
  &.input {
    padding-bottom: 0.1rem;
    background-color: var(--white);

    &::placeholder {
      font-size: var(--large-font-size);
    }

    &:hover {
      border-color: var(--dark-blue);
    }

    &:focus {
      border-color: var(--dark-blue);
      outline: var(--small-input-outline-border);
    }

    &:focus-visible {
      box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
    }
  }

  .search--large {
    background-size: var(--spacing-unit-x2) var(--spacing-unit-x2);
    height: var(--spacing-unit-x6);
    background-position: var(--spacing-unit-x2) 0.95rem;

    &:focus {
      outline: var(--small-input-outline-border);
    }

    &:focus-visible {
      box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
    }
  }

  &__container,
  &--large__container {
    .button--float {
      position: absolute;
      top: 0;
      right: 0;

      &:hover {
        background: none;
      }
    }

    .input:hover {
      border-color: var(--button-gray);
    }

    .input:focus {
      border-color: var(--dark-blue);
      box-shadow: var(--date-picker-input-shadow);
    }

    .clear {
      height: var(--medium-font-size) !important;
      width: var(--medium-font-size);
      right: calc(var(--spacing-unit) + var(--half-spacing-unit));
      top: 50%;
      margin: 0;
      margin-top: calc(var(--medium-font-size) / 2);

      &:hover {
        fill: var(--dark-blue) !important;
      }
    }
  }
}
