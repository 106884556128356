.select-filter-button {
  display: flex;
  align-items: center;
  gap: var(--spacing-unit-x2);
  border: var(--standard-border-width) solid var(--button-back-gray);

  &:focus-visible {
    box-shadow: unset;
  }

  &__chevron {
    fill: var(--light-gray);
  }

  &__hover,
  &__active {
    background-color: var(--lighter-off-white);

    .typography {
      color: var(--text-gray);
    }

    .svg {
      fill: var(--text-gray) !important;
    }
  }

  &__active {
    &:hover {
      background-color: var(--off-white-focus);
    }
  }

  &:focus {
    background-color: var(--off-white-focus);
    border: var(--standard-border);
    border-color: var(--text-gray);

    &:hover {
      background-color: var(--button-back-gray);
    }
  }

  &__wrapper {
    display: flex;
    gap: var(--spacing-unit-x8);
    height: var(--spacing-unit-x6);
  }

  &__selected {
    .typography {
      color: var(--text-teal);
    }

    .svg {
      fill: var(--text-teal) !important;
    }

    &-wrapper {
      display: flex;
      background-color: var(--lighter-teal);

      &:has(.select-filter-button__selected-close:focus) {
        .select-filter-button__selected-label {
          border-right: unset !important;
        }
      }

      &-pill {
        border-radius: var(--extra-large-border-radius);
      }
    }

    &-label {
      border: var(--transparent-border);
      border-right: var(--card-border-highlight);
      gap: var(--spacing-unit-x2);
      border-radius: unset;

      &-pill {
        border-radius: var(--left-extra-large-border-radius);
      }

      &:focus {
        .typography {
          color: var(--teal-medium);
        }
      }

      &:hover {
        .typography {
          color: var(--teal-medium);
        }
      }
    }

    &-close {
      border-radius: unset;

      &-pill {
        border-radius: var(--right-extra-large-border-radius);
      }

      .svg {
        margin: 0;
      }
    }

    &-label,
    &-close {
      box-shadow: unset !important;

      &:hover {
        background-color: var(--text-gray-opacity-4);

        .svg {
          fill: var(--teal-medium) !important;
        }
      }

      &:focus {
        border: var(--standard-border);
        border-color: var(--teal-medium);
        background-color: var(--text-gray-opacity-8);

        .svg {
          fill: var(--teal-medium) !important;
        }

        &:hover {
          background-color: var(--text-gray-opacity-12);
        }
      }
    }
  }
}
