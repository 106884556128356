.optimize-crm-modal {
  height: 58.333rem;
  width: 58.333rem;

  &__body-description {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x7) 0 var(--spacing-unit-x7);
    margin: 0;
    display: inline;
  }

  &__list ul {
    max-height: 30rem;
  }

}
