.use-personalization-fields-modal {
  width: 50rem;

  &__loader {
    width: 100%;
  }

  &__input {
    &__container {
      margin-top: var(--spacing-unit-x4);
    }

    &-message {
      display: inline-flex;
      align-items: center;
      gap: var(--spacing-unit);
    }

    &-tooltip {
      width: 24rem;
    }
  }

  &__radio {   
    &-container {
      margin-top: var(--spacing-unit-x4);

      .radiogroup {
        gap: var(--spacing-unit-x2);
      }
    }

    &-option {
      width: 100%;

      &__options-container {
        width: inherit;
        margin-bottom: var(--spacing-unit-x2);

        &__content {
          width: 100%;
          margin: 0 2.334rem 0 0;
        }
      }
    }
  }

  &__dropdown {
    &-label {
      line-height: var(--spacing-unit-x4);
      margin: var(--spacing-unit) 0 var(--half-spacing-unit) 0;
    }
     
    &-unselected {
      .single-select-dropdown__faux-select {
        color: var(--light-gray);
      }
    }
  }
}
