
.donut-chart-simple {
  .donut-segment {
    transform-origin: center;
    animation: donut 3s;
  }

  @keyframes donut {
    0% {
      stroke-dasharray: 0, 100;
    }
  }
}
