.sage-board {
  &__embed {
    height: 21rem;

    &-container {
      height: 21rem;
    }

    &-container-loaded,
    &-loaded {
      height: 80vh;
    }
  }
}
