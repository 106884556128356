.send-details-select-contacts-modal {
  width: 100rem;
  height: 66rem;

  &__header {
    padding-left: var(--spacing-unit-x4);
    padding-right: var(--spacing-unit-x4);

    &__extra {
      margin-left: auto;
    }
  }

  &__search-input {
    width: 25rem;
  }

  &__body.modal-body {
    padding: 0;
  }

  &__footer {
    &__extra {
      margin-right: auto;
    }
  }

  &__lazy-loader {
    height: 100%;
  }
}
