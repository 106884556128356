.password-block {
  display: flex;
  align-items: center;
  gap: var(--spacing-unit-x2);
  margin-bottom: var(--spacing-unit-x2);

  &__svg {
    width: 1.3333rem;
    height: 1.3333rem;
  }

  &__wrapper {
    padding: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit);
  }

  &__status {
    &-default {
      fill: var(--button-hover);
    }

    &-success {
      fill: var(--success-green);
      color: var(--text-gray);
    }

    &-error {
      fill: var(--remove-red);
      color: var(--remove-red);
    }
  }
}
