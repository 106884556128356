.contact-owner-setting {
  &__more-options {
    margin-top: var(--spacing-unit-x3);

    &,
    &__content {
      width: 100%;
    }

    &-personalization {
      position: relative;

      &-button {
        align-self: flex-end;

        &:has(button[disabled]) > div {
          cursor: not-allowed;
        }

        button {
          padding: var(--spacing-unit);
          margin-bottom: var(--half-spacing-unit);

          svg {
            margin: 0 !important;
          }
        }

        button[disabled] {
          pointer-events: none;

          svg {
            fill: var(--light-gray) !important;
          }
        }
      }

      &__content {
        margin-top: var(--spacing-unit);
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__owner-select {
    width: 22rem;
  }
   
  &__container {
    .contact-settings {
      &__input-section {
        margin-top: var(--spacing-unit-x6);
      }
    }
  }
}
