.move-to-folder {
  display: block;
  width: 50rem;

  & .modal-body {
    padding-top: var(--spacing-unit-x4);
    overflow: unset;
  }

  &__title {
    margin-bottom: var(--spacing-unit);
  }

  &__options {
    padding-top: var(--spacing-unit-x3);
    padding-bottom: var(--spacing-unit-x2);
  }
}
