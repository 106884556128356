.landing-page-embed-codes-container {
  padding-bottom: 0;

  &__info {
    margin-bottom: var(--spacing-unit-x4);
  }
   
  .tableV2 {
    min-width: 100%;
  }
}
