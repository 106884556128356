
.program-performance-chart-container {
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  overflow: hidden;

  .chart {
    &.container {
      margin: 0;
      border: none;
    }
  }

  &--all-contacts {
    border-top-color: var(--border-delivered);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &--early-exits {
    border-top-color: var(--border-earlyexit);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: visible;

    & > .pie-chart > div {
      overflow: visible !important;
    }
  }

  &--standard-exits {
    border-top-color: var(--border-standardexit);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: visible;

    div[data-highcharts-chart] {
      overflow: visible !important;
    }
  }

  &__extra-element {
    position: absolute;
    top: var(--chart-extra-element-top);
    left: 11.6rem;
    right: 8%;
    z-index: var(--z-index-chart-extra);
  }

  > .positionContainer > div {
    padding: 0;
  }
}
