.analytics-variables-modal {
  width: 50rem;

  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
  }

  .tableV2 {
    min-width: 100%;
  }
}
