.modal-header-v2 {
  display: flex;
  position: relative;
  flex: 1 1 auto;

  &__inner {
    min-height: var(--spacing-unit-x12);
    max-height: var(--spacing-unit-x12);
    max-width: 100%;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--light-theme-background-darker);
    border-bottom: var(--standard-border);
    border-top-left-radius: var(--standard-border-radius);
    border-top-right-radius: var(--standard-border-radius);

    &-no-border {
      border-bottom: none;
    }

    &-list {
      & input {
        background-color: var(--white);
      }
    }

    &-default {
      padding: var(--spacing-unit-x6) var(--spacing-unit-x6) 0 var(--spacing-unit-x6);
      justify-content: center;
      background-color: transparent;
    }
  }

  &__left {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    max-width: 100%;

    &-action-actions-and-search {
      max-width: calc(100% - 34rem);
    }

    &-action-button-and-search {
      max-width: calc(100% - 40rem);
    }

    &-action-split-and-search {
      max-width: calc(100% - 42rem);
    }

    &-action {
      max-width: calc(100% - 16rem);
    }

    &-search {
      max-width: calc(100% - 27rem);
    }

    &-icon {
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__right {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-unit-x2);
  }

  &__search {
    width: 25rem;
  }

  &__actions {
    &-button {
      padding: calc(var(--spacing-unit-x10) / 6) var(--spacing-unit-x2);

      .svg {
        width: 0.83rem;
      }

      &.button--float .svg {
        margin: 0 0 0 var(--spacing-unit-x2);
      }

    }
  }

  &__back-button {
    &:focus {
      background-color: var(--navigation-button-hover);
    }
  }

  &__close-button {
    border-radius: 50%;
    height: var(--spacing-unit-x6);
    width: var(--spacing-unit-x6);
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border-radius: 50%;
    }

    svg {
      fill: var(--light-gray);
      height: calc(var(--spacing-unit-x8) / 3);
      width: calc(var(--spacing-unit-x8) / 3);
    }

    &:hover svg {
      fill: var(--text-gray);
    }
  }
}

@media all and (max-width: 75rem) {
  .modal-header-v2__search {
    width: 21.6667rem;
  }
}
