
.table-list {
  &__table {
    &-border {
      border: var(--standard-border);
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    border-bottom: var(--standard-border);

    &:last-of-type:not(&-no-border),
    &-total {
      border-bottom: none;
    }

    &,
    &-total {
      padding: var(--spacing-unit-x2);
    }

    &-no-border {
      padding: var(--spacing-unit-x2) 0;
    }

    &-teal {
      background-color: var(--background-teal);
    }
  }
}
