.review-upgrade {
  &__container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
    margin: var(--spacing-unit-x4) 0;
  }
  
  &__title-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
  }
  
}
