.list-program-source-selector {
  display: flex;
  align-items: center;
  gap: var(--spacing-unit-x2);

  &__button {
    height: var(--spacing-unit-x6);
    padding: calc(var(--spacing-unit-x4) / 3) var(--spacing-unit-x3);
    flex-shrink: 0;

    &:not(.button--info) {
      svg {
        color: var(--text-gray);
      }
    }

    svg {
      color: var(--text-teal);
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__info {
    width: var(--detail-column-width);
  }

  &__no-list-selected {
    display: inline-flex;
    gap: var(--spacing-unit);
    align-items: center;
  }
}
