.tabs-ao {
  &__list {
    display: flex;
    min-width: var(--positioning-min-width);

    &[data-orientation='vertical'] {
      flex-direction: column;
    }
  }

  &__list-with-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__type {
    &-unified {
      background-color: var(--white);
      padding-left: var(--spacing-unit-x4);
      border-bottom: var(--modal-border);
    }
  }

  &__tab-cards {
    background-color: var(--input-background);
    border-radius: var(--spacing-unit-x3);
    width: fit-content;
    min-width: unset;
    padding: var(--half-spacing-unit);
    position: relative;
    align-items: center;

    .glider {
      position: absolute;
      background-color: var(--teal);
      border-radius: var(--spacing-unit-x3);
      z-index: 1;

      &__with-transition {
        transition: 0.25s ease-out;
      }
    }
  }

  &__tab {
    margin-right: var(--spacing-unit-x6);
    border-width: 0 0 var(--thick-border-width) 0;
    padding-bottom: calc(var(--spacing-unit) + var(--half-spacing-unit));
    border-style: solid;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
    
    &--locked {
      display: inline-flex;
      cursor: not-allowed !important;
      align-items: center;
    }

    &[data-state='inactive']:not(&-card):not(&-solid):not(&-pill),
    &--inactive,
    &__drop-down__content {
      border-color: transparent;

      .typography {
        color: var(--gray);
      }

      & .button--icon-right {
        .caret-icon {
          fill: var(--gray);
        }
      }
    }

    &:not(&-split):not(&-boxy):not(&-card) {
      .typography {
        font-weight: var(--font-weight-medium);
      }
    }

    &:hover:not(&-split):not(&-boxy):not(&--disabled):not(&-card):not(&-pill),
    &--active,
    &--dropdown-open {
      color: var(--text-teal);

      .typography {
        color: var(--text-teal);
        text-decoration: none;
      }

      & .button--icon-right {
        .caret-icon {
          fill: var(--text-teal) !important;
        }
      }
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: var(--keyboard-focus-box-shadow);
    }

    &[data-state='active']:not(&--inactive):not(&-split):not(&-boxy):not(&-card):not(&-pill),
    &--active {
      border-color: var(--text-teal);
      font-weight: var(--font-weight-medium);

      .typography {
        color: var(--text-teal);
      }

      & .button--icon-right {
        .caret-icon {
          fill: var(--text-teal);
        }
      }
    }

    &-card {
      border: unset;
      margin-right: 0;
      padding: var(--spacing-unit) var(--spacing-unit-x2);
      z-index: 2;

      &-active {
        background-color: var(--teal);
        border-radius: var(--spacing-unit-x3);
      }

      & + & {
        margin-left: var(--spacing-unit-x2);
      }

      .typography {
        transition: color 0.25s ease-in;
        color: var(--light-gray);
      }

      &[data-state='active'] {
        .typography {
          color: var(--white);
          transition: color 0.25s ease-in;
        }
      }
    }

    &-pill {
      border: unset;
      margin-right: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
      height: var(--spacing-unit-x6);

      &-active {
        background-color: var(--text-teal);
        border-radius: var(--spacing-unit-x3);
        transition: background-color 0.25s ease-in;
        padding-bottom: 0;
      }

      &-inactive {
        border-radius: var(--spacing-unit-x3);
        transition: background-color 0.25s ease-in;
        padding-bottom: 0;
      }

      .typography {
        color: var(--text-gray);
      }

      &[data-state='active'] {
        .svg {
          fill: var(--white);
        }

        .typography {
          color: var(--white);
        }
      }

      &[data-state='inactive'] {
        background-color: var(--white);
        padding-bottom: 0;
        border-radius: var(--spacing-unit-x3);

        &:hover {
          background-color: var(--lighter-teal);
          border-radius: var(--spacing-unit-x3);

          .svg {
            fill: var(--text-teal);
          }

          .typography {
            color: var(--text-teal);
          }
        }

        &:focus {
          background-color: var(--lighter-teal);
          outline: var(--standard-border-dark-blue);

          .svg {
            fill: var(--text-teal);
          }

          .typography {
            color: var(--text-teal);
          }
        }
      }
    }

    &-dot {
      justify-content: center;
      margin-right: 0;
      display: flex;
      border-color: transparent !important;

      &-label {
        color: var(--text-gray) !important;
      }

      &-items {
        display: flex;
        width: 100%;
        justify-content: center;
      }

      &-wrapper {
        display: flex;
      }

      &-connector {
        border: var(--standard-border);
        width: 13rem;
        margin-bottom: 1.6rem;
      }

      &-button {
        border-radius: 50%;
        margin: 0 calc(var(--spacing-unit) / 3);
        height: var(--spacing-unit-x3);
        width: var(--spacing-unit-x3);
        border: var(--teal-border);

        &-wrapper {
          width: var(--spacing-unit-x4);
          height: var(--spacing-unit-x4);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          &-active {
            background-color: var(--focus-light-teal);
          }

        }

        &-disabled {
          background-color: var(--lightest-gray);
          border: none;
        }

        &-active {
          background-color: var(--teal);
        }
      }

      &-item {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
      }

      &-section {
        align-items: center;
        display: flex;
      }

      &-active {
        border-color: transparent !important;
      }
    }

    &[data-state='inactive']&--disabled,
    &--disabled {
      cursor: default;

      .typography {
        color: var(--lighter-gray);
      }

      & .button--icon-right {
        cursor: default;

        .caret-icon {
          fill: var(--lighter-gray);
        }
      }
    }

    &-button[data-state='active']:not(&-card) {
      font-weight: var(--font-weight-medium);
      color: var(--text-teal);
      background-color: var(--highlight-gray);
    }

    & .drop-down__main-button {
      & .button--icon-right {
        height: unset;
        padding: 0;
        line-height: var(--line-height-large);
      }
    }

    &__drop-down {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: calc(var(--spacing-unit-x2) - 0.02rem);

      & div[role='menuitem'] {
        padding-right: var(--spacing-unit-x5);

        /* focus-visible is sometimes being triggered on hover */
      }
    }
  }

  &__primary-icon {
    display: flex;
    align-items: center;

    .svg {
      margin-left: var(--spacing-unit);
      fill: var(--remove-red);
    }

    &-pill {
      display: inline-flex;
      gap: var(--spacing-unit);
      padding: var(--spacing-unit) var(--spacing-unit-x3) var(--spacing-unit) var(--spacing-unit-x2);
      flex-direction: row-reverse;

      &__icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--spacing-unit-x4);
        height: var(--spacing-unit-x4);
      }

      .svg {
        margin-left: 0;
        width: 1.3334rem;
        height: 1.3334rem;
        fill: var(--lighter-gray);
      }
    }
  }

  &__boxy &__list {
    justify-content: space-between;
  }

  &__boxy &__tab {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    min-width: var(--spacing-unit-x17);
    max-width: var(--spacing-unit-x18);
    flex: 1;
    margin-right: var(--spacing-unit);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x3);
    background-color: var(--white);

    .typography {
      color: var(--gray);
    }
  }

  &__solid {
    .tabs-ao__panel {
      border: var(--standard-border);
      border-top: 0;
      margin-top: 0;
      border-bottom-left-radius: var(--standard-border-radius);
      border-bottom-right-radius: var(--standard-border-radius);
    }
  }

  &__solid &__list {
    min-width: unset;
    display: flex;
  }

  &__tab-solid {
    flex: auto;
    text-align: center;
    padding: var(--spacing-unit-x2);
    margin-right: 0 !important;
    border-right: var(--standard-border);
    border-bottom: var(--standard-border);
    border-top: var(--standard-border);

    &:first-child {
      border-left: var(--standard-border);
      border-top-left-radius: var(--standard-border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--standard-border-radius);
    }

    &[data-state='active'] {
      border-bottom: none;
      border-right: var(--standard-border);
      border-color: var(--border-gray) !important;
    }

    &[data-state='inactive'] {
      background-color: var(--input-background);
    }

    &[data-state='inactive']:not([data-disabled]) {
      &:hover {
        background-color: var(--off-white-focus);

        .typography {
          color: var(--text-gray) !important;
        }
      }
    }
  }

  &__split &__tab {
    min-width: 16.6667rem;
    margin-right: var(--spacing-unit-x2);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    flex: 1;
    padding: var(--spacing-unit-x2);
    background-color: var(--white);

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__tab-split[data-state='active'] &__split-secondary {
    color: var(--text-gray);
  }

  &__split-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--half-spacing-unit);

    .tooltip__trigger {
      vertical-align: center;
    }

    &-tooltip {
      margin-left: var(--spacing-unit);
    }
  }

  &__split-content {
    display: flex;
    align-items: center;
  }

  &__label-content {
    margin-top: var(--spacing-unit-x2);
    display: flex;
    align-items: baseline;

    &-primary {
      margin-right: var(--spacing-unit);
      line-height: var(--line-height-sub-header);
    }
  }

  &__split-container &__label-content-primary {
    margin-right: var(--spacing-unit-x4);
  }

  &__panel {
    margin-top: var(--spacing-unit-x4);
    outline: none;

    &--invisible {
      display: none;

      &[hidden] {
        display: block;
      }
    }
  }

  &__theme- {
    &base:hover {
      border-color: var(--border-sent);
    }

    &base[data-state='active'] {
      background-color: var(--background-sent);
      border-color: var(--border-sent);
    }

    &delivered:hover {
      border-color: var(--border-delivered);
    }

    &delivered[data-state='active'] {
      background-color: var(--background-delivered);
      border-color: var(--border-delivered);
    }

    &clicked:hover {
      border-color: var(--border-clicked);
    }

    &clicked[data-state='active'] {
      background-color: var(--background-clicked);
      border-color: var(--border-clicked);
    }

    &replied:hover {
      border-color: var(--border-replied);
    }

    &replied[data-state='active'] {
      background-color: var(--background-replied);
      border-color: var(--border-replied);
    }

    &failed:hover {
      border-color: var(--border-failed);
    }

    &failed[data-state='active'] {
      background-color: var(--background-failed);
      border-color: var(--border-failed);
    }

    &optedout:hover {
      border-color: var(--border-optedout);
    }

    &optedout[data-state='active'] {
      background-color: var(--background-optedout);
      border-color: var(--border-optedout);
    }

    &earlyexit:hover {
      border-color: var(--border-earlyexit);
    }

    &earlyexit[data-state='active'] {
      background-color: var(--background-earlyexit);
      border-color: var(--border-earlyexit);
    }

    &standardexit:hover {
      border-color: var(--border-standardexit);
    }

    &standardexit[data-state='active'] {
      background-color: var(--background-standardexit);
      border-color: var(--border-standardexit);
    }
  }
}
