.create-in-crm-details-v2 {
  div .typography:not(.typography__inline) {
    margin-bottom: unset !important;
  }

  > div + div {
    margin-top: var(--spacing-unit);
  }

  ul {
    margin: unset;
    padding-left: var(--spacing-unit-x4);
  }

  &__entity-container {
    &-spacing ul {
      margin-bottom: var(--spacing-unit);
    }
  }
}
