.segment-save-modal {
  width: 50rem;
  overflow: visible;

  &__folder-drop-down {
    .nested-dropdown__wrapper {
      position: unset;
      overflow: visible;
    }
  }

  &__tags {
    display: flex;
    gap: calc(var(--half-spacing-unit) + var(--spacing-unit));
    flex-wrap: wrap;
  }

  &__form-row {
    & + & {
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__description {
    min-height: var(--spacing-unit-x10);
    max-height: 25rem;
    min-width: 100%;
    max-width: 100%;
  }
}
