.send-api-details-modal {
  width: 50rem;

  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
  }

  &__notes {
    min-height: 5.3334rem;
    max-height: 43rem;
    resize: vertical;
  }

  .input-with-status__tooltip__trigger-wrapper {
    width: 100%;
  }

  .button .loader {
    margin-bottom: var(--spacing-unit-x2);
  }
}
