.review-export-options {
  &__header {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__section {
    display: flex;
    align-items: center;

    &-header {
      width: 17.5rem;
      flex-shrink: 0;
    }

    &-content {
      flex-grow: 1;
    }

    &:first-child {
      align-items: flex-start;
    }

    &:not(:first-child) {
      padding-top: var(--spacing-unit-x3);
      margin-top: var(--spacing-unit-x3);
      border-top: var(--standard-border);
    }
  }

  &__radio-label {
    > .typography {
      display: flex;
      gap: var(--spacing-unit);
    }
  }
}
