.recommended-segment-icon {
  height: var(--spacing-unit-x8);
  width: var(--spacing-unit-x8);
  border-radius: var(--spacing-unit-x18);
  background-color: var(--teal);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);
    fill: var(--white);
    color: var(--white);
  }
}
