.email-composer-preview-inbox-sidebar {
  max-width: calc(var(--spacing-unit-x20) * 4);
  min-width: calc(var(--spacing-unit-x20) * 3);
  flex: max-content;
  border-left: var(--standard-border);
  display: flex;
  flex-direction: column;

  &__header {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4) var(--spacing-unit-x4);

    &-title {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      margin-bottom: var(--spacing-unit-x4);

      &-tooltip {
        max-width: 22rem;
      }
    }

    &-search {
      background-color: var(--white);
    }
  }

  &__content {
    height: 100%;
    overflow: auto;

    &-empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-accordion {
      &__item {
        border: none;
        padding: 0;
      }

      &__header {
        height: auto;
        border-top: var(--standard-border-dark);
      }

      &__panel {
        margin: 0;
      }

      &__button {
        justify-content: flex-end;
        gap: var(--spacing-unit-x2);
        border-radius: 0;
        background-color: var(--selectable-row-hover);
        padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

        &[data-state='closed'] {
          .svg {
            transform: rotate(-90deg);
          }
        }

        &[data-state='open'] {
          .svg {
            transform: none;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    border-top: var(--standard-border-dark);
    padding: var(--spacing-unit-x4);

    &-action {
      width: 100%;
      padding: var(--spacing-unit) var(--spacing-unit-x2);

      &:hover {
        .typography {
          color: var(--white);
        }
      }

      .typography {
        text-decoration: none;
      }
    }
  }
}
