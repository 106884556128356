
.add-step-modal {
  .chooser-button__icon,
  .program-step-icon {
    width: 3rem;
    height: 3rem;
  }

  .chooser-screen &__hide-button.chooser-button {
    display: none;
  }
}
