.send-details-section-split-header {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--spacing-unit-x4);

  &__left {
    flex: 1 1 83%;
    margin-right: var(--spacing-unit-x2);
  }

  &__right {
    width: var(--spacing-unit-x28);
  }

  &__button {
    min-width: 13.3334rem;

    .svg {
      margin-right: var(--spacing-unit);
    }
  }
}
