
.edit-wait-step {
  width: 50rem !important;

  &__row {
    display: flex;

    input {
      flex: 0 0 20rem;
    }
  }

  &__select-small {
    margin-left: var(--spacing-unit-x2);
    flex: 0 0 10rem;
  }

  &__select-large {
    flex: 0 0 20rem;
  }
}
