.upgrade-manager {
  &__step {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
  }

  &__title-container,
  &__content-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
  }

  &__progress-bar {
    margin: 0 0 var(--spacing-unit-x4) calc(0 - calc(10rem / 12));
    
    & .progress-bar__step-header {
      &:first-child .typography,
      &:nth-child(2) .typography,
      &:last-child .typography {
        white-space: nowrap;
      }
    }

    & .progress-bar__separator {
      width: calc(var(--spacing-unit-x6) + var(--half-spacing-unit));
    }
  }

  &__not-clickable-container {
    *:not(.page-container) {
      pointer-events: none !important;
    }
  }

  &__warning-message {
    margin: unset;
  }
}
