.button-copy {
  display: flex;
  align-items: center;
  padding: var(--spacing-unit) var(--spacing-unit-x2);
  gap: var(--spacing-unit);

  .typography:hover:not(.button--float-teal-clicked .typography) {
    text-decoration: none;
    color: var(--text-teal);
  }

  .svg--larger-icon {
    width: 1.33rem;
    height: 1.33rem !important;
  }

  &:focus {
    background-color: var(--white);
    box-shadow: var(--indicator-focus-box-shadow);
  }

  &:disabled {
    opacity: 0.5;

    .typography {
      text-decoration: none;
      cursor: default;
    }
  }

  &__svg {
    margin-bottom: calc(var(--spacing-unit-x2) / 12);

    &-disabled.svg {
      fill: var(--text-gray) !important;
    }
  }
}
