.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--standard-border-radius);
  text-shadow: var(--primary-button-text-shadow);
  cursor: pointer;

  &:focus:not(&:disabled),
  &:hover&:focus:not(&:disabled) {
    border: var(--standard-border-dark-blue);
    box-shadow: var(--date-picker-input-shadow);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &__size {
    &-large {
      height: var(--spacing-unit-x6);
      width: var(--spacing-unit-x6);
    }

    &-medium {
      height: var(--spacing-unit-x5);
      width: var(--spacing-unit-x5);
    }

    &-small {
      height: var(--spacing-unit-x4);
      width: var(--spacing-unit-x4);
    }
  }

  &__type {
    &-primary {
      background-color: var(--teal);

      .svg {
        filter: drop-shadow(var(--primary-button-text-shadow));
      }

      &:hover:not(&:disabled),
      &:hover&:focus:not(&:disabled) {
        background-color: var(--hover-teal);
      }

      &:focus:not(&:disabled) {
        background-color: var(--teal);
      }

      &:disabled {
        background-color: var(--lighter-off-white);

        .svg {
          filter: none;
        }
      }
    }


    &-secondary {
      &:hover:not(&:disabled),
      &:focus&:hover:not(&:disabled) {
        background-color: var(--off-white-focus);
      }

      &:focus:not(&:disabled) {
        background-color: var(--lighter-off-white);
      }
    }

    &-tertiary {
      &:hover:not(&:disabled),
      &:focus&:hover:not(&:disabled) {
        background-color: var(--hover-row-button-background-teal);
      }

      &:focus:not(&:disabled) {
        background-color: var(--hover-row-background-teal);
      }
    }

    &-white {
      background-color: var(--white);
      border: var(--standard-border-width) solid var(--button-border-gray);
      line-height: var(--spacing-unit-x2);

      &:hover {
        border-color: var(--tab-gray);
      }

      &:disabled {
        background-color: var(--input-background);
        color: var(--button-hover);
        cursor: default;

        &:hover {
          border-color: var(--button-border-gray);
        }
      }
    }

    &-gray {
      background-color: var(--lighter-off-white);
      line-height: var(--spacing-unit-x2);

      &:hover {
        background-color: var(--off-white-focus);
      }

      &:disabled {
        background-color: var(--off-white-hover-disabled);
        color: var(--button-hover);
        pointer-events: none;
      }
    }

    &-teal-on-hover {
      background-color: var(--white);
      border: var(--standard-border);

      &:hover {
        background-color: var(--hover-create-option-icon);
        border-color: var(--hover-create-option-icon);
      }

      &:disabled {
        background-color: var(--off-white-hover-disabled);
        color: var(--button-hover);
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    &-light-teal-on-hover {
      background-color: var(--white);
      border: var(--standard-border);

      &:hover:not(&:disabled),
      &:hover&:focus:not(&:disabled) {
        background-color: var(--light-teal);
        border-color: var(--border-teal);

        & .svg {
          fill: var(--text-teal) !important;
        }
      }

      &:disabled {
        background-color: var(--off-white-hover-disabled);
        color: var(--button-hover);
        cursor: not-allowed;
      }
    }

    &-lighter-teal-on-hover {
      background-color: var(--white);
      border: var(--standard-border);

      &:hover:not(&:disabled),
      &:hover&:focus:not(&:disabled) {
        background-color: var(--lighter-teal);
        border-color: var(--focus-light-teal);
      }

      &:disabled {
        background-color: var(--off-white-hover-disabled);
        color: var(--button-hover);
        cursor: not-allowed;
      }
    }

    &-delete {
      background-color: var(--white);
      border: var(--standard-border);

      &:hover:not(&:disabled),
      &:hover&:focus:not(&:disabled) {
        background-color: var(--status-warning);
        border-color: var(--status-warning-border);

        & .svg {
          fill: var(--high-red) !important;
        }
      }

      &:disabled {
        background-color: var(--off-white-hover-disabled);
        color: var(--button-hover);
        cursor: not-allowed;
      }
    }

    &-remove {
      background-color: var(--remove-red);
      border: var(--program-step-border-error);

      &:hover:not(&:disabled) {
        background-color: var(--erase-hover-red);
      }
    }
  }

  &__round-button {
    border-radius: 100%;
  }

  &__hide-border {
    border: unset;
  }
}
