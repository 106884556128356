.form-domains {
  &__info-action {
    margin-bottom: 0;

    .info-action--wrapper-buttons {
      display: none;
    }

    .text-link {
      display: flex;
      align-items: center;
    }
  }

  &__table {
    & .table__th:first-child {
      flex: unset !important;
      min-width: unset !important;
      width: unset !important;
    }

    & .nested-table-row {
      & > div:first-child {
        flex: unset !important;
        min-width: unset !important;
        width: unset !important;
      }
    }

    .actionable-nested-table__thead.actionable-nested-table__thead-fixed {
      border-radius: var(--standard-border-radius) var(--standard-border-radius) 0 0;
      top: var(--spacing-unit-x12);
      border-top: var(--standard-border);
    }

    &-row-action__container {
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__modal {
    &-body.modal-body {
      min-width: calc(var(--spacing-unit-x2) * 50);
      padding: var(--spacing-unit-x4) var(--spacing-unit-x7);
    }

    &-input {
      width: 31rem;
    }

    &-info {
      margin-top: var(--spacing-unit-x2);

      &-item {
        padding-left: var(--spacing-unit-x4);
        margin-top: var(--half-spacing-unit);
        position: relative;

        &::before {
          content: '';
          width: var(--half-spacing-unit);
          height: var(--half-spacing-unit);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: var(--spacing-unit);
          background-color: var(--light-gray);
        }
      }

      &-url {
        font-weight: var(--font-weight-medium);
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--spacing-unit-x2);
    padding-top: var(--spacing-unit-x4);
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--page-back);
    z-index: var(--z-index-9999);

    &-search {
      min-width: calc(var(--spacing-unit-x2) * 25);
    }
  }
}

/* stylelint-disable */
@media (max-width: 1220px) {
  /* stylelint-enable */
  .form-domains {
    &__info-action {
      .typography {
        display: inline;
      }

      .text-link {
        display: inline;
        margin-left: var(--spacing-unit);
      }
    }
  }
}
