.score-expression {
  display: flex;
  gap: var(--spacing-unit-x2);
  
  &__score-sheet-select,
  &__campaign-select {
    min-width: 20rem;
    max-width: 30rem;
  }
}
