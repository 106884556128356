.send-details-recipients {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  &__with-bottom {
    padding-bottom: var(--spacing-unit-x4);
  }

  .tableV2 {
    margin: 0 calc(-1 * var(--spacing-unit-x4));
  }

  &__recipients {
    display: flex;
    gap: var(--spacing-unit-x4);

    &-no-ellip {
      white-space: normal;
      overflow: unset;
      text-overflow: unset;
    }
  }

  &__add-modal {
    width: var(--add-recipients-modal-width);

    .radio-card__svg.radio-card__no-width {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
    }

    .radio-card {
      align-items: center;

      .svg.radio-card__svg--hover {
        fill: var(--text-teal);
      }
    }

    .button.select-asset-v2__save-button {
      margin-left: 0;
    }

    .modal-footer__inner {
      gap: var(--spacing-unit-x4);
    }
  }

  &__quick-link {
    margin-top: var(--spacing-unit-x2);
    display: flex;
    gap: var(--spacing-unit);
    align-items: center;
  }

  &__suppress-duplicate-banner {
    background-color: var(--lighter-off-white);
    border-radius: var(--standard-border-radius);
    border: var(--standard-border);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

    &__content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }

    &__tooltip {
      background-color: var(--border-gray);
      border-radius: calc(var(--large-border-radius) * 2);
    }
  }

  &__svg-tooltip {
    vertical-align: middle;
  }
}
