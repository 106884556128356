.mapping-field {
  display: grid;
  grid-template-columns: 45% 40% 15%;
  align-items: center;
  padding: calc(var(--spacing-unit) + var(--half-spacing-unit)) var(--spacing-unit-x4);

  &:hover {
    background-color: var(--hover-row-background-teal);

    .mapping-field__actions {
      visibility: visible;
      opacity: 1;
    }
  }

  &__report-field {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-tooltip {
      max-width: 17rem;
    }
  }

  &__actions {
    opacity: 0;
    visibility: hidden;

    &-reset {
      &:hover {
        background-color: var(--hover-row-button-background-teal);
      }
    }
  }

  &__select {
    display: flex;
    align-items: center;
    padding-right: var(--spacing-unit-x7);

    &-dropdown {
      max-width: 27.25rem;
      width: 100%;
    }

    &-separator {
      margin-left: var(--spacing-unit-x7);
      min-width: 1.33rem;
    }
  }

  &__confirmation-modal {
    & .modal-footer__inner {
      padding: var(--spacing-unit-x8) var(--spacing-unit-x6) var(--spacing-unit-x4);
    }

    & .confirmation-modal__body {
      padding: var(--spacing-unit-x4) var(--spacing-unit-x11) var(--spacing-unit-x2);
    }
  }
}
