.create-segment-modal {
  display: block;
  width: 43rem;

  &__body {
    min-height: var(--spacing-unit-x24);
    padding-top: var(--spacing-unit-x4) !important;
    padding-bottom: var(--spacing-unit-x6) !important;
  }

  &__text {
    margin-bottom: var(--spacing-unit-x2);
  }
}
