.manage-contacts-or-start-modal {
  width: 45rem;

  &__modal-body {
    min-height: 20rem;
  }

  &__text-one {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__non-first-start {
    width: 40rem;
  }

  &__radios {
    margin-top: var(--spacing-unit-x4);
  }

  .loader {
    margin: auto;
  }
}
