.event-modal {
  width: 60rem;

  &.modal {
    overflow: unset;
  }

  & .modal-body {
    scrollbar-gutter: stable;
    height: 53rem;
  }

  &__tabs {
    &__list {
      padding-left: 0;
      min-width: unset;
    }
  }

  &__content {
    margin-top: var(--spacing-unit-x3);
    border-top: var(--standard-border);
    padding-top: var(--spacing-unit-x4);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
  }

  &__name {
    display: flex;
    align-items: flex-end;
    gap: var(--spacing-unit);

    &-input {
      &__container {
        flex-basis: 95%;
      }
    }

    .inputV2__wrapper {
      flex: 1 1 auto;
    }
  }

  &__wrapper {
    &-flex-column {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
    }

    &-flex-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }
  }

  &__datetime {
    height: 5.16667rem;
    margin-bottom: var(--spacing-unit-x2);

    &-date-picker {
      flex-basis: 56%;
      align-self: flex-start;
    }

    &-time-picker {
      & .time-picker-v2-picker {
        width: unset;
        min-width: 10.3rem;
      }
    }

    &-typography {
      align-self: flex-end;
      padding-bottom: calc(var(--spacing-unit-x5) / 3);
    }
  }

  &__checkbox {
    height: var(--spacing-unit-x4);
    align-items: center;
  }

  &__separator {
    height: var(--spacing-unit-x4);
    border-left: var(--standard-border);
  }

  &__selectV2-popover {
    & div[data-radix-popper-content-wrapper] {
      & div[data-align='start'] {
        width: 25rem;
      }
    }
  }

  &__add-video-conferencing {
    height: var(--spacing-unit-x6);
  }
}
