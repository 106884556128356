.drop-zone {
  border: var(--dashed-border);
  border-radius: var(--large-border-radius);
  background-color: var(--input-background);
  text-align: center;
  padding: 3.833rem var(--spacing-unit-x4);

  &__upload-cloud-icon {
    height: var(--spacing-unit-x8);
    width: var(--spacing-unit-x8);
    fill: var(--button-hover);
  }

  &__text {
    margin-top: var(--spacing-unit-x2);
  }

  &__upload-button {
    margin: 0 auto;
    height: var(--spacing-unit-x6);
  }

  &__division {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--upload-image-drop-zone-division-width);
    margin: var(--spacing-unit-x3) auto;

    &::before,
    &::after {
      content: '';
      background-color: var(--button-hover);
      width: 100%;
      height: 0.08rem;
    }

    &::before {
      margin-right: var(--spacing-unit);
    }

    &::after {
      margin-left: var(--spacing-unit);
    }
  }

  &__drag-over {
    background-color: var(--off-white-focus);
    border: var(--dashed-border-focus);
    transition: var(--linear-80);

    & * {
      pointer-events: none;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x3);
    flex-direction: column;

    background-color: var(--status-warning);
    border-color: var(--remove-red);
    padding: 2.75rem var(--spacing-unit-x4);

    .section-headline {
      margin: unset;
    }

    &-message {
      .typography {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__file-loaded {
    padding: 0;
    border: none;
    background-color: var(--white);
  }

  &__file {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-unit-x5);
    height: var(--spacing-unit-x13);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x2);
    overflow: hidden;
    text-align: left;

    &-svg {
      position: relative;
      width: 4.16rem;
      height: var(--spacing-unit-x10);
      fill: var(--lighter-off-white);
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: calc(var(--spacing-unit) + var(--half-spacing-unit));
    }

    &-name {
      max-width: 30rem;

      &-wrapper {
        display: flex;
        align-items: center;
        gap: var(--spacing-unit-x2);
      }
    }

    &-remove {
      &-button {
        width: 1.33rem;
        height: 1.33rem;
        align-self: center;
        cursor: pointer;

        &:hover {
          background-color: unset;

          & > .svg {
            fill: var(--tab-gray);
          }
        }
      }

      &-icon {
        &.svg {
          fill: var(--tab-gray);
          height: 0.666rem;
          width: 0.666rem;
        }
      }
    }
  }

  .tooltip__trigger--disabled {
    .typography__type-link {
      cursor: not-allowed; 
    }
  }
}
