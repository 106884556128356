
.sendability {
  margin-top: var(--spacing-unit-x2);

  .button:first-of-type {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__not-sendable,
  &__sendable {
    margin-top: var(--spacing-unit-x2);

    .typography {
      margin-left: var(--spacing-unit-x2);
    }
  }

  &__not-sendable {
    padding-left: var(--spacing-unit-x6);

    > div {
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__sendable {
    padding-left: var(--spacing-unit-x12);
  }

  &__delivery-confirm-title {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__specific-error {
    .typography {
      margin-left: var(--spacing-unit-x2);
    }

    .svg {
      margin-bottom: calc(-1rem / 6);
    }
  }

  &__confirmation-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &-image {
      display: flex;
      justify-content: center;
      padding-bottom: var(--spacing-unit-x2);

      svg {
        fill: var(--text-gray);
      }
    }
  }

  &__confirmation-body {
    width: 40rem;
  }
}
