
.spinner-status {
  margin-right: var(--spacing-unit);

  &__label {
    margin-right: var(--spacing-unit-x2);
  }

  &__icon {
    cursor: pointer;
    margin-right: var(--spacing-unit-x2);
    margin-left: var(--spacing-unit);
    animation: linear 2s rotate infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
}
