
.account-details-modal {
  width: 50rem;

  &__body {
    display: flex;
    min-height: 18rem;
    flex-direction: column;
    justify-content: space-between;
  }

  &__fields {
    margin-top: var(--spacing-unit-x2);
  }

  &__field-item {
    margin-bottom: var(--spacing-unit);
  }

  &__label {
    display: inline-block;
    width: var(--spacing-unit-x19);
  }

  &__contact {
    a {
      margin-left: var(--spacing-unit);
    }
  }

  .modal-footer__inner {
    justify-content: end;
  }
}
