.selectable-columns-section {
  &:has(&__loader-container) {
    background-color: var(--page-back);
  }

  & + & {
    padding-top: var(--spacing-unit-x3);
    border-top: var(--standard-border);
  }

  &__header {
    width: 100%;
    justify-content: space-between;
    padding: 0 var(--spacing-unit-x2) var(--spacing-unit-x3) var(--spacing-unit-x2);
    background-color: var(--white);
    position: sticky;
    top: 0;

    &-sticky {
      box-shadow: var(--box-shadow);
    }

    &,
    &-clickable {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }

    &-clickable {
      user-select: none;
      
      > svg {
        width: var(--spacing-unit-x2);
        height: var(--spacing-unit-x2);
      }

      &:hover > svg {
        fill: var(--text-teal) !important;
      }

      &:not(&-disabled) {
        cursor: pointer;
      }
    }

    &-clickable:not(&-clickable-disabled):hover &-title > div {
      color: var(--text-teal);
    }

    &-title {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }
  }

  &__field {
    height: var(--spacing-unit-x4);
    align-items: center !important;

    & label {
      display: flex;
      align-items: center;
      margin-left: var(--spacing-unit-x2);
      gap: calc(8rem / 12);
    }
  }

  &__fields-container {
    padding: 0 var(--spacing-unit-x2) var(--spacing-unit-x3);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
  }

  &__loader-container {
    background-color: var(--page-back);
    padding: var(--spacing-unit-x8) var(--spacing-unit-x2);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x3);
    align-items: center;

    .loader,
    .loader svg {
      width: var(--spacing-unit-x8);
      height: var(--spacing-unit-x8);
    }
  }
}