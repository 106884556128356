.crm-segment-details {
  &__header {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__description {
    margin-bottom: var(--spacing-unit-x3);
  }

  &__button {
    height: var(--spacing-unit-x6);

    .svg {
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__input,
  &__select {
    width: 33rem;
  }

  &__keep-sync {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
    margin-bottom: var(--spacing-unit-x4);

    .svg {
      fill: var(--light-gray);
    }
  }

  &__options-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);

    .radio-with-options__radio {
      margin: 0;
    }
  }

  &__radio-option-edit {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit-x3);
    justify-content: center;
  }

  &__radio-option-create {
    &-container {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
    }

    &-error {
      margin-left: var(--spacing-unit-x4);
    }
  }
}
