.draft-messages-table {
  margin-top: var(--spacing-unit-x6);

  &__table__table-holder {
    width: 100%;

    .tr {
      max-width: 100% !important;

      &:hover .td {
        text-decoration: underline;
      }
    }
  }

  &__title-col {
    font-weight: var(--font-weight-medium);

    .ellip {
      &:focus-visible {
        box-shadow: var(--keyboard-focus-box-shadow);
      }

      & em {
        color: var(--light-gray);
      }
    }
  }

  &__sent-by-col {
    width: 13rem;
  }

  .tableV2-tbody__row-disabled {
    td:first-child {
      pointer-events: all;
    }

    .svg {
      fill: var(--button-gray);
    }
  }
}
