.segment-details-info-hover {
  display: block;

  &__section {
    &-title {
      color: var(--light-gray);
    }

    &-tree {
      min-height: var(--spacing-unit-x17);
    }

    &-type {
      display: flex;
      justify-content: space-between;
      padding: 0 0 var(--spacing-unit-x2) 0;

      &-actions {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-unit-x2);

        a {
          justify-content: flex-end;
        }
      }
    }
  }

  &__divider {
    width: 100%;
    height: var(--standard-border-width);
    background-color: var(--border-gray);
    margin: var(--spacing-unit-x3) 0 var(--spacing-unit-x3) 0;
  }

  &__system {
    display: flex;

    &-soft-bounces {
      display: block;
    }
  }

  &__crm {
    &-error-tooltip {
      background-color: var(--white);

      &-trigger {
        margin-top: var(--spacing-unit-x2);

        &:hover {
          cursor: pointer;
        }
      }
      
      .tooltip__content:has(&) span .tooltip__arrow {
        fill: var(--white);
      }
    }

    &-status {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      &-title {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &-subtitle {
        flex-wrap: nowrap;
        margin-top: var(--spacing-unit-x2);

        & > .text-link {
          margin-left: var(--spacing-unit);
        }
      }

      &-icon {
        &-warning-solid-no-fill {
          fill: var(--yellow-caution);
        }

        &-fail {
          fill: var(--remove-red);
        }

        &-reload-no-fill {
          color: var(--teal);
        }

        &-check {
          fill: var(--success-green);
        }

        &-opt-out {
          fill: var(--disabled-plus-gray);
        }
      }
    }
  }

  &__sources {
    display: flex;
    flex-direction: column;
    gap: var(--half-spacing-unit);
    justify-content: center;
    width: 18rem;

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    a {
      white-space: nowrap;
    }
  }
}
