.sort-drop-down {
  width: max-content;
  display: flex;
  align-items: center;

  &__label {
    color: var(--tag-no-color);
    margin-right: var(--spacing-unit);
  }

  &__trigger {
    padding: 0.666rem 0;

    &:hover {
      .typography {
        color: var(--text-teal);
      }

      svg {
        fill: var(--text-teal);
      }
    }

    svg {
      fill: var(--text-gray);
      width: 0.666rem;
      height: 0.666rem;
      margin: 0 0.666rem;
    }
  }

  &__menu {
    margin-top: var(--half-spacing-unit);
    min-width: 21rem;
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    box-shadow: var(--tooltip-box-shadow);
    background-color: var(--white);

    &__wrapper {
      z-index: var(--z-index-9999);
    }

    &-group {
      padding: var(--spacing-unit) 0;

      &:not(:first-child) {
        border-top: var(--standard-border);
      }
    }

    &-group-name,
    &-option {
      padding: 0.666rem var(--spacing-unit-x2) 0.666rem var(--spacing-unit-x4);
    }

    &-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        background-color: var(--selectable-row-hover);
      }

      &--selected {
        background-color: var(--lighter-teal);
        box-shadow: var(--radio-card-box-shadow);
        padding: 0 var(--spacing-unit-x2) 0 var(--spacing-unit-x4);

        &:hover {
          background-color: var(--hover-row-button-background-teal);
        }
      }

      &-indicator-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--spacing-unit-x6);
        height: var(--spacing-unit-x6);
      }
    }
  }

  &__drop-down__trigger {
    align-self: end;
  }
}
