
.draggable-column-actions {
  display: flex;
  align-items: center;

  &__disabled {
    position: relative;
    width: var(--spacing-unit-x5);
    height: var(--spacing-unit-x5);
    cursor: pointer;

    svg {
      color: var(--disabled-edit-columns-action);
      fill: var(--disabled-edit-columns-action);
      position: absolute;
      top: 25%;
      left: 25%;
    }
  }

  .button {
    width: var(--spacing-unit-x5);
    height: var(--spacing-unit-x5);

    svg {
      width: calc(var(--spacing-unit-x14) / 6);
      height: calc(var(--spacing-unit-x14) / 6);
    }
  }

  &__action-fixed {
    visibility: visible !important;
  }
}