
.segment-performance {
  .info-cards {
    &__cards {
      gap: var(--spacing-unit-x2);
    }

    &__card-container {
      margin-right: unset;
      width: 100%;
    }
  }
}
