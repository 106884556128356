
.segmented-single-line-chart {
  display: flex;
  flex-direction: row;
  gap: calc(var(--spacing-unit) / 6);
  height: calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
  width: 100%;

  &__title {
    margin: var(--spacing-unit-x2) 0 var(--spacing-unit) 0;
  }

  &__empty {
    background-color: var(--border-gray);
    border-radius: var(--segmented-line-chart-border-radius);
    cursor: default;
  }

  &__segment {
    border: var(--tag-color-border);
    height: 100%;
    width: 100%;

    &:hover {
      cursor: default;
      opacity: 1 !important;
    }

    &-first {
      border-radius: var(--segmented-line-chart-start-border-radius);
    }

    &-second {
      background-color: var(--segmented-line-chart-background-2);

      &:hover {
        background-color: var(--segmented-line-chart-background-hover-2);
      }
    }

    &-third {
      background-color: var(--segmented-line-chart-background-3);

      &:hover {
        background-color: var(--segmented-line-chart-background-hover-3);
      }
    }

    &-fourth {
      background-color: var(--border-clicked);
    }

    &-last {
      border-radius: var(--segmented-line-chart-end-border-radius);
    }
  }

  &__tooltip {
    background-color: var(--white);
    border: var(--standard-border);

    &-content {
      display: flex;
      flex-direction: column;

      &-title {
        display: flex;
        gap: var(--spacing-unit);
        align-items: baseline;
      }

      &-subtitle {
        margin-left: calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
      }

      &-dot {
        width: calc(var(--spacing-unit) + var(--half-spacing-unit));
        height: calc(var(--spacing-unit) + var(--half-spacing-unit));
        border-radius: 50%;
        flex-shrink: 0;
      }
    }

    &-trigger {
      height: 100%;
      width: 100%;
    }

    &-arrow {
      fill: var(--styled-tooltip-border-blue);
      margin-top: 0;
    }
  }
}
