
.last-updated {
  display: flex;
  align-items: center;

  &__label {
    margin-right: var(--spacing-unit-x2);
  }

  &__icon {
    cursor: pointer;

    &--clicked {
      animation: rotate;
      animation-duration: 2s;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
}
