
.drag-preview {
  width: fit-content;
  min-width: var(--spacing-unit-x28);
  padding: var(--spacing-unit) var(--spacing-unit-x3);
  border-radius: var(--standard-border-radius);
  background-color: var(--teal);
  box-shadow: var(--drag-preview-box-shadow);
  line-height: var(--line-height-larger);
  text-shadow: var(--drag-preview-text-shadow);

  &__disabled {
    background-color: var(--lightest-gray);
  }
}
