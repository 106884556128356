.forms-upgrade-configure {
  .radio-card {
    height: var(--spacing-unit-x14);
  }

  &__subtitle {
    margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x4) 0;
  }

  &__toggle-container > div {
    padding: var(--spacing-unit-x4);
    border: var(--standard-border);
    border-bottom: none;
    width: 100%;

    .toggle {
      margin-left: unset;
    }

    &:first-child {
      border-top-left-radius: var(--standard-border-radius);
      border-top-right-radius: var(--standard-border-radius);
    }

    &:last-child {
      border-bottom: var(--standard-border);
      border-bottom-left-radius: var(--standard-border-radius);
      border-bottom-right-radius: var(--standard-border-radius);
    }
  }
}
