.export-to-ftp-file-modal {
  display: block;
  width: 50rem;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }

  &__label {
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x2) 0;
  }
}
