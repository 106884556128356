
.print-button {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;

  &:focus-visible {
    box-shadow: var(--keyboard-focus-box-shadow);
  }

  &:hover {
    border-radius: 0.25rem;
    background-color: var(--off-white-focus);
    text-decoration: none;
  }

  .svg {
    margin-right: 0.25rem;
  }
}
