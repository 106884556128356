.add-user-button {
  padding: var(--spacing-unit) var(--spacing-unit-x2);
  border-radius: var(--standard-border-radius);
  width: fit-content;
  cursor: pointer;

  &__add {
    outline: var(--standard-border-width) solid var(--border-teal);
    outline-offset: calc(var(--standard-border-width) * -1);

    &:hover {
      outline: none;

      > div {
        color: var(--white) !important;
      }
    }
  }

  &:hover,
  &__just-added {
    background-color: var(--teal);
  }

  &__remove {
    background-color: var(--remove-red);

    &:hover {
      background-color: var(--erase-hover-red);
    }
  }
}
