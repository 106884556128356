.review-and-send-modal {
  width: 40rem;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-unit-x4);
    padding: var(--spacing-unit-x6) var(--spacing-unit-x4) var(--spacing-unit-x2);
  }
}
