.single-line-chart {
  display: block;

  &__values {
    align-items: center;
    display: inline-flex;
    width: 100%;
    margin-bottom: var(--spacing-unit);

    &-small-total {
      align-items: baseline;
    }

    div:last-child {
      margin-left: auto;
    }
  }

  &__bar {
    position: relative;
    height: var(--spacing-unit-x2);

    div {
      height: inherit;
      width: 100%;
      position: absolute;
      border-radius: var(--extra-large-border-radius);
    }

    &-total {
      background-color: var(--border-gray);
      width: 100%;

      &:hover {
        cursor: default;
      }
    }

    &-actual {
      width: 100%;
      border: var(--tag-color-border);

      &:hover {
        cursor: default;
      }

      &-hidden {
        display: none;
      }

      &-teal {
        background-color: var(--teal);
      }

      &-purple {
        background-color: var(--purple);
      }

      &-orange {
        background-color: var(--orange);
      }

      &-red {
        background-color: var(--remove-red);

        &:hover {
          cursor: default;
          background-color: var(--remove-red);
        }
      }

      &-warning {
        background-color: var(--yellow-caution);
      }
    }
  }

  &__percentage {
    &-warning {
      color: var(--remove-red);
    }

    &-fixed {
      &-teal {
        color: var(--teal);
      }

      &-orange {
        color: var(--orange);
      }

      &-purple {
        color: var(--purple);
      }

      &-text-teal {
        color: var(--text-teal);
      }
    }
  }

  &__tooltip {
    height: 100% !important;
    width: 100%;

    &:hover {
      cursor: default;
    }
  }

  &__warning-icon {
    fill: var(--yellow-caution);
    margin: 0 var(--spacing-unit) calc(var(--spacing-unit) / 6) 0;
  }

  &__helper-text {
    padding-top: calc(var(--spacing-unit-x3) / 2);
    display: flex;
    align-items: center;
  }
}
