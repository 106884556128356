.incoming-webhook-hmac-authentication {
  display: grid;
  column-gap: var(--spacing-unit-x3);
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  
  &__calendly-input {
    display: flex;
  }
}
