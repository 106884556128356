.upload-image {
  max-width: 100%;
  max-height: var(--upload-image-drop-zone-size);
  min-height: var(--upload-image-drop-zone-height);
  position: relative;

  /* stylelint-disable */
  @media (max-width: 1200px) {
    /* stylelint-enable */
    max-width: 100%;
  }

  &:hover &-hover {
    display: block;
  }

  &__drop-zone {
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-holder {
    max-width: inherit;
    max-height: inherit;
    min-height: inherit;
    border-radius: var(--large-border-radius);
    border: var(--dashed-border);
    background-color: var(--input-background);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-progress {
    background: var(--hover-row-background-teal);
    border-color: var(--light-theme-selected-item);
    padding: var(--spacing-unit-x4);
    width: 100%;
    min-height: inherit;
    text-align: center;

    &-bar {
      width: calc(var(--spacing-unit) * 50);
      margin-bottom: var(--spacing-unit-x6);
    }
  }

  &-image {
    max-width: inherit;
    max-height: inherit;
    padding: var(--spacing-unit-x4);
  }

  &-hover {
    position: absolute;
    display: none;
    border-radius: var(--large-border-radius);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--media-card-background-hover);

    &-content {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: var(--spacing-unit-x2);
      padding: 10rem 15rem;
    }

    &-remove-button,
    &-change-button {
      min-width: var(--spacing-unit-x24);
    }

    &-change-button {
      margin: auto;
      color: var(--white);

      &:hover {
        color: unset;
        background-color: var(--white);
      }
    }
  }

  &__error-name {
    max-width: var(--upload-image-error-name-width);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    display: block;
  }
}
