.display-toggle-card {
  width: 100%;
  padding: calc(1.5 * var(--spacing-unit)) var(--spacing-unit-x2) calc(1.5 * var(--spacing-unit)) var(--spacing-unit-x3);
  border-radius: var(--standard-border-radius);
  border: var(--standard-border);
  justify-content: space-between;

  &__label {
    display: flex;
    align-items: center;
  }

  &__close {
    background-color: var(--off-white-hover-disabled);
  }

  &__icon {
    padding: var(--spacing-unit);
    border-radius: var(--standard-border-radius);

    &:hover {
      background-color: var(--hover-row-button-background-teal);
    }
  }

  &:hover {
    border-color: var(--text-teal);
    text-decoration: none;
  }
}
