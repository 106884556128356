
.edit-branch-step {
  width: 43rem;

  &__row {
    display: flex;
    width: 31rem;

    input {
      flex: 0 0 26rem;
    }

    select {
      margin-left: var(--spacing-unit-x2);
      flex: 0 0 10rem;
    }
  }

  &__step-name {
    width: 43rem;
  }
}
