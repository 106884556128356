.logos {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-gap: var(--spacing-unit-x4);
  }

  &__loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  &__grid-loading-state {
    visibility: hidden;
  }

  &__sub-header {
    &__container {
      display: flex;
      justify-content: space-between;
      margin-bottom: calc(var(--spacing-unit-x2) + var(--spacing-unit));

      &-visibility {
        visibility: hidden;
      }

      &-info-title {
        display: flex;
        grid-gap: var(--spacing-unit);
      }

      &-actions {
        display: flex;

        .switch-buttons {
          margin-right: var(--spacing-unit-x2);
        }
      }
    }
  }

  &__img-container {
    height: 3.333rem;
    min-width: 3.333rem;
    width: 3.333rem;
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    background-color: var(--white);
    padding: var(--half-spacing-unit);
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &-invalid-list {
      padding: var(--spacing-unit);
      background-color: var(--status-warning);
      border-radius: var(--standard-border-radius);
    }
  }

  .image-media-card {
    .media-card__body {
      padding: var(--spacing-unit-x4);
      height: 17.6667rem;
      max-width: 27.75rem;
    }

    &.media-card--default {
      border: var(--teal-border);
    }

    &--error {
      img {
        padding: var(--spacing-unit-x6);
        background-color: var(--status-warning);
        border-radius: var(--large-border-radius);
      }
    }
  }

  &__caution {
    margin-bottom: var(--spacing-unit-x4);

    &-default-logo {
      display: flex;
      flex-direction: column;
    }
  }

  .td {
    gap: var(--spacing-unit-x2);
  }

  .typography.typography__type-body-text {
    flex: 1 0 auto;
  }
}
