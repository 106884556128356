.list-page-scroll-area {
  position: relative;
  height: 100%;
  padding-top: var(--spacing-unit-x4);

  &__viewport {
    height: inherit;
    overflow: auto;
    scrollbar-gutter: stable;
  }

  &__top-bar {
    visibility: hidden;
    position: absolute;
    background-color: var(--page-back);
    height: var(--spacing-unit-x4);
    left: 0;
    top: 0;
    z-index: var(--z-index-999);

    &-visible {
      visibility: visible;
    }
  }

  &__thead-shadow {
    .actionable-nested-table__thead {
      box-shadow: var(--box-shadow);
      border-top: var(--standard-border);
    }
  }
}
