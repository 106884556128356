
.field-mapping-row {
  &__info-text {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__tooltip {
    width: 24rem;

    &-icon {
      margin-top: var(--half-spacing-unit);
    }
  }

  &__error {
    padding-top: var(--spacing-unit);

    &-field,
    &-field:hover {
      border-color: var(--red);
    }
  }

  &__tooltip:first-child {
    text-align: center;
  }

  &__row {
    display: flex;
    width: 100%;
    gap: var(--spacing-unit-x6);

    &-custom {
      gap: var(--spacing-unit-x2);
    }
  }

  &__left {
    width: 100%;
  }

  &__right {
    width: 25rem;

    &-data {
      width: 25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__header {
    display: flex;
    gap: var(--spacing-unit);
  }

  &__delete-button {
    visibility: hidden;
    display: flex;
    justify-content: center;
    margin-left: var(--spacing-unit-x2);
  }

  &:hover &__delete-button[aria-hidden='false'] {
    visibility: visible;
  }

  &__scoresheet-selector {
    margin-right: var(--spacing-unit-x9);
    margin-left: 0;

    &-container {
      margin-top: var(--spacing-unit-x4);
    }

    &__select-text {
      align-items: center;
      margin-right: var(--spacing-unit-x4);

      > div {
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
