.sms-sent-message-table {
  margin-top: var(--spacing-unit-x6);

  &__table__table-holder {
    width: 100%;

    .tr {
      max-width: 100% !important;
    }
  }

  .table {
    .tbody {
      max-height: 37.25rem;
      overflow: auto;
    }

    &__td:first-child {
      padding-right: var(--spacing-unit-x4);
    }
  }

  .sms-sent-message-table__report {
    display: none;
  }

  .tbody .tr:hover {
    .sms-sent-message-table__opt-out {
      display: none;
    }

    .sms-sent-message-table__report {
      display: block;
    }
  }
}
