.condition-toggle-card {
  &__toggletip {
    &__content {
      width: 40rem;
      overflow: auto;
      max-height: min(75rem, 80vh);
      display: flex;
      flex-direction: column;
    }

    &--accordion {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;

      &__item {
        padding: 0;
        border-bottom: none;
        border-top: var(--standard-border);

        &:first-child {
          border-top: var(--standard-border-dark);
        }

        &:last-child {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          overflow: auto;
        }
      }

      &__header {
        min-height: var(--spacing-unit-x10);
      }

      &__button {
        border-radius: 0;
        padding-left: var(--spacing-unit-x4);
        justify-content: space-between;

        &:hover {
          background-color: var(--hover-row-background-teal);
        }
      }

      &__icon {
        width: var(--spacing-unit-x5);
        height: var(--spacing-unit-x5);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &:hover {
          background-color: var(--hover-row-button-background-teal);

          svg {
            fill: var(--text-teal);
          }
        }
      }

      &__panel {
        margin-top: 0;
        overflow: auto;
      }
    }

    &__wrapper {
      &-content {
        max-height: 25rem;
      }

      &-conditions {
        flex-grow: 1;
      }
    }
  }
}
