.drafts-listing-page-container {
  &__create-draft-button {
    height: var(--spacing-unit-x6);

    &-disabled {
      &.button:hover {
        cursor: not-allowed;
      }
    }

    .svg {
      fill: var(--white);
      margin-right: var(--spacing-unit);
    }
  }

  &__draft-name {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-unit);
      overflow: hidden;
    }

    .info-hover-card {
      height: var(--spacing-unit-x3);
      visibility: hidden;

      &__visible {
        visibility: visible;
      }
    }
  }

  &__draft-note {
    &__trigger {
      border-radius: var(--standard-border-radius);

      &:hover {
        background-color: var(--hover-row-button-background-teal);
      }

      &--focus {
        background-color: var(--hover-row-button-background-teal);
        border: var(--standard-border-dark-blue);
        outline: var(--input-outline-border);
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x3);
      padding-right: var(--spacing-unit-x2);
    }
  }
}
