.recipients-card {
  width: 100%;
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  background-color: var(--white);
  margin-top: var(--spacing-unit-x3);

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
    background-color: var(--page-back);

    &-title {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);

      &-tooltip {
        max-width: 25rem;
      }
    }

    &-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-unit);

      &-container {
        display: flex;
        align-items: center;
        gap: var(--spacing-unit-x2);
      }

      &-button {
        padding: var(--spacing-unit);

        & .svg {
          width: 1.33rem;
          height: 1.33rem;
        }
      }
    }
  }

  &__body {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.33rem;
    padding: var(--spacing-unit-x3);

    &-recipient {
      overflow: hidden;

      .button {
        width: 100%;
        justify-content: flex-start;
      }

      & .typography {
        &:hover {
          color: var(--text-teal);
          text-decoration: underline;
        }
      }
    }
  }

  &__loading {
    height: 3.666rem;
    padding: 0;

    .spinner__svg {
      max-height: var(--spacing-unit-x6);
      max-width: var(--spacing-unit-x6);
      min-height: var(--spacing-unit-x6);
      min-width: var(--spacing-unit-x6);
    }
  }
}
