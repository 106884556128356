.transactional-email-pane {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);

  &__toggle-container {
    display: flex;
    padding: var(--spacing-unit-x3);
    align-items: center;
    gap: var(--spacing-unit-x4);
    align-self: stretch;
    border-radius: 0.33333rem;
    background-color: var(--light-theme-background-darker);
    max-width: 45rem;

    &-disabled {
      cursor: not-allowed;
    }

    &__text {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
    }

    .toggle {
      flex-shrink: 0;
    }
  }
}
