.event-triggered-email-pane {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x2);

  &__divider {
    border-top: var(--standard-border);
    border-bottom: none;
    width: 100%;
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x2);
  }

  &__content-missing__tooltip__trigger-wrapper {
    display: block;
  }

  &__options {
    &__accordion {
      padding: var(--spacing-unit-x2);
      border-radius: 0.3334rem;
      background-color: var(--input-background);
      margin-top: var(--spacing-unit);
      border-bottom: none;
      max-width: 45rem;

      &__panel[data-state='open'] {
        animation: var(--slide-down-accordion-fade);
      }

      &__panel[data-state='closed'] {
        animation: var(--slide-up-accordion-fade);
      }

      &__item,
      &__button {
        padding: 0;
        border-bottom: none;
      }

      &__header {
        height: unset;
        gap: var(--spacing-unit-x2);
      }

      &__button {
        display: flex;
        justify-content: flex-end;
        gap: var(--spacing-unit-x2);
        align-items: center;
        width: 100%;

        svg {
          width: calc(var(--spacing-unit-x2) * 3/4);
          height: calc(var(--spacing-unit-x2) * 3/4);
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
    }

    &__action {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__details-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x3);
  }

  &__id-container {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-unit-x3);
    align-items: center;
    flex-direction: row;
    gap: var(--spacing-unit-x2);
    border-radius: 0.33333rem;
    background: var(--input-background);
  }

  &__toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
