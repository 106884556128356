.email-layouts-modal-content {
  &__search-results {
    border-bottom: var(--standard-border);
  }

  &__grid {
    grid-gap: var(--spacing-unit-x4);
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    padding: var(--half-spacing-unit) var(--spacing-unit-x4) var(--spacing-unit-x4);

    &__spinner {
      height: auto;
    }
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  }

  &__system-tooltip {
    margin: 0 var(--half-spacing-unit);
  }

  &__lazy-load-container {
    height: calc(100% - var(--spacing-unit-x10));
  }
}
