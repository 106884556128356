.create-program-from-template {
  width: 50rem;

  &__body-text {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__asset {
    &-first {
      display: flex;
      gap: var(--spacing-unit-x2);
      align-items: baseline;
      padding-top: var(--spacing-unit-x2);
      padding-bottom: var(--spacing-unit-x2);
      border-bottom: var(--standard-border);

      .svg {
        fill: var(--teal);
      }
    }

    &-no-border {
      border-bottom: none;
    }

    &-description {
      flex: 1;
      max-width: 41rem;
    }
  }
}
