.trueOpenSettings {
  padding-top: var(--spacing-unit-x4);

  &__page-header {
    gap: var(--spacing-unit-x2);
  }

  &__page-header-headline {
    flex: unset;
  }

  &__reporting-wrapper {
    background: var(--white);
    padding: var(--spacing-unit-x4);
    border-radius: 0.3333rem;
    margin-top: var(--spacing-unit-x4);
    border: var(--standard-border);
  }

  &__reporting-top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-unit-x4);
    height: var(--spacing-unit-x6);

    &-title-edit {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }
  }

  &__reporting-bottom-section {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);

    &-option {
      &:first-child &-content {
        border-bottom: var(--standard-border);
        padding-bottom: var(--spacing-unit-x4);
      }

      &-content {
        display: flex;
        gap: var(--spacing-unit-x4);
        align-items: center;
        justify-content: space-between;

        &-title {
          margin-bottom: var(--spacing-unit-x2);
        }
      }
    }
  }

  &__caution {
    margin: var(--spacing-unit-x4) 0;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-unit-x4);
  }
}
