.data-analytics {
  width: 100%;
  display: flex;

  &__card {
    max-width: var(--data-analytics-full-screen-card-max-width);
    padding-right: var(--spacing-unit-x4);
    padding-bottom: var(--spacing-unit);
    padding-top: var(--spacing-unit);

    & + & {
      padding-left: var(--spacing-unit-x4);
      border-left: var(--standard-border);
    }

    &-value {
      margin-bottom: var(--spacing-unit-x2);

      .typography {
        font-size: var(--page-header-font);
      }
    }

    &-value,
    &-title {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }

    &-info-tooltip {
      width: var(--data-analytics-tooltip-width);
    }

    /* stylelint-disable */
    @media (max-width: 1280px) {
      /* stylelint-enable */
      max-width: var(--data-analytics-card-max-width);
      padding: var(--spacing-unit) var(--spacing-unit-x3);

      &-value {
        & .typography {
          font-size: var(--sub-header-font-size);
        }
      }
    }
  }
}
