.inbox-import-decline-modal {
  width: 50rem;
  height: 42.5rem;

  .tableV2 {
    border-radius: 0;
    min-width: unset;

    tr:hover {
      .tableV2-row-action {
        background: linear-gradient(90deg, hsla(0, 0%, 98%, 0) 0, var(--hover-row-background-teal) 2.5rem, var(--hover-row-background-teal) 100%);
      }
    }
  }

  .tableV2-tbody__td,
  .tableV2-head__row th {
    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  .tableV2-row-action {
    display: flex;
    background: var(--white);
    height: 100%;
  }

  &__preview {
    cursor: pointer;

    &:hover .svg {
      fill: var(--text-teal);
    }
  }
}
