.edit-append-to-segment-step {
  display: block;
  width: 50rem;
  overflow: visible !important;

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50rem;
    height: 27rem;
  }

  &__flex {
    display: flex;

    &-align-items {
      align-items: center;
    }

    > .button {
      flex-basis: 30%;
      margin-left: var(--spacing-unit-x2);
    }
  }

  &__text {
    margin: var(--spacing-unit) 0 var(--spacing-unit-x3) 0;
  }

  &__step-name {
    color: var(--light-gray);
  }

  &__svg {
    margin-right: var(--spacing-unit);
  }

  &__select-label {
    margin: var(--spacing-unit-x3) 0 var(--spacing-unit-x2) 0;

    &-tooltip {
      margin-left: var(--spacing-unit);
    }
  }

  &__dropdown {
    flex-basis: 70%;

    & .nested-dropdown__wrapper {
      & .nested-dropdown__dropdown-wrapper {
        width: 43rem;
        margin-top: var(--spacing-unit-x6);
      }
    }
  }

  &__select-or-create-item {
    margin-top: unset;
  }
}
