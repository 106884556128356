.ip-addresses {
  background-color: var(--page-back);

  &__inner-container {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    background-color: var(--white);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  }

  &__sub-header-container {
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x4);
  }

  &__sub-header {
    display: inline;
    width: 85%;
  }

  &__more-info {
    margin-left: var(--half-spacing-unit);
    display: inline-block;
  }

  &__info-action {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__list {
    display: flex;
    justify-content: space-between;
  }

  &__error-input {
    color: var(--remove-red);
  }

  &__ip-column {
    display: flex;
    flex-direction: column;
    width: 29%;
  }

  &__list-container {
    margin-top: var(--spacing-unit-x6);
  }

  &__single-input-container {
    margin-bottom: 1.333rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__confirmation {
    width: 40.25rem;
    min-width: 40.25rem;
  }
}
