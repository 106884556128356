.blocked-modal {
  width: 40rem;
  text-align: center;

  &__image {
    max-width: 16rem;
    margin: var(--spacing-unit-x7) 0;
  }

  &__title {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__button {
    min-width: 25rem;
    margin-top: var(--spacing-unit-x5);
    margin-bottom: var(--spacing-unit-x6);
  }
}