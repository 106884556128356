.landing-page-manager-header {
  padding: var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x5);

  &__status {
    margin-right: var(--spacing-unit-x3);
    gap: var(--spacing-unit-x2);
    padding: 0 var(--spacing-unit-x2);
    white-space: nowrap;

    &-dot {
      --dot-size: calc(var(--spacing-unit-x3) / 2);
      border-radius: 50%;
      height: var(--dot-size);
      width: var(--dot-size);

      &-enabled {
        background: var(--success-green);
      }

      &-disabled {
        background: var(--disabled-plus-gray);
      }
    }
  }

  &__dirty {
    border-right: var(--standard-border);
    padding-right: var(--spacing-unit-x4);
    height: var(--spacing-unit-x6);
    margin-right: var(--spacing-unit-x2);
  }
}
