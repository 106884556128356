.segments-insights-settings-modal {
  width: 100rem;
  height: 60rem;

  &__body {
    scrollbar-gutter: stable;

    > div:not(:first-child) {
      padding: var(--spacing-unit-x4) 0;
    }

    > div:not(:first-child):not(:last-child) {
      border-bottom: var(--standard-border);
    }
  }
}
