.entity-loader {
  &__row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  &__filterable {
    flex: 1;
  }

  &__empty_row {
    height: var(--spacing-unit-x10);
  }

  &__col_count {
    padding-right: var(--spacing-unit-x4);
    flex: 1 1 50%;
    align-items: flex-end;
    display: flex;
    flex-flow: column;
  }

  &__col_count_label {
    background-color: var(--off-white-hover);
    padding: var(--spacing-unit-x2);
    line-height: var(--line-height-standard);
    border-radius: 0.333rem;
  }

  &__bold {
    font-weight: var(--font-weight-bold);
  }

  &__less-bold {
    font-weight: var(--font-weight-lighter);
  }
}
