.info-hover-card {
  &__content {
    display: flex;
    border-radius: var(--standard-border-radius);
    flex-direction: column;
    width: 25rem;
    background-color: var(--white);
    overflow: hidden;
    box-shadow: var(--nav-box-shadow);
    max-height: 30rem;
    z-index: var(--z-index-99999);

    &[data-side='top'] {
      & .info-hover-card__arrow {
        fill: var(--white);
      }
    }

    &[data-side='bottom'] {
      & .info-hover-card__arrow {
        fill: var(--dark-blue);
      }
    }
  }

  &__children {
    padding: var(--spacing-unit-x4);
  }

  &__hidden {
    visibility: hidden;
  }

  &__trigger {
    display: flex;

    &-icon {
      fill: var(--teal);
    }
  }

  &__visible {
    visibility: visible;
  }

  &__header {
    background-color: var(--dark-blue);
    padding: var(--spacing-unit-x3);

    &-hidden {
      > div {
        visibility: hidden;
      }
    }
  }

  &__footer {
    height: auto;
    border-top: var(--standard-border);
    padding: var(--spacing-unit-x3);

    &-hidden {
      > div {
        visibility: hidden;
      }
    }
  }

  &__gradient {
    height: var(--spacing-unit-x3);
    pointer-events: none;
    position: absolute;
    width: 100%;
    left: 0;

    &-bottom {
      background: linear-gradient(180deg, transparent 0%, var(--white) 100%);
      bottom: 0;
    }

    &-top {
      background: linear-gradient(360deg, transparent 0%, var(--white) 100%);
      top: 0;
    }
  }

  &__loader {
    z-index: 1;
    position: absolute;
  }

  &__scroll {
    & .scroll-area__scrollbar {
      margin-right: calc(var(--spacing-unit-x2) / 3);
      padding: calc(var(--spacing-unit-x2) / 3) 0 calc(var(--spacing-unit-x2) / 3) 0;
    }
  }

  &__description {
    border-bottom: var(--standard-border);
    padding: var(--spacing-unit-x3) 0;

    &-long {
      padding-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      /* stylelint-disable */
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      /* stylelint-enable */

      &-expended {
        display: block;
      }
    }

    &-title {
      padding: 0 var(--spacing-unit-x3) var(--spacing-unit);
    }

    &-line {
      padding: 0 var(--spacing-unit-x3);
      align-items: center;
      width: 28rem;

      .svg {
        margin-right: var(--spacing-unit);
      }

      & & {
        padding: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    &-show-link {
      padding: var(--spacing-unit-x2) var(--spacing-unit-x3) 0;
    }
  }
}
