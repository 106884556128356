.svg {
  &--smaller-icon {
    width: 0.83334rem;
    height: 0.83334rem;
    fill: var(--text-gray);
  }

  &--light-smaller-icon {
    width: 0.83334rem;
    height: 0.83334rem;
    fill: var(--light-gray);
  }

  &--icon {
    width: 1rem;
    height: 1rem;
    fill: var(--text-gray);
  }

  &--icon-medium {
    width: 0.667rem;
    height: 0.8rem;
  }

  &--medium-icon {
    width: 1.16667rem;
    height: 1.16667rem;
    fill: var(--text-gray);
  }

  &--larger-icon {
    width: 1.33334rem;
    height: 1.33334rem;
    fill: var(--text-gray);
  }

  &--standard-button-icon {
    width: var(--spacing-unit-x2) !important;
    height: var(--spacing-unit-x2) !important;
  }

  &--medium-large-icon {
    width: var(--spacing-unit-x3);
    height: var(--spacing-unit-x3);
  }

  &--small-icon {
    width: calc(667rem / 1000);
    height: calc(667rem / 1000);
  }

  &--tiny-icon {
    width: var(--spacing-unit);
    height: var(--spacing-unit);
  }

  &--large-icon {
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);
  }

  &--very-large-icon {
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
  }

  &--extra-large-icon {
    width: var(--spacing-unit-x8);
    height: var(--spacing-unit-x8);
  }

  &--super-large-icon {
    width: var(--spacing-unit-x10);
    height: var(--spacing-unit-x10);
  }

  &--huge-icon {
    width: var(--spacing-unit-x13);
    height: var(--spacing-unit-x13);
  }

  &--selectv2-option-icon {
    width: 1.6667rem;
    height: 1.6667rem;
  }

  &--very-small-icon {
    width: calc(7rem / 12);
    height: calc(7rem / 12);
  }

  &--fill {
    &-text-gray {

      /* need !important to override button styling */
      fill: var(--text-gray) !important;
    }

    &-black {
      fill: var(--black) !important;
    }

    &-button-gray {
      fill: var(--button-gray) !important;
    }

    &-icon-gray {
      fill: var(--gray-icon) !important;
    }

    &-button-hover-gray {
      fill: var(--button-hover) !important;
    }

    &-teal {
      fill: var(--teal) !important;
    }

    &-teal-dark {
      fill: var(--darker-teal) !important;
    }

    &-text-teal {
      fill: var(--text-teal) !important;
    }

    &-light-gray {
      fill: var(--light-gray) !important;
    }

    &-navigation-button-hover {
      fill: var(--navigation-button-hover) !important;
    }

    &-tab-gray {
      fill: var(--tab-gray) !important;
    }

    &-background-gray {
      fill: var(--modal-gray-background) !important;
    }

    &-remove-red {
      fill: var(--remove-red) !important;
    }

    &-error {
      fill: var(--error) !important;
    }

    &-error-text {
      fill: var(--error-text) !important;
    }

    &-hover-indicator-background {
      fill: var(--hover-indicator-background) !important;
    }

    &-success-green {
      fill: var(--success-green) !important;
    }

    &-dark-blue {
      fill: var(--dark-blue) !important;
    }

    &-yellow-caution {
      fill: var(--yellow-caution) !important;
    }

    &-white {
      fill: var(--white) !important;
    }

    &-light-teal {
      fill: var(--border-teal) !important;
    }

    &-border-gray {
      fill: var(--border-gray) !important;
    }
  }

  &--hover {
    &-text-gray:hover {
      fill: var(--text-gray);
    }

    &-teal:hover {
      fill: var(--teal);
    }

    &-text-teal:hover {
      fill: var(--text-teal);
    }

    &-light-gray:hover {
      fill: var(--light-gray);
    }

    &-navigation-button-hover:hover {
      fill: var(--navigation-button-hover) !important;
    }

    &-tab-gray:hover {
      fill: var(--tab-gray);
    }

    &-background-gray:hover {
      fill: var(--modal-gray-background);
    }

    &-remove-red:hover {
      fill: var(--remove-red);
    }

    &-error-text:hover {
      fill: var(--error-text);
    }

    &-hover-indicator-background:hover {
      fill: var(--hover-indicator-background) !important;
    }

    &-success-green:hover {
      fill: var(--success-green);
    }

    &-dark-blue:hover {
      fill: var(--dark-blue);
    }

    &-light-teal:hover {
      fill: var(--border-teal) !important;
    }

    &-button-gray:hover {
      fill: var(--button-gray) !important;
    }
  }

  overflow: visible;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;

    &-standard {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
    }

    &-large {
      width: var(--spacing-unit-x6);
      height: var(--spacing-unit-x6);
    }
  }
}
