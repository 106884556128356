.row-actions {
  background: linear-gradient(
    90deg,
    rgba(249, 249, 249, 0) 0%,
    var(--hover-row-background-teal) var(--spacing-unit-x2),
    var(--hover-row-background-teal) 100%
  );
  margin-right: var(--spacing-unit);
  padding-left: var(--spacing-unit-x3);

  &__container {
    display: flex;
    column-gap: var(--spacing-unit-x2);
  }

  &__drop-down-actions {
    width: var(--spacing-unit-x26);
    display: inline-grid;
  }

  &:hover {
    cursor: pointer;
  }

  &__button {
    justify-content: center;
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);
    border: none;

    svg {
      fill: var(--text-gray);
    }

    &:hover {
      background-color: var(--hover-row-button-background-teal);
    }
  }

  &__drop-down {
    svg {
      color: var(--text-gray);
    }

    button {
      height: var(--spacing-unit-x2);
      width: var(--spacing-unit-x2);
    }

    &-open button {
      background-color: var(--hover-row-button-background-teal);
    }

    &-hovered {
      svg {
        color: var(--text-gray);
      }
    }
  }

  &__tooltip {
    align-items: center;
    display: flex;
    transition: var(--linear-80);

    > button {
      margin-top: 0;
    }
  }

  .drop-down {
    & > button {
      height: var(--spacing-unit-x4);
      transition: var(--linear-80);
      width: var(--spacing-unit-x4);

      &:hover {
        background-color: var(--hover-row-button-background-teal);
      }
    }
  }
}
