.status-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__icon {
    margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x4) 0;
  }

  &__subtitle {
    padding: var(--spacing-unit-x2) 0;
  }

  &__link {
    padding-bottom: calc(var(--spacing-unit-x5) + var(--half-spacing-unit));
  }
}
