.menu-item-with-header {
  max-height: inherit;
  position: relative;

  &__header {
    border-bottom: calc(var(--half-spacing-unit) / 3) solid transparent;
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-unit-x2);
    position: sticky;
    top: 0;
    transition: margin-bottom, border-bottom ease-in-out 200ms;
    background-color: var(--white);
    z-index: 1;

    &-icon.svg {
      width: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2));
      height: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2));
      padding: 0.375rem;
    }

    &-text {
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      display: flex;
      flex-grow: 1;
      gap: var(--spacing-unit);

      &:hover {
        text-decoration: none;
      }
    }

    & > svg {
      transition: transform var(--expand-timing) ease-out;
    }
  }

  &__scrolling {
    border-bottom: var(--standard-border);
  }
}
