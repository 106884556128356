:root {
  --white: #ffffff;
  --off-white: #fafafa;
  --light-off-white: #f2f3f8;
  --lighter-off-white: #f6f6f6;
  --off-white-hover: #f2f2f2;
  --off-white-hover-disabled: #f2f2f299;
  --off-white-focus: #eeeeee;
  --black: #000000;
  --black-back-transparent: rgba(0, 0, 0, 0.8);
  --navigation-button-hover: #eaedf1;
  --browser-highlight: #51a7e8;
  --border-gray: #e3e3e3;
  --button-border-gray: #d6d6d6;
  --chip-base: #eaedf1;
  --dark-border-gray: #d8d8d8;
  --empty-gray-background: #f4f6f6;
  --modal-gray-background: #a5aaba;
  --page-back: #f7f9fb;
  --review-send-bg: #e2e2e2;
  --dark-page-back: #d1cecd;
  --light-red: #feebec;
  --red: #ff6268;
  --remove-red: #f25656;
  --remove-red-hover: #ef3232;
  --erase-hover-red: #e81212;
  --erase-disabled: #f89f9f;
  --remove-hover-red: #ab2020;
  --highlight-red: #821a1a;
  --error-lighter: #fff7f7;
  --error-light: #f9e5e6;
  --error: #e81111;
  --error-focus: #d84444;
  --error-text: #c51b1b;
  --text-label-error: #994d4d;
  --status-warning: #ffeaeb;
  --status-active: #edf6ed;
  --status-error: #ffe7e7;
  --overlay: #193661;
  --text-gray: #444444;
  --text-black: #222222;
  --text-gray-opacity-10: #4444441a;
  --text-gray-opacity-12: #4444441f;
  --text-gray-opacity-15: #44444426;
  --text-gray-opacity-25: #44444440;
  --text-gray-opacity-8: #44444414;
  --text-gray-opacity-4: #4444440a;
  --gray: #606060;
  --gray-highlight: #8a888c;
  --gray-icon: #898989;
  --light-gray: #767676;
  --icon-light-gray: #767b80;
  --lighter-gray: #979797;
  --lightest-gray: #c1c9d4;
  --lightest-gray-opacity: #c1c9d44d;
  --highlight-gray: #effbfb;
  --unselected-gray: #dbdee2;
  --button-back-gray: #e3e3e3;
  --button-hover: #c9c9c9;
  --button-gray: #aeaeae;
  --disabled-plus-gray: #b7b7b7;
  --disabled-gray: #99a0ab;
  --disabled-alert-gray: #8f8f8f;
  --disabled-alert-secondary-gray: #adadad;
  --disabled-edit-columns-action: rgb(68, 68, 68, 0.5);
  --input-background: #f9f9f9;
  --input-select-background: #b4d5fe;
  --icon-back-gray: #e3ecec;
  --sms-text-gray: #f1f1f1;
  --data-preview-header-gray: #f6f7f6;
  --crm-back-gray: #ece8e3;
  --crm-icon-gray: #9e998c;
  --program-icon-gray: #8c9d9e;
  --tab-gray: #949494;
  --hover-background-gray: #464e58;
  --add-filter-gray: #eceef0;
  --remove-active-filter-gray: #ced0d2;
  --hover-row-background-teal: #f2fbfb;
  --hover-row-button-background-teal: #dbeeee;
  --hover-indicator-background: #d7dade;
  --hover-create-option-icon: #00747a;
  --step-highlight: #c2eeef;
  --lighter-teal: #e5f9f9;
  --teal-light: #ace8ea;
  --teal-medium: #00686c;
  --dark-teal: #b3dbdb;
  --darker-teal: #004245;
  --disabled-teal: #99e3e5;
  --light-teal: #d1eced;
  --teal: #00babe;
  --text-teal: #00747a;
  --border-teal: #a5d6d7;
  --highlight-teal: #dbefef;
  --hover-teal: #08a2a5;
  --focus-teal: #009a9e;
  --focus-light-teal: #ace9eb;
  --background-list-header: #f0f6ff;
  --background-teal: #eefafa;
  --background-dark-teal: #d3f6f6;
  --teal-secondary-alert: #c9eeef;
  --selectable-row-hover: #f5f6f8;
  --salmon: #e55f74;
  --salmon-medal: #e34e64;
  --magenta: #d483b7;
  --info-cerulean: #007ea0;
  --active-blue: #178acb;
  --ao-var-dark-blue: #304a71;
  --light-blue: #4e6486;
  --blue: #455c7f;
  --medium-blue: #0000cd;
  --dark-blue: #304a71;
  --dark-blue-disabled: #304a7199;
  --sonic-blue: #194f99;
  --dark-blue-medal: #194f90;
  --sonic-blue-background: #194f9926;
  --darker-blue: #193661;
  --light-royal-blue: #dff0f8;
  --royal-blue: #0085c9;
  --highlight-blue: #367aff;
  --more-info-blue: #1e7df8;
  --info-blue: #2560e1;
  --more-info-blue: #1e7df8;
  --sms-preview-link: #2a8ef9;
  --sky-blue: #f2f7ff;
  --lighter-green: #caf1df;
  --light-green: #8cdacd;
  --green: #b2e0b4;
  --pulse-green: #00ba4f;
  --green-percentage: #008800;
  --green-emerald: #06960e;
  --dark-green: #406340;
  --darker-green: #0d6e37;
  --comment-line-green: #236e27;
  --success-green: #69b466;
  --add-success: #425041;
  --highlight-green: #0f6647;
  --light-orange: #fcebdd;
  --orange: #eb8126;
  --dark-orange: #a73205;
  --light-yellow: #fff5cc;
  --deep-orange: #e26c2c;
  --yellow-border: #f4e0cf;
  --dark-yellow-border: #fdebdc;
  --status-active-border: #dbeeda;
  --status-warning-border: #efd6d6;
  --status-error-border: #f1b3b3;
  --text-yellow: #816c00;
  --yellow: #f0c29c;
  --yellow-highlight: #f7a154;
  --yellow-caution: #ffcd00;
  --yellow-caution-hover: #ebbd00;
  --yellow-medal: #e9a800;
  --background-yelow: #f5ebb3;
  --text-dark-yellow: #594d1d;
  --yellow-background: #fffcf3;
  --purple: #7059c6;
  --purple-lavender: #6d79de;
  --purple-secondary: #9442dd;
  --purple-secondary-dark: #61209c;
  --purple-secondary-light: #e9d9f8;
  --purple-secondary-lightest: #f6f0fc;
  --purple-icon: #939ed5;
  --purple-medal: #6959e7;
  --reddish-purple: #692340;
  --pink: #fcd6d6;
  --high-red: #e81212;
  --signout-svg: #3276b1;
  --scrollbar-background-color: #aaaaaa;
  --dark-mode-background: #000000;
  --dark-mode-content-background: #151e2d;
  --dark-mode-container-header-background: #00747a;
  --dark-mode-container-background: #213048;
  --styled-tooltip-border-blue: #008fca;
  --gray-pill: #64717d;
  --new-pill: #61209c;
  --new-pill-background: #f6f0fc;
  --gray-doctype: #687687;

  /* tab themes */
  --neutral: #e4e6f2;
  --background-base: #f2f7ff;
  --border-base: #455c7f;
  --background-sent: #f6f1ff;
  --border-sent: #a084dd;
  --background-delivered: #f0f5ff;
  --border-delivered: #7b9fed;
  --background-clicked: #eaf7ff;
  --border-clicked: #50bdff;
  --background-replied: #e2fff4;
  --border-replied: #7de7c1;
  --background-failed: #fff7e2;
  --border-failed: #ffcb3e;
  --background-optedout: #ffefef;
  --border-optedout: #f27575;
  --background-earlyexit: #fdebdc;
  --border-earlyexit: #eb8126;
  --border-standardexit: #d286b6;
  --background-standardexit: #feebf7;
  --background-tag: rgba(118, 118, 118, 0.1);

  /* Failures */
  --failure-filtered: #b3e0d5;
  --failure-unreachable: #ffa27f;
  --failure-overflow: #1b9377;
  --failure-landline: #b2b9d7;
  --failure-from-max: #b6f2fc;
  --failure-region-permission: #cd7ca5;
  --failure-unknown-destination: #ffd892;
  --failure-unknown: #d8d0c7;
  --failure-unsubscribed: #ffb8d7;
  --failure-fatigue: #c7d411;
  --failure-other: #e4e6f2;

  /* Light theme */
  --light-theme-background: #ffffff; /* navBackgroundColor */
  --light-theme-unselected: #606060; /* navFontColor */
  --light-theme-selected: #444444; /* navFontActiveColor */
  --light-theme-selected-item: #007b80; /* navFontHighlightColor */
  --light-theme-selected-item-background: #effbfb; /* navSubBackgroundColor */
  --light-theme-border: #ececec; /* navSubDividerColor */
  --light-theme-background-darker: #f9f9f9;

  /* Dark theme */
  --dark-theme-background: #304a71;
  --dark-theme-unselected: #c1c9d4;
  --dark-theme-selected: #ffffff;
  --dark-theme-selected-item: #ffffff;
  --dark-theme-selected-item-background: #455c7f;
  --dark-theme-border: #193661;
  --dark-theme-background-darker: #2c4468;

  /* RGBA */
  --pulse-green-rgba-zero-alpha: rgba(0, 186, 79, 0);
  --pulse-green-rgba-six-tenths-alpha: rgba(0, 186, 79, 0.6);
  --report-lost-background: rgba(242, 117, 117, 0.24);
  --report-won-background: rgba(125, 231, 193, 0.24);
  --report-open-background: rgba(80, 189, 255, 0.24);
  --vertical-bar: rgba(123, 159, 237, 0.5);
  --last-vertical-bar-border: rgba(123, 159, 237, 0.4);
  --last-vertical-bar-border-hover: rgba(123, 159, 237, 1);
  --segmented-line-chart-background-1: rgba(80, 189, 255, 0.15);
  --segmented-line-chart-background-2: rgba(80, 189, 255, 0.5);
  --segmented-line-chart-background-3: rgba(80, 189, 255, 0.75);
  --segmented-line-chart-background-hover-2: rgba(80, 189, 255, 0.6);
  --segmented-line-chart-background-hover-3: rgba(80, 189, 255, 0.85);
  --media-card-background-hover: rgba(25, 54, 97, 0.85);
  --media-card-background-disabled: rgba(249, 249, 249, 0.85);
  --asset-picker-preview-background: rgba(164, 170, 186, 0.8);

  /* Tags */
  --tag-no-color: #949494;
  --tag-coral-red: #ff4040;
  --tag-orange-peel: #ff9f00;
  --tag-golden-yellow: #ffdf00;
  --tag-lime-green: #32cd32;
  --tag-leaf: #0aaa5d;
  --tag-sky: #53bffc;
  --tag-cobalt: #484ee7;
  --tag-vivid-violet: #9f00ff;
  --tag-hot-magenta: #ff1dce;
  --tag-ruby-red: #9b111e;
}
