.multi-select {
  &__wrapper {
    display: flex;
    align-items: center;
    min-width: 16rem;
  }

  &__faux-select {
    cursor: pointer;
    margin: 0;
    width: 100%;
    border-radius: var(--standard-border-radius);
    font-size: var(--large-font-size);
    line-height: var(--line-height-title-page);
    padding: 0 var(--spacing-unit-x2);
    box-sizing: border-box;
    appearance: none;
    background-color: var(--input-background);
    background-repeat: no-repeat;
    background-position: calc(100% - var(--spacing-unit-x2)) center;
    background-size: 0.75rem 0.46rem;
    color: var(--text-gray);
    border: var(--input-border);
    max-height: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
    outline: none;
    transition: var(--linear-80);
    padding-right: 2.5rem;

    &:hover,
    &:focus {
      background-color: var(--white);
    }

    &:hover {
      border: var(--input-border-hover);
    }

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
    }

    &:focus {
      border: var(--input-border-focus);
      outline: var(--input-outline-border);
    }

    &:disabled {
      opacity: 0.7;

      &:hover,
      &:focus {
        cursor: default;
        background-color: var(--input-background);
        border: var(--input-border);
      }
    }

    &--active {
      display: none;
    }

    &:invalid {
      color: var(--light-gray);
    }
  }

  &__dropdown-wrapper {
    position: relative;
    display: none;
    flex-grow: 1;

    &--active {
      display: block;
    }
  }

  &__search-wrapper {
    box-shadow: var(--box-shadow);
  }

  &__search {
    border: none;
    background-color: var(--white);

    &:hover,
    &:active,
    &:focus {
      border: none;
      background-color: var(--white);
      outline: none;
    }
  }

  &__dropdown-container {
    position: absolute;
    left: 0;
    right: 0;
    background: var(--white);
    box-shadow: var(--box-shadow);
    border-radius: 0 0 var(--standard-border-radius) var(--standard-border-radius);
    border-top: var(--standard-border);
    z-index: var(--z-index-multi-select);
  }

  &__options {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    margin: 0;
    max-height: 50vh;
    overflow: auto;
    scrollbar-gutter: stable;
  }

  &__checkbox {
    padding: var(--spacing-unit) 0;
  }

  &__button-wrapper {
    border-top: var(--standard-border);
    padding: var(--spacing-unit-x4) calc(var(--half-spacing-unit) + var(--spacing-unit));
  }

  &__clear-wrapper {
    flex-shrink: 0;
    padding: 0;

    &--active {
      display: none;
    }
  }

  &__clear-text {
    padding: 0 0 0 var(--spacing-unit-x2);
  }
}
