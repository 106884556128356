
.extra-element {
  position: absolute;
  top: var(--spacing-unit-x3);
  left: 40%;
  z-index: var(--z-index-chart-extra);

  .radio__label::after,
  .radio__label::before {
    top: calc(var(--spacing-unit) / 3);
  }
}
