.standard-field-names {
  &__loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__sub-header-container {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__sub-header {
    display: inline;
    width: 85%;
  }

  &__status-section {
    .info-action--secondary {
      margin-left: 0;
      width: 16.5rem;
    }
  }

  &__container,
  &__content-container {
    min-height: var(--spacing-unit-x28);
  }

  &__content-container {
    margin-top: var(--spacing-unit-x4);
    padding-top: 0;
    position: relative;
  }

  &__list-header {
    display: flex;
    border-bottom: var(--standard-border);
    margin-bottom: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

    &-left {
      width: 36.5rem;
    }
  }

  &__more-info {
    margin-left: var(--half-spacing-unit);
  }

  &__add-bottom {
    margin: var(--spacing-unit-x2) 0 0 var(--spacing-unit-x4);
    padding-left: var(--spacing-unit-x3);
  }
}


/* stylelint-disable */
@media (max-width: 1485px) {
  /* stylelint-enable */
  .standard-field-names__input {
    .input-to-select-mapping-row {
      &__input,
      &__select {
        width: 24rem;
      }
    }
  }
}
