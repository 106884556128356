.submission-details {
  &__details {
    display: flex;
    gap: var(--spacing-unit-x2);

    &-svg {
      margin-top: var(--half-spacing-unit);
    }

    &-list {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
    }
  }

  &__segment {
    display: flex;
    gap: var(--half-spacing-unit);

    &-base {
      flex: 1;
      min-width: 60%;
    }

    &-link {
      max-width: 32rem;
    }
  }

  &__info {
    margin-bottom: 0;
  }
}
