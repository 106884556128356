.asset-link-modal {
  width: 50rem;

  &.modal {
    overflow: unset;
  }

  &__label {
    margin-bottom: var(--spacing-unit);
  }

  &__url-section {
    margin-top: var(--spacing-unit-x7);
  }

  &__checkbox {
    padding-top: var(--spacing-unit-x3);
  }

  &__modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
