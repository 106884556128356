
.score-sheet-row {
  border: var(--standard-border);
  display: flex;
  margin-left: var(--spacing-unit-x6);
  margin-right: var(--spacing-unit-x14);
  height: 5.8rem;

  &__select-text {
    margin-left: var(--spacing-unit-x4);
    margin-right: var(--spacing-unit-x6);
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    display: flex;
    justify-content: center;
    height: 3.3rem;
  }

  &__select {
    width: 13.75rem;
    margin-top: 1.25rem;
    margin-bottom: var(--spacing-unit-x2);
    margin-right: var(--spacing-unit-x4);
  }
}
