.optIns-sourceLists {
  &__loader {
    width: 100%;
    height: 42rem;
  }

  &__headline {
    padding: 0 var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x3);
    display: flex;
    justify-content: space-between;
  }

  .table__table-holder {
    border-top: none;
  }

  .table__table-holder,
  .table__paginator {
    border-right: none;
    border-left: none;
    min-width: var(--sms-optins-positioning-min-width);
  }

  .table__paginator {
    border-radius: unset;
    border-bottom: none;
  }

  &__has-overflow .table__table-holder {
    border-bottom: var(--standard-border);
    padding-bottom: 0;
    margin-bottom: var(--spacing-unit-x4);
  }

  .td,
  .th {
    &.table__right {
      padding-right: var(--spacing-unit-x4) !important;
    }
  }

  .td {
    .svg {
      fill: var(--teal);
    }
  }

  .td&__list-name {
    font-weight: var(--font-weight-medium);
  }

  &__welcome-checkbox {
    justify-content: flex-end;
  }

  &__list-segment-name {
    display: flex;
    align-items: center;
    gap: calc(var(--spacing-unit) + var(--half-spacing-unit));

    & span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .button {
    .svg {
      margin-right: 0.833rem;
    }
  }

  &__confirmation {
    width: 40rem;
  }

  .tableV2 {
    &-tbody__td {
      svg {
        fill: var(--teal);
        flex-shrink: 0;
      }
    }
  }
}
