.ftp-sync-segment-definition {
  &__section-title {
    color: var(--light-gray);
  }

  &__divider {
    width: 100%;
    height: var(--standard-border-width);
    background-color: var(--border-gray);
    margin: var(--spacing-unit-x3) 0 var(--spacing-unit-x3) 0;
  }

  &__ftp-sync {
    display: flex;
    flex-direction: column;

    &-title {
      margin-bottom: var(--spacing-unit-x2);
    }

    &-item {
      align-items: center;
      display: flex;
      gap: var(--spacing-unit);

      &-text {
        display: flex;
        gap: var(--half-spacing-unit);
      }

      &-path {
        max-width: 11rem;
      }
    }

    &-status-icon {
      margin-left: auto;
    }

    &-error {
      align-items: center;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      &-text {
        display: flex;
        align-items: center;
        gap: var(--spacing-unit);
        width: 100%;
        justify-content: space-between;
        margin-top: var(--spacing-unit);
      }

      &-link {
        width: 100%;

        & > .link__text > .typography {
          font-size: var(--medium-font-size);
        }
      }
    }
  }
}
