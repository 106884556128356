.entity-details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);
  
  &__added-fields {
    &-empty {
      padding-top: var(--spacing-unit-x2);
      border-top: var(--standard-border);
    }
  }
}
