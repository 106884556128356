
.column-state {
  &__check {
    fill: var(--success-green);
  }

  &__warning {
    fill: var(--yellow-caution);
  }

  &__exception {
    fill: var(--error);
  }
}
