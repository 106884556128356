.item-activity {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-unit-x6);
  min-width: 70rem;
  grid-gap: var(--spacing-unit-x4);

  &__performance,
  &__details-content,
  &__activity-content {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    background-color: var(--white);
  }

  &-details-section {
    &__title-text {
      text-transform: capitalize;
    }
  }

  &__activity-content {
    padding: var(--spacing-unit-x3) 0;

    &-title {
      padding-left: var(--spacing-unit-x4);
    }
  }

  &__table {
    .item-activity__table__table-holder {
      border: 0;
      min-width: unset;
      max-width: unset;
    }

    .tr {
      max-width: unset !important;
    }
  }

  &__details {
    &-header {
      margin-bottom: var(--spacing-unit-x4);
    }

    &-tag {
      height: var(--spacing-unit-x4);
      flex-shrink: 0;
      max-width: fit-content;
      width: fit-content;

      &-manager-trigger {
        height: var(--spacing-unit-x4);
        white-space: nowrap;
      }
    }

    &-tags {
      column-gap: var(--spacing-unit);
      display: flex;
      flex-wrap: wrap;
      row-gap: calc(var(--spacing-unit) + var(--half-spacing-unit));

      [data-radix-popper-content-wrapper] {
        z-index: var(--z-index-99999) !important;
      }
    }
  }

  &__sections-wrapper .item-activity-details-section:first-child {
    border-top: 0;
  }

  .info-cards {
    &__cards {
      gap: var(--spacing-unit-x2);
    }

    &__card-container {
      margin-right: unset;
      width: 100%;
    }
  }
}

@media (min-width: 100rem) {
  .item-activity {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 15.6rem auto;

    &__performance,
    &__activity {
      width: 70rem;
    }

    &__details {
      min-width: 37.5rem;
      grid-row: 1 / 3;
      grid-column: 2;
    }
  }
}
