.breadcrumbs-drop-down {
  & [data-radix-popper-content-wrapper] {
    direction: rtl;
    left: unset !important;
    top: unset !important;
    position: fixed !important;
    transform: unset !important;
  }
  
  &__actions {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);

    & .drop-down-actions__item {
      padding: 0.17rem var(--spacing-unit-x4);

      & .typography {
        line-height: var(--spacing-unit-x4);
      }
    }
  }

  &__content {
    border-top: none;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    box-shadow: var(--breadcrumbs-drop-down-shadow);
    margin-top: 0;
    width: 14.91667rem;
  }
}