.scroll-area {
  display: block;

  &__root {
    width: 100%;
    height: 100%;
  }

  &__viewport {
    width: 100%;
    height: 100%;

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
    }
  }

  &__scrollbar {
    transition: 300ms opacity ease;
    width: 0.6667rem;
  
    &-horizontal {
      height: 0.6667rem;
    }
  }

  &__thumb {
    background-color: var(--dark-border-gray);
    border-radius: 0.33334rem;

    &-horizontal {
      height: 0.6667rem !important;
    }
  }

  &__story {
    height: 12rem;
  }
}
