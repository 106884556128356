
.send-email-step-detail {
  &__singleton &__svg {
    margin-left: var(--spacing-unit);
  }

  > div {
    margin-bottom: var(--spacing-unit);
  }

  &__then,
  &__ab-test-message {
    padding-left: var(--spacing-unit-x4);
    padding-right: var(--half-spacing-unit);
    display: flex;
    justify-content: left;
  }

  &__ab-description {
    padding-left: var(--spacing-unit);
    padding-bottom: var(--spacing-unit-x3);
  }

  &__ab-expanded {
    .table__table-holder {
      min-width: 35rem;

      .tbody,
      .td {
        border-top: none;
      }
    }
  }

  &__svg {
    margin: var(--half-spacing-unit) var(--spacing-unit);
    fill: var(--text-teal);
  }

  &__test-message-container {
    display: flex;

    .svg {
      margin: var(--half-spacing-unit) var(--spacing-unit);
    }
  }

  &__message-container {
    display: flex;
  }

  &__mark-winner {
    height: var(--spacing-unit-x4);
  }

  &__ab-test {
    padding-bottom: var(--spacing-unit-x3);
  }
}
