
.map-contacts {
  width: 50rem;

  &__info-text {
    margin-bottom: var(--spacing-unit-x5);
  }

  .form-group {
    margin-bottom: unset;
  }

  &__header-title {
    display: flex;
    align-items: center;
  }

  &__list {
    margin-bottom: var(--spacing-unit-x2);

    & .checkbox__label {
      line-height: var(--line-height-medium);
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-unit-x4);

    &-date-format {
      flex-direction: row;
    }
  }

  &__col {
    width: 29rem;
  }

  &__col-date {
    width: 13.5rem;
    margin-right: var(--spacing-unit-x2);
  }

  &__col-date-last {
    width: 13.5rem;
  }

  &__preview {
    margin-top: var(--spacing-unit);
  }
}
