.review-import-options {
  &__header {
    margin-bottom: var(--spacing-unit-x4);
  }
  
  &__section {
    display: flex;

    &-header {
      width: 17.5rem;
      flex-shrink: 0;
    }
    
    &-content {
      flex-grow: 1;
    }

    &:not(:first-child) {
      padding-top: var(--spacing-unit-x3);
      margin-top: var(--spacing-unit-x3);
      border-top: var(--standard-border);
    }
  }
  
  &__segment-name {
    margin-top: var(--spacing-unit-x2);
    
    &__content {
      display: flex;
      gap: var(--spacing-unit);
    }
  }
}
