.custom-insights-results-section {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x2) 0;
  
    &-expandable {
      cursor: pointer;
    }
  
    &-caret {
      margin-right: var(--spacing-unit);
    }
     
    &-title {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x4);
    }
  }

  &__segments {
    &-row + &-row {
      margin-top: var(--spacing-unit-x2);
    }
     
    &-hidden {
      display: none;
    }
  }

  &__empty-state {
    align-items: center;
    border-radius: var(--standard-border-radius);
    border: var(--standard-border);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
    justify-content: center;
    padding: 2.75rem 0;
  }
}
