.emoji-picker {
  em-emoji-picker { /* stylelint-disable-line selector-type-no-unknown */
    height: 28.2rem;
    --font-family: var(--font-regular);
    --font-size: var(--large-font-size);
    --border-radius: var(--standard-border-radius);
    --rgb-color: 68 68 68;
    --background-rgb: 255 0 0;
    --rgb-accent: 0 116 122;
    --rgb-input: 255 255 255;
    --shadow: var(--recipients-select-box-shadow);
  }
}
