.settings-and-reports {
  display: block;
  padding: var(--spacing-unit-x4);
  margin: var(--spacing-unit-x4) 0;

  .section-headline {
    margin-bottom: 0;
  }

  &__section {
    margin-bottom: var(--spacing-unit);

    border-bottom: var(--standard-border);
    padding: var(--spacing-unit-x3) 0;

    &-header {
      &-left {
        gap: var(--spacing-unit);
        margin-bottom: var(--spacing-unit-x4);
      }
    }

    &-subheader {
      display: flex;
      justify-content: space-between;

      &-left {
        display: flex;
        align-items: flex-start;
        gap: var(--spacing-unit-x2);
      }
    }
  }

  &__section:last-of-type,
  &__single-section {
    border-bottom: none;
    padding-bottom: 0;
  }

  &__svg {
    margin-top: var(--half-spacing-unit);
  }

  .typography__type-body-text-small-light {
    margin-top: 0.5rem;
  }
}
