.scoped-modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-99999);
  justify-content: center;
  align-items: center;
  display: none;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay);
    opacity: 0.55;
    display: none;
  }

  &--visible {
    display: flex;
    width: 100%;
    height: 100%;

    .scoped-modal__background {
      display: block;
    }
  }

  &__content {
    position: absolute;
    background-color: var(--white);
    min-width: 15rem;
    border-radius: 0.333rem;
    max-height: calc(100% - var(--spacing-unit-x8));
    max-width: calc(100% - var(--spacing-unit-x8));
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
  }

  &__body {
    overflow: auto;
    height: 100%;
  }
}
