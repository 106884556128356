.landing-page-report {
  margin-left: 0;
  padding-top: var(--spacing-unit-x5);

  .position-container__children {
    height: calc(100vh - 19.5rem);
    margin-bottom: var(--spacing-unit-x4);

    > div {
      height: 100%;
    }
  }

  &__iframe {
    background: var(--white);
    border: none;
    width: 100%;
    height: 100%;
  }
}
