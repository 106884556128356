.list-picker-modal {
  &__selected-list-header {
    &-title {
      font-size: var(--larger-font-size);
      flex: 1 1 100%;
    }

    &-button {
      flex: 0 0 14rem;
      display: flex;
      justify-content: flex-end;
    }
  }

  &-body-filter-container {
    padding: var(--spacing-unit) 0;
  }

  &__header {
    display: flex;
    flex-wrap: nowrap;
    padding-top: 1.333rem;
    padding-bottom: 1.333rem;

    .typography {
      flex: 1 1 100%;
    }
  }

  .modal-sub-header {
    padding: 0 var(--spacing-unit-x4);
  }

  &__search {
    min-height: 10rem;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__search-input {
    flex: 0 0 19.5rem;
    width: 25rem;
  }

  span&__search-input__container {
    flex: 0;
  }

  &__body {
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-width: 100rem;
    width: calc(100vw - 6.66rem);
    min-height: 26.66rem;
  }

  &__selected-lists {
    font-size: var(--large-font-size);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 2.3rem;

    svg {
      margin-left: var(--spacing-unit);
    }
  }

  &__total-records {
    font-size: var(--default-font-size);
    color: var(--light-gray);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 1rem;
  }

  &-footer__view-selected-button {
    gap: 0.58333rem;
    height: var(--spacing-unit-x6);
  }
}
