:root {
  --toggle-check-small: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8417_7539)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.17615 1.39665C9.55799 1.73474 9.60958 2.33771 9.29138 2.74342L4.79144 8.48088C4.49699 8.85629 3.98352 8.93542 3.60082 8.66435L0.90086 6.75186C0.48729 6.45891 0.375535 5.86521 0.651248 5.42579C0.926962 4.98637 1.48574 4.86763 1.89931 5.16058L3.92638 6.59643L7.9086 1.51908C8.2268 1.11337 8.7943 1.05856 9.17615 1.39665Z' fill='%2308A2A5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8417_7539'%3E%3Crect width='9' height='9' fill='white' transform='translate(0.5 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  --toggle-check-large: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icons/Actions' clip-path='url(%23clip0_8417_7512)'%3E%3Cpath id='icon' fill-rule='evenodd' clip-rule='evenodd' d='M11.5682 1.19551C12.0773 1.6463 12.1461 2.45026 11.7218 2.99121L5.72192 10.6411C5.32933 11.1417 4.64469 11.2472 4.13443 10.8858L0.53448 8.33579C-0.016947 7.9452 -0.165954 7.1536 0.201664 6.5677C0.569283 5.98181 1.31432 5.82349 1.86574 6.21409L4.56851 8.12855L9.87813 1.35876C10.3024 0.817808 11.0591 0.74472 11.5682 1.19551Z' fill='%2308A2A5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8417_7512'%3E%3Crect width='12' height='12' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.toggle {
  position: relative;
  width: var(--spacing-unit-x6);
  height: var(--spacing-unit-x3);
  border-radius: var(--spacing-unit-x2);
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
  margin-right: 0.75rem;
  margin-left: var(--spacing-unit-x2);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.333rem;
    height: 1.333rem;
    box-shadow: 0 0.083rem 0.333rem rgba(71, 70, 70, 0.5);
    border-radius: 50%;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    background: center var(--off-white);
  }

  &--container {
    display: inline-flex;
    align-items: center;
  }

  &--large {
    width: var(--spacing-unit-x8);
    height: var(--spacing-unit-x4);
    border-radius: var(--spacing-unit-x2);
    margin-right: var(--spacing-unit-x2);

    &::before {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &--on {
    background: var(--teal);

    &::before {
      left: calc(100% - var(--spacing-unit-x3));
      background: center no-repeat var(--toggle-check-small) var(--white);
      border: var(--hover-teal-border);
    }

    &.toggle--large {
      &::before {
        left: calc(100% - 1.9rem);
        background: center no-repeat var(--toggle-check-large) var(--white);
      }
    }
  }

  &--off {
    background: #BDBDBD;

    &::before {
      left: 0.1rem;
      background: var(--off-white);
    }
  }

  &--label {
    font-weight: var(--font-weight-medium-light);
    font-size: var(--large-font-size);
    line-height: var(--line-height-large);
    transition: color 0.28s cubic-bezier(0.4, 0, 0.2, 1);

    &-on {
      color: var(--text-gray);
    }

    &-off {
      color: var(--light-gray);
    }

    &.toggle--large__label {
      font-size: var(--larger-font-size);
      line-height: var(--line-height-larger);
    }
  }

  &--no-left {
    margin-left: 0;
  }

  &--no-right {
    margin-right: 0;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus-visible {
    border-color: var(--dark-blue);
    outline: var(--input-outline-border);
  }
}
