.generative-email-modal {
  width: 84rem;

  &__header {
    justify-content: flex-start;
    gap: var(--spacing-unit-x2);

    .svg {
      color: var(--text-teal);
    }
  }

  .modal &__body {
    display: flex;
    height: 50rem;
    padding: 0 var(--spacing-unit-x4) 0 calc(var(--spacing-unit-x3) + var(--half-spacing-unit));
    gap: var(--spacing-unit-x4);
  }

  &__left {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x4) 0 var(--half-spacing-unit);
    overflow: auto;
    flex: 1;
    border-right: var(--standard-border);

    > div {
      margin-bottom: var(--spacing-unit-x4);
    }
  }

  &__row {
    display: flex;
    gap: var(--spacing-unit-x4);

    > div {
      flex: 1;
    }
  }

  &__summary-label {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__char-count-disabled {
    opacity: 0.5;
  }

  &__include-subject {
    display: flex;
    align-items: center;

    .toggle {
      margin-left: var(--half-spacing-unit);
    }

    &-tooltip {
      margin-left: var(--spacing-unit);
    }

    .svg {
      margin-top: var(--half-spacing-unit);
    }
  }

  &__lengths {
    display: flex;
    gap: var(--spacing-unit-x2);

    .button {
      height: var(--spacing-unit-x6);
      width: var(--spacing-unit-x6);
    }
  }

  &__output-length-disabled {
    opacity: 0.5;
    background: var(--lighter-off-white);

    &-selected {
      background: var(--lighter-teal);
    }
  }

  &__generate-button-ctr {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__generate-button {
    &.button {
      height: var(--spacing-unit-x6);
    }

    &-generating.button {
      padding-top: 0;
      padding-left: var(--spacing-unit-x6);
      padding-right: var(--spacing-unit-x6);
    }

    &-svg.svg {
      height: var(--spacing-unit-x4);
      width: var(--spacing-unit-x4);
      color: var(--white);
    }

    &-loader {
      margin-bottom: var(--half-spacing-unit);
    }
  }

  &__typography-disabled {
    opacity: 0.5;
  }

  &__right {
    width: 34rem;
    padding: var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x4) 0;
    overflow: auto;
  }

  &__code-block,
  &__empty {
    width: 32rem;
  }

  &__code-block {
    margin-top: var(--spacing-unit-x2);

    &-generating {
      color: var(--border-gray); /* I don't like doing this but no matching style in Typography */
    }
  }

  &__generated-error {
    background-color: var(--status-warning);
    padding: var(--spacing-unit-x4);
    margin-top: var(--spacing-unit-x4);

    &-headline {
      margin-bottom: var(--spacing-unit-x2);
    }

    &-body {
      padding-bottom: var(--spacing-unit-x4);
      border-bottom: var(--standard-border);
      border-bottom-color: var(--status-warning-border);
    }

    &-footer {
      padding-top: var(--spacing-unit-x2);
    }
  }

  &__footer {
    .modal-footer__inner {
      padding-left: var(--spacing-unit-x4);
      padding-right: var(--spacing-unit-x4);
    }

    &__extra {
      display: flex;
      margin-right: auto;

      &-tooltip {
        margin-left: var(--spacing-unit);
      }

      .svg {
        margin-top: 0.3334rem;
      }
    }
  }
}
