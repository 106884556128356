.incoming-webhook-authentication-disabled-section {
  align-items: center;
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x4);
  padding: var(--spacing-unit-x4);
  
  &__info {
    &-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
      justify-content: center;
    }

    &-link {
      padding: calc(var(--spacing-unit-x20) / 12) 0; 
    }
    
    &-text {
      text-align: center;

      &-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: calc(15rem / 12);
        justify-content: center;
      }
    }

    &-title {
      color: var(--text-gray);
    }
  }
  
  &__shield-container {
    align-items: center;
    display: flex;
    height: var(--spacing-unit-x14);
    justify-content: center;
    width: var(--spacing-unit-x14);
  }
}
