.listing-page-help-section {
  display: block;
  min-width: var(--spacing-unit-x28);
  text-align: center;
  margin-top: var(--spacing-unit-x4);

  &__middle-text {
    margin-top: 0.83rem;
    margin-bottom: var(--spacing-unit-x3);
  }

  &__life-saver-image {
    width: 4.56rem;
    margin-bottom: var(--spacing-unit-x2);
  }

  /* stylelint-disable */
  @media (max-width: 1366px) {
    & {
      width: 23.5rem;
    }
  }
}
