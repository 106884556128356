.review-import-advanced-options {
  &__header {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
    cursor: pointer;

    &:hover {
      div {
        color: var(--text-teal);
      }

      svg {
        fill: var(--text-teal) !important;
      }
    }
  }
  
  &__content {
    display: none;

    &-visible {
      display: block;
      margin-top: var(--spacing-unit-x4);
    }
  }

  &__section {
    display: flex;

    &-header {
      width: 17.5rem;
      align-items: center;
      display: flex;
      gap: var(--spacing-unit);
      
      &-title {
        flex-shrink: 0;
      }
    }

    &-content {
      flex-grow: 1;
    }

    &:not(:first-child) {
      padding-top: var(--spacing-unit-x3);
      margin-top: var(--spacing-unit-x3);
      border-top: var(--standard-border);
    }
  }

  &__hidden {
    display: none;
  }

  &__remove-entries {
    align-items: flex-start;

    & > .checkbox {
      margin-top: var(--half-spacing-unit);
    }
  }
  
  &__select,
  &__input__container {
    width: 33rem;
  }

  &__external-id-match {
    &-select {
      margin: var(--spacing-unit-x2) 0;
    }
  }
}
