
.wait-until-in-segment-step {
  .checkbox__label {
    line-height: 1.5rem;
    padding-right: 0;
  }

  &__row {
    display: flex;
    width: 43rem;
    align-items: center;
  }

  &__row__border {
    display: flex;
    width: 41rem;
    border: var(--standard-border-width) solid var(--button-back-gray);
    padding: var(--spacing-unit-x2);
    justify-content: space-between;

    .form-row {
      display: flex;
      padding: 0;
      flex: 0 0 20rem;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    width: 10.75rem;

    label {
      display: block;
    }

    input {
      display: block;
    }

    select {
      display: block;
    }
  }

  &__col-notfirst {
    display: flex;
    flex-direction: column;
    width: 14.75rem;
    margin-left: var(--spacing-unit-x2);
  }

  &__label-checkbox {
    margin-left: var(--spacing-unit-x2);
  }

  &__label-checkbox--nomargin {
    margin-left: 0;
  }

  &__select-small {
    margin-left: var(--spacing-unit-x2);
    flex: 0 0 10rem;
  }

  &__select-large {
    flex: 0 0 20rem;
  }
}
