.content-personalization {
  &__info-action {
    margin-bottom: 0;

    .info-action--wrapper-buttons {
      display: none;
    }

    .text-link {
      display: inline;
    }
  }

  &__copy__trigger {
    margin-left: -0.5rem;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--spacing-unit-x2);
    padding-top: var(--spacing-unit-x4);
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--page-back);
    z-index: var(--z-index-9999);

    &-search {
      min-width: calc(var(--spacing-unit-x2) * 25);
    }
  }

  &__table {
    border-top: 0;

    &-accessor-field {
      max-width: 20rem;
    }

    & .table__th:first-child {
      flex: unset !important;
      min-width: unset !important;
      width: unset !important;
    }

    & .nested-table-row {
      & > div:first-child {
        flex: unset !important;
        min-width: unset !important;
        width: unset !important;
      }
    }

    .actionable-nested-table__thead.actionable-nested-table__thead-fixed {
      border-radius: var(--standard-border-radius) var(--standard-border-radius) 0 0;
      top: var(--spacing-unit-x12);
      border-top: var(--standard-border);
    }

    &-row-action__container {
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__table-custom-header {
    display: flex;
    align-items: center;

    .tooltip__trigger {
      height: 1.4167rem;
    }

    &-title {
      margin-right: var(--spacing-unit);
    }
  }

  &__modal {
    &-body.modal-body {
      max-width: calc(var(--spacing-unit-x2) * 50);
      padding: var(--spacing-unit-x4) var(--spacing-unit-x7) var(--spacing-unit-x2);
    }

    &-body {
      .tooltip__trigger {
        width: 100%;
      }

      &-textarea {
        resize: vertical;
      }
    }

    &-body-name {
      margin-top: var(--spacing-unit-x2);
    }

    &-body-label {
      margin-bottom: var(--spacing-unit-x2);
    }

    &-body-description {
      margin-top: var(--spacing-unit);
    }

    .form-row:first-of-type {
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__save-button:disabled {
    cursor: not-allowed;
  }
}
