
.breadcrumbs {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-unit-x2);

  &__icon {
    margin-right: var(--spacing-unit-x2);
  }

  &__wrap {
    flex-wrap: wrap;
  }

  &__crumb {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit);
    overflow: hidden;
    
    & .drop-down__overflow-button:hover {
      border-radius: var(--standard-border-radius);
      background-color: var(--off-white-focus);
    }
  }

  &__drop-down-open {
    height: unset;
    width: unset;

    .drop-down__overflow-button {
      border-radius: var(--standard-border-radius);
      background-color: var(--lighter-teal);
    }

    & .svg {
      color: var(--light-theme-selected-item);
    }
  }
}
