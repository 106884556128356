.segment-name-with-crm-sync-and-info-hover {
  display: block;
  overflow: hidden;
  gap: var(--spacing-unit);

  &__card-body .segment-details-info-hover__section {
    max-width: 24rem;
  }

  &__content {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit);
  }

  &__crm-status-tooltip__trigger-wrapper {
    align-items: center;
    display: flex;
  }

  &__icon {
    min-width: var(--spacing-unit-x2);
    display: flex;

    &-warning-solid-no-fill {
      fill: var(--yellow-caution);
    }

    &-fail {
      fill: var(--remove-red);
    }

    &-reload-no-fill {
      color: var(--teal);
    }
  }
}
