.marketing-list-opt-ins {
  &__overview-and-search {
    display: flex;
    flex-direction: column;

    .search {
      width: 25rem;
    }
  }

  &__overview {
    display: flex;
    justify-content: space-between;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      display: inline-block;
      color: var(--text-teal);
      font-size: var(--default-font-size);
      line-height: var(--line-height-medium);
    }
  }

  &__total-and-view {
    display: flex;
    align-items: center;
  }

  &__total {
    margin-right: var(--spacing-unit);
  }

  .position-container {
    &__no-overflow {
      margin-left: 0;

      .position-container__children {
        padding: 0;
      }
    }
  }

  .opt-in-search-results {
    margin-top: var(--spacing-unit-x2);
  }
}
