.unsupported-type-modal {
  &__body {
    width: 40.25rem;
    padding: var(--spacing-unit-x6) !important;
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x2);
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--spacing-unit-x4);
  }
}
