.email-composer-preview-inbox {
  height: calc(100% - 6.083rem);
  display: flex;

  &__wrapper {
    min-width: calc(var(--spacing-unit-x20) * 5);
    display: flex;
    flex-direction: column;
    flex: min-content;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    border-bottom: var(--standard-border);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    gap: var(--spacing-unit-x4);

    &-title {
      display: flex;
      align-items: center;
      gap: calc(var(--spacing-unit) + var(--half-spacing-unit));

      &-text {
        overflow: hidden;
        text-overflow: ellipsis;
        /* stylelint-disable */
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        /* stylelint-enable */
      }

      .svg {
        color: var(--text-teal);
      }
    }

    &-actions,
    &-buttons {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
      white-space: nowrap;

      .button {
        padding: 0.666rem;

        .svg {
          width: 1.33rem;
          height: 1.33rem;
        }
      }
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--page-back);
    overflow: auto;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  &__empty {
    max-width: 46rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-unit-x2);

    &-image {
      width: 19rem;
      height: 14rem;
      margin-bottom: var(--spacing-unit-x2);
    }

    &-button {
      width: 25rem;
      margin: var(--spacing-unit-x2) 0;

      .svg {
        width: 1.33rem;
        height: 1.33rem;
      }
    }
  }

  &__loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-unit-x2);

    .spinner__wrapper {
      padding: var(--spacing-unit-x2);
    }
  }

  &__image {
    max-width: 100%;

    &--loading {
      position: absolute;
      visibility: hidden;
    }

    &-warning {
      width: 100%;
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
      border-radius: var(--standard-border-radius);
      border: var(--caution-border);
      background-color: var(--light-yellow);
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
      margin: var(--spacing-unit-x2) 0;
  
      &-circle {
        width: var(--spacing-unit-x4);
        height: var(--spacing-unit-x4);
        padding: var(--spacing-unit);
        border-radius: 50%;
        background-color: var(--yellow-caution);
      }
    }

    &-container {
      min-width: 100%;
      max-height: 100%;
      padding: var(--spacing-unit-x4) var(--spacing-unit-x8);
      text-align: center;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    border-top: var(--standard-border);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

    &-toggles {
      display: flex;
      align-items: center;

      &-wrapper {
        display: flex;
        align-items: center;
      }
    }
    
    &-tooltip {
      width: 19rem;
    }
  }
}
