.forms-listing-page-container {
  &__header-content {
    display: flex;
    gap: var(--spacing-unit-x4);
  }

  &__submits {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-unit);
    align-items: center;

    &__info .svg {
      display: flex;
      align-items: center;
    }
  }

  &__new-form {
    height: var(--spacing-unit-x6);

    &-disabled {
      &.button:hover {
        cursor: not-allowed;
      }
    }

    .svg {
      fill: var(--white);
      margin-right: var(--spacing-unit);
    }
  }

  &__form-name {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-wrapper {
      display: flex;
      flex-direction: row;
      gap: var(--half-spacing-unit);
      overflow: hidden;
    }

    &-typography {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .info-hover-card {
      padding-left: var(--spacing-unit);
      margin-top: var(--half-spacing-unit);
      visibility: hidden;

      &__visible {
        visibility: visible;
      }
    }
  }
}