.progress-bar {
  display: flex;
  justify-content: space-between;
  
  &__button {
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);
    border-radius: var(--extra-large-border-radius);
    margin: 0 var(--spacing-unit);
    
    &-pending,
    &-active {
      background-color: var(--white);
      border: var(--hover-indicator-border);
    }
    
    &-pending {
      border-color: var(--hover-indicator-background);
      cursor: not-allowed;

      &-clickable {
        cursor: pointer;

        &:hover {
          background-color: var(--lighter-teal);
          border-color: var(--teal);
        }
      }
    }

    &-active {
      border-color: var(--teal);
      box-shadow: 0 0 0 var(--thick-border-width) var(--focus-light-teal);
    }

    &-done {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      background-color: var(--teal);

      &:hover {
        background-color: var(--hover-teal);
      }
    }

    &-not-clickable {
      cursor: default;

      .progress-bar__step-header-button:has(.progress-bar__button-done) &:hover {
        background-color: var(--teal);
      }
    }

    &-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__step-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-unit-x2);

    &-button {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  &__separator {
    height: var(--spacing-unit-x4);
    flex-grow: 1;
    display: flex;
    align-items: center;
   
    &-right::after {
      border-radius: 0.083rem 0 0 0.083rem;
    }

    &-left::after {
      border-radius: 0 0.083rem 0.083rem 0;
    }
 
    &::after {
      content: '';
      border: var(--standard-border);
      border-color: var(--hover-indicator-background);
      width: 100%;
    }

    &-teal::after {
      border-color: var(--teal);
    }

    &-hidden {
      visibility: hidden;
    }
  }
}
