.inbox-sidebar-previews-list {
  &__card {
    display: flex;
    flex-direction: column;
    gap: var(--half-spacing-unit);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    cursor: pointer;

    &:hover {
      background-color: var(--hover-row-background-teal);
    }

    &:not(:last-child) {
      border-bottom: var(--standard-border-dark);
    }

    &--selected {
      background-color: var(--lighter-teal);
      box-shadow: var(--selected-option-box-shadow);
    }
  }
}
