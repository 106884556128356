.display-conditions-field {
  display: flex;
  gap: var(--spacing-unit);
  flex-direction: column;

  &__field,
  &__field-validations {
    display: flex;
    gap: var(--spacing-unit-x2);
    align-items: flex-start;
  }

  &__personalization,
  &__field-validations__personalization {
    width: 26.3rem;

    & .selectv2__control-icon-error {
      fill: var(--text-gray) !important;
      color: var(--text-gray) !important;
    }

    &--blank {
      width: 78.2rem;

      & > .display-conditions-field__validation-message {
        padding-left: 0.75rem;
      }
    }
  }

  &__grouping-operator,
  &__field-validations__grouping-operator {
    width: 2.25rem;
    text-align: right;
    align-self: center;
  }

  &__operator,
  &__field-validations__operator {
    width: 26.3rem;
    height: var(--spacing-unit-x6);

    & .selectv2__control {
      height: var(--spacing-unit-x6);
    }

    &--no-value {
      width: 50.8rem;
    }

    &--multiple-value {
      width: 19rem;
    }

    &--date-range {
      width: 36.5rem;
    }
  }
  
  &__field-validations__operator {
    height: unset;
  }

  &__field-validations__value {
    width: 23.5rem;

    &--multiple {
      width: calc(13.3rem + 3rem);
    }

    &--date-range {
      width: 18.9rem;
    }

    &--full-width {
      width: 40rem;
    }
  }

  &__value {
    background-color: var(--white);
    width: 23.5rem;

    &--multiple {
      width: 13.3rem;
    }

    &--full-width {
      width: 40rem;
    }

    &__panel {
      z-index: var(--z-index-999999);

      .ant-picker-date-panel {
        width: calc(var(--date-picker-panel-width) + var(--spacing-unit-x2));
      }
    }
  }

  &__date-format {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--page-back);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
    width: 100%;

    &--range {
      background-color: var(--white);
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }

    .ant-picker-content {
      width: 31.1667rem;
    }

    .ant-picker-dropdown {
      z-index: var(--z-index-9999);
    }

    .date-range-picker__panel-footer {
      display: flex;
      flex-direction: row;
      gap: var(--spacing-unit-x3);
      padding: 0;
    }

    &__select {
      width: 20.75rem;
    }
  }

  &__icons {
    display: flex;
    gap: 0.25rem;
  }
}
