
.menu-item {
  &__scroll {
    max-height: 29rem;

    &__scrollbar {
      margin-right: calc(var(--spacing-unit-x2) / 3);
      z-index: 2;
    }

    .scroll-area__root,
    .scroll-area__viewport {
      height: unset;
      max-height: inherit;
    }
  }

  &__add {
    height: var(--spacing-unit-x4);
    width: var(--spacing-unit-x4);

    &:hover {
      background-color: var(--off-white-focus);
      cursor: pointer;
    }

    & > svg {
      fill: var(--light-gray);
      margin: var(--spacing-unit);
    }
  }
}
