.early-exit-condition-modal {
  width: 50rem;

  &__radio,
  &__select {
    margin-bottom: var(--spacing-unit-x4);

    .tooltip__trigger {
      display: inline-block;
    }
  }

  &__radio {
    .svg {
      margin: 0 var(--spacing-unit);
    }
  }

  &__select {
    margin-top: calc(-1 * var(--spacing-unit-x2));
  }

  .single-select-dropdown__options {
    max-height: 26rem;
  }

  .single-select-dropdown__search-wrapper {
    max-height: var(--spacing-unit-x6);
  }
}
