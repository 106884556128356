.incoming-webhook-select {
  flex-grow: 1;
  padding-bottom: 2.3334rem;
  width: 100%;

  &__label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8334rem;

    > span,
    > div {
      display: flex;
      align-items: center;
    }
  }
  
  &:has(.selectv2__error) {
    padding-bottom: unset;
  }
}