
.chooser-screen {
  width: 43rem;
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-unit) 0;

  .chooser-button {
    border-right: 0.333rem;
    padding: var(--spacing-unit-x2);
    margin: 0;
    flex: 0 0 21.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow: hidden;
    text-decoration: none;

    &__text {
      text-align: left;
    }

    &__title {
      display: block;
      font-size: var(--large-font-size);
      color: var(--light-gray);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 0;
    }

    &__description {
      display: block;
      font-size: var(--small-font-size);
      color: var(--light-gray);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 0;
    }

    &__tooltip {
      gap: var(--spacing-unit);
      display: inline-flex;
      inline-size: max-content;
    }

    &__pill-new {
      color: var(--new-pill);
      background: var(--new-pill-background);
      text-shadow: none;
    }

    &:hover:not(&-disabled) {
      background-color: var(--page-back);

      & .chooser-button__title {
        color: var(--text-gray);
      }

      & .chooser-button__description {
        color: var(--gray);
      }
    }

    &__icon {
      margin-right: var(--spacing-unit-x2);
      width: 2rem;
      height: 2rem;

      svg {
        max-width: 2rem;
        max-height: 2rem;
      }
    }

    &--style-icon {
      svg {
        fill: var(--teal);
      }
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  .chooser-group {
    display: flex;
    flex-wrap: wrap;
    border-bottom: var(--standard-border);
    padding: var(--spacing-unit) 0;

    &:last-child {
      border-bottom: none;
    }
  }

  &--minimal-buttons {
    width: 21.5rem;
    flex-direction: column;

    .chooser-button {
      flex: 0 0 4rem;
      width: 21.5rem;
      white-space: nowrap;
    }
  }

  &--grouped {
    display: block;
  }
}
