.incoming-webhook-content-mapping {
  &__header > :first-child {
    margin-bottom: var(--spacing-unit);
  }

  &__channel-select {
    flex-grow: 1;
    width: 100%;

    &-label {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.8334rem;

      > span,
      > div {
        display: flex;
        align-items: center;
      }
    }
  }
  
  &__loader {
    margin-right: auto;
    margin-left: auto;
  }

  > div + div {
    margin-top: var(--spacing-unit-x4);
  }
}
