
.edit-api-step {
  width: 50rem !important;

  &__row {
    display: flex;
    flex-wrap: nowrap;
  }

  &__col {
    flex: 1 1 100%;
    margin-left: var(--spacing-unit-x4);

    &:first-child {
      margin-left: 0;
    }
  }

  &__columns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .checkbox__container {
      width: var(--spacing-unit-x28);
    }

    .checkbox__label {
      flex: 0 0 24%;
      min-width: var(--spacing-unit-x24);
      max-width: var(--spacing-unit-x24);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: var(--spacing-unit) 0 var(--spacing-unit) var(--spacing-unit);
      line-height: var(--line-height-medium);
    }
  }

  &__preview {
    max-width: 40rem;
    cursor: text;
  }
}
