.automated-program-picker-modal {
  &__program-state {
    border-radius: 50%;
    height: calc(var(--spacing-unit) + var(--half-spacing-unit));
    min-width: calc(var(--spacing-unit) + var(--half-spacing-unit));
    background-color: var(--disabled-plus-gray);
    margin-left: var(--half-spacing-unit);
    margin-right: calc(var(--spacing-unit) + var(--half-spacing-unit));

    &-error {
      fill: var(--remove-red);
      margin-right: calc(var(--spacing-unit-x10) / 12);
      margin-left: calc(var(--spacing-unit-x6) / 12);
    }

    &-running {
      background-color: var(--success-green);
    }

    &-stopped {
      background-color: var(--yellow-caution);
    }
  }

  &__program-name {
    display: flex;
    align-items: center;
    margin-left: calc(-1 * var(--half-spacing-unit));

    &-disabled {
      cursor: not-allowed;
      pointer-events: all;

      .tooltip__trigger {
        cursor: not-allowed !important;
        pointer-events: all;
      }
    }

    &-typography {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      padding-left: var(--spacing-unit);
    }
  }
}