
.pill-label {
  display: inline-block;
  padding: var(--third-spacing-unit) var(--spacing-unit-x2);
  border-radius: var(--spacing-unit-x2);
  border-width: var(--standard-border-width);
  border-style: solid;
  margin: 0 var(--spacing-unit-x2);
  line-height: var(--line-height-standard);

  &__default {
    background-color: var(--lighter-teal);
    border-color: var(--border-teal);
    color: var(--text-teal);
  }

  &__success {
    background-color: var(--status-active);
    border-color: var(--status-active-border);
    color: var(--dark-green);
  }

  &__warning {
    background-color: var(--light-yellow);
    border: var(--caution-border);
    color: var(--yellow-caution);
  }

  &__error {
    background-color: var(--status-warning);
    border-color: var(--status-warning-border);
    color: var(--error-text);
  }

  &__not-set {
    background-color: var(--input-background);
    border-color: var(--button-gray);
    color: var(--text-gray);
  }

  &__custom {
    background-color: var(--sonic-blue-background);
    border-color: var(--sonic-blue);
    color: var(--sonic-blue);
  }

  &__no-margin {
    margin: 0;
  }
}
