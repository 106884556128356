.upgrade-assistant-banner {
  display: block;
  margin-top: var(--spacing-unit-x4);

  .collapsible-banner__content {
    gap: var(--spacing-unit-x3);
    margin: var(--spacing-unit-x4) var(--spacing-unit-x6) 0;
  }

  .collapsible-banner__title-with-icon {
    padding: 0;
  }
  
  &__footer {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
  }
}
