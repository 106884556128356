
.single-input {
  &__index {
    margin-right: var(--spacing-unit-x2);
    width: var(--spacing-unit-x3);
    text-align: right;
  }

  &__input__container {
    width: 100%;
  }
}

