.email-sms-performance {
  min-width: var(--positioning-min-width);
  max-width: var(--positioning-max-width);
  margin-top: var(--spacing-unit-x4);
  margin-bottom: var(--spacing-unit-x4);

  & .last-updated {
    height: var(--spacing-unit-x7);
  }

  &__tabs-container {
    display: none;
    flex-direction: column;
    box-sizing: border-box;
  }

  &__headers {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    background-color: var(--white);
  }

  .typography__type-header-section {
    margin-bottom: var(--spacing-unit);
  }
}
