.button-show-hide {
  display: flex;
  align-items: center;

  &__text {
    color: var(--text-teal);

    &:hover {
      color: var(--darker-teal);
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__icon {
    margin-right: var(--spacing-unit);
  }
}
