
.edit-sms {
  .section-headline {
    margin-bottom: 1.5rem;
  }

  &__main-container {
    display: flex;
  }

  &__left-container {
    flex: 2;
    margin-right: var(--spacing-unit-x4);
  }

  &__right-container {
    flex: 0 0 29rem;

    .section-headline {
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__saving-message {
    width: var(--spacing-unit-x28);
    text-align: right;
    color: var(--light-gray);
    font-size: var(--large-font-size);

    .svg,
    .loader {
      margin-right: var(--spacing-unit);
    }

    .loader {
      display: inline-block;
    }
  }
}
