.collapsible-banner {
  background-color: var(--lighter-teal);
  border: var(--standard-border);
  border-top: var(--thick-border-width) solid var(--teal);
  border-radius: 0 0 var(--standard-border-radius) var(--standard-border-radius);
  padding: 0 0 var(--spacing-unit-x4) var(--spacing-unit-x4);
  position: relative;
  transition: height 200ms ease-in-out;
  
  &__collapsed {
    background-color: unset;
    border: none;
    padding: 0;
    height: 0;
    width: 0;
  }
  
  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: var(--spacing-unit-x4);
    margin: var(--spacing-unit-x4) var(--spacing-unit-x8) 0 var(--spacing-unit-x8);
  }
  
  &__title {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit-x2);
    justify-content: space-between;

    &-with-icon {
      display: flex;
      gap: var(--spacing-unit-x2);
      justify-content: space-between;
      padding-top: var(--spacing-unit);
    }

    &-container {
      display: flex;
      justify-content: space-between;
      padding: var(--spacing-unit-x4) var(--spacing-unit-x2) 0 0;
    }
  }
}
