.allowed-send-time {
  &__inputs {
    display: flex;
    align-items: center;

    & .start-time {
      margin-right: var(--spacing-unit-x4);
    }

    & .end-time {
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__time-error {
    margin-top: var(--spacing-unit);
  }

  &__toggle {
    padding-top: 3rem;
  }
}
