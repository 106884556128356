.copy-segments {
  &__header-link {
    white-space: nowrap;
    
    &:hover .link__text {
      text-decoration: none;
    }

    &:active,
    &:focus-visible {
      border: var(--transparent-border);
      box-shadow: none;
      
      & .link__text {
        text-decoration: none;
      }
    }
    
    & .link__text {
      &:hover {
        text-decoration: none;
      }
    }
  }
  
  &__review-button,
  &__review-button__trigger-wrapper {
    margin-top: var(--spacing-unit-x4);
  }

  &__review-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--spacing-unit-x2);
  }
  
  &__status-toast {
    max-width: 41rem !important;
  }
}
