.actionable-nested-table {
  background-color: var(--white);
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  border-spacing: 0;
  display: flex;
  flex-direction: column;
  min-width: var(--positioning-min-width);
  max-width: var(--positioning-max-width);
  width: 100%;

  &__empty-text {
    padding-left: var(--spacing-unit-x4);
    height: var(--actionable-nested-table-row-height);
    display: flex;
    align-items: center;

    &-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: var(--spacing-unit-x2);
    }

    &-icon-tooltip-trigger {
      display: flex;
    }
  }

  &__extra-options {
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x4);

    :hover {
      background-color: var(--page-back);
      border-color: var(--page-back);

      .svg {
        fill: var(--teal) !important;
      }

      .typography {
        color: var(--text-gray);
        text-decoration: unset;
      }
    }

    & button {
      border: var(--standard-border);
      height: 2.33rem;
      justify-content: start;
      padding: var(--spacing-unit) var(--spacing-unit-x2);
    }

    & .content-expander__nesting-controls {
      padding: var(--spacing-unit) var(--spacing-unit-x2);
      white-space: nowrap;

      .svg {
        fill: var(--gray);
        margin-bottom: unset;
      }
    }

    & .typography {
      color: var(--light-gray);
      font-size: 1.16667rem;
      font-style: normal;
      font-weight: 400;
    }
  }

  &__thead {
    align-items: center;
    border-bottom: var(--input-border-hover);
    border-radius: var(--standard-border-radius) var(--standard-border-radius) 0 0;
    display: flex;
    height: var(--spacing-unit-x8);

    &-fixed {
      position: sticky;
      top: 0;
      z-index: 4;
      background-color: var(--white);
    }

    .th {
      display: flex;
    }
  }

  &__trh {
    gap: var(--spacing-unit-x4);
    padding: var(--spacing-unit) var(--spacing-unit) var(--spacing-unit) 0;
    align-items: center;

    &:first-child {
      padding-left: var(--spacing-unit-x4);
    }
  }

  &__trh:last-of-type .table__right .typography,
  .nested-table-row__cell:last-of-type .table__right {
    margin-right: var(--spacing-unit-x3);
  }

  &__has-overflow {
    max-height: 100%;

    .actionable-nested-table__body {
      overflow-y: auto;
    }
  }

  &__body {
    &-loading {
      display: flex;
      justify-content: center;
    }
  }

  &__sort-div {
    align-self: center;
    margin-left: var(--spacing-unit);
  }
}

.table-action-header {
  height: var(--spacing-unit-x8);
  outline: 0.083rem solid var(--dark-blue);

  > * {
    height: auto !important;
  }

  &__count {
    padding-left: var(--spacing-unit-x2) !important;
  }
}
