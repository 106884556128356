
.folder-search-filter-buttons {
  display: inline-flex;
  gap: var(--spacing-unit-x2);

  &__button {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
    padding-left: var(--spacing-unit-x2);
    padding-right: var(--spacing-unit-x2);
    height: 2.33rem;
    border-color: var(--border-gray);

    svg {
      fill: var(--text-gray);
    }

    &-inactive {
      svg {
        fill: var(--light-gray);
      }

      &:hover {
        background-color: var(--lighter-teal);
        border-color: var(--lighter-teal);

        svg {
          fill: var(--text-gray);
        }

        div {
          color: var(--text-gray) !important;
        }
      }
    }
  }

  &__check {
    fill: var(--teal) !important;
  }
}
