.website-prospector-alerts {
  display: block;

  &__filters {
    &-header {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: var(--spacing-unit-x5);
    }
  }

  &__button {
    &-global-filters {
      margin-bottom: var(--spacing-unit-x4);
      gap: var(--spacing-unit-x2);

      > svg {
        fill: var(--text-gray);
      }
    }
  }

  .__trigger-wrapper {
    display: flex;
  }
  /* stylelint-disable */
  @media (max-width: 1485px) {
    /* stylelint-enable */
    .page-container {
      padding: var(--spacing-unit-x4);
    }
  }
}
