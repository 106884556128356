.checkbox {
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  border-radius: var(--checkbox-border-radius);
  border: var(--standard-border-width) solid var(--border-teal);
  background-color: var(--white);
  transition: var(--linear-80);
  flex-shrink: 0;

  &:hover {
    background-color: var(--lighter-teal);
    border: var(--standard-border-width) solid var(--teal);
  }

  &--checked,
  &--checked:hover {
    background-color: var(--teal);
    border: var(--standard-border-width) solid var(--teal);
  }

  &--disabled,
  &--disabled:hover {
    border-color: var(--border-gray);
    background-color: var(--input-background);
    cursor: not-allowed;
  }

  &--disabled + &__label {
    cursor: auto;
    color: var(--light-gray);
  }

  &--indeterminate,
  &--indeterminate:hover {
    background-color: var(--teal);
    border: var(--standard-border-width) solid var(--teal);
  }

  &__indicator {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: var(--checkbox-border-radius);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &--checked > &__indicator {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='-3 0 16 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 64 (93537) - https://sketch.com --%3E%3Ctitle%3EIcon Fill%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3Cpath d='M7.15277938,9.26620655 L5.19337525,7.95993713 C4.61896438,7.57699656 3.84287771,7.73221389 3.45993713,8.30662475 C3.07699656,8.88103562 3.23221389,9.65712229 3.80662475,10.0400629 L6.80662475,12.0400629 C7.38124372,12.4231422 8.15763311,12.2676574 8.54041797,11.6928423 L12.8624395,5.20260791 C13.2450857,4.62800093 13.0894708,3.85199388 12.5148638,3.46934765 C11.9402568,3.08670142 11.1642497,3.24231635 10.7816035,3.81692334 L7.15277938,9.26620655 Z' id='path-1'%3E%3C/path%3E%3Cfilter x='-5.1%25' y='-5.6%25' width='110.2%25' height='122.2%25' filterUnits='objectBoundingBox' id='filter-2'%3E%3CfeOffset dx='0' dy='1' in='SourceAlpha' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0' type='matrix' in='shadowOffsetOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg id='Settings-&gt;-Other-Settings-&gt;-Beacon-Settings' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Inputs-/-Checkbox-/-Checkbox-Only-/-Selected' transform='translate(-3.000000, -3.000000)'%3E%3Crect id='Fill' fill='none' x='0' y='0' rx='2'%3E%3C/rect%3E%3Cg id='Icon-Fill' fill-rule='nonzero'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill='%23FFFFFF' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  &--disabled > &__indicator {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='-4 0 17 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E78435F00-FBBD-4A30-ACD9-0E349046863D%3C/title%3E%3Cg id='inputs' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='checkbox' transform='translate(-51.000000, -357.000000)' fill='%23AEAEAE'%3E%3Cg id='Icon-Fill' transform='translate(48.000000, 354.000000)'%3E%3Cpath d='M6.90291222,9.26620655 L4.94350809,7.95993713 C4.36909723,7.57699656 3.59301055,7.73221389 3.21006997,8.30662475 C2.8271294,8.88103562 2.98234674,9.65712229 3.5567576,10.0400629 L6.5567576,12.0400629 C7.13137657,12.4231422 7.90776595,12.2676574 8.29055082,11.6928423 L12.6125723,5.20260791 C12.9952185,4.62800093 12.8396036,3.85199388 12.2649966,3.46934765 C11.6903896,3.08670142 10.9143826,3.24231635 10.5317364,3.81692334 L6.90291222,9.26620655 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  &--indeterminate > &__indicator {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+SW50ZXJtZWRpYXRlIENoZWNrbWFyazwvdGl0bGU+CiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkludGVybWVkaWF0ZS1DaGVja21hcmsiPgogICAgICAgICAgICA8cmVjdCBpZD0iRmlsbCIgZmlsbD0iIzAwQkFCRSIgeD0iMCIgeT0iMCIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiByeD0iMiI+PC9yZWN0PgogICAgICAgICAgICA8cmVjdCBpZD0iU2hhZG93IiBmaWxsPSIjMDA5QTlFIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHg9IjMiIHk9IjgiIHdpZHRoPSIxMCIgaGVpZ2h0PSIyIiByeD0iMSI+PC9yZWN0PgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiBmaWxsPSIjRkZGRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHg9IjMiIHk9IjciIHdpZHRoPSIxMCIgaGVpZ2h0PSIyIiByeD0iMSI+PC9yZWN0PgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
  }

  &:focus-visible {
    background-color: var(--white);
    box-shadow: var(--keyboard-focus-box-shadow);
  }

  &__container {
    display: flex;
    align-items: center;

    &-multiline {
      align-items: flex-start;
    }
  }

  &__label {
    font-size: var(--large-font-size);
    color: var(--text-gray);
    cursor: pointer;
    margin-left: var(--spacing-unit);

    &-multiline {
      line-height: var(--line-height-standard);
    }

    &--small {
      font-size: var(--medium-font-size);
    }
  }
}
