.program-flow {
  position: relative;
  height: calc(100vh - var(--top-bar-height) - 9.8rem);
  overflow: auto;
  background-color: var(--page-back);

  .loader {
    z-index: 100;
  }

  &--panning {
    cursor: grabbing;
    user-select: none;
  }

  &--can-pan {
    cursor: grab;
  }

  &__inner {
    position: relative;
  }

  &__zoom {
    position: absolute;
    z-index: 40;
    right: var(--spacing-unit-x5);
    bottom: var(--spacing-unit-x5);
    width: 3.33rem;

    button {
      width: 3.33rem;
      height: 3.33rem;
      display: flex;
      margin-bottom: var(--standard-border-width);
      justify-content: center;
      align-items: center;
      background-color: var(--button-back-gray);

      &:hover {
        background-color: var(--button-hover);
      }

      svg {
        fill: var(--text-gray);
        width: 1.33rem;
        height: 100%;
      }
    }
  }

  &__print-button {
    position: absolute;
    z-index: 40;
    top: var(--spacing-unit);
    right: var(--spacing-unit-x2);
  }

  &__border-top,
  &__border-left,
  &__border-bottom,
  &__border-right {
    position: absolute;
    z-index: 1;

    &--goto {
      background-color: var(--dark-yellow-border);
    }

    &--copy {
      background-color: var(--icon-back-gray);
    }
  }

  &__border-top {
    left: 0;
    top: 0;
    height: 1rem;
    width: calc(100vw - var(--nav-width));
  }

  &__border-left {
    left: 0;
    top: 0;
    height: calc(100vh - var(--top-bar-height) - 9.8rem);
    width: 1rem;
  }

  &__border-right {
    right: 0;
    top: 0;
    height: calc(100vh - var(--top-bar-height) - 9.8rem);
    width: 1rem;
  }

  &__border-bottom {
    left: 0;
    bottom: 0;
    height: 1rem;
    width: calc(100vw - var(--nav-width));
  }

  &__delete-body {
    width: 32rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .page-headline {
      padding-top: var(--spacing-unit-x2);
    }

    &-text {
      flex: 0 0 100%;
      padding: var(--spacing-unit-x2) 0 var(--spacing-unit) 0;
      color: var(--light-gray);
      text-align: center;
      font-size: var(--large-font-size);
    }
  }

  &__prevent-delete-modal {
    .modal-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-unit-x3);
      padding: var(--spacing-unit-x6) 0 var(--spacing-unit-x2);
    }
  }
}
