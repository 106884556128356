.media-link {
  width: 50rem;
  height: auto;
  overflow: visible !important;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }

  label {
    display: inline !important;
  }

  &__divider {
    margin-top: var(--spacing-unit-x4) !important;
  }

  &__link-name-input {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__input-error {
    border: var(--input-border-error);
  }

  &__replace-button {
    margin-top: var(--spacing-unit);
    color: var(--text-teal);

    &:hover {
      color: var(--darker-teal);
    }
  }

  .input-with-status {
    width: 46rem;
  }

  &__selectV2 {
    & .selectv2__clear-indicator {
      &-with-dropdown-indicator-hidden {
        margin-right: var(--spacing-unit) !important;
      }
    }
  }

  &__selectV2-container {
    position: relative;
    height: inherit;
    margin-top: var(--spacing-unit-x2);
    padding-left: var(--spacing-unit-x3);
    margin-left: var(--spacing-unit);

    &::before {
      position: absolute;
      content: '';
      width: 0.33rem;
      height: 100%;
      border-radius: var(--standard-border-radius);
      left: 0;
      top: 0;
      background-color: var(--teal);
    }
  }

  &__bottom-options {
    margin-top: var(--spacing-unit-x4);
    position: relative;
    display: flex;
    gap: var(--spacing-unit);
    align-items: center;
  }

  .modal-body,
  .modal-footer__inner {
    padding: var(--spacing-unit-x4);
  }

  .modal-footer__inner {
    gap: var(--spacing-unit-x4);
  }

  .button.media-link__footer-button-add {
    margin: 0;
    line-height: 1.6667rem;
  }

  &__footer {
    &-button {
      &-cancel {
        padding: 0.9167rem 0;
      }

      &-add {
        padding: 0.6667rem var(--spacing-unit-x4);
      }
    }
  }

  .modal-body {
    overflow: visible !important;
  }
}
