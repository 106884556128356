.selected-lists {
  display: flex;
  flex-direction: column;
  gap: 2.66rem;
  padding: 2.166rem calc(3.33rem - var(--spacing-unit-x2)) 2.166rem 3.33rem;

  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
  }

  &__table {
    border: none;
    max-width: unset;

    & .table__th:first-child {
      flex: unset !important;
    }

    & .nested-table-row {
      & > div:first-child {
        flex: unset !important;
      }

      &:last-child {
        border-bottom: var(--standard-border);
      }
    }
  }

  &__records {
    text-align: right;
  }

  .td&__records {
    padding-right: var(--spacing-unit-x9);
  }

  .td&__ucl-source {
    padding-right: var(--spacing-unit-x2);
  }

  .td&__source {
    padding-right: 1.3rem;
  }

  .td&__ucl-source,
  .td&__source {
    > div {
      min-width: 0;
    }

    button {
      max-width: 100%;
    }
  }

  &__scroll-area {
    height: 38rem;

    .scroll-area__viewport > div {
      display: flex !important;
      flex-direction: column;
      gap: 2.916rem;
      padding-right: var(--spacing-unit-x2);
    }
  }
}
