.email-layouts-modal {
  width: 100%;
  height: 100%;
  max-width: 100rem;
  max-height: 66rem;

  &__header {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    width: 100%;

    &__right {
      gap: var(--spacing-unit-x4);
    }

    &__search {
      width: 25rem;
    }

    &__manage-button {
      height: var(--spacing-unit-x6);
      padding: 0 var(--spacing-unit-x2);

      .svg {
        margin: 0 var(--spacing-unit) 0 0;
      }
    }

    &--preview {
      display: grid;
      grid-template-columns: auto 1fr auto;
    }
  }

  &__footer {
    .modal-footer__inner {
      padding: var(--spacing-unit-x4);
    }

    &__left {
      margin-right: auto;
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      gap: var(--spacing-unit-x4);
    }

    &__preview {
      &-button {
        svg {
          margin-right: calc(10rem / 12);
        }
      }

      &-selected-text {
        &__trigger-wrapper {
          & > .typography {
            display: flex;
            align-items: center;

            .typography {
              padding-right: var(--spacing-unit);
            }
          }
        }
      }
    }
  }
}
