.incoming-webhook-authentication {
  &__header {
    > :first-child {
      margin-bottom: var(--spacing-unit);
    }
  }

  &__type {
    &-select {
      margin-top: 0.8334rem;
    }
  }

  > div + div {
    margin-top: var(--spacing-unit-x4);
  }
  
  &__authentication-container {
    display: flex;
    justify-content: center;
    padding: var(--spacing-unit-x4) 0;
    background-color: var(--input-background);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
  
    &-dashed {
      border-style: dashed;
    }
     
    &-loading {
      background-color: var(--lighter-teal);
      border-color: var(--border-teal);
     
      .loader {
        height: 1.6667rem;
        width: 1.6667rem;
        margin-right: var(--spacing-unit-x2);
      }
    }

    &-error {
      border: var(--dashed-border-error);
      background-color: var(--status-warning);

      > div {
        color: var(--error-text);
      }
    }
  }

  &__show-password {
    display: flex;
    gap: var(--spacing-unit);
    align-items: center;

    svg,
    div {
      fill: var(--text-teal);
      color: var(--text-teal);
    }

    &:hover {
      cursor: pointer;

      svg,
      div {
        fill: var(--darker-teal);
        color: var(--darker-teal);
      }
    }
  }
}
