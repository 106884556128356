.button-with-loader {
  height: var(--spacing-unit-x6);

  &:has(&__loader) {
    background-color: var(--hover-teal);
  }

  &__loader {
    display: inline-flex;
  }
}
