.status-toast {
  &__success,
  &__warning,
  &__fail,
  &__loading {
    height: 100%;
    min-width: var(--spacing-unit-x8);
    max-width: var(--spacing-unit-x8);
    display: flex;
    align-content: center;
    align-items: center;
    margin-right: var(--spacing-unit-x2);
    border-radius: 0.333rem 0 0 0.333rem;

    svg {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
      fill: var(--white);
    }
  }

  &__success {
    background-color: var(--success-green);
  }

  &__warning {
    background-color: var(--yellow-caution);
  }

  &__fail {
    background-color: var(--error);
  }
   
  &__loading {
    background-color: var(--lighter-teal);

    .loader--page {
      height: unset;
    }
  }

  &__text-with-title {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-unit-x2) 0;
    max-width: calc(var(--spacing-unit-x20) * 5);

    &__text {
      display: contents;
    }
  }
}
