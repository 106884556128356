.sales-users {
  &__sub-header-container {
    height: var(--spacing-unit-x6);
    margin-bottom: var(--spacing-unit-x4);
  }

  &__table-info-bar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-unit-x2);

    &__actions {
      display: flex;
      margin-left: auto;
      margin-right: var(--spacing-unit-x2);
    }

    &__search {
      min-width: calc(var(--spacing-unit-x2) * 25);
    }
  }

  &__count-info {
    display: flex;
    grid-gap: var(--spacing-unit-x5);
  }
}
