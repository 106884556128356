.new-leads {
  &__actions {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: var(--z-index-chart-extra);

    &-select {
      min-width: calc(var(--spacing-unit-x20) + var(--spacing-unit-x13));
      margin-right: var(--spacing-unit);
    }

    &-toggle {
      display: flex;
      align-items: center;
    }
  }

  & .chart .highcharts-container svg {
    width: 100%;
  }

  & li .highcharts-a11y-proxy-button {
    cursor: default !important;
  }
}
