.code-editor-with-preview {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  height: calc(100vh - var(--spacing-unit-x24));
  overflow-y: hidden;

  &__preview {
    border: none;
    height: 100%;
    width: 100%;

    &-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &-container {
      position: relative;
      min-width: var(--upload-html-code-editor-min-width);
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  &__editor {
    min-width: var(--upload-html-code-editor-min-width);
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;

    &-header {
      background-color: var(--white);
      border: var(--standard-border);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: calc(var(--spacing-unit) + var(--half-spacing-unit)) var(--spacing-unit-x2) calc(var(--spacing-unit) + var(--half-spacing-unit))
        var(--spacing-unit-x4);
      border-top: none;

      &-actions {
        display: flex;
        align-items: center;
        gap: var(--spacing-unit);

        &-icon-only {
          margin-top: calc(var(--spacing-unit-x2) / 3);
          margin-bottom: calc(var(--spacing-unit-x2) / 3);
        }

        &-download {
          display: flex;
          align-items: center;
          padding: var(--spacing-unit) var(--spacing-unit-x2);
          gap: var(--spacing-unit);

          & .typography {
            text-decoration: none;
            color: var(--text-teal);
          }

          & .svg--larger-icon {
            width: 1.33rem;
            height: 1.33rem !important;
          }

          &:disabled {
            & .typography {
              cursor: not-allowed;
              color: var(--text-gray);
            }

            & .svg--larger-icon {
              fill: var(--text-gray) !important;
            }
          }
        }

        &-copy {
          &:disabled {
            color: var(--text-gray);
            cursor: not-allowed;

            & .svg--larger-icon {
              fill: var(--text-gray) !important;
            }

            & .typography {
              cursor: not-allowed;

              &:hover {
                color: var(--text-gray) !important;
              }
            }
          }
        }
      }

      /* stylelint-disable */
      @media only screen and (max-width: 396px) {
        /* stylelint-enable */
        &__editor-header {
          margin: calc(var(--spacing-unit-x4) / 3) 0;
        }
      }
    }
  }

  &__resize {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: calc(var(--spacing-unit) / 6);
    cursor: col-resize;
    z-index: var(--z-index-99);
    background-color: transparent;

    &:hover {
      box-shadow: var(--resizable-bar-shadow);
      background-color: var(--teal);
    }

    &:active {
      box-shadow: var(--resizable-bar-shadow);
      background-color: var(--teal);
    }
  }
}
