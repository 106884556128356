.dynamic-content {
  min-width: 100rem;
  max-width: 100rem;
  height: 60rem;

  @media (min-height: 60rem) {
    height: unset;
    max-height: 75rem;
  }

  &--hidden {
    opacity: 0;
  }

  .modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  &__body {
    &-header {
      padding: var(--spacing-unit-x3) var(--spacing-unit-x6) 0 var(--spacing-unit-x6);
      position: sticky;
      top: 0;
      height: var(--spacing-unit-x12);
      background-color: var(--white);
      z-index: var(--z-index-900);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);

      &-shadow {
        box-shadow: var(--box-shadow);
        padding-bottom: var(--spacing-unit-x3);
      }
    }

    &-content {
      padding-left: var(--spacing-unit-x6);
      padding-right: var(--spacing-unit-x6);
    }
  }

  &__sub-header {
    padding-bottom: var(--spacing-unit-x4);
  }

  &__connect-link {
    padding-top: var(--spacing-unit-x2);
  }

  &__inline-editor {
    max-width: 90rem;
  }

  &__error-message {
    height: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit);

    &-text {
      margin-bottom: var(--spacing-unit-x4);
    }
  }

  &__description {
    height: 7.5rem;

    &-errors {
      border: var(--solid-error-border);

      &:hover {
        border: var(--solid-error-border);
      }

      &:focus {
        border-color: var(--error-focus);
        outline: unset;
      }
    }
  }

  &__empty-body-text-description {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x3);
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-unit);

    &__image {
      margin-right: var(--spacing-unit-x3);
      width: var(--spacing-unit-x22);
      height: var(--spacing-unit-x16);
    }
  }

  &__body-description__middle-text {
    margin-top: var(--spacing-unit);
    margin-bottom: var(--spacing-unit);
  }

  &__remove-all {
    display: flex;
    gap: var(--spacing-unit);

    &-svg-container {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
      padding: 0.33334rem;
    }

    .svg {
      height: 1.33334rem;
      width: 1.33334rem;
    }
  }

  &__header {
    padding-left: var(--spacing-unit-x4);
    padding-right: var(--spacing-unit-x4);
  }

  &__footer {
    & .modal-footer__inner {
      padding: var(--spacing-unit-x4);
    }

    &__inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-unit-x4);
    }
  }
}
