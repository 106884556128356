.send-details-suppressions {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  &__table {
    border-bottom: var(--standard-border);
    margin: 0 calc(-1 * var(--spacing-unit-x4)) var(--spacing-unit-x4);
  }

  &__toggle {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__toggle:first-of-type,
  &__bounces {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__bounces {
    display: flex;
    align-items: center;
    gap: var(--half-spacing-unit);
  }

  &__header-variant {
    margin-bottom: var(--spacing-unit-x2);
  }
}
