.segment-definition-element-with-dn-d {
  &__dragging {
    > div {
      border: var(--dashed-border);
      border-color: var(--hover-indicator-background);
      background-color: var(--chip-base);
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--chip-base);
      }
    }
  }

  &__is-over {
    border-bottom: var(--text-teal-border);
    padding-bottom: var(--spacing-unit);

    &-top {
      border-top: var(--text-teal-border);
      padding-top: var(--spacing-unit);
    }
  }
}
