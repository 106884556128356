.sync-results {
  width: 48rem;
  height: 62rem;
  border: var(--standard-border);

  .svg {
    width: var(--spacing-unit-x3);
    height: var(--spacing-unit-x3);
    color: var(--overlay);
  }

  .modal-body__inner {
    overflow: overlay;
    height: calc(100% - 5rem);
  }

  .modal-header__close-button {
    padding: var(--spacing-unit-x2);
    top: var(--spacing-unit-x2);
    right: var(--spacing-unit-x2);
  }

  &__send-date-col {
    width: var(--spacing-unit-x25);
  }

  &__title-text {
    display: flex;
    justify-content: center;
    align-content: center;
    border-bottom: 0;
    font-weight: var(--font-weight-lighter);
    margin-top: var(--spacing-unit-x6);
    font-size: var(--larger-font-size);
    color: var(--text-gray);
    line-height: var(--line-height-larger);
  }

  &__panel {
    text-align: center;
    margin-top: 0;
    background-color: var(--white);
  }

  &__panel-padding {
    text-align: center;
    margin-top: 0;
    background-color: var(--white);
    padding-top: var(--spacing-unit-x4);
  }

  &__results {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-height: 30rem;
    overflow-y: auto;

    .tr {
      cursor: default;
    }

    .th:hover {
      text-decoration: none;
      cursor: default;
    }
  }

  &__opt-activity {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  &__opt-activity2 {
    display: grid;
    grid-template-columns: 8rem 8rem 8rem 8rem;
    grid-template-rows: auto;
    column-gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
  }

  &__opt-item {
    justify-self: left;
    font-size: var(--larger-font-size);
    line-height: var(--line-height-larger);
    font-weight: var(--font-weight-bold);
  }

  &__opt-header {
    font-size: var(--smallest-font-size);
    line-height: var(--line-height-large);
    justify-self: left;
  }

  &__results > .table__table-holder {
    min-width: var(--spacing-unit-x2);
    width: 40rem;
    margin-top: var(--spacing-unit-x2);
    border: 0;
  }

  &__opt-activity > .table__table-holder {
    width: 27rem;
    border: 0;
  }

  &__table-title {
    font-size: var(--larger-font-size);
    margin-top: var(--spacing-unit-x10);
    font-weight: var(--font-weight-medium);
    color: var(--text-gray);
  }

  &__sub-header {
    font-weight: var(--font-weight-lightest);
    color: var(--light-gray);
    margin-bottom: var(--spacing-unit-x8);
  }
}
