.export-file-selection-screen {
  &__selected-file {
    width: fit-content;
    max-width: 100%;
    min-width: 42rem;

    &-wrapper {
      padding: var(--spacing-unit-x4);

      > div:first-child {
        margin-bottom: var(--spacing-unit-x2);
      }
    }
  }

  &__button {
    &-next {
      display: flex;
      justify-content: flex-end;
      margin-top: var(--spacing-unit-x2);
    }

    &-new-file {
      display: flex;
      padding: var(--spacing-unit) var(--spacing-unit-x3);
    }
  }

}
