.disabled-tab_with-tooltip {
  &__wrapper {
    display: flex;
    margin-right: var(--spacing-unit-x6);
    padding-bottom: calc(var(--spacing-unit) + var(--half-spacing-unit));
    cursor: not-allowed;
  }

  &_label {
    display: inline-flex;
    margin-right: 0.3rem;
    cursor: not-allowed;
  }

  &_icon {
    background: var(--border-gray);
    line-height: 0.75rem;
    border: var(--standard-border-radius) solid var(--border-gray);
    border-radius: 50%;
    box-sizing: border-box;
    display: grid;
    margin-left: 0.5rem;
    cursor: not-allowed;
  }

  &__tab-dot-label {
    cursor: not-allowed;
  }

  &_container {
    display: flex;
  }
}
