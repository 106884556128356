.carousel-data-card {
  &__dot {
    background-color: var(--lightest-gray);
    border-radius: 50%;
    cursor: pointer;
    margin: 0 calc(var(--spacing-unit) / 3);
    height: calc(var(--spacing-unit-x4) / 3);
    width: calc(var(--spacing-unit-x4) / 3);
  }

  &__dot--active {
    background-color: var(--teal);
    z-index: var(--z-index-999999) !important;
  }

  & .data-card__background {
    padding-left: 0;
    padding-top: 0;
    padding-right: var(--spacing-unit-x2);
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-unit-x2);
  }

  &__ribbon {
    align-items: center;
    border-bottom-left-radius: calc(var(--spacing-unit-x2)/3);
    border-top-left-radius: calc(var(--spacing-unit-x2)/3);
    display: flex;
    justify-content: center;
    width: var(--spacing-unit-x12);
    height: 7.8rem;

    & svg {
      padding: var(--spacing-unit-x3);
      height: var(--spacing-unit-x16);
      margin-bottom: calc((var(--half-spacing-unit) /2) * -1);
      width: auto;

    }

    &-empty {
      background-color: var(--off-white-hover);

      & svg {
        padding: var(--spacing-unit-x5);
      }
    }

    &-first {
      background-color: var(--hover-teal);
    }

    &-second {
      background-color: var(--salmon-medal);
    }

    &-third {
      background-color: var(--dark-blue-medal);
    }

    &-fourth {
      background-color: var(--yellow-medal);
    }

    &-fifth {
      background-color: var(--purple-medal);
    }
  }

  &__title-section {
    align-content: space-around;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-unit-x2) 0;
    row-gap: inherit;

    & .typography {
      color: var(--text-gray);
    }

    &-empty {
      white-space: break-spaces;
      justify-content: center;
    }
  }
  
  &__subtitle {
    &-empty {
      color: var(--light-gray);
    }
  }

  &__indicator {
    max-width: fit-content;

    & .button {
      padding: unset;
    }
  }

  &__button {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;

    &-empty {
      &:hover { 
        background-color: unset;
      } 
    }
  }

  &__loader {
    width: 100%;
    align-items: center;
  }
}
