.accordion-step {
  &__header {
    height: var(--spacing-unit-x10);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    justify-content: space-between;
    min-height: var(--spacing-unit-x6);
    cursor: pointer;

    &-label,
    &-right {
      gap: var(--spacing-unit-x3);
    }

    &-label svg {
      color: var(--button-gray);
      fill: var(--button-gray);
    }

    &-icon {
      color: var(--hover-indicator-background);
    }
    
    button {
      visibility: hidden;
    }
  }

  &:not(&__selected) &__header:hover {
    background-color: var(--hover-row-background-teal);
  }

  &__selected &__header {
    cursor: default;
  }
  
  &__header:hover button,
  &__selected button {
    visibility: visible;
  }
  
  &__header:hover &__header-label svg,
  &__selected &__header-label svg {
    color: var(--text-teal);
    fill: var(--text-teal);
  }

  &__content {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x4);

    & > .tooltip__trigger {
      margin-top: var(--spacing-unit-x4);
      width: 100%;
    }
  }
}
