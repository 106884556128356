.image-picker-modal {
  width: 100%;
  height: 100%;

  &__body {
    display: grid;
    grid-template-rows: var(--spacing-unit-x10);
    grid-template-columns: minmax(max-content, 0);
    grid-template-areas: 'sidebar filter' 'sidebar container';

    .asset-picker-sidebar {
      grid-area: sidebar;
      display: grid;
      max-width: 25rem;
      border-right: var(--standard-border);
      overflow: hidden;
    }

    &:has(.empty-listing),
    &:has(.image-picker-modal-preview) {
      display: flex;
    }
  }

  &__sidebar {
    &__menu {
      border: 0;
    }

    &__blurb {
      background-color: var(--input-background);
      margin: auto var(--spacing-unit-x4) var(--spacing-unit-x4);
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
      border-radius: var(--standard-border-radius);
    }
  }

  &__footer-forceTooltip {
    .modal-footer-v2 {
      &__inner-left {
        max-width: calc(100vw - 25rem);
      }

      &__view-selected-button {
        width: 15rem;
      }
    }

    .image-picker-modal__footer__preview-selected-text__trigger-wrapper {
      width: calc(100vw - 39rem);
    }
  }

  .modal-footer-v2 {
    &__view-selected-button {
      width: 15rem;
    }
  }
}
