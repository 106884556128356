.sms-report {
  &__main {
    display: flex;
    max-width: 84rem;
    margin-top: var(--spacing-unit-x2);
  }

  &__content {
    flex: 1;
    margin-right: var(--spacing-unit-x4);

    .loader {
      top: 50%;
      left: 30%;
    }
  }

  &__download-btn {
    margin-right: calc(var(--spacing-unit) - var(--spacing-unit-x2));
  }

  &__sidebar {
    width: 21.6667rem;
    margin-bottom: var(--spacing-unit-x4);
  }

  &__download {
    font-weight: var(--font-weight-medium-light);
    line-height: var(--line-height-standard);
  }
}
