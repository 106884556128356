.recipients-section {
  &__list {
    margin: var(--spacing-unit-x3) var(--spacing-unit-x4);
    background-color: var(--light-theme-background-darker);
    border-radius: var(--standard-border-radius);
    padding: calc(var(--spacing-unit-x10) / 3);

    &-content {
      margin: calc(var(--spacing-unit-x4) / 3);
    }
  }

  &__footer {
    padding: 0 0 calc(var(--spacing-unit-x5) / 3) calc(var(--spacing-unit-x20) / 3);
  }

  &__emails-modal {
    max-width: 50rem;

    &__header {
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    }

    &__body.modal-body {
      padding: var(--spacing-unit-x4);
    }

    &__info {
      background-color: var(--light-theme-background-darker);
      padding: var(--spacing-unit-x4);
    }

    &__footer {
      .modal-footer__inner {
        padding: var(--spacing-unit-x4);
      }
    }
  }

  &__recipients {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-no-ellip {
      white-space: normal;
      overflow: unset;
      text-overflow: unset;
    }
  }

  &__svg-tooltip {
    vertical-align: middle;
  }
}
