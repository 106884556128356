.saved-rows {
  width: 40rem;

  .modal-header__inner,
  .modal-footer__inner,
  .modal-body {
    padding-right: var(--spacing-unit-x4);
    padding-left: var(--spacing-unit-x4);
  }

  &__row-name-input__container {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__inputV2-label {
    .labelV2__required {
      font-size: var(--large-font-size);
    }
  }
}
