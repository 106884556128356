.review-field-replacement {
  display: grid;
  grid-template-columns: 1fr var(--spacing-unit-x12) 1fr;
  row-gap: var(--spacing-unit-x2);
  
  &__arrow {
    align-self: center;
    fill: var(--gray-icon);
    justify-self: center;
  }
  
  &__change-to-container {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit-x16) / 12);
    padding: calc(var(--spacing-unit-x18) / 12) 0;
  }

  &__condition {
    white-space: nowrap;

    &-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: var(--half-spacing-unit);
    }

    &-source {
      background-color: var(--chip-base);
      border-radius: var(--spacing-unit-x2);
      padding: var(--half-spacing-unit)calc(var(--spacing-unit-x18) / 12);
      height: var(--spacing-unit-x4);
    }
  }
  
  &__current-field {
    background-color: var(--selectable-row-hover);
    border: var(--standard-border-darker);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    
    &-container {
      background-color: var(--input-background);
      border-radius: var(--standard-border-radius);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
      padding: calc(var(--spacing-unit-x18) / 12) var(--spacing-unit-x2);
    }
  }
  
  &__value-container {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit-x16) / 12);
  }
}