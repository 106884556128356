.removed-steps-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x3);

  &__box {
    padding-left: var(--spacing-unit-x2);
    padding-right: var(--spacing-unit-x2);
  }

  &__operations {
    column-gap: var(--spacing-unit-x4);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: var(--spacing-unit-x3);
  }
  
  &__source-list-container {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit-x16)/12);
    width: 36.0834rem;
  }

  &__steps {
    gap: var(--spacing-unit-x4);
    padding: var(--spacing-unit-x3);
  }

  .step-pill {
    margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x3) 0;
  }
}