.review-push-modal {
  width: 80rem;

  .modal-body {
    display: grid;
  }
  
  &__caution {
    margin-top: 0;
    margin-bottom: var(--spacing-unit-x4);
  }

  &__container-header {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);
  }
   
  &__simple-list {
    padding-bottom: var(--spacing-unit);

    &-header {
      display: flex;
      gap: var(--spacing-unit-x2);
      border-bottom: var(--input-border-hover);
 
      &-title {
        align-items: center;
        border-radius: var(--small-border-radius);
        padding: var(--half-spacing-unit) var(--spacing-unit-x2);
        margin-bottom: var(--spacing-unit);
        flex: 1 1 0;
  
        &-blue {
          background-color: var(--background-list-header);

          .typography {
            color: var(--dark-blue);
          }
        }
        
        &-teal {
          background-color: var(--hover-row-background-teal);

          .typography {
            color: var(--text-teal);
          }
        }

        &-allow {
          flex: unset;
          width: var(--spacing-unit-x15);
        }
      }
    }

    &-row {
      display: flex;
      gap: var(--spacing-unit-x2);

      &-cell {
        flex: 1 1 0;
        padding-left: var(--spacing-unit-x2);
        overflow: hidden;
        margin-top: var(--spacing-unit-x2);

        &-allow {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          flex: unset;
          padding-left: unset;
          width: var(--spacing-unit-x15);
        }
      }
    }
  }
}
