
.data-management {
  &__headline {
    margin-left: 0;
  }

  &__save-header {
    width: 33rem;
    padding-top: var(--spacing-unit);
    padding-bottom: var(--spacing-unit-x2);
  }

  &__overflow {
    padding-left: var(--spacing-unit-x3);
    padding-right: var(--spacing-unit-x2);
  }

  &__confirmation-modal {
    & .modal-footer__inner {
      padding: var(--spacing-unit-x8) var(--spacing-unit-x6) var(--spacing-unit-x4);
    }

    & .button--delete {
      background-color: var(--remove-red);
  
      &:hover {
        background-color: var(--remove-hover-red);
      }
    }
  
    & .confirmation-modal__body {
      padding: var(--spacing-unit-x4) var(--spacing-unit-x11) var(--spacing-unit-x2);
    }
  }
}

.custom-prompt {
  & .modal-footer__inner {
    padding: var(--spacing-unit-x8) var(--spacing-unit-x6) var(--spacing-unit-x4);
  }

  & .button--delete {
    background-color: var(--remove-red);

    &:hover {
      background-color: var(--remove-hover-red);
    }
  }

  & .confirmation-modal__body {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x11) var(--spacing-unit-x2);
  }
}
