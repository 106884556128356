.tableV2-head {
  &__thead {
    display: table;
    width: 100%;
    height: var(--spacing-unit-x10);
    table-layout: fixed;
    background-color: var(--white);
    position: sticky;
    top: 0;
    z-index: var(--z-index-999);
    border-top-left-radius: var(--standard-border-radius);
    border-top-right-radius: var(--standard-border-radius);
    box-shadow: var(--box-shadow-table-header-border);

    &:has(.tableV2-action-header) {
      .tableV2-head__row {
        z-index: auto;
      }
    }
  }

  &__row {
    position: relative;
    z-index: var(--z-index-9);
  }

  &__checkbox {
    position: relative;
    z-index: var(--z-index-9999);
  }

  &__header-with-sort {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-unit);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &-icon {
      display: inherit;

      &--sorted {
        visibility: visible;
      }
    }
  }

  &__sticky-header::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    outline: var(--standard-border);
    background-color: var(--white);
    border-top-left-radius: var(--standard-border-radius);
    border-top-right-radius: var(--standard-border-radius);
  }
}
