.webhook-info-modal {
  width: 50rem;

  &__header {
    background-color: unset;
    border-bottom: none;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x4);

    &-actions {
      display: flex;
      gap: var(--spacing-unit);

      svg {
        fill: var(--text-gray);
      }

      button {
        border-radius: var(--extra-large-border-radius);
      }
    }

    &-name {
      display: flex;
      gap: var(--spacing-unit-x3);
    }
  }

  &__body {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x6) var(--spacing-unit-x6) var(--spacing-unit-x6) !important;

    > div + div {
      margin-top: var(--spacing-unit-x3);
    }
  }

  &__webhook-info {
    border-bottom: var(--standard-border);
    max-width: 100%;
    padding-bottom: var(--spacing-unit-x4);
    word-wrap: break-word;

    > div + div {
      margin-top: var(--spacing-unit);
    }
  }

  &__title {
    align-items: center;
    display: flex;

    & :last-child {
      margin-left: var(--half-spacing-unit);
    }
  }

  &__input-label {
    display: flex;
    align-items: center;
    margin-bottom: 0.8334rem;
  }

  &__password {
    margin-top: var(--spacing-unit-x3);

    &-label {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > :last-child {
        margin-bottom: 0.8334rem;
      }
    }
  }

  &__caution {
    align-items: flex-start;
    margin: unset;
  }
}
