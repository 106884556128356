
.caution {
  display: flex;
  align-items: center;
  border-radius: var(--standard-border-radius);
  margin: 1rem 0;
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  background-color: var(--light-yellow);
  border: var(--caution-border);

  &-top {
    align-items: unset;
  }

  &__icon {
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);

    & ~ div {
      width: calc(100% - var(--spacing-unit-x4));
      padding-left: var(--spacing-unit-x2);
    }
  }

  &__error {
    background-color: var(--status-warning);
  }
}
