.successfully-upgraded {
  margin-top: var(--spacing-unit-x4);

  img {
    width: 20.8334rem;
    height: 15rem;
  }

  &__status,
  &__status-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__status {
    gap: var(--spacing-unit-x2);
    max-width: 50rem;

    button {
      width: 25rem;
      gap: var(--spacing-unit-x2);

      &:first-of-type {
        margin-top: var(--spacing-unit-x2);
      }
    }

    &-container {
      padding-top: var(--spacing-unit-x8);
      padding-bottom: var(--spacing-unit-x10);
    }
  }
}
