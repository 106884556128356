.single-select-dropdown {
  &__wrapper {
    min-width: 16rem;

    &-disabled {
      pointer-events: none;
      color: var(--button-gray);
      opacity: 0.7;

      .svg {
        fill: var(--button-gray);
      }
    }
  }

  &__disabled {
    cursor: not-allowed;
  }

  &__search {
    border: none;
    background-color: var(--white);

    &:hover,
    &:active,
    &:focus {
      border: none;
      background-color: var(--white);
      outline: none;
    }

    &-wrapper {
      box-shadow: var(--box-shadow);
    }

    &__container {
      & > input {
        &:hover,
        &:focus,
        &:active {
          border: var(--input-border-focus);
          outline: var(--input-outline-border);
        }
      }
    }
  }

  &__faux-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: var(--spacing-unit-x6);
    max-height: var(--spacing-unit-x6);
    padding: 0 var(--spacing-unit-x2);
    border: var(--input-border);
    border-radius: var(--standard-border-radius);
    font-size: var(--large-font-size);
    line-height: var(--line-height-title-page);
    background-color: var(--input-background);

    &:hover,
    &:focus {
      background-color: var(--white);
    }

    &:hover {
      border: var(--input-border-hover);
    }

    &:focus-visible {
      outline: none;
      box-shadow: var(--keyboard-focus-box-shadow);
    }

    &:focus {
      border: var(--input-border-focus);
      outline: var(--input-outline-border);
    }

    &:disabled {
      opacity: 0.7;

      &:hover,
      &:focus {
        cursor: default;
        background-color: var(--input-background);
        border: var(--input-border);
      }
    }

    svg {
      align-self: center;
    }

    &--active {
      border: var(--input-border-focus);
      outline: var(--input-outline-border);

      svg {
        transform: rotate(180deg);
      }
    }

    &--error {
      border-color: var(--error);
    }

    &--disabled {
      background-color: var(--off-white-hover);
      border: unset;
    }

    &:invalid {
      color: var(--light-gray);
    }
  }

  &__options {
    padding: var(--spacing-unit-x2) 0;
    margin: 0;
    max-height: 27vh;
    overflow-y: auto;
  }

  &__option {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-selected {
      background-color: var(--lighter-teal);

      .svg {
        fill: var(--teal);
      }
    }

    &:hover {
      background-color: var(--hover-row-background-teal);
      cursor: pointer;
    }
  }

  &__option-div-disabled {
    opacity: 0.4;

    &:hover {
      background-color: var(--white);
      cursor: not-allowed;
    }
  }

  &__option-disabled {
    cursor: not-allowed;
  }

  &__category-options {
    &-header {
      padding-top: var(--spacing-unit);
      padding-bottom: var(--spacing-unit);
      top: calc(var(--spacing-unit-x2) * -1);
    }
  }
}
