
.spacer-block {
  display: block;
  position: relative;
  width: var(--spacing-unit-x7);

  &-border {
    border-left: var(--vertical-table-border);
  }

  &-small {
    display: block;
    position: relative;
    width: var(--spacing-unit-x6);
  }

  &-large {
    display: block;
    position: relative;
    width: var(--spacing-unit-x9);
  }

  &-half {
    display: block;
    position: relative;
    width: var(--spacing-unit-x9);

    &-bottom {
      border-left: var(--vertical-table-border);
      position: absolute;
      top: 0;
      bottom: var(--spacing-unit-x2);
    }

    &-top {
      border-left: var(--vertical-table-border);
      position: absolute;
      top: var(--spacing-unit-x2);
      bottom: 0;
    }
  }
}
