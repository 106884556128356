.composer-form-type-modal {
  min-width: 50rem;

  &__header {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }

  &__body.modal-body {
    padding: var(--spacing-unit-x4);
  }

  &__footer {
    .modal-footer__inner {
      padding: var(--spacing-unit-x4);
    }
  }
}
