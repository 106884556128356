.step-details {
  display: block;

  &__name {
    margin-bottom: var(--spacing-unit-x4);

    .textarea {
      max-width: 100%;
      min-width: 100%;
      min-height: var(--spacing-unit-x16);
    }

    &-input {
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__options {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit-x4);

    &-label {
      gap: var(--spacing-unit);
      padding-bottom: var(--spacing-unit-x2);
      justify-content: space-between;
    }

    &-update {
      display: flex;
      flex-direction: column;
    }

    &-radios {
      margin-top: var(--spacing-unit);

      .radio-with-options > .radio__container {
        margin-top: var(--spacing-unit);
      }
    }
  }

  &__divider {
    height: var(--standard-border-width);
    background-color: var(--border-gray);
    margin: var(--spacing-unit-x2) 0;
  }

  &__cards-group {
    grid-auto-flow: column;

    &-extended {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
