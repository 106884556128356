.list-picker-modal {
  border-radius: var(--standard-border-radius);
  height: 60rem;
  width: 100rem;

  &__selected-list-header {
    &-title {
      font-size: var(--larger-font-size);
      flex: 1 1 100%;
    }

    &-button {
      flex: 0 0 var(--spacing-unit-x28);
      display: flex;
      justify-content: flex-end;
    }
  }

  &__search {
    min-height: var(--spacing-unit-x20);
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__search-input {
    flex: 0 0 19.5rem;
    width: 25rem;
  }

  span&__search-input__container {
    flex: 0;
  }

  &__selected-lists {
    font-size: var(--large-font-size);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 2.3rem;

    svg {
      margin-left: var(--spacing-unit);
    }
  }

  &__total-records {
    font-size: var(--default-font-size);
    color: var(--light-gray);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: var(--spacing-unit-x2);
  }

  &__info-hover {
    display: flex;
    gap: 0.86rem;
    overflow: hidden;

    &-icon {
      padding-top: var(--half-spacing-unit);
    }
  }

  .td&__source {
    padding-right: var(--spacing-unit-x7);

    > div {
      min-width: 0;
    }

    button {
      max-width: 100%;
    }
  }
  
  &__segment-tree {
    align-items: center;
    display: flex;
    height: 100%;
  }

  &__records {
    text-align: right;
  }

  .td&__records {
    padding-right: 2.16rem;
  }

  & .base-tag {
    max-width: 5rem;
  }

  &__tooltip {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit) ;
  }
}
