.import-contacts {
  &__upload-bullet-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(15rem, 1fr));
    grid-column-gap: var(--spacing-unit-x10);
    margin: var(--spacing-unit-x5) var(--spacing-unit-x9) 0;
  }

  &__drop-zone-wrapper {
    padding: var(--spacing-unit-x9) var(--spacing-unit-x9) var(--spacing-unit-x10) var(--spacing-unit-x9);
  }

  &__upload-progress-wrapper {
    padding: var(--spacing-unit-x9) 16.75rem var(--spacing-unit-x8) 16.75rem;
  }

  &__selected-file-wrapper {
    padding: var(--spacing-unit-x23) var(--spacing-unit-x28) var(--spacing-unit-x19);
  }

  &__selected-file-rectangle {
    height: var(--spacing-unit-x17);
    border: var(--standard-border);
    border-color: var(--off-white-focus);
    border-radius: var(--standard-border-radius);
    overflow: hidden;
    position: relative;
  }

  &__selected-file-icon {
    position: absolute;
    fill: var(--off-white-hover);
    left: var(--spacing-unit-x4);
    top: var(--spacing-unit-x3);
    width: 6.75rem;
    height: 8.25rem;
  }

  &__selected-file-details {
    white-space: nowrap;
    margin: var(--spacing-unit-x6) var(--spacing-unit-x19) var(--spacing-unit-x5) var(--spacing-unit-x23);
  }

  &__middle-dot::before {
    content: '\00B7';
    margin: 0 var(--spacing-unit);
  }

  &__selected-file-name {
    display: flex;
  }

  &__remove-file-icon {
    &.svg {
      fill: var(--tab-gray);
      height: 0.83333rem;
      width: 0.83333rem;
    }

    &-wrapper {
      align-self: center;
      margin-left: var(--spacing-unit-x2);
      cursor: pointer;
      height: var(--spacing-unit-x2);

      &:hover {
        background-color: unset;

        & > .svg {
          fill: var(--tab-gray);
        }
      }
    }
  }

  &__file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30rem;
    align-items: center;
  }

  &__file-details {
    margin-top: var(--spacing-unit-x2);
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-top: var(--spacing-unit-x4);
  }

  &__main-container {
    display: flex;
    gap: var(--spacing-unit-x4);
    min-height: calc(100vh - var(--top-bar-height) - var(--spacing-unit-x15));
  }

  &__right-container {
    margin-bottom: var(--spacing-unit-x4);

    &-content {
      width: 21rem;
      position: sticky;
      height: calc(100vh - var(--top-bar-height) - var(--spacing-unit-x19));
    }
  }

  &__header-import-log {
    white-space: nowrap;

    svg {
      margin-right: var(--spacing-unit);
    }
  }

  &__import-status-toast {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 60rem;
  }

  &__import-summary-modal {
    padding-bottom: var(--spacing-unit-x4);
    width: 39rem;
    max-width: unset;
    min-width: unset;
  }

  &__progress-bar {
    padding: 0 var(--spacing-unit-x23) var(--spacing-unit-x6);
  }
}
