
.edit-add-crm-step {
  width: 50rem;
  min-height: 15rem;

  &__not-current {
    margin-top: var(--spacing-unit-x2);
  }

  &__status-row {
    position: relative;
    min-height: 5rem;
  }

  &__new-status {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x2) 0 var(--spacing-unit-x2);

    &-header {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      label {
        flex: 0 0 25%;

        &:first-child {
          flex: 0 0 50%;
        }

        &:nth-child(3) {
          flex: 0 0 15%;
        }
      }
    }

    &-row {
      padding: var(--spacing-unit-x2) 0 0 0;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    &-col {
      flex: 0 0 25%;

      &:first-child {
        flex: 0 0 50%;
      }

      &:nth-child(3) {
        flex: 0 0 15%;
      }
    }

    &-input {
      width: 15rem;
      height: 2rem;
    }

    .button {
      margin-top: var(--spacing-unit-x2);
      padding-left: 0;
    }
  }
}

