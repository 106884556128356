.programs-and-templates-tabbed-container {
  &__upgrade-banner {
    &-body {
      display: flex;
      flex-direction: column;
    }
    
    &-displayed {
      margin-top: 0;
    }

    &-info {
      display: flex;
      gap: var(--half-spacing-unit);
      white-space: nowrap;
    }

    &-link {
      width: fit-content;
    }
  }
}
