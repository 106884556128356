.forms-upgrade-map-fields {
  &__header {
    margin-bottom: var(--spacing-unit-x2);
  }

  .tableV2 {
    margin-top: var(--spacing-unit-x4);
  }
  
  &__error-alert {
    padding-left: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x3);

    .typography {
      white-space: pre-wrap;
    }

    .info-status--wrapper {
      align-items: flex-start;
    }
  }
}
