.revenue-impact-header {
  border-bottom: var(--standard-border);
  margin-bottom: var(--spacing-unit-x4);
  padding-bottom: var(--spacing-unit-x3);

  &__tooltip {
    max-width: 36rem;
  }

  &__info {
    display: flex;
    column-gap: var(--spacing-unit);
    align-items: center;

    &-warning-svg {
      margin-right: var(--spacing-unit);
    }

    &-svg {
      margin-left: var(--spacing-unit);

      > div {
        display: flex;
      }
    }
  }

  &__print-button {
    margin-left: var(--spacing-unit-x2);
  }

  &__row {
    & + & {
      margin-top: var(--spacing-unit-x4);
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
