.media-picker-modal {
  width: 100rem;
  height: 66rem;

  &--hidden {
    opacity: 0;
  }

  .modal-body {
    padding: 0;
  }

  &__sidebar-loader {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__item-name {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);

    &-typography {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      padding-left: var(--spacing-unit);
    }

    .svg {
      flex-shrink: 0;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    scrollbar-gutter: stable;

    & .tableV2 {
      & .tableV2-head__sticky-header::after {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &-tbody__row:last-of-type {
        border-bottom: var(--standard-border);
      }
    }

    & .folder-search {
      .tableV2 {
        overflow: unset;
      }

      &__container {
        padding: 0;
        margin-top: var(--spacing-unit-x4);
        border: none;
      }

      &__title {
        margin-left: var(--spacing-unit-x3);
      }

      &__select-folder {
        margin: var(--spacing-unit-x4) var(--spacing-unit-x3) var(--spacing-unit-x2) var(--spacing-unit-x3);
      }

      & .breadcrumbs-item,
      & .tooltip__trigger {
        max-width: 100%;
      }
    }
  }

  &__folders {
    margin-bottom: var(--spacing-unit);

    & .folder {
      padding-left: var(--spacing-unit-x6);
    }
  }

  &__body {
    display: flex;
    flex-direction: row;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-gutter: stable;
      width: 100%;
    }
  }

  &__header {
    height: 6rem;
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

    &__section {
      display: flex;
      justify-content: space-between;
      gap: var(--spacing-unit-x4);

      &-left-side {
        display: flex;
        gap: var(--spacing-unit-x2);
      }

      .search {
        width: 25rem;
        padding-bottom: 0;
      }
    }

    [data-radix-popper-content-wrapper] {
      z-index: var(--z-index-9999) !important;
    }
  }

  &__view-selected-button {
    display: flex;
    align-items: center;
    gap: calc(var(--spacing-unit-x6) / 5);
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__section {
      display: flex;
      gap: var(--spacing-unit-x4);
      justify-content: space-between;
      align-items: center;
    }
  }

  .modal-footer__inner {
    padding: var(--spacing-unit-x4);
    gap: var(--spacing-unit-x4);
  }

  .button.upload-file__button-upload {
    margin: 0;
    line-height: 1.6667rem;
  }

  &__footer-button-cancel {
    padding: 0.9167rem 0;
  }
}
