.spot-iq {
  &__embed {
    height: 100vh;
  }

  &__iframe {
    width: 116rem;
    height: 90vh;
    padding-right: var(--spacing-unit-x4);
    border: none;
  }
}
