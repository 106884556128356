.asset-picker-search-header {
  &__results,
  &__filters,
  &__results__base-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__results {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    gap: var(--spacing-unit);

    &--no-filters {
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x6);
    }

    &__base-filter {
      gap: calc(var(--third-spacing-unit) * 2);
    }
  }

  &__filters {
    display: flex;
    padding: var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x2);
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
    }

    &__chips {
      display: flex;
      flex-direction: row;
      gap: var(--spacing-unit);
    }
  }
}
