.date-picker {
  height: var(--spacing-unit-x6);

  &__parent-modal {
    z-index: var(--z-index-99999);
  }

  &__panel {
    &-footer {
      display: flex;
      justify-content: flex-end;
      gap: var(--spacing-unit-x2);
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    }

    .ant-picker {
      &:hover {
        border: var(--input-border) !important;
      }

      &-footer {
        border-top: var(--standard-border) !important;
      }

      &-cell {
        padding: calc(var(--spacing-unit) / 3) 0;

        &:not(.ant-picker-cell-disabled) {
          color: var(--text-gray);

          &:hover {
            .ant-picker-cell-inner {
              color: var(--text-teal) !important;
              background: none !important;

              &::before {
                position: absolute;
                top: 0;
                inset-inline-end: 0;
                bottom: 0;
                inset-inline-start: 0;
                z-index: -1;
                border: var(--calendar-date-outline-border) !important;
                border-color: var(--text-teal) !important;
                background: var(--white);
                content: '';
                display: unset;
              }

              &::after {
                content: none;
              }
            }
          }
        }

        &-disabled {
          &::before {
            content: none;
          }
        }

        &::before {
          transition: none;
        }

        &-inner {
          transition: none;
        }
      }

      &-cell-today {
        .ant-picker-cell-inner {
          &::before {
            display: none;
          }
        }
      }

      &-cell-hover {
        &::before {
          left: 50%;
        }
      }

      &-header {
        padding: var(--spacing-unit-x3) 0 var(--spacing-unit) 0;

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: var(--spacing-unit-x6);
          width: var(--spacing-unit-x6);
          color: var(--button-gray);

          &:hover {
            svg {
              fill: var(--text-teal);
            }
          }
        }

        &-view {
          color: var(--text-gray);
          line-height: var(--spacing-unit-x6);
          font-size: var(--larger-font-size);

          button {
            color: var(--text-gray);
            font-size: var(--larger-font-size);
            line-height: var(--spacing-unit-x6);

            &:hover {
              color: var(--text-teal);
              text-decoration: unset;
            }
          }
        }
      }

      &-panel-container {
        font-family: var(--font-regular);
        line-height: var(--line-height-large);
        border: var(--standard-border);
        border-radius: var(--standard-border-radius);
        box-shadow: var(--media-card-box-shadow);
        margin-top: 0.33333rem;
      }

      &-date-panel {
        padding: 0 var(--spacing-unit-x4);
        width: var(--date-picker-panel-width);

        .ant-picker-body {
          display: flex;
          justify-content: center;
          padding: var(--spacing-unit-x2) 0;

          .ant-picker-content {
            border-collapse: separate;
            border-spacing: var(--spacing-unit-x2) 0;
          }

          th {
            font-weight: var(--font-weight-medium);
          }
        }

        .ant-picker-header {
          border-bottom: none;
        }

        .ant-picker-cell {

          /* Date from other months */
          &:not(.ant-picker-cell-in-view) {
            visibility: hidden;

            .ant-picker-cell-inner {
              display: none;
            }
          }

          &-inner {
            height: var(--spacing-unit-x6);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &-in-view {
            width: var(--spacing-unit-x6);
            height: var(--spacing-unit-x6);
            color: var(--text-gray);
          }

          &-disabled {
            color: var(--button-gray);
          }

          &-selected,
          &-today {
            font-weight: var(--font-weight-medium);
          }

          &-today {
            &:hover {
              .ant-picker-cell-inner::before {
                background-color: var(--lighter-teal);
              }
            }

            .ant-picker-cell-inner {
              color: var(--text-teal);
              background-color: var(--lighter-teal);
            }
          }

          /* Date cell borders */
          .ant-picker-cell-inner {
            border-start-start-radius: 50% !important;
            border-end-start-radius: 50% !important;
            border-start-end-radius: 50% !important;
            border-end-end-radius: 50% !important;
          }

          &:not(.ant-picker-cell-disabled) {
            &:hover {
              .ant-picker-cell-inner {
                &::before {
                  border-radius: 50% !important;
                }
              }
            }
          }
        }

        /* WeekDay text color */
        thead {
          th {
            color: var(--light-gray);
          }
        }
      }

      &-month-panel,
      &-year-panel,
      &-decade-panel {
        width: var(--month-year-picker-panel-width);

        .ant-picker-header {
          padding: var(--spacing-unit-x3) var(--spacing-unit-x4) var(--spacing-unit);
        }

        .ant-picker-body {
          padding: 0 var(--spacing-unit-x4);
        }

        .ant-picker-cell {
          &-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            width: min-content;
            height: var(--spacing-unit-x6);
            margin: 0 auto;
            padding: 0 var(--spacing-unit-x3);
            white-space: nowrap;
          }

          &:not(.ant-picker-cell-disabled, .ant-picker-cell-range-start, .ant-picker-cell-range-end) {
            &:hover {
              .ant-picker-cell-inner {
                &::before {
                  border-radius: var(--standard-border-radius) !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-picker {
    width: 100%;
    background-color: var(--white);
    border-color: var(--border-gray);
    border-radius: var(--standard-border-radius);
    padding: 0;

    &:hover {
      border-color: var(--button-gray);
    }

    &-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
      &:hover {
        .ant-picker-cell-inner {
          color: var(--white) !important;
          background: var(--text-teal) !important;

          &::before {
            display: none !important;
          }
        }
      }

      .ant-picker-cell-inner {
        background: var(--text-teal) !important;
        color: var(--white) !important;
        font-weight: 600;
      }
    }

    &-focused {
      border: var(--input-border-focus);
      box-shadow: var(--date-picker-input-shadow);

      &:hover {
        border: var(--input-border-focus);
      }

      .ant-picker-suffix {
        svg {
          fill: var(--text-teal);
        }
      }
    }

    &-input {
      pointer-events: auto;
      cursor: pointer;
      outline: none;

      input {
        color: var(--text-gray);
        line-height: var(--line-height-large);
        font-size: var(--large-font-size);
        min-height: var(--spacing-unit-x6);
        padding: 0 var(--spacing-unit-x2);
        outline: none;

        &:hover {
          cursor: pointer;
        }

        &::placeholder {
          font-family: var(--font-regular);
          color: var(--light-gray);
          font-size: var(--large-font-size);
          font-weight: var(--font-weight-medium-light);
          line-height: var(--line-height-large);
        }
      }

      &::after {
        position: absolute;
        top: calc(-1 * var(--standard-border-width));
        left: calc(-1 * var(--standard-border-width));
        width: calc(100% + 2 * var(--standard-border-width));
        height: calc(100% + 2 * var(--standard-border-width));
        border-radius: var(--standard-border-radius);
        pointer-events: none;
        transition: all 0.3s ease-out;
      }

      &:hover {
        &::after {
          content: '';
        }
      }

      &:not(:first-child) {
        input {
          padding-right: calc(var(--spacing-unit-x4) / 3);
          width: var(--spacing-unit-x25);
        }
      }
    }

    &-clear {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      padding: var(--spacing-unit);
      margin-right: var(--spacing-unit-x5);

      &:hover {
        .ant-picker-clear-container {
          background-color: var(--chip-base);

          svg {
            fill: var(--text-gray);
          }
        }
      }

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--spacing-unit-x4);
        height: var(--spacing-unit-x4);
        padding: var(--spacing-unit);
        border-radius: 50%;

        svg {
          fill: var(--tab-gray);
          transition: all 200ms ease-in-out 0s !important;
        }
      }
    }

    &-suffix {
      margin-inline-start: 0;
      position: relative;
      right: 0;
      padding: 0 var(--spacing-unit-x2);

      svg {
        fill: var(--button-gray);
      }
    }

    &-active-bar {
      display: none;
    }
  }

  &-input {
    height: var(--spacing-unit-x6);

    &-arrow-icon {
      padding: var(--spacing-unit-x2);

      &:hover {
        svg {
          fill: var(--text-gray);
        }
      }

      svg {
        width: 1rem;
        height: 1rem;
        fill: var(--tab-gray);
      }
    }

    &-divider {
      width: 0.083rem;
      margin: var(--spacing-unit) 0;
      background-color: var(--border-gray);
    }

    &-icon-start {
      .ant-picker {
        &-clear {
          margin-right: var(--spacing-unit-x6);
        }

        &-input {
          flex-direction: row-reverse;
        }
      }

      .ant-picker-input input {
        padding: 0;
      }
    }
  }

  &__disable-previous {
    .ant-picker-date-panel,
    .ant-picker-month-panel,
    .ant-picker-year-panel,
    .ant-picker-decade-panel {
      .ant-picker-header-prev-btn {
        opacity: 0.5;
        pointer-events: none;
      }

      .ant-picker-header-super-prev-btn {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &__error {
    & .ant-picker-suffix {
      pointer-events: auto;
    }

    &-icon-container {
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: var(--z-index-999);
    }
  }
}
