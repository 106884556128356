.account-management-permissions-modal {
  width: 50rem;

  &__subheader {
    margin-bottom: var(--spacing-unit-x3);
  }

  &__permission {
    &-description {
      display: flex;
      margin-top: var(--half-spacing-unit);
      align-items: center;
      gap: var(--spacing-unit-x4);
    }

    &-text {
      flex: 1;
    }
  }

  &__submit-button {
    &.button {
      height: var(--spacing-unit-x6);
    }

    &-submitting.button {
      padding-top: 0;
      padding-left: var(--spacing-unit-x6);
      padding-right: var(--spacing-unit-x6);
    }

    &-svg.svg {
      height: var(--spacing-unit-x4);
      width: var(--spacing-unit-x4);
      color: var(--white);
    }

    &-loader {
      margin-bottom: var(--half-spacing-unit);
    }
  }
}
