.navigation {
  flex: 0 0 var(--nav-width-collapsed);
  padding: 0 0 0 0;
  width: var(--nav-width-collapsed);
  height: 100vh;
  border-right: var(--standard-border-width) solid var(--light-theme-border);
  background-color: var(--light-theme-background);
  transition: var(--ease-out-all-180);
  z-index: var(--z-index-single-select-dropdown);

  &--hidden {
    display: none;
  }

  &__logo {
    position: absolute;
    top: 0;
    z-index: var(--z-index-logo);
    display: flex;
    text-decoration: none;
    width: var(--nav-width-collapsed);
    justify-content: flex-start;
    align-items: center;
    height: var(--spacing-unit-x10);
    transition: var(--ease-out-all-180);

    svg {
      transition: margin-left var(--expand-timing) ease-out;
      margin-left: 1.7rem;
      height: auto;
      width: 2.33rem;
    }

    &--custom {
      justify-content: center;
    }

    img {
      max-height: 2.66rem;
      max-width: calc(var(--nav-width-collapsed) - var(--spacing-unit-x4));
    }
  }

  &__main {
    list-style: none;
    padding: 0 0 var(--spacing-unit-x4) 0;
    margin: 0;
    width: var(--nav-width-collapsed);
    overflow: visible;
    transition: var(--ease-out-all-180);
  }

  &__body-mobile,
  &__body {
    position: absolute;
    top: calc(var(--spacing-unit-x10) + var(--half-spacing-unit));
    height: calc(100vh - var(--spacing-unit-x13) - var(--expander-height) + var(--half-spacing-unit) + var(--spacing-unit-x2));
    left: 0;
    width: var(--nav-width-collapsed);
    padding-top: var(--spacing-unit-x2);
    z-index: var(--z-index-body);
  }

  &--powered-by {
    .navigation__body,
    .navigation__body-mobile {
      height: calc(100vh - var(--spacing-unit-x13) - var(--expander-height) + var(--half-spacing-unit) - var(--powered-by-height));
    }
  }

  &__body-mobile {
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: var(--z-index-body-mobile);
    outline: none;

    &:hover {
      overflow-y: auto;
    }

    .navigation__main {
      width: var(--nav-width-collapsed);
      overflow: hidden;
    }
  }

  &__body {
    overflow: visible;

    .navigation__item--root > button {
      span,
      svg {
        visibility: hidden;
      }
    }
  }

  .navigation__mobile-header {
    display: none;
  }

  .navigation__mobile-header-line {
    display: none;
  }

  &__item {
    position: relative;

    &--root {
      width: var(--nav-width-collapsed);

      button {
        background-color: transparent;
      }
    }

    &-after-image {
      margin-left: var(--spacing-unit);
    }
  }

  &__sub {
    &-item {
      button {
        background-color: transparent;
      }
    }
  }

  &__top-section {
    border-top: var(--standard-border);
    margin: var(--spacing-unit) var(--spacing-unit-x2);
  }

  &__expander {
    position: absolute;
    bottom: 0;
    height: var(--expander-height);
    width: var(--nav-width-collapsed);
    border-top: var(--standard-border-width) solid var(--light-theme-border);
    border-right: var(--standard-border-width) solid var(--light-theme-border);
    background-color: var(--light-theme-background);
    z-index: var(--z-index-body);
    transition: var(--ease-out-all-180);
    transition-property: width, background-color;
    font-weight: var(--font-weight-medium);

    label {
      display: flex;
      height: var(--expander-height);
      align-items: center;
      flex-wrap: nowrap;
      justify-content: flex-start;
      font-size: var(--default-font-size);
      white-space: nowrap;
      padding-left: var(--spacing-unit-x4);
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      fill: var(--light-theme-unselected);
      transition: var(--ease-out-all-180);
      transform: rotate(180deg);
    }

    span {
      padding-left: var(--spacing-unit-x2);
      color: var(--light-theme-unselected);
      display: none;
    }

    &:hover {
      background-color: var(--light-theme-background-darker);
      text-decoration: none;

      label {
        text-decoration: none;
      }

      span {
        color: var(--light-theme-selected);
      }

      svg {
        fill: var(--light-theme-selected);
      }
    }
  }

  &__item-name {
    display: flex;
    text-align: left;
    flex-wrap: nowrap;
    font-size: var(--default-font-size);
    width: 100%;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    label {
      display: flex;
      flex: 1 1 auto;
      cursor: pointer;
      color: var(--light-theme-unselected);
      align-items: center;
      padding: calc(var(--spacing-unit) + var(--half-spacing-unit)) 0;
      font-weight: var(--font-weight-medium-light);

      sup {
        padding-left: var(--spacing-unit-x2);
        text-decoration: none;
      }
    }

    &--active {
      & > label {
        font-weight: var(--font-weight-bold);
        background-color: var(--light-theme-selected-item-background);
        border-left: 0.33rem solid var(--teal);
        color: var(--light-theme-selected-item);
      }
    }

    &--root {
      width: calc(var(--nav-width-collapsed) - var(--standard-border-width));
      overflow: hidden;
      background-color: var(--light-theme-background);

      & > label {
        font-size: var(--large-font-size);
        padding: var(--spacing-unit-x3) 0 calc(var(--spacing-unit-x2) + var(--half-spacing-unit)) 0;
        width: calc(var(--nav-width-collapsed) - var(--standard-border-width));
        font-weight: var(--font-weight-medium);

        span:not(.tooltip__trigger) {
          display: none;
          line-height: 1.4rem;
        }
      }

      .tooltip__trigger {
        display: flex;
        flex: 0 0 calc(var(--spacing-unit-x5) + var(--half-spacing-unit));

        label {
          font-size: var(--large-font-size);
          font-weight: var(--font-weight-medium);
        }

        span {
          display: none;
          line-height: 1.4rem;
        }
      }
    }

    &--root-active {
      font-weight: var(--font-weight-bold);

      & > label {
        color: var(--light-theme-selected);
        font-weight: var(--font-weight-bold);
      }
    }

    &--no-icon > label {
      padding-left: 0;
    }

    &--sub > label {
      padding-left: 0;
      display: flex;
      align-items: center;
    }

    &--root:hover {
      > label {
        color: var(--light-theme-selected);
      }

      .tooltip__trigger {
        label {
          color: var(--light-theme-selected);
        }
      }
    }

    &:hover {
      & > label {
        text-decoration: underline;
        color: var(--light-theme-selected);
      }

      svg {
        fill: var(--light-theme-selected);
      }
    }

    &--active:hover {
      text-decoration: none;

      & > label {
        color: var(--light-theme-selected-item);
        text-decoration: none;
      }
    }

    &--root-active:hover {
      text-decoration: none;

      & > label {
        text-decoration: none;
      }
    }

    &--not-active:hover > label {
      color: var(--light-theme-selected);
    }

    &--small-font > label > span {
      font-size: var(--small-font-size);
    }

    &--no-link > label {
      margin: 0 0 0 1.33rem;
      cursor: default;
    }
  }

  &__item-caret {
    &--down {
      transform: rotate(90deg);
    }
  }

  &__group {
    width: 0.01rem;
    height: 0.01rem;
    overflow: hidden;
    list-style: none;
    padding: 0;
    transition: max-height 140ms ease-out;

    .navigation__item-name label {
      padding-left: calc(var(--spacing-unit-x8) + var(--half-spacing-unit));
    }

    .navigation__item-name--sub label {
      padding-left: calc(var(--spacing-unit-x6) + var(--half-spacing-unit) - 0.05rem);
    }

    &--depth-1 {
      left: var(--nav-width-collapsed);
      top: 0;
      position: absolute;
      border-radius: 0 0.33rem 0.33rem 0;
      box-shadow: var(--nav-box-shadow);
      padding: 0;
      background-color: transparent;

      &.navigation__group--visible-hover {
        width: 18rem;
      }

      .navigation__mobile-header {
        display: block;
        font-size: var(--large-font-size);
        font-weight: var(--font-weight-bold);
        color: var(--light-theme-selected);
        padding: var(--spacing-unit-x3) var(--spacing-unit-x6) var(--spacing-unit-x3) calc(var(--spacing-unit-x5) + 0.16rem);
      }

      .navigation__mobile-header-line {
        display: block;
        margin: 0 calc(var(--spacing-unit-x5) + 0.16rem) var(--spacing-unit) calc(var(--spacing-unit-x5) + 0.16rem);
        padding: 0;
        height: var(--standard-border-width);
        background-color: var(--border-gray);
        border: none;
      }

      .navigation__item-name label {
        padding-left: calc(var(--spacing-unit-x5) + 0.16rem);
      }

      .navigation__item-name--sub label {
        padding-left: calc(var(--spacing-unit-x3) + 0.1rem);
      }

      .navigation__sub-item:last-child {
        margin-bottom: var(--spacing-unit-x2);
      }
    }

    &--visible-hover {
      width: auto;
      height: auto;
      overflow: auto;

      &.navigation__group--depth-1 {
        background-color: var(--light-theme-background);
      }
    }

    &--depth-2,
    &--depth-3 {
      max-height: 0;
      width: auto;
      height: auto;

      .navigation__mobile-header {
        display: none;
      }

      .navigation__mobile-header-line {
        display: none;
      }
    }

    &--depth-2 .navigation__item-name label {
      padding-left: var(--spacing-unit-x7);
    }

    &--depth-3 .navigation__item-name label {
      padding-left: var(--spacing-unit-x9);
    }
  }

  &__item-icon-default,
  &__item-icon-selected {
    margin: 0 var(--spacing-unit) 0 1.33rem;
    flex: 0 0 calc(var(--spacing-unit-x5) + var(--half-spacing-unit));
    height: 1.33rem;
  }

  &__item-icon-default {
    fill: var(--light-theme-unselected);
    display: block;

    &--active-click {
      display: none;
    }

    &--active-hover {
      fill: var(--light-theme-selected);
    }
  }

  &__item-icon-selected {
    fill: var(--light-theme-selected);

    &--active-click {
      display: block;
    }
  }

  button:focus > label,
  a:focus > label {
    box-shadow: inset 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
  }

  &--expanding {
    width: var(--nav-width);
    flex: 0 0 var(--nav-width);

    .navigation__main {
      width: var(--nav-width);
    }

    .navigation__logo {
      width: var(--nav-width);

      svg {
        margin-left: 4.7rem;
      }
    }

    .navigation__group {
      display: none;
    }

    .navigation__expander {
      width: var(--nav-width);
      overflow: hidden;

      svg {
        transform: rotate(0deg);
      }
    }
  }

  &--disabled {
    .navigation__item-name:hover svg {
      fill: var(--gray);
    }

    a:hover,
    .navigation__item-name:hover > label {
      text-decoration: none;
      color: var(--gray);
    }

    .navigation__item-name--active:hover > label {
      color: var(--light-theme-selected-item);
    }
  }

  .navigation__group--depth-1 .navigation__item-name--active label {
    padding-left: 2.3333rem;
  }

  .navigation__group--depth-2 .navigation__item-name--active label {
    padding-left: 3.25rem;
  }

  /** EXPANDED NAVIGATION **/
  &--expanded {
    flex: 0 0 var(--nav-width);
    width: var(--nav-width);
    overflow: hidden;

    .navigation__main {
      width: var(--nav-width);
    }

    .navigation__logo {
      width: var(--nav-width);

      svg {
        margin-left: 4.75rem;
        width: 7.41rem;
      }

      img {
        max-height: 2.66rem;
        max-width: calc(var(--nav-width) - var(--spacing-unit-x4));
      }
    }

    .navigation__mobile-header {
      display: none;
    }

    .navigation__mobile-header-line {
      display: none;
    }

    .navigation__body {
      overflow-x: hidden;
      overflow-y: hidden;
      width: var(--nav-width);

      &:hover {
        overflow-y: auto;
      }
    }

    .navigation__item--root {
      width: var(--nav-width);
    }

    .navigation__item-name {
      width: var(--nav-width);

      &--no-icon > label {
        padding-left: calc(var(--spacing-unit-x4) + var(--half-spacing-unit));
        padding-right: var(--spacing-unit-x3);
        padding-bottom: var(--spacing-unit);
        line-height: 1.4rem;
      }

      &--root {
        width: var(--nav-width);

        svg,
        span {
          visibility: visible;
        }

        & > label {
          width: var(--nav-width);
          display: flex;
          align-items: flex-start;

          span:not(.tooltip__trigger) {
            visibility: visible;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: var(--spacing-unit-x3);
          }

          svg {
            visibility: visible;
          }
        }

        .tooltip__trigger {
          label {
            width: var(--nav-width);
          }

          span {
            visibility: visible;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: var(--spacing-unit-x3);
          }

          svg {
            visibility: visible;
          }
        }

        &:hover {
          text-decoration: none;

          label {
            text-decoration: none;
          }

          svg {
            fill: var(--text-gray);
          }
        }
      }
    }

    .navigation__group {
      overflow: hidden;
      width: auto;
      height: auto;
      max-height: 0;

      &--depth-1 {
        position: relative;
        top: auto;
        left: 0;
        width: auto;
        box-shadow: none;
        padding: 0;

        .navigation__sub-item:last-child {
          margin-bottom: 0;
        }
      }

      &--depth-1,
      &--depth-2,
      &--depth-3 {
        border-radius: 0;
      }

      .navigation__item-name label {
        padding-left: 4.75rem;
      }

      .navigation__item-name--active label {
        padding-left: 4.3333333rem;
        margin-right: 0.0833333rem;
      }

      .navigation__item-name--sub label {
        padding-left: 3.7rem;
      }

      &--depth-2 .navigation__item-name label {
        padding-left: calc(var(--spacing-unit-x10) + var(--half-spacing-unit));
      }

      &--depth-2 .navigation__item-name--active label {
        padding-left: 5rem;
      }

      &--depth-3 .navigation__item-name label {
        padding-left: calc(var(--spacing-unit-x12) + var(--half-spacing-unit));
      }
    }

    .navigation__item-icon-default {
      display: block;

      &--active {
        display: none;
      }
    }

    .navigation__item-icon-selected {
      display: none;

      &--active {
        display: block;
      }
    }

    .navigation__expander {
      width: var(--nav-width);

      span {
        display: block;
      }

      svg {
        transform: rotate(0deg);
      }
    }
  }

  &--disabled&--expanded {
    .navigation__body:hover {
      overflow-y: hidden;
    }

    .navigation__item-name--root:hover svg {
      fill: var(--light-theme-unselected);
    }

    .navigation__item-name--root-active > label {
      color: var(--light-theme-selected);
    }

    .navigation__item-icon-selected {
      fill: var(--light-theme-selected) !important;
    }
  }

  &.navigation--collapsing {
    flex: 0 0 var(--nav-width-collapsed);
    overflow: hidden;

    .navigation__main {
      overflow: hidden;
      width: var(--nav-width-collapsed);
    }

    .navigation__logo {
      width: var(--nav-width-collapsed);

      svg {
        margin-left: 1.7rem;
        width: 2.7rem;
      }
    }

    .navigation__expander {
      width: var(--nav-width-collapsed);
      overflow: hidden;

      span {
        display: none;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
