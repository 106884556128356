.landing-page-composer-custom-code {
  display: flex;
  height: calc(100vh - 11.75rem);
  overflow: hidden;

  &__container {
    flex: 1;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--spacing-unit) var(--spacing-unit-x4);
      background-color: var(--white);
      border-bottom: var(--standard-border);

      &-spacer {
        width: 11.25rem;
      }
    }
  }

  .email-composer-preview-content {
    height: calc(100% - 4.333rem);
  }
}
