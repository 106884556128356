.test-webhook-response {
  &__tabs-container {
    margin-top: var(--spacing-unit-x4);
    border-top: 0;
  }

  &__request {
    height: var(--spacing-unit-x18);
    padding: var(--spacing-unit-x2);
  }
}
