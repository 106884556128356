
.timespan-picker {
  display: flex;
  width: 17rem;
  justify-content: space-between;

  .svg {
    height: 1.33rem;
    fill: var(--text-gray);
  }

  .button {
    &:disabled {
      svg {
        fill: var(--lighter-gray);
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__date__range {
    padding: 0 0.5rem;
    min-width: 6.5rem;
    text-align: center;
  }

  &__wrapper_alt {
    display: flex;
    align-items: center;
    width: 7rem;
  }

  & select {
    margin-right: var(--spacing-unit-x4);
  }
}
