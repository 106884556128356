.activity-card {
  align-items: center;
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x3);

  &:hover {
    background: var(--hover-row-background-teal);
  }

  &__information {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);

    &-text {
      display: flex;
      flex-direction: column;
      max-width: 37rem;
      gap: var(--half-spacing-unit);
      
      &-webinar {
        max-width: 27rem;
      }

      &-form {
        max-width: 25rem;
      }
    }
  }

  &__preview {
    align-items: center;
    border-radius: var(--standard-border-radius);
    display: flex;
    height: var(--spacing-unit-x6);
    justify-content: center;
    width: var(--spacing-unit-x6);

    > svg {
      fill: var(--text-gray);
    }

    &:hover {
      background: var(--hover-row-button-background-teal) !important;
    }
  }
}
