.nested-table-row {
  height: var(--actionable-nested-table-row-height);
  padding: 0 var(--spacing-unit) 0 var(--spacing-unit-x4);
  position: relative;

  &__cell {
    margin-right: var(--spacing-unit-x4);

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__caret {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > .svg {
      flex-shrink: 0;
      width: 0.58333rem;
      height: 0.58333rem;
    }
  }

  &__expander {
    display: flex;
    flex-direction: row;
    gap: 0.9rem;
    height: 100%;

    &-border {
      border-left: var(--standard-border);
      margin: 0 0.2rem;
      padding-top: var(--spacing-unit-x2);
    }
  }

  &__hidden-tag {
    position: absolute;
    visibility: hidden;
  }

  &__tag-manager-trigger-with-text {
    visibility: hidden;
  }

  &__tags {
    align-items: center;
    flex: 1 0 auto;
    display: flex;
    gap: var(--spacing-unit);
    width: 100%;

    & .tag-manager__drop-down-open .tag-manager-trigger,
    & .tag-viewer__drop-down-open .tag-manager-trigger {
      background-color: var(--teal);
      border: calc(var(--half-spacing-unit) / 3) solid var(--hover-teal);
      cursor: pointer;

      & .tag-manager-trigger__icon {
        fill: var(--white);
      }

      & .tag-manager-trigger-with-number__number,
      & .tag-manager-trigger-with-text__text {
        color: var(--white);
      }
    }
  }

  &:hover {
    background-color: var(--hover-row-background-teal);

    & .nested-table-row__tag-manager-trigger-with-text {
      visibility: visible;
    }

    & .info-hover-card {
      visibility: visible;

      & svg {
        height: 1.33rem;
        width: 1.33rem;
        fill: var(--teal);
      }
    }
  }

  &:hover .nested-table-row-actions {
    border-top: none;
    display: inline-flex;

    & > div > button:hover,
    & .drop-down__overflow-button:hover {
      background-color: var(--hover-row-button-background-teal);
    }
  }

  &-hover {
    z-index: var(--z-index-999);
    background-color: var(--hover-row-background-teal);

    & .nested-table-row__tag-manager-trigger-with-text {
      visibility: visible !important;
    }
  }

  &__text {
    align-items: center;
    justify-content: left;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0.958rem 0 0.875rem 0;

    &-action:hover {
      cursor: pointer;
    }
  }

  &__cell-text {
    display: flex;
    width: 100%;
  }

  &-expanded {
    border-top: var(--standard-border);

    &:first-child {
      border-top: none;
    }
  }

  &-vertical-alignment {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  }
}
