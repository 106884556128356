
.text-bubble {
  margin: 0 1.25rem 0.5rem 0;
  word-break: break-word;
  white-space: pre-wrap;
  padding: 1rem calc(14rem / 12) 1rem 1rem;
  background-color: var(--sms-text-gray);
  border-radius: var(--standard-border-radius);
  border: none;

  &__background-teal {
    background-color: var(--highlight-teal);
  }

  &__no-margin {
    margin: 0;
  }

  &__rounded-radius__wrapper,
  &__rounded-radius {
    border-radius: var(--sms-message-border-radius);
  }

  &__radius-left {
    border-bottom-left-radius: 0;
  }

  &__radius-right {
    border-bottom-right-radius: 0;
  }

  &__smaller-margin {
    margin-bottom: var(--spacing-unit);
  }
}
