.dots {
  &__wrapper {
    width: 100%;
    height: 100%;
    padding: var(--spacing-unit-x4);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  &__svg {
    &--xsmall {
      max-width: var(--spacing-unit-x2);
      max-height: var(--spacing-unit-x2);
      min-width: var(--spacing-unit-x2);
      min-height: var(--spacing-unit-x2);

      circle {
        r: 2;
      }
    }

    &--small {
      max-width: var(--spacing-unit-x3);
      max-height: var(--spacing-unit-x3);
      min-width: var(--spacing-unit-x3);
      min-height: var(--spacing-unit-x3);

      circle {
        r: 2;
      }
    }

    &--medium {
      max-width: var(--spacing-unit-x4);
      max-height: var(--spacing-unit-x4);
      min-width: var(--spacing-unit-x4);
      min-height: var(--spacing-unit-x4);
    }

    &--large {
      max-width: var(--spacing-unit-x8);
      max-height: var(--spacing-unit-x8);
      min-width: var(--spacing-unit-x8);
      min-height: var(--spacing-unit-x8);
    }

    &--xlarge {
      max-width: var(--spacing-unit-x12);
      max-height: var(--spacing-unit-x12);
      min-width: var(--spacing-unit-x12);
      min-height: var(--spacing-unit-x12);
    }

    circle {
      fill: var(--teal);
      stroke: none;
    }
  }
}
