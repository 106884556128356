.contacts-details {
  display: block;

  &__header-shadow .table .thead {
    box-shadow: var(--locked-columns-box-shadow);
  }

  .tableV2__wrapper {
    max-height: 60.5vh;
  }

  &__top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-unit-x2);

    &--label {
      margin-right: var(--spacing-unit);
    }
  }

  &__table {
    border-radius: var(--standard-border-radius);
    display: flex;
    flex-direction: column;
    min-width: unset !important;
    position: relative;

    &__table-holder {
      border-radius: var(--standard-border-radius);
      max-width: unset;
      height: 60.5vh;
    }
  }

  .table {
    overflow: auto;

    .tbody {
      min-width: fit-content;

      .tr:last-child {
        border-bottom: var(--standard-border);
      }
    }

    .thead {
      background-color: var(--white);
      min-width: fit-content;
      padding-right: 0;
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 4;

      & .table-action-header-sticky {
        &__checkbox {
          padding-left: var(--spacing-unit-x3) !important;
        }

        &__count {
          margin-left: -6.25rem;
          padding-left: 6.25rem !important;
        }

        &__actions {
          margin-left: -17.25rem;
          padding-left: 17.25rem !important;
        }
      }
    }

    .td {
      &:nth-child(1) {
        margin-left: 0;
      }

      &:last-child {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .tr {
      max-width: unset !important;
      min-width: fit-content !important;

      &:hover [data-sticky-td]:not([role='columnheader']) {
        background-color: var(--hover-row-background-teal);
      }

      & .th,
      .td {
        padding-left: 0;
      }

      & .table__ellip {
        display: unset;
      }

      & .table__use-checkbox {
        padding-left: var(--spacing-unit-x2);
      }
    }

    [data-sticky-last-left-td] {
      & + .th {
        padding-left: var(--spacing-unit-x4);
      }

      & + .td {
        padding-left: var(--spacing-unit-x4);
      }
    }
  }

  .table__paginator,
  .paginator {
    max-width: unset;
  }

  .input-with-clear,
  .search--large__container {
    width: 34rem;
  }

  .table-row-action {
    padding-right: var(--spacing-unit-x4);
  }

  .drop-down > button:hover {
    text-decoration: none;
  }

  &__last-column {
    max-width: unset !important;
    width: unset !important;
  }

  &__cell-search {
    > div {
      display: inline-block;
      overflow: hidden;
      width: 100%;
    }
  }

  .tableV2__loader-wrapper {
    height: calc(100vh - calc(var(--spacing-unit-x20) + var(--spacing-unit-x10)));
  }
}
