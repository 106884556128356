.personalization-check-errors {
  height: 100%;
  overflow: auto;
  display: flex;
  background-color: var(--white);
  border-radius: var(--standard-border-radius);
  border: var(--standard-border);

  &__sidebar {
    display: flex;
    flex-direction: column;
    border-right: var(--standard-border);
    min-width: calc(var(--spacing-unit-x16) * 4);

    &-title {
      padding: calc(var(--spacing-unit-x2) + var(--half-spacing-unit)) var(--spacing-unit-x4);
      border-bottom: var(--standard-border);
    }

    &-filters {
      flex: max-content;
      overflow: auto;
    }

    &__filter {
      display: grid;
      grid-template-areas: 'title score' 'type score';
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x2) var(--spacing-unit-x6);
      border-bottom: var(--standard-border);
      text-align: left;

      &:hover {
        text-decoration: none;
        background-color: var(--selectable-row-hover);
      }

      &-selected {
        background-color: var(--lighter-teal);
        position: relative;

        &::before {
          position: absolute;
          content: '';
          border: var(--text-teal-border);
          inset: 0 auto 0 0;
        }
      }

      &-title {
        grid-area: title;
      }

      &-type {
        grid-area: type;
      }

      &-count {
        grid-area: score;
        margin-left: var(--spacing-unit-x3);
      }
    }
  }

  &__details {
    width: 100%;
    overflow-y: auto;
    padding: var(--spacing-unit-x4) var(--spacing-unit-x6) var(--spacing-unit-x6);
  }
}
