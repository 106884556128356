.key-value-picker {
  width: 100%;
  max-width: 40rem;

  &__header,
  &__row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-bottom: var(--spacing-unit-x2);
    gap: var(--spacing-unit-x2);

    &--has-validation {
      padding-bottom: var(--spacing-unit);
    }

    &__cell {
      flex: 1 1 50%;

      &:last-child {
        flex: 0 0 var(--spacing-unit-x8);

        .add-button__nolabel {
          padding: 0;
        }
      }
    }
  }

  &__validation {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-unit);
    align-items: center;
  }

  &__header {
    padding-bottom: 0;
  }
}
