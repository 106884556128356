.segment-composer-build {
  min-width: 87rem;
  padding: var(--spacing-unit-x4);
  position: absolute;
  width: 100%;
  
  &__breadcrumbs {
    height: 2.667rem;

    &-container {
      width: 100%;
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
      background-color: var(--selectable-row-hover);
      padding: var(--half-spacing-unit) var(--spacing-unit-x2);
      margin-bottom: var(--spacing-unit-x4);
    }
  }
}
