.radio-with-options {
  :first-child > &__radio {
    margin: 0;
  }

  :not(:first-child) > &__radio {
    margin: var(--spacing-unit-x2) 0 0 0;
  }

  &__options-container {
    margin-top: var(--spacing-unit-x2);
  }
}
