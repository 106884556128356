.marketing-users {
  &__sub-header-container {
    height: var(--spacing-unit-x6);
    margin-bottom: var(--spacing-unit-x4);
  }

  .info-hover-card {
    margin-left: var(--spacing-unit);
    margin-top: var(--spacing-unit);
    visibility: hidden;
  }

  &__info-card-permissions-icon {
    width: var(--spacing-unit-x2);
    height: var(--spacing-unit-x2);
  }

  &__info-hover-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-icon {
      display: flex;
      align-items: center;

      .svg {
        margin-right: var(--spacing-unit);
      }
    }
  }

  &__info {
    &-list {
      display: flex;
      align-items: flex-start;

      & + & {
        margin-top: var(--spacing-unit-x3);
      }

      &__title {
        width: var(--spacing-unit-x12);
        text-transform: uppercase;
        color: var(--gray);
        padding-top: 0.16rem;
      }

      &__value {
        text-wrap: wrap;
        width: calc(100% - var(--spacing-unit-x12));
        color: var(--gray);
      }
    }
  }

  &__info-hover-body {
    & + & {
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__table-info-bar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-unit-x2);

    &__hidden {
      visibility: hidden;
    }

    .users__count-info__tooltip__trigger-wrapper {
      margin-left: auto;
      margin-right: var(--spacing-unit-x2);
    }

    &__search {
      min-width: calc(var(--spacing-unit-x2) * 25);
    }
  }

  &__table {
    .tr:hover {
      .info-hover-card {
        visibility: visible;
      }
    }
  }
}
