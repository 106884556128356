.tableV2-row-drop-down-actions {
  align-items: center;
  display: inline-flex;

  &__open > button {
    background-color: var(--hover-row-button-background-teal);
  }

  .drop-down {
    & > button {
      align-items: center;
      border: var(--standard-border-width) solid transparent;
      border-radius: var(--standard-border-radius);
      display: flex;
      height: var(--spacing-unit-x6);
      justify-content: center;
      transition: var(--linear-80);
      width: var(--spacing-unit-x6);

      &:hover {
        background-color: var(--hover-row-button-background-teal);
      }
    }
  }
}
