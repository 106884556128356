.select {
  cursor: pointer;
  margin: 0;
  width: 100%;
  border-radius: 0.25rem;
  font-size: var(--large-font-size);
  padding: 0 var(--spacing-unit-x5) 0 var(--spacing-unit-x2);
  line-height: var(--line-height-medium);
  box-sizing: border-box;
  appearance: none;
  background-color: var(--input-background);
  background-repeat: no-repeat;
  background-position: calc(100% - var(--spacing-unit-x2)) center;
  background-size: 0.75rem 0.46rem;
  color: var(--text-gray);
  border: var(--input-border);
  max-height: var(--spacing-unit-x6);
  height: var(--spacing-unit-x6);
  outline: none;
  transition: var(--linear-80);

  &::-ms-expand {
    display: none;
  }

  &:hover,
  &:focus {
    background-color: var(--white);
  }

  &:hover {
    border: var(--input-border-hover);
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
  }

  &:focus {
    border: var(--input-border-focus);
    outline: var(--input-outline-border);
  }

  &:disabled {
    opacity: 0.7;

    &:hover,
    &:focus {
      cursor: default;
      background-color: var(--input-background);
      border: var(--input-border);
    }
  }

  &:invalid {
    color: var(--light-gray);
  }

  &__small {
    height: var(--spacing-unit-x4);
    font-size: var(--medium-font-size);
    line-height: var(--line-height-larger);
    padding-left: calc(var(--spacing-unit) + var(--half-spacing-unit));
  }
}
