.program-details {
  display: inline-flex;
  width: 100%;

  &__main-content {
    flex: 1;
  }

  &__schedule {
    padding-bottom: var(--spacing-unit-x2);

    &-container {
      > div:first-child {
        margin-bottom: var(--spacing-unit-x4);
      }
    }
  }
}
