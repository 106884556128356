.segment-details {
  &__download-modal {
    .modal-body {
      padding: var(--spacing-unit-x4);
    }

    .modal-footer {
      &__inner {
        gap: var(--spacing-unit-x4);
        padding: var(--spacing-unit-x4);
      }
    }
  }

  &__send-message-button {
    &-actions {
      width: 14.167rem;
    }

    &-triggered {
      background-color: var(--hover-teal);
    }
  }

  &__header {
    gap: var(--spacing-unit-x2);
  }

  .pane-container {
    &__inner {
      padding: 0 var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x4);
    }
  }

  &__dropdown-link {
    display: inline-flex;

    div {
      margin-left: var(--spacing-unit-x2);
    }

    svg {
      fill: var(--button-gray);
    }

    &:hover {
      svg {
        fill: var(--text-gray);
      }
    }

    &-disabled {
      pointer-events: none;
      text-decoration: none;
      color: var(--gray);
    }
  }

  &__drop-down {
    white-space: nowrap;

    &-open {
      background-color: var(--off-white-hover);
    }
  }
}

/* stylelint-disable */
@media (max-width: 1485px) {
  /* stylelint-enable */
  .segment-details {
    padding: var(--spacing-unit-x4);
  }
}
