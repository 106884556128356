.filter-type-select {
  position: relative;
  z-index: var(--z-index-99999);

  &__select {
    width: 100%;
    padding: var(--spacing-unit-x3);
    padding-left: var(--spacing-unit-x4);
    background-color: var(--white);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);

    &:hover {
      background-color: var(--lighter-off-white);
      text-decoration: none;
    }

    > :first-child {
      svg {
        fill: var(--text-teal);
        color: var(--text-teal);
      }
    }

    &-expanded &-caret {
      transform: rotate(180deg);
    }
  }

  &__menu {
    background-color: var(--white);
    margin-top: var(--spacing-unit);
    border-radius: var(--standard-border-radius);
    box-shadow: var(--recipients-select-box-shadow);
    height: 30rem;

    &-option {
      padding: var(--spacing-unit) var(--spacing-unit-x2);

      &-icon.svg {
        height: var(--spacing-unit-x4);
        width: var(--spacing-unit-x4);
        fill: var(--button-gray);
        color: var(--button-gray);
        margin-right: var(--spacing-unit-x4);
      }

      &:hover,
      &-selected {
        svg {
          fill: var(--text-teal);
          color: var(--text-teal);
        }
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
    }

    > :first-child {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x4);
    }
  }
}
