.fav-icon__expanded {
  height: fit-content;
  width: fit-content;
  position: relative;

  &:hover &-hover {
    display: block;
  }

  &-holder {
    min-height: calc(2 * var(--spacing-unit-x22));
    min-width: calc(2 * var(--spacing-unit-x22));
    border-radius: var(--large-border-radius);
    border: var(--dashed-border);
    display: flex;
    justify-content: center;
    align-items: center;

    &-title {
      margin-top: var(--spacing-unit-x3);
    }

    &-description {
      margin-top: calc(var(--spacing-unit-x3) / 2);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      /* stylelint-disable */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &-default,
    &-invalid {
      flex-direction: column;
      text-align: center;
      width: calc(2 * var(--spacing-unit-x22));
      padding: var(--spacing-unit-x4);
    }

    &-default {
      &-svg {
        fill: var(--button-gray);
      }

      &-set-button {
        margin-top: var(--spacing-unit-x4);
      }

      background-color: var(--input-background);
    }

    &-invalid {
      background-color: var(--status-warning);
      border-color: var(--remove-red);

      &-set-button {
        margin-top: var(--spacing-unit-x3);
      }
    }
  }

  &-image {
    max-width: calc(2 * var(--spacing-unit-x22));
    max-height: calc(2 * var(--spacing-unit-x22));
    padding: var(--spacing-unit-x2);
  }

  &-hover {
    position: absolute;
    border-radius: var(--large-border-radius);
    display: none;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--media-card-background-hover);

    &-content {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-change-button {
      color: var(--white);
      margin: 0 auto;

      &:hover {
        color: unset;
        background-color: var(--white);
      }
    }

    &-remove-button {
      margin: var(--spacing-unit-x2) auto;
      padding: var(--spacing-unit-x2) 3.25rem;
    }
  }
}
