
.edit-send-step {
  &__radio-row {
    display: flex;
    align-items: flex-end;

    .radiogroup,
    .edit-send-step__label {
      flex: 1 1 100%;
    }
  }

  &__move-button {
    margin-right: var(--spacing-unit-x3);
    display: flex;
    height: 2.2rem;
    align-items: center;

    svg {
      fill: var(--light-gray);
      width: 1rem;
      height: 1rem;
      margin-right: var(--half-spacing-unit);
    }
  }

  &__threshold {
    font-size: var(--default-font-size);
    color: var(--light-gray);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: var(--spacing-unit);

    .input {
      height: 2rem;
      width: 4rem;
      margin: 0 var(--spacing-unit);
    }
  }

  &__test-row {
    display: flex;
    align-items: center;
    padding-top: var(--spacing-unit-x2);
    width: 35rem;

    label {
      flex: 0 0 5rem;
    }

    button {
      flex: 0 0 2rem;
    }
  }

  &__delete-icon {
    text-align: center;

    svg {
      fill: var(--error);
      width: 1rem;
      height: 1rem;
    }
  }
}
