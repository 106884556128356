.media-card-actions {
  display: flex;
  flex-direction: row;

  &__dropdown {
    .drop-down__overflow-button[data-state='open'] {
      background-color: var(--darker-blue);
    }
  }
}
