.data-cards-modal {
  width: 94rem;

  &__drop-down {
    background: var(--light-theme-background);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    margin-right: var(--spacing-unit-x8);
   
    .drop-down {
      margin: var(--spacing-unit-x2);
    }
  }

  &__header-text {
    padding-top: var(--spacing-unit-x2);
    padding-bottom: var(--spacing-unit-x6);
  }

  &__text {
    hyphens: auto;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
  }

  &__container {
    display: inline-flex;
  }

  &__logo {
    height: var(--spacing-unit-x10);
    width: var(--spacing-unit-x10);
    object-fit: cover;
    flex-shrink: 0;
  }

  &__text-footer {
    padding-left: var(--spacing-unit-x4);
    align-self: flex-start;
    align-content: flex-start;
    display: inline;
  }
}
