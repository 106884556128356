.segment-composer-settings {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-unit-x4);
  padding: var(--spacing-unit-x4);
  justify-content: center;

  .container__wrapper + .container__wrapper {
    margin-top: 0;
  }

  &__segment-description {
    width: 100% !important;
    min-height: var(--spacing-unit-x8);
  }

  /* stylelint-disable */
  @media (max-width: 1100px) {
    /* stylelint-enable */
    flex-direction: column;
  }
  
  &__left {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
    flex-grow: 1;
    max-width: 74rem;

    /* stylelint-disable */
    @media (max-width: 1298px) {
      /* stylelint-enable */
      flex-grow: 1;
      max-width: unset;
    }

    .container__wrapper {
      min-width: 63rem;

      .selectv2__container {
        min-width: 40rem;
      }

      .container {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-unit-x4);
        width: 100%;
      }

      /* stylelint-disable */
      @media (max-width: 1298px) {
        /* stylelint-enable */
        min-width: 40rem;
        width: unset;
      }

      /* stylelint-disable */
      @media (max-width: 1100px) {
        /* stylelint-enable */
        min-width: 70rem;
      }

    }
  }

  &__segment-details {
    width: 40rem;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    padding-top: var(--spacing-unit-x4);
    flex-shrink: 0;
    flex-grow: 0;

    /* stylelint-disable */
    @media (max-width: 1298px) {
      /* stylelint-enable */
      width: 32rem;
    }

    /* stylelint-disable */
    @media (max-width: 1100px) {
      /* stylelint-enable */
      min-width: 70rem;
      width: 100%;
    }

    .drawer-pane-group {
      width: 100%;
    }

    .selectv2__container {
      max-width: 58.333rem;
    }

    &-breadcrumbs {
      padding-top: 0.75rem;
      row-gap: var(--spacing-unit);
      column-gap: var(--spacing-unit-x2);
      flex-wrap: wrap;
    }
  }

  .radio-card {
    width: unset;
    
    &-group {
      display: flex;
      gap: var(--spacing-unit-x2);
    }
  }
}
