.image-picker-modal-content {
  &__container {
    grid-area: container;

    .tableV2-tbody__row-disabled {
      &:hover {
        background-color: unset;

        .tableV2-tbody__row-actions {
          display: none;
        }
      }

      .typography {
        color: unset;
      }

      img {
        opacity: 50%;
      }
    }
  }

  &__empty-listing {
    width: 100%;
  }

  &__search-results {
    padding: 0 var(--spacing-unit-x4);
    border-bottom: var(--standard-border);
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    grid-area: filter;
  }

  &__grid {
    grid-gap: var(--spacing-unit-x4);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 27.58rem), 1fr));
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x4);
    justify-content: center;

    &-less-3-items {
      /* stylelint-disable */
      @media (min-width: 1116px) {
        /* stylelint-enable */
        grid-template-columns: repeat(2, 1fr); /* Take 1/2 of container */
      }

      /* stylelint-disable */
      @media (min-width: 1485px) {
        /* stylelint-enable */
        grid-template-columns: repeat(3, 1fr); /* Take 1/3 of container */
      }
    }

    .image-media-card {
      max-width: 100%;

      img {
        aspect-ratio: 21 / 9;
        object-fit: cover;
      }

      &__trigger {
        width: 100%;
      }
    }

    &__spinner {
      height: auto;
    }
  }

  &__cell-image {
    max-height: inherit;
    max-width: inherit;

    &__loader {
      position: absolute;
      width: var(--spacing-unit-x8);
      height: var(--spacing-unit-x8);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-wrapper {
      position: relative;
      min-width: var(--spacing-unit-x8);
      max-width: var(--spacing-unit-x8);
      min-height: var(--spacing-unit-x8);
      max-height: var(--spacing-unit-x8);
      border-radius: var(--standard-border-radius);
      border: var(--standard-border);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      &-error {
        background-color: var(--page-back);
      }
    }

    &-pill {
      margin: 0 0 0 var(--spacing-unit);
    }
  }
}
