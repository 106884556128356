.editable-container { 
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  
    &-edit-button {
      height: var(--spacing-unit-x5);
    }
     
    &-full-width {
      display: block;
      position: relative;
  
      > :last-child {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
   
  &__label {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
    height: var(--spacing-unit-x5);
   
    svg {
      fill: var(--light-gray);
    }
  }

  &__bottom {
    display: flex;
    gap: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit-x3);
  
    button {
      height: var(--spacing-unit-x6);
    }
  }
}
