.button {
  padding: 1rem var(--spacing-unit-x3);
  font-size: var(--large-font-size);
  border-radius: var(--standard-border-radius);
  font-weight: var(--font-weight-medium);
  outline: none;
  cursor: pointer;
  transition: var(--linear-80);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: var(--line-height-smallish);

  &:hover,
  &.button--hover {
    text-decoration: none;
  }

  svg {
    width: var(--spacing-unit-x2);
    height: var(--spacing-unit-x2);
    fill: var(--light-gray);
  }

  &--primary {
    color: var(--white);
    background: var(--teal);
    padding: var(--spacing-unit) var(--spacing-unit-x4);
    line-height: var(--spacing-unit-x4);
    text-shadow: var(--primary-button-text-shadow);
    white-space: nowrap;

    &:hover,
    &.button--hover {
      color: var(--white);
      background: var(--hover-teal);
      text-decoration: none;
    }

    &:focus-visible,
    &:active {
      color: var(--white);
      background: var(--focus-teal);
      text-decoration: none;
    }

    &-teal-medium {
      background: var(--text-teal);
      text-shadow: unset;

      &:hover,
      &:focus-visible,
      &:active,
      &.button--hover {
        background: var(--teal-medium);
      }
    }
  }

  &--dark-blue {
    height: var(--spacing-unit-x6);
    color: var(--white);
    padding: var(--spacing-unit) var(--spacing-unit-x4);
    line-height: var(--spacing-unit-x4);
    white-space: nowrap;
    background-color: var(--dark-blue);
    text-shadow: unset;

    &:disabled {
      background: var(--dark-blue-disabled);
      text-shadow: none;
      pointer-events: none;
    }

    &:hover:not(:disabled),
    &:focus-visible,
    &:active,
    &.button--hover {
      background: var(--dark-blue);
    }
  }

  &--delete,
  &--cancel-send {
    color: var(--white);
    background: var(--remove-red);

    &:hover,
    &.button--hover {
      color: var(--white);
      background: var(--high-red);
      text-decoration: none;
    }

    &:focus {
      color: var(--white);
      background: var(--remove-red);
      text-decoration: none;
    }
  }

  &--erase {
    color: var(--white);
    background: var(--remove-red);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    line-height: 1.166rem;

    &:hover,
    &.button--hover {
      color: var(--white);
      background: var(--erase-hover-red);
      text-decoration: none;
    }

    &:focus {
      color: var(--white);
      background: var(--error-focus);
      text-decoration: none;
    }

    &:disabled {
      color: var(--white);
      background: var(--erase-disabled);
      text-decoration: none;
      cursor: not-allowed;
    }
  }

  &--secondary {
    color: var(--text-gray);
    background: var(--lighter-off-white);
    border: var(--standard-border-width) solid var(--lighter-off-white);

    &:hover,
    &.button--hover {
      color: var(--text-gray);
      background: var(--off-white-hover);
      text-decoration: none;
    }

    &:focus-visible,
    &:active {
      color: var(--text-gray);
      background: var(--off-white-focus);
      text-decoration: none;
    }

    &:disabled {
      cursor: default;
      background: var(--lighter-off-white);
      opacity: 0.5;
    }
  }

  &--tertiary {
    color: var(--light-gray);

    &:focus-visible {
      background: transparent;
      box-shadow: var(--keyboard-focus-box-shadow);
    }

    &:hover:not(:disabled),
    &.button--hover:not(:disabled) {
      color: var(--text-gray);
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  &--float {
    border: none;
    color: var(--text-gray);
    background: transparent;
    padding: var(--spacing-unit-x2);
    font-weight: var(--font-weight-medium);

    .typography {
      font-weight: var(--font-weight-medium);
    }

    &:hover:not(:disabled),
    &.button--hover:not(:disabled),
    &:focus-visible {
      background: var(--off-white-focus);
    }

    &:hover:disabled {
      cursor: default;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      background: transparent;
      box-shadow: var(--keyboard-focus-box-shadow);
      outline: var(--input-outline-border);
    }

    .svg {
      margin: 0 var(--spacing-unit) 0 0;
      height: 1rem;
      fill: var(--text-gray) !important;

      &--larger-icon {
        margin: inherit;
      }
    }

    &-gray {
      color: var(--light-gray);

      &:hover {
        color: var(--text-gray);
      }
    }

    &-teal {
      color: var(--text-teal);

      &:hover:not(:disabled),
      &.button--hover:not(:disabled),
      &:focus-visible {
        background: var(--lighter-teal);
      }

      &:disabled {
        opacity: 0.5;

        &:hover,
        &.button--hover {
          cursor: not-allowed;
          text-decoration: none;
        }
      }

      &-clicked {
        background-color: var(--text-teal);
        color: var(--white);

        &:hover:not(:disabled),
        &.button--hover:not(:disabled),
        &:focus-visible {
          background-color: var(--text-teal);
        }
      }

      .svg {
        fill: var(--text-teal) !important;
      }
    }

    &-yellow {
      color: var(--text-yellow);

      &:hover:not(:disabled),
      &:focus-visible {
        background: var(--background-yelow);
      }

      &:disabled {
        &:hover {
          text-decoration: none;
        }

        .svg {
          fill: var(--text-gray) !important;
        }
      }

      &-clicked {
        background-color: var(--text-yellow);
        color: var(--white);

        &:hover:not(:disabled),
        &:focus-visible {
          background-color: var(--text-yellow);
        }
      }

      .svg {
        fill: var(--text-yellow) !important;
      }
    }

    &-dark-bg {
      color: var(--white);
      gap: var(--spacing-unit);
      line-height: var(--line-height-standard);
      height: var(--spacing-unit-x6);
      padding: 0 var(--spacing-unit-x2);

      &:hover:not(:disabled) {
        background: var(--white);
        color: var(--dark-blue);

        .svg {
          fill: var(--dark-blue) !important;
        }
      }

      &:focus-visible {
        box-shadow: none;

        &:not(:hover) {
          border: var(--standard-border-white);
          outline: var(--float-dark-button-outline);
        }
      }

      &:disabled {
        opacity: 0.5;
      }

      .svg {
        margin: 0;
        fill: var(--white) !important;
      }
    }
  }

  &--active-filter {
    border: var(--standard-border-width) solid var(--page-back);
    color: var(--text-gray);
    background-color: var(--page-back);

    &:hover,
    &.button--hover {
      border-color: var(--lighter-teal);
      background: var(--lighter-teal);
      text-decoration: none;
    }
  }

  &--text {
    border: none;
    color: var(--light-gray);
    background: transparent;
    padding: 0;
    font-weight: var(--font-weight-medium-light);

    &:hover:not(:disabled),
    &.button--hover:not(:disabled) {
      color: var(--text-gray);
    }

    &:hover:disabled {
      cursor: default;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: var(--keyboard-focus-box-shadow);
    }

    &-teal {
      color: var(--text-teal);

      &:hover:not(:disabled),
      &.button--hover:not(:disabled) {
        color: var(--darker-teal);

        svg {
          fill: var(--darker-teal);
        }
      }

      svg {
        width: calc(var(--spacing-unit-x2) + var(--spacing-unit-x2) / 3);
        height: calc(var(--spacing-unit-x2) + var(--spacing-unit-x2) / 3);
        fill: var(--text-teal);
      }
    }
  }

  &--info {
    border: var(--standard-border-width) solid var(--border-teal);
    background-color: var(--lighter-teal);
    color: var(--text-teal);
    height: var(--spacing-unit-x6);

    &:focus-visible:not([disabled]),
    &:hover:not([disabled]),
    &.button--hover:not([disabled]) {
      background-color: var(--hover-row-button-background-teal);
    }

    .svg {
      fill: var(--text-teal);
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  &--remove {
    border: none;
    color: var(--remove-red);
    background: transparent;
    padding: 0.375rem 1rem;

    &:hover,
    &.button--hover {
      color: var(--remove-hover-red);
      text-decoration: none;

      svg {
        fill: var(--remove-hover-red);
      }
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: var(--keyboard-focus-box-shadow);
    }

    svg {
      margin: 0 var(--spacing-unit) calc(1rem / 6) 0;
      height: 1rem !important;
      fill: var(--remove-red);
    }
  }

  &--dismiss {
    color: var(--remove-red);
    margin-left: var(--spacing-unit-x2);
    background: transparent;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    line-height: 1.166rem;

    &:hover,
    &.button--hover {
      color: var(--remove-red);
      background-color: var(--pink);
      text-decoration: none;
    }

    &:focus {
      color: var(--remove-red);
      background-color: var(--pink);
      text-decoration: none;
    }

    &:disabled {
      color: var(--white);
      background: var(--erase-disabled);
      text-decoration: none;
      cursor: not-allowed;
    }
  }

  &--dashed-pill {
    border: var(--thin-dashed-border);
    border-color: var(--text-gray-opacity-25);
    color: var(--gray);

    &:hover,
    &.button--hover {
      border: var(--standard-border-width) solid var(--text-gray-opacity-25);
    }
  }

  &--pill {
    border: var(--standard-border-width) solid var(--text-gray-opacity-25);
  }

  &--dashed-pill,
  &--pill {
    border-radius: var(--spacing-unit-x4);
  }

  &--white {
    background-color: var(--white);
    border: var(--standard-border-width) solid var(--button-border-gray);
    line-height: var(--spacing-unit-x2);
    height: var(--spacing-unit-x6);

    &:hover,
    &.button--hover {
      border-color: var(--tab-gray);
    }

    &:disabled {
      background-color: var(--input-background);
      color: var(--button-hover);
      cursor: default;

      &:hover,
      &.button--hover {
        border-color: var(--button-border-gray);
      }
    }
  }

  &--text-insert {
    border: var(--standard-border);
    background-color: var(--white);

    &.button {
      display: flex;
      height: var(--spacing-unit-x6);
      padding: 0 var(--spacing-unit-x2);
      align-items: center;
      gap: var(--spacing-unit-x2);

      &:disabled {
        background-color: var(--off-white-hover-disabled);
        cursor: not-allowed;
        color: var(--tab-gray);

        &.svg {
          fill: var(--tab-gray) !important;
        }
      }
    }

    &:hover,
    &.button--hover {
      background-color: var(--off-white-focus);
    }

    &:focus-visible {
      border: var(--standard-border-dark-blue);
      outline: var(--input-outline-border);
    }

    & .svg {
      width: 1.334rem;
      height: 1.334rem;
    }
  }

  &--delete-outline {
    border: var(--standard-border);
    border-color: var(--erase-hover-red);
    padding: calc(var(--spacing-unit) + var(--half-spacing-unit)) var(--spacing-unit-x2);
    color: var(--erase-hover-red);

    &.button {
      display: flex;
      justify-content: center;
    }

    & .svg {
      fill: var(--erase-hover-red);
      width: 1.334rem;
      height: 1.334rem;
    }

    &:hover:not(:disabled),
    &.button--hover:not(:disabled) {
      background-color: var(--erase-hover-red);
      color: var(--white);

      & .svg {
        fill: var(--white) !important;
        width: 1.334rem;
        height: 1.334rem;
      }
    }

    &:disabled {
      cursor: not-allowed;
      border: var(--transparent-border);
      color: var(--tab-gray);
      background-color: var(--off-white-hover-disabled);

      & .svg {
        fill: var(--tab-gray) !important;
      }
    }
  }

  &--outline {
    border: var(--standard-border);
    border-color: var(--text-teal);
    color: var(--text-teal);

    &.button {
      display: flex;
      justify-content: center;
    }

    & .svg {
      fill: var(--text-teal);
      margin-right: var(--spacing-unit);
      width: 1.334rem;
      height: 1.334rem;
    }

    &:hover:not(:disabled),
    &.button--hover:not(:disabled) {
      background-color: var(--text-teal);
      color: var(--white);

      & .svg {
        fill: var(--white) !important;
        width: 1.334rem;
        height: 1.334rem;
      }
    }

    &:disabled {
      cursor: not-allowed;
      border: var(--transparent-border);
      color: var(--tab-gray);
      background-color: var(--off-white-hover-disabled);

      & .svg {
        fill: var(--tab-gray) !important;
      }
    }
  }

  &--table-action {
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
    border: var(--standard-border-width) solid transparent;
    padding: 0;

    .svg {
      width: 1.333rem;
      height: 1.333rem;

      &--icon {
        width: var(--spacing-unit-x2);
        height: var(--spacing-unit-x2);
      }
    }

    &:focus-visible {
      box-shadow: var(--keyboard-focus-box-shadow);
    }

    &:hover,
    &.button--hover {
      background-color: var(--off-white-focus);

      .svg {
        fill: var(--text-gray);
      }
    }
  }

  &--header {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3) !important;
    background-color: var(--off-white-focus);

    &:hover,
    &.button--hover {
      background-color: var(--button-hover);
    }

    &:disabled {
      background-color: transparent;
      border: var(--standard-border);
      cursor: default;
    }
  }

  &--transparent {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3) !important;
    border: var(--standard-border);
    background-color: transparent;

    &:disabled {
      cursor: default;
    }

    &:hover:not(:disabled),
    &.button--hover:not(:disabled) {
      background-color: var(--button-back-gray);
    }
  }

  &--icon {
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
    border: var(--standard-border-width) solid transparent;
    padding: 0;

    .svg {
      width: 1.333rem;
      height: 1.333rem;
    }

    &:focus-visible {
      box-shadow: var(--keyboard-focus-box-shadow);
    }

    &:hover,
    &.button--hover {
      background-color: var(--off-white-focus);

      .svg {
        fill: var(--text-gray);
      }
    }
  }

  &--navigation-button {
    border-radius: var(--extra-large-border-radius);
    padding: var(--spacing-unit-x2) !important;
    height: var(--spacing-unit-x6);
    width: var(--spacing-unit-x6);

    &:hover,
    &.button--hover {
      background-color: var(--navigation-button-hover);
    }

    > svg {
      fill: var(--text-gray);
      flex-shrink: 0 !important;
      height: calc(var(--spacing-unit-x8) / 3) !important;
      width: calc(var(--spacing-unit-x8) / 3) !important;
    }

    > svg[aria-label='arrow-left'] {
      height: var(--spacing-unit-x3) !important;
      width: var(--spacing-unit-x3) !important;
    }
  }

  &--icon-left {
    padding-left: 0;

    svg {
      margin-right: var(--spacing-unit);
    }

    &.button--primary {
      padding-left: var(--spacing-unit-x3);

      svg {
        fill: var(--white);
      }
    }
  }

  &--icon-right {
    padding-right: 0;

    svg {
      margin-left: var(--spacing-unit);
    }
  }

  &--icon-float {
    display: inline-flex;

    svg {
      margin-right: 0;
    }
  }

  &--text-align {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }

  &--no-padding {
    padding: 0;
  }

  &--no-top-bottom-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &--minimal-padding {
    height: var(--spacing-unit-x6);
    padding: 0 var(--spacing-unit-x2);
  }

  &--primary:disabled {
    background-color: var(--disabled-teal);
    color: var(--white);
    text-shadow: var(--standard-border-width) var(--standard-border-width) 0.125rem var(--disabled-teal);
    cursor: default;

    &:hover,
    &.button--hover {
      background-color: var(--disabled-teal);
      color: var(--white);
      text-decoration: none;
      cursor: not-allowed;
    }

    &:focus {
      background-color: var(--disabled-teal);
      color: var(--white);
      text-decoration: none;
      cursor: default;
    }
  }

  &--edit {
    font-size: var(--default-font-size);
    color: var(--white);
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    margin: -0.5rem 0;

    &:hover,
    &.button--hover {
      background-color: var(--blue);
    }
  }

  &--full-width {
    width: 100%;
    display: inherit;
  }

  &--inline {
    display: inline;
  }

  &--size-large {
    width: 16.66rem;
  }

  &--size-medium {
    width: 11rem;
  }

  &--size-small {
    font-size: var(--medium-font-size);
  }

  &--weight-medium {
    font-weight: var(--font-weight-medium);
  }

  &--cursor-not-allowed:hover:disabled {
    cursor: not-allowed;
  }
}
