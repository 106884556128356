
.page-error {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 26rem;

  &--center {
    position: absolute;
    left: calc(50% - 13rem);
    top: calc(50% - 2.415rem);
  }

  &--vertical {
    width: auto;

    &__wrapper {
      flex-direction: column;
    }

    &__text {
      padding-top: var(--spacing-unit-x2);
      padding-left: 0;
    }
  }

  &--vertical&--center {
    position: absolute;
    left: calc(50% - 7rem);
    top: calc(50% - 7rem);
  }

  &__wrapper {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
  }

  &__icon-wrapper {
    border-radius: 5rem;
    height: var(--spacing-unit-x16);
    margin-top: var(--spacing-unit-x2);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 0.416rem solid var(--error-lighter);
    background-color: var(--error-light);

    svg {
      fill: var(--error);
      margin: 0 var(--spacing-unit-x2);
    }
  }

  &__text {
    padding-left: var(--spacing-unit-x3);
  }

  button {
    flex: 0 0 auto;
    margin-top: var(--spacing-unit-x4);
  }
}
