.modal-footer {
  &--form,
  &--list {
    border-top: var(--standard-border);
    border-top-color: var(--light-theme-border);
  }

  &__inner {
    flex: 1 1 100%;
    position: relative;
    padding: var(--spacing-unit-x4) var(--spacing-unit-x7);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    &--show-divider {
      border-top: var(--standard-border);
    }

    &--multiple {
      display: flex;
      gap: var(--spacing-unit-x2);
    }

    &--default {
      padding: var(--spacing-unit-x2) var(--spacing-unit-x6) var(--spacing-unit-x6) var(--spacing-unit-x6);
      justify-content: right;
    }
  }
}
