
.welcome-message-editor {
  .container {
    border: none;
    padding: var(--spacing-unit-x3) 0 0 0;

    .section-headline {
      display: none;
    }
  }

  &__top-text {
    padding-top: var(--spacing-unit-x2);
  }

  &__explainer-list {
    margin-top: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x2);

    li {
      margin-bottom: var(--spacing-unit-x2);

      > div {
        margin-right: var(--spacing-unit);
      }
    }
  }

  &__templates {
    display: inline;

    button {
      padding-left: var(--spacing-unit);
    }
  }
}
