.landing-page-settings {
  padding: var(--spacing-unit-x3) var(--spacing-unit-x4) 0;

  &__headline {
    margin-bottom: var(--spacing-unit-x3);
  }

  &__block {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit-x3) 0;

    & + & {
      border-top: var(--standard-border);
    }

    &-button {
      margin-left: auto;
    }

    &-title {
      margin-bottom: var(--spacing-unit);
    }
  }
}
