
.message-preview {
  &__layout {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 50rem;
  }

  &__inner-container {
    box-sizing: border-box;
    width: 23.5rem;
    border: var(--standard-border);
    border-radius: calc(4rem / 12);
    padding: 1rem;
    margin-bottom: 1rem;

    .radio-top {
      margin-bottom: 0;
    }

    .radio-bottom {
      margin: 0;
    }

    .radio__label {
      margin-bottom: 1rem;

      &::after,
      &::before {
        bottom: 0.5rem;
      }
    }

    .select {
      margin-top: 1rem;
      width: 100%;
    }
  }

  &__info {
    margin-bottom: 1rem;
  }

  &__caution {
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    margin: 1rem 0;
    padding: 1rem;
    background-color: var(--light-yellow);

    .svg {
      margin-right: calc(2rem / 3);
    }
  }

  &__right-container {
    box-sizing: border-box;
    margin-left: 2rem;
  }

  &__sms-block {
    margin: 0 1.25rem 0.5rem 0;
    word-break: break-word;
    white-space: pre-wrap;
    padding: 1rem calc(14rem / 12) 1rem 1rem;
    background-color: var(--sms-text-gray);
    border-radius: 1.3333rem 1.3333rem 1.3333rem 0;
    font-size: var(--large-font-size);

    p {
      margin: 0;
      padding: 0;
      min-height: 1.3333rem;
    }
  }

  &__empty {
    font-size: var(--large-font-size);
    line-height: var(--line-height-medium);
    text-align: center;
    color: var(--light-gray);
    padding: 8rem 2rem 1rem;
  }

  &__highlight {
    color: var(--highlight-blue);
  }
}

@media all and (max-width: 79.5rem) {
  .message-preview {
    &__layout {
      flex-wrap: nowrap;
      align-items: center;
      max-height: none;
    }

    &__inner-container:first-child {
      order: 0;
      margin-bottom: 1rem;
    }

    &__inner-container {
      width: 100%;
      order: 2;
      margin-bottom: 3.5rem;
    }

    &__right-container {
      order: 1;
      margin: 0 0 1rem;
    }
  }
}
