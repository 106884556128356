.discarded-segment-feedback-modal {
  width: 50rem;

  .checkbox__container {
    margin-bottom: var(--spacing-unit-x2);
  }
  
  &__body {
    > :first-child {
      margin-bottom: var(--spacing-unit-x4);
    }
  }

  &__footer {
    &__extra {
      padding-top: var(--spacing-unit-x2);
      margin-right: auto;
    }
  }
}
