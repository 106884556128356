.automated-programs-info-hover {
  .info-hover-card__trigger {
    width: 1.34rem;
    height: 1.34rem;
  }

  &-scroll-hide-scroll {
    .automated-programs-info-hover {
      display: none;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__modified {
    display: flex;
    gap: var(--spacing-unit);
    margin-top: var(--spacing-unit-x2);
  }

  &__refresh {
    fill: var(--white);
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-unit-x2);
  }

  &__body-title {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__schedule {
    margin-right: var(--spacing-unit);
  }

  &__footer {
    height: auto;

    &-icon {
      margin-left: var(--spacing-unit);
    }
  }

  &-scroll-hide__footer {
    height: auto;
  }
}
