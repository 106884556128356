
.data-with-arrow {
  &__change-no-arrow {
    margin: 0 var(--spacing-unit);
    display: inline-block;
    width: var(--spacing-unit-x2);
    color: transparent;
  }

  &__change-arrow {
    margin: 0 var(--spacing-unit);

    &-down {
      fill: var(--error);
    }

    &-up {
      fill: var(--success-green);
    }
  }
}
