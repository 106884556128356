.alert-filters-list {
  display: block;

  &__filter,
  &__filter-toggle,
  &__filter-fixed {
    margin-bottom: var(--spacing-unit-x3);
  }

  &__filter:last-child {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__filter {
    &-fixed {
      background: var(--lighter-off-white);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
      color: var(--text-gray);
      padding: var(--spacing-unit-x2) !important;
      width: fit-content;
    }
  }
}
