
.revenue-impact {
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit-x4);
  }

  .nested-table__holder {
    margin-top: var(--spacing-unit-x4);
  }
}
