.change-field-value-upgrade-step {
  &__common-condition {
    white-space: nowrap;
    
    &-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: var(--half-spacing-unit);
    }

    &-source {
      background-color: var(--chip-base);
      border-radius: var(--spacing-unit-x2);
      padding: var(--half-spacing-unit)calc(var(--spacing-unit-x18) / 12);
      height: var(--spacing-unit-x4);
    }
  }
}
