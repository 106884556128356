
.data-card-header-with-options {
  &__trigger {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: var(--half-spacing-unit);

    &:hover {
      cursor: pointer;

      .data-card-header-with-options__caret {
        fill: var(--text-gray);
      }
    }
  }

  &__caret {
    fill: var(--gray);

    &.svg {
      height: 0.58333rem;
      width: 0.58333rem;
    }

    &-open {
      fill: var(--text-gray);
    }
  }

  &__selected {
    white-space: nowrap;

    &:hover {
      color: var(--text-gray);
    }
  }
}
