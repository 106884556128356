.step-three {
  &__loader {
    width: 100%;

    &-container {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
  
  &__mapping {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-row-gap: var(--spacing-unit-x2);
    
    & > :first-child {
      grid-row-gap: var(--spacing-unit-x4);
    }
    
    &-empty {
      margin: 0;

      &-listing {
        padding-bottom: 0;
        padding-top: 0;
      }
      
      &-text {
        padding: var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x4) 0;
      }
    }
    
    &-info-action {
      margin: 0 0 var(--spacing-unit-x4) 0;
    }
  }


  & .step-content__content {
    max-width: 68.67rem;
  }
}
