.collapsible-banner-collapsed-content {
  &__tooltip__trigger-wrapper {
    margin-right: var(--spacing-unit-x2);
  }
  
  &__icon-container {
    align-items: center;
    border-radius: var(--standard-border-radius);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: var(--spacing-unit-x6);
    transition: background-color 200ms ease-in-out 0s;
    width: var(--spacing-unit-x6);
    
    &:hover {
      background-color: var(--off-white-focus);
      cursor: pointer;
    }
  }
}