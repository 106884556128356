.recipient-info {
  display: flex;
  gap: var(--spacing-unit-x2);
  cursor: default;

  &__icon {
    position: relative;
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);

    &-muted {
      display: flex;
      align-items: center;
      position: absolute;
      overflow: hidden;
      background-color: var(--remove-red);
      border-radius: var(--extra-large-border-radius);
      max-width: fit-content;
      width: calc(16rem / 12);
      left: calc(22rem / 12);
      top: calc(20rem / 12);
      transition: width 300ms ease-in-out;

      &:hover {
        width: var(--spacing-unit-x10);
      }

      svg {
        flex-shrink: 0;
      }

      div {
        padding-right: var(--spacing-unit);
      }
    }
  }

  &__name {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x2);

    svg {
      fill: var(--button-gray);
    }

    &-group-button {
      height: var(--spacing-unit-x2);
      width: var(--spacing-unit-x2);
      border: none;
      cursor: pointer;

      svg {
        color: var(--button-gray);
      }

      &:hover {
        background-color: unset;

        svg {
          color: var(--text-teal);
        }
      }
    }
  }
}
