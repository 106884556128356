.sidebar-menu {
  width: 40rem;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--white);
  z-index: var(--z-index-99999);
  border-left: var(--standard-border);
  box-shadow: var(--breadcrumbs-drop-down-shadow);

  &__header {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x2) var(--spacing-unit-x3) var(--spacing-unit-x4);

    &-left {
      display: flex;
      gap: var(--spacing-unit-x2);
      align-items: center;
    }

    &-close-button {
      border-radius: var(--extra-large-border-radius);
    }
  }

  &__body {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x3);
    border-top: var(--standard-border);
    border-bottom: var(--standard-border);
    height: calc(100% - var(--spacing-unit-x24));
    overflow: hidden visible;
  }

  &__footer {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit-x3);
    justify-content: flex-end;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x3) var(--spacing-unit-x3) var(--spacing-unit-x4);
    height: var(--spacing-unit-x12);
  }
}
