.breadcrumbs-hover-card {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  & > [data-radix-popper-content-wrapper] {
    direction: rtl;
    left: unset !important;
    position: relative !important;
    top: unset !important;
    transform: unset !important;
  }
  
  &__breadcrumb {
    padding: 0.66667rem;
    
    &:hover {
      cursor: pointer;
      border-radius: var(--standard-border-radius);
      background-color: var(--off-white-focus);
      text-decoration: none;
    }
  }

  &__breadcrumbs {
    gap: var(--spacing-unit);
  }
  
  &__content,
  &__trigger {
    position: absolute;
  }

  &__content {
    border-radius: var(--standard-border-radius);
    background-color: var(--white);
    direction: ltr;
    padding: 0.33334rem 1.33334rem;
    box-shadow: var(--breadcrumbs-hover-card-shadow);
    visibility: hidden;
    
    &-show {
      visibility: unset;
    }
  }

  &__trigger {
    align-items: center;
    display: flex;
    height: fit-content;
    text-decoration: none !important;
    width: inherit;
    
    &-text {
      padding: var(--spacing-unit-x2) 0;
      white-space: nowrap;
    }
  }

}
