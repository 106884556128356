.confirmation-modal {
  max-width: 40rem;
  min-width: 32rem;

  & .modal-body {
    padding: 0 var(--spacing-unit-x6);
  }

  & .modal-header__inner {
    padding: var(--spacing-unit-x6) var(--spacing-unit-x6) var(--spacing-unit-x2) var(--spacing-unit-x6);
    background-color: transparent;

    &.confirmation-modal__header--no-title {
      padding-bottom: 0;
    }

    &.confirmation-modal__header--with-icon {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x4);
    }
  }

  &__header--center {
    justify-content: center;
    text-align: center;
  }

  & .modal-footer__inner {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x6) var(--spacing-unit-x6) var(--spacing-unit-x6);
    justify-content: right;
  }

  &__hide-footer {
    visibility: hidden;
  }

  .button {
    gap: var(--spacing-unit-x2);
  }
}
