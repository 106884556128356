.create-webinar-segments-modal {
  width: 60rem;

  &__body {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x6) !important;
  }

  &__content {
    margin-top: var(--spacing-unit-x4);
    display: grid;
    grid-template-columns: 22rem 32rem;
  }

  &__pill {
    border-radius: var(--standard-border-radius);
    background-color: var(--selectable-row-hover);
    color: var(--text-gray);
    padding: 0.16rem var(--spacing-unit);
  }

  &__section {
    &-title {
      margin-bottom: var(--spacing-unit-x4);
    }

    &-rule {
      display: flex;
      gap: var(--spacing-unit);
      align-items: center;
    }
  }

  &__submit-button {
    width: 13.5rem;
  }
}
