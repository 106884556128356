.forms-picker-modal {
  &__item-name {
    &-disabled {
      cursor: not-allowed;
      pointer-events: all;
   
      .tooltip__trigger {
        cursor: not-allowed !important;
        pointer-events: all;
      }
    }
  }

  &__disabled-tooltip {
    white-space: pre-line !important;
  }
}