.more-options {
  display: inline-flex;

  &::before {
    content: '';
    margin: 0 calc(14rem / 12) 0 var(--spacing-unit);
    border: var(--standard-border);
    border-width: var(--thick-border-width);
    border-color: var(--teal);
    border-radius: var(--standard-border-radius);
  }
}
