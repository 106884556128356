.list-maintenance-programs-listing-page-container {
  &__program-name-cell {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit);
    width: inherit;
    cursor: pointer;

    svg {
      flex-shrink: 0;
    }
  }

  &__fix-errors-cell {
    cursor: not-allowed;
  }

  &__create-program-button {
    height: var(--spacing-unit-x6);

    svg {
      margin-right: var(--spacing-unit);
      fill: var(--white);
    }
  }
}
