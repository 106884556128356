.folder {
  position: relative;
  padding: 0 var(--spacing-unit-x4);

  &:hover {
    cursor: pointer;
  }

  &__folder-name {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-unit);
  }

  &__caret {
    width: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2));
    height: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2));
    padding: 0.4166666666666667rem;

    &:hover {
      background-color: unset;

      & > .svg {
        fill: var(--light-gray);
      }
    }

    &-icon.svg {
      height: var(--spacing-unit);
      width: var(--spacing-unit);
      fill: var(--light-gray) !important;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-unit) 0 var(--spacing-unit) var(--spacing-unit-x6);
    gap: var(--spacing-unit-x2);

    &-hovered {
      background-color: var(--hover-row-background-teal);
    }

    &-title {
      align-items: center;
      display: flex;
      gap: var(--spacing-unit-x2);
    }
  }

  &__folder-icon {
    width: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2));
    height: calc(var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2));
  }

  &__icon {
    fill: var(--button-hover);

    &-active {
      fill: var(--teal);
    }
  }

  &__no-sub-folders {
    margin-left: calc((var(--spacing-unit-x2) / 3 + var(--spacing-unit-x2)) + var(--spacing-unit-x2));
  }

  &__toggle-container {
    padding-right: calc(var(--spacing-unit) + 0.2rem);
  }

  &__actions-container {
    position: absolute;
    right: 0;
  }

  &__pointer-event {
    pointer-events: auto;
  }
}
