.date-timeframes {
  display: flex;
  
  > div:not(.date-timeframes__ignore-year) {
    margin-left: var(--spacing-unit-x2);
  }

  > div:first-child {
    margin-left: 0;
  }
  
  &__ignore-year {
    margin-left: var(--spacing-unit);
  }
}