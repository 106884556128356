.indented-table {
  flex: 1;
  padding: 0;

  &__holder {
    width: 100%;
    overflow-x: auto;
    border: var(--standard-border);
    border-radius: 0.33rem;
    border-spacing: 0;
    min-width: var(--positioning-min-width);
    max-width: var(--positioning-max-width);
    padding-bottom: var(--spacing-unit-x4);
    display: flex;
    flex-direction: column;
    background-color: var(--white);
  }

  &__left {
    text-align: left;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }

  &__thead &__tr {
    display: flex;
    width: 100%;
    align-items: flex-end;
    height: var(--spacing-unit-x8);

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: var(--white);
    }
  }

  &__tbody &__tr {
    display: flex;
    padding-right: var(--spacing-unit-x4);
  }

  &__thead &__expander {
    padding-left: var(--spacing-unit-x4);
  }

  &__th {
    display: inline-block;
    padding-bottom: var(--spacing-unit);
    color: var(--light-gray);
    font-weight: var(--font-weight-bold);
    font-size: var(--smallest-font-size);
    text-transform: uppercase;
    border-bottom: var(--input-border-hover);
    cursor: pointer;

    &:nth-child(1) {
      padding-left: var(--spacing-unit-x4);
    }

    &:last-child {
      padding-right: var(--spacing-unit-x4);
    }

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
    }
  }

  &__ignore-header-hover &__th {
    cursor: auto;
  }

  &__tbody {
    display: flex;
    flex-direction: column;
  }

  &__tr:hover {
    background-color: var(--hover-row-background-teal);

    .spacer-block-large,
    .spacer-block-small {
      background-color: var(--white);
    }
  }

  &__td {
    padding: var(--spacing-unit-x2) 0;

    &-bottom-border {
      border-bottom: var(--standard-border);
    }

    &-top-border {
      border-top: var(--standard-border);
    }
  }

  &__row-last &__td,
  &__row-last &__td-last-inner,
  &__row-last &__expander-text {
    border-bottom: none;
  }

  &__thead &__th {
    border-bottom: var(--input-border-hover);
  }
}
