.location-details {
  &__select-card {
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x3);
    background-color: var(--input-background);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
  }

  &__select-popover {
    &__trigger {
      &:focus-visible {
        border: var(--standard-border-dark-blue);
      }
    }

    &-selected {
      display: flex;
      background-color: var(--white);
      gap: var(--spacing-unit-x3);

      &-icon {
        border: var(--standard-border);
        border-radius: var(--standard-border-radius);
      }
    }

    &__anchor {
      display: flex;
      background-color: var(--white);
      justify-content: space-between;
      padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);

      & + div {
        width: 50rem;
      }
    }

    & .button--outline {
      gap: var(--spacing-unit);
      margin: var(--spacing-unit-x2) var(--spacing-unit-x4);
      width: calc(100% - var(--spacing-unit-x8));
    }

    & .svg {
      margin: 0;
    }
  }
}
