.search-results {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: calc(var(--spacing-unit-x3) + var(--half-spacing-unit));
  min-height: 0;

  &__all-lists {
    display: flex;
    flex-direction: column;
    gap: 2.66rem;
    padding-top: 1.04rem;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.208rem;
    height: calc(100% - 8.75rem);
    min-height: 0;

    .th:last-child,
    .nested-table-row__cell:last-of-type {
      margin-right: var(--spacing-unit-x4) !important;
    }

    & .actionable-nested-table-with-lazy-loading {
      flex: unset !important;
    }
  }

  &__filters {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.916rem;
    padding: 0 1.66rem;
  }

  &__label {
    padding: var(--spacing-unit-x4) 0 0 1.66rem;
  }

  &__list {
    &-label {
      padding-left: 1.66rem;
    }
  }

  &__scroll-area {
    .scroll-area__viewport > div {
      display: flex !important;
      flex-direction: column;
      height: 100%;
    }
  }

  &__table {
    border: none;
    max-width: unset;

    & .table__th:first-child {
      flex: unset !important;
    }

    & .nested-table-row {
      & > div:first-child {
        flex: unset !important;
      }

      &:last-child {
        border-bottom: var(--standard-border);
      }
    }
  }

  .caution {
    border-left: none;
    border-radius: 0;
    margin: 0;
  }
}
