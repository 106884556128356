.webhook-source-selector {
  position: relative;

  &__label {
    margin-bottom: 0.8334rem;
  }
 
  &__option,
  &__container {
    display: flex;
    align-items: center;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3);

    > div {
      flex-grow: 1;
      margin: 0 var(--spacing-unit-x3);
    }

    svg {
      flex-shrink: 0;
    }

    button {
      height: var(--spacing-unit-x6);
    }
  }

  &__option {
    &:hover {
      background-color: var(--selectable-row-hover);
      cursor: pointer;
    }
     
    &-selected {
      background-color: var(--lighter-teal);
      box-shadow: inset calc(4rem / 12) 0 0 var(--teal);
  
      &:hover {
        background-color: var(--hover-row-button-background-teal);
      }
    }
     
    &-container {
      position: fixed;
      z-index: var(--z-index-99999);
      margin-top: var(--spacing-unit);
      padding: var(--spacing-unit) 0;
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
      background-color: var(--white);
      box-shadow: var(--media-card-box-shadow);
    }
  }
}
