.select-forms-step {
  &__title {
    margin-bottom: var(--spacing-unit-x2);
  }
   
  &__subtitle {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__name-with-icon {
    display: flex;
    gap: var(--spacing-unit-x2);
    align-items: center;

    .svg {
      flex-shrink: 0;
      width: calc(var(--spacing-unit-x8) / 3);
      height: calc(var(--spacing-unit-x8) / 3);
      color: var(--text-teal);
    }
  }
 
  &__content {
    padding: 0;
    max-width: 60rem;
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x4);
   
    &-title {
      margin-top: var(--spacing-unit-x2);
    }
     
    &-none {
      margin: var(--spacing-unit-x4);
    }
  
    .tableV2 {
      min-width: unset;
    }
  }
 
  &__button {
    &-svg {
      margin-right: var(--spacing-unit);

      &.svg {
        width: calc(var(--spacing-unit-x8) / 3);
        height: calc(var(--spacing-unit-x8) / 3);
      }
    }
  }
}
