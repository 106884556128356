.form-optIns-lists {
  &__loader {
    height: 42rem;
  }

  &__headline {
    padding-left: var(--spacing-unit-x4);
    margin-top: 0;
  }

  .table__table-holder {
    border-top: none;
  }

  .table__table-holder,
  .table__paginator {
    border-right: none;
    border-left: none;
    min-width: var(--sms-optins-positioning-min-width);
  }

  .table__paginator {
    border-radius: unset;
    border-bottom: none;
  }

  .td {
    &__form-name {
      font-weight: var(--font-weight-medium);
    }

    .svg {
      fill: var(--teal);
    }
  }

  &__segment-with-icon {
    display: flex;
    align-items: center;
    gap: calc(var(--spacing-unit) + var(--half-spacing-unit));

    > svg {
      min-width: 1.33rem;
      height: 1.33rem;
    }
  }
}
