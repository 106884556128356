.email-composer-preview-details {
  display: flex;
  flex-direction: column;

  &__banner-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    .info-status--caution {
      margin: 0;
      width: 100%;
    }

    .info-status--wrapper-status-text {
      justify-content: flex-start;
      width: 100%;
    }
  }

  &__preview {
    border-bottom: var(--standard-border-dark);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

    &-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x3);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    overflow: auto;

    &-item {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit);
      word-break: break-word;
    }
  }

  &__loading {
    margin-top: var(--spacing-unit-x25);
  }
}
