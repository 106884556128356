.email-templates-listing-page-container {
  &__beta-pill {
    color: var(--white) !important;
  }

  &__drop-down {
    margin-left: var(--spacing-unit-x2);
  }

  &__flex {
    display: flex;
  }

  &__tooltip {
    margin-left: var(--spacing-unit);
    margin-right: auto;
    padding-right: var(--spacing-unit);
    display: flex;
    align-items: center;
  }

  &__text-link {
    & .svg {
      fill: var(--white);
    }
  }

  &__create-template-disabled {
    &.button:hover {
      cursor: not-allowed;
    }
  }

  &__search-name {
    display: flex;
    cursor: pointer;
    gap: var(--spacing-unit);

    &-no-action {
      cursor: default;
    }
  }

  &__template-buttons-wrapper {
    display: flex;
    align-self: center;
    gap: var(--spacing-unit-x2);
    height: var(--spacing-unit-x6);
  }

  &__blank-setup-button {
    padding-left: 1.333rem;
    white-space: nowrap;
    height: var(--spacing-unit-x6);
  }

  &__template-catalog-button {
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    white-space: nowrap;
  }
}

.pill.email-templates-listing-page-container__beta-pill {
  color: var(--white) !important;
}
