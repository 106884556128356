
.message-details {
  display: flex;
  flex-direction: column;

  &__section {
    margin-top: var(--spacing-unit-x3);
  }

  &__section-spacer {
    margin-top: var(--spacing-unit-x4);
  }

  &__section-title {
    padding-bottom: var(--spacing-unit-x2);
  }

  &__section-subtitle {
    padding-bottom: var(--spacing-unit);
  }

  &__row-separator {
    padding-bottom: var(--spacing-unit-x2);
  }

  &__label-separator {
    margin-bottom: var(--spacing-unit);
  }

  &__detail {
    display: flex;
  }

  &__detail-label {
    margin-right: var(--spacing-unit-x4);
  }

  &__detail-description {
    min-height: var(--spacing-unit-x2);
  }

  &__lined {
    padding-bottom: var(--spacing-unit);
    margin-bottom: var(--spacing-unit);
    border-bottom: var(--standard-border);
  }

  &__data {
    display: flex;
    justify-content: space-between;

    .svg {
      flex: 0 0 var(--spacing-unit-x2);
      width: var(--spacing-unit-x2);
      height: var(--line-height-large);
      fill: var(--teal);
      margin: 0 var(--spacing-unit);
    }
  }

  &__lists-table {
    margin-bottom: var(--spacing-unit-x4);
  }

  .message-preview-visual__sms-block {
    background-color: var(--highlight-teal);
  }
}
