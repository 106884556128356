.delete-with-confirmation-modal {
  width: 40rem;

  .modal-body {
    margin-top: var(--spacing-unit-x2);
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &-svg {
      display: flex;
      justify-content: center;
      margin: var(--spacing-unit-x6) 0 var(--spacing-unit-x4);
    }
  }

  &__confirmation-body-input {
    margin-top: var(--spacing-unit-x4);

    &-text {
      margin-bottom: var(--spacing-unit-x2);
    }

    &-elem__container {
      padding-bottom: var(--half-spacing-unit);
    }
  }
}
