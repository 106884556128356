.ab-split {
  position: relative;

  &__header {
    margin: 0 0 var(--spacing-unit-x4) 0;

    &-main {
      max-width: 59rem;
      margin-bottom: var(--spacing-unit-x2);
    }

    &-regenerate {
      margin-bottom: var(--spacing-unit-x4);
    }
  }

  &__subheader {
    display: flex;
    gap: var(--spacing-unit);
    margin-bottom: var(--spacing-unit-x4);
  }

  &__section {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__content {
    display: flex;

    &-left {
      width: 59rem;
      min-width: 39rem;
    }

    &-right {
      width: calc(var(--spacing-unit-x16) + var(--spacing-unit-x29));
      margin-left: var(--spacing-unit-x4);
    }
  }

  &__loader {
    height: 100%;
    width: 100%;
  }

  &__suppressed {
    padding-right: var(--spacing-unit-x12);
  }
}
