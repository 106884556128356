.drop-down-actions {
  background-color: var(--white);

  &__list {
    min-height: var(--spacing-unit-x6);
  }

  &__item,
  &__item__text-with-title {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit) var(--spacing-unit-x3);
    transition: var(--linear-80);
    min-height: inherit;

    &-small {
      padding: var(--spacing-unit) var(--spacing-unit-x2);
    }

    &:hover {
      background-color: var(--lighter-teal);
      cursor: pointer;

      & > svg {
        fill: var(--text-gray);
        color: var(--text-gray);
      }
    }

    & > svg {
      fill: var(--text-gray);
      color: var(--text-gray);
      transition: fill 80ms linear;
    }
  }

  &__item__text-with-title {
    padding-left: 0;
    padding-right: 0;
  }

  &__item__title-container {
    display: block;
    flex-direction: column;
    gap: var(--spacing-unit);
    padding-left: var(--spacing-unit);
    padding-right: var(--spacing-unit);
  }

  &__item-disabled {
    align-items: center;
    display: flex;
    gap: var(--spacing-unit-x2);
    padding: var(--spacing-unit) var(--spacing-unit-x3);
    transition: var(--linear-80);
    cursor: not-allowed;

    & > svg {
      fill: var(--text-gray);
      color: var(--text-gray);
      opacity: 0.4;
      transition: fill 80ms linear;
    }
  }

  &__top-section {
    border-bottom: var(--standard-border);
    margin: var(--spacing-unit) 0;
  }

  &__footer-top-section-line {
    border-top: var(--standard-border);
    margin-top: var(--spacing-unit);
    padding-top: var(--spacing-unit);
  }
}
