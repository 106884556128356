.input-tags {
  --error-margin-top: calc(var(--spacing-unit-x3) / 2);
  --error-line-height: var(--line-height-standard);

  margin-bottom: calc(var(--error-line-height) + var(--error-margin-top));

  &__no-margin-bottom {
    margin-bottom: 0;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-unit);
    align-content: flex-start;
    min-height: var(--spacing-unit-x20);
    max-height: var(--spacing-unit-x23);
    padding: var(--spacing-unit-x2);
    border-radius: var(--standard-border-radius);
    border: var(--input-border);
    background: var(--input-background);
    overflow-x: auto;
  }

  &__tag {
    display: flex;
    align-items: center;
    padding: calc(var(--spacing-unit-x2) / 3);
    padding-left: var(--spacing-unit);
    border-radius: calc(var(--standard-border-radius) * 25);
    border: var(--input-border-hover);
    background: var(--white);
    max-width: var(--input-tags-tag);

    & > svg {
      fill: var(--remove-red);
    }

    .typography {
      margin-right: var(--spacing-unit-x2);
      margin-left: var(--spacing-unit);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-error {
      border-color: var(--high-red);
    }

    &:hover {
      cursor: pointer;

      .input-tags__remove-icon {
        background: rgba(68, 68, 68, 0.12);
      }
    }
  }

  &__remove-icon {
    padding: calc(var(--spacing-unit-x2) / 3) var(--spacing-unit);
    border-radius: 100%;
  }

  &__input {
    border: none;
    background: inherit;
    color: var(--text-gray);
    font-size: var(--large-font-size);
    font-weight: var(--font-weight-medium-light);
    flex: auto;
    line-height: var(--line-height-larger);

    &:focus-visible {
      outline: none;
    }
  }

  &__error-msg {
    margin-top: var(--error-margin-top);
    line-height: var(--error-line-height);
    color: var(--high-red);
  }
}
