.tableV2-action-header {
  height: var(--spacing-unit-x10);
  z-index: var(--z-index-9999);
  display: flex;
  position: sticky;
  top: -0.5rem;
  margin: 0 -0.08rem;
  align-items: center;
  background: var(--dark-blue);
  padding-right: var(--spacing-unit-x4);
  border-radius: var(--standard-border-radius) var(--standard-border-radius) 0 0;

  &__items {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  &__checkbox {
    max-width: var(--spacing-unit-x10);
    width: var(--spacing-unit-x10);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__total-count {
    padding-left: 0;
    padding-right: 0;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
    justify-content: flex-end;
    padding-right: 0;
    padding-left: 0;

    & .button--table-action:not(.button--delete),
    & .drop-down__main-button {
      &:hover {
        background-color: var(--blue);
        border-radius: var(--standard-border-radius);
      }
    }

    &-drop-down button,
    .button--table-action {
      color: var(--white);
      font-weight: var(--font-weight-medium-light);

      .svg {
        fill: var(--white) !important;
      }
    }

    &-drop-down {
      &-option {
        display: inline-flex;

        > .svg {
          margin-top: var(--half-spacing-unit);
        }
      }

      &-open button {
        background-color: var(--blue);
      }
    }

    & .tag-manager__drop-down-open .button--table-action {
      background-color: var(--blue);
    }

    .drop-down {
      padding-right: 0;
    }
  }
}
