.display-toggle-card-toggletip {
  &__trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &-small {
      padding: var(--half-spacing-unit);
    }

    &-medium {
      padding: calc(var(--spacing-unit-x2) / 3);
    }

    &-large {
      padding: var(--spacing-unit);
    }

    .svg {
      flex-shrink: 0;
    }
  }

  &__content {
    max-width: 40rem;
    background-color: var(--white);
    box-shadow: var(--white-toggletip-box-shadow);
    border-radius: var(--standard-border-radius);
    z-index: var(--z-index-999999);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-unit-x2);
    padding-left: var(--spacing-unit-x4);
  }
}
