
.credits-summary {
  > .container {
    border: var(--card-border-highlight);
  }

  &__table-credits-breakdown {
    margin-top: var(--spacing-unit-x4);
  }

  &__credits-amount {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    display: flex;

    span:first-child {
      flex: 1;
    }

    span:last-child {
      flex: 0 0 2rem;
      text-align: right;
    }
  }
}
