.preview-version-modal {
  width: 56rem;
  height: 50rem;

  .modal-body {
    padding: 0;
  }

  .modal-footer__inner {
    padding: var(--spacing-unit-x4);
    height: var(--spacing-unit-x14);
  }

  .preview-asset-modal__header {
    height: var(--spacing-unit-x12);
  }

}
