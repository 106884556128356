.copy-permissions-modal {
  width: 100%;
  max-width: calc(100 * var(--spacing-unit));
  overflow: unset;

  .modal-header__inner {
    border-top-left-radius: 0.333rem;
    border-top-right-radius: 0.333rem;
  }

  &__description {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__users-count {
    margin-right: auto;
  }

  &__info {
    padding-top: var(--spacing-unit-x4);
    padding-bottom: var(--spacing-unit-x4);

    &-title {
      margin-bottom: var(--spacing-unit-x4);
      padding-top: var(--spacing-unit-x4);
      border-top: var(--modal-border);
    }

    &-list {
      display: flex;
      align-items: flex-start;

      & + & {
        margin-top: var(--spacing-unit-x3);
      }

      &__title {
        width: var(--spacing-unit-x12);
        text-transform: uppercase;
        color: var(--gray);
        padding-top: 0.16rem;
      }

      &__value {
        width: calc(100% - var(--spacing-unit-x12));
        color: var(--gray);
      }
    }
  }
}
