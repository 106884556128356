
.program-performance-detail-table-container {
  border: var(--standard-border);
  border-radius: var(--standard-border-radius);
  background-color: var(--white);
  min-width: calc(var(--positioning-min-width) + (var(--standard-border-width) * 2));
  margin-top: var(--spacing-unit-x4);
  overflow: hidden;

  &--in-program {
    border-top-color: var(--border-clicked);
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }

  &__table-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__table {
    display: flex;
    flex-direction: column;
    background-color: inherit;
    flex-grow: 1;

    &__table-holder {
      flex-grow: 1;
      border: none;
    }

    & .table__paginator {
      border: none;
      border-top: var(--standard-border);
    }
  }

  &__loading {
    & .table-with-loader {
      border: none;
    }
  }

  &__empty-table {
    & .program-performance-table-header__title-search {
      padding: 0;
    }
  }
}

.program-performance-detail-empty-table {
  &__empty-listing {
    border: none;
  }
}
