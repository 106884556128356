.code-block-v2 {
  &__label.label {
    font-size: var(--large-font-size);
    line-height: var(--line-height-large);
    margin-bottom: var(--spacing-unit);
  }

  &__content {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    overflow: hidden;
  }

  &__content-inner {
    min-height: 8rem;
    overflow: hidden;
    word-wrap: break-word;
  }

  &__simple {
    padding: var(--spacing-unit-x2);
  }

  &__footer {
    background-color: var(--white);
    border-top: var(--standard-border);
    padding: var(--spacing-unit) calc(var(--spacing-unit) + var(--half-spacing-unit)) var(--spacing-unit) var(--spacing-unit-x4);
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    border-bottom-left-radius: var(--standard-border-radius);
    border-bottom-right-radius: var(--standard-border-radius);
  }

  &__helper-text {
    font-size: var(--default-font-size);
    line-height: var(--line-height-medium);
    color: var(--light-gray);
    margin-top: var(--spacing-unit-x2);
  }
}
