.duplicate-modal {
  width: 50rem;
  max-width: 50rem;
  max-height: 50rem;
  border-radius: var(--standard-border-radius);
  background-color: var(--white);

  &__body-fix-height {
    height: 31.6667rem !important;
  }

  &__header {
    height: 5.583rem;
  }

  &__criteria {
    padding-bottom: var(--spacing-unit-x2);

    &-word-break {
      word-break: break-word;
    }
  }

  &__input {
    &-wrapper {
      margin-bottom: var(--spacing-unit-x4);
    }

    &__container {
      .input__icon {
        fill: var(--text-gray);
        top: unset;
        bottom: 0.8333rem;
        transform: unset;
        left: calc(var(--standard-border-width) + var(--spacing-unit-x2));
      }
    }

    &-description {
      margin-top: calc(var(--spacing-unit) + var(--half-spacing-unit));

      &-margin-bottom {
        margin-bottom: var(--spacing-unit-x4);
      }
    }
  }

  &__toggle-margin-bottom {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__form-row {
    & > .label {
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__select {
    padding-left: var(--spacing-unit-x7);
    background-repeat: no-repeat;
    background-position: calc(100% - var(--spacing-unit-x2)) center, var(--spacing-unit-x2) center;
    background-size: 0.75rem 0.46rem, 1.5rem 1.33rem;
  }

  &__tags {
    display: flex;
    gap: calc(var(--half-spacing-unit) + var(--spacing-unit));
    flex-wrap: wrap;
  }

  &__tag {
    height: var(--spacing-unit-x4);
  }

  &.modal {
    overflow: visible;
  }

  &__folder-drop-down {
    & .nested-dropdown {
      &__wrapper {
        position: unset;
        overflow: visible;
      }
    }
  }

  &__email-settings {
    margin-top: var(--spacing-unit);

    .checkboxgroup__container {
      gap: unset;
    }

    &-suppression {
      margin-left: var(--spacing-unit-x6);
    }

    &-tooltip {
      max-width: 25rem;
      padding: var(--spacing-unit) var(--spacing-unit-x2);

      &__trigger-wrapper {
        margin-left: var(--spacing-unit);
        display: flex;
      }
    }
  }
}
