.email-composer-preview-content {
  height: calc(100% - 6.083rem);
  display: flex;

  &__wrapper {
    min-width: calc(var(--spacing-unit-x20) * 5);
    display: flex;
    flex-direction: column;
    flex: min-content;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x3);
    align-items: center;
    border-bottom: var(--standard-border);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

    &-title {
      gap: var(--half-spacing-unit);
    }

    &__title-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__container {
    background-color: var(--page-back);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: max-content;
    overflow: auto;

    &-info {
      gap: var(--spacing-unit);
      padding: var(--spacing-unit-x2);
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    border-top: var(--standard-border);
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

    &-toggles {
      display: flex;
      align-items: center;

      &-wrapper {
        display: flex;
        align-items: center;
      }

      &-divider {
        width: var(--standard-border-width);
        height: var(--spacing-unit-x4);
        background-color: var(--border-gray);
        margin-right: var(--spacing-unit-x2);
      }
    }

    &-code-button {
      &:hover {
        .svg {
          fill: var(--darker-teal);
        }
      }

      .svg {
        fill: var(--text-teal);
        width: 1.3334rem;
        height: 1.3334rem;
        margin-right: var(--spacing-unit);
      }
    }
  }
}
