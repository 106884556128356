.message-picker {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 10rem;

  &__header,
  &__row {
    width: 100%;
    display: flex;

    &:hover .message-picker__preview {
      display: inline-flex;
    }
  }

  &__col {
    display: flex;

    & > label {
      padding: var(--spacing-unit) var(--spacing-unit) var(--spacing-unit) 0;
      font-size: var(--large-font-size);
      color: var(--text-gray);
      display: flex;
      align-items: center;
      flex: 1 1 100%;
      flex-wrap: nowrap;
      overflow: hidden;
    }

    .message-picker__text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 1.5rem;
    }

    &:nth-child(1) {
      flex: 0 0 3rem;
    }

    &:nth-child(2) {
      flex: 1 1 100%;
    }

    &:nth-child(3) {
      flex: 0 0 8rem;
      margin-right: var(--spacing-unit-x4);
    }
  }

  &__message {
    &-title {
      padding-left: var(--spacing-unit-x3);
    }

    &-pill {
      margin-left: calc(var(--spacing-unit-x3) / 2);
    }
  }

  &__mail-icon {
    fill: var(--gray);
  }

  &__header {
    border-bottom: var(--standard-border);

    .message-picker__row .message-picker__col {
      & > label {
        border-top: none;
        color: var(--gray);
        font-size: var(--smallest-font-size);
        padding: var(--spacing-unit-x2) 0;
      }

      &:nth-child(2) > label {
        flex: 0 0 auto;
        font-weight: var(--font-weight-bold);
      }

      &--folders:nth-child(1) > label {
        padding-left: var(--spacing-unit-x5);
      }
    }
  }

  &__row {
    width: calc(100% - var(--spacing-unit-x2));
    display: flex;
    padding-left: var(--spacing-unit-x2);
    font-size: var(--large-font-size);

    .message-picker__col {
      &-hide {
        display: none;
      }

      & > label {
        border-top: var(--standard-border);
      }

      &:nth-child(1) > label {
        font-weight: var(--font-weight-medium);
      }

      &:nth-child(2) > label {
        font-weight: var(--font-weight-medium);
      }
    }

    &:nth-child(2) .message-picker__col > label {
      border-top: none;
    }
  }

  &__folder {
    width: 100%;

    .caret-icon {
      margin: 0 0.5rem;
    }

    &-row {
      width: calc(100% - var(--spacing-unit-x4));
      padding: var(--spacing-unit) 0;
      display: flex;
      align-items: center;
      margin-right: var(--spacing-unit-x2);
      margin-left: var(--spacing-unit-x2);
      border-top: var(--standard-border);
      font-size: var(--large-font-size);
      font-weight: var(--font-weight-medium);
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    .message-picker__row {
      padding-left: var(--spacing-unit-x2);
    }

    .message-picker__row .message-picker__col > label {
      border-top: var(--standard-border);

      &:nth-child(1) {
        padding-left: var(--spacing-unit-x3);
      }
    }

    &:nth-child(2) .message-picker__folder-row {
      border-top: none;
    }
  }

  &__icon-holder {
    flex: 0 0 2.4rem;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      max-width: 1.333rem;
      max-height: 1.333rem;
    }
  }

  &__checked-icon {
    width: 1.333rem;
    height: 1.333rem;
    border-radius: 2rem;
    background-color: var(--teal);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      fill: var(--white);
      width: 0.735rem;
      height: 0.673rem;
      position: absolute;

      &:nth-child(1) {
        z-index: 1;
      }

      &:nth-child(2) {
        fill: var(--focus-teal);
        position: relative;
        top: 0.1rem;
      }
    }
  }

  &__preview {
    margin-left: var(--spacing-unit-x2);
    cursor: pointer;
    display: none;
  }
}
