
.data-card {
  width: 100%;
  height: var(--spacing-unit-x16);
  min-width: calc(var(--spacing-unit-x29) * 2 + var(--spacing-unit));
  padding: 0 !important;
  background-color: var(--white);
  border: var(--standard-border);
  border-radius: 0.333rem;

  &__clickable {
    &:hover {
      cursor: pointer;
      box-shadow: var(--locked-columns-box-shadow);
    }
  }

  &__background {
    height: 100%;
    width: 100%;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4) calc(var(--spacing-unit-x2) + var(--half-spacing-unit)) var(--spacing-unit-x4);
    background-repeat: no-repeat;
    background-size: contain;
  }
}
