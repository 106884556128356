.query-segment-builder {
  &__switch {
    &-info-tooltip {
      width: 25rem;
    }

    &-container {
      display: flex;
      justify-content: space-between;

      &-right,
      &-left {
        display: flex;
        align-items: center;
        gap: var(--spacing-unit);
      }
    }
  }

  &__definition {
    &-container {
      margin-top: var(--spacing-unit-x2);
      margin-bottom: var(--spacing-unit-x4);
      display: flex;
      flex-direction: column;

      > div:not(:first-child) {
        padding-top: var(--spacing-unit);
      }

      > div:not(:last-child) {
        padding-bottom: var(--spacing-unit);
      }
    }

    &-empty {
      border: var(--thick-border-width) dotted;
      border-radius: var(--standard-border-radius);
      border-color: var(--hover-indicator-background);
      background-color: var(--chip-base);
      padding: 1.667rem var(--spacing-unit-x4);
    }
  }

  &__add-button {
    &.button--float .svg {
      width: 1.333rem;
      height: 1.333rem;
      margin-right: var(--spacing-unit);
    }

    &-container {
      display: flex;
    }
  }
}
