
.folder-list-picker {
  display: grid;
  width: 100%;
  height: 100%;
  max-height: inherit;
  min-height: 10rem;
  flex-wrap: nowrap;
  grid-template-columns: 18rem 100%;
  grid-template-rows: 100%;

  &__folders {
    position: relative;
    flex: 0 0 18rem;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: var(--standard-border);
    padding: 0 var(--spacing-unit-x2) 0 var(--spacing-unit-x4);
    height: 100%;
    max-height: inherit;

    &-header {
      padding: var(--spacing-unit-x2) 0 var(--spacing-unit-x2) var(--spacing-unit-x2);
    }

    &-list {
      position: relative;
      min-height: 10rem;
    }

    &-row {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      font-size: var(--default-font-size);
      overflow: hidden;

      &:hover {
        text-decoration: none;
        font-weight: var(--font-weight-medium);
      }

      &-name {
        display: block;
        align-items: center;
        flex: 0 1 100%;
        padding: var(--spacing-unit) 0 var(--spacing-unit) calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-count {
        flex: 0 0 2rem;
        padding: var(--spacing-unit);
        text-align: right;
      }

      &--current {
        font-weight: var(--font-weight-medium);
        background-color: var(--highlight-gray);
      }
    }
  }

  &__folder-icon {
    width: 1.166rem;
    margin-right: var(--spacing-unit);
    fill: var(--gray-icon);

    &--current {
      fill: var(--teal);
    }
  }

  .list-picker,
  .message-picker {
    width: calc(100% - 18rem);
    height: 100%;
  }
}
