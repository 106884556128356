.custom-link__customer-support {
  &-title {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-unit-x4);

    .tooltip__trigger {
      margin-left: calc(var(--spacing-unit-x3) / 2);

      > div {
        display: flex;

        .svg {
          fill: var(--light-gray);

          &:hover {
            fill: var(--text-gray);
          }
        }
      }
    }
  }

  &__custom-link-svg {
    fill: var(--gray);
    width: calc(var(--spacing-unit-x10) / 3);
    height: calc(var(--spacing-unit-x10) / 3);
    margin-right: var(--spacing-unit-x2);
  }

  &__info {
    margin-top: var(--spacing-unit-x3);

    &-section {
      display: flex;

      & + & {
        margin-top: var(--spacing-unit);
      }

      :first-child {
        width: var(--spacing-unit-x8);
      }
    }
  }

  &__caution {
    .caution__text {
      display: block;
    }
  }

  &__setup-button {
    margin-top: var(--spacing-unit-x4);
  }
}
