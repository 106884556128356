.global-composer-header-bottom {
  &__tabs {
    background-color: var(--page-back);

    &__review-pill {
      background: var(--remove-red);
      color: var(--white) !important;
      margin: 0 calc(var(--spacing-unit) + var(--half-spacing-unit));
      padding: var(--half-spacing-unit) calc(var(--spacing-unit) + var(--half-spacing-unit));
    }

    &__list {
      border-top: var(--standard-border);
      border-bottom: var(--standard-border);
      padding-left: var(--spacing-unit-x4);
      padding-right: var(--spacing-unit-x4);
      background-color: var(--hover-row-background-teal);
      position: sticky;
      top: var(--position-sticky-global-header-composer);
      z-index: var(--z-index-composer-tablist);

      .tabs-ao__tab {
        padding-top: var(--spacing-unit-x4);
      }
    }

    &__customer-care {
      &__list {
        border-top: var(--standard-border);
        border-bottom: var(--standard-border);
        padding-left: var(--spacing-unit-x4);
        padding-right: var(--spacing-unit-x4);
        background-color: var(--hover-row-background-teal);
        position: sticky;
        z-index: var(--z-index-composer-tablist);
        top: var(--position-sticky-custom-care-header);

        .tabs-ao__tab {
          padding-top: var(--spacing-unit-x4);
        }
      }
    }

    .tabs-ao {
      &__list-with-content {
        &__triggers {
          display: flex;
        }

        &__content {
          display: flex;
          gap: var(--spacing-unit-x2);

          .button {
            padding: var(--spacing-unit) var(--spacing-unit-x3);
          }
        }
      }

      &__panel {
        margin-top: 0;
      }
    }
  }

  &__header-button {
    height: var(--spacing-unit-x6);
  }

  .loader.button-with-loader__loader {
    display: flex;
    min-width: var(--spacing-unit-x21);
    align-items: center;
    justify-content: center;
  }
}
