.section-headline {
  font-size: var(--larger-font-size);
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: var(--line-height-larger);
  margin-bottom: var(--spacing-unit-x4);

  &__small {
    margin-bottom: var(--spacing-unit-x2);
  }
}
