.form-selector-modal {
  width: 50rem;

  .loader {
    width: 100%;
  }
  
  &__subheader {
    margin-bottom: var(--spacing-unit-x3);
  }
    
  &__row {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x2) calc(var(--spacing-unit-x6) - var(--half-spacing-unit)) var(--spacing-unit-x2) var(--spacing-unit-x3);
    position: relative;
    cursor: pointer;
   
    & + & {
      margin-top: var(--spacing-unit-x2);
    }
      
    &:hover:not(&-selected) {
      border-color: var(--border-gray);
      background-color: var(--selectable-row-hover);
    }
     
    &-selected {
      border-color: var(--teal);
      background-color: var(--hover-row-background-teal);
    }
     
    &-form-name {
      overflow: hidden;
      margin-bottom: var(--half-spacing-unit);
    }
      
    &-radio-button {
      position: absolute;
      top: calc(var(--spacing-unit) + var(--half-spacing-unit));
      right: calc(var(--spacing-unit) + var(--half-spacing-unit));
      margin: 0;
      height: var(--radio-size);
  
      label {
        margin: 0;
      }
    }
  }

  &__empty-state {
    align-items: center;
    background-color: var(--lighter-off-white);
    border-radius: var(--standard-border-radius);
    border: var(--standard-border);
    display: flex;
    justify-content: center;
    padding: var(--spacing-unit-x4) 0;
  }
}
