.test-webhook-modal {
  width: 51rem;

  .modal-header {
    &__close-button {
      right: var(--spacing-unit-x2);
      top: var(--spacing-unit-x5);
    }
     
    &__inner {
      padding: var(--spacing-unit-x4) var(--spacing-unit-x6) 0 var(--spacing-unit-x6);
    }
  }

  .modal-footer {
    &__inner {
      padding: var(--spacing-unit-x2) var(--spacing-unit-x6) var(--spacing-unit-x3) var(--spacing-unit-x6);
    } 
  }

  .loader--page svg {
    width: var(--spacing-unit-x8);
  }

  .modal-body {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x6);
  }

  &__header {
    background-color: unset;
  
    &-hidden {
      display: none;
    }
  }

  &__retry-button {
    height: var(--spacing-unit-x6);
  
    svg {
      margin-bottom: 0 !important;
    }
  }

  &__webhook-created-message {
    margin-bottom: var(--spacing-unit-x4);
  }
   
  &__caution {
    align-items: flex-start;
    margin-top: var(--spacing-unit-x4);
  }
  
  &__conf-container {
    padding: var(--spacing-unit-x4);
  }
   
  &__container {
    padding: var(--spacing-unit-x4);
    padding-bottom: 0;
  }
   
  &__icons {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: var(--spacing-unit-x2);
  }
   
  &__arrow-icon {
    margin: var(--spacing-unit-x3);
  }
   
  &__description {
    padding: var(--spacing-unit-x2) 0 var(--spacing-unit-x4) 0;
  }
   
  &__test-button {
    margin-bottom: var(--spacing-unit-x2);    

    &-loading {
      background-color: var(--hover-teal) !important;
    }

    .loader {
      height: var(--spacing-unit-x4);
    }
  }

  &__waiting_test {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--spacing-unit-x16) 0;
  }
   
  &__footer {
    .modal-footer__inner {
      display: flex;
      flex-direction: column;
      row-gap: var(--spacing-unit-x2);
    }
    
    .button {
      width: 100%;
      margin-left: 0 !important;
    }
  }
}
