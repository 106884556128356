:root {
  --font-regular: 'Open Sans', helvetica, arial, sans-serif;
  --font-weight-lightest: 100;
  --font-weight-lighter: 300;
  --font-weight-medium-light: 400;
  --font-weight-semi-medium: 500;
  --font-weight-medium: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;

  /* stylelint-disable */
  --dot: 6px;
  --smallest-font-size: 10px;
  --small-font-size: 11px;
  --default-font-size: 12px;
  --medium-font-size: 12px;
  --medium-large-font-size: 13px;
  --input-font-size: 13px;
  --large-font-size: 14px;
  --large-medium-font-size: 15px;
  --larger-font-size: 16px;

  --sub-header-font-size: 18px;
  --small-page-header-font: 21px;
  --page-header-font: 24px;
  --large-page-header-font: 28px;
  --data-card-text: 30px;
  --extra-large-font-size: 32px;

  --menu-only-font-size: 13px;

  --line-height-smallest: 10px;
  --line-height-small: 12px;
  --line-height-xsmall: 13px;
  --line-height-smallish: 14px;
  --line-height-sub-header: 15px;
  --line-height-standard: 16px;
  --line-height-medium-small: 17px;
  --line-height-medium: 18px;
  --line-height-medium-large: 19px;
  --line-height-large: 20px;
  --line-height-large-medium: 22px;
  --line-height-larger: 24px;
  --line-height-banner: 26px;
  --line-height-extra-large: 30px;
  --line-height-headline-page-small: 31px;
  --line-height-title-page: 36px;
  --line-height-title-large: 38px;
  --line-height-headline-page: 41px;
  --line-height-table-actions: 53px;

  --radio-button-dimension: 16px;
  --people-background-image-size: 334px;

  /* stylelint-enable */
}
