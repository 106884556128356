.info-hover-card__content {
  max-height: 31rem;
  width: 28rem;
}

.form-info-hover {
  &__children {
    padding: 0;
  }

  &__name {
    width: 24rem;
    margin-bottom: var(--spacing-unit-x2);
  }

  &__scroll {
    height: var(--spacing-unit-x28);
  }

  &__body,
  &__footer {
    padding: 0;

    &-section {
      border-bottom: var(--standard-border);

      &-title {
        padding: var(--spacing-unit-x3) var(--spacing-unit-x4) 0;
      }

      &-line {
        padding: var(--spacing-unit) var(--spacing-unit-x4) var(--spacing-unit-x3) var(--spacing-unit-x4);
        display: flex;
        align-items: center;
        width: 28rem;

        .svg {
          margin-right: var(--spacing-unit);
        }

        & & {
          padding: 0;
        }

        &-first {
          padding-bottom: 0;
        }
      }
    }
  }

  &__footer {
    &-section-line {
      gap: calc(1rem / 12);
    }
  }

  &__description-long {
    padding-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* stylelint-enable */

    &-extended {
      display: block;
    }
  }

  &__show-link {
    padding: var(--spacing-unit) var(--spacing-unit-x4) var(--spacing-unit-x3);
  }
}
