.program-contacts {
  display: block;

  &__position-inner > div {
    min-width: calc(var(--positioning-min-width) + var(--spacing-unit-x8));
    margin-bottom: var(--spacing-unit-x4);
  }

  &__table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x2);

    &__search-container {
      display: flex;
      flex: 0 0 33rem;

      &__button {
        flex: 0 0 3rem;
      }

      &__search-bar {
        flex: 1 0 auto;
      }
    }
  }

  .tr .table__row-actions {
    margin-right: var(--spacing-unit-x4);
  }

  &__tooltip {
    padding: var(--half-spacing-unit) var(--spacing-unit-x2);
  }
}
