.cell-value {
  display: flex;
  gap: var(--spacing-unit-x2);
  align-items: center;
  width: 100%;

  &__flexCell {
    display: flex;
    align-items: center;
    grid-gap: calc(var(--spacing-unit) + var(--half-spacing-unit));
  }
}
