.tag-manager {
  &__action {
    border-top: var(--standard-border);
    padding: 0.75rem 0 var(--half-spacing-unit) 0;

    &-button {
      align-items: center;
      display: flex;
      gap: var(--spacing-unit-x2);
      padding: var(--spacing-unit) var(--spacing-unit-x2);
      width: 100%;

      &:hover {
        text-decoration: none;
      }

      &:disabled {
        cursor: initial;
      }

      &-active:hover,
      &-active:focus {
        background-color: var(--lighter-teal);
      }
    }

    &-icon {
      fill: var(--button-hover);

      &-active {
        fill: var(--text-gray);
      }
    }
  }

  &__button-disabled {
    pointer-events: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 var(--spacing-unit-x2);
    max-height: 36.3rem;
    min-height: 22rem;
    width: 25rem;
  }

  &__division {
    border: var(--standard-border);
    margin-bottom: var(--spacing-unit-x3);
  }

  &__drop-down {
    display: block;
  }

  &__error {
    margin-top: var(--spacing-unit-x2);
  }

  &__footer {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin: var(--spacing-unit) 0 var(--spacing-unit-x3) 0;

    &-tag-creation {
      display: flex;
      gap: var(--spacing-unit);
      margin-top: var(--spacing-unit-x3);

      &-button {
        > button {
          padding: calc(var(--spacing-unit) + var(--half-spacing-unit)) var(--spacing-unit-x2);
          min-height: var(--spacing-unit-x5);
          min-width: var(--spacing-unit-x5);
          border: none;

          &:hover {
            cursor: initial;
          }
        }

        &-enabled > button:hover {
          cursor: pointer;
        }

        &-icon {
          fill: var(--text-gray) !important;

          &-enabled {
            fill: var(--white) !important;
            filter: drop-shadow(var(--primary-button-text-shadow));
          }
        }
      }

      &-info {
        display: flex;
        gap: var(--half-spacing-unit);
        margin-top: var(--spacing-unit-x2);

        &-enter {
          background-color: var(--lighter-off-white);
          border-radius: var(--legend-border-radius);
          display: inline;
          padding: 0 var(--spacing-unit);
        }
      }
    }
  }

  &__tags {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
    max-height: 17rem;
    position: relative;

    &-gradient {
      background: linear-gradient(180deg, transparent 0%, var(--white) 100%);
      bottom: 0;
      height: var(--spacing-unit-x3);
      pointer-events: none;
      position: absolute;
      width: 100%;
    }

    &-wrapper {
      flex-grow: 1;
      margin-top: var(--spacing-unit-x3);

      &-bottom {
        min-height: var(--half-spacing-unit);
        width: 100%;
      }

      & .scroll-area__viewport > div {
        display: block !important;
        min-width: auto !important;
      }
    }
  }

  &__tooltip-disabled-appy-button {
    width: 100%;

    &:hover {
      cursor: initial;
    }
  }

  &__empty-state {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
