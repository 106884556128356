.form-and-templates-tabbed-container {
  &__upgrade-banner {
    &-body {
      display: flex;
      flex-direction: column;
    }
    
    &-displayed {
      margin-top: 0;
    }

    &-text {
      max-width: 70rem;
    }
  }
}