.program-general-settings {
  margin-bottom: var(--spacing-unit-x4);
  display: flex;
  gap: var(--spacing-unit-x4);

  &__table__table-holder,
  .table__paginator {
    border-radius: unset;
    border-right: none;
    border-left: none;
  }

  &__table__table-holder,
  .table__paginator,
  .paginator {
    min-width: initial;
    max-width: initial;
  }

  &__table__table-holder {
    border-top: none;
    border-bottom: none;

    &.table__has-pagination {
      & .tr:last-of-type {
        & .td {
          border-bottom: none;
        }
      }
    }

    & .tr:last-of-type {
      & .td {
        border-bottom: var(--standard-border);
      }
    }
  }

  &__row {
    margin-bottom: var(--spacing-unit-x4);

    .input,
    .textarea,
    select {
      background-color: var(--white);
      width: 22rem;
    }
  }

  &__no-sources {
    display: flex;
    align-items: center;

    .svg {
      margin-left: var(--spacing-unit);
      fill: var(--remove-red);
    }
  }

  &__sources {
    padding: var(--spacing-unit-x3) 0 var(--spacing-unit-x4) 0;

    .container__title {
      padding: 0 var(--spacing-unit-x4);
    }

    &__header {
      padding: 0 var(--spacing-unit-x4) 0 var(--spacing-unit-x4);
    }

    &-add {
      margin-bottom: var(--spacing-unit-x2);
    }

    &-select {
      margin-left: var(--spacing-unit-x2);
    }

    &-select-button--disabled {
      opacity: 0.5;

      &:hover {
        cursor: default;
      }
    }
  }

  &__source-list-empty {
    width: 30rem;
  }

  &__source-list {
    padding: var(--spacing-unit) var(--spacing-unit-x2);
    border-bottom: var(--standard-border);
    font-weight: var(--font-weight-medium);
    font-size: var(--large-font-size);
    line-height: 2rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &:first-of-type {
      border-top: var(--standard-border);
    }

    span {
      flex: 1 1 100%;
    }

    button {
      flex: 0 0 3rem;

      svg {
        height: 1rem;
        width: 1rem;
        fill: var(--error);
      }
    }
  }

  &__info-text {
    padding-left: var(--spacing-unit-x4);
  }

  &__tip {
    margin-left: var(--spacing-unit);
  }

  &__schedule-row {
    &-options,
    &-checkbox {
      display: inline-flex;
      gap: var(--spacing-unit-x2);
    }

    &-checkbox {
      align-items: center;
      margin-top: var(--spacing-unit-x2);
    }
  }

  &__schedule-col {
    padding-right: var(--spacing-unit-x2);
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    padding-bottom: var(--spacing-unit);

    &--not-after {
      justify-content: right;
    }

    &--1,
    &--2 {
      flex: 0 0 12.75rem;
    }

    &--3 {
      flex: 0 0 8rem;

      label {
        margin: var(--spacing-unit-x3) 0 var(--spacing-unit) 0;
        padding-right: 0;
      }
    }

    &--label {
      padding-bottom: 0;
    }
  }

  &__list-button {
    margin: var(--spacing-unit-x4) var(--spacing-unit-x2) var(--spacing-unit-x2);
  }

  &__options {
    column-count: 2;
    column-gap: var(--spacing-unit-x3);

    &__item {
      break-inside: avoid-column;
      padding-bottom: var(--spacing-unit-x2);

      .info-text {
        padding-left: var(--spacing-unit-x4);
      }
    }
  }

  &__info-icon {
    margin-left: var(--half-spacing-unit);
    fill: var(--light-gray);

    &:hover {
      fill: var(--text-gray);
    }
  }

  &__timezone-select {
    width: 28.75rem;
  }

  &__alternate-field {
    display: block;
    margin: var(--spacing-unit) 0 var(--spacing-unit) var(--spacing-unit-x4);
    width: 15rem;
  }
}

@media (max-width: 70.4rem) {
  .program-general-settings__schedule-row-options {
    gap: var(--spacing-unit);
  }

  .program-general-settings {
    .checkbox {
      min-width: var(--checkbox-size);
    }

    .checkbox__container {
      align-items: flex-start;
      line-height: var(--line-height-standard);
    }
  }
}
