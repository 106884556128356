.saved-rows-copy {
  &__overview {
    padding: var(--spacing-unit-x4);
    background-color: var(--white);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    margin-top: var(--spacing-unit-x8);
    display: flex;
    flex-direction: column;

    &-content {
      margin-top: var(--spacing-unit-x2);
      display: flex;
      gap: calc(var(--spacing-unit-x5) / 3);

      &-item {
        display: flex;
        flex-direction: column;
        border-radius: var(--standard-border-radius);
        background-color: var(--light-off-white);
        width: 18.333rem;
        height: 7.5rem;
        padding: var(--spacing-unit-x2) calc(var(--spacing-unit-x7) / 3) var(--spacing-unit-x4) calc(var(--spacing-unit-x7) / 3);

        &-count {
          margin-top: calc(var(--spacing-unit-x7) / 3);
        }
      }
    }

    &-button {
      margin-top: var(--spacing-unit-x4);
      width: fit-content;
    }
  }

  &__info {
    margin-top: var(--spacing-unit-x4);
  }
}
