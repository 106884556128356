.crm-entity {
  &__outer {
    margin-top: var(--spacing-unit-x6);
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;
    line-height: var(--line-height-standard);
    align-items: center;
  }

  &__row .pill {
    font-size: var(--default-font-size);
    line-height: var(--line-height-small);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0.4rem;
    font-weight: var(--font-weight-lighter);
  }

  &__col_count {
    padding-left: var(--spacing-unit-x4);
    flex: 1 1 50%;
    align-items: center;
    display: flex;
    flex-flow: column;
  }

  &__col_count_label {
    background-color: var(--highlight-gray);
    padding-left: var(--spacing-unit-x2);
    padding-right: var(--spacing-unit-x2);
    cursor: pointer;
  }

  &__tab_entity {
    overflow-y: scroll;
  }

  &__info-text {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__divider {
    border-top: var(--standard-border);
  }

  &__entity-data {
    padding-left: var(--spacing-unit-x2);

    .thead {
      .tr {
        height: unset;
        padding-bottom: var(--spacing-unit);
        cursor: default;
      }
    }

    &__item {
      padding-right: 0;
      padding-left: 0;
    }

    &__button {
      padding: var(--spacing-unit-x3) var(--spacing-unit-x3) var(--spacing-unit-x3) 0;
    }
  }

  &__pill {
    &.button {
      padding: 0;
    }
  }

  &__pill-count {
    padding-left: var(--spacing-unit-x2);
    font-weight: 600;
    color: var(--text-teal) !important;

    &:hover {
      text-decoration: underline;
    }
  }

  &__selected-fields-count {
    padding-left: var(--spacing-unit-x);
    font-weight: normal;
    color: var(--light-gray) !important;
  }

  &__entity-display {
    display: flex;
    align-items: center;
  }

  &--redesigned {
    .crm-entity__divider {
      display: none;
    }

    .crm-entity__entity-data {
      padding-left: 0;
    }

    .crm-entity__row {
      padding: 1rem;
      justify-content: space-between;
      width: 100%;
      padding-right: 0;
    }

    .accordion-radix__item {
      padding: 0.5rem 1.5rem;
      margin-bottom: 1rem;
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
    }

    .entity-loader__col_count {
      align-items: flex-start;
      margin-left: 3.5rem;
      margin-top: 0;
    }

    .accordion-radix__button {
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding-right: 0;
    }

    .filterable-list-pair {
      padding-left: 3rem;
    }

    .filterable-list__list {
      border-top: var(--standard-border-radius);
      margin-bottom: 2rem;
    }

    .toggle {
      margin-right: 0;
    }

    .entity-loader__col_count_label {
      margin: 0;
    }

    .input__container {
      input {
        border-top-left-radius: var(--standard-border-radius);
      }
    }

    .filterable-list__title {
      border-top-right-radius: var(--standard-border-radius);
    }

    .filterable-list-pair__left ul {
      border-bottom-left-radius: var(--standard-border-radius);
    }

    .filterable-list-pair__right ul {
      border-bottom-right-radius: var(--standard-border-radius);
    }
  }
}
