.edit-alert-step {
  width: 50rem;
  line-height: 1.25rem;

  &__emails {
    margin-bottom: var(--spacing-unit-x2);
    width: 50%;
  }

  &__users {
    width: 50%;
  }

  &__message {
    width: 100%;
  }

  &__row {
    .input__container {
      display: unset;
    }

    margin-bottom: var(--spacing-unit-x4);

    &-select {
      display: flex;
      align-items: center;
      margin-bottom: var(--spacing-unit-x2);
    }
  }

  &__border {
    border: var(--standard-border);
    padding: var(--spacing-unit-x2);
  }

  svg {
    margin-right: var(--spacing-unit);
  }

  &__delete-icon {
    fill: var(--error);
  }

  &__disclaimer {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__more-options {
    margin-top: var(--spacing-unit-x3);

    &,
    &__content {
      width: 100%;
    }

    &-personalization {
      position: relative;

      &-button {
        align-self: flex-end;

        &:has(button[disabled]) > div {
          cursor: not-allowed;
        }

        button {
          padding: var(--spacing-unit);
          margin-bottom: var(--half-spacing-unit);

          svg {
            margin: 0 !important;
          }
        }

        button[disabled] {
          pointer-events: none;

          svg {
            fill: var(--light-gray) !important;
          }
        }
      }

      &__content {
        margin-top: var(--spacing-unit);
        display: flex;
        flex-direction: column;
      }
    }
  }
}
