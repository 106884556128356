.report-detail {
  display: flex;
  flex-direction: column;
  width: 58.5rem;

  .container {
    padding-bottom: 0;
  }

  &__table-holder,
  .table__paginator,
  .paginator {
    min-width: 53rem;
    max-width: 59rem;
  }

  &__table-holder,
  .table__paginator {
    border: none;
  }

  .chart {
    border-radius: 0 0 var(--standard-border-radius) var(--standard-border-radius);

    & .highcharts-container svg {
      width: 100%;
    }
  }

  &__empty {
    border-top: 0;
    border-radius: 0 0 var(--standard-border-radius) var(--standard-border-radius);
  }

  &__table-holder {
    .thead .tr.table__global-filter-container {
      height: auto;
    }

    .table {
      &__global-filter {
        display: flex;
        align-items: center;

        &-info-text {
          font-size: var(--large-font-size);
        }
      }

      &__empty-text {
        text-align: center;
        font-size: var(--larger-font-size);
        color: var(--light-gray);
        margin-left: 0;
        margin-top: 10rem;
      }

      &__th {
        padding-top: var(--spacing-unit-x2);
      }

      &__paginator {
        border-top: var(--standard-border);
        padding-bottom: 0;
      }
    }
  }

  > :first-child {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__info-text {
    font-weight: var(--font-weight-medium);
  }

  &__chart-container- {
    /* stylelint-disable */

    &summary {
      border-top: 1px solid var(--border-sent);
    }

    &delivered {
      border-top: 1px solid var(--border-delivered);
    }

    &clicked {
      border-top: 1px solid var(--border-clicked);
    }

    &replied {
      border-top: 1px solid var(--border-replied);
    }

    &failed {
      border-top: 1px solid var(--border-failed);
    }

    &optedout {
      border-top: 1px solid var(--border-optedout);
    }

    /* stylelint-enable */
  }

  &__name-none {
    font-style: italic;
  }
}
