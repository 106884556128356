.crm-contacts {
  padding: 0;
  border: 0;
  background-color: transparent;
  margin-top: var(--spacing-unit);

  &__panel-title {
    font-size: var(--larger-font-size);
    font-weight: var(--font-weight-lighter);
    line-height: var(--line-height-larger);
    padding-top: var(--spacing-unit-x3);
  }

  &__list-header {
    display: flex;
    justify-content: space-between;
    line-height: var(--line-height-standard);
    font-weight: var(--font-weight-bold);
  }

  &__standard {
    background-color: var(--white);
    margin-bottom: var(--spacing-unit-x2);
  }

  &__custom {
    background-color: var(--white);
    margin-bottom: var(--spacing-unit-x2);
  }

  &__standard-list {
    padding-top: var(--spacing-unit-x6);
  }

  &__custom-list {
    padding-top: var(--spacing-unit-x6);
  }

  .modal-header > &__validation-header {
    line-height: var(--line-height-larger);
    font-size: var(--page-header-font);
    display: unset;
    text-align: center;
    padding-top: var(--spacing-unit-x6);
    border-bottom: 0;
    padding-bottom: var(--spacing-unit);
    font-weight: var(--font-weight-medium);
  }

  .modal-body__inner &__save-header {
    text-align: center;
    font-size: var(--large-font-size);
    line-height: var(--line-height-large);
    color: var(--light-gray);
    padding-bottom: 0;
  }

  .modal-footer__inner &__validation-primary-button {
    box-shadow: #00BABE;
    background-color: var(--teal);
    line-height: var(--line-height-smallish);
    text-shadow: unset;
    border: 0;
    border-radius: var(--standard-border-radius);
  }
}
