.manage-urls-confirmation-modal {
  &__message {
    line-height: var(--line-height-large-medium);
  }

  &__generate-limit-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: var(--spacing-unit-x3);
    text-align: center;
  }

  .modal-footer__inner {
    padding: var(--spacing-unit-x8) var(--spacing-unit-x6) var(--spacing-unit-x4);
  }
}
