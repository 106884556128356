
.fragments-tab {
  display: block;
  width: 100%;

  &__inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: var(--spacing-unit-x4);

    svg {
      fill: var(--text-gray);
    }
  }

  &__multi-select {
    width: 20.67rem;
    margin-right: var(--spacing-unit-x2);
  }

  &__left-options,
  &__right-options {
    display: flex;
  }

  &__left-options {
    .text-link {
      align-self: center;
    }
  }


  &__right-options label {
    margin-right: 1.83rem;
  }

  .typography {
    align-self: center;
  }

  &__table-wrapper {
    background: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
