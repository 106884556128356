.tableV2-tfoot {
  width: 100%;
  display: table;
  table-layout: fixed;

  &__row {
    border-top: var(--standard-border);
    position: relative;
  }
}
