.new-source {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x3);
    
  &__button {
    gap: calc(10rem / 12); 
    width: fit-content;
  }

  &__container { 
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
  }

  &__replacement {
    align-items: center; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__restore-recommended {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: calc(4.75rem / 12) calc(var(--spacing-unit-x8) / 12);

    &:hover {
      cursor: pointer;
      background-color: var(--lighter-teal);
    }
  }

  &__source-box {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit) + var(--half-spacing-unit));
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

    &-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__create-segment-section {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-unit-x2);
    justify-content: space-between;
    padding: calc(2rem / 12) 0;

    .inputV2__wrapper {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
