
.folder-with-dnd {
  display: block;

  &__is-over {
    background-color: var(--hover-row-background-teal);
    outline: var(--standard-border-width) solid var(--border-teal);
    outline-offset: calc(var(--standard-border-width) * -1);
  }
}
