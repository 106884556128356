
.mapping-screen {
  &__second-text {
    margin: var(--spacing-unit-x3) 0 var(--spacing-unit-x4);
  }

  &__section {
    margin-bottom: var(--spacing-unit-x2);
    padding: var(--half-spacing-unit) var(--spacing-unit);
  }

  &__title {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &__row-container {
    margin-bottom: var(--spacing-unit-x2);

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: var(--import-contacts-row-box-shadow);
    }
  }

  &__grid {
    display: grid;
    align-items: start;
    grid-template-columns: minmax(var(--spacing-unit-x17), 100%)
      var(--spacing-unit-x4) 
      minmax(var(--spacing-unit-x19), 21rem)
      minmax(var(--spacing-unit-x13), 15.5rem)
      minmax(var(--spacing-unit-x13), 15.5rem);
    grid-gap: var(--spacing-unit-x4);

    &:hover &-column-preview-button {
      display: flex;
    }

    &-field-row {
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

      > div {
        height: var(--spacing-unit-x6);
      }
    }

    &-selected {
      background-color: var(--input-background);
    }

    &-left {
      grid-column-start: 1;
      grid-column-end: 2;
      color: var(--blue);
      background-color: var(--background-list-header);
    }

    &-right {
      grid-column-start: 3;
      grid-column-end: 6;
      color: var(--text-teal);
      background-color: var(--hover-row-background-teal);
    }

    &-wrapper {
      margin: 0 calc(var(--spacing-unit-x4) * -1);
    }

    &-headers {
      border-bottom: var(--columns-title-border);
      margin-bottom: var(--spacing-unit-x4);
    }

    &-header {
      margin-bottom: var(--spacing-unit);
      row-gap: 0;
    }

    &-column {
      grid-column-start: 1;
      grid-column-end: 2;
      display: flex;
      align-items: center;

      &-preview-button {
        margin-left: var(--spacing-unit-x2);
        display: none;
        gap: var(--spacing-unit);
      }
    }

    &-arrow {
      grid-column-start: 2;
      grid-column-end: 3;
    }

    &-existing {
      grid-column-start: 3;
      grid-column-end: 4;
      display: block;
      height: unset !important;

      & .checkbox__container {
        margin-top: 0.75rem;
      }
    }

    &-data {
      grid-column-start: 4;
      grid-column-end: 5;
    }

    &-merge {
      grid-column-start: 5;
      grid-column-end: 6;

      &-tooltip {
        margin-bottom: auto;
      }
    }

    &-checkbox {
      justify-content: center;
    }
  }

  &__text {
    text-transform: capitalize;
  }

  &__column-imported-fields {
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 0 var(--spacing-unit-x2);
    height: var(--spacing-unit-x8);
    display: flex;
    align-items: center;
    cursor: pointer;

    &.button {
      display: inline-block;
      text-align: left;
    }

    &:hover {
      border-radius: var(--standard-border-radius);
      background-color: var(--off-white-hover);
    }
  }

  &__icon- {
    &arrow {
      fill: var(--light-gray);
    }
  }

  &__format-container {
    text-align: center;
    margin-top: var(--spacing-unit);
    padding-bottom: var(--spacing-unit-x2);
  }

  &__format-wrapper {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x3);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    display: inline-flex;
  }

  &__format-text {
    float: left;
    text-align: left;
    width: 21rem;
  }

  &__format-options {
    float: right;
    width: var(--spacing-unit-x20);
  }

  &__column-tooltip {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
  }

  &__filename {
    display: flex;
    gap: var(--spacing-unit);
    margin-bottom: var(--spacing-unit-x2);
    align-items: center;

    .__trigger-wrapper {
      display: flex;
    }

    .section-headline {
      margin: 0;
    }

    .tooltip__trigger {
      display: flex;
    }
  }

  .selectv2__container {
    height: var(--spacing-unit-x6);
    width: 100%;
  }
}
