.create-alert-modal {
  height: 100vh;
  width: 100vw;

  &__name {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__name,
  &__description {
    > div {
      margin-bottom: var(--spacing-unit-x2);
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      min-height: var(--spacing-unit-x16);
    }
  }

  &__left-section {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x6);
    flex-grow: 1;

    > div {
      padding-bottom: var(--spacing-unit-x4);
    }

    &-schedule,
    &-filters {
      padding-top: var(--spacing-unit-x4);
    }

    &-details,
    &-schedule {
      border-bottom: var(--standard-border);
    }

    &-subtitle {
      margin: var(--spacing-unit-x2) 0 var(--spacing-unit-x4) 0;
    }
  }

  &__recipients {
    width: 35rem;
    padding: var(--spacing-unit-x4) var(--spacing-unit-x6) var(--spacing-unit-x4) 0;

    &-header {
      padding-left: var(--spacing-unit-x6);
      margin-bottom: var(--spacing-unit-x3);
    }
  }

  &__body {
    display: flex;
    scrollbar-gutter: stable;
  }

  &__header,
  &__footer .modal-footer__inner, {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x6);
  }

  &__footer .modal-footer__inner {
    justify-content: space-between;
  }

  &__divider {
    border-right: var(--standard-border);
    position: sticky;
    top: 0;
  }
}
