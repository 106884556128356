
.edit-crm-step {
  &__button {
    .svg {
      margin-right: var(--spacing-unit);
    }
  }

  &__new-records {
    height: 3rem;
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-unit);
    padding-bottom: 0;

    .checkbox__label {
      padding-right: 0;
      margin-right: 1.166666rem;
    }

    .radio__label {
      margin: 1rem 1rem 1rem 0;
    }
  }

  &__add-row {
    display: flex;

    .select {
      width: 27.75rem;
      margin-right: var(--spacing-unit-x2);
    }
  }

  &__add {
    margin-left: var(--spacing-unit-x3);

    .svg {
      margin: 0.2rem var(--spacing-unit) 0;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__header-text {
    border-bottom: var(--standard-border);
    margin-bottom: var(--spacing-unit-x2);
    padding-bottom: var(--spacing-unit-x2);
  }

  &__row {
    margin-bottom: var(--spacing-unit-x2);

    .input,
    .select {
      width: 28.75rem;
    }

    .button {
      display: inline-block;
    }

    .button.input__hidden {
      display: none;
    }

    .checkbox__label {
      top: -0.166666rem;
      padding-right: 0;
    }
  }

  &__existing-records {
    margin-top: var(--spacing-unit-x4);
  }

  &__indent {
    margin-left: var(--spacing-unit-x2);
  }

  &__section {
    border: var(--standard-border);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x2);
  }

  &__entity-header {
    margin-top: var(--spacing-unit-x4);
    font-size: var(--large-font-size);
    display: flex;
    justify-content: space-between;
  }
}
