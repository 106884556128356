.overview {
  display: grid;
  grid-template-columns: 1.9fr 3fr;
  gap: var(--spacing-unit-x4);

  .divider {
    margin: var(--spacing-unit-x4) 0;
    background-color: var(--border-gray);
    height: calc(var(--spacing-unit) / 6);
  }

  &__right-content {
    .overview__section {
      .overview__section-description {
        margin-bottom: var(--spacing-unit-x4);
      }

      .overview__section-item-header {
        display: flex;
        align-items: center;
        margin-bottom: calc(var(--spacing-unit) + var(--spacing-unit-x2) / 3);

        div {
          display: flex;
        }
      }

      .overview__section-item {
        justify-content: space-between;

        .overview__section-item {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &__section {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    background-color: var(--white);
    padding: var(--spacing-unit-x4);

    &-button {
      margin-top: var(--spacing-unit-x6);
    }

    &-header {
      margin-bottom: var(--spacing-unit-x4);
    }

    &-item {
      display: flex;
      align-items: center;

      &-value {
        max-width: 80%;
      }

      &-icon {
        margin-right: calc(var(--spacing-unit-x2) / 3 * 4);
      }

      &:not(:last-of-type) {
        margin-bottom: var(--spacing-unit-x2);
      }
    }
  }
}
