.create-landing-page-modal {
  width: 50rem;

  &__info {
    margin-bottom: var(--spacing-unit-x3);
  }

  .button--primary {
    width: var(--spacing-unit-x16);
  }

  &__title-with-icon {
    display: flex;
    gap: var(--spacing-unit);

    & .svg {
      display: flex;
      align-items: center;
    }
  }

  .radio-card-group {
    & .radio-card {
      width: 100%;
    }
  }
}
