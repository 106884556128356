.meta-data-pane-v2 {
  &__title {
    margin-bottom: var(--spacing-unit);
  }

  &__page-title {
    margin-bottom: var(--spacing-unit-x7);

    &-wrapper {
      margin-bottom: var(--spacing-unit-x3);
    }
  }

  &__fields {
    &-textarea {
      height: var(--spacing-unit-x16);
      margin-bottom: var(--spacing-unit-x3);
    }
  }

  &__upload-image {
    &-disabled {
      pointer-events: none;
    }

    &-container {
      &-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--spacing-unit);
      }
    }

    img {
      max-height: 22rem;
    }
  }

  &__preview {
    width: 50rem;

    &-image {
      max-width: 23.0833rem;
      max-height: var(--spacing-unit-x26);
    }

    &-meta {
      &-wrapper {
        max-width: 45.9167rem;
      }

      &-name {
        text-align: start;
        color: var(--medium-blue);
      }

      &-url {
        color: var(--comment-line-green);
      }
    }

    &-content {
      display: flex;
      gap: var(--spacing-unit-x2);
    }

    &-header-description {
      max-width: calc((var(--spacing-unit-x12) * var(--spacing-unit-x20)) + var(--spacing-unit-x2));
    }

    &-seo-block {
      display: flex;
      margin-bottom: var(--spacing-unit-x4);
      gap: var(--spacing-unit-x4);
    }

    &-edit-button {
      min-width: var(--spacing-unit-x14);
      justify-content: space-between;
    }

    &-toggle {
      margin-bottom: var(--spacing-unit-x3);
    }
  }

  &__visible-for-search {
    margin-top: var(--spacing-unit-x4);
  }
}
