.nested-table-row-dnd {
  position: relative;

  &-drag-source {
    display: none;
    position: absolute;
    left: var(--spacing-unit);
    top: 1.36rem;
    z-index: var(--z-index-1);
    cursor: grab;

    svg {
      fill: var(--button-gray);
    }
  }

  &:hover .nested-table-row-dnd-drag-source {
    display: block;
  }

  .nested-table-row-dnd-drag-source:hover {
    z-index: var(--z-index-9999);
  }

  &-dragging {
    background-color: var(--hover-row-background-teal);

    .nested-table-row-dnd-drag-source {
      svg {
        fill: none;
      }
    }
  }

  &-over&-cannot-drop {
    border-bottom: none;
  }

  &-over-top&-cannot-drop {
    border-top: none;
  }

  &-over {
    border-bottom: var(--thick-border-width) solid var(--disabled-teal);
  }

  &-over-top {
    border-top: var(--thick-border-width) solid var(--disabled-teal);
  }

  &:first-child {
    .nested-table-row-expanded {
      border-top: unset;
    }
  }
}
