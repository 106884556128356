.text-link {
  display: inline-flex;
  align-items: center;

  &__icon {
    margin-left: var(--spacing-unit);

    &--left {
      margin-right: var(--spacing-unit);
      margin-left: none;
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0.16rem 0.16rem var(--browser-highlight); /* keyboard-only focus styles */
  }

  &__underline {
    .typography {
      text-decoration: underline;
    }
  }
}
