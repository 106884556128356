
.message-preview-visual {
  border: var(--standard-border-heavy-dark);
  border-radius: var(--spacing-unit-x4);
  overflow: hidden;
  width: 22.5rem;
  min-height: 45rem;
  height: 45rem;
  margin: auto;

  .scroll-area__viewport > div {
    height: 100%;
  }

  &__overflow {
    height: 100%;
    line-height: var(--line-height-standard);
  }

  &__empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--spacing-unit-x4);
  }

  &__sms-block {
    border-radius: 1.3333rem 1.3333rem 1.3333rem 0;
    margin: var(--spacing-unit-x2);

    p {
      margin: 0;
      padding: 0;
      min-height: 1.3333rem;
    }
  }

  &__highlight {
    color: var(--highlight-blue);
  }
}
