.tooltip {
  max-width: 34rem;
  border-radius: var(--standard-border-radius);
  box-shadow: var(--tooltip-box-shadow);
  background-color: var(--text-gray);
  padding: var(--spacing-unit-x2) var(--spacing-unit-x4);

  &__content {
    z-index: var(--z-index-9999999);
    
    & span:has(> .tooltip__arrow.tooltip__arrow-left-aligned) {
      left: var(--half-spacing-unit) !important;
      visibility: unset !important;
    }
  }

  &__trigger {
    &--full-width {
      width: 100%;
      display: inherit;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &--disable-tooltip {
      pointer-events: none;
    }

    &--inline {
      display: inline-block;
    }
  }

  &__minimal-padding {
    padding: var(--half-spacing-unit) var(--spacing-unit-x2);
  }

  &__align-text-center {
    text-align: center;
  }

  &__children {
    word-break: break-word;
  }

  &__trigger:hover {
    text-decoration: none;
  }

  &__actions {
    width: 100%;

    a {
      color: var(--white);
      text-decoration: underline;

      &:hover {
        color: var(--white);
      }
    }
  }

  &__arrow {
    fill: var(--text-gray);
    margin-top: -0.1rem;
  }

  &__ellip-on-text .typography {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
