.removed-early-exit-sources-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x3);
  padding: var(--spacing-unit-x3);

  &__source {
    width: 38.083rem;
  }

  &__sources {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
  }
}
