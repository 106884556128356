.profile-overview-modal {
  width: 50rem;
  max-height: 52rem;

  &__input-list {
    padding-bottom: var(--spacing-unit-x4);

    &-two-cols {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--spacing-unit-x3);
    }
  }

  .divider {
    margin: var(--spacing-unit-x4) 0;
    background-color: var(--border-gray);
    height: calc(var(--spacing-unit) / 6);
  }
}
