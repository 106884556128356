.assign-contacts-modal {
  width: 50rem;

  .modal-body {
    height: 16.66667rem;
  }

  &__input__container {
    margin-top: var(--spacing-unit-x4);
    margin-bottom: var(--spacing-unit);
  }

  &__submit-button {
    &.button {
      height: var(--spacing-unit-x6);
    }

    &-submitting.button {
      padding-top: 0;
      padding-left: var(--spacing-unit-x6);
      padding-right: var(--spacing-unit-x6);
    }

    &-svg.svg {
      height: var(--spacing-unit-x4);
      width: var(--spacing-unit-x4);
      color: var(--white);
    }

    &-loader {
      margin-bottom: var(--half-spacing-unit);
    }
  }
}
