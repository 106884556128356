.erase-contact {
  &__title {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__sub-header-container {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__sub-header {
    display: inline;
    width: 85%;
  }

  &__more-info {
    margin-left: var(--half-spacing-unit);
  }

  &__confirmation {
    width: 40.25rem;
    min-width: 40.25rem;

    &-body {
      span {
        font-weight: var(--font-weight-medium);
      }

      &-input {
        max-width: 27.25rem;
        width: 100%;
        margin: var(--spacing-unit-x4) auto 0;
        padding-bottom: var(--half-spacing-unit);

        &-text {
          margin-bottom: var(--spacing-unit-x2);
        }
      }
    }

    &-toast {
      padding: var(--spacing-unit-x2) 0;
    }
  }
}
