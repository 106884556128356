.review-removed-step-field {
  background-color: var(--input-background);
  border-radius: var(--standard-border-radius);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-unit-x2);
  padding: calc(var(--spacing-unit-x18) / 12) var(--spacing-unit-x2);
  
  &__condition {
    white-space: nowrap;

    &-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: var(--half-spacing-unit);
    }

    &-source {
      background-color: var(--chip-base);
      border-radius: var(--spacing-unit-x2);
      padding: var(--half-spacing-unit)calc(var(--spacing-unit-x18) / 12);
    }
  }
  
  &__value-container {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit-x16) / 12);
  }
}