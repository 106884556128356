.visitors-conditions-editor {
  margin-bottom: var(--spacing-unit);

  &__rows-container {
    overflow: auto;
    scrollbar-gutter: stable;
    max-height: 21.5rem;
  }

  &__row {
    display: flex;
    gap: var(--spacing-unit-x2);

    & + & {
      margin-top: var(--spacing-unit-x2);
    }

    &-logical {
      width: calc(94rem / 12);
    }

    &-field {
      width: calc(250rem / 12);
    }

    &-condition {
      width: calc(176rem / 12);

      &-extended {
        flex-grow: 1;
      }
    }

    &-value {
      &__container {
        flex-grow: 1;
      }
    }
  }

  &__error {
    margin-bottom: 0;

    &-message {
      font-weight: var(--font-weight-medium-light);
      margin-top: var(--spacing-unit-x3);
    }
  }

  .single-select-dropdown__wrapper {
    min-width: unset;
  }
}
