
.removable-tag {
  &__remove {
    height: 100%;
    position: absolute;
    right: 0;
    width: 1.667rem;
    z-index: 4;

    &:hover {
      background-color: rgba(118, 118, 118, 0.15);

      .removable-tag__remove-icon {
        fill: var(--white);
      }
    }

    &-button.button {
      width: 1.667rem;
      height: var(--spacing-unit-x4);

      &:hover {
        background-color: unset;
      }
    }

    &-icon {
      fill: var(--light-gray);
      transition: fill 0.05s linear;
      z-index: 4;

      &.svg {
        height: var(--spacing-unit);
        width: var(--spacing-unit);
      }
    }
  }

  &:hover .removable-tag__remove-icon {
    fill: var(--white);
    filter: drop-shadow(var(--tag-text-shadow));
  }

  & .tag-with-name__name {
    margin-right: 1.667rem;
    padding-right: var(--half-spacing-unit);
  }
}
