.email-sms-performance-detail {
  &__extra-element {
    position: absolute;
    top: var(--chart-extra-element-top);
    left: 11.6rem;
    right: 8%;
    z-index: var(--z-index-chart-extra);
  }

  &__container {
    margin-top: var(--spacing-unit-x4);
    overflow: hidden;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    background-color: var(--white);
    margin-top: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x4);
  }

  &__with-label {
    display: flex;
  }
}
