.powered-by {
  position: absolute;
  left: 0;
  width: var(--nav-width);
  padding: 1.75rem 0;
  justify-content: center;
  height: calc(var(--powered-by-height) - 3.5rem);
  bottom: var(--expander-height);
  color: var(--lighter-gray);
  font-size: var(--smallest-font-size);
  display: flex;
  align-items: center;
  z-index: 1;

  &__svg {
    margin-left: var(--spacing-unit);
    height: 1.33rem;
    width: 3.66rem;
    fill: var(--lighter-gray);
  }
}

.root--theme-dark {
  .powered-by {
    color: var(--dark-theme-unselected);

    &__svg {
      fill: var(--dark-theme-unselected);
    }
  }
}
