.send-test-email-modal {
  width: 50rem;

  &__dynamic-content {
    margin-bottom: var(--spacing-unit-x4);

    & .selectv2__indicators-container {
      align-items: center !important;
    }

    & .selectv2__indicator-separator {
      align-self: center !important;
    }
  }

  &__header {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }

  &__body {
    &__email-count {
      display: flex;
      justify-content: space-between;
    }
  }

  &__footer {
    .modal-footer__inner {
      padding: var(--spacing-unit-x4);
    }

    &__checkbox {
      margin-right: auto;

      label {
        margin-left: var(--spacing-unit-x2);
      }

      &-hide {
        display: none;
      }
    }
  }

  &__spinner {
    min-height: 38rem;
    gap: var(--spacing-unit-x4);
  }
}
