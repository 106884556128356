
.iframe {
  width: 100%;
  margin-top: var(--top-bar-height);
}

.root--iframe {
  &-visible .iframe {
    display: block;
  }

  &-hidden .iframe {
    display: none;
  }
}
