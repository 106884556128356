.view-html-code-modal {
  width: 75rem;
  height: 60rem;

  &__header {
    &-title {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);

      & .svg {
        width: var(--spacing-unit-x5);
        height: var(--spacing-unit-x5);
      }
    }
  }

  &__body {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem var(--spacing-unit-x2) 0.75rem var(--spacing-unit-x4);
      border-bottom: var(--standard-border);
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);

      &-download {
        display: flex;
        align-items: center;
        padding: var(--spacing-unit) var(--spacing-unit-x2);
        gap: var(--spacing-unit);

        & .typography {
          text-decoration: none;
          color: var(--text-teal);
        }

        & .svg {
          width: 1.33rem;
          height: 1.33rem !important;
        }
      }
    }
  }

  & .modal {
    &-body {
      padding: 0;
      overflow: hidden;
    }

    &-header__inner {
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    }

    &-footer__inner {
      padding: var(--spacing-unit-x4);
    }
  }

  & .code-block-v2 {
    height: calc(100% - var(--spacing-unit-x9));

    &__content {
      border: none;
    }

    & * {
      height: 100%;
    }

    pre * {
      height: unset;
    }
  }
}
