.beacon-settings {
  background-color: var(--page-back);

  &__header-info-link {
    margin-left: var(--half-spacing-unit);
  }

  &__status-section.info-status {
    margin: var(--spacing-unit-x4) 0;
  }

  &__section {
    background-color: var(--white);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit-x4);

    & + & {
      margin-top: var(--spacing-unit-x4);
    }
  }

  &__section-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__section-link {
    margin-left: var(--spacing-unit-x4);
  }

  &__section-header {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__setting-description {
    padding: var(--spacing-unit) var(--spacing-unit-x12) var(--spacing-unit-x2) var(--spacing-unit-x4);
  }

  &__section-description {
    padding-right: var(--spacing-unit-x12);
    margin: var(--spacing-unit-x2) 0;
  }

  &__instructions.info-status {
    padding: var(--spacing-unit-x2);
    margin-bottom: var(--spacing-unit-x4);

    .info-status__svg {
      margin-right: var(--half-spacing-unit);

      svg {
        width: var(--spacing-unit-x3);
        height: var(--spacing-unit-x3);
      }
    }
  }

  &-clipboard-copy__trigger {
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit-x2);
  }
}
