.forethought-widget {
  width: var(--spacing-unit-x6);
  height: var(--spacing-unit-x6);
  margin-right: var(--spacing-unit-x2);
  border-radius: 50%;

  &:hover {
    background-color: var(--selectable-row-hover);
  }

  &__tooltip {
    padding: var(--spacing-unit) var(--spacing-unit-x2);
  }

  .svg {
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);
  }
}

iframe[id|='forethought-chat'] {
  height: 52rem !important;
  z-index: var(--z-index-forethought-widget) !important;
}
