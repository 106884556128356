.opportunities {
  &__actions {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: var(--z-index-chart-extra);

    &-select {
      min-width: calc(var(--spacing-unit-x20) + var(--spacing-unit-x13));
      margin-right: var(--spacing-unit);

      &:first-of-type {
        margin-right: var(--spacing-unit-x2);
      }
    }

    &-toggle {
      display: flex;
      align-items: center;
    }
  }

  & .chart .highcharts-container svg {
    width: 100%;
  }

  & li .highcharts-a11y-proxy-button {
    cursor: default !important;
  }

  & .highcharts-series-1 > span > span {
    padding-right: var(--spacing-unit-x5);
  }
}
