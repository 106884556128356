
.wait-step {
  &__row {
    display: flex;

    input {
      flex: 0 0 4.16rem;
    }
  }

  &__select-small {
    margin-left: var(--spacing-unit-x2);
    flex: 0 0 10rem;
  }
}
