
.edit-field-set-step {
  width: 50rem !important;

  &__operation-row {
    display: flex;
    flex-wrap: nowrap;

    select {
      min-width: 14rem;
    }

    select,
    input {
      &:nth-child(1) {
        flex: 0 0 11.5rem;
        margin-right: var(--spacing-unit-x2);
      }

      &:nth-child(2) {
        flex: 0 0 11.5rem;
        margin-right: var(--spacing-unit-x2);
      }

      &:nth-child(3) {
        flex: 0 0 11.5rem;
        margin-right: var(--spacing-unit-x2);
      }
    }
  }

  &__delete-operation {
    flex: 0 0 2rem;
    text-align: right;

    &--extra-width {
      flex: 0 0 14rem;
    }
  }

  &__condition-buttons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;

    button {
      margin-right: var(--spacing-unit-x3);
      display: flex;
      align-items: center;

      svg {
        width: 1rem;
        height: 1rem;
        fill: var(--light-gray);
      }
    }
  }

  &__conditions_header {
    position: relative;
    height: 1.5rem;
    overflow: visible;
  }

  &__delete-condition {
    color: var(--error);

    svg {
      margin-right: 0 var(--spacing-unit) 0.25rem 0;
    }
  }

  &__change-detail.change-field-step-detail .change-field-step-detail__operation .typography:not(.typography__inline) {
    margin-bottom: var(--spacing-unit);
  }

  button {
    .svg {
      margin: 0 var(--spacing-unit) 0.25rem 0;
    }
  }
}
