.opt-out-link-modal {
  width: 50rem;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__radio-group {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);

    .radio__container {
      margin: 0 0 0 0;
      
      .radio__label {
        padding-left: 2.33rem;
      }
    }

    &__radio-description {
      margin-left: 2.33rem;
    }
  }
}
