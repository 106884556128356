.edit-columns-listing {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);

    .button {
      font-weight: var(--font-weight-medium);

      .svg {
        margin-right: calc(10rem / 12);
      }
    }
  }

  &__body {
    padding: var(--thick-border-width) var(--spacing-unit-x2) var(--spacing-unit-x4);
  }

  &__scrollbar {
    overflow-y: auto;
  }

  &__column {
    padding: var(--spacing-unit) 0;
  }

  &__scroll {
    height: 45.25rem;
  }
}
