.error-alert-banner {
  z-index: var(--z-index-banner);
  display: flex;
  background-color: var(--status-warning);
  color: var(--black);
  border: var(--standard-border-width) solid var(--status-warning-border);
  border-radius: var(--standard-border-radius);
  margin-top: var(--top-bar-height);

  &__nav {
    width: var(--spacing-unit-x8);
    height: var(--spacing-unit-x8);
    display: flex;
    align-items: center;
    justify-content: center;

    .svg {
      width: var(--spacing-unit-x14);
      height: 50%;
    }
  }

  &__main {
    width: calc(100vw - var(--powered-by-height));
    min-width: 66.6rem;
    /* stylelint-disable */
    @media (max-width: 1116px) {
      /* stylelint-enable */
      & {
        width: calc(100vw - var(--nav-width-collapsed));
        padding: var(--spacing-unit-x2) 0;
        padding-left: var(--spacing-unit-x2);
      }
    }
  }

  &__position {
    height: 100%;
    /* stylelint-disable */
    @media (max-width: 1485px) {
      /* stylelint-enable */
      & {
        margin-left: 0;
        padding-right: 0;
      }
    }
  }

  &__content {
    height: 100%;
    margin-left: var(--spacing-unit);
    margin-right: var(--spacing-unit-x6);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* stylelint-disable */
    @media (max-width: 1485px) {
      /* stylelint-enable */
      & {
        margin: 0;
      }
    }
  }

  &__title {
    flex: 1;
    margin-right: var(--spacing-unit-x6);
  }

  &__top {
    margin-bottom: 0.167rem;
  }

  &__bottom {
    margin-bottom: 0;
  }

  &__buttons {
    display: flex;
    margin-left: var(--spacing-unit);
  }
}
