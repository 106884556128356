
.breadcrumbs-item {
  white-space: nowrap;

  &--truncate {
    max-width: 95%;
  }

  &--active {
    cursor: default;
  }
}
