.review-upgrade-segment-replacement {
  &__arrow {
    align-self: center;
    fill: var(--gray-icon);
    justify-self: center;
  }
  
  &__box {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit) + var(--half-spacing-unit));
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    
    &-current {
      background-color: var(--selectable-row-hover);
    }
  }
}