.dependencies-content-modal {
  width: 50rem;
  max-width: 50rem;
  max-height: 63rem;
  border-radius: var(--standard-border-radius);
  background-color: var(--white);

  &__body {
    &-content {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x4);
      padding-bottom: var(--spacing-unit-x4);
    }

    &-caution {
      margin: 0;
    }
  }
}
