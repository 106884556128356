
.chart-extra-element {
  display: flex;
  justify-content: space-between;

  &__date-selector-ctr {
    display: flex;
    align-items: center;

    .select {
      width: var(--spacing-unit-x20);
      margin-right: var(--spacing-unit);
    }

    .input {
      width: var(--spacing-unit-x10);
      margin-left: var(--spacing-unit);
      margin-right: var(--spacing-unit);
      text-align: right;

      &--quantity {
        text-align: left;
      }
    }
  }
}
