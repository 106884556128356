.edit-step-modal {
  min-width: 50rem;
  
  &__title {
    display: block;
    margin-left: calc(var(--spacing-unit-x2) + var(--half-spacing-unit));
  }

  &__running .modal-body {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x7);

    .typography:not(.typography__inline) {
      margin-bottom: var(--spacing-unit-x4);
    }
  }

  &__submit {
    margin-left: var(--spacing-unit-x2);
  }
}
