.select-asset-modal {
  width: 50rem;
  overflow: visible !important;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
  }

  &__sub-headers {
    margin: 0;
    margin-bottom: 0.6667rem;
  }

  &__media-file {
    display: flex;
    align-items: center;
  }

  &__main-button {
    display: flex;
    align-items: center;
    background-color: unset;
    border-color: var(--text-teal);

    &-svg {
      margin-right: var(--spacing-unit);
    }
  }

  &__assets-container {
    display: flex;
    margin-top: var(--spacing-unit-x2);
    gap: var(--spacing-unit-x2);

    .svg {
      margin-bottom: 0;
    }

    .button {
      width: 50%;
      padding-right: var(--spacing-unit-x2);
    }
  }

  &__asset-options {
    &-divider {
      background-color: var(--border-gray);
      width: 0.0833rem;
      height: var(--spacing-unit-x6);
    }
  }

  &__secondary-buttons {
    display: flex;
    align-items: center;
    background-color: unset;
    border: none;

    &:hover {
      background-color: unset !important;
    }
  }

  .radio__container {
    svg {
      top: 0.2rem;
      margin-left: var(--spacing-unit);
    }
  }

  &__bottom-options {
    display: flex;
    gap: var(--spacing-unit);
    align-items: center;
    margin-top: var(--spacing-unit-x4);
    position: relative;
  }

  .modal-body {
    overflow: visible !important;
  }

  .modal-body,
  .modal-footer__inner {
    padding: var(--spacing-unit-x4);
  }

  .modal-footer__inner {
    gap: var(--spacing-unit-x4);
  }

  .button.select-asset-modal__footer-button-insert {
    margin: 0;
    line-height: 1.6667rem;
  }

  &__footer {
    &-button {
      &-cancel {
        padding: 0.9167rem 0;
      }

      &-insert {
        padding: 0.6667rem var(--spacing-unit-x4);
      }
    }
  }

  @media only screen and (max-height: 40rem) {
    .date-picker__panel {
      top: -530% !important;
    }
  }
}
