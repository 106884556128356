.input-with-max-length {
  display: block;

  .input {
    padding-right: var(--spacing-unit-x12);
  }

  &__counter {
    position: absolute;
    top: calc(var(--spacing-unit) + var(--half-spacing-unit));
    right: var(--spacing-unit-x2);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;

    &-labelled {
      top: var(--spacing-unit-x6);
    }

    &-small {
      top: var(--half-spacing-unit);
    }

    &-labelled&-small {
      top: var(--spacing-unit-x5);
    }

    &-error {
      .typography {
        color: var(--remove-red);
      }
    }
  }
}
