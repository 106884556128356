.review-condition {
  &__source-condition {
    white-space: nowrap;

    &-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: var(--half-spacing-unit);
    }

    &-name {
      background-color: var(--chip-base);
      border-radius: var(--spacing-unit-x2);
      padding: var(--half-spacing-unit)calc(var(--spacing-unit-x18) / 12);
    }
  }

  &__sources {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
  }
}