.rss-modal {
  width: 60rem;
  height: 62rem;

  & .modal-header__inner {
    padding-left: var(--spacing-unit-x4);
    padding-right: var(--spacing-unit-x4);
  }

  & .modal-footer__inner {
    padding-left: var(--spacing-unit-x4);
    padding-right: var(--spacing-unit-x4);
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
  }

  &__radio-card {
    flex-basis: 100%;

    & .radio-card__svg {
      width: 6.667rem;
      height: 6.667rem;
    }

    & .svg {
      width: 5.667rem;
    }
  }

  &__label {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__select-group {
    display: flex;
    flex-direction: column;
    gap: var(--half-spacing-unit);

    &-label {
      display: flex;
      align-items: center;
      gap: var(--half-spacing-unit);
    }

    &-selectV2 {
      margin-top: var(--spacing-unit);
      width: 21.5rem;
    }
  }

  &__checkbox-group {
    margin-top: var(--spacing-unit);

    & .checkboxgroup__container {
      gap: var(--spacing-unit-x12);
    }
  }

  &.modal { 
    overflow: unset; 
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);

    &-plain-text-preview.modal-body {
      padding: var(--spacing-unit-x4);
    }
  }

  &__header-with-back-button {
    justify-content: unset;
  }

  &__anchor {
    display: flex;
    gap: var(--spacing-unit-x3);
    align-items: center;

    &-svg {
      padding: 0.6rem;
      display: flex;
      align-items: center;
      background-color: var(--white);
    }
  }

  &__rss-feed {
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    padding: var(--spacing-unit);

    &-plain-text {
      padding: var(--spacing-unit-x4) var(--spacing-unit-x3);
    }
  }

  &__button-icon {
    &:focus:not(&:disabled),
    &:hover&:focus:not(&:disabled) {
      border: none;
      box-shadow: none;
    }
  }

  &__button-icons {
    display: flex;
    align-items: center;
    box-shadow: none; 
    gap: var(--spacing-unit-x4);

    &-active {
      background-color: var(--border-gray);
      text-shadow: unset;

      &:focus:not(&:disabled),
      &:hover&:focus:not(&:disabled) {
        background-color: var(--border-gray);
      }
    }
  }

  &__alignment {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit-x6);
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
    background-color: var(--lighter-off-white);
    border-radius: var(--standard-border-radius);
  }

  &__selectV2-popover {
    &__anchor {
      border-radius: var(--standard-border-radius);
      background-color: var(--white);
      padding: var(--spacing-unit-x2);
      border: var(--standard-border);
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + div {
        width: 52.5rem;
      }
    }
  }
}
