.forms-upgrade-warning-modal {
  width: 50rem;
  max-height: 63rem;
  border-radius: var(--standard-border-radius);
  background-color: var(--white);

  &__body {
    &-content {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x4);
      padding-bottom: var(--spacing-unit);
    }

    &-caution {
      margin: 0;
    }
  }

  &__section {
    box-sizing: border-box;
    border: var(--standard-border);
    border-radius: var(--thick-border-width);
    padding-left: var(--spacing-unit-x4);
    padding-right: var(--spacing-unit-x4);

    &-row {
      display: flex;
      align-items: baseline;
      gap: 1rem;
      padding-bottom: var(--spacing-unit);


      &:last-child {
        padding-bottom: var(--spacing-unit-x2);
      }

    }
  }

  &__icon {
    overflow: unset;

    &-container {
      height: var(--line-height-large);
      display: flex;
      align-items: center;
    }
  }

  &__footer {
    padding-top: 0;
  }
}
