.edit-plain-text-modal {
  width: 60rem;

  &__dynamic-content-warning {
    margin: var(--spacing-unit-x3) var(--spacing-unit-x4) var(--spacing-unit-x2);
  }

  &--hidden {
    opacity: 0;
  }
  
  &__drop-down {
    max-width: 27rem;
  }

  .modal-header__inner,
  &__toolbar {
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4);
  }

  .modal-body {
    padding: 0;
  }

  &__footer {
    padding: var(--spacing-unit-x4);

    &__extra {
      margin-right: auto;
    }

    .modal-footer__inner {
      padding: 0;
    }
  }
  
  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--standard-border);
    
    &__insert,
    &__history {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);

      .link:active {
        border: none;
        box-shadow: unset;
      }
    }
  }

  &__divider {
    align-self: stretch;
    width: 0.0834rem;
    background-color: var(--border-gray);
    margin-bottom: 0.667rem;
    margin-top: 0.667rem;
    margin-left: var(--spacing-unit);
    margin-right: var(--spacing-unit);
  }

  .spinner__wrapper {
    height: 32.8rem;
  }

  .textarea {
    height: 32.5rem;
    font-family: Fira Code, monospace;
    resize: none;
    overflow: none;
    border: none;
    outline: none;
    background-color: var(--white);
    padding: var(--spacing-unit-x4);
  }

  .info-status {
    align-items: unset;
  }
}
