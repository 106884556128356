.opt-in-search-results {
  border-top: var(--standard-border);

  &__pagination__table-holder,
  &__no-pagination__table-holder,
  .table__paginator {
    border: none;
  }

  .table__paginator {
    border-top: var(--standard-border);
  }

  &__empty-listing {
    height: 12rem;
  }
}
