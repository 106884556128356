.invite-users-permissions-form {
  &__launch {
    &__content {
      border: var(--standard-border);
      border-radius: var(--standard-border-radius);
      padding: var(--spacing-unit-x3) var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x3);
      margin-top: var(--spacing-unit-x3);

      &-title {
        font-size: var(--large-font-size);
        line-height: var(--line-height-large);
        margin-top: 0;
        margin-bottom: var(--spacing-unit-x2);

        .tooltip__trigger {
          line-height: inherit;
        }
      }
    }
  }

  &__copy-permissions {
    &-tooltip {
      .typography {
        white-space: pre-line !important;
      }

      &__trigger-wrapper {
        margin-bottom: var(--spacing-unit-x4);
      }
    }

    &-button {
      padding-left: var(--spacing-unit-x3);
    }
  }
}
