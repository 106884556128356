.page-status-pane {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-unit-x3);

    &-button {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      padding: 0;
    }
  }

  &__last-published {
    &-time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: var(--spacing-unit-x3);
    }

    &-text {
      font-size: var(--spacing-unit-x2);
    }
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-unit-x3);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    background: var(--light-theme-background-darker);
    height: 7.83334rem;
    transition: var(--ease-in-out-300);
    margin-bottom: var(--spacing-unit-x4);

    &-teal-bg {
      background: var(--hover-row-background-teal);
    }

    &-enabled {
      background: var(--hover-row-background-teal);
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
    }

    &-pane {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-status {
        display: flex;
        align-items: center;
        gap: var(--spacing-unit);

        &__svg {
          width: var(--spacing-unit-x2);
          height: var(--spacing-unit-x2);
        }
      }
    }

    &-small-margin {
      margin-bottom: var(--spacing-unit);
    }
  }

  &__select-landing-page {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x2);
    margin-top: var(--spacing-unit-x2);
    width: 100%;

    &-selector {
      flex: 1;

      &-error .button {
        border: var(--program-step-border-error);
      }
    }

    &-error {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }
  }

  &__redirect-input {
    &-container {
      display: flex;
      align-items: stretch;
      gap: var(--spacing-unit-x4);
      margin-top: var(--spacing-unit-x2);
      max-width: 58.3rem;
    }

    &-divider {
      width: var(--half-spacing-unit);
      background-color: var(--teal);
      border-radius: var(--standard-border-radius);
      margin-left: var(--spacing-unit);
    }
  }

  &__restore-button {
    display: flex;
    gap: var(--half-spacing-unit);
    align-items: center;
  }
}
