.chart-section {
  margin-top: var(--spacing-unit-x4);

  &__header {
    padding: 0 var(--spacing-unit-x4) var(--spacing-unit-x5);
    border-bottom: var(--standard-border);
  }

  &__content {
    padding: var(--spacing-unit-x5) var(--spacing-unit-x4) 0;
  }

  &__title {
    margin-bottom: var(--spacing-unit-x4);
  }
}
