.group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__header {
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: none;
    }

    &-expand-section {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
    }

    &-expand-section:hover &-label {
      color: var(--text-teal);
    }

    &-expand-section:hover &-chevron svg {
      fill: var(--text-teal);
      color: var(--text-teal);
    }

    &-expanded &-chevron {
      svg {
        transform: rotate(90deg);
      }
    }

    &-reload {
      padding-left: var(--half-spacing-unit);
      width: var(--spacing-unit-x4);
      height: var(--spacing-unit-x4);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--button-gray);
      cursor: pointer;

      &-icon.svg {
        height: var(--spacing-unit-x2);
        width: var(--spacing-unit-x2);
      }

      &:hover {
        color: var(--text-teal);
        background-color: unset;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--spacing-unit-x4);
    height: var(--spacing-unit-x4);

    &-icon.svg {
      width: var(--spacing-unit-x2);
      height: var(--spacing-unit-x2);
    }
  }

  &:hover &__action {
    display: flex;
  }

  &__action {
    display: none;
    border-radius: var(--extra-large-border-radius);

    &:hover {
      cursor: pointer;
      background-color: var(--text-gray-opacity-15);
    }
  }
}
