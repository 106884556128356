
.auto-saving-status {
  width: var(--spacing-unit-x28);
  text-align: right;

  .svg,
  .loader {
    margin-right: var(--spacing-unit);
  }

  .loader {
    display: inline-block;
  }

  &__saved svg {
    fill: var(--light-gray);
  }
}
