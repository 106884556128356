.from-addresses {
  &__info-action {
    margin-bottom: var(--spacing-unit-x4);

    .info-action--wrapper-buttons {
      display: none;
    }

    .text-link {
      display: inline;
    }
  }

  &__title {
    margin-bottom: var(--spacing-unit-x2);
  }

  &__sub-header-container {
    margin-bottom: var(--spacing-unit-x4);
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-unit-x2);

    &-search-end {
      justify-content: flex-end;
    }

    &-search {
      min-width: calc(var(--spacing-unit-x2) * 25);
    }
  }

  &__tooltip {
    padding: var(--half-spacing-unit) var(--spacing-unit-x2);

    &--phone {
      line-height: var(--line-height-standard);
    }
  }

  &__table {
    .hover-svg {
      visibility: hidden;
    }

    .tr:hover {
      .hover-svg {
        visibility: visible;
      }
    }

    .table__row-actions {
      margin-right: var(--spacing-unit);
    }

    &--info {
      .table__ellip {
        max-width: 80%;

        .tooltip__trigger {
          height: unset;

          .ellip {
            height: unset;
            margin-right: var(--spacing-unit);
          }
        }
      }

      .tooltip__trigger {
        height: 1.33rem;

        & * {
          height: 1.33rem;
        }
      }
    }
  }

  & .tableV2 {
    .hover-svg {
      visibility: hidden;
    }

    &-tbody__row:hover {
      .hover-svg {
        visibility: visible;
      }
    }
  }

  &__name {
    width: 70%;

    &-margin {
      margin-left: var(--spacing-unit-x2);
    }
  }

  &__icon {
    &-warning {
      fill: var(--yellow-caution);
    }
  }
}
