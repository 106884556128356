.accordion-details-modal {
  display: block;
  width: 37.5rem;

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x2) var(--spacing-unit-x4);
    min-height: var(--spacing-unit-x16);

    &-text {
      min-height: 0;
      align-items: center;
    }
  }

  &__subheader {
    padding: var(--spacing-unit-x4);
    padding-top: 0;
  }

  &__body {
    padding: 0 !important;
    overflow: hidden !important;
  }

  &__icon {
    fill: var(--button-gray) !important;
    transform: none !important;
    flex-shrink: 0;
    height: var(--spacing-unit-x4) !important;
    width: var(--spacing-unit-x4) !important;
  }

  &__actions {
    display: flex;
    height: 100%;

    > svg {
      fill: var(--text-gray) !important;
    }
  }

  &__section {
    padding: var(--spacing-unit-x3) var(--spacing-unit-x4);
    display: flex;
    border-top: var(--standard-border);
    align-items: center;
    min-height: var(--spacing-unit-x10);

    &-regular {
      display: flex;
      gap: var(--spacing-unit-x2);
      align-items: center;
    }
  }

  &__action {
    display: flex;
    width: var(--spacing-unit-x6);
    height: var(--spacing-unit-x6);
    border-radius: var(--extra-large-border-radius);
    align-items: center;
    justify-content: center;
    transition: var(--linear-120);

    > .svg {
      fill: var(--text-gray);
    }

    &:hover {
      background: var(--text-gray-opacity-10);

      > .svg {
        fill: var(--black);
      }
    }
  }

  &__accordion {
    padding: 0;

    &-header {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x3);
    }

    &-arrow {
      width: 100%;

      &__icon {
        align-items: center;
        border-radius: var(--extra-large-border-radius);
        display: flex;
        height: var(--spacing-unit-x6);
        justify-content: center;
        transition: var(--linear-120);
        width: var(--spacing-unit-x6);

        &:hover {
          background: var(--hover-row-button-background-teal);

          > .svg {
            fill: var(--text-teal) !important;
          }
        }
      }

      &__item {
        border-bottom: none;
        padding: 0;
      }

      &__button {
        padding: var(--spacing-unit-x4) var(--spacing-unit-x3) var(--spacing-unit-x4) var(--spacing-unit-x4);
      }

      &__panel {
        padding: 0 var(--spacing-unit-x4) var(--spacing-unit-x4) var(--spacing-unit-x4);
        margin-top: var(--spacing-unit);
      }

      &__header {
        align-items: center;
        min-height: var(--spacing-unit-x10);

        &:hover {
          background: var(--hover-row-background-teal);

          .alert-details-modal__accordion-header > .svg {
            fill: var(--text-teal) !important;
          }
        }
      }
    }
  }

  & .scroll-area {
    &__viewport {
      max-height: 66rem;
    }

    &__scrollbar {
      display: none;
    }
  }

  &__contact-card {
    &-information {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
      margin-left: var(--half-spacing-unit);

      > .tooltip__trigger {
        width: fit-content;
      }

      &-field {
        display: flex;
        gap: var(--spacing-unit-x2);
        align-items: center;
      }
    }

    &-activities {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-unit-x2);
    }
  }
}
