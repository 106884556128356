.number-input {
  display: flex;
  width: fit-content;

  &__input-v2 {
    width: var(--spacing-unit-x8);
    min-width: var(--spacing-unit-x8);
    max-width: var(--spacing-unit-x20);
   
    &:focus {
      position: relative;
      z-index: var(--z-index-1);
    }
   
    &-symbol {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &__container {
        display: inline-table;
      }
    }
  
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
     
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &__symbol {
    display: flex;
    align-items: center;
    padding: 0 calc(var(--spacing-unit) + var(--half-spacing-unit));
    background-color: var(--page-back);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
}
