.account-management-listing-page {
  &-container {
    &__create-account-success {
      width: 30rem;
    }

    &__page-header {
      margin: 0;
    }

    .listing-page__content-tableV2 {
      padding-top: var(--spacing-unit-x4);
    }
  }

  &__name {
    display: flex;
    align-items: center;

    &-id {
      display: flex;
      align-items: center;
      gap: var(--spacing-unit);
      height: 1.6667rem;

      .clipboard-copy {
        display: none;
      }
    }
  }

  tr:hover &__name-id {
    .clipboard-copy {
      display: flex;
    }
  }

  &__used-contacts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-unit);

    &-error {
      fill: var(--red);
    }

    &-warning {
      fill: var(--yellow-caution);
    }
  }
}

.golden-child-toast {
  .toast__no-padding {
    width: 35rem;
  }
}
