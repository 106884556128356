.crm-string-field {
  &__input {
    flex-grow: 1;

    .tagify {
      max-width: unset;
      width: 37.1rem;
    }

    &-with-helper {
      margin-bottom: var(--spacing-unit);
    }
  }
}
