.add-alert-filter-modal {
  min-width: 75rem;

  &__filter {
    border-bottom: var(--standard-border);

    >:first-child {
      margin-bottom: var(--spacing-unit-x3);
    }
  }

  &__filter,
  &__conditions-container {
    padding: var(--spacing-unit-x6);
  }

  &__conditions {
    &-footer {
      padding-top: var(--spacing-unit-x2);
      border-top: var(--standard-border);
    }

    &-body {
      padding: var(--spacing-unit-x3) 0;
    }

    &-container {
      padding-bottom: var(--spacing-unit-x3);
    }
  }
}
