.import-log {
  width: 100rem;

  .tableV2__wrapper {
    max-height: 55vh;
    overflow: auto;
  }

  &__filename {
    max-width: 25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__header {
    padding: var(--spacing-unit-x4);
  }

  &__status {
    display: flex;
    align-items: center;
    gap: var(--spacing-unit);
  }

  &__modal {
    &-body {
      padding: 0 var(--spacing-unit-x4) var(--spacing-unit-x4) !important;
    }

    &-header {
      padding: var(--spacing-unit-x4);
    }
  }

  > * .tr {
    max-width: unset !important;
  }

  &__cell-username {
    white-space: nowrap;
  }
}
