.step-pill {
  border-radius: var(--standard-border-radius);
  box-shadow: var(--program-flow-step-shadow);
  display: flex;
  flex-direction: row;
  width: fit-content;
    
  &__icon {
    height: unset;
    width: var(--spacing-unit-x6);
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit);
    padding: var(--spacing-unit) var(--spacing-unit-x4) var(--spacing-unit) var(--spacing-unit);
  }
}
