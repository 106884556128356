.activity-gauge-chart {
  display: block;
  right: 100%;

  &__wrapper {
    position: relative;
  }

  &__chart-container {
    display: flex;
    justify-content: flex-end;
  }

  &__tooltip-container {
    position: absolute;
    width: 6.25rem;
    height: 6.25rem;
  }

  &__tooltip-value {
    display: none;
    font-weight: bold;
    font-size: medium;
    justify-content: center;
    max-width: var(--spacing-unit-x13);
    position: relative;
    top: 35%;
    width: var(--spacing-unit-x13);
    z-index: 1;

    &-warning {
      color: var(--yellow-caution-hover);
    }

    &-overflow {
      color: var(--remove-red-hover);

      &-oversize {
        font-size: larger;
      }

      &-supersize {
        font-size: inherit;
      }
    }

    &-default {
      color: var(--hover-teal);
    }
  }
}
