:root {
  --fake-var-so-comment-is-not-first-line: '';

  /* Vertical and Horizontal Cadence */
  --third-spacing-unit: 0.16667rem;
  --half-spacing-unit: 0.25rem;
  --spacing-unit: 0.5rem;
  --spacing-unit-x2: 1rem;
  --spacing-unit-x3: 1.5rem;
  --spacing-unit-x4: 2rem;
  --spacing-unit-x5: 2.5rem;
  --spacing-unit-x6: 3rem;
  --spacing-unit-x7: 3.5rem;
  --spacing-unit-x8: 4rem;
  --spacing-unit-x9: 4.5rem;
  --spacing-unit-x10: 5rem;
  --spacing-unit-x11: 5.5rem;
  --spacing-unit-x12: 6rem;
  --spacing-unit-x13: 6.5rem;
  --spacing-unit-x14: 7rem;
  --spacing-unit-x15: 7.5rem;
  --spacing-unit-x16: 8rem;
  --spacing-unit-x17: 8.5rem;
  --spacing-unit-x18: 9rem;
  --spacing-unit-x19: 9.5rem;
  --spacing-unit-x20: 10rem;
  --spacing-unit-x21: 10.5rem;
  --spacing-unit-x22: 11rem;
  --spacing-unit-x23: 11.5rem;
  --spacing-unit-x24: 12rem;
  --spacing-unit-x25: 12.5rem;
  --spacing-unit-x26: 13rem;
  --spacing-unit-x27: 13.5rem;
  --spacing-unit-x28: 14rem;
  --spacing-unit-x29: 14.5rem;

  /* standard borders */
  /* stylelint-disable */
  --standard-border-width: 1px;
  --legend-border-radius: 2px;
  --collapsible-menu-radius: 3px;
  --checkbox-border-radius: 2px;
  --standard-border: 1px solid #e3e3e3;
  --standard-border-darker: 1px solid #C1C9D4;
  --standard-border-dark: 1px solid #c9c9c9;
  --standard-border-dark-blue: 1px solid #304a71;
  --standard-border-white: 1px solid var(--white);
  --hover-teal-border: 1px solid #08a2a5;
  --modal-border: 1px solid #ececec;
  --border-dark-blue-light: 4px solid #455c7f;
  --standard-border-heavy-dark: 3px solid #444;
  --small-border-radius: 2px;
  --standard-border-radius: 4px;
  --standard-border-top-inner-radius: 2.25px 2.25px 0 0;
  --standard-border-bottom-inner-radius: 0 0 2.25px 2.25px;
  --large-border-radius: 6px;
  --extra-large-border-radius: 18px;
  --left-extra-large-border-radius: 18px 0 0 18px;
  --right-extra-large-border-radius: 0 18px 18px 0;
  --segmented-line-chart-border-radius: 2px;
  --segmented-line-chart-start-border-radius: 2px 0px 0px 2px;
  --segmented-line-chart-end-border-radius: 0px 2px 2px 0px;
  --sms-message-border-radius: 16px;
  --vertical-bar-border-radius: 24px;
  --single-select-border-radius: 4px 4px 0 0;
  --thick-border-width: 2px;
  --transparent-border: 1px solid transparent;
  --columns-title-border: 1px solid #eeeeee;
  --hover-indicator-border: 2px solid #ffffff;
  --input-border: 1px solid #e3e3e3;
  --input-border-hover: 1px solid #c9c9c9;
  --input-border-error: 1px solid #ff9292;
  --input-border-focus: 1px solid #304a71;
  --input-box-shadow-focus: 0px 0px 0px 2px #ACE9EB;
  --input-outline-border: 3px solid #ace9eb;
  --input-thin-outline-border: 2px solid #ace9eb;
  --float-dark-button-outline: 2px solid #a5d6d7;
  --inputV2-border-hover: 1px solid #aeaeae;
  --inputV2-outline-border: 2px solid #ace9eb;
  --program-step-border-error: 1px solid #f25656;
  --solid-error-border: 1px solid #D84444;
  --small-input-outline-border: 2px solid #ace9eb;
  --single-select-input-border: 3px solid #f2fbfb;
  --table-actions-border: 2px solid #304a71;
  --card-border-highlight: 1px solid #a5d6d7;
  --highlight-border-radius: 8px 8px 8px 0;
  --dashed-border: 2px dashed #c9c9c9;
  --thin-dashed-border: 1px dashed #c9c9c9;
  --dashed-border-focus: 2px dashed #767676;
  --dashed-border-error: 1px dashed #f25656;
  --split-button-inner-border: 1px solid rgba(0, 0, 0, 0.25);
  --tag-color-border: 1px solid rgba(0, 0, 0, 0.05);
  --last-bar-border: 1px solid rgba(0, 0, 0, 0.1);
  --lighter-teal-border: 1px solid var(--lighter-teal);
  --teal-border: 1px solid #00babe;
  --text-teal-border: 4px solid #00babe;
  --condition-card-teal-border: 3px solid #00babe;
  --vertical-table-border: 1px solid #d8d8d8;
  --caution-border: 1px solid #eee4bb;
  --radio-card-border: 1px solid #ace9eb;
  --radio-button-border-gray: 1px solid #d6d6d6;
  --calendar-date-outline-border: 1.5px solid #00747a;
  --dark-mode-container-border: 1px solid #344B70;
  --condition-group-connector-border: 1px solid #aeaeae;
  /* stylelint-enable */

  /* box shadow */
  --box-shadow: 0 0.41rem 1.25rem 0 rgba(24, 45, 100, 0.06);
  --hover-menu-shadow: 0 0.333rem var(--spacing-unit-x4) 0 rgba(176, 176, 176, 0.5);
  --nav-box-shadow: 0.083rem 0.416rem 1.25rem -0.416rem rgba(0, 0, 0, 0.3);
  --drag-preview-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.06);
  --locked-columns-box-shadow: 0 0.417rem 1.25rem 0 rgba(24, 45, 100, 0.07);
  --selected-option-box-shadow: inset 0.33334rem 0 0 var(--teal);
  --breadcrumbs-drop-down-shadow: 0 var(--spacing-unit-x2) var(--spacing-unit-x4) 0 rgba(0, 0, 0, 0.12);
  /* stylelint-disable */
  --drop-down-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.06);
  --program-flow-step-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  --autoresize-textarea-box-shadow: 0 0 0 var(--standard-border-width) #ff9292;
  --keyboard-focus-box-shadow: 0 0 0.16rem 0.16rem #51a7e8;
  --drag-preview-text-shadow: 0 0 0.167rem 0 #007b80;
  --drag-hover-shadow: 0 calc(var(--standard-border-width) * -2) 0 #99e3e5;
  --primary-button-text-shadow: 1px 1px 0.125rem #009a9e;
  --primary-small-button-text-shadow: 1px 1px 0.167rem #009a9e;
  --tooltip-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.06);
  --media-card-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.06);
  --tag-text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  --date-picker-input-shadow: 0 0 0 2px #ace9eb;
  --recipients-select-box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  --indicator-focus-box-shadow: 0px 0px 0px 1px #304a71, 0px 0px 0px 3px #ace9eb;
  --box-shadow-split-button-focus: inset 0px 0px 0px 1px var(--dark-blue);
  --box-shadow-table-header-border: 0 1px 0 #c9c9c9;
  --box-shadow-table-row-border: 0 1px 0 #e3e3e3 inset;
  --radio-card-box-shadow: 4px 0px 0px 0px #00babe inset;
  --breadcrumbs-hover-card-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.18);
  --resizable-bar-shadow: 1px 0 0 var(--teal), 2px 0 0 var(--teal);
  --secondary-pill-text-shadow: 1px 1px 2px var(--purple-secondary-dark);
  --import-contacts-row-box-shadow: 0px 3px 5px 0px rgba(174, 174, 174, 0.2);
  --white-toggletip-box-shadow: 0px 12px 24px 0px #0000001F;
  --footer-shadow: 0px -0.6000000238418579px 6px 0px rgba(0, 0, 0, 0.15);
  --interior-container-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);

  /* stylelint-enable */

  /* Specific global components */
  --top-bar-height: 5rem;
  --checkbox-size: 1.333rem;

  /* Navigation globals */
  --powered-by-height: 5.5rem;
  --expander-height: 4.25rem;
  --nav-width: 18.33rem;
  --nav-width-collapsed: 5.7rem;

  /* stylelint-disable */
  /* positioning widths */
  --positioning-margin-left: -220px;
  --positioning-min-width: 885px;
  --positioning-max-width: 1045px;
  --standard-field-names-positioning-min-width: 997px;
  --full-width-min-width: 100rem;

  --detail-column-width: 22rem;

  --sms-optins-positioning-min-width: 69.5rem;

  --program-settings-left-container-max-width: 60rem;
  --program-settings-right-container-min-width: 21rem;

  --chart-max-height: 344px;
  --list-max-height: 400px;

  --asset-picker-modal-height: 66rem;
  --asset-picker-modal-width: 100rem;

  --message-picker-height: 644px;

  --invite-users-modal-height: 470px;

  --input-tags-tag: 520px;

  --footer-border-margin: 40px;

  --actionable-nested-table-row-height: 44px;

  --chart-extra-element-top: 22px;
  --chart-extra-element-search-top: 4.5px;

  --media-card-width: 350px;
  --media-card-body-height: 197px;
  --media-card-footer-height: 74px;

  --upload-image-drop-zone-size: 500px;
  --upload-image-drop-zone-division-width: 300px;
  --upload-image-drop-zone-height: 264px;
  --upload-image-error-message-width: 370px;
  --upload-image-error-name-width: 200px;

  --max-width-td-tableV2: 150px;

  --data-analytics-full-screen-card-max-width: 190px;
  --data-analytics-card-max-width: 140px;
  --data-analytics-tooltip-width: 221px;

  --date-picker-panel-width: 372px;
  --month-year-picker-panel-width: 384px;

  --collapsible-menu-width-small: 25rem;
  --collapsible-menu-width-large: 30rem;

  --empty-state-img-size: 250px;
  --empty-state-img-large-width: 300px;
  --empty-state-img-medium-height: 180px;
  --empty-state-content-width: 568px;

  --editable-button-max-width: 700px;
  --editable-button-min-width: 128px;

  --position-sticky-global-header-composer: 84px;
  --position-sticky-header-composer: 86px;
  --position-sticky-custom-care-header: 138px;
  --position-sticky-custom-care-header-small: 152px;
  --add-recipients-modal-width: 600px;

  --webinar-details-modal-timezone-picker-width: 261px;

  --upload-html-code-editor-min-wdith: 396px;

  --upload-html-code-editor-gutter-width: 70px;

  --template-catalog-modal-mobile-preview-width: 352px;
  --template-catalog-modal-submit-button-width: 139px;

  --split-button-large-content-width: 248px;

  /* stylelint-enable */

  --z-index-chart-extra: 10;
  --z-index-multi-select: 20;
  --z-index-logo: 102;
  --z-index-body: 103;
  --z-index-body-mobile: 104;
  --z-index-top-bar: 18600;
  --z-index-menu: 19001;
  --z-index-banner: 205001; /* Menu z-index + 1 */
  --z-index-single-select-dropdown: 20500;
  --z-index-composer-tablist: 20501;
  --z-index-toast: 101000;
  --z-index-forethought-widget: 9998;
  --z-index-9: 9;
  --z-index-1: 1;
  --z-index-900: 900;
  --z-index-99: 99;
  --z-index-999: 999;
  --z-index-9999: 9999;
  --z-index-99999: 99999;
  --z-index-999999: 999999;
  --z-index-9999999: 9999999;
}
