.seo-url-modal {
  max-width: 50rem;

  &__label {
    margin: var(--spacing-unit-x4) 0 var(--spacing-unit);
    line-height: var(--line-height-smallish);
  }

  &__url {
    word-break: break-all;
  }

  &__delete-button {
    margin-right: auto;
  }

  &__input {
    .tooltip__trigger,
    .input-with-status {
      width: 100%;
    }
  }
}
