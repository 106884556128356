.fav-icon__view {
  &__tabbar {
    display: flex;
    background-color: var(--lighter-off-white);
    border-bottom: var(--standard-border);
    border-radius: calc(5 * var(--standard-border-radius) / 2) calc(5 * var(--standard-border-radius) / 2) 0 0;

    &-dots {
      display: flex;
      align-items: center;
      gap: calc(var(--spacing-unit-x17) / 12);
      padding: 0 var(--spacing-unit-x3);

      span {
        border-radius: 50%;
        background-color: var(--border-gray);
        border: calc(var(--standard-border-width) / 2) solid rgba(0, 0, 0, 0.2);
        height: calc(var(--spacing-unit-x23) / 12);
        width: calc(var(--spacing-unit-x23) / 12);
      }
    }

    &-tab {
      position: relative;
      width: calc(2 * var(--spacing-unit-x21));
      display: flex;
      align-items: center;
      gap: var(--spacing-unit-x2);
      background-color: var(--white);
      border: var(--standard-border);
      border-bottom: none;
      border-radius: inherit;
      margin-top: calc(var(--spacing-unit-x5) / 3);
      padding: var(--spacing-unit-x2) var(--spacing-unit-x4) var(--spacing-unit-x2) var(--spacing-unit-x3);

      &::after {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: calc(-1 * var(--half-spacing-unit) / 3);
        border-bottom: var(--standard-border);
        border-color: var(--white);
        content: '';
      }
    }

    &-image {
      height: fit-content;
      max-width: var(--spacing-unit-x4);
      max-height: var(--spacing-unit-x4);
    }
  }

  &__source {
    display: flex;
    align-items: center;
    margin: var(--spacing-unit-x3) 0 var(--spacing-unit);
    gap: var(--spacing-unit-x2);

    &-title {
      flex: 0 0 auto;
    }

    &-url {
      margin-left: calc(-1 * var(--spacing-unit));
    }
  }

  &__tooltip {
    line-height: initial;

    > div {
      display: flex;
    }

    .svg {
      fill: var(--light-gray);

      &:hover {
        fill: var(--text-gray);
      }
    }
  }

  &__svg-invalid {
    fill: var(--remove-red) !important;
  }
}
