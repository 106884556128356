
.program-steps {
  margin-bottom: var(--spacing-unit-x4);

  &__table__holder {
    border-radius: 0 0 var(--standard-border-radius) var(--standard-border-radius);

    &.indented-table__holder {
      border-top: none;

      .indented-table__td {
        align-items: center;
      }
    }
  }

  &__table-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: var(--standard-border);
    border-radius: var(--standard-border-radius) var(--standard-border-radius) 0 0;
    border-bottom: none;
    padding: var(--spacing-unit-x2) var(--spacing-unit-x4) 0;
    background-color: var(--white);
  }

  &__icon {
    position: relative;
    top: calc(var(--half-spacing-unit) / 3);
  }

  &__confirmation {
    width: 40.25rem;
  }
}
