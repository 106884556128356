.program-steps-and-details {
  scrollbar-gutter: stable;

  &__page-header {
    .page-headline {
      padding-right: var(--spacing-unit-x5);
    }
  }

  &__edit-mode {
    &-cancel {
      height: var(--spacing-unit-x6);
    }

    &-save {
      padding: var(--spacing-unit) var(--spacing-unit-x3);

      svg {
        margin-right: var(--spacing-unit-x2);
        fill: var(--white);
      }
    }
  }
}
