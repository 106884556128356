.users__count-info {
  display: flex;
  align-items: center;
  white-space: break-spaces;

  &__svg {
    margin-left: var(--spacing-unit);
    fill: var(--light-gray);

    &:hover {
      fill: var(--text-gray);
    }

    &-error {
      fill: var(--remove-red);

      &:hover {
        fill: var(--remove-hover-red);
      }
    }
  }

  &__tooltip {
    .tooltip__actions {
      width: auto;
    }
  }
}
