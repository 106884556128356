.incoming-webhook-token-authentication {
  &__token-generated {
    padding: var(--spacing-unit-x4) var(--spacing-unit-x6);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
  }
 
  &__input,
  &__empty-container,
  &__token-container,
  &__success-image {
    margin-bottom: var(--spacing-unit-x3);
  }
   
  &__button {
    &-generate {
      min-width: 10.75rem;

      .button {
        height: var(--spacing-unit-x6);
      }

      > svg {
        margin-right: var(--spacing-unit);
        fill: var(--white);
      }
    }
  }

  &__input {
    &-label {
      display: flex;
      align-items: center;
      margin-bottom: 0.8334rem;
    }
  }
}
