.data-cards {
  width: 100%;
  display: flex;
  gap: var(--spacing-unit-x2);
  padding-bottom: var(--spacing-unit-x2);

  &__card {
    max-width: 25%;
    width: 100%;
    padding: var(--spacing-unit-x3) var(--spacing-unit-x3) var(--spacing-unit-x4);
    border: var(--standard-border);
    border-radius: var(--standard-border-radius);
    background-color: var(--white);

    &-header {
      padding-bottom: var(--spacing-unit-x2);
      border-bottom: var(--standard-border);
    }

    &-content {
      margin: var(--spacing-unit-x2) 0 var(--spacing-unit);

      .typography {
        font-size: var(--large-page-header-font);
      }

      &-no-data {
        font-weight: var(--font-weight-medium-light);
        color: var(--light-gray);
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button--text-teal {
        line-height: var(--line-height-medium);
      }
    }

    &-trend-text {
      color: var(--remove-red);

      &__success {
        color: var(--success-green);
      }
    }

    &-arrow-icon {
      margin-right: 0.1667rem;
    }
  }
}
