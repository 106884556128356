.edit-update-subscription-step {
  width: 60rem;

  &__subscription-form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-unit-x4);
  }

  &__add-category {
    border-top: var(--standard-border);
    margin-top: var(--spacing-unit-x3);
  }

  &__add-button {
    margin-top: var(--spacing-unit-x2);
  }

  &__row-option {
    display: flex;
    gap: var(--spacing-unit);
    margin-bottom: var(--spacing-unit-x2);
  }

  &__row-option:last-child {
    display: flex;
    gap: var(--spacing-unit);
    margin-bottom: var(--spacing-unit-x3);
  }

  &__row-option:first-child {
    display: flex;
    gap: var(--spacing-unit);
    margin-top: var(--spacing-unit-x3);
  }

  &__category-selector {
    width: 32rem;
  }

  &__option-selector {
    width: 15rem;
  }

  &__form-radius {
    border-radius: var(--spacing-unit);
    margin-bottom: unset;
  }

  &__error-name {
    display: flex;
    gap: var(--half-spacing-unit);
    margin-top: var(--spacing-unit);
  }

  .info-status--warning {
    margin-top: 0rem;
  }

  .info-status__svg {
    align-self: self-start;
    padding-left: var(--spacing-unit-x4);
  }

  &__category-error {
    display: flex;
    align-items: center;
    margin-left: var(--spacing-unit-x2);
  }

  &__category-duplicate-with-margin {
    margin-top: var(--spacing-unit-x2);
  }

  .category-duplicate {
    margin-top: 0;
  }

  &__disabled-button {
    margin-top: var(--spacing-unit-x2);
    align-items: normal;
  }

  &__form-row {
    padding-bottom: unset;
  }

  &__row-option-view {
    display: flex;
    gap: var(--spacing-unit);
  }

  &__option-view {
    padding-left: var(--spacing-unit-x3);
  }

}
